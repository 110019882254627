/* eslint-disable */
/**
 * All Context will be merged to child, to clear up app.
 */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { AppManager, appStorage, useAppConfig } from '@dom-digital-online-media/dom-app-config-sdk';
import { AuthManager, useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { MoManager } from '@dom-digital-online-media/dom-mo-sdk';
import { StaticProvider } from '@dom-digital-online-media/dom-static-content-sdk';
import { PaymentProvider, PaymentManager } from '@dom-digital-online-media/dom-mps-sdk';
import { getCookie, removeCookie, setCookie } from '@utils/cookies';
import { AlertProvider, LayoutProvider } from '@context/Utils';
import { MobileOneProvider } from '@context/MobileOne';
import { AlphaCommProvider } from '@context/AlphaComm';
import MetaTagProvider from '@context/Utils/MetaTag';

export function AxiosManager({ children, config }) {
  // Global refresh promise handler
  let refreshTokenPromise;

  // Context
  const { onRegistrationToken, onTokenExpire } = useAuth();

  // States

  // Functions
  // eslint-disable-next-line consistent-return
  const getTempAccessToken = async () => {
    try {
      const {
        data: { access_token: accessToken },
        success
      } = await onRegistrationToken();
      if (success && accessToken) {
        await config.storage.encryptedSetItem(appStorage.AUTH_TOKEN, accessToken);
        return { data: { access_token: accessToken } };
      }
    } catch (error) {
      console.log(error);
      alert('Error in config');
      return { data: { access_token: false } };
    }
    // let url = `${env.REACT_APP_SERVER}api/temp-access-token`;

    // let headers = {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // };
    // return axios({
    //   method: 'GET',
    //   url,
    //   headers,
    // });
  };

  // eslint-disable-next-line consistent-return
  const getRefreshAccessToken = async () => {
    try {
      const {
        data: { access_token: accessToken, refresh_token: refreshToken, ...restData },
        success
      } = await onTokenExpire();
      if (success) {
        await config.storage.encryptedSetItem(appStorage.AUTH_TOKEN, accessToken);
        await config.storage.encryptedSetItem(appStorage.AUTH_REFRESH_TOKEN, refreshToken);
        await config.storage.encryptedSetItem(appStorage.USER_AUTH_DATA, JSON.stringify(restData));
        return { data: { access_token: accessToken, refresh_token: refreshToken } };
      }
    } catch (error) {
      console.log(error);
      alert('Error in config');
      return { data: { access_token: false } };
    }
  };

  // Config for axios authorise requests
  const axiosConfig = () => {
    // Function that will be called to refresh authorization
    const refreshAuthLogic = async (failedRequest) => {
      try {
        // Fetch temp token or refresh request once received 401;
        refreshTokenPromise =
          (await config.storage.encryptedGetItem(appStorage.AUTH_REFRESH_TOKEN)) &&
          (await config.storage.encryptedGetItem(appStorage.AUTH_TOKEN))
            ? getRefreshAccessToken()
            : getTempAccessToken();

        const {
          data: { access_token: accessToken = false }
        } = await refreshTokenPromise;

        // eslint-disable-next-line no-param-reassign
        failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        alert('Error in refreshing session!');
        return Promise.reject(error);
      }
    };
    // Use interceptor to inject the token to requests
    axios.interceptors.request.use(async (request) => {
      // Fetch token from storage
      const token = await config.storage.encryptedGetItem(appStorage.AUTH_TOKEN);
      // TODO: Add payment token into payment token api
      // const token = await config.storage.encryptedGetItem(appStorage.);
      if (
        token &&
        (request.url.includes(config.env.REACT_APP_MO_URL) ||
          request.url.includes(config.env.REACT_APP_MO_USER_URL))
      ) {
        // Assign token to request
        request.headers.Authorization = `Bearer ${token}`;
      }
      return request;
    });

    // Instantiate the interceptor (you can chain it as it returns the axios instance)
    createAuthRefreshInterceptor(axios, refreshAuthLogic);
  };

  // axiosConfig();

  return children;
}

export const ContextManagerContext = createContext({});

// eslint-disable-next-line react/prop-types
export function ContextLoader({ config, children }) {
  // Load configuration from server
  // Context
  const { env, loading } = useAppConfig();

  // State
  const [isEnvLoaded, setEnvLoaded] = useState(false);

  const contextPayload = useMemo(() => ({ config: { ...config, env } }), [config]);

  // Hooks

  // Validate environments are loaded correctly and then load children
  useEffect(() => {
    // console.log({ env, isEnvLoaded });
    if (!loading && env.REACT_APP_MO_URL && env.REACT_APP_MO_USER_URL) {
      setEnvLoaded(true);
    }
  }, [env, loading]);

  return isEnvLoaded ? (
    <>
      {/* TODO: Config it with proper props handling */}
      <ContextManagerContext.Provider value={contextPayload}>
        <StaticProvider
          {...{
            config: { ...config, env: { ...process.env, ...env } }
          }}
        >
          {/* Global App Alert Context Provider */}
          <AlertProvider>
            <PaymentProvider
              {...{
                config: { ...config, env: { ...process.env, ...env } }
              }}
            >
              {/* Global Authentication Context Loader */}
              <AuthManager
                {...{
                  config: { ...config, env: { ...process.env, ...env } }
                }}
              >
                {/* Global MobileOne Provider */}
                <MoManager
                  {...{
                    config: { ...config, env: { ...process.env, ...env } }
                  }}
                >
                  {/* Global Alphacomm Provider */}
                  <PaymentManager
                    {...{
                      config: { ...config, env: { ...process.env, ...env } }
                    }}
                  >
                    {/* App API Request Manager */}
                    <AxiosManager
                      {...{
                        config: { ...config, env: { ...process.env, ...env } }
                      }}
                    >
                      {/* App MobileOne Context Provider */}
                      <MobileOneProvider
                        {...{
                          config: { ...config, env: { ...process.env, ...env } }
                        }}
                      >
                        {/* App Alphacomm Context Provider */}
                        <AlphaCommProvider>
                          {/* App Meta tags Context Provider */}
                          <MetaTagProvider>
                            {/* Global App Layout Context Provider */}
                            <LayoutProvider>{children}</LayoutProvider>
                          </MetaTagProvider>
                        </AlphaCommProvider>
                      </MobileOneProvider>
                    </AxiosManager>
                  </PaymentManager>
                </MoManager>
              </AuthManager>
            </PaymentProvider>
          </AlertProvider>
        </StaticProvider>
      </ContextManagerContext.Provider>
    </>
  ) : (
    <>Loading...</>
  );
}

// eslint-disable-next-line react/prop-types
export function ContextManager({ children }) {
  // Constants
  // App Stroage & Env Config
  const config = {
    env: {
      ...process.env
      // REACT_APP_IS_CLIENT_LOGIN: process.env.REACT_APP_IS_CLIENT_LOGIN === 'true'
    },
    storage: {
      getItem: (key) =>
        new Promise((resolve, reject) => {
          try {
            resolve(sessionStorage.getItem(key));
          } catch (e) {
            reject(e);
          }
        }),
      setItem: (key, value) =>
        new Promise((resolve, reject) => {
          try {
            resolve(sessionStorage.setItem(key, value));
          } catch (e) {
            reject(e);
          }
        }),
      removeItem: (key) =>
        new Promise((resolve, reject) => {
          try {
            resolve(sessionStorage.removeItem(key));
          } catch (e) {
            reject(e);
          }
        }),
      encryptedSetItem: (key, value) =>
        new Promise((resolve, reject) => {
          try {
            resolve(setCookie(key, value));
          } catch (e) {
            reject(e);
          }
        }),
      encryptedGetItem: (key) =>
        new Promise((resolve, reject) => {
          try {
            resolve(getCookie(key));
          } catch (e) {
            reject(e);
          }
        }),
      encryptedRemoveItem: (key) =>
        new Promise((resolve, reject) => {
          try {
            if (key === appStorage.USER_AUTH_DATA) {
              removeCookie(key);
              // window.location.reload();
            }
            resolve(removeCookie(key));
          } catch (e) {
            reject(e);
          }
        })
    }
  };

  return (
    <>
      {/* Global App Context Loader */}
      <AppManager {...{ config }}>
        <ContextLoader {...{ config }}>{children}</ContextLoader>
      </AppManager>
    </>
  );
}

export const useConfig = () => useContext(ContextManagerContext);
ContextLoader.propTypes = {
  config: PropTypes.shape({
    env: PropTypes.shape({})
  }).isRequired
};
export default ContextManager;
