import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { FormCheck, FormControl } from 'react-bootstrap';
import { Col, Row } from '@core/Layout';
import { Button } from '@core/Inputs';
import { appRoute } from '@utils/globalConstant';
import { useRecharge } from '@context/MobileOne/recharge';
import { AutomaticRecharge } from '@part/Recharge/AutomaticRecharge';

export function Recharge() {
  // Context
  const { t } = useStaticContent();
  const { validationSchema, formValue, onSubmit, isLoading } = useRecharge();

  // State

  // Function
  return (
    <>
      {!formValue.rechargeOption ? (
        <Row>
          <Col md={4}>
            <Link to={appRoute.CREDIT_DETAILS}>
              <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
            </Link>
          </Col>
          <Col md={8}>
            <h3>{t('sh_comfort_charge_h1')}</h3>
            <Formik
              initialValues={{ ...formValue }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values: { rechargeOption }, handleBlur, handleChange, handleSubmit, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="m-2">
                      {[
                        t('sh_comfort_charge_opt1'),
                        t('sh_comfort_charge_opt2'),
                        t('sh_comfort_charge_opt3')
                      ].map((opt) => (
                        <FormCheck
                          label={opt}
                          name="rechargeOption"
                          type="radio"
                          className="m-1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={opt}
                          checked={rechargeOption === opt}
                        />
                      ))}
                      <FormControl.Feedback type="invalid" className="d-block">
                        {errors.rechargeOption}
                      </FormControl.Feedback>
                    </Col>
                    <Col xs={12} className="pt-3">
                      <Button variant="primary" type="submit" isLoading={isLoading}>
                        {t('sh_comfort_charge_button')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      ) : (
        <AutomaticRecharge />
      )}
    </>
  );
}

export default Recharge;
