import React from 'react';
import { Col, Container, Row, Section } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appSecBgColorTypes, appSecBottomElementTypes } from '@utils/globalConstant';

export function MandatoryDisclosure() {
  const { t } = useStaticContent();
  // return (
  //   <Container>
  //     <br />
  //     <br />
  //     <Row>
  //       <Col md={6} xs={12}>
  //         <h1 dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_h1') }} />
  //         <br />
  //         <b>
  //           <p dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_subheadline') }} />
  //         </b>
  //         <p dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_subheadline_text') }} />
  //         <br />
  //         <b>
  //           <p dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_subheadline2') }} />
  //         </b>
  //         <p
  //           dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_subheadline2_text1') }}
  //         />
  //         <br />
  //         <b>
  //           <p dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_subheadline3') }} />
  //         </b>
  //         <p dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_subheadline3_text') }} />
  //       </Col>
  //     </Row>
  //   </Container>
  // );
  return (
    <>
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 py-5 pb-0 pb-sm-5 mb-5 mb-sm-0"> */}
      <Section bgColorCode={appSecBgColorTypes.OFFWHITE}>
        <div className="container pt-0 pt-lg-5 pb-56 pb-lg-80">
          {/* <div className="row py-5 pb-0 pb-sm-5"> */}
          <div className="row pt-5">
            <div className="col-xl-7 offset-xl-1 col-md-8 offset-md-1 col-12">
              <h1
                className="primary-color-text my-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_h1') }}
              />
              <div
                className="body-large-bold primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_subheadline') }}
              />
              <div
                className="body-large-regular primary-color-text my-4 mt-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_subheadline_text') }}
              />
              <div
                className="body-large-regular primary-color-text mb-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_subheadline2') }}
              />

              <div
                className="body-large-regular primary-color-text mb-0"
                dangerouslySetInnerHTML={{
                  __html: t('sh_web_mobile_mandatory_subheadline2_text1')
                }}
              />
              {/* <strong className="body-large-bold primary-color-text">Deinem Widerrufsrecht: </strong> */}
              <div
                className="body-large-regular primary-color-text d-block m-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_subheadline3') }}
              />
              <div
                className="body-large-regular primary-color-text d-block m-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_mandatory_subheadline3_text') }}
              />
            </div>
          </div>
        </div>
      </Section>
      {/* <Section topElementType={appSecBottomElementTypes.TYPE_8}></Section> */}
      {/* </section> */}
    </>
  );
}

export default MandatoryDisclosure;
