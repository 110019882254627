import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import './style.scss';
import Accordion from '@core/Layout/Accordion';
import { Link, useParams } from 'react-router-dom';
import { appImages, appRoute, appSortOrder, deviceRegex } from '@utils/globalConstant';
import { useMetaTag } from '@context/Utils/MetaTag';
import { useLayout } from '@context/Utils';

export function FaqDetails() {
  const { categoryName } = useParams();

  // State
  const [categoryList, setCategoryList] = useState([]);
  const [faqList, setFaqList] = useState([]);
  const [topFaqList, setTopFaqList] = useState([]);
  const [showFullList, setShowFullList] = useState(false);
  const [isIosDevice, setIsIosDevice] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);

  // Context
  const { setLightHeader } = useLayout();
  const { faqStaticData = [], t, staticContentData } = useStaticContent();
  const { setMetaTags } = useMetaTag();

  // Hooks
  useEffect(() => {
    setLightHeader(false);
    let deviceInfo = navigator.userAgent;
    setIsIosDevice(deviceRegex.ISIOS.test(deviceInfo));
    setIsAndroidDevice(deviceRegex.ISANDRIOD.test(deviceInfo));

    return () => {
      setLightHeader(true);
      setIsIosDevice(false);
      setIsAndroidDevice(false);
    };
  }, []);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_faqus_hilfe_thema_meta_title'),
      description: t('sh_web_page_faqus_hilfe_thema_meta_description'),

      twitterTitle: t('sh_web_page_faqus_hilfe_thema_meta_title'),
      twitterDescription: t('sh_web_page_faqus_hilfe_thema_meta_description'),

      ogTitle: t('sh_web_page_faqus_hilfe_thema_meta_title'),
      ogDescription: t('sh_web_page_faqus_hilfe_thema_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  useEffect(() => {
    if (staticContentData && staticContentData.sh_faqSortList && faqStaticData) {
      const sortCatList = staticContentData.sh_faqSortList.find(
        (list) => list.listName === appSortOrder.FAQ_MAIN_CAT_LIST
      );

      if (sortCatList && sortCatList.listOrder) {
        const catList = sortCatList.listOrder
          .map((item) => ({
            ...item,
            ...faqStaticData.find((faq) => `${faq.categoryID}` === `${item.id}`)
          }))
          .filter((item) => item.id && item.category && item.categoryID)
          .map(({ id, categoryID, category }) => ({
            id,
            categoryID,
            category
          }));

        setCategoryList(catList);
      }

      let allFaqs = faqStaticData
        .filter((item) => item.category.toLowerCase() === categoryName.toLowerCase())
        .map((faqItem) => ({
          title: faqItem.question,
          component: `${faqItem.answer}`,
          // component: <div dangerouslySetInnerHTML={{ __html: faqItem.answer }}></div>,
          internalID: faqItem.internalID,
          categoryID: faqItem.categoryID,
          categoryName: faqItem.category
        }));

      setFaqList(allFaqs);
      setTopFaqList(allFaqs.filter((item, index) => item && index < 5));
    }
    return () => {
      setCategoryList([]);
      setTopFaqList([]);
      setFaqList([]);
    };
  }, [staticContentData, faqStaticData]);

  return (
    <>
      <section className="frequently-asked-section pt-10 pb-5 pt-5 pt-md-0 position-relative">
        <div className="container mb-lg-5 pt-10 pt-5 my-4 my-md-0">
          <div className="row">
            <div className="col-12 orange-gray-bubble"></div>
            <div className="col-12 col-md-4 offset-md-1">
              {faqList.length > 0 && (
                <h1
                  className="primary-color-text text-break"
                  dangerouslySetInnerHTML={{ __html: faqList[0]?.categoryName }}
                />
              )}
            </div>

            <div className="col-12 col-md-7 mb-lg-5 pt-md-5">
              {!showFullList && faqList.length > 5 && (
                <>
                  <Accordion items={topFaqList} />
                  <button
                    className="btn btn-secondary w-75 mt-5"
                    onClick={() => setShowFullList(true)}
                  >
                    {t('sh_web_faq_detail_text')} ({faqList.length - 5})
                  </button>
                </>
              )}

              {showFullList && <Accordion items={faqList} />}

              {faqList.length <= 5 && <Accordion items={faqList} />}
            </div>
          </div>
        </div>
      </section>

      <section className="questions-clarified-section quest-section position-relative bg-primary-color friends-purple-shade-2 purple-curve-shade-2 pt-5 pb-2 py-md-5 my-md-5 mt-5 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-12 offset-md-1 col-md-7 offset-lg-1 col-lg-5 pb-md-5">
              <h3
                className="offWhite-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_ques_subtext1') }}
              />
              <p
                className="body-large-regular offWhite-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_ques_subtext2') }}
              />
              <div className="d-flex flex-wrap flex-column flex-sm-row pt-2">
                {isIosDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    target="_blank"
                    href={t('sh_ap_appstore')}
                  >
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </a>
                )}
                {isAndroidDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    target="_blank"
                    href={t('sh_ap_playstore')}
                  >
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </a>
                )}
                {!isAndroidDevice && !isIosDevice && (
                  <>
                    <span className="pe-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 border-0">
                      <img className="mx-100 grow" src={appImages.qrcode} alt="Qrcode" />
                    </span>
                    <a
                      className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      target="_blank"
                      href={t('sh_ap_playstore')}
                    >
                      <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                    </a>
                    <a
                      className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      target="_blank"
                      href={t('sh_ap_appstore')}
                    >
                      <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="frequently-topic-section bg-offWhite-color gray-shade-1 pt-5 position-relative mt-md-5">
        <div className="container py-5 mt-md-5">
          <div className="row pb-lg-5">
            <div className="col-12 col-md-4 col-lg-3">
              <h4
                className="primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_faq_overview_subheadline') }}
              />
            </div>
            <div className="col-12 col-md-8 col-lg-9 mt-md-5 mb-5 mb-md-0">
              <ul className="list-unstyled category-link my-md-5">
                {categoryList.map((list, index) => (
                  <li className="my-3" key={`${index + 1}`}>
                    <Link
                      className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                      to={appRoute.FAQ_TOPIC(list.categoryID)}
                    >
                      <div dangerouslySetInnerHTML={{ __html: list.category }} />{' '}
                      <i className="fa fa-arrow-right" />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <br className="d-none d-lg-block" />
        <br className="d-none d-lg-block" />
      </section>
      <br />
      <br className="d-block d-lg-none d-xl-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
    </>
  );
}

export default FaqDetails;
