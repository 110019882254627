import React from 'react';
/* eslint-disable */
import { ChargeHistoryProvider } from './ChargeHistory';
import { OnlineTopUpProvider } from './OnlineTopUp';
import { VoucherProvider } from './Voucher';

export function AlphaCommProvider({ children }) {
  return (
    <>
      {/* OnlineTopUp Context Provider */}
      <OnlineTopUpProvider>
        {/* Charge History Context Provider */}
        <ChargeHistoryProvider>
          {/* Voucher Provider */}
          <VoucherProvider>{children}</VoucherProvider>
        </ChargeHistoryProvider>
      </OnlineTopUpProvider>
    </>
  );
}

export default AlphaCommProvider;
/* eslint-disable */
export * from './ChargeHistory';
export * from './OnlineTopUp';
export * from './Voucher';
