import React from 'react';
import { Text } from '@core/Inputs';
import { Col, Row } from '@core/Layout';
import { useFormikContext } from 'formik';
// import PropTypes from 'prop-types'

export function Phone() {
  const { values, errors, touched, handleBlur, handleChange } = useFormikContext();

  return (
    <>
      <div className="py-3">
        <hr />
        <h2>Phone number to be loaded</h2>
        <hr />
        <Row>
          <Col xs={12}>
            <span>Enter the phone number you want to top up</span>
          </Col>
          <Col xs={12} md={6} lg={4} className="py-2">
            <Text
              type="text"
              label="Phone number"
              id="phone"
              name="phone"
              onChange={handleChange}
              value={values.phone}
              placeholder="Enter phone number"
              isValid={touched.phone && !errors.phone}
              isInvalid={touched.phone && !!errors.phone}
              onBlur={handleBlur}
              error={errors.phone}
            />
          </Col>
          <Col xs={12} md={6} lg={4} className="py-2">
            <Text
              type="text"
              label="Confirm phone number"
              id="confirmPhone"
              name="confirmPhone"
              onChange={handleChange}
              value={values.confirmPhone}
              placeholder="Confirm phone number"
              isValid={touched.confirmPhone && !errors.confirmPhone}
              isInvalid={touched.confirmPhone && !!errors.confirmPhone}
              onBlur={handleBlur}
              error={errors.confirmPhone}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

Phone.propTypes = {};

export default Phone;
