import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import { Card, CardBody, CardHeader, CardText, CardTitle } from '@core/Layout/Card';
import { Row, Col } from '@core/Layout/Container';
import { Text } from '@core/Inputs/Text';

export function DirectPaymentProduct({
  id,
  productType,
  minAmount,
  maxAmount,

  currency,
  priceRange,

  channel,
  optionType,

  isSelected,
  onSelect,
  otherAmount,
  onAmountChange,
  onBlur
}) {
  return (
    <Card border="primary" onClick={() => onSelect(id)}>
      <CardHeader>
        <Row className="justify-content-md-center p-0">
          <Col xs={10}>
            {productType} - {id}
          </Col>
          <Col xs={2} className="text-center justify-content-center">
            <Form.Check
              type="radio"
              id={`payment-other-product-${id}`}
              checked={isSelected}
              onBlur={onBlur}
              onChange={() => onSelect(id)}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <CardTitle>
          min - {minAmount} {currency}, max - {maxAmount} {currency}
        </CardTitle>
        <CardText>
          Option Type - {optionType} <br />
          Price Range - {priceRange} <br />
          Channel - {channel} <br />
        </CardText>
        <Text
          label="Other Amount"
          type="text"
          id={`other_amount_${id}`}
          placeholder="Enter Amount"
          name={`other_amount_${id}`}
          value={otherAmount}
          onChange={(e) => {
            onAmountChange(e.target.value);
            onSelect(id);
          }}
        />
      </CardBody>
    </Card>
  );
}

DirectPaymentProduct.propTypes = {
  id: PropTypes.number,
  productType: PropTypes.string,
  minAmount: PropTypes.number,
  maxAmount: PropTypes.number,
  currency: PropTypes.string,
  priceRange: PropTypes.string,
  channel: PropTypes.string,
  optionType: PropTypes.string,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,

  otherAmount: PropTypes.string,
  onAmountChange: PropTypes.func,
  onBlur: PropTypes.func
};

DirectPaymentProduct.defaultProps = {
  id: 105,
  productType: '',
  minAmount: 5,
  maxAmount: 50,
  currency: '',
  priceRange: '',
  channel: '',
  optionType: '',
  isSelected: false,
  onSelect: () => {},
  otherAmount: '',
  onAmountChange: () => {},
  onBlur: () => {}
};

export default DirectPaymentProduct;
