import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useNavigate } from 'react-router-dom';
import { UTAG_LINK, UTAG_VIEWS } from '@utils/utag';

export function CancelContractSuccess() {
  // Context
  const { t } = useStaticContent();

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.CANCEL_CONTRACT_SUCCESS_MOUNTING);
    }
  }, [window.utag]);

  return (
    <>
      <section className="position-relative bg-offWhite-color gray-shade-1 py-5 pb-0 pb-lg-5 mb-5">
        <div className="container pt-0 pt-lg-5 py-5 my-0 my-lg-5">
          <div className="row pt-5 pb-0 py-xl-5">
            <div className="col-xl-7 offset-xl-1 col-md-8 offset-md-2 col-lg-7 col-12">
              <h1
                className="primary-color-text mt-5 mb-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_cancel_contract_accept_h1') }}
              />
              <div
                className="body-large-regular primary-color-text mb-4"
                dangerouslySetInnerHTML={{
                  __html: t('sh_web_cancel_contract_accept_text1')
                }}
              />
              <div
                className="body-large-regular divrimary-color-text mb-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_cancel_contract_accept_text2') }}
              />
              <div className="col-md-7 col-sm-6 col-12 mb-5 mb-xl-0">
                <a
                  type="button"
                  className="btn btn-secondary w-100"
                  href={t('sh_web_cancel_contract_accept_button_link')}
                  onClick={() => {
                    if (window && window.utag) {
                      window.utag.link(UTAG_LINK.CANCEL_CONTRACT_ON_CLICK.SUCCESS_ON_SUBMIT);
                    }
                  }}
                >
                  {t('sh_web_cancel_contract_accept_button')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative py-4 my-5 d-none d-xl-block">&nbsp;</section>
    </>
  );
}

export default CancelContractSuccess;
