/* eslint-disable */
import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { formValidation } from '@dom-digital-online-media/dom-app-config-sdk';
import { appAlert, appRoute } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk/dist';

export const RechargeContext = createContext({});

export function RechargeContextProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    rechargeOption: '',
    enoughBalance: '',
    date: ''
  });
  // Validations
  const validationSchema = Yup.object().shape({
    rechargeOption: formValidation({ required: 'please_select' })
  });
  const validationSchemaRechargeOpt1 = Yup.object().shape({
    automaticRecharge: formValidation({ required: 'please_select' }),
    enoughBalance: formValidation({ required: 'please_select' })
  });
  const validationSchemaRechargeOpt2 = Yup.object().shape({
    automaticRecharge: formValidation({ required: 'please_select' }),
    date: formValidation({ required: 'please_select' })
  });
  const validationSchemaRechargeOpt3 = Yup.object().shape({
    automaticRecharge: formValidation({ required: 'please_select' })
  });
  const { showAlert } = useAlert();
  const { t } = useStaticContent();
  // Functions
  const onSubmit = async (values) => {
    setFormValue(values);
    /* if (values.sofortOption || values.cashcode) {
            if (values.cashcode) {
                try {
                    const { status } = await onVoucherTDM(values.cashcode)
                    if (status === 200) {
                        showAlert({ type: appAlert.SUCCESS, message: t('sh_db_charge_balance_success_msg') });
                    }
                    setFormValue({
                        creditOption: '',
                        sofortOption: '',
                        cashcode: '',
                        isSofortSelected: false,
                        isCashcodeEntered: false
                    })
                    navigate(appRoute.DASHBOARD)
                } catch (error) {
                    showAlert({
                        type: appAlert.ERROR,
                        message: `Error in request. ${error.error[0].messageBody}`
                    });
                }

            } else if (values.sofortOption) {
                showAlert({ type: appAlert.SUCCESS, message: t('sh_db_charge_balance_success_msg') });
                setFormValue({
                    creditOption: '',
                    sofortOption: '',
                    cashcode: '',
                    isSofortSelected: false,
                    isCashcodeEntered: false
                })
                navigate(appRoute.DASHBOARD)
            }
        } */
  };
  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      formValue,
      setFormValue,
      validationSchema,
      validationSchemaRechargeOpt1,
      validationSchemaRechargeOpt2,
      validationSchemaRechargeOpt3,
      onSubmit
    }),
    [
      // States
      isLoading,
      setIsLoading,
      formValue,
      setFormValue,
      validationSchema,
      validationSchemaRechargeOpt1,
      validationSchemaRechargeOpt2,
      validationSchemaRechargeOpt3,
      onSubmit
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <RechargeContext.Provider value={contextPayload}>{children}</RechargeContext.Provider>;
}
RechargeContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useRecharge = () => useContext(RechargeContext);

export default RechargeContext;
