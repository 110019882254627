import React from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Text, Button } from '@core/Inputs';
import { useAccount } from '@context/MobileOne/Account';
import { Col, Row } from '@core/Layout';

export function ChangeEmail({ setIsEmailChange }) {
  // States
  // Context
  const { t } = useStaticContent();
  const {
    isLoading,
    email,
    changeEmailForm,
    onChangeEmailSubmit,
    changeEmailValidation,
    onResendEmailChangeCode,
    emailCodeInitialValue,
    changeEmailCodeValidation,
    setChangeEmailForm,
    onVerifyEmail
  } = useAccount();

  return (
    <>
      <br />
      {!changeEmailForm.email && (
        <Row>
          <Col md={3}>
            <Button variant="link" onClick={() => setIsEmailChange(false)}>
              <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
            </Button>
          </Col>
          <Col md={6}>
            <h4>{t('sh_md_change_email_headline')}</h4>
            <Formik
              initialValues={{ ...changeEmailForm, email }}
              enableReinitialize
              validationSchema={changeEmailValidation}
              onSubmit={(values) => {
                onChangeEmailSubmit(values);
              }}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Text
                        label={t('sh_md_change_email_subheadline1')}
                        type="text"
                        id="email"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        placeholder={t('sh_md_change_email_new_email')}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                        onBlur={handleBlur}
                        error={errors.email}
                      />
                    </Col>
                    <Col xs={12} className="py-3">
                      <Button variant="primary" type="submit" isLoading={isLoading}>
                        {t(' sh_md_change_email_button')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      )}
      {changeEmailForm.email && (
        <Row>
          <Col md={3}>
            <Button variant="link" onClick={() => setChangeEmailForm({ eamil: '' })}>
              <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
            </Button>
          </Col>
          <Col md={6}>
            <h4>{t('sh_md_change_email_headline')}</h4>
            <br />
            <h5>{t('sh_md_change_email_subheadline2')}</h5>
            <p dangerouslySetInnerHTML={{ __html: t('sh_md_change_email_text1') }} />
            <Formik
              initialValues={emailCodeInitialValue}
              enableReinitialize
              validationSchema={changeEmailCodeValidation}
              onSubmit={(values) => {
                onVerifyEmail(values);
              }}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Text
                        label={t('sh_md_change_email_code')}
                        type="text"
                        id="emailCode"
                        name="emailCode"
                        onChange={handleChange}
                        value={values.emailCode}
                        isValid={touched.emailCode && !errors.emailCode}
                        isInvalid={!!errors.emailCode}
                        onBlur={handleBlur}
                        error={errors.emailCode}
                      />
                    </Col>
                    <Col xs={12} className="py-3">
                      <Button variant="primary" type="submit" isLoading={isLoading}>
                        {t('sh_md_change_email_verification_button')}
                      </Button>
                    </Col>
                  </Row>
                  <center>
                    <p dangerouslySetInnerHTML={{ __html: t('sh_md_change_email_text2') }} />
                    <Button
                      variant="link"
                      onClick={() => {
                        onResendEmailChangeCode();
                      }}
                    >
                      {t('sh_md_change_email_resend_code')}
                    </Button>
                  </center>
                </Form>
              )}
            </Formik>
          </Col>
          <br />
        </Row>
      )}
    </>
  );
}

export default ChangeEmail;
