/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Card, CardBody } from '@core/Layout';
// import { Card, CardBody, CardHeader, CardText } from '@core/Layout/Card';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button, Text } from '@core/Inputs';
import { useActivation } from '@context/MobileOne';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import {
  appActivationFlowType,
  appActivationStep1Modals,
  appButtonType,
  appImages,
  appStaticPdf,
  appTariff
} from '@utils/globalConstant';
import { UTAG_LINK, utag } from '@utils/utag';
import TariffDetailsModal from './TariffDetailsModal';
import MGMCodeModal from './MGMCodeModal';
import { FootNotesModal } from './FootNotesModal';
// import { BsCheckLg } from 'react-i';
import './style.scss';
import NewFootNotesModal from './NewFootNotesModal';
import NewTariffModal from './NewTariffModal';

export function YourTariff({
  setFieldValue,
  handleChange,
  errors,
  touched,
  setTouched,
  setFieldTouched,
  setErrors,
  codeForValidation,
  handleBlur
}) {
  const { t, getPdfList, staticContentData } = useStaticContent();
  const [modal, setModal] = useState();
  const { activationInfo, setActivationInfo, activationType } = useActivation();
  const {
    activeTariff,
    setActiveTariff,
    bookableTariff,
    setBookableTariff,
    formValue,
    setFormValue,
    applyMGMCode,
    isLoading
  } = useActivationTariff();

  // Functions
  const openPdf = (pdfId, pdfName) => {
    if (staticContentData) {
      let pdfInfo = getPdfList('sh_pdfLists').find((item) => item.listName === pdfName);
      window.open(pdfInfo.listContent.find(({ id }) => id === pdfId).media_url_display);
    }
  };

  // Hooks
  useEffect(() => {
    if (staticContentData) {
      setTimeout(() => {
        const startModal = document.querySelector('.star-btn');
        if (startModal) {
          startModal.addEventListener('click', () => {
            setModal(appActivationStep1Modals.NEW_FOOTNOTES);
          });
        }
      }, 300);
    }
  }, [staticContentData]);

  useEffect(() => {
    if (activeTariff && activeTariff.promoCode) {
      setActivationInfo({
        ...activationInfo,
        isPromoApplied: true,
        codeForValidation: activeTariff.promoCode
      });
    }
    if (staticContentData) {
      setTimeout(() => {
        const startModal = document.querySelector('.star-btn');
        if (startModal) {
          startModal.addEventListener('click', () => {
            setModal(appActivationStep1Modals.NEW_FOOTNOTES);
          });
        }
      }, 300);
    }
  }, [activeTariff]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-8 offset-md-1 col-lg-5 offset-lg-1">
          <p className="text-white label-medium mb-1">{t('sh_apoc_summary_subheader1')}</p>
        </div>
      </div>
      {activeTariff && activeTariff.detailInfo && (
        <>
          <div className="row">
            <div className="col-12 col-md-8 offset-md-1 col-lg-5 offset-lg-1 mb-3 mb-sm-0">
              <h4
                className="text-white mb-3 mb-sm-4"
                dangerouslySetInnerHTML={{ __html: activeTariff.detailInfo.header }}
              />
              <ul className="list-unstyled overview-tariff tarrif-feature mb-2 mb-sm-3">
                {(activeTariff?.promoCode
                  ? activeTariff?.operationsInfo?.ON_MGM_CODE_APPLY?.bullets
                  : activeTariff?.detailInfo?.additionalInfo?.bulletsActivationSummaryPage || []
                ).map((bullet, index) => (
                  <li
                    className="body-large-regular text-white list-group-item pe-5"
                    style={{ marginBottom: '10px' }}
                    key={`additional-info-${index + 1}`}
                    dangerouslySetInnerHTML={{ __html: bullet.header }}
                  />
                ))}
              </ul>
            </div>
          </div>

          <div className="row mb-1 mb-sm-4">
            <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1 mt-3 mb-2 mb-sm-0">
              <Card sx={{ backgroundColor: '#786886', color: 'white', borderRadius: '12px' }}>
                <CardBody
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <div className="d-block">
                    <p className="body-medium-regular mb-1">{t('sh_apoc_tarif_overview_text4')}</p>
                    <p className="body-large-bold text-white mb-1">
                      {t('sh_apoc_start_initial_price_header').replace(
                        '{{price}}',
                        activeTariff.starterPackPrice
                      )}
                    </p>
                    <p className="body-medium-regular my-1">
                      {t('sh_apoc_summary_start_credit_text')}
                    </p>
                    <p
                      className="label-medium mb-1"
                      role="button"
                      onClick={() => {
                        if (window && utag) {
                          activationType === appActivationFlowType.NEW_ONLINE
                            ? activeTariff.id === appTariff.FLAT_M
                              ? window.utag.link(
                                  UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.MORE_INFO_ON_CLICK
                                )
                              : window.utag.link(
                                  UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.MORE_INFO_ON_CLICK
                                )
                            : activeTariff.id === appTariff.FLAT_M
                            ? window.utag.link(
                                UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.MORE_INFO_ON_CLICK
                              )
                            : window.utag.link(
                                UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.MORE_INFO_ON_CLICK
                              );
                        }
                        setModal(appActivationStep1Modals.FOOTNOTES);
                      }}
                    >
                      <u>{t('sh_apoc_tarif_overview_text6')}</u>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1 mb-2 mb-sm-0">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-end">
                  <div className="d-flex align-items-end">
                    <h4
                      className="text-white"
                      dangerouslySetInnerHTML={{
                        __html: `${activeTariff.starterPackPrice} &euro;`
                      }}
                    />
                    <span className="text-white body-medium-regular mb-2 mx-1">
                      / {activeTariff.duration}&nbsp;{activeTariff.durationUnit}
                    </span>
                  </div>
                  <div className="pb-3">
                    <div
                      className="astrick"
                      role="button"
                      onClick={() => {
                        setModal(appActivationStep1Modals.FOOTNOTES);
                      }}
                    >
                      {/* <i
                      className="bi bi-star-fill primary-color-text"
                      style={{ position: 'relative' }}
                    /> */}
                      <img className="img-fluid" src={appImages.footnote} alt="foot note icon" />
                    </div>
                  </div>
                </div>

                <div
                  className={activeTariff.operationsInfo.promoCode ? 'mt-2 p-0 d-flex' : 'mt-2 p-0'}
                >
                  <div className="d-flex align-items-end">
                    <input type="hidden" name="isPromoApplied" defaultValue="false" />
                    {!activationInfo.isPromoApplied && (
                      <a
                        className="text-white label-small p-0 mb-3 mb-sm-0 link-light tariff-link tariff-code shadow-none"
                        variant="link"
                        onClick={() => {
                          if (window && window.utag) {
                            window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.REDEEM_CODE_ON_CLICK
                            );
                          }
                          setModal(appActivationStep1Modals.MGM_CODE);
                        }}
                      >
                        {t('sh_apoc_tarif_overview_voucher')}
                      </a>
                    )}
                  </div>

                  {/* <input type="hidden" name="isPromoApplied" value={activationInfo.isPromoApplied} />
              {!activationInfo.isPromoApplied ? (
                <a
                  className="text-white body-medium-regular p-0 mb-5 mb-sm-0 link-light shadow-none"
                  variant="link"

                  onClick={() => {
                    setModal(appActivationStep1Modals.MGM_CODE);
                  }}>
                  {t('sh_apoc_tarif_overview_voucher')}
                </a>
              ) : (
                <>
                  {!activeTariff.promoCode ? (
                    <>
                      <Text
                        type="text"
                        id="codeForValidation"
                        name="codeForValidation"
                        onChange={handleChange}
                        value={codeForValidation}
                        placeholder="Code"
                        isInvalid={!!touched.codeForValidation && !!errors.codeForValidation}
                        onBlur={handleBlur}
                        error={
                          touched.codeForValidation && errors.codeForValidation
                            ? errors.codeForValidation
                            : null
                        }
                      />
                      <a
                        className="text-white body-medium-regular p-0 mb-5 mb-sm-0 link-light shadow-none"
                        variant="link"
                        onClick={() => {
                          setTouched({ codeForValidation: true });
                          applyMGMCode({ codeForValidation, setErrors });
                        }}>
                        {t('sh_apoc_voucher_redeem_button')}
                      </a>
                    </>
                  ) : (
                    <div>
                      <b>
                        {t('sh_apoc_tarif_overview_voucher').replace(
                          ' ',
                          ` "${activeTariff.operationsInfo.promoCode}" `
                        )}
                      </b>
                      <p>
                        {t('sh_apoc_tarif_overview_voucher-msg')
                          .replace(
                            '"\\add GB here"',
                            `+${activeTariff.operationsInfo.codeGBVolume} GB.`
                          )
                          .replace('Code "\\add code here" eingelöst', '')}
                      </p>
                    </div>
                  )}
                  <a
                    className="m-3 text-white body-medium-regular p-0 mb-5 mb-sm-0 link-light shadow-none"
                    variant="link"
                    onClick={() => {
                      setFieldValue('isPromoApplied', false);
                      setFieldValue('codeForValidation', '');
                      setFieldTouched('codeForValidation', false, false);
                      const bookableTariffsIds = bookableTariff?.bookableTariffs.map((bt) => bt.id);
                      bookableTariff.bookableTariffs = [
                        ...staticContentData.tariff_share.filter(
                          (bt) => bookableTariffsIds.indexOf(bt.id) > -1
                        )
                      ];
                      const defaultTariff = !bookableTariff.msisdn
                        ? bookableTariff.bookableTariffs.find((bt) => bt.defaultTariff)
                        : bookableTariff.bookableTariffs[0];

                      setFormValue({ ...formValue, tariff: defaultTariff.id });
                      setActiveTariff(defaultTariff);
                      setBookableTariff(JSON.parse(JSON.stringify(bookableTariff)));
                    }}>
                    {t('sh_apoc_tarif_overview_delete')}
                  </a>
                </>
              )} */}
                </div>
              </div>
            </div>
          </div>
          {activationType === appActivationFlowType.NEW_ONLINE && (
            <div className="row">
              <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1">
                <div className="d-flex pt-2">
                  <div
                    className="text-white label-small link-light tariff-link shadow-none"
                    dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text7') }}
                  />
                  {/* <div
                    className="astrick"
                    role="button"
                    onClick={() => {
                      setModal(appActivationStep1Modals.NEW_TARIFF_MODAL);
                    }}
                  >
                    <img className="img-fluid" src={appImages.footnote} alt="foot note icon" />
                  </div> */}
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12 col-md-8 offset-md-1 col-lg-5 offset-lg-1">
              <a
                className="btn-link text-white label-small tariff-link shadow-none"
                variant="link"
                onClick={() => {
                  if (window && window.utag) {
                    activationType === appActivationFlowType.NEW_ONLINE
                      ? activeTariff.id === appTariff.FLAT_M
                        ? window.utag.link(
                            UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW
                              .ALLNET_M_TARIFF_DETAILS_ON_CLICK
                          )
                        : window.utag.link(
                            UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW
                              .ALLNET_L_TARIFF_DETAILS_ON_CLICK
                          )
                      : activeTariff.id === appTariff.FLAT_M
                      ? window.utag.link(
                          UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.ALLNET_M_TARIFF_DETAILS_ON_CLICK
                        )
                      : window.utag.link(
                          UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.ALLNET_L_TARIFF_DETAILS_ON_CLICK
                        );
                  }
                  setModal(appActivationStep1Modals.TARIFF_DETAILS);
                }}
              >
                {t('sh_apoc_tarif_overview_tarif-details')}
              </a>
              <br />
              <a
                className="btn-link text-white label-small tariff-link shadow-none"
                variant="link"
                onClick={() => {
                  if (window && window.utag) {
                    activationType === appActivationFlowType.NEW_ONLINE
                      ? activeTariff.id === appTariff.FLAT_M
                        ? window.utag.link(
                            UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW
                              .ALLNET_M_PRODUCT_INFORMATION_ON_CLICK
                          )
                        : window.utag.link(
                            UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW
                              .ALLNET_L_PRODUCT_INFORMATION_ON_CLICK
                          )
                      : activeTariff.id === appTariff.FLAT_M
                      ? window.utag.link(
                          UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW
                            .ALLNET_M_PRODUCT_INFORMATION_ON_CLICK
                        )
                      : window.utag.link(
                          UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW
                            .ALLNET_L_PRODUCT_INFORMATION_ON_CLICK
                        );
                  }

                  openPdf(
                    activeTariff?.additionalInfo?.document?.productInformation.id,
                    appStaticPdf.PRODUCT_INFO
                  );
                }}
              >
                {t('sh_apoc_tarif_overview_product-info')}
              </a>

              {/* <a
                className="text-white label-small btn-link"
                target="_blank"
                href={activeTariff.additionalInfo.document.tariffDetails.url}
                rel="noreferrer">
                {t('sh_apoc_tarif_overview_tarif-details')}
              </a>
              <a
                className="text-white label-small btn-link"
                target="_blank"
                href={activeTariff.additionalInfo.document.productInformation.url}
                rel="noreferrer">
                {t('sh_apoc_tarif_overview_product-info')}
              </a> */}

              {/* removed the Preisliste from here refs #44000 */}
              {/* <a
              style={{ marginLeft: "8px" }}
              className="btn-link"
              target="_blank"
              rel="noreferrer"
              href={activeTariff.additionalInfo.document.priceList.url}>
              {t('sh_apoc_tarif_overview_prices')}
            </a> */}
            </div>
          </div>

          <div className="row mb-1 mb-sm-4">
            <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1 mt-3 mb-sm-0">
              <Card sx={{ backgroundColor: '#786886', color: 'white', borderRadius: '12px' }}>
                <CardBody
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <div className="d-block">
                    <div
                      className="body-medium-regular mb-1"
                      dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-charge') }}
                    />
                    <div
                      className="body-large-bold text-white mb-1"
                      dangerouslySetInnerHTML={{
                        __html: t('sh_apoc_tarif_overview_tarif-charge-text')
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1 mb-2">
              {activeTariff?.promoCode && (
                <div className="row p-3 bg-purple-light-color box-radius mx-0 mt-4">
                  <div className="col-12 p-0">
                    <p className="label-medium text-white">
                      {t('sh_apoc_tarif_overview_voucher-code-msg1').replace(
                        '{{mgmCode}}',
                        activeTariff?.promoCode
                      )}
                    </p>
                    <p className="label-small text-uppercase text-white mb-0">
                      {t('sh_apoc_tarif_overview_voucher-code-msg2').replace(
                        '{{dataVolume}}',
                        activeTariff?.operationsInfo?.codeGBVolume
                      )}
                    </p>
                  </div>
                  <div className="col-12 px-0 pt-2">
                    <a
                      className="text-white label-small p-0 mb-5 mb-sm-0 link-light tariff-link shadow-none"
                      variant="link"
                      onClick={() => {
                        if (window && window.utag) {
                          activationType === appActivationFlowType.NEW_ONLINE
                            ? activeTariff.id === appTariff.FLAT_M
                              ? window.utag.link(
                                  UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.REMOVE_CODE_ON_CLICK
                                )
                              : window.utag.link(
                                  UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.REMOVE_CODE_ON_CLICK
                                )
                            : activeTariff.id === appTariff.FLAT_M
                            ? window.utag.link(
                                UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.REMOVE_CODE_ON_CLICK
                              )
                            : window.utag.link(
                                UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.REMOVE_CODE_ON_CLICK
                              );
                        }
                        setFieldValue('isPromoApplied', false);
                        setFieldValue('codeForValidation', '');
                        setFieldTouched('codeForValidation', false, false);
                        const bookableTariffsIds = bookableTariff?.bookableTariffs.map(
                          (bt) => bt.id
                        );
                        bookableTariff.bookableTariffs = [
                          ...staticContentData.tariff_share.filter(
                            (bt) => bookableTariffsIds.indexOf(bt.id) > -1
                          )
                        ];
                        const defaultTariff =
                          bookableTariff.bookableTariffs.length > 1
                            ? bookableTariff.bookableTariffs.find((bt) => bt.id === activeTariff.id)
                            : bookableTariff.bookableTariffs[0];

                        setFormValue({ ...formValue, tariff: defaultTariff.id });
                        setActiveTariff(defaultTariff);
                        setBookableTariff(JSON.parse(JSON.stringify(bookableTariff)));
                      }}
                    >
                      {t('sh_apoc_tarif_overview_delete')}
                    </a>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1 mt-3 mb-2 mb-sm-0">
              <Card sx={{ backgroundColor: '#786886', color: 'white', borderRadius: '12px' }}>
                <CardBody
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <div className="d-block">
                    <p className="body-medium-regular mb-1">{t('sh_apoc_tarif_overview_text4')}</p>
                    <p className="body-large-bold text-white mb-1">
                      {t('sh_apoc_start_initial_price_header').replace(
                        '{{price}}',
                        activeTariff.starterPackPrice
                      )}
                    </p>
                    <p className="body-medium-regular my-1">
                      {t('sh_apoc_summary_start_credit_text')}
                    </p>
                    <p
                      className="label-medium mb-1"
                      role="button"
                      onClick={() => {
                        if (window && utag) {
                          activationType === appActivationFlowType.NEW_ONLINE
                            ? activeTariff.id === appTariff.FLAT_M
                              ? window.utag.link(
                                  UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.MORE_INFO_ON_CLICK
                                )
                              : window.utag.link(
                                  UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.MORE_INFO_ON_CLICK
                                )
                            : activeTariff.id === appTariff.FLAT_M
                            ? window.utag.link(
                                UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.MORE_INFO_ON_CLICK
                              )
                            : window.utag.link(
                                UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.MORE_INFO_ON_CLICK
                              );
                        }
                        setModal(appActivationStep1Modals.FOOTNOTES);
                      }}
                    >
                      <u>{t('sh_apoc_tarif_overview_text6')}</u>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div> */}
          </div>

          {modal === appActivationStep1Modals.TARIFF_DETAILS && (
            <TariffDetailsModal setModal={setModal} />
          )}

          {modal === appActivationStep1Modals.MGM_CODE && (
            <MGMCodeModal
              {...{
                isLoading,
                setModal,
                handleChange,
                handleBlur,
                codeForValidation,
                touched,
                errors,
                setErrors,
                setTouched,
                applyMGMCode
              }}
            />
          )}

          {modal === appActivationStep1Modals.FOOTNOTES && <FootNotesModal setModal={setModal} />}
          {modal === appActivationStep1Modals.NEW_FOOTNOTES && (
            <NewFootNotesModal setModal={setModal} />
          )}
          {modal === appActivationStep1Modals.NEW_TARIFF_MODAL && (
            <NewTariffModal setModal={setModal} />
          )}
        </>
      )}
    </>
  );
}

/* YourTariff.propTypes = {
            activeTariff: PropTypes.shape({ }),
};
          YourTariff.defaultProps = {
            activeTariff: { },
}; */

export default YourTariff;
