// import { appRoute as commonRoutes } from '@dom-digital-online-media/dom-app-config-sdk';

import {
  appRegex as _appRegex,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_FIELD_VALIDATION_TYPE,
  appStorage as AppStorage
} from '@dom-digital-online-media/dom-app-config-sdk';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';

// Tariff ids
export const appTariff = {
  FLAT_L: 6500,
  FLAT_M: 6501
};

// Extend routes
export const appRoute = {
  // Public Routes
  // ...commonRoutes,
  TEST: '/test',
  TEST2: '/test2',
  HOME: '/',
  NEW_ONLINE_CUSTOMER: '/checkout',
  ACTIVATION: '/aktivieren/kartendaten/',
  ACTIVATION_WELCOME: '/willkommen',
  NOT_FOUND: '/404',
  DEBUG: '/debug',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  CANCEL_CONTRACT: '/vertrag-jetzt-kuendigen',
  CANCEL_CONTRACT_SUCCESS: '/vertrag-jetzt-kuendigen-success',

  PRIVACY: '/datenschutz',
  CANCELLATION_RIGHTS: '/widerruf',
  MANDATORY_DISCLOSURE: '/pflichtangaben',
  SHARING: '/for-friends',
  WHY_SHARE_MOBILE: '/ueber-share-mobile',
  MAINTENANCE: '/mps-maintenance',
  GENERIC_GREETINGS: '/projekt',
  ACTIVATE_SIM_CARD: '/aktivieren',
  LEGITIMACY_SUCCESS: '/legitimacy-success',
  LEGITIMACY_FAILED: '/legitimacy-failed',
  CONTACT: '/hilfe-service/kontakt',
  GIVEAWAY: '/giveaway',
  ROAMING: '/hilfe-service/roaming',
  AGB: '/agb',
  AGB_INSTRUCTIONS: '/agb/hinweise-zu-batterien-und-altgeraeten',
  IMPRINT: '/impressum',
  CUSOMER_INFORMATION: '/verbraucherinformationen',
  REDIRECT: (url) => (url ? `/redirect?target=${url}` : '/redirect'),

  // Private Routes
  DASHBOARD: '/dashboard',
  CREDIT_DETAILS: '/credit-details',
  RECHARGE: '/credit-details/recharge',
  USAGE_DETAILS: '/usage-details',
  USER_ACCOUNT: '/user/account',
  CHARGES: '/user/charges',
  PAYMENT_CALLBACK: '/payment',

  REFER_FRIEND: '/refer-friend',
  EVN: '/evn',
  SIM_CARD_E_SIM: '/sim-card-esim',

  TARIFF_OPTION: '/tariff-options',
  TARIFF_CHANGE: '/tariff-change',
  // TARIFF_CHANGE: (id) => (id ? `/tariff/change/${id}` : '/tariff/change/:tariffId'),

  OPTION_BOOK: (id) => (id ? `/option/book/${id}` : '/option/book/:optionId'),
  SPEEDON_BOOK: (id) => (id ? `/speedon/book/${id}` : '/speedon/book/:speedonId'),
  PASSOFFER_BOOK: (passCode) =>
    passCode ? `/passoffer/book/${passCode}` : '/passoffer/book/:passCode',
  DATAPASS_BOOK: (id) => (id ? `/datapass/book/${id}` : '/datapass/book/:datapassId'),
  ROAMING_BOOK: (id) => (id ? `/roaming/book/${id}` : '/roaming/book/:roamingId'),

  // Static Pages
  DOWNLOAD_APP: '/app-download',
  TOPUP_CREDIT: '/aufladen',

  IMPRINT: '/impressum',
  AGB: '/agb',
  CUS_INFO: '/verbraucherinformationen',
  DATA_PROT: '/datenschutz',
  REVOCATION: '/widerruf',
  CANCEL_CONTRACT: '/vertrag-jetzt-kuendigen',

  FAQ_LIST: '/hilfe-service/faq',
  FAQ_TOPIC: (id) =>
    id ? `/hilfe-service/faq/uebersicht?categoryId=${id}` : '/hilfe-service/faq/uebersicht',
  FAQ_DETAILS: (categoryName) =>
    categoryName ? `/hilfe-service/faq/${categoryName}` : '/hilfe-service/faq/:categoryName',

  HELP_AND_SERVICE: '/hilfe-service',

  IDENT_SUCCESS: '/aktivieren/ident-erfolgreich',
  IDENT_PENDING: '/aktivieren/ident-ausstehend',
  IDENT_FAILED: '/aktivieren/ident-fehlgeschlagen'
};
export const storageKeys = {
  // ...commonRoutes,
  CODE: 'code',
  SESSION_STATE: 'session_state'
};

export const appStorage = {
  ...AppStorage,
  CART_NAME: 'cartName',
  ORDER_NUMBER: 'orderNumber',
  ACTIVATION_INFO: 'activation-info',
  ACTIVATION_FLOW_TYPE: 'activation-flow-type',
  NEW_ONLINE_ACTIVATION_DATA: 'new-online-info',
  AFFILIATE_DATA: 'affiliate-data',
  STORAGE_TIME_LEFT: 'storage-time-left',
  RECHARGE_OPTIONS: 'recharge-options',
  POSTIDENT: 'post-ident',
  ACTIVATION_DATA: 'activation-data',
  SIM_PROVIDERS: 'sim-providers',
  POSTPAID_NUMBERS: 'postpaid-numbers'
};

export const appActivationFlowType = {
  NEW_ONLINE: 'new-online',
  SIM_ACTIVATION: 'sim-activation'
};

// App alert types
export const appAlert = {
  DEFAUT_TIMEOUT: 4000,
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark',
  SUCCESS: 'success',
  ERROR: 'danger'
};

// App alert types
export const appDefaultAlertMessage = {
  SUCCESS: 'success',
  ERROR: 'danger',
  ERROR_NOT_FOUND: 'Not able to found your last request',
  ERROR_UNAUTH: 'Please login to continue!',
  INFO: 'info',
  WARNING: 'warning'
};

export const appIcons = {
  HOME: 'Home',
  DASHBOARD: 'Dashboard',
  RADIO_ACTIVE: 'RadioActive'
};

export const appIconsPath = {
  HOME: '/assets/images/icons/home.svg'
};

export const deviceRegex = {
  ISIOS: /iPhone|iPad|iPod/i,
  ISANDRIOD: /Android/i
};

export const appRegex = {
  ..._appRegex,
  validatePwd:
    /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-])|(?=.*[a-z])(?=.*[A-Z])(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-]))([\x21-\xFF]){12,100}$/,
  validatePwdStringLength: /^.{12,100}$/,
  validatePwdUpperOrLowerCase: /(?=.*[a-zA-Z])/,
  validatePwdNumbers: /(?=.*\d)/,
  validatePwdSpecialChar: /(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-])/
};

export const formValidation = ({
  type = DEFAULT_FIELD_VALIDATION_TYPE.STRING, //  for which type of validation required
  required = false, // for for required validations
  minLength = false, // provide min length if required
  minLengthError = DEFAULT_ERROR_MESSAGE.fieldValidation.MIN_LENGTH,
  maxLength = false, // provide min length if required
  maxLengthError = DEFAULT_ERROR_MESSAGE.fieldValidation.MAX_LENGTH,
  regex = '', // regex if you want to do matching validation
  errorMessage = DEFAULT_ERROR_MESSAGE.fieldValidation.REQUIRED, // for addtional sub types of valdation which can required messages other than required.
  validErrorMessage = DEFAULT_ERROR_MESSAGE.fieldValidation.INVALID
}) => {
  // Assign the yup schema
  // Default willl be considered as string
  let valid = {};
  switch (type) {
    case DEFAULT_FIELD_VALIDATION_TYPE.ARRAY:
      valid = yup.array();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.BOOLEAN:
      valid = yup.boolean();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.DATE:
      valid = yup.date();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.MIXED:
      valid = yup.mixed();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.NUMBER:
      valid = yup.number();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.OBJECT:
      valid = yup.object();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.STRING:
      valid = yup.string();
      break;
    default:
      valid = yup.string();
      break;
  }

  // Check if required and set error message for it.
  if (required && typeof required === 'string') {
    valid = valid.required(required);
  }

  // Check if minimum length and set error message for it.
  if (minLength) {
    valid = valid.min(minLength, minLengthError);
  }

  // Check if maximum length and set error message for it.
  if (maxLength) {
    valid = valid.max(maxLength, maxLengthError);
  }

  // Check if type is email
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.EMAIL) {
    valid = valid.email(errorMessage);
  }

  // Check if type is url
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.URL) {
    valid = valid.url(errorMessage);
  }

  // Check if type is lowe_case
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.LOWER_CASE) {
    valid = valid.lowercase(errorMessage);
  }

  // Check if type is upper_case
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.UPPER_CASE) {
    valid = valid.uppercase(errorMessage);
  }

  // Check if regex is passed throw invalid error message.
  if (regex) {
    valid = valid.matches(regex, validErrorMessage);
  }

  return valid;
};

export const inputValidation = {
  VALID: 'valid',
  INVALID: 'invalid',

  MIN_ERROR: 'min',
  MAX_ERROR: 'max',

  MIN_MAX_ERROR: 'minMax',

  UPPER_CASE_ERROR: 'uppercase',
  LOWER_CASE_ERROR: 'lowecase',

  NUMBER_ERROR: 'number',
  SPECIAL_CASE_ERROR: 'specific',

  MATCH: 'match'
};

// String Length Validations
export function validateStringLength(string, min = 12, max = 64) {
  return `${string}`.length >= min && `${string}`.length <= max;
  // return (
  //   appRegex.validateString.test(string) && `${string}`.length >= min && `${string}`.length <= max
  // );
}

// String Minimum Length Validations
export function validateMinStringLength(string, min = 12) {
  return `${string}`.length >= min;
}

// UpperCase Validations
export function validateUpperCase(string) {
  return appRegex.validateUpperCase.test(string);
}

// LowerCase Validations
export function validateLowerCase(string) {
  return appRegex.validateLowerCase.test(string);
}

// Number Validations
export function validateNumber(string) {
  return appRegex.validateNumbers.test(string);
}

// Special Validations
export function validateSpecial(string) {
  return /(?=.*[!"§$%&#])/.test(string);
  // return appRegex.validateSpecial.test(string);
}

// Mobile Special Validations
export function validateMobileSpecial(string) {
  return /(?=.*[!$%&#])/.test(string);
  // return appRegex.validateSpecial.test(string);
}

// Password Validation
export function validatePassword(values) {
  // string, controlName, password

  const errors = Object.keys(values).map((controlName) => {
    const string = values[controlName];
    if (!string) {
      return {
        [controlName]: 'required'
      };
    }

    const hasLength = validateStringLength(values.newPassword, 12, 20);
    const hasUpperCase = validateUpperCase(values.newPassword);
    const hasLowerCase = validateLowerCase(values.newPassword);
    const hasNumber = validateNumber(values.newPassword);
    const hasSpecial = validateSpecial(values.newPassword);

    if (!hasLength || !hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecial) {
      return {
        otherErrors: {
          [inputValidation.MIN_MAX_ERROR]: hasLength,
          [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
          [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
          [inputValidation.NUMBER_ERROR]: hasNumber,
          [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
          [inputValidation.MATCH]: values.newPassword === values.confirmPassword
        }
      };
    }

    if (
      (values.newPassword || values.confirmPassword) &&
      values.newPassword !== values.confirmPassword
    ) {
      return { confirmPassword: 'match_error' };
    }

    return {};
  });
  return Object.assign(...errors);
  // let errors = {};
  // Object.keys(values).forEach((controlName) => {
  //   const string = values[controlName];

  //   if (!string) {
  //     errors = {
  //       ...errors,
  //       [controlName]: 'required'
  //     }
  //     return errors;
  //   }
  //   if (controlName === 'confirmPassword') {
  //     errors = {
  //       confirmPassword: values.newPassword === string ? false : "DO_NOT_MATCH"
  //     }
  //     return errors;
  //   }

  //   const hasLength = validateStringLength(string, 8, 20);
  //   const hasUpperCase = validateUpperCase(string);
  //   const hasLowerCase = validateLowerCase(string);
  //   const hasNumber = validateNumber(string);
  //   const hasSpecial = validateSpecial(string);
  //   if (!hasLength) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasLength error"
  //     }
  //     return errors;
  //   }
  //   if (!hasUpperCase) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasUpperCase error"
  //     }
  //     return errors;
  //   }
  //   if (!hasLowerCase) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasLowerCase error"
  //     }
  //     return errors;
  //   }
  //   if (!hasNumber) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasNumber error"
  //     }
  //     return errors;
  //   }
  //   if (!hasSpecial) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasSpecial error"
  //     }
  //     return errors;
  //   }
  //   console.log(values, errors, {
  //     hasLength,
  //     hasUpperCase,
  //     hasLowerCase,
  //     hasNumber,
  //     hasSpecial,
  //   })

  //   // errors = {
  //   //   ...errors,
  //   //   [controlName]: hasLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecial
  //   //     ? false // inputValidation.VALID
  //   //     : hasLength || hasUpperCase || hasLowerCase || hasNumber || hasSpecial,

  //   //   [inputValidation.MIN_MAX_ERROR]: hasLength,
  //   //   [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
  //   //   [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
  //   //   [inputValidation.NUMBER_ERROR]: hasNumber,
  //   //   [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
  //   // }
  //   return errors;
  // })
  // return errors;
  // Confirm password validation
  // if (controlName === 'confirmPassword') {
  //   return {
  //     valid:
  //       password && string && password === string ? inputValidation.VALID : inputValidation.INVALID,
  //   };
  // }

  // const hasLength = validateStringLength(string, 8, 20);
  // const hasUpperCase = validateUpperCase(string);
  // const hasLowerCase = validateLowerCase(string);
  // const hasNumber = validateNumber(string);
  // const hasSpecial = validateSpecial(string);

  // return {
  //   error: {
  //     [controlName]: hasLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecial
  //       ? false // inputValidation.VALID
  //       : hasLength || hasUpperCase || hasLowerCase || hasNumber || hasSpecial,

  //     [inputValidation.MIN_MAX_ERROR]: hasLength,
  //     [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
  //     [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
  //     [inputValidation.NUMBER_ERROR]: hasNumber,
  //     [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
  //   }
  // };
}

// Password Validation
export function validatePasswordWithKeys(values, firstPasswordKey) {
  // string, controlName, password

  const errors = Object.keys(values).map((controlName) => {
    const string = values[controlName];
    if (!string) {
      return {
        [controlName]: 'required'
      };
    }

    const hasLength = validateStringLength(values[firstPasswordKey], 8, 16);
    const hasUpperCase = validateUpperCase(values[firstPasswordKey]);
    const hasLowerCase = validateLowerCase(values[firstPasswordKey]);
    const hasNumber = validateNumber(values[firstPasswordKey]);
    const hasSpecial = validateSpecial(values[firstPasswordKey]);

    if (!hasLength || !hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecial) {
      return {
        otherErrors: {
          [inputValidation.MIN_MAX_ERROR]: hasLength,
          [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
          [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
          [inputValidation.NUMBER_ERROR]: hasNumber,
          [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial
          //  [inputValidation.MATCH]: values[firstPasswordKey] === values[confirmPasswordKey]
        }
      };
    }

    /* if (
      (values[firstPasswordKey] || values[confirmPasswordKey]) &&
      values[firstPasswordKey] !== values[confirmPasswordKey]
    ) {
      return { [confirmPasswordKey]: 'match_error' };
    } */

    return {};
  });
  return Object.assign(...errors);
}
export const gettingNumberForGerman = (number = '') => {
  let num = number;
  if (num.charAt(0) === 0) {
    num = num.substring(1);
  }
  return `+49${num}`;
};

export const appAutoTopUpType = {
  UNIQUE: 'UNIQUE',
  AUTOMATIC: 'AUTOMATIC'
};

export const appAutoTopUpPeriodType = {
  LOW_BALANCE: 'LOW_BALANCE',
  ONCE_PER_MONTH: 'ONCE_PER_MONTH',
  RATE: 'FIXED_RATE'
};

export const appPaymentProductType = {
  // Currently using
  RECURRING: 'RECURRING',
  LOW_BALANCE: 'LOW_BALANCE',
  DIRECT: 'DIRECT',

  // Extended
  ALL: 'ALL',
  PREPAID: 'PREPAID',
  TOPUP: 'TOPUP',
  CASH_CODE: 'CASH_CODE',
  VOUCHER: 'VOUCHER'
};

export const appOptionsGroupIDs = {
  TARIFF_OPTION: [350],
  DATA_OPTIONS: [115, 352],
  DAYFLATS: [151],
  SPEED_ONS_PASSES: [152],
  ROAMING: {
    LANDER_ZONE_1: [153],
    LANDER_ZONE_2: [154],
    LANDER_ZONE_3: [155]
  },
  OTHER: [156] // Sprach-Optionen
};

// MO Customer Tariff Options & Usage Status
// https://rm3.dom.de/projects/cnet/wiki/Customer_Tariff_Options_&_Usage_Status

export const appCustomerStatus = {
  NEW: 0,
  ACTIVE: 1,
  CANCELLED: 2,
  NEW_INREALISATION: 3,
  INACTIVE: 4,
  IN_CANCELLATION: 5,
  MNP2: 6,
  MNP: 7,
  MARKED_DELETED: 8
};

export const appTariffStatus = {
  ACTIVATION_PENDING: 0,
  ACTIVE: 1,
  REJECTED: 2,
  LOCKED: 3,
  LOCK_REQUESTED: 4,
  DEACTIVATION_REQUESTED: 5,
  PAUSED: 6,
  TERMINATION_REQUESTED: 7,
  UNLOCK_REQUESTED: 8,
  TERMINATED: 9,
  ACTIVATION_REQUESTED: 10,
  SENT: 11,
  CANCELLATION_REQUESTED: 12,
  CANCELLED: 13,
  NOT_AVAILABLE: 14,
  DEFECT: 15,
  EXPORTED: 16,
  IN_CHANGE: 17,
  UNKNOWN: 18
};
// Find Tariff Status Key
export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const getTariffStatusKey = (statusId) => getKeyByValue(appTariffStatus, statusId);

export const appOptionStatus = {
  ACTIVATION_PENDING: 0,
  ACTIVE: 1,
  REJECTED: 2,
  LOCKED: 3,
  LOCK_REQUESTED: 4,
  DEACTIVATION_REQUESTED: 5,
  PAUSED: 6,
  TERMINATION_REQUESTED: 7,
  UNLOCK_REQUESTED: 8,
  TERMINATED: 9,
  ACTIVATION_REQUESTED: 10,
  SENT: 11,
  CANCELLATION_REQUESTED: 12,
  CANCELLED: 13,
  NOT_AVAILABLE: 14,
  DEFECT: 15,
  EXPORTED: 16,
  IN_CHANGE: 17,
  UNKNOWN: 18
};

export const appUsageType = {
  VOICE: 'VOICE',
  DATA: 'DATA',
  SMS: 'SMS',
  DATA_UNL: 'DATA_UNL',
  VOICE_UNL: 'VOICE_UNL',
  SMS_UNL: 'SMS_UNL',
  MEASUREMENT: 'MEASUREMENT'
};

export const appUsageMeasurementsUnits = {
  MB: 'MB',
  GB: 'GB',
  MIN: 'MIN',
  SMS: 'SMS'
};

// TDM API response type
export const internetDataUnit = {
  GB: 'GB',
  MB: 'MB',
  KB: 'KB'
};

// Method to get data in GB from required unit
export const formatBytes = (data, units) => {
  const marker = 1024; // Change to 1000 if required
  const decimal = 1; // Change as required
  if (units === internetDataUnit.KB) {
    return (data / (marker * marker)).toFixed(decimal);
  }
  if (units === internetDataUnit.MB) {
    return (data / marker).toFixed(decimal);
  }
  if (units === internetDataUnit.GB) {
    return data;
  }
  return data;
};

export const getMaximumValue = ({
  initialValue,
  remainingValue,
  initialUnit,
  remainingUnit,
  ...restData
}) => {
  let maxValue = restData != null && initialValue != null ? initialValue : 0;
  let currentValue = restData != null && remainingValue != null ? remainingValue : 0;

  if (restData != null && initialValue != null && remainingValue != null) {
    if (initialUnit.includes(internetDataUnit.GB) && remainingUnit.includes(internetDataUnit.GB)) {
      maxValue = parseFloat(maxValue);
      currentValue = parseFloat(currentValue);
    } else if (
      initialUnit.includes(internetDataUnit.MB) &&
      remainingUnit.includes(internetDataUnit.MB)
    ) {
      maxValue = formatBytes(parseFloat(maxValue), internetDataUnit.MB);
      currentValue = formatBytes(parseFloat(currentValue), internetDataUnit.MB);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (initialUnit.includes(internetDataUnit.GB)) {
        maxValue = formatBytes(parseFloat(maxValue), internetDataUnit.GB);
        currentValue = formatBytes(parseFloat(currentValue), internetDataUnit.MB);
      } else {
        maxValue = formatBytes(parseFloat(maxValue), internetDataUnit.MB);
        currentValue = formatBytes(parseFloat(currentValue), internetDataUnit.GB);
      }
    }
  }

  return {
    maximumValue: maxValue,
    currentValue
  };
};

export const progressPrecentageValue = (minValue, maxValue) => (minValue / maxValue) * 100;

// This constant is used in the e-Sim Modal and currently using this constant.
export const deviceListing = [
  {
    id: 1,
    title: 'sh_apoc_eSIM_option1',
    devices: 'sh_apoc_eSIM_devices_apple'
  },
  {
    id: 2,
    title: 'sh_apoc_eSIM_option2',
    devices: 'sh_apoc_eSIM_devices_samsung'
  },
  {
    id: 3,
    title: 'sh_apoc_eSIM_option3',
    devices: 'sh_apoc_eSIM_devices_google'
  },
  {
    id: 4,
    title: 'sh_apoc_eSIM_option4',
    devices: 'sh_apoc_eSIM_devices_other'
  }
];

// Ex scripts
export const appDynamicScripts = {
  TEALIUM_UTAG: (domain, account, profile, environment = 'dev') =>
    `https://${domain}/utag/${account}/${profile}/${environment}/utag.js`
};

// Reasons for contract cancellations
export const contractCancellationReasons = [
  'sh_login_cancel_reason_op1',
  'sh_login_cancel_reason_op2',
  'sh_login_cancel_reason_op3',
  'sh_login_cancel_reason_op4',
  'sh_login_cancel_reason_op5',
  'sh_login_cancel_reason_op6',
  'sh_login_cancel_reason_op7',
  'sh_login_cancel_reason_op8',
  'sh_login_cancel_reason_op9'
];

// App query parameters
export const appQueryParams = {
  APP_WEB_VIEW: 'appview',
  AFFILIATE: {
    PARTNER_ID: 'sp',
    SPEICAL_REPORT_ID: 'spfr',
    CLICK_REF: 'clickref',
    CGM: 'cgm',
    DC_LID: 'dclid',
    GC_LID: 'gclid'
  }
};

// Hooks for getting url query parameters
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Static Pdf
export const appStaticPdf = {
  CANCELLATION_RIGHTS: 'Widerrufsrecht',
  ORDER_ITEMIZED_BILL: 'Auftrag Einzelverbindungsnachweis',
  AGB_SERVICES: 'AGB_serviceDocumentsTermsAndConditions',
  AGB_ARCHIVE: 'AGB_Archive_serviceDocumentsProductsheets',
  DATA_PRIVACY: 'Datenschutz_serviceDocumentsPrivacy',
  PRODUCT_INFO: 'Produktinformationsblatt'
};

export const appActivationStep1Modals = {
  NONE: '',
  FOOTNOTES: 'footnotes',
  NEW_FOOTNOTES: 'newFootnotes',
  MGM_CODE: 'mgm_code',
  PRICE_DETAILS: 'priceDetails',
  TARIFF_DETAILS: 'tariffDetails',
  NEW_TARIFF_MODAL: 'newTariffModal',
  PINK_TARIFF_BUBBLE_MODAL: 'pinkTariffBubbleModal'
};

export const homePageModals = {
  PINK_TEASER_MODAL: 'pinkBoxModal',
  YELLOW_TEASER_FOOTNOTE: 'yellowTeaserFootnote'
};

export const appActivationStep2Modals = {
  NONE: '',
  NEW_NUMBER: 'newNumbers',
  OLD_NUMBER_PROVIDER: 'oldNumberProvider',
  CURRENT_NUMBER: 'currentNumber',
  START_WITH_OLD_NUMBER: 'startWithOldNumber',

  FOOTNOTES: 'footnotes',
  ESIM_INFO: 'esimInfo',
  ESIM_BRAND_SELECTION: 'esimBrandSelect',
  ESIM_BRAND_DEVICES: 'esimBrandDevices'
};

export const appActivationStep2Screens = {
  PHONE_MNP_NEW_SELECTION: 'phone_number_porting',
  SIM_ESIM_SELECTION: 'sim_esim'
};

export const roamingModals = {
  DEACTIVATE_SWITZERLAND_ROAMING: 'deactivate_roaming_switzerland_near_border',
  DEACTIVATE_WLAN_CALLS: 'deactivate_wlan_calls',
  LANDER_ZONE: 'lander-zone',
  ESIM: 'esim'
};

export const appeSimDeviceListing = [
  {
    id: 1,
    title: 'sh_apoc_eSIM_option1',
    devices: 'sh_apoc_eSIM_devices_apple',
    toggle: true,
    showTitleOnDetail: true
  },
  {
    id: 2,
    title: 'sh_apoc_eSIM_option2',
    devices: 'sh_apoc_eSIM_devices_samsung',
    toggle: true,
    showTitleOnDetail: true
  },
  {
    id: 3,
    title: 'sh_apoc_eSIM_option3',
    devices: 'sh_apoc_eSIM_devices_google',
    toggle: true,
    showTitleOnDetail: true
  },
  {
    id: 4,
    title: 'sh_apoc_eSIM_option4',
    devices: 'sh_apoc_eSIM_devices_other',
    toggle: true,
    showTitleOnDetail: false
  }
];

export const appButtonType = {
  PRIMARY: 'btn btn-primary',
  SECONDARY: 'btn btn-secondary',
  DANGER: 'btn btn-danger',
  LINK: 'btn btn-link',
  DISABLED: 'btn btn-disabled',
  OUTLINE_SECONDARY: 'btn btn-outline-secondary'
};

export const appImages = {
  splashScreenLogo: '/assets/images/splash-screen-logo-default.svg',
  splashScreenDarkLogo: '/assets/images/splash-screen-logo-variant-2.svg',
  background: '/assets/images/password-visible.svg',
  InfoIcon: '/assets/images/info-purple-icon.svg',
  InfoIconWhite: '/assets/images/info-icon.svg',
  androidapp: '/assets/images/android-app.svg',
  iosapp: '/assets/images/ios-app.svg',
  qrcode: '/assets/images/qr-code.svg',
  simcard_activations: '/assets/images/sim-card-activation.svg',
  simcard_activations_m: '/assets/images/sim-card-activation-m.svg',
  SIMKartennummer: '/assets/images/sim-card.svg',
  sharemobileapp: '/assets/images/share-mob-app.svg',
  sharemobileapp_m: '/assets/images/share-mob-app-m.svg',
  footnote: '/assets/images/footnote.svg',
  newfootnote: '/assets/images/sh_icon_note.svg',

  project_btmbanner: '/assets/images/project-banner.jpg',
  project_details: '/assets/images/project-detail.svg',
  project_detailsmobile: '/assets/images/project-detail-mobile.svg',
  project_phone_d: '/assets/images/project-phone-d.svg',
  project_phone_d_m: '/assets/images/project-phone-d-m.svg',
  project_btmbannermobile: '/assets/images/project-banner-mobile.jpg',

  dein_Tarif: '/assets/images/share-general-project.svg',
  dein_Tarif_m: '/assets/images/share-general-project_m.svg',
  pink_gray_bubble: '/assets/images/pink-gray-bubble.svg',
  Home_network: '/assets/images/network-quality-img.svg',
  Home_victory: '/assets/images/victory-img.svg',
  Home_heart: '/assets/images/heart-img.svg',
  Home_share_overview: '/assets/images/share-app-overview.svg',

  bubbleOuter: '/assets/images/bubble outer.svg',
  freedom: '/assets/images/freedom v1.svg',
  globalFreedom: '/assets/images/global-freedom v1.svg',

  forfriend_topbanner: '/assets/images/for-friend-top-bnr.jpg',
  forfriend_bubble: '/assets/images/friends-bubble.svg',
  forfriend_heartic: '/assets/images/friends-heart-icon.svg',
  forfriend_education: '/assets/images/digital-education-img.jpg',
  forfriend_howitdoneic1: '/assets/images/how-it-done-icon1.svg',
  forfriend_howitdoneic2: '/assets/images/how-it-done-icon2.svg',
  forfriend_howitdoneic3: '/assets/images/how-it-done-icon3.svg',

  sharemobileappaufladen: '/assets/images/share-mob-app-aufladen.svg',
  sharemobileappaufladen_m: '/assets/images/share-mob-app-aufladen-m.svg',
  rewelogo: '/assets/images/rewe-logo.svg',
  dmlogo: '/assets/images/dm-logo.svg',
  rt_arw: '/assets/images/rt-arw.svg',

  shareStage: '/assets/images/share-about-stage.svg',
  aboutSmartphone: '/assets/images/share-about-smartphone-d.svg',
  aboutSmartphoneMobile: '/assets/images/share-about-smartphone-m.svg',
  congstarLogo: '/assets/images/congstar-logo.svg',
  congstarResponsiveLogo: '/assets/images/congstar-responsive-logo.svg',
  congstarLogoPoweredBy: '/assets/images/sh_logo_powered_by_congstar.svg',
  congstarResponsiveLogoPoweredBy: '/assets/images/sh_logo_powered_by_congstar_md.svg',
  logowhite: '/assets/images/logo-w.svg',
  logodark: '/assets/images/logo-d.svg',

  logowhite: '/assets/images/sh_logo_share_mobile-d.svg',
  logodark: '/assets/images/sh_logo_share_mobile.svg',

  humbermenuwhite: '/assets/images/humbergmenu-white.svg',
  humbermenudark: '/assets/images/humbergmenu-dark.svg',
  closemenudark: '/assets/images/close-ic.svg',
  modalclose: '/assets/images/modal-close.svg',
  modalback: '/assets/images/modal-back.svg',

  CongStarWhite: '/assets/images/congstar-logo-w.svg',
  CongStarWhite02: '/assets/images/congstar-logo-2.svg',

  // tariffBubble: '/assets/images/sh_new_stoerer_pink_4mehr.svg',
  // yellowTeaser: '/assets/images/sh_new_yellow_teaser.svg',
  yellowTeaser: '/assets/images/sh_home_stoerer_03-06.svg',
  // newPinkTeaser: '/assets/images/sh_step2-pink_box.svg',
  newPinkTeaser: '/assets/images/sh_step2-pink_box_2.svg',
  onlinePosPinkTeaser: '/assets/images/sh_pos_pink_teaser.svg',
  action: '/assets/images/sh-icon-action.svg',
  leftangle: '/assets/images/icon-left-angle.svg',
  rightangle: '/assets/images/Icon-right-angle.svg'
};

export const appSecTopElementTypes = {
  default: false,
  TYPE_1: '1',
  TYPE_2: '2',
  TYPE_3: '3',
  TYPE_4: '4',
  TYPE_5: '5',
  TYPE_6: '6',
  TYPE_7: '7',
  TYPE_8: '8',
  TYPE_9: '9'
};

export const appSecBottomElementTypes = {
  default: false,
  TYPE_1: '1',
  TYPE_2: '2',
  TYPE_3: '3',
  TYPE_4: '4',
  TYPE_5: '5',
  TYPE_6: '6',
  TYPE_7: '7',
  TYPE_8: '8',
  TYPE_9: '9'
};

export const appSecBgColorTypes = {
  default: 'transprent',
  PRIMARY: 'Purple', // '#534164',
  SECONDARY: 'Mango', // '#534164',
  WHITE: 'White', // '#FFFFFF',
  OFFWHITE: 'off-white' // '#EFECE8'
};

export const REDIRECT_TIME_OUT = 20000;

export const cancelContractReason = [
  // {
  //   id: 1,
  //   value: 'sh_login_cancel_reason_op1'
  // },
  {
    id: 2,
    value: 'sh_login_cancel_reason_op2'
  },
  {
    id: 3,
    value: 'sh_login_cancel_reason_op3'
  },
  {
    id: 4,
    value: 'sh_login_cancel_reason_op4'
  },
  {
    id: 5,
    value: 'sh_login_cancel_reason_op5'
  },
  {
    id: 6,
    value: 'sh_login_cancel_reason_op6'
  },
  {
    id: 7,
    value: 'sh_login_cancel_reason_op7'
  },
  {
    id: 8,
    value: 'sh_login_cancel_reason_op8'
  },
  {
    id: 9,
    value: 'sh_login_cancel_reason_op9'
  }
];

export const appBreakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};

export const findBreakPoint = (screenSize) => {
  if (screenSize >= appBreakPoints.xxl) {
    return 'xxl';
  }
  if (screenSize >= appBreakPoints.xl) {
    return 'xl';
  }
  if (screenSize >= appBreakPoints.lg) {
    return 'lg';
  }
  if (screenSize >= appBreakPoints.md) {
    return 'md';
  }
  if (screenSize >= appBreakPoints.sm) {
    return 'sm';
  }
  if (screenSize >= appBreakPoints.xs) {
    return 'xs';
  }
};
export const appSortOrder = {
  FAQ_MAIN_CAT_LIST: 'FAQ_mainPage_categories',
  FAQ_MAIN_TOP_QNA: 'FAQ_mainPage_topLevel_qna'
};

export const appPaymentMethods = {
  PAYPAL: 'paypal',
  CREDIT_CARD: 'creditcard_braintree',
  GOOGLE_PAY: 'googlepay_braintree',
  APPLE_PAY: 'applepay_braintree'
};

export const appPaymentStaticData = {
  description: 'Erteilung einer Einzugsermächtigung für Share Mobile'
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const getFaqLinkId = (title) => {
  const faqTitle = `faq-${title
    .replaceAll(' ', '-')
    .replaceAll(',', '-')
    .replaceAll('?', '')
    .toLowerCase()}-oeffnen`;
  return faqTitle;
};

export default {
  appRoute,
  storageKeys,
  appAlert,
  deviceRegex,
  appDefaultAlertMessage,
  deviceListing,
  gettingNumberForGerman,
  contractCancellationReasons,

  progressPrecentageValue,
  getMaximumValue,

  appQueryParams,
  useQuery,

  appStaticPdf
};
