import React, { Fragment, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useEmailVerification } from '@context/MobileOne/Activation/EmailVerification';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button, Text } from '@core/Inputs';
import { useActivation } from '@context/MobileOne';
import {
  appActivationFlowType,
  appActivationStep2Screens,
  appButtonType
} from '@utils/globalConstant';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';
import './style.scss';

export function EmailSend() {
  const { initialEmail, validationSchemaEmail, validateEmail, isLoading, verifyEmail } =
    useEmailVerification();
  const { activationInfo, prevStep, activationType, setNumberPortingScreen } = useActivation();

  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.EMAIL_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.EMAIL_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
      if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.EMAIL_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.EMAIL_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  const { t } = useStaticContent();
  return (
    <Fragment>
      <div className="container">
        <div className="row position-relative">
          {/* <div className="col-12 orange-gray-bubble"></div> */}
          <div className="col-md-3 pb-5 mt-5">
            <div className="back-btn">
              <a
                className="text-white body-medium-bold text-decoration-none link-light"
                onClick={() => {
                  if (window && window.utag) {
                    activationType === appActivationFlowType.NEW_ONLINE
                      ? window.utag.link(UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.EMAIL.BACK_ON_CLICK)
                      : window.utag.link(UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.EMAIL.BACK_ON_CLICK);
                  }

                  if (activationType === appActivationFlowType.NEW_ONLINE) {
                    setNumberPortingScreen(appActivationStep2Screens.SIM_ESIM_SELECTION);
                  }
                  prevStep();
                }}
              >
                <i className="fa fa-arrow-left me-3"></i>
                {t('sh_back_button')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-sm-5">
        <div className="row">
          <div className="col-12 col-md-11 offset-md-1 col-lg-7 offset-lg-1 mb-5 mb-sm-0">
            <Formik
              initialValues={{ ...initialEmail }}
              validationSchema={validationSchemaEmail}
              validate={validateEmail}
              onSubmit={(values) => {
                if (window && window.utag) {
                  activationType === appActivationFlowType.NEW_ONLINE
                    ? window.utag.link(
                        UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.EMAIL.REQUEST_CODE_ON_CLICK
                      )
                    : window.utag.link(
                        UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.EMAIL.REQUEST_CODE_ON_CLICK
                      );
                }
                verifyEmail(values);
              }}
            >
              {({ values: { email }, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <h6 className="text-uppercase text-white">
                    {t(
                      activationType === appActivationFlowType.SIM_ACTIVATION
                        ? 'sh_apoc_step4/9'
                        : 'sh_apoc_email_step3'
                    )}
                  </h6>
                  <h1 className="pink-color-text mt-2 mt-sm-2 mb-sm-5">{t('sh_apoc_email_h1')}</h1>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-8 col-lg-9">
                      <p
                        className="text-white body-large-regular body-large-20 mt-3 mt-sm-0"
                        dangerouslySetInnerHTML={{ __html: t('sh_apoc_email_text1') }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 mt-3">
                      <Text
                        type="email"
                        placeholder={t('sh_apoc_email_input')}
                        id="email"
                        name="email"
                        onChange={handleChange}
                        value={email}
                        // isValid={touched.cscPassword && !errors.cscPassword}
                        isInvalid={!!touched.email && !!errors.email}
                        onBlur={handleBlur}
                        error={touched.email && errors.email ? errors.email : null}
                      />
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-sm-12 col-md-6">
                      <Button
                        buttonType={appButtonType.PRIMARY}
                        className="w-100 bg-offWhite-color"
                        type="submit"
                        isLoading={isLoading}
                      >
                        {t('sh_apoc_email_code_button')}
                      </Button>
                    </div>
                    <p
                      className="body-medium-regular offWhite-color-text font-13 mt-4"
                      dangerouslySetInnerHTML={{ __html: t('sh_apoc_email_text2') }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default EmailSend;
