import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Modal } from '@core/Utils';
import {
  appActivationFlowType,
  appActivationStep2Modals,
  appeSimDeviceListing,
  mobileBrands
} from '@utils/globalConstant';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';
import { useActivation } from '@context/MobileOne';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';

export function ESimBrandSelection({ setMobileBrand, setModal }) {
  // Context
  const { t } = useStaticContent();
  const { activationInfo, activationType } = useActivation();

  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ESIM_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ESIM_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  return (
    <Modal
      showCloseButton
      onCloseClick={() => {
        setMobileBrand('');
        setModal('');
      }}
      stepHeader={t('sh_apoc_eSIM_header1')}
      header={t('sh_apoc_eSIM_h1')}
      description={t('sh_apoc_eSIM_subheader1')}
    >
      <div className="mt-4">
        <ul className="list-unstyled category-link mt-4">
          {appeSimDeviceListing.map(({ id, title }) => (
            <li className="my-4" key={`${title}-brands`}>
              <a
                className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link({
                      link_id: `${t(title).replaceAll(' ', '-').toLowerCase()}-weiter`
                    });
                  }
                  setMobileBrand({ id });
                  setModal(appActivationStep2Modals.ESIM_BRAND_DEVICES);
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: t(title) }}></div>
                <i className="fa fa-arrow-right" />
              </a>
            </li>
          ))}
        </ul>
        {/* <ul className="list-unstyled category-link mt-4">
          {appeSimDeviceListing.map(({ id, title, devices, toggle }, index) => (
            <li className="my-4" key={`${title}-brands`}>
              <div
                role='button'
                className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                onClick={() => {
                  if(toggle) {

                  }
                  setMobileBrand({ id });
                  setModal(appActivationStep2Modals.ESIM_BRAND_DEVICES);
                }}
                dangerouslySetInnerHTML={{__html: t(brand)}}>Z
                <i className="fa fa-arrow-right fa-lg"></i>
              </div>
            </li>
          ))}
        </ul> */}
      </div>
    </Modal>
  );
}

export default ESimBrandSelection;
