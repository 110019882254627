import React, { useEffect, useState } from 'react';
import { Button } from '@core/Inputs';
import { Col, Container, Row } from '@core/Layout';
import { QrCode } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appImages, deviceRegex } from '@utils/globalConstant';

export function LegitimacySuccess() {
  // States
  const [isIosDevice, setIsIosDevice] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);

  // Contexts
  const { t } = useStaticContent();

  // Functions

  //  Hooks
  useEffect(() => {
    let deviceInfo = navigator.userAgent;
    setIsIosDevice(deviceRegex.ISIOS.test(deviceInfo));
    setIsAndroidDevice(deviceRegex.ISANDRIOD.test(deviceInfo));
  }, []);

  return (
    <Container>
      <br />
      <br />
      <Row>
        <Col md={6} xs={12} className="mb-5">
          <h1 dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_legitimation_h1') }} />
          <p dangerouslySetInnerHTML={{ __html: t('sh__web_apoc_legitimation_text') }} />
          <p dangerouslySetInnerHTML={{ __html: t('sh_apoc_web_legitimation_text2') }} />
        </Col>
        <Col md={6} xs={12} className="mb-5">
          <b>
            <p
              className="m-0"
              dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_subtext1') }}
            />
            <p dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_subtext2') }} />
          </b>
          {isIosDevice && (
            <Button
              className="btn-dark"
              href={t('sh_web_app_store_link')}
              to={t('sh_web_app_store_link')}
              target="_blank"
            >
              App Store
            </Button>
          )}
          {isAndroidDevice && (
            <Button
              className="btn-dark"
              href={t('https://www.google.com/')}
              to={t('https://www.google.com/')}
              target="_blank"
            >
              Google Play
            </Button>
          )}
          {!isAndroidDevice && !isIosDevice && (
            <>
              <img className="mx-100 grow" src={appImages.qrcode} alt="QR code" />
              <br />
              <br />
              <Button
                className="btn-dark"
                href={t('sh_web_play_store_link')}
                to={t('sh_web_play_store_link')}
                target="_blank"
              >
                Google Play
              </Button>
              &ensp; &ensp;
              <Button
                className="btn-dark"
                href={t('sh_web_app_store_link')}
                to={t('sh_web_app_store_link')}
                target="_blank"
              >
                App Store
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default LegitimacySuccess;
