import { Col, Row } from '@core/Layout';
import React from 'react';
import { Form } from 'react-bootstrap';
// import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { appAutoTopUpPeriodType, appPaymentProductType } from '@utils/globalConstant';
import { Select } from '@core/Inputs';
import { useOnlineTopUp } from '@context/AlphaComm';

export function TimePeriod() {
  const { allPaymentProducts, setPaymentProducts } = useOnlineTopUp();

  const { values, errors, touched, handleBlur, handleChange } = useFormikContext();

  const onChangeProductType = (e) => {
    if (
      allPaymentProducts.length > 0 &&
      (e.target.value === appAutoTopUpPeriodType.LOW_BALANCE ||
        e.target.value === appAutoTopUpPeriodType.RATE)
    ) {
      setPaymentProducts(
        allPaymentProducts.filter(
          ({ productType }) => productType === appPaymentProductType.LOW_BALANCE
        )
      );
      handleChange('periodType')(e);
      handleChange('productId')({ target: { value: '' } });
      handleChange('productAmount')({ target: { value: '' } });
    }

    if (allPaymentProducts.length > 0 && e.target.value === appAutoTopUpPeriodType.ONCE_PER_MONTH) {
      setPaymentProducts(
        allPaymentProducts.filter(
          ({ productType }) => productType === appPaymentProductType.RECURRING
        )
      );
      handleChange('periodType')(e);
      handleChange('productId')({ target: { value: '' } });
      handleChange('productAmount')({ target: { value: '' } });
    }
  };

  return (
    <>
      <div className="py-3">
        <hr />
        <h2>Period of time</h2>
        <hr />
        <h5>Choose a period of time in which your credit should be topped up</h5>
        <Row>
          <Col xs={12}>
            <span>Charge directly or set up automatic charging</span>
          </Col>
          <Col xs={12} lg={6} xxl={6} className="py-4">
            <Form.Check
              label={<strong>When the balance drops below €5</strong>}
              feedback={
                touched.periodType && !!errors.periodType
                  ? errors.periodType
                  : 'Top up once a month on a day of your choice'
              }
              feedbackType={touched.periodType && !!errors.periodType ? 'invalid' : 'help'}
              isInvalid={touched.periodType && !!errors.periodType}
              name="periodType"
              type="radio"
              className="m-0"
              onChange={onChangeProductType}
              onBlur={handleBlur}
              value={appAutoTopUpPeriodType.LOW_BALANCE}
              checked={values.periodType === appAutoTopUpPeriodType.LOW_BALANCE}
              id={appAutoTopUpPeriodType.LOW_BALANCE}
            />
            {/* <Form.Control.Feedback type="help">
              Top up once a month on a day of your choice
            </Form.Control.Feedback>
            {touched.periodType && errors.periodType && (
              <Form.Control.Feedback type="invalid">{errors.periodType}</Form.Control.Feedback>
            )} */}
          </Col>
          <Col xs={8} lg={4} xxl={4} className="py-4">
            <Form.Check
              label={<strong>1x per month</strong>}
              feedback={
                touched.periodType && !!errors.periodType
                  ? errors.periodType
                  : 'Top up when your balance is low or on a specific day'
              }
              feedbackType={touched.periodType && !!errors.periodType ? 'invalid' : 'help'}
              isInvalid={touched.periodType && !!errors.periodType}
              name="periodType"
              type="radio"
              className="m-0"
              onChange={onChangeProductType}
              onBlur={handleBlur}
              value={appAutoTopUpPeriodType.ONCE_PER_MONTH}
              checked={values.periodType === appAutoTopUpPeriodType.ONCE_PER_MONTH}
              id={appAutoTopUpPeriodType.ONCE_PER_MONTH}
            />
            {/* <Form.Control.Feedback type="help">
              Top up when your balance is low or on a specific day
            </Form.Control.Feedback>
            {touched.periodType && errors.periodType && (
              <Form.Control.Feedback type="invalid">{errors.periodType}</Form.Control.Feedback>
            )} */}
            {/* <Form.Check
              type="radio"
              id="type"
              value={appAutoTopUpPeriodType.ONCE_PER_MONTH}
              checked={values.periodType === appAutoTopUpPeriodType.ONCE_PER_MONTH}
              onChange={handleChange}
              onBlur={handleBlur}>
              <Form.Check.Input type="radio" />
              <Form.Check.Label>
                <strong>1x per month</strong>
              </Form.Check.Label>
              {touched.periodType && errors.periodType && (
                <Form.Control.Feedback type="error">{errors.periodType}</Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="help">
                Top up when your balance is low or on a specific day
              </Form.Control.Feedback>
            </Form.Check> */}
          </Col>
          <Col xs={4} lg={2} xxl={2} className="py-4">
            {/* <Form.Label>
              <strong>Select day</strong>
            </Form.Label> */}
            <Select
              label="Select day"
              id="periodDay"
              name="periodDay"
              options={Array.from(Array(28)).map((a, i) => ({ id: i, value: `${i + 1}` }))}
              value={values.periodDay}
              onChange={(e) =>
                handleChange({
                  target: {
                    id: 'periodDay',
                    name: 'periodDay',
                    value: e.target.value
                  }
                })
              }
              getOptionId={(option) => option.id}
              getOptionName={(option) => option.value}
              isInvalid={!!touched.periodDay && !!errors.periodDay}
              onBlur={handleBlur}
            />
            {/* <Form.Select aria-label="Select day">
              {Array.from(Array(28)).map((a, i) => (
                <option value={i} key={`day-${i + 1}`}>
                  {i + 1}
                </option>
              ))}
            </Form.Select> */}
          </Col>
          <Col xs={12} lg={6} xxl={6} className="py-4">
            <Form.Check
              label={<strong>Rates</strong>}
              feedback={
                touched.periodType && !!errors.periodType
                  ? errors.periodType
                  : 'Top up when your tariff is due and your credit balance is insufficient'
              }
              feedbackType={touched.periodType && !!errors.periodType ? 'invalid' : 'help'}
              isInvalid={touched.periodType && !!errors.periodType}
              name="periodType"
              type="radio"
              className="m-0"
              onChange={onChangeProductType}
              onBlur={handleBlur}
              value={appAutoTopUpPeriodType.RATE}
              checked={values.periodType === appAutoTopUpPeriodType.RATE}
              id={appAutoTopUpPeriodType.RATE}
            />
            {/* <Form.Control.Feedback type="help">
              Top up when your tariff is due and your credit balance is insufficient
            </Form.Control.Feedback>
            {touched.periodType && errors.periodType && (
              <Form.Control.Feedback type="invalid">{errors.periodType}</Form.Control.Feedback>
            )} */}
            {/* <Form.Check
              type="radio"
              id="type"
              value={appAutoTopUpPeriodType.RATE}
              checked={values.periodType === appAutoTopUpPeriodType.RATE}
              onChange={handleChange}
              onBlur={handleBlur}>
              <Form.Check.Input type="radio" />
              <Form.Check.Label>
                <strong>Rates</strong>
              </Form.Check.Label>
              {touched.periodType && errors.periodType && (
                <Form.Control.Feedback type="error">{errors.periodType}</Form.Control.Feedback>
              )}
              <Form.Control.Feedback type="help">
                Top up when your tariff is due and your credit balance is insufficient
              </Form.Control.Feedback>
            </Form.Check> */}
          </Col>
        </Row>
      </div>
    </>
  );
}

TimePeriod.propTypes = {
  // values: PropTypes.shape({ periodType: PropTypes.string, periodDay: PropTypes.string }).isRequired,
  // errors: PropTypes.shape({ periodType: PropTypes.string, periodDay: PropTypes.string }).isRequired,
  // touched: PropTypes.shape({ periodType: PropTypes.bool, periodDay: PropTypes.string }).isRequired,
  // handleBlur: PropTypes.func.isRequired,
  // handleChange: PropTypes.func.isRequired
};

export default TimePeriod;
