import React from 'react';
// import { Form } from 'react-bootstrap';
import { Form, useFormikContext } from 'formik';

// import { Text } from '@core/Inputs';
import { Col, Row } from '@core/Layout';
import { useOnlineTopUp } from '@context/AlphaComm';
import { DirectPaymentProduct, PaymentProduct } from '@part/Payment';
// import { appPaymentProductType } from '@utils/globalConstant';
import Feedback from 'react-bootstrap/esm/Feedback';
// import PropTypes from 'prop-types'

export function Amount() {
  const { values, errors, touched, handleBlur, handleChange } = useFormikContext();
  const { paymentProducts } = useOnlineTopUp();
  return (
    <>
      <div className="py-3">
        <hr />
        <h2>Top-up amount</h2>
        <hr />
        <Row>
          <Col xs={12}>
            <span>Charge directly or set up automatic charging</span>
            {touched.productId && errors.productId && (
              <Feedback type="invalid" className="d-block">
                {errors.productId}
              </Feedback>
            )}
          </Col>
          {paymentProducts.map((item) =>
            item.amount > 0 || Number(item.minAmount) === Number(item.maxAmount) ? (
              <Col
                xs={12}
                md={6}
                lg={4}
                xxl={3}
                className="py-2"
                key={`select-payment-product-${item.id}`}
              >
                <PaymentProduct
                  id={item.id}
                  minAmount={Number(item.minAmount)}
                  maxAmount={Number(item.maxAmount)}
                  channel={item.channel}
                  optionType={item.optionType}
                  priceRange={item.priceRange}
                  productType={item.productType}
                  isSelected={values.productId === item.id}
                  onBlur={handleBlur}
                  onSelect={(id) => {
                    handleChange('productId')({
                      target: { name: 'productId', value: id }
                    });
                    handleChange('productType')({
                      target: { name: 'productType', value: item.productType }
                    });
                  }}
                />
              </Col>
            ) : (
              <Col
                xs={12}
                md={6}
                lg={4}
                xxl={3}
                className="py-2"
                key={`select-direct-payment-product-${item.id}`}
              >
                <DirectPaymentProduct
                  id={item.id}
                  minAmount={Number(item.minAmount)}
                  maxAmount={Number(item.maxAmount)}
                  channel={item.channel}
                  optionType={item.optionType}
                  priceRange={item.priceRange}
                  productType={item.productType}
                  isSelected={values.productId === item.id}
                  otherAmount={values.productAmount}
                  onBlur={handleBlur}
                  onSelect={(id) => {
                    handleChange('productId')({
                      target: { name: 'productId', value: id }
                    });
                  }}
                  onAmountChange={(amount) => {
                    handleChange('productAmount')({
                      target: { name: 'productAmount', value: amount }
                    });
                    handleChange('productType')({
                      target: { name: 'productType', value: item.productType }
                    });
                    // setOtherAmount(amount);
                  }}
                />
                {touched.productAmount && errors.productAmount && (
                  <Form.Control.Feedback type="invalid">
                    {errors.productAmount}
                  </Form.Control.Feedback>
                )}
              </Col>
            )
          )}

          {/* {allPaymentProducts.map(
            (item) =>
              item.productType === appPaymentProductType.DIRECT && (
                <Col
                  xs={12} md={6} lg={4} xxl={3} className="py-2"
                  key={`select-direct-payment-product-${item.id}`}>
                  <DirectPaymentProduct
                    id={item.id}
                    minAmount={parseInt(item.minAmount, 10)}
                    maxAmount={parseInt(item.maxAmount, 10)}
                    channel={item.channel}
                    optionType={item.optionType}
                    priceRange={item.priceRange}
                    productType={item.productType}
                    isSelected={values.productId === item.id}
                    otherAmount={values.productAmount}
                    onSelect={(id) => {
                      handleChange('productId')({
                        target: { name: 'productId', value: id }
                      });
                    }}
                    onAmountChange={(amount) => {
                      handleChange('productAmount')({
                        target: { name: 'productAmount', value: amount }
                      });
                      handleChange('productType')({
                        target: { name: 'productType', value: item.productType }
                      });
                      // setOtherAmount(amount);
                    }}
                  />
                  {touched.productAmount && errors.productAmount && <Form.Control.Feedback type="invalid">{errors.productAmount}</Form.Control.Feedback>}
                </Col>
              )
          )} */}
          {/* <Col xs={4} className="py-2">
            <Text {...{
              label: 'Different Amount',
              placeholder: 'Amount',
            }} />
          </Col> */}
        </Row>
      </div>
    </>
  );
}

Amount.propTypes = {};

export default Amount;
