import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';

import { useAlert, useLayout } from '@context/Utils';
import { appActivationFlowType, appAlert, appImages } from '@utils/globalConstant';
import InputFeedback from '../InputFeedback';
import { UTAG_LINK, utag } from '@utils/utag';
import { useActivation } from '@context/MobileOne';

export function Text({
  label,
  type,
  id,
  placeholder,
  name,
  onChange,
  onBlur,
  onFocus,
  value,
  isValid,
  isInvalid,
  isDisabled,
  error,
  help,
  showCode,
  maxLength
  // onKeyDown
}) {
  // State
  const [showPassword, setShowPassword] = useState(false);

  // Context
  const { showAlert } = useAlert();
  const { activationType } = useActivation();
  const { showMobileMenu } = useLayout();

  // Function
  const handleCopyText = () => {
    if (showCode) {
      navigator.clipboard.writeText(value);
      showAlert({ type: appAlert.SUCCESS, message: 'Copied..' });
    }
  };

  // const inptutControl = (
  //   <Form.Group as={Col} {...{ sm, md, lg, xl, xxl, controlId: id }}>
  //     {label && <Form.Label>{label}</Form.Label>}
  //     <Form.Control
  //       type={type}
  //       aria-describedby={`${id}Block`}
  //       {...{
  //         name,
  //         placeholder,
  //         onChange,
  //         value: value === undefined || value === null ? '' : value,
  //         isValid,
  //         isInvalid,
  //         onBlur,
  //         maxLength
  //       }}
  //       disabled={isDisabled}
  //     />
  //     {help && (
  //       <Form.Text id={`${id}Block`} muted>
  //         {help}
  //       </Form.Text>
  //     )}
  //     {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
  //   </Form.Group>
  // );

  const inputControl = (
    <div className="mb-3">
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}
      <input
        className={`form-control primary-color-text ${isValid ? 'is-valid' : ''} ${
          isInvalid && !showMobileMenu ? 'is-invalid' : ''
        }`}
        placeholder={placeholder}
        type={showPassword ? 'text' : type}
        aria-describedby={`${id}Block`}
        {...{
          id,
          name,
          placeholder,
          onChange,
          value: value === undefined || value === null ? '' : value,
          label,
          onBlur,
          onFocus,
          disabled: isDisabled,
          maxLength
          // onKeyDown
        }}
      />
      {help && <InputFeedback id={`${id}Block`} feedBackMessage={help} />}

      {/* {isValid && (
        <InputFeedback id={`${id}Block`} isValid feedBackMessage={help}/>
      )} */}

      {error && <InputFeedback id={id} isInvalid feedBackMessage={error} />}
    </div>
  );

  const inputWithIcon = (
    <div className="input-group mb-3 position-relative">
      <input
        className={`form-control with-icon primary-color-text z-1 ${isValid ? 'is-valid' : ''} ${
          isInvalid && !showMobileMenu ? 'is-invalid' : ''
        }`}
        placeholder={placeholder}
        type={showPassword ? 'text' : type}
        aria-describedby={`${id}Block`}
        {...{
          name,
          placeholder,
          onChange,
          value: value === undefined || value === null ? '' : value,
          label,
          onBlur
        }}
        disabled={isDisabled}
      />
      <span
        className="bg-white border-0 position-absolute top-0 end-0 p-2 m-2"
        style={{ zIndex: `${!showMobileMenu ? 2 : 0}` }}
        onClick={() => {
          if (window && window.utag) {
            showPassword &&
              (activationType === appActivationFlowType.NEW_ONLINE
                ? window.utag.link(UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.LOGIN.VIEW_PASSWORD_ON_CLICK)
                : window.utag.link(UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.LOGIN.VIEW_PASSWORD_ON_CLICK));
          }
          setShowPassword(!showPassword);
        }}
      >
        <img src={appImages.background} alt="logo" />
      </span>

      {help && <InputFeedback id={`${id}Block`} feedBackMessage={help} />}
      {error && <InputFeedback id={id} isInvalid feedBackMessage={error} />}
    </div>

    // {label && <Form.Label>{label}</Form.Label>}
    // <InputGroup className="mb-3">
    //   {/* <Form.Group as={Col} {...{ sm, md, lg, xl, xxl, controlId: id }}> */}
    //   {/**/}
    //   <Form.Control
    //     type={showPassword ? 'text' : type}
    //     aria-describedby={`${id}Block`}
    //     {...{
    //       name,
    //       placeholder,
    //       onChange,
    //       value: value === undefined || value === null ? '' : value,
    //       isValid,
    //       isInvalid,
    //       label,
    //       onBlur
    //     }}
    //     disabled={isDisabled}
    //   />
    //   {help && (
    //     <Form.Text id={`${id}Block`} muted>
    //       {help}
    //     </Form.Text>
    //   )}
    //   {/* </Form.Group> */}
    //   <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
    //     {showPassword ? <i className="bi bi-eye-slash" /> : <i className="bi bi-eye-fill" />}
    //   </InputGroup.Text>
    //   {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    // </InputGroup>
  );

  // const inputCopyText = (
  //   <>
  //     {label && <Form.Label>{label}</Form.Label>}
  //     <InputGroup className="mb-3">
  //       {/* <Form.Group as={Col} {...{ sm, md, lg, xl, xxl, controlId: id }}> */}
  //       {/**/}
  //       <Form.Control
  //         type="text"
  //         aria-describedby={`${id}Block`}
  //         {...{
  //           name,
  //           onChange,
  //           placeholder,
  //           value: showCode ? value : '******',
  //           isValid,
  //           isInvalid,
  //           label,
  //           onBlur
  //         }}
  //         disabled={isDisabled}
  //       />
  //       {help && (
  //         <Form.Text id={`${id}Block`} muted>
  //           {help}
  //         </Form.Text>
  //       )}
  //       {/* </Form.Group> */}
  //       <InputGroup.Text onClick={handleCopyText}>
  //         <i className="bi bi-clipboard" />
  //       </InputGroup.Text>
  //       {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
  //     </InputGroup>
  //   </>
  // );

  const inputDateControl = (
    <div className="mb-3">
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}
      <DatePicker
        className={`primary-color-text w-100 ${isValid ? 'is-valid' : ''} ${
          isInvalid && !showMobileMenu ? 'is-invalid' : ''
        }`}
        placeholderText={placeholder}
        onChange={(e) =>
          onChange({
            target: {
              id,
              name,
              value: e
            }
          })
        }
        autoComplete="off"
        autoFocus={false}
        selected={(value && new Date(value)) || null}
        maxDate={new Date()}
        dateFormat="dd.MMMM yyyy"
        locale={de}
        showYearDropdown
        showMonthDropdown
        // showFourColumnMonthYearPicker
        dropdownMode="select"
        {...{
          id,
          name,
          value: value === undefined || value === null ? '' : value,
          label,
          onBlur,
          onFocus,
          disabled: isDisabled
          // onKeyDown
        }}
      />
      {help && <InputFeedback id={`${id}Block`} feedBackMessage={help} />}

      {/* {isValid && (
        <InputFeedback id={`${id}Block`} isValid feedBackMessage={help}/>
      )} */}

      {error && <InputFeedback id={id} isInvalid feedBackMessage={error} />}
    </div>
  );

  if (type === 'password') {
    return inputWithIcon;
  }

  if (type === 'date') {
    return inputDateControl;
  }
  // if (type === 'copytext') {
  //   return inputCopyText;
  // }

  return inputControl;
}

Text.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  // onKeyDown: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any]),
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCode: PropTypes.any,
  error: PropTypes.any,
  help: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number
};

Text.defaultProps = {
  label: '',
  type: 'text',
  id: '',
  placeholder: '',
  // onKeyDown: () => { },
  name: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  showCode: () => {},
  value: '',
  maxLength: '',
  isValid: null,
  isInvalid: null,
  isDisabled: null,
  error: '',
  help: '',
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 12
};

export default Text;
