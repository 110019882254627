import React, { useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Col, Container, Row } from '@core/Layout';
import { useCustomer } from '@context/MobileOne';
import { Button } from '@core/Inputs';
import { PasswordChange } from '@page/User/Account/PasswordChange';
import { PaymentRegister } from '@page/User/Account/PaymentRegister';
import { ChangeEmail } from '@page/User/Account/ChangeEmail';
import { Spinner } from '@core/Utils';

export function Account() {
  // States
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [isEmailChange, setIsEmailChange] = useState(false);
  const [isPaymentRegister, setIsPaymentRegister] = useState(false);

  // Contexts
  const { t } = useStaticContent();
  const { personalData, customerData, isLoading } = useCustomer();
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container>
          {!isPaymentRegister && !isPasswordChange && !isEmailChange && (
            <>
              <Row className="mt-2">
                <Col>
                  <center>
                    <h3>{t('sh_md_db_h1')}</h3>
                  </center>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>{t('sh_md_db_name_subheadline')}</b>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  {`${personalData?.firstName}  ${personalData?.lastName}`}
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>{t('sh_md_db_adress_subheadline')}</b>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  {`${personalData?.address?.street},  ${personalData?.address?.houseNumber}`}
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>{t('sh_md_db_zipcode_city_subheadline')}</b>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  {`${personalData?.address?.zip},  ${personalData?.address?.city}`}
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>{t('sh_md_db_email_subheadline')}</b>
                </Col>
                <Col style={{ textAlign: 'right' }}>{personalData?.email?.emailAddress}</Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    variant="link"
                    onClick={() => {
                      setIsEmailChange(true);
                    }}
                  >
                    {t('sh_md_db_change_email_link')}
                  </Button>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <h3>{t('sh_md_db_h2')}</h3>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>{t('sh_md_db_number_subheadline')}</b>
                </Col>
                <Col style={{ textAlign: 'right' }}>{customerData.msisdn}</Col>
              </Row>
              <Row>
                <Col>
                  <Button variant="link" onClick={() => setIsPasswordChange(true)}>
                    {t('sh_md_db_change_password_link')}
                  </Button>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <h3>{t('sh_md_db_h3')}</h3>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>{t('sh_md_payment_method_information_text')}</p>
                </Col>
                <Col md={12}>
                  <Button variant="primary" onClick={() => setIsPaymentRegister(true)}>
                    Zahlungsmethode hinzufugen
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {isPasswordChange && <PasswordChange setIsPasswordChange={setIsPasswordChange} />}
          {isEmailChange && <ChangeEmail setIsEmailChange={setIsEmailChange} />}
          {isPaymentRegister && <PaymentRegister setIsPaymentRegister={setIsPaymentRegister} />}
        </Container>
      )}
    </>
  );
}

export default Account;

export * from './HotlinePassword';
export * from './ChangeEmail';
export * from './ChangePassword';
export * from './SimLock';
