import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import './style.scss';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_VIEWS } from '@utils/utag';
import { useLayout } from '@context/Utils';
import { appSecBgColorTypes, appSecBottomElementTypes } from '@utils/globalConstant';
import { Section } from '@core/Layout';

export function Contact() {
  // Contexts
  const { setLightHeader } = useLayout();
  const { t, staticContentData } = useStaticContent();
  const { setMetaTags } = useMetaTag();

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_contact_meta_title'),
      description: t('sh_web_page_contact_meta_description'),

      twitterTitle: t('sh_web_page_contact_meta_title'),
      twitterDescription: t('sh_web_page_contact_meta_description'),

      ogTitle: t('sh_web_page_contact_meta_title'),
      ogDescription: t('sh_web_page_contact_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  useEffect(() => {
    setLightHeader(false);
    return () => {
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      const tagInfo = UTAG_VIEWS.CONTACT_MOUNTING;
      window.utag.view(tagInfo);
    }
  }, [window.utag]);

  return (
    <Section
      className="mb-sm-0 position-relative"
      // bottomElementType={appSecBottomElementTypes.TYPE_4}
      // bgColorCode={appSecBgColorTypes.OFFWHITE}
    >
      {/* <section className="bg-offWhite-color"> */}
      <section className="bg-offWhite-color py-56 py-lg-80">
        <div className="container pt-0 pt-lg-5 my-0">
          <div className="row py-5 pb-0 pb-sm-5">
            <div className="col-xl-7 offset-xl-1 col-lg-8 col-md-8 col-12">
              <h1
                className="primary-color-text my-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_contact_h1') }}
              />
              <p
                className="body-large-regular primary-color-text my-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_contact_text') }}
              />
              <strong
                className="body-large-bold primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_failed_number') }}
              />
              <p
                className="body-large-regular primary-color-text m-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_failed_opening_time') }}
              />
            </div>
          </div>
        </div>
      </section>
    </Section>
  );
}

export default Contact;
