import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMpsPayment } from '@dom-digital-online-media/dom-mps-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import * as Yup from 'yup';

import {
  appActivationFlowType,
  appAlert,
  appPaymentStaticData,
  appRoute,
  appStorage,
  formValidation
} from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
import { useConfig } from '@config/ContextManager';

import { useActivationTariff } from '../ActivationTariff';
import { usePersonalData } from '../PersonalData';
import { useSimValidation } from '../SimValidation';
import { UTAG_LINK, utag } from '@utils/utag';
import { useNumberPorting } from '../NumberPorting';
import { useEmailVerification } from '../EmailVerification';
import { useActivation } from '..';

export const PaymentContext = createContext({});

export function PaymentContextProvider({
  children,
  onFormUpdate,
  env,
  activationInfo,
  setCurrentStep
}) {
  // Constant
  const GERMAN_STREET_REGEX = /^([a-zäöüß\s.-]+?)(\d|\d+?[a-zäöüß\s\d.-]+?)$/i;
  const initialValue = {
    paymentMethod: 'paypal',
    enoughCredit: false
  };

  // Validations
  // const validationSchema = Yup.lazy((values) => {
  //   console.log({ values });
  //   return Yup.object().shape({
  //     paymentMethod: formValidation({ required: 'please_select_payment_method' }),
  //     enoughCredit: Yup.boolean().required().oneOf([true], 'mark_as_true')
  //   });
  // });
  const validationSchema = Yup.object().shape({
    paymentMethod: formValidation({ required: 'please_select_payment_method' }),
    enoughCredit: Yup.boolean().required().oneOf([true], 'mark_as_true')
  });

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(initialValue);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [currentRoute, setCurrentRoute] = useState('');

  // Context
  // MO Context
  const { initialValue: simValdateInitialValues, setFormValue: simValdateSetFormValues } =
    useSimValidation();
  const {
    bookableTariff,
    setBookableTariff,
    setActiveTariff,
    activeTariff,
    initialValue: TariffInitValues,
    setFormValue: TariffSetFormValues
  } = useActivationTariff();
  const { setActivationInfo, activationType } = useActivation();
  const { initialValue: numberPortingInitialValue, setFormValue: numberPortingSetFormValues } =
    useNumberPorting();
  const { setFormValue: EmailSetFormValues } = useEmailVerification();
  const { showAlert } = useAlert();
  const {
    onPaymentOAuthToken,
    onPaymentMethod,
    onPaymentResgister,
    onPaymentResgisterResult,
    onPaymentSessionDetails
  } = useMpsPayment();
  const {
    config: { storage }
  } = useConfig();
  const location = useLocation();

  const { onDomcountriesCall } = useMobileOne();
  const {
    initialValue: personalInitiValues,
    setFormValue: setPersonalDataForm,
    passwordInitialValue,
    setPasswordValue
  } = usePersonalData();
  const navigate = useNavigate();
  // const { countries } = useActivation();

  // Functions
  const getPaymentMethods = async () => {
    await onPaymentOAuthToken({
      grant_type: env.REACT_APP_GRANT_TYPE,
      scope: env.REACT_APP_PAYMENT_SCOPE
    });
    const { data, status } = await onPaymentMethod({
      businessPartnerConfigId: env.REACT_APP_PAYMENT_BUSINESS_PARTNER_ID,
      currency: 'EUR'
    });
    if (status === 200) {
      setPaymentMethods(data.availablePaymentMethods);
    }
  };
  const validateForm = (values) => {
    // console.log({ values });
    const errors = {};
    if (!values.paymentMethod) {
      errors.paymentMethod = 'please_select_payment_method';
    }

    return errors;
  };
  const onLoad = async () => {
    const storeActivationInfo = JSON.parse(await storage.getItem(appStorage.ACTIVATION_INFO));
    if (
      location.pathname.includes(appRoute.NEW_ONLINE_CUSTOMER) &&
      (!storeActivationInfo || (!activationInfo && !activationInfo.tariff))
    ) {
      navigate('/');
    }
  };

  const setActivationInitialValues = async () => {
    await storage.removeItem(appStorage.ACTIVATION_INFO);
    if (
      location.pathname.includes(appRoute.NEW_ONLINE_CUSTOMER) ||
      location.pathname.includes(appRoute.ACTIVATION)
    ) {
      await storage.setItem(
        appStorage.ACTIVATION_FLOW_TYPE,
        location.pathname.includes(appRoute.NEW_ONLINE_CUSTOMER)
          ? appActivationFlowType.NEW_ONLINE
          : appActivationFlowType.SIM_ACTIVATION
      );
    } else {
      await storage.removeItem(appStorage.ACTIVATION_FLOW_TYPE);
    }
    await storage.removeItem(appStorage.STORAGE_TIME_LEFT);
    await storage.removeItem(appStorage.RECHARGE_OPTIONS);
    await storage.removeItem(appStorage.SIM_PROVIDERS);
    await storage.removeItem(appStorage.POSTPAID_NUMBERS);
    simValdateSetFormValues(simValdateInitialValues);
    TariffSetFormValues(TariffInitValues);
    numberPortingSetFormValues(numberPortingInitialValue);
    EmailSetFormValues({ email: '' });
    setFormValue(initialValue);
    setPersonalDataForm(personalInitiValues);
    setPasswordValue(passwordInitialValue);
    onFormUpdate({});
    setActivationInfo({});
    setCurrentStep(0);
  };

  useEffect(() => {
    (async () => {
      const currentTime = new Date().getTime(); // in milliseconds
      const sessionTime = (await storage.getItem(appStorage.STORAGE_TIME_LEFT)) || 0;
      const isFlowType = await storage.getItem(appStorage.ACTIVATION_FLOW_TYPE);
      const storeActivationInfo =
        JSON.parse(await storage.getItem(appStorage.ACTIVATION_INFO)) || {};
      if (
        location.pathname.includes(appRoute.NEW_ONLINE_CUSTOMER) &&
        (!isFlowType || isFlowType === appActivationFlowType.NEW_ONLINE)
      ) {
        await storage.setItem(appStorage.ACTIVATION_FLOW_TYPE, appActivationFlowType.NEW_ONLINE);
        if (!sessionTime || (sessionTime && !(Object.keys(storeActivationInfo).length > 0))) {
          await storage.setItem(appStorage.STORAGE_TIME_LEFT, currentTime + 1800000);
        }
      } else if (
        location.pathname.includes(appRoute.ACTIVATION) &&
        (!isFlowType || isFlowType === appActivationFlowType.NEW_ONLINE)
      ) {
        await storage.setItem(
          appStorage.ACTIVATION_FLOW_TYPE,
          appActivationFlowType.SIM_ACTIVATION
        );
        if (!sessionTime || (sessionTime && !(Object.keys(storeActivationInfo).length > 0))) {
          await storage.setItem(appStorage.STORAGE_TIME_LEFT, currentTime + 1800000);
        }
      } else {
        if (!location.pathname.includes(appRoute.ACTIVATION)) {
          setActivationInitialValues();
        }
      }
    })();
  }, [location]);

  const getPaymentResult = async () => {
    try {
      const { data } = await onPaymentResgisterResult({
        checkoutSessionId: activationInfo.checkoutSessionId
      });
      if (activationInfo.paymentMethod === 'paypal') {
        const { data } = await onPaymentSessionDetails({
          checkoutSessionId: activationInfo.checkoutSessionId,
          includePaymentServiceDetails: true,
          includePaymentTransactionDetails: true
        });
        const { data: countries } = await onDomcountriesCall();
        const {
          paymentTransactionDetails: {
            customerInformation: { billingAddress = {} } = { billingAddress: {} }
          }
        } = data;
        if (billingAddress) {
          const {
            firstName = '',
            lastName = '',
            addressLine1 = '',
            addressLine2 = '',
            state = '',
            postalCode = '',
            city = '',
            countryCode = ''
          } = billingAddress;
          const breakAddress = GERMAN_STREET_REGEX.exec(addressLine1);

          const findCountryCode = countries.find(
            (country) => country.countryCode === countryCode || country.id === countryCode
          );

          const data =
            findCountryCode && findCountryCode.id
              ? {
                  firstName,
                  lastName,
                  street: breakAddress === null ? addressLine1 : breakAddress[1].trim(),
                  houseNumber: breakAddress === null ? '' : breakAddress[2].trim(),
                  city,
                  zip: postalCode,
                  countryCode: findCountryCode.id
                }
              : {
                  firstName,
                  lastName,
                  street: breakAddress === null ? addressLine1 : breakAddress[1].trim(),
                  houseNumber: breakAddress === null ? '' : breakAddress[2].trim(),
                  city,
                  zip: postalCode
                };
          setPersonalDataForm(data);
        }
      }
      /* eslint-disable */
      delete activationInfo['checkoutSessionId'];

      await storage.removeItem(appStorage.ACTIVATION_INFO);

      setBookableTariff(activationInfo.bookableTariff);
      setActiveTariff(activationInfo.activeTariff);
      /* eslint disable */
      delete activationInfo.bookableTariff;
      activationInfo.acceptedCreditCheck = false;
      activationInfo.termsAndConditions = false;
      activationInfo.brandPartnerCustomMarketing = true;
      onFormUpdate({ ...activationInfo, payment: data });

      const previousFlow = await storage.getItem(appStorage.ACTIVATION_FLOW_TYPE);
      setCurrentStep(previousFlow === appActivationFlowType.NEW_ONLINE ? 4 : 6);

      // await storage.removeItem(appStorage.ACTIVATION_FLOW_TYPE);
    } catch (error) {
      console.log(error);

      const previousFlow = await storage.getItem(appStorage.ACTIVATION_FLOW_TYPE);
      setCurrentStep(previousFlow === appActivationFlowType.NEW_ONLINE ? 3 : 5);
    }
  };

  const onSubmit = async (values) => {
    // console.log(values);
    try {
      setIsLoading(true);
      const rechargeOptions = JSON.parse(await storage.getItem(appStorage.RECHARGE_OPTIONS));
      if (!rechargeOptions) {
        setFormValue(values);
        onFormUpdate({ ...activationInfo, ...values });
        const { data } = await onPaymentResgister({
          businessPartnerConfigId: env.REACT_APP_PAYMENT_BUSINESS_PARTNER_ID,
          returnUrl: `${env.REACT_APP_SHOPPING_RETURN_URL}checkout`,
          description: appPaymentStaticData.description,
          currency: 'EUR',
          locale: 'de_DE',
          uiDetails: {
            binding_legal_terms: '*** Binding legal terms ***'
          },
          themeName: 'default',
          paymentMethod: values.paymentMethod
        });
        activationInfo.isPromoApplied = activeTariff.promoCode ? true : false;
        await storage.setItem(
          appStorage.ACTIVATION_INFO,
          JSON.stringify({
            ...activationInfo,
            ...values,
            checkoutSessionId: data.checkoutSessionId,
            bookableTariff
          })
        );
        await storage.setItem(appStorage.ACTIVATION_FLOW_TYPE, appActivationFlowType.NEW_ONLINE);
        window.location = `${data.redirectUrl}`;
      } else {
        setFormValue(values);
        const { data } = await onPaymentResgister({
          businessPartnerConfigId: env.REACT_APP_PAYMENT_BUSINESS_PARTNER_ID,
          returnUrl: `${env.REACT_APP_SHOPPING_RETURN_URL}credit-details`,
          description: appPaymentStaticData.description,
          currency: 'EUR',
          locale: 'de_DE',
          uiDetails: {
            binding_legal_terms: '*** Binding legal terms ***'
          },
          themeName: 'default',
          paymentMethod: values.paymentMethod
        });
        await storage.setItem(
          appStorage.RECHARGE_OPTIONS,
          JSON.stringify({
            ...rechargeOptions,
            checkoutSessionId: data.checkoutSessionId
          })
        );
        await storage.setItem(appStorage.ACTIVATION_FLOW_TYPE, appActivationFlowType.NEW_ONLINE);
        window.location = `${data.redirectUrl}`;
      }
      setIsLoading(false);
      return true;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request.${error.error[0].messageBody} `
      });
      return false;
    }
  };

  const setInitValues = async () => {
    setInterval(async () => {
      const timeLeft = await storage.getItem(appStorage.STORAGE_TIME_LEFT);
      if (timeLeft) {
        const currentTime = new Date().getTime();
        if (timeLeft && timeLeft < currentTime) {
          await storage.removeItem(appStorage.STORAGE_TIME_LEFT);
          await storage.removeItem(appStorage.RECHARGE_OPTIONS);
          setActivationInitialValues();
          navigate(appRoute.HOME);
        }
      }
    }, 5000);
  };

  // const handleLocation = async () => {
  //   console.log({ location });
  //   console.log({ activationType });
  //   if (
  //     (activationType === appActivationFlowType.NEW_ONLINE &&
  //       location.pathname === appRoute.ACTIVATION) ||
  //     (activationType === appActivationFlowType.SIM_ACTIVATION &&
  //       location.pathname === appRoute.NEW_ONLINE_CUSTOMER)
  //   ) {
  //     console.log('in the if condition here');
  //     await storage.removeItem(appStorage.STORAGE_TIME_LEFT);
  //     await storage.removeItem(appStorage.RECHARGE_OPTIONS);
  //     setActivationInitialValues();
  //   }
  // };

  // Call /validate-sim
  // Store response in setValidateSim

  // Hooks
  // Default Hook // No use
  useEffect(() => {
    onLoad();
    setInitValues();
  }, []);

  // useEffect(() => {
  //   handleLocation();
  // }, [location]);

  // Update props on form submit
  useEffect(() => {
    onFormUpdate(formValue);
  }, [formValue]);

  useEffect(() => {
    if (activationInfo.paymentMethod) {
      setFormValue({
        paymentMethod: activationInfo.paymentMethod,
        enoughCredit: activationInfo.enoughCredit || false
      });
    }
    return () => {
      setFormValue(initialValue);
    };
  }, [activationInfo.paymentMethod, activationInfo.enoughCredit]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // Constants
      initialValue,
      formValidation,
      // States

      isLoading,
      setIsLoading,
      formValue,
      setFormValue,
      paymentMethods,
      setPaymentMethods,
      getPaymentResult,
      currentRoute,
      setCurrentRoute,

      // Functions
      onLoad,
      getPaymentMethods,
      onSubmit,
      validateForm,
      setActivationInitialValues
    }),
    [
      // Constants
      initialValue,
      validationSchema,
      // States

      isLoading,
      setIsLoading,
      formValue,
      setFormValue,
      paymentMethods,
      setPaymentMethods,
      getPaymentResult,
      currentRoute,
      setCurrentRoute,

      // Functions
      onLoad,
      getPaymentMethods,
      onSubmit,
      validateForm,
      setActivationInitialValues
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <PaymentContext.Provider value={contextPayload}>{children}</PaymentContext.Provider>;
}
PaymentContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const usePayment = () => useContext(PaymentContext);

export default PaymentContext;
