import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import './style.scss';
import { appImages, roamingModals } from '@utils/globalConstant';
import Accordion from '@core/Layout/Accordion';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_LINK, UTAG_VIEWS } from '@utils/utag';
import LanderZoneModal from './LanderZoneModal';
import ESimSmartPhoneListModal from './ESimSmartPhoneListModal';
import DeactivateSwitzerlandRoamingModal from './DeactivateSwitzerlandRoamingModal';
import DeactivateWlanCallsModal from './DeactivateWlanCallsModal';
import { useLayout } from '@context/Utils';

export function Roaming() {
  // Contexts
  const { setLightHeader } = useLayout();
  const { t, staticContentData } = useStaticContent();
  const { setMetaTags } = useMetaTag();

  // Used Accordian for Roaming
  const accordianRoamingItem = [
    {
      title: t('sh_web_roaming_info1'),
      component: t('sh_web_roaming_info1_text')
    },
    {
      title: t('sh_web_roaming_info2'),
      component: t('sh_web_roaming_info2_text')
    },
    {
      title: t('sh_web_roaming_info3'),
      component: t('sh_web_roaming_info3_text')
    },
    {
      title: t('sh_web_roaming_info4'),
      component: t('sh_web_roaming_info4_text')
    },
    {
      title: t('sh_web_roaming_info5'),
      component: t('sh_web_roaming_info5_text')
    }
  ];

  // States
  const [modal, setModal] = useState('');

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_service_meta_title'),
      description: t('sh_web_page_service_meta_description'),

      twitterTitle: t('sh_web_page_service_meta_title'),
      twitterDescription: t('sh_web_page_service_meta_description'),

      ogTitle: t('sh_web_page_service_meta_title'),
      ogDescription: t('sh_web_page_service_meta_description')
    });
    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  useEffect(() => {
    setLightHeader(false);
    setTimeout(() => {
      const ldModal = document.querySelector('#lander-zone-modal');
      if (ldModal) {
        ldModal.addEventListener('click', () => {
          setModal(roamingModals.LANDER_ZONE);
          if (window && window.utag) {
            window.utag.link(UTAG_LINK.ROAMING_ON_CLICK.COUNTRY_GROUP_ON_CLICK);
          }
        });
      }

      const esimModal = document.querySelector('#esim-modal');
      if (esimModal) {
        esimModal.addEventListener('click', () => {
          setModal(roamingModals.ESIM);
        });
      }
    }, 2500);

    return () => {
      setModal('');
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.ROAMING_MOUNTING);
    }
  }, [window.utag]);

  return (
    <>
      {/* <section className="position-relative py-5"> */}
      <section className="position-relative pt-5 pb-56 pb-lg-80">
        {/* <div className="container pt-0 pt-md-5 py-5 pb-2 pb-sm-5 mt-0 mt-md-5"> */}
        <div className="container pt-0 pt-md-5 mt-0 mt-md-5">
          {/* <div className="row py-5 pb-0 pb-sm-5"> */}
          <div className="row pt-5">
            {/* <div className="col-12 position-relative">
              <div className="orange-gray-bubble"></div>
            </div> */}
            <div className="col-12 col-xl-7 offset-xl-1 col-lg-8 offset-lg-1 col-md-10 offset-md-1">
              <h1
                className="roaming-heading primary-color-text my-5 mt-0 mt-sm-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_h1') }}
              />
              <div
                className="body-large-regular primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text') }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="eu-social-section bg-offWhite-color pt-md-5 mb-5 mb-md-0 mt-md-5 gray-shade-1 gray-shade-5 position-relative"> */}
      <section className="eu-social-section bg-offWhite-color position-relative pt-56 pt-lg-80">
        {/* <div className="container py-5 py-md-4 py-lg-5"> */}
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-1 col-md-10 offset-md-1 mt-4 mt-md-0">
              <h4
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_opt_ques') }}
              />
              <div
                className="primary-color-text body-large-regular mb-5 mb-sm-0 pe-md-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_opt_ques_text') }}
              />
            </div>
          </div>
          {/* <div className="row pb-5 mb-0 mb-xl-5"> */}
          <div className="row">
            <div className="col-sm-4 col-xl-3 col-12">
              <div className="text-center mobile-100 py-0 py-xl-5 mb-5">
                <img className="mx-100" src={appImages.bubbleOuter} alt="Telefonieren" />
                <div
                  className="heading-small primary-color-text w-100 d-block mb-2"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_opt1') }}
                />
                <div
                  className="body-medium-regular primary-color-text w-100 px-md-2 m-auto"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_opt1_text') }}
                />
              </div>
            </div>
            <div className="col-sm-4 col-xl-4 col-12">
              <div className="text-center mobile-100 py-0 py-xl-5 mb-5">
                <img className="mx-100" src={appImages.freedom} alt="SMS nutzen" />
                <div
                  className="heading-small primary-color-text w-100 d-block mb-2"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_opt2') }}
                />
                <div
                  className="body-medium-regular primary-color-text w-100 px-md-2 m-auto"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_opt2_text') }}
                />
              </div>
            </div>
            <div className="col-sm-4 col-xl-3 col-12">
              {/* <div className="text-center mobile-100 py-0 py-xl-5 mb-0 mb-sm-5"> */}
              <div className="text-center mobile-100 py-0 py-xl-5 mb-5">
                <img className="mx-100" src={appImages.globalFreedom} alt="Surfen" />
                <div
                  className="heading-small primary-color-text w-100 d-block mb-2"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_opt3') }}
                />
                <div
                  className="body-medium-regular primary-color-text w-100 px-md-2 m-auto"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_opt3_text') }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative py-56 py-lg-80">
        <div className="container">
          {/* <div className="row py-4"> */}
          <div className="row">
            {/* <div className="col-12 col-md-10 offset-md-1 mb-5 py-4 py-sm-5"> */}
            <div className="col-12 col-md-10 offset-md-1">
              <h4
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text6') }}
              />
              <div
                className="body-large-regular primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text1') }}
              />
              <div
                className="body-large-regular primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text2') }}
              />
              <div
                className="body-large-bold primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text3_bold') }}
              />
              <div
                className="body-large-regular primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text4') }}
              />
              <div
                className="body-large-regular primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text5_bold') }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="gray-shade-1 bg-offWhite-color position-relative service-gray-shade-2 my-md-5 py-md-5"> */}
      <section className="bg-offWhite-color position-relative py-56 py-lg-80">
        <div className="container">
          {/* <div className="row pb-5 pt-5 pt-sm-0 mb-5"> */}
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <h4
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text7_bold') }}
              />
              <div
                className="primary-color-text body-large-regular mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text8') }}
              />
              <h5
                className="primary-color-text mt-4 mt-md-0 mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text9_bold') }}
              />
              <div
                className="primary-color-text body-large-regular mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text10') }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="my-5 my-md-0 my-lg-5 py-5"> */}
      <section className="py-56 py-lg-80">
        {/* <div className="container pb-0 pb-sm-5"> */}
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <h4
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_headline1') }}
              />
              <h5
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_subheadline1') }}
              />
              <div
                className="body-large-regular primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text11') }}
              />
              <h5
                className="primary-color-text mb-4 mt-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_subheadline2') }}
              />
              <ol className="body-large-regular d-grid gap-3 ps-4">
                <li>
                  <a className="link primary-color-text" href="#roaming-list-1">
                    {t('sh_web_roaming_checklist1')}
                  </a>
                </li>
                <li>
                  <a className="link primary-color-text" href="#roaming-list-2">
                    {t('sh_web_roaming_checklist2')}
                  </a>
                </li>
                <li>
                  <a className="link primary-color-text" href="#roaming-list-3">
                    {t('sh_web_roaming_checklist3')}
                  </a>
                </li>
                <li>
                  <a className="link primary-color-text" href="#roaming-list-4">
                    {t('sh_web_roaming_checklist4')}
                  </a>
                </li>
                <li>
                  <a className="link primary-color-text" href="#roaming-list-5">
                    {t('sh_web_roaming_checklist5')}
                  </a>
                </li>
                <li>
                  <a className="link primary-color-text" href="#roaming-list-6">
                    {t('sh_web_roaming_checklist6')}
                  </a>
                </li>
              </ol>
              {/* <ul className="mt-5 list-unstyled service-list d-flex flex-column gap-5 gap-md-4 mb-xl-5"> */}
              <ul className="mt-5 list-unstyled service-list d-flex flex-column gap-5 gap-md-4">
                <li className="d-md-flex align-items-center flex-wrap" id="roaming-list-1">
                  <div className="d-flex align-items-center flex-column flex-sm-row mb-2">
                    <span className="rounded-circle bg-primary-color text-white d-flex align-items-center justify-content-center body-large-bold me-sm-3 mb-4 mb-md-0 ms-md-0">
                      1
                    </span>
                    <h6
                      className="heading-small primary-color-text mb-md-0 mb-3"
                      dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_subheadline3') }}
                    />
                  </div>
                  <div
                    className="body-large-regular primary-color-text ms-md-5 ps-md-3"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text12') }}
                  />
                  <a
                    className="body-large-regular link primary-color-text ms-md-5 ps-md-3"
                    variant="link"
                    onClick={() => {
                      setModal(roamingModals.DEACTIVATE_SWITZERLAND_ROAMING);
                    }}
                  >
                    {t('sh_web_roaming_link')}
                  </a>
                </li>
                <li className="d-md-flex align-items-center flex-wrap" id="roaming-list-2">
                  <div className="d-flex align-items-center flex-column flex-sm-row mb-2">
                    <span className="rounded-circle bg-primary-color text-white d-flex align-items-center justify-content-center body-large-bold me-sm-3 mb-4 mb-md-0 ms-md-0">
                      2
                    </span>
                    <h6
                      className="heading-small primary-color-text mb-md-0 mb-3"
                      dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_subheadline4') }}
                    />
                  </div>
                  <div
                    className="body-large-regular primary-color-text ms-md-5 ps-md-3"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text13') }}
                  />
                </li>
                <li className="d-md-flex align-items-center flex-wrap" id="roaming-list-3">
                  <div className="d-flex align-items-center flex-column flex-sm-row mb-2">
                    <span className="rounded-circle bg-primary-color text-white d-flex align-items-center justify-content-center body-large-bold me-sm-3 mb-4 mb-md-0 ms-md-0">
                      3
                    </span>
                    <h6
                      className="heading-small primary-color-text mb-md-0 mb-3"
                      dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_subheadline5') }}
                    />
                  </div>
                  <div
                    className="body-large-regular primary-color-text ms-md-5 ps-md-3"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text14') }}
                  />
                </li>
                <li className="d-md-flex align-items-center flex-wrap" id="roaming-list-4">
                  <div className="d-flex align-items-center flex-column flex-sm-row mb-2">
                    <span className="rounded-circle bg-primary-color text-white d-flex align-items-center justify-content-center body-large-bold me-sm-3 mb-4 mb-md-0 ms-md-0">
                      4
                    </span>
                    <h6
                      className="heading-small primary-color-text mb-md-0 mb-3"
                      dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_subheadline6') }}
                    />
                  </div>
                  <div
                    className="body-large-regular primary-color-text ms-md-5 ps-md-3"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text15') }}
                  />
                  <a
                    className="body-large-regular link primary-color-text ms-md-5 ps-md-3"
                    variant="link"
                    onClick={() => {
                      setModal(roamingModals.DEACTIVATE_WLAN_CALLS);
                    }}
                  >
                    {t('sh_web_roaming_link')}
                  </a>
                </li>
                <li className="d-md-flex align-items-center flex-wrap" id="roaming-list-5">
                  <div className="d-flex align-items-center flex-column flex-sm-row mb-2">
                    <span className="rounded-circle bg-primary-color text-white d-flex align-items-center justify-content-center body-large-bold me-sm-3 mb-4 mb-md-0 ms-md-0">
                      5
                    </span>
                    <h6
                      className="heading-small primary-color-text mb-md-0 mb-3"
                      dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_subheadline7') }}
                    />
                  </div>
                  <div
                    className="body-large-regular primary-color-text ms-md-5 ps-md-3"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text16') }}
                  />
                </li>
                <li className="d-md-flex align-items-center flex-wrap" id="roaming-list-6">
                  <div className="d-flex align-items-center flex-column flex-sm-row mb-2">
                    <span className="rounded-circle bg-primary-color text-white d-flex align-items-center justify-content-center body-large-bold me-sm-3 mb-4 mb-md-0 ms-md-0">
                      6
                    </span>
                    <h6
                      className="heading-small primary-color-text mb-md-0 mb-3"
                      dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_subheadline8') }}
                    />
                  </div>
                  <div
                    className="body-large-regular primary-color-text ms-md-5 ps-md-3"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_text17') }}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="gray-shade-1 bg-offWhite-color position-relative service-gray-shade-2 mb-5 py-5"> */}
      <section className="bg-offWhite-color position-relative py-56 py-lg-80">
        {/* <div className="container mb-0 mb-sm-5 pb-0 pb-sm-5"> */}
        <div className="container">
          {/* <div className="row mb-0 mb-sm-5"> */}
          <div className="row">
            <div className="col-12 col-md-5 offset-md-1 col-lg-4 offset-lg-1">
              <h5
                className="primary-color-text mb-md-4 pe-lg-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_headline2') }}
              />
            </div>
            <div className="col-12 col-md-6">
              <Accordion items={accordianRoamingItem} />
            </div>
          </div>
        </div>
      </section>
      {modal === roamingModals.LANDER_ZONE && <LanderZoneModal setModal={setModal} />}
      {modal === roamingModals.ESIM && <ESimSmartPhoneListModal setModal={setModal} />}
      {modal === roamingModals.DEACTIVATE_SWITZERLAND_ROAMING && (
        <DeactivateSwitzerlandRoamingModal setModal={setModal} />
      )}
      {modal === roamingModals.DEACTIVATE_WLAN_CALLS && (
        <DeactivateWlanCallsModal setModal={setModal} />
      )}
      {/* <section className="py-1 d-block d-sm-none"> &nbsp;</section> */}
    </>
  );
}

export default Roaming;
