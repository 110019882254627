import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

// Images of for friends page
// import { useActivation } from '@context/MobileOne';
import { TariffPicker } from '@part/Tariff/Catalogue/Picker';
// import { appActivationFlowType, appImages } from '@utils/globalConstant';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_VIEWS } from '@utils/utag';
import { useLayout } from '@context/Utils';
import { Image } from '@core/Utils';

import './style.scss';
// Images of for friends page

export function WhyShareMobile() {
  // Contexts
  const { t, staticContentData } = useStaticContent();
  const { setMetaTags } = useMetaTag();
  // const { setActivationType } = useActivation();

  const { setLightHeader, screenSize } = useLayout();

  useEffect(() => {
    setLightHeader(false);
    // setActivationType(appActivationFlowType.NEW_ONLINE);

    return () => {
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_ueber_meta_title'),
      description: t('sh_web_page_ueber_meta_description'),

      twitterTitle: t('sh_web_page_ueber_meta_title'),
      twitterDescription: t('sh_web_page_ueber_meta_description'),

      ogTitle: t('sh_web_page_ueber_meta_title'),
      ogDescription: t('sh_web_page_ueber_meta_description')
    });
    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.WHY_SHARE_MOBILE_MOUNTING);
    }
  }, [window.utag]);

  return (
    <>
      <section className="position-relative pt-10 mb-5 mb-sm-0">
        <div className="container-fluid pb-0 pb-xl-0 pt-0 pt-md-0 pt-xl-5">
          <div className="row py-5 pt-md-5 pt-md-5 pb-md-5 flex-column-reverse flex-xl-row flex-md-row">
            <div className="col-xl-5 col-lg-5 col-md-5 col-12 me-5 z-1 text-xl-end me-md-0 me-xl-5 p-0">
              {/* <img
                className="mx-100 mobile-width-100"
                src={appImages.shareStage}
                alt="top banner"
              /> */}
              <Image
                className="mx-100 position-absolute top-image-share"
                refs="sh_web_about_content-1"
                alt="top banner"
                mobileStyle={{ height: '322', width: '294' }}
                ipadStyle={{ height: '395', width: '360' }}
              />
            </div>
            <div className="col-xl-5 col-lg-7 offset-lg-5 offset-xxl-6 offset-xl-6 col-md-6 offset-md-1 col-12 ps-xl-5 mt-5 mt-md-0 mt-lg-0 mt-xl-5 about-heading-text">
              <h1
                className="primary-color-text my-4 mt-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_h1') }}
              />
              <div
                className="body-large-bold primary-color-text mb-2"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_subheadline') }}
              />
              <div
                className="body-large-regular primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_text') }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="get-more-gb-row position-relative bg-primary-color pt-5 pb-0 pb-sm-5">
        <div className="container pb-5 pt-10 py-lg-5">
          <div className="row pt-xxl-5">
            <div className="col-md-6 col-lg-5 col-xl-4 offset-xl-1">
              <h2
                className="mb-4 pink-color-text w-75"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_headline') }}
              />
            </div>
            <div className="col-md-6 col-lg-4 mt-md-5 ms-xl-2">
              <div
                className="body-large-bold text-white mb-0 mt-0 mt-md-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_subheadline1') }}
              />
              <div
                className="body-large-regular text-white"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_text2') }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="uber-share-section position-relative">
        {/* <img
          className="mx-100 w-100 d-none d-md-block"
          src={appImages.aboutSmartphone}
          alt="Share About Smartphone"
        />
        <img
          className="mx-100 w-100 d-block d-md-none"
          src={appImages.aboutSmartphoneMobile}
          alt="Share About Smartphone"
        /> */}
        <Image
          className="mx-100 w-100"
          refs="sh_web_lg_about_bg-1"
          resRefs="sh_web_md_about_bg-1"
          alt="Share Mobile app"
          isBannerImage
        />
      </section>
      <section className="position-relative py-2 py-sm-5 mt-4 mt-sm-0 mb-lg-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5 col-xl-4 offset-xl-1">
              <h2
                className="mb-4 primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_headline2') }}
              />
            </div>
            <div className="col-md-6 col-lg-5 mt-md-5 ms-xl-2">
              <div
                className="body-large-bold primary-color-text mt-md-5 mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_subheadline2') }}
              />

              <div
                className="body-large-regular primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_text3') }}
              />
            </div>
          </div>
        </div>
      </section>

      <Image
        className="share-mobile-section uber-tarif-section position-relative pt-10 pb-5 mt-5 mt-sm-0"
        refs="sh_web_lg_about_bg-2"
        resRefs="sh_web_md_tarif_bg-1"
        mobileStyle={{ height: '478', width: '375' }}
        sectionWithImage
      >
        <div className="container pb-lg-5 mb-lg-5 pt-5">
          <div className="row pb-lg-5">
            <div className="col-12 col-md-5 col-lg-5 col-xl-4 my-5 py-xl-5 mt-md-0">
              <h1
                className="text-white mt-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_headline3') }}
              />
              <div
                className="text-white body-large-regular mt-4 mb-xl-5 pb-xl-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_subheadline3') }}
              />
            </div>
            {screenSize >= 768 && (
              <div className="col-12 col-md-7 offset-lg-2 col-lg-5 offset-xl-3 col-xl-5 d-none d-md-block">
                <TariffPicker customeClass="about-page-tariff" />
              </div>
            )}
          </div>
        </div>
      </Image>

      <section className="question-row position-relative bg-offWhite-color py-5">
        <div className="container d-block d-md-none mob-tariff">
          <div className="row">
            {screenSize < 768 && (
              <div className="col-12 col-md-7 offset-lg-2 col-lg-5 offset-xl-3 col-xl-5">
                <TariffPicker customeClass="about-page-tariff" />
              </div>
            )}
          </div>
        </div>
        <div className="container pb-0 mt-4 mt-sm-0 pt-md-5">
          <div className="row pt-5">
            <div className="col-md-5 col-lg-5 col-xl-4 offset-xl-1">
              <h2
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_headline4') }}
              />
              <div
                className="body-large-bold primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_subheadline4') }}
              />
            </div>
            <div className="col-md-6 col-lg-5 mt-md-5 pt-xl-0 ms-xl-2 pt-md-5 pt-xl-0 mb-4">
              <div className="d-none d-lg-block pt-md-5 py-4 my-3">&nbsp;</div>
              <div
                className="body-medium-regular primary-color-text mb-3 pt-xl-0 pt-md-5 pt-0 mt-xl-0 mt-md-3 mt-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_text4') }}
              />
              <div
                className="body-medium-regular primary-color-text mb-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_about_us_text5') }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="d-block d-sm-none py-3">&nbsp;</section>
    </>
  );
}

export default WhyShareMobile;
