import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button } from '@core/Inputs';
import { useActivation } from '@context/MobileOne';
import { useSimActivation } from '@context/MobileOne/Activation/SimActivation';

import { YourTariff } from './YourTariff';
import { UserAccount } from './UserAccount';
import { Terms } from './Terms';
import SelectedPhoneNumber from './SelectedPhoneNumber';
import SelectedPaymentMethod from './SelectedPaymentMethod';
import { appActivationFlowType, appButtonType } from '@utils/globalConstant';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';

export function OverView() {
  // State

  // Context
  const {
    activationType,
    setActivationInfo,
    activationInfo,
    setCurrentStep,
    setEditStep,
    prevStep
  } = useActivation();

  const { t } = useStaticContent();
  const { onSubmit } = useSimActivation();

  useEffect(() => {
    setTimeout(() => {
      const {
        isPromoApplied,
        activeTariff: { name = '' } = { name: '' },
        isNewNumber
      } = activationInfo;
      if (window && window.utag) {
        const tariffName = `${name}`.toLocaleLowerCase().replaceAll(' ', '-');
        const tagInfo = {
          page_content_id:
            activationType === appActivationFlowType.NEW_ONLINE
              ? 'www.share-mobile_de.checkout.zusammenfassung'
              : 'www.share-mobile_de.aktivieren.zusammenfassung',
          customer_type:
            activationType === appActivationFlowType.NEW_ONLINE
              ? 'online-neukunde'
              : 'pos-neukunde',
          mgm_code: isPromoApplied ? 'mgm-aktiv' : 'mgm-nicht-aktiv',
          shop_order_phone_number: isNewNumber === 'true' ? 'neue-rufnummer' : 'rufnummermitnahme',
          shop_order_sim: activationInfo.eSim === 'true' ? 'e-sim' : 'sim-karte',
          shop_order_payment_mode:
            activationInfo &&
            activationInfo.payment &&
            activationInfo.payment.paymentMethod === 'paypal'
              ? 'paypal'
              : 'kreditkarte',
          shop_product_status: 'view',
          shop_product_name: tariffName === 'allnet-flat-m' ? 'allnet-flat-m' : 'allnet-flat-l',
          shop_product_price: tariffName === 'allnet-flat-m' ? '10.00' : '15.00',
          shop_product_quantity: '1'
        };
        window.utag.view(tagInfo);
      }
      // if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
      //   const tariffName = `${name}`.toLocaleLowerCase().replaceAll(' ', '-');
      //   const tagInfo = isPromoApplied
      //     ? isNewNumberSelected
      //       ? tariffName ===
      //         UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //           .ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE.shop_product_name
      //         ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //             .ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE
      //         : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //             .ALLNET_FLAT_M_NEW_NUMBER_MGM_ACTIVE
      //       : tariffName ===
      //         UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //           .ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE.shop_product_name
      //       ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //           .ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_ACTIVE
      //       : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //           .ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_ACTIVE
      //     : isNewNumberSelected
      //     ? tariffName ===
      //       UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //         .ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE.shop_product_name
      //       ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //           .ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE
      //       : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //           .ALLNET_FLAT_M_NEW_NUMBER_MGM_NON_ACTIVE
      //     : tariffName ===
      //       UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //         .ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE.shop_product_name
      //     ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //         .ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_NON_ACTIVE
      //     : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //         .ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_NON_ACTIVE;

      //   window.utag.view(tagInfo);
      // }
      // if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
      //   const tariffName = `${name}`.toLocaleLowerCase().replaceAll(' ', '-');
      //   const tagInfo = isPromoApplied
      //     ? isNewNumberSelected
      //       ? tariffName ===
      //         UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING.ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE
      //           .shop_product_name
      //         ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //             .ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE
      //         : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //             .ALLNET_FLAT_M_NEW_NUMBER_MGM_ACTIVE
      //       : tariffName ===
      //         UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING.ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE
      //           .shop_product_name
      //       ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //           .ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_ACTIVE
      //       : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //           .ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_ACTIVE
      //     : isNewNumberSelected
      //     ? tariffName ===
      //       UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //         .ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE.shop_product_name
      //       ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //           .ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE
      //       : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //           .ALLNET_FLAT_M_NEW_NUMBER_MGM_NON_ACTIVE
      //     : tariffName ===
      //       UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //         .ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE.shop_product_name
      //     ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //         .ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_NON_ACTIVE
      //     : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.OVERVIEW_MOUNTING
      //         .ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_NON_ACTIVE;

      //   window.utag.view(tagInfo);
      // }
    }, 500);
  }, [window.utag]);

  return (
    <div className="container">
      <div className="row position-relative">
        {/* <div className="col-12 orange-gray-bubble"></div> */}
        <Formik
          enableReinitialize
          initialValues={{ ...activationInfo }}
          onSubmit={(values) => {
            if (window && window.utag) {
              activationType === appActivationFlowType.NEW_ONLINE
                ? window.utag.link(UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.ORDER_ON_CLICK)
                : window.utag.link(UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.ORDER_ON_CLICK);
            }

            onSubmit(values);
          }}
        >
          {({
            values: {
              acceptedCreditCheck,
              termsAndConditions,
              marketingMultibrand,
              codeForValidation
            },
            handleBlur,
            handleChange,
            errors,
            setErrors,
            touched,
            handleSubmit,
            setFieldValue,
            setTouched,
            setFieldTouched
          }) => (
            <>
              <div>
                <div className="row">
                  <div className="col-md-3 pb-5 mt-5">
                    <div className="back-btn">
                      <a
                        className="text-white body-medium-bold text-decoration-none link-light"
                        onClick={() => {
                          if (window && window.utag) {
                            activationType === appActivationFlowType.NEW_ONLINE
                              ? window.utag.link(
                                  UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.BACK_ON_CLICK
                                )
                              : window.utag.link(
                                  UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.BACK_ON_CLICK
                                );
                          }

                          prevStep();
                        }}
                      >
                        <i className="fa fa-arrow-left me-3" />
                        {t('sh_back_button')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <Form onSubmit={handleSubmit}>
                <div className="mt-sm-5">
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-1 col-lg-6 offset-lg-1 mb-5 mb-sm-0">
                      <h6 className="text-uppercase text-white mb-3">
                        {t(
                          activationType === appActivationFlowType.SIM_ACTIVATION
                            ? 'sh_apoc_step9/9'
                            : 'sh_apoc_summary_step8'
                        )}
                      </h6>
                      <h1 className="pink-color-text mt-2 mt-sm-2 mb-sm-5">
                        {t('sh_apoc_summary_h1')}
                      </h1>
                      <p className="text-white font-24 body-large-regular mt-3 pt-2 mb-0 mb-sm-5">
                        {t('sh_apoc_summary_text1')}
                      </p>
                    </div>
                  </div>

                  <YourTariff
                    codeForValidation={codeForValidation}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setTouched={setTouched}
                    setErrors={setErrors}
                    setFieldTouched={setFieldTouched}
                    handleBlur={handleBlur}
                  />

                  <SelectedPhoneNumber
                    activationInfo={activationInfo}
                    setCurrentStep={setCurrentStep}
                    setEditStep={setEditStep}
                    setActivationInfo={setActivationInfo}
                  />

                  <SelectedPaymentMethod
                    activationType={activationType}
                    activationInfo={activationInfo}
                    setActivationInfo={setActivationInfo}
                    setCurrentStep={setCurrentStep}
                    setEditStep={setEditStep}
                  />

                  <UserAccount
                    activationType={activationType}
                    activationInfo={activationInfo}
                    setEditStep={setEditStep}
                    setCurrentStep={setCurrentStep}
                  />

                  <Terms
                    activationInfo={activationInfo}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setActivationInfo={setActivationInfo}
                    errors={errors}
                    touched={touched}
                    codeForValidation={activationInfo.codeForValidation}
                    marketingMultibrand={marketingMultibrand}
                  />

                  <div className="col-12 col-md-8 offset-md-1 col-lg-4 offset-lg-1 pb-5 pb-sm-0 mt-1 mt-sm-0 mb-5 mb-sm-0">
                    <Button
                      buttonType={appButtonType.PRIMARY}
                      className="w-100"
                      type="submit"
                      disabled={!acceptedCreditCheck || !termsAndConditions}
                    >
                      {t('sh_apoc_summary_order_button')}
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default OverView;
