import React from 'react';
import { appImages, appSecBgColorTypes, appSecBottomElementTypes } from '@utils/globalConstant';
import { Section } from '@core/Layout';

// import { Col, Container, Row } from '@core/Layout';
// import { Switch, Text } from '@core/Inputs';
// import { ToolTip } from '@core/Utils';

export function Test() {
  return (
    <>
      <section className="container mb-5">
        <div className="mt-5 row">
          <h6>DESIGN TOKENS</h6>
          <h3 className="primary-color-text">Color</h3>
          <div className="col my-5">
            <h4 className="primary-color-text">Shapes</h4>
            <ul className="list-inline mt-3">
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-primary-color"></figure>
                <h6>Purple (#534164)</h6>
                <span>color: $purple </span>
              </li>
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-pink-color"></figure>
                <h6>Pink (#E999AB)</h6>
                <span>color: $pink </span>
              </li>
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-coral-color"></figure>
                <h6>Coral (#F39E72)</h6>
                <span>color: $coral</span>
              </li>
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-mango-color"></figure>
                <h6>Mango (#F9BB5A)</h6>
                <span>color: $mango</span>
              </li>
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-yellow-color"></figure>
                <h6>Yellow (#FFDC69)</h6>
                <span>color: $yellow</span>
              </li>
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-purple-light-color"></figure>
                <h6>Light Purple (#786886)</h6>
                <span>color: $purple-light </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col my-5">
            <h4 className="primary-color-text">Neutral</h4>
            <ul className="list-inline mt-3">
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-white-color border"></figure>
                <h6>White (#FFFFFF)</h6>
                <span>color: $white </span>
              </li>
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-offWhite-color border"></figure>
                <h6>Off White (#EFECE8)</h6>
                <span>color: $off-white</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col my-5">
            <h4 className="primary-color-text">Text</h4>
            <ul className="list-inline mt-3">
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-primary-color border"></figure>
                <h6>Purple (#503A64)</h6>
                <span>color: $purple-text</span>
              </li>
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-pink-color border"></figure>
                <h6>Pink (#F2A0B3)</h6>
                <span>color: $pink-text</span>
              </li>
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-grey-color border"></figure>
                <h6>Grey (#626B6F)</h6>
                <span>color: $grey</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col my-5">
            <h4 className="primary-color-text">Status</h4>
            <ul className="list-inline mt-3">
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-error-color border"></figure>
                <h6>Error (#F43F5E)</h6>
                <span>color: $error</span>
              </li>
              <li className="px-2 list-inline-item">
                <figure className="p-5 rounded-2 bg-disabled-color border"></figure>
                <h6>Disabled (#D0D0D0)</h6>
                <span>color: $disabled</span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="container mb-5">
        <div className="row">
          <div className="mt-5 col-12">
            <h6>DESIGN TOKENS</h6>
            <h3 className="primary-color-text">Typography</h3>
            <div className="col-12 my-5">
              <h1 className="mb-5">H1 {'-> '}Fix problem quickly with galvanized jets</h1>
              <h2 className="mb-5">H2 {'-> '}Quick fox jumps nightly above wizard</h2>
              <h3 className="mb-5">H3 {'-> '}The quick brown fox jumps over the lazy dog</h3>
              <h4 className="mb-5">H4 {'-> '}Pack my red box with five dozen quality jugs</h4>
              <h5 className="mb-5">
                H5 {'-> '}The pack of my red box with five dozen quality jugs
              </h5>
              <h6 className=" text-uppercase">H6 {'-> '}Show mangled quartz flip vibe exactly</h6>
            </div>
            <div className="col-12 my-5">
              <h3 className="mb-3 primary-color-text">Body</h3>
              <p className="body-large-bold">
                The wizard quickly jinxed the gnomes before they vaporized{' '}
              </p>
              <h6 className="grey-color-text mb-5">
                Large Bold - Noto Sans (700) {'-> '}class: body-large-bold
              </h6>
              <p className="body-large-regular">The five boxing wizards jump quickly</p>
              <h6 className="grey-color-text mb-5">
                Large Regular - Noto Sans (400) {'-> '}class: body-large-regular
              </h6>
              <p className="body-medium-bold">
                The wizard quickly jinxed the gnomes before they vaporized
              </p>
              <h6 className="grey-color-text mb-5">
                Medium Bold - Noto Sans (700) {'-> '}class: body-medium-bold
              </h6>
              <p className="body-medium-regular">Heavy boxes perform quick waltzes and jigs</p>
              <h6 className="grey-color-text ">
                Medium Regular - Noto Sans (400) {'-> '}class: body-medium-regular
              </h6>
            </div>
            <div className="col-12 my-5">
              <h3 className="mb-3 primary-color-text">Label</h3>
              <p className="label-medium">Quick fox jumps nightly above wizard</p>
              <h6 className="grey-color-text mb-5">class: label-medium {'-> '}Patron (500)</h6>
              <p className="label-small">Fix problem quickly with galvanized jets</p>
              <h6 className="grey-color-text">class: label-small {'-> '}Patron (500)</h6>
            </div>
          </div>
        </div>
      </section>

      <section className="container mb-5">
        <div className="row">
          <div className="col-12 mt-5">
            <h6>COMPONENTS</h6>

            <h3 className="primary-color-text">Input field</h3>
            <form className="my-5">
              <div className="bg-offWhite-color p-4">
                <div className="mb-3">
                  <input className="form-control" type="text" value="Text" />
                </div>
                <div className="input-group mb-3">
                  <input type="text" className="form-control border-end-0" value="Text" />
                  <span className="input-group-text bg-white border-0">
                    <img src={appImages.background} alt="logo" />
                  </span>
                </div>
                <div className="input-group mb-3">
                  <input type="text" className="form-control is-invalid" value="Text" />
                </div>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control border-end-0 is-invalid"
                    value="Text"
                  />
                  <span className="input-group-text bg-white">
                    <img src={appImages.background} alt="logo" />
                  </span>
                </div>
                <div className="mb-3">
                  <input className="form-control" placeholder="Text" type="text" />
                </div>
                <div className="input-group mb-3">
                  <input type="text" className="form-control border-end-0" placeholder="Text" />
                  <span className="input-group-text bg-white border-0">
                    <img src={appImages.background} alt="logo" />
                  </span>
                </div>
                <h5 className="primary-color-text mt-5">Select Dropdown</h5>
                <select
                  className="form-select primary-color-text shadow-none body-medium-regular border-0"
                  aria-label="Default select example"
                >
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>

              <h3 className="primary-color-text mt-5">Radio button</h3>
              <div className="mt-4">
                <div className="form-check radio-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Default radio
                  </label>
                </div>
                <div className="form-check radio-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    Default checked radio
                  </label>
                </div>
              </div>

              <div className="mt-4 pb-5">
                <h5 className="primary-color-text mt-5">Dark Radio button</h5>
                <div className="form-check radio-check dark-radio">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault3"
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault3">
                    Default radio
                  </label>
                </div>
                <div className="form-check radio-check dark-radio">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault4"
                    checked
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault4">
                    Default checked radio
                  </label>
                </div>
              </div>

              <h3 className="primary-color-text mt-5">Radio selection group</h3>
              <div className="mt-4 bg-primary-color p-3">
                <div className="form-check radio-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault5"
                  />
                  <label className="form-check-label text-white" htmlFor="flexRadioDefault5">
                    <p className="body-large-bold mb-0">Default checked radio</p>
                    <p className="body-medium-regular">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                  </label>
                </div>
                <div className="form-check radio-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault6"
                    checked
                  />
                  <label className="form-check-label text-white" htmlFor="flexRadioDefault6">
                    <p className="body-large-bold mb-0">Default checked radio</p>
                    <p className="body-medium-regular">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                  </label>
                </div>
              </div>

              <div className="mt-4 px-2 bg-offWhite-color pb-3">
                <h5 className="primary-color-text p-3">Dark Radio button</h5>
                <div className="form-check radio-check dark-radio">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault7"
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault7">
                    <p className="body-large-bold primary-color-text mb-0">Default checked radio</p>
                    <p className="grey-color-text body-medium-regular">
                      {' '}
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                  </label>
                </div>
                <div className="form-check radio-check dark-radio">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault8"
                    checked
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault8">
                    <p className="body-large-bold primary-color-text mb-0">Default checked radio</p>
                    <p className="grey-color-text body-medium-regular">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                  </label>
                </div>
              </div>

              <h5 className="primary-color-text mt-5">Radio selection group</h5>
              <div className="mt-4 bg-primary-color p-3">
                <div className="form-check radio-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault9"
                  />
                  <label className="form-check-label text-white" htmlFor="flexRadioDefault9">
                    <p className="body-large-bold mb-0">Default checked radio</p>
                    <p className="my-1 body-medium-regular pink-color-text">Promotion Text</p>
                    <p className="body-medium-regular mb-2">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                    <a className="text-white label-medium" href="">
                      Link label
                    </a>
                  </label>
                </div>
                <div className="form-check radio-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault10"
                    checked
                  />
                  <label
                    className="form-check-label text-white body-medium-regular"
                    htmlFor="flexRadioDefault10"
                  >
                    <p className="body-large-bold mb-0">Default checked radio</p>
                    <p className="my-1 body-medium-regular pink-color-text">Promotion Text</p>
                    <p className="body-medium-regular mb-2">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                    <a className="text-white label-medium" href="">
                      Link label
                    </a>
                  </label>
                </div>
              </div>

              <div className="mt-4 px-2 bg-offWhite-color pb-3">
                <h5 className="primary-color-text p-3">Dark Radio button</h5>
                <div className="form-check radio-check dark-radio">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault11"
                  />
                  <label
                    className="form-check-label primary-color-text"
                    htmlFor="flexRadioDefault11"
                  >
                    <p className="body-large-bold mb-0">Default checked radio</p>
                    <p className="my-1 body-medium-regular pink-color-text">Promotion Text</p>
                    <p className="body-medium-regular grey-color-text mb-2">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                    <a className="primary-color-text label-medium" href="">
                      Link label
                    </a>
                  </label>
                </div>
                <div className="form-check radio-check dark-radio">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault12"
                    checked
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault12">
                    <p className="body-large-bold mb-0">Default checked radio</p>
                    <p className="my-1 body-medium-regular pink-color-text">Promotion Text</p>
                    <p className="body-medium-regular grey-color-text mb-2">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                    <a className="primary-color-text label-medium" href="">
                      Link label
                    </a>
                  </label>
                </div>
              </div>
              {/* Button Components */}
              <div className="mt-5 bg-offWhite-color p-4">
                <h6>COMPONENTS</h6>
                <h3 className="primary-color-text">Buttons</h3>
                <button type="button" className="btn btn-primary">
                  Primary Button
                </button>
                <br />
                <br />
                <button type="button" className="btn btn-secondary">
                  Secondary Button
                </button>
                <br />
                <br />
                <button disabled type="button" className="btn btn-disabled">
                  Disabled Button
                </button>
                <br />
                <br />
                <button type="button" className="btn btn-outline-secondary">
                  Secondary Outline Button
                </button>
              </div>

              {/* Switch Button Components */}
              <div className="mt-5">
                <h6>COMPONENTS</h6>
                <h3 className="primary-color-text">Switch Buttons</h3>
                <div className="bg-offWhite-color p-3 mt-3">
                  <div className="form-check form-switch d-flex justify-content-between align-items-center ps-0">
                    <label
                      className="form-check-label body-medium-regular primary-color-text"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Ich habe die Mitnahme bei meinem aktuellen Anbieter beantragt (Opt-In). Mir
                      ist bewusst, dass doppelte Kosten entstehen können, wenn mein bisheriger Tarif
                      noch eine Laufzeit hat.
                    </label>
                    <input
                      className="form-check-input shadow-none"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
                <div className="bg-primary-color p-3 mt-3">
                  <div className="form-check form-switch dark-switch d-flex justify-content-between align-items-center ps-0">
                    <label
                      className="form-check-label body-large-regular text-white"
                      htmlFor="flexSwitchCheckDefault1"
                    >
                      Ich habe die Mitnahme bei meinem aktuellen Anbieter beantragt (Opt-In). Mir
                      ist bewusst, dass doppelte Kosten entstehen können, wenn mein bisheriger Tarif
                      noch eine Laufzeit hat.
                    </label>
                    <input
                      className="form-check-input shadow-none"
                      type="checkbox"
                      id="flexSwitchCheckDefault1"
                    />
                  </div>
                </div>
              </div>

              {/* Modal Component */}
              <div className="mt-5 bg-offWhite-color p-4">
                <h6>COMPONENTS</h6>
                <h3 className="primary-color-text">Modals</h3>
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Launch demo modal
                </button>
                {/* On open please add "show d-block" classes to modal*/}
                <div
                  className="modal fade share-modal"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-offWhite-color border-0">
                      <div className="modal-header justify-content-start p-0 border-0">
                        <button
                          type="button"
                          className="btn-close mx-0 me-1 shadow-none"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                        <h5 className="modal-title body-medium-bold grey-color-text">Abbrechen</h5>
                      </div>

                      <div className="modal-body mt-3 p-2">
                        <h6 className="text-uppercase grey-color-text">
                          Zusätzliche Angaben (1 / 3)
                        </h6>
                        <h3 className="primary-color-text">Aktueller Mobilfunkanbieter</h3>
                        <p className="mt-4 body-large-regular grey-color-text">
                          Bei welchem Mobilfunkanbieter bist du gerade?
                        </p>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Dein aktueller Mobilfunkanbieter"
                        />
                        <div className="mt-4">
                          <h6 className="text-uppercase grey-color-text">Alle Anbieter</h6>
                          <ul className="list-unstyled category-link mt-4">
                            <li>
                              <a
                                className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                                href=""
                              >
                                1und1 Internet AG – 1UN1 <i className="fa fa-arrow-right fa-lg"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                                href=""
                              >
                                4B Flexz! - TURK <i className="fa fa-arrow-right fa-lg"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                                href=""
                              >
                                ALDI Talk (MEDIONmobile) - EP...{' '}
                                <i className="fa fa-arrow-right fa-lg"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                                href=""
                              >
                                Alphatel - ALPH <i className="fa fa-arrow-right fa-lg"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                                href=""
                              >
                                Blau Mobilfunk (Tarif Blau) - BL...{' '}
                                <i className="fa fa-arrow-right fa-lg"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Schließen Modal Component */}
              <div className="mt-5 bg-offWhite-color p-4 d-none">
                <h3 className="primary-color-text">Schließen Modals</h3>
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Schließen Launch demo modal
                </button>
                {/* On open please add "show d-block" classes to modal*/}
                <div
                  className="modal fade show d-block share-modal consent-modal"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-offWhite-color border-0 p-4">
                      <div className="modal-header justify-content-start p-0 border-0">
                        <button
                          type="button"
                          className="btn-close mx-0 me-1 shadow-none"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                        <h5 className="modal-title body-medium-bold grey-color-text">Schließen</h5>
                      </div>

                      <div className="modal-body mt-3 p-0">
                        <h5 className="primary-color-text mt-3">
                          Verwalte deine Datenschutzeinstellungen
                        </h5>
                        <p className="mt-4 body-medium-regular primary-color-text">
                          Um dir ein optimales Webseitenerlebnis zu bieten, setzen wir Cookies ein.
                          Dazu zählen Cookies für den Betrieb und die Optimierung der Seite als auch
                          für Services, wie die an dein online Nutzungsverhalten orientierte
                          Werbung. So kann z. B. erkannt werden, wenn du unsere Seiten vom selben
                          Gerät aus wiederholt besuchst. Wir möchten dir die Wahl geben, welche
                          Cookies du zulässt:
                        </p>

                        <div className="d-grid gap-3 mt-4 pt-3">
                          <h6 className="heading-small primary-color-text">Erforderliche Tools</h6>
                          <div className="form-check form-switch d-flex justify-content-between align-items-center ps-0">
                            <label
                              className="form-check-label body-medium-regular primary-color-text"
                              htmlFor="flexSwitchCheckDefault4"
                            >
                              Diese Cookies sind notwendig, damit du durch die Seiten navigieren und
                              wesentliche Funktionen nutzen kannst.
                            </label>
                            <div className="justify-content-center d-flex flex-wrap">
                              <input
                                className="form-check-input shadow-none"
                                type="checkbox"
                                id="flexSwitchCheckDefault4"
                              />
                              <p className="body-medium-regular mb-0 purple-light-color-text mt-2">
                                erforderlich
                              </p>
                            </div>
                          </div>
                          <a className="link primary-color-text body-medium-regular" href="#">
                            Mehr erfahren
                          </a>
                        </div>

                        <div className="d-grid gap-3 mt-4 pt-3">
                          <h6 className="heading-small primary-color-text">Analytische Tools</h6>
                          <div className="form-check form-switch d-flex justify-content-between align-items-center ps-0">
                            <label
                              className="form-check-label body-medium-regular primary-color-text"
                              htmlFor="flexSwitchCheckDefault5"
                            >
                              Diese Cookies helfen uns, das Nutzungsverhalten besser zu verstehen.
                            </label>
                            <div className="justify-content-center d-flex flex-wrap">
                              <input
                                className="form-check-input shadow-none"
                                type="checkbox"
                                id="flexSwitchCheckDefault5"
                              />
                              <p className="body-medium-regular mb-0 purple-light-color-text mt-2">
                                nicht erlauben
                              </p>
                            </div>
                          </div>
                          <a className="link primary-color-text body-medium-regular" href="#">
                            Weniger anzeigen
                          </a>
                          <p className="body-medium-regular primary-color-text">
                            Analyse-Cookies ermöglichen die Erhebung von Nutzungs- und
                            Erkennungsmöglichkeiten durch Erst- oder Drittanbieter, in so genannten
                            pseudonymen Nutzungsprofilen. Wir benutzen beispielsweise
                            Analyse-Cookies, um die Zahl der individuellen Besucher einer Webseite
                            oder eines Dienstes zu ermitteln oder um andere Statistiken im Hinblick
                            auf den Betrieb unserer Produkte zu erheben, als auch das
                            Nutzerverhalten auf Basis anonymer und pseudonymer Informationen zu
                            analysieren, wie Besucher mit der Webseite interagieren. Ein
                            unmittelbarer Rückschluss auf eine Person ist dabei nicht möglich.
                          </p>
                        </div>

                        <div className="d-grid gap-3 mt-4">
                          <h6 className="heading-small primary-color-text">Marketing-Tools</h6>
                          <div className="form-check form-switch d-flex justify-content-between align-items-center ps-0">
                            <label
                              className="form-check-label body-medium-regular primary-color-text"
                              htmlFor="flexSwitchCheckDefault6"
                            >
                              Diese Cookies und ähnliche Technologien werden eingesetzt, um dir
                              personalisierte und dadurch relevante werbliche Inhalte anzeigen zu
                              können.
                            </label>
                            <div className="justify-content-center d-flex flex-wrap">
                              <input
                                className="form-check-input shadow-none"
                                type="checkbox"
                                id="flexSwitchCheckDefault6"
                              />
                              <p className="body-medium-regular mb-0 primary-color-text mt-2">
                                erlauben
                              </p>
                            </div>
                          </div>
                          <a className="link primary-color-text body-medium-regular" href="#">
                            Mehr erfahren
                          </a>
                        </div>

                        <div className="d-grid gap-3 mt-4 pt-3">
                          <h6 className="heading-small primary-color-text">
                            Dienste von anderen Unternehmen (eigenverantwortliche Drittanbieter)
                          </h6>
                          <div className="form-check form-switch d-flex justify-content-between align-items-center ps-0">
                            <label
                              className="form-check-label body-medium-regular primary-color-text"
                              htmlFor="flexSwitchCheckDefault7"
                            >
                              Auf Seiten der Telekom werden Drittanbieterdienste eingebunden, die
                              ihre Services eigenverantwortlich oder in gemeinsamer Verantwortung
                              mit Telekom Deutschland GmbH erbringen. Hierbei werden Daten und
                              Informationen an Drittanbieter übermittelt, zu eigenen werblichen
                              Zwecke verarbeitet und mit Daten Dritter zusammengeführt.
                            </label>
                            <div className="justify-content-center d-flex flex-wrap">
                              <input
                                className="form-check-input shadow-none"
                                type="checkbox"
                                id="flexSwitchCheckDefault7"
                              />
                              <p className="body-medium-regular mb-0 primary-color-text mt-2">
                                erlauben
                              </p>
                            </div>
                          </div>
                          <a className="link primary-color-text body-medium-regular" href="#">
                            Mehr erfahren
                          </a>
                        </div>

                        <div className="d-grid gap-4 mx-auto w-75 my-5 px-0 px-sm-5">
                          <button className="btn btn-secondary">Alle akzeptieren</button>
                          <button className="btn btn-outline-secondary">Auswahl bestätigen</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* cokkie popup */}
                {/* <div
                  className="modal fade show d-block share-modal cokkie-modal"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-offWhite-color border-0 p-4">
                      <div className="modal-body mt-0 p-0">
                        <h5 className="primary-color-text mt-3">Cookie-Einwilligung und Datenverarbeitung</h5>
                        <p className="mt-4 body-medium-regular primary-color-text cokkie-cnt">
                        Wir verwenden verschiedene Cookies und ähnliche Technologien, um dir das bestmögliche Online-Erlebnis zu bieten und Inhalte auf deine Interessen anzupassen. Mittels der eingesetzten Technologien können Informationen auf deinem Endgerät gespeichert, angereichert und gelesen werden.<br/><br/>

Mit einem Klick auf „Alle akzeptieren“ stimmst du dem Zugriff auf dein Endgerät zu sowie der Verarbeitung deiner Daten, der Webseiten- sowie partner- und geräteübergreifenden Erstellung und Verarbeitung von individuellen Nutzungsprofilen sowie der Weitergabe deiner Daten an Drittanbieter, die zum Teil deine Daten in Ländern außerhalb der europäischen Union verarbeiten (DSGVO Art. 49). Die Telekom kann unter Umständen nicht in allen Fällen sicherstellen, dass das europäische Datenschutzniveau eingehalten wird.
                        </p>

                        <div className="d-grid gap-4 mx-auto w-75 my-5 px-0 px-sm-5 mx-0">
                          <button className="btn btn-secondary">Alle akzeptieren</button>
                          <button className="btn btn-outline-secondary">Einstellungen ändern</button>
                          <button className="btn btn-secondary">Nur erforderliche</button>
                          <p className="body-medium-bold primary-color-text text-center">Datenschutzhinweis <br/>Impressum</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* Alert Messeage  */}
      <section className="container mb-2">
        <div className="row">
          <div className="col-12">
            <h6>COMPONENTS</h6>
            <h3 className="primary-color-text">Alerts</h3>
            <h5 className="primary-color-text mt-4">Success Alert</h5>
            <div
              className="alert alert-success share-alert-success bg-mango-color border-0 text-md-center body-medium-regular primary-color-text"
              role="alert"
            >
              <img className="me-1" src={appImages.InfoIcon} alt="info-icon" /> Deine E-Mail-Adresse
              wurde erfolgreich verifiziert.
            </div>
            <h5 className="primary-color-text mt-4">Error Alert</h5>
            <div
              className="alert alert-error share-alert-error bg-error-color border-0 text-md-center body-medium-regular text-white"
              role="alert"
            >
              <img className="me-1" src={appImages.InfoIcon} alt="info-icon" /> Deine E-Mail-Adresse
              wurde erfolgreich verifiziert.
            </div>
            <h5 className="primary-color-text mt-4">Info Alert</h5>
            <div
              className="alert alert-info share-alert-info bg-disabled-color border-0 text-md-center body-medium-regular primary-color-text"
              role="alert"
            >
              <img className="me-1" src={appImages.InfoIcon} alt="info-icon" /> Deine E-Mail-Adresse
              wurde erfolgreich verifiziert.
            </div>
          </div>
        </div>
      </section>
      {/* Accordion */}
      <section className="container mb-2">
        <div className="row">
          <div className="col-12 mt-5">
            <h6>COMPONENTS</h6>
            <h3 className="primary-color-text">Accordion</h3>
            <div className="accordion accordion-flush mt-5" id="accordionFlushExample">
              <div className="accordion-item border-primary">
                <div id="flush-headingOne">
                  <button
                    className="d-flex justify-content-between accordion-button collapsed body-large-bold shadow-none bg-transparent primary-color-text px-0 py-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Welches Projekt wird mit meinem Tarif unterstützt?
                    <i className="fa fa-angle-down purple-light-color-text" aria-hidden="true"></i>
                    <i className="fa fa-angle-up purple-light-color-text" aria-hidden="true"></i>
                  </button>
                </div>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body body-large-regular primary-color-text px-0 pt-0">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                    tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                    vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                    no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
                    amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                    et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
                    sanctus est Lorem ipsum dolor sit amet.
                  </div>
                </div>
              </div>
              <div className="accordion-item border-primary">
                <div id="flush-headingTwo">
                  <button
                    className="d-flex justify-content-between accordion-button collapsed body-large-bold shadow-none bg-transparent primary-color-text px-0 py-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Was hat congstar damit zu tun?
                    <i className="fa fa-angle-down purple-light-color-text" aria-hidden="true"></i>
                    <i className="fa fa-angle-up purple-light-color-text" aria-hidden="true"></i>
                  </button>
                </div>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body body-large-regular primary-color-text px-0 pt-0">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                    tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                    vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                    no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
                    amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                    et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
                    sanctus est Lorem ipsum dolor sit amet.
                  </div>
                </div>
              </div>
              <div className="accordion-item border-primary">
                <div id="flush-headingThree">
                  <button
                    className="d-flex justify-content-between accordion-button collapsed body-large-bold shadow-none bg-transparent primary-color-text px-0 py-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Wie hilft share?
                    <i className="fa fa-angle-down purple-light-color-text" aria-hidden="true"></i>
                    <i className="fa fa-angle-up purple-light-color-text" aria-hidden="true"></i>
                  </button>
                </div>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body body-large-regular primary-color-text px-0 pt-0">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                    tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                    vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                    no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
                    amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                    et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
                    sanctus est Lorem ipsum dolor sit amet.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Card */}
      <section className="container mb-2">
        <div className="row">
          <div className="col-12 mt-5">
            <h6>COMPONENTS</h6>
            <h3 className="primary-color-text">Card</h3>

            <div className="bg-offWhite-color p-4">
              <div className="card col-4 border-0 box-radius">
                <div className="card-body py-3">
                  <h5 className="card-title grey-color-text heading-small">Kontakt</h5>
                  <p className="card-text body-medium-regular grey-color-text w-75">
                    Du erreichst unseren Service von Montag bis Samstag zwischen 8 und 20 Uhr:
                  </p>
                  <span className="label-small pink-color-text">0221 – 79 700 440</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container mb-2">
        <div className="bg-offWhite-color">
          <br />
          <h3 className="primary-color-text">Grids</h3>
          <br />
          <h5>Desktop</h5>
          <br />
          <div className="row bg-primary-color">
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">1</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">2</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">3</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">4</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">5</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">6</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">7</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">8</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">9</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">10</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">11</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">12</div>
            </div>
          </div>
          <br />
          <h5>Tablet</h5>
          <br />
          <div className="row bg-primary-color">
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">1</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">2</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">3</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">4</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">5</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">6</div>
            </div>
          </div>
          <br />
          <h5>Mobile</h5>
          <br />
          <div className="row bg-primary-color">
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">1</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">2</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">3</div>
            </div>
            <div className="col-1">
              <div className="bg-pink-color text-white text-center">4</div>
            </div>
          </div>
          <br />
        </div>
      </section>

      <Section
        bgColorCode={appSecBgColorTypes.WHITE}
        bottomElementType={appSecBottomElementTypes.TYPE_1}
      >
        <div className="container-fluid pb-0 pt-5">
          <div className="row py-5 pt-10 pb-0 flex-column-reverse flex-xl-row flex-md-row">
            <div className="col-xl-5 col-md-6 col-lg-5 col-12 me-5 z-1 text-xl-end me-md-0 ps-md-0 me-xl-5 ps-xl-3 ps-0">
              <img
                className="mx-100"
                src="https://spreadspace-cms-whitelabel.d.dom.de/api/media/286/display?ts=1695292814"
                alt="top banner"
                loading="lazy"
                style={{ height: 591, width: 572 }}
              />
            </div>
            <div className="col-xl-5 col-lg-6 col-md-6 col-12 ps-3 ps-md-3 ps-xl-5">
              <h1 className="primary-color-text my-5 mt-5">Teilen verdoppelt die Freude</h1>
              <div className="body-large-regular primary-color-text mb-5">
                <p>
                  Freund:innen zu <span className="body-large-bold">share mobile</span> einladen und
                  noch mehr Gutes tun:
                  <br />
                  Mit jeder erfolgreichen Einladung über die share mobile App erhalten Werber:in und
                  Geworbene:r mehr GB und jemand anders mehr Bildung.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

export default Test;
