import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { formValidation } from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { useMpsPayment } from '@dom-digital-online-media/dom-mps-sdk';
import { appAlert, appRoute, appTariffStatus, getTariffStatusKey } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
import { useCustomer } from '@context/MobileOne/Customer';
import { useOption, useSpeedOn, useTariff } from '../TariffOption';

export const DashBoardContext = createContext({});

export function DashBoardContextProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [tariffDetails, setTariffDetails] = useState({});
  const [tariffStatus, setTariffStatus] = useState(false); // False | ACTIVE - Display Nothing | PAUSED | Change In progress
  const [dataUsage, setDataUsage] = useState({});
  const [smsUsage, setSmsUsage] = useState({});
  const [voiceUsage, setVoiceUsage] = useState({});
  const [activeOptions, setActiveOptions] = useState([]);
  const [requestedOptions, setResponseData] = useState([]);
  const [isBasicTariff, setIsBasicTariff] = useState(false);
  const [formValue, setFormValue] = useState({
    isRechargeOptions: false,
    creditOption: '',
    sofortOption: '',
    isSofortSelected: false,
    cashcode: '',
    isCashcodeEntered: false,
    isPaymentInitiated: false
  });
  const [history, setHistory] = useState([]);
  // Context
  const {
    isLoading: isCustomerLoading,
    customerData,
    customerUsage,
    customerBalance
  } = useCustomer();
  const { isLoading: isTariffLoading, activeTariff } = useTariff();
  const { isLoading: isOptionLoading, bookableOptions, bookedOptions } = useOption();
  const { isLoading: isSpeedOnLoading, passOffers } = useSpeedOn();
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { onVoucherTDM, onVoucherHistory } = useMobileOne();
  const { onPaymentResgisterResult } = useMpsPayment();
  // Validations
  const validationSchema = Yup.object().shape({
    creditOption: formValidation({ required: 'please_select' })
  });
  const validationSchemaSofort = Yup.object().shape({
    sofortOption: formValidation({ required: 'please_select' }),
    isSofortSelected: formValidation({ required: 'please_select' })
  });
  const validationSchemaCashcode = Yup.object().shape({
    cashcode: formValidation({
      required: 'please_enter',
      minLength: 16,
      minLengthError: 'min_13_digit',
      maxLength: 19,
      maxLengthError: 'max_16_digit'
    }),
    isCashcodeEntered: formValidation({ required: 'please_select' })
  });
  // Functions
  const onSubmit = async (values) => {
    setFormValue(values);
    if (values.sofortOption || values.cashcode) {
      if (values.cashcode) {
        try {
          const { status } = await onVoucherTDM(values.cashcode);
          if (status === 200) {
            showAlert({ type: appAlert.SUCCESS, message: t('sh_db_charge_balance_success_msg') });
          }
          setFormValue({
            creditOption: '',
            sofortOption: '',
            cashcode: '',
            isSofortSelected: false,
            isCashcodeEntered: false
          });
          navigate(appRoute.DASHBOARD);
        } catch (error) {
          showAlert({
            type: appAlert.ERROR,
            message: `Error in request. ${error.error[0].messageBody}`
          });
        }
      } else if (values.sofortOption) {
        showAlert({ type: appAlert.SUCCESS, message: t('sh_db_charge_balance_success_msg') });
        setFormValue({
          creditOption: '',
          sofortOption: '',
          cashcode: '',
          isSofortSelected: false,
          isCashcodeEntered: false
        });
        navigate(appRoute.DASHBOARD);
      }
    }
  };
  const getVoucherHistory = async () => {
    try {
      const { status, data } = await onVoucherHistory();
      if (status === 200) {
        console.log(data);
        setHistory([
          {
            amount: 10,
            date: '01.Nov.2022'
          },
          {
            amount: 15,
            date: '22.Okt.2022'
          }
        ]);
      }
    } catch (error) {
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
    }
  };
  // Hooks
  // Loading Hook
  useEffect(() => {
    setIsLoading(isCustomerLoading || isTariffLoading || isOptionLoading || isSpeedOnLoading);

    // Clean up
    return () => {
      setIsLoading(false);
    };
  }, [isCustomerLoading, isTariffLoading, isOptionLoading, isSpeedOnLoading]);

  // Set tariff details & status for dashboard
  useEffect(() => {
    // Find active tariff to view it on dashboard
    const findTariff = activeTariff.find(({ status }) => status.id === appTariffStatus.ACTIVE);
    if (findTariff) {
      const {
        tariff = {},
        tariff: { id: tariffId = false },
        status: { id: statusId = false }
      } = findTariff;
      if (tariff && tariffId && statusId) {
        // Find & Set Status Id
        const statusKey = getTariffStatusKey(statusId);
        setTariffStatus(statusKey);
        // Set Tariff for details
        setTariffDetails(tariff);
      }
    }
    // Set other tariffs PAUSED | IN_PROGRESS etc
    if (!findTariff) {
      const searchOtherStatusTariff = activeTariff.find(({ status }) => status.id > -1);
      if (searchOtherStatusTariff) {
        const {
          tariff = {},
          tariff: { id: tariffId = false },
          status: { id: statusId = false }
        } = searchOtherStatusTariff;
        if (tariff && tariffId && statusId) {
          // Find & Set Status Id
          const statusKey = getTariffStatusKey(statusId);
          setTariffStatus(statusKey);
          // Set Tariff for details
          setTariffDetails(tariff);
        }
      }
    }

    // Clean up
    return () => {
      setTariffStatus(false);
      setTariffDetails({});
    };
  }, [activeTariff]);

  // Set customer usage details
  useEffect(() => {
    /* eslint-disable */
    if (customerUsage && customerUsage?.counters) {
      const { DATA, SMS, VOICE, isMultiData } = customerUsage?.counters;
      if (isMultiData) {
        alert('Multi Data is detected');
      }
      if (DATA) {
        DATA.remainingValue = Math.round(DATA.remainingValue / 1024);
        DATA.initialValue = Math.round(DATA.initialValue / 1024);
        setDataUsage(DATA);
      }
      if (SMS) setSmsUsage(SMS);
      if (VOICE) setVoiceUsage(VOICE);
    }

    // Clean up
    return () => {
      setDataUsage({});
      setSmsUsage({});
      setVoiceUsage({});
    };
  }, [customerUsage]);

  useEffect(() => {
    const { msisdn = false, ...restCustomerData } = customerData;
    if (restCustomerData && msisdn && activeTariff) {
      const findTariff = activeTariff.find(({ status }) => status.id);
      if (findTariff) {
        const {
          tariff: { price, duration }
        } = findTariff;
        if (price === 0 && duration === 0) {
          setIsBasicTariff(true);
        } else {
          setIsBasicTariff(false);
        }
      }
    }
  }, [customerData, activeTariff]);

  useEffect(() => {
    let rechargeOptions = JSON.parse(localStorage.getItem('recharge-options'));
    if (rechargeOptions) {
      onPaymentResgisterResult({
        checkoutSessionId: rechargeOptions.checkoutSessionId
      }).then((res) => {
        const { data } = res;
        delete rechargeOptions.checkoutSessionId;
        rechargeOptions.payment = data;
        setFormValue(rechargeOptions);
        localStorage.removeItem('recharge-options');
      });
    }
  }, []);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      tariffDetails,
      setTariffDetails,
      tariffStatus,
      setTariffStatus,
      formValue,
      setFormValue,
      history,
      customerBalance,

      isBasicTariff,

      dataUsage,
      setDataUsage,
      voiceUsage,
      setVoiceUsage,
      smsUsage,
      setSmsUsage,
      activeOptions,
      setActiveOptions,
      bookableOptions,
      bookedOptions,
      passOffers,
      validationSchema,
      validationSchemaSofort,
      validationSchemaCashcode,
      onSubmit,
      getVoucherHistory
    }),
    [
      // States
      isLoading,
      setIsLoading,
      tariffDetails,
      setTariffDetails,
      tariffStatus,
      setTariffStatus,
      formValue,
      setFormValue,
      customerData,
      customerBalance,
      history,

      isBasicTariff,

      dataUsage,
      setDataUsage,
      voiceUsage,
      setVoiceUsage,
      smsUsage,
      setSmsUsage,
      requestedOptions,
      setResponseData,
      activeOptions,
      setActiveOptions,
      bookableOptions,
      bookedOptions,
      passOffers,
      validationSchema,
      validationSchemaSofort,
      validationSchemaCashcode,
      onSubmit,
      getVoucherHistory
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <DashBoardContext.Provider value={contextPayload}>{children}</DashBoardContext.Provider>;
}
DashBoardContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useDashBoard = () => useContext(DashBoardContext);

export default DashBoardContext;
