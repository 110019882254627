import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useEmailVerification } from '@context/MobileOne/Activation/EmailVerification';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button, Text } from '@core/Inputs';
import { useActivation } from '@context/MobileOne';
import { appActivationFlowType, appButtonType } from '@utils/globalConstant';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';
import { useAlert } from '@context/Utils';

import './style.scss';

export function EmailVerification() {
  const [screenSize, setScreenSize] = useState(
    window && window.innerWidth ? window.innerWidth : 1080
  );
  const { validationSchemaCode, validateCode, verifyEmail, isLoading, verifyCode, formValue } =
    useEmailVerification();

  const { activationInfo, prevStep, activationType } = useActivation();
  const { t } = useStaticContent();
  const { alert } = useAlert();

  useEffect(() => {
    if (window.innerWidth) {
      setScreenSize(window.innerWidth);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.EMAIL_CONFIRMATION_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.EMAIL_CONFIRMATION_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
      if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.EMAIL_CONFIRMATION_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.EMAIL_CONFIRMATION_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  return (
    <div className="container">
      <div className="row position-relative">
        {/* <div className="col-12 orange-gray-bubble"></div> */}
        <Formik
          initialValues={{ ...formValue }}
          validationSchema={validationSchemaCode}
          validate={validateCode}
          onSubmit={(values) => {
            if (window && window.utag) {
              activationType === appActivationFlowType.NEW_ONLINE
                ? window.utag.link(
                    UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.EMAIL_VERIFICATION.REQUEST_CODE_ON_CLICK
                  )
                : window.utag.link(
                    UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.EMAIL_VERIFICATION.REQUEST_CODE_ON_CLICK
                  );
            }
            verifyCode(values);
          }}
        >
          {({ values: { code }, errors, touched, handleBlur, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="col-md-3 pb-5 mt-5 mb-2">
                <div className="back-btn">
                  <a
                    className="text-white body-medium-bold text-decoration-none link-light"
                    onClick={() => {
                      if (window && window.utag) {
                        activationType === appActivationFlowType.NEW_ONLINE
                          ? window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.EMAIL_VERIFICATION.BACK_ON_CLICK
                            )
                          : window.utag.link(
                              UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.EMAIL_VERIFICATION.BACK_ON_CLICK
                            );
                      }
                      prevStep();
                    }}
                  >
                    <i className="fa fa-arrow-left me-3"></i>
                    {t('sh_back_button')}
                  </a>
                </div>
              </div>

              <div className="mt-sm-5 mb-5 mb-sm-0">
                <div className="col-12 col-md-9 offset-md-1 col-lg-5 offset-lg-1">
                  <h6
                    className={`text-uppercase text-white mb-4${
                      alert.length > 0 ? ' mt-5 pt-4' : ''
                    }`}
                  >
                    {t(
                      activationType === appActivationFlowType.SIM_ACTIVATION
                        ? 'sh_apoc_step5/9'
                        : 'sh_apoc_email_step4'
                    )}
                  </h6>
                  <h1 className="pink-color-text mt-2 mt-sm-2">
                    {t(screenSize > 380 ? 'sh_apoc_code_h1' : 'sh_apoc_code_h2')}
                  </h1>
                  <p className="text-white body-large-regular mt-4 mt-sm-5">
                    {t('sh_apoc_email_verification_text')}
                  </p>

                  <div className="row">
                    <div className="col-md-9">
                      <Text
                        type="text"
                        id="code"
                        name="code"
                        maxLength="4"
                        onChange={handleChange}
                        value={code}
                        placeholder={t('sh_apoc_email_code-input')}
                        isInvalid={!!touched.code && !!errors.code}
                        onBlur={handleBlur}
                        error={touched.code && errors.code ? errors.code : null}
                      />
                    </div>
                  </div>
                  <span className="body-medium-bold text-white mt-3">
                    {t('sh_apoc_email_code-bold')}{' '}
                  </span>
                  <span className="body-medium-regular font-16 text-white mt-3">
                    {t('sh_apoc_email_code-norm')}
                  </span>
                  <div className="row mt-3">
                    <div className="col-md-9">
                      <Button
                        buttonType={appButtonType.PRIMARY}
                        className="w-100 bg-offWhite-color"
                        type="submit"
                        isLoading={isLoading}
                      >
                        {t('sh_apoc_email_verification_button')}
                      </Button>
                    </div>
                  </div>
                  <p className="offWhite-color-text body-medium-regular font-16 mt-5">
                    {t('sh_apoc_email_text3')}
                  </p>
                  <a
                    className="text-white body-medium-regular font-16 p-0 mb-5 mb-sm-0 link-light shadow-none"
                    variant="link"
                    onClick={() => {
                      if (window && window.utag) {
                        activationType === appActivationFlowType.NEW_ONLINE
                          ? window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.EMAIL_VERIFICATION
                                .RESEND_CODE_ON_CLICK
                            )
                          : window.utag.link(
                              UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.EMAIL_VERIFICATION
                                .RESEND_CODE_ON_CLICK
                            );
                      }
                      verifyEmail({ email: activationInfo.email, resendEmail: true });
                    }}
                  >
                    {t('sh_apoc_email_send-code-again')}
                  </a>

                  {/* <Button
                    buttonType={appButtonType.LINK}
                    className="body-medium-regular text-white p-0 mb-5 mb-sm-0 link-light shadow-none"
                    type="button"
                    onClick={() => verifyCode(formValue)}>
                    {t('sh_apoc_email_send-code-again')}
                  </Button> */}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default EmailVerification;
