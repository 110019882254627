/* eslint-disable */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { useCustomer } from '@context/MobileOne/Customer';
import { StatusCodes } from '@dom-digital-online-media/dom-app-config-sdk';

import { useNavigate } from 'react-router-dom';
import { useAlert } from '@context/Utils';
import { appAlert, appRoute } from '@utils/globalConstant';
// import { useAppConfig } from '@dom-digital-online-media/dom-app-config-sdk';

export const SpeedOnContext = createContext({});

export function SpeedOnContextProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [passOffers, setPassOffers] = useState([]);
  // Context
  const { onPassoffer, onBookOption } = useMobileOne();
  const { customerData, getCustomerData } = useCustomer();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  // const { env } = useAppConfig();

  // Validations

  // Functions
  const onLoad = async () => {
    /* try {
      setIsLoading(true);
      // if (TDM_2) {
      const { data, success, status } = await onPassoffer();
      if (status === StatusCodes.OK || success) {
        setPassOffers(data);
      }
      // }
      setIsLoading(false);
      return null;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return error;
    } */
  };

  // Book speedOn if possible
  const bookSpeedOn = async (speedonId) => {
    try {
      setIsLoading(true);
      const { data, status, success } = await onBookOption(speedonId);
      console.log({ data, success, status });

      await getCustomerData();
      if (success) {
        showAlert({
          type: appAlert.SUCCESS,
          message: 'Successfully updated tariff'
        });
      } else {
        showAlert({
          type: appAlert.ERROR,
          message: 'Error in processing, try again after sometime.'
        });
      }
      navigate(appRoute.TARIFF_OPTION);
      setIsLoading(false);

      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return error;
    }
  };

  // Hooks
  useEffect(() => {
    const { msisdn, ...restCustomerData } = customerData;
    if (restCustomerData && msisdn) {
      onLoad();
    }
  }, [customerData]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      passOffers,

      bookSpeedOn
    }),
    [
      // States
      isLoading,
      setIsLoading,
      passOffers,

      bookSpeedOn
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <SpeedOnContext.Provider value={contextPayload}>{children}</SpeedOnContext.Provider>;
}
SpeedOnContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useSpeedOn = () => useContext(SpeedOnContext);

export default SpeedOnContext;
