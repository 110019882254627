import React from 'react';
import { Formik } from 'formik';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Modal } from '@core/Utils';
import { Button, Text } from '@core/Inputs';
import { appActivationFlowType, appButtonType } from '@utils/globalConstant';
import { useActivation } from '@context/MobileOne';
import { UTAG_LINK, utag } from '@utils/utag';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';

export function MGMCodeModal({
  setModal,
  handleChange: formikHandleChange,
  handleBlur: formikHandleBlur,
  codeForValidation: formikCodeForValidation,
  touched: formikTouched,
  errors: formikErrors,
  setErrors,
  setTouched: formikSetTouched,
  applyMGMCode,
  isLoading
}) {
  // Context
  const { t } = useStaticContent();
  const { activationType } = useActivation();
  const { validationSchemaMgmCode } = useActivationTariff();

  // State
  // const [isLoading, setIsLoading] = useState(false);
  const onSubmit = ({ codeForValidation }) => {
    if (window && window.utag) {
      activationType === appActivationFlowType.NEW_ONLINE
        ? window.utag.link(UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.REDEEM_CODE_ON_CLICK)
        : window.utag.link(UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.REDEEM_CODE_ON_CLICK);
    }
    formikSetTouched({ codeForValidation: true });
    applyMGMCode({ codeForValidation, setErrors, setModal });
  };

  return (
    <Modal
      showCloseButton
      onCloseClick={() => {
        if (window && window.utag) {
          window.utag.link(UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.MGM_CODE.CLOSE_ON_CLICK);
        }
        setModal('');
      }}
      header={t('sh_apoc_voucher_h1')}
      description={t('sh_apoc_voucher_text1')}
    >
      <Formik
        initialValues={{
          codeForValidation: formikCodeForValidation
        }}
        validationSchema={validationSchemaMgmCode}
        onSubmit={onSubmit}
      >
        {({
          values: { codeForValidation },
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <>
            <div className="row">
              <div className="col-10">
                <Text
                  type="text"
                  id="codeForValidation"
                  name="codeForValidation"
                  onChange={(e) => {
                    handleChange(e);
                    formikHandleChange(e);
                  }}
                  value={codeForValidation}
                  placeholder={t('sh_apoc_voucher_input')}
                  isInvalid={
                    (!!touched.codeForValidation || !!formikTouched.codeForValidation) &&
                    (!!errors.codeForValidation || !!formikErrors.codeForValidation)
                  }
                  onBlur={(e) => {
                    handleBlur(e);
                    formikHandleBlur(e);
                  }}
                  error={
                    formikErrors.codeForValidation
                      ? formikErrors.codeForValidation
                      : touched.codeForValidation && errors.codeForValidation
                      ? errors.codeForValidation
                      : null
                  }
                />
                <Button
                  className="w-100"
                  buttonType={appButtonType.SECONDARY}
                  isLoading={isLoading}
                  onClick={handleSubmit}
                >
                  {t('sh_apoc_voucher_redeem_button')}
                </Button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default MGMCodeModal;
