// import { useDashBoard } from "@context/MobileOne/DashBoard";
import { useCustomer } from '@context/MobileOne/Customer';
import { Button, Link } from '@core/Inputs';
import { Card, CardBody, CardFooter, CardHeader } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appRoute } from '@utils/globalConstant';

export function CustomerBalance() {
  const { t } = useStaticContent();
  const { customerBalance } = useCustomer();
  return (
    <Card sx={{ width: '25rem' }}>
      <CardHeader>
        <div className="d-flex align-items-center justify-content-between">
          <h3>{t('sh_db_credit_h3')}</h3>
          <Link to={appRoute.CREDIT_DETAILS}>{t('sh_db_details')}</Link>
        </div>
      </CardHeader>
      <CardBody>
        <div className="d-flex align-items-center justify-content-between">
          <h3> {customerBalance.totalBalance} &euro;</h3>
          <Button variant="primary">{t('sh_db_credit_button')}</Button>
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex align-items-baseline justify-content-between">
          <p> {t('sh_db_credit_text1')} </p>
          <Button variant="link">{t('sh_db_credit_text2')}</Button>
        </div>
      </CardFooter>
    </Card>
  );
}

export default CustomerBalance;
