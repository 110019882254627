import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useTariff } from '@context/MobileOne/TariffOption/Tariff';
import { Col, Container, Row } from '@core/Layout';
import { Link } from '@core/Inputs';
import { Accordion } from '@core/Layout/Accordion';
import { appRoute } from '@utils/globalConstant';
import { ThirdPartyLock } from '@page/TariffOption/Tariff/SimCardESim/ThirdPartyLock';
import { ESimApple } from '@page/TariffOption/Tariff/SimCardESim/ESimApple';
import { ESimAndroid } from '@page/TariffOption/Tariff/SimCardESim/ESimAndroid';
import { SimCardExchange } from '@page/TariffOption/Tariff/SimCardESim/SimCardExchange';
import { SimCardLock } from '@page/TariffOption/Tariff/SimCardESim/SimCardLock';

export function SimCardESim() {
  // States
  // context
  const { t } = useStaticContent();
  const { getSimData, simData } = useTariff();

  // Constants declared after StaticContent 't' function. Used for Accordian (eSIM)
  const accordianESimItems = [
    { title: t('sh_tab_two_sim_card_esim_dropdown1'), component: <ThirdPartyLock /> },
    { title: t('sh_tab_two_sim_card_esim_dropdown2'), component: <SimCardExchange /> },
    { title: t('sh_tab_two_sim_card_esim_dropdown3'), component: <ESimApple /> },
    { title: t('sh_tab_two_sim_card_esim_dropdown4'), component: <ESimAndroid /> },
    { title: t('sh_tab_two_sim_card_esim_dropdown5'), component: <SimCardLock /> }
  ];

  // Used Accordian for SIM
  const accordianSimItems = [
    { title: t('sh_tab_two_sim_card_sim_dropdown1'), component: <ThirdPartyLock /> },
    { title: t('sh_tab_two_sim_card_sim_dropdown2'), component: <SimCardExchange /> },
    { title: t('sh_tab_two_sim_card_sim_dropdown3'), component: <SimCardLock /> }
  ];

  // Functions

  // Hooks
  // Default Hook
  useEffect(() => {
    getSimData();
  }, []);

  return (
    <Container>
      <br />
      <Row>
        <Col xs={4} className="d-flex align-items-start flex-column">
          <Link to={appRoute.TARIFF_OPTION}>
            <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
          </Link>
        </Col>
        <Col xs={8} className="d-flex align-items-start flex-column">
          <center>
            {simData && simData.simType === 'eSIM' ? (
              <h3 dangerouslySetInnerHTML={{ __html: t('sh_tab_two_sim_card_esim_h1') }} />
            ) : (
              <h3 dangerouslySetInnerHTML={{ __html: t('sh_tab_two_sim_card_sim_h1') }} />
            )}
          </center>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12}>
          {simData && simData.simType === 'eSIM' ? (
            <Accordion items={accordianESimItems} />
          ) : (
            <Accordion items={accordianSimItems} />
          )}
        </Col>
      </Row>
      <br />
    </Container>
  );
}

export default SimCardESim;
