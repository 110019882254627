/* eslint-disable */
import React from 'react';
import { Col, Row } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import PropTypes from 'prop-types';
import { Button } from '@core/Inputs';
import { useCustomer } from '@context/MobileOne';

export function UserAccount() {
  const { t } = useStaticContent();
  const { personalData } = useCustomer();
  const birthDate = new Date(personalData.birthDate);
  const yyyy = birthDate.getFullYear();
  let mm = birthDate.getMonth() + 1; // Months start at 0!
  let dd = birthDate.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return (
    <Row>
      <Col xs={12}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>{t('sh_sm_switch_summary_subheadline3')}</h3>
        </div>
        <p>
          {personalData.firstName} {personalData.lastName}
        </p>
        <p>
          {personalData.address.street} {personalData.address.houseNumber}
        </p>
        <p>
          {personalData.address.zip} {personalData.address.city}
        </p>
        <p>
          <b>{t('sh_apoc_summary_personal_subheader1')} </b>
          {`${dd}-${mm}-${yyyy}`}
        </p>
        <p>
          <b>{t('sh_apoc_summary_personal_subheader2')} </b>
          {personalData.email.emailAddress}
        </p>
      </Col>
    </Row>
  );
}

UserAccount.propTypes = {
  personalData: PropTypes.object
};
UserAccount.defaultProps = {
  personalData: {}
};

export default UserAccount;
