/* eslint-disable */
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from '@core/Layout';
import { Button } from '@core/Inputs';
import { useCustomer, useTariff } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';

import { YourTariff } from './YourTariff';
import { UserAccount } from './UserAccount';
import { Terms } from './Terms';
import { appRoute } from '@utils/globalConstant';

export function TariffOverView() {
  // State

  // Context
  const { changeTariff, setChangeTariff, onSubmit } = useTariff();
  const { t, staticContentData } = useStaticContent();
  const { personalData } = useCustomer();
  const navigate = useNavigate();
  const { customerBalance, setFormValue, formValue } = useDashBoard();
  const recharge = () => {
    setFormValue({
      ...formValue,
      isRechargeOptions: true
    });
    navigate(appRoute.CREDIT_DETAILS);
  };

  useEffect(() => {
    if (staticContentData && changeTariff) {
      const defaultTariff = staticContentData.tariff_share.find((bt) => bt.id === changeTariff.id);
      setChangeTariff({ ...defaultTariff });
    }
  }, [staticContentData]);
  return (
    <Container>
      <Row>
        <Formik
          enableReinitialize
          initialValues={{
            codeForValidation: '',
            isPromoApplied: false,
            ...personalData.flags,
            ...changeTariff
          }}
          onSubmit={onSubmit}
        >
          {({
            values: { acceptedCreditCheck, termsAndConditions, codeForValidation },
            handleBlur,
            handleChange,
            errors,
            setErrors,
            touched,
            handleSubmit,
            setFieldValue,
            setTouched,
            setFieldTouched
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12}>
                  <h4>{t('sh_sm_switch_summary_headline1')}</h4>
                  <p dangerouslySetInnerHTML={{ __html: t('sh_sm_switch_summary_text1') }} />
                </Col>
              </Row>
              <br />
              <YourTariff
                codeForValidation={codeForValidation}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                setTouched={setTouched}
                setErrors={setErrors}
                setFieldTouched={setFieldTouched}
                handleBlur={handleBlur}
              />
              <br />
              <hr />
              {/* <SelectedPaymentMethod activationInfo={activationInfo} setCurrentStep={setCurrentStep} />
              <hr />
              <br /> */}
              <UserAccount />
              <br />
              <Terms
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
              />
              <br />
              {customerBalance.totalBalance - changeTariff.starterPackPrice < 0 && (
                <>
                  <p dangerouslySetInnerHTML={{ __html: t('sh_sm_switch_summary_top_up_info') }} />
                  <br />
                  <Button variant="primary" type="button" className="mb-2" onClick={recharge}>
                    {t('sh_sm_switch_summary_top_up_button')}
                  </Button>
                  <br />
                </>
              )}
              <Button
                variant="primary"
                type="submit"
                disabled={
                  !acceptedCreditCheck ||
                  !termsAndConditions ||
                  customerBalance.totalBalance - changeTariff.starterPackPrice < 0
                }
              >
                {t('sh_sm_switch_summary_order_button')}
              </Button>
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
}

export default TariffOverView;
