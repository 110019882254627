import { Link } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Card, CardBody, CardFooter, CardHeader } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';

export function ReferFriend() {
  const { t } = useStaticContent();
  return (
    <Card sx={{ width: '25rem' }}>
      <CardHeader>
        {' '}
        <h3>{t('sh_db_friends_h4')}</h3>{' '}
      </CardHeader>
      <CardBody>
        <p>{t('sh_db_friends_text')}</p>
      </CardBody>
      <CardFooter>
        <Link to={appRoute.REFER_FRIEND}>{t('sh_db_friends_button')}</Link>
      </CardFooter>
    </Card>
  );
}

export default ReferFriend;
