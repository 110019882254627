import React, { useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Col, Row } from '@core/Layout';
import { Button } from '@core/Inputs';
import { Modal } from 'react-bootstrap';

export function ThirdPartyLock() {
  // States
  const [modalPopup, setModalPopup] = useState(false);

  // context
  const { t } = useStaticContent();

  return (
    <>
      <Row>
        <Col xs={12}>
          <p>{t('sh_tab_two_sim_card_esim_dropdown1_text1')}</p>
          <Button
            variant="link"
            className="p-0 mb-3"
            onClick={() => {
              setModalPopup(true);
            }}
          >
            {t('sh_tab_two_sim_card_esim_dropdown1_link')}
          </Button>
          <h5>{t('sh_tab_two_sim_card_esim_subheadline1')}</h5>
          <p>{t('sh_tab_two_sim_card_esim_dropdown1_text2')}</p>
          <Button variant="dark">{t('sh_tab_two_sim_card_esim_button')}</Button>
        </Col>
      </Row>

      <Modal show={modalPopup} onHide={() => setModalPopup(false)} size="lg">
        <Modal.Header closeButton>
          <div>
            <p className="m-0">{t('sh_tab_two_overlay_subheadline')}</p>
            <Modal.Title>{t('sh_tab_two_overlay_subheadline')}</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <p>{t('sh_tab_two_overlay_text')}</p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ThirdPartyLock;
