import React, { useEffect } from 'react';
import { Col, Row } from '@core/Layout';
import { Table } from 'react-bootstrap';
import { useChargeHistory } from '@context/AlphaComm';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { Spinner } from '@core/Utils';
// import PropTypes from 'prop-types'

export function ChargeHistory() {
  // Context
  const { isUserLoggedIn } = useAuth();
  const { history, onLoad, isLoading } = useChargeHistory();

  // Hooks
  useEffect(() => {
    if (isUserLoggedIn) {
      onLoad();
    }
  }, []);

  return (
    <div className="py-3">
      <hr />
      <h2>Charge History</h2>
      <hr />
      <Row>
        <Col className="text-end">
          {isLoading && <Spinner />}
          {history.length === 0 && !!isLoading && <div key="charge-history">No Data Found</div>}
          {!isLoading && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {history.map((item, i) => (
                  <tr key={`charge-history-${item.date}`}>
                    <td>{i + 1}</td>
                    <td>{item.amount}</td>
                    <td>{item.date}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </div>
  );
}

ChargeHistory.propTypes = {};

export default ChargeHistory;
