import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useLayout } from '@context/Utils';
import { UTAG_VIEWS } from '@utils/utag';

export function IdentFailed() {
  // Context
  const { t } = useStaticContent();
  const { setLightHeader } = useLayout();

  // Hooks
  useEffect(() => {
    setLightHeader(true);

    return () => {
      setLightHeader(false);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      const tagInfo = UTAG_VIEWS.IDENT_FAILED_MOUNTING;
      window.utag.view(tagInfo);
    }
  }, [window.utag]);

  return (
    <section className="position-relative bg-primary-color purple-shade-2 py-5">
      <div className="container my-5 py-5">
        <div className="row position-relative py-0 py-lg-5 my-0 my-xl-3 my-xxl-5">
          <div className="col-12 col-xl-7 offset-xl-1 col-md-8 offset-md-1">
            <h1
              className="pink-color-text mt-2 mt-sm-5 mb-5"
              dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_failed_h1') }}
            ></h1>
            <div
              className="text-white body-large-regular mt-3 mt-sm-4 mb-5"
              dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_failed_text') }}
            ></div>
            <div
              className="text-white body-large-bold m-0"
              dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_failed_number') }}
            ></div>
            <div
              className="text-white body-large-regular"
              dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_failed_opening_time') }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default IdentFailed;
