import React, { useEffect } from 'react';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useNumberPorting } from '@context/MobileOne/Activation/NumberPorting';
import {
  appActivationFlowType,
  appActivationStep2Modals,
  appActivationStep2Screens
} from '@utils/globalConstant';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';
import { useActivation } from '@context/MobileOne';

export function NewNumberModal({ setModal }) {
  // Context
  const { t } = useStaticContent();
  const { newNumbers, onSubmit, formValue, setFormValue } = useNumberPorting();
  const { activationType, activationInfo, setNumberPortingScreen } = useActivation();

  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.NEW_NUMBER_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.NEW_NUMBER_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
      if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.NEW_NUMBER_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.NEW_NUMBER_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  return (
    <Modal
      showCloseButton
      onCloseClick={() => {
        setModal('');
        setFormValue({
          ...formValue,
          newNumber: '',
          isNewNumberSelected: 'false',
          isNewNumber: 'true'
        });
      }}
      header={t('sh_apoc_number_new-number_h3')}
    >
      <div className="mt-4">
        <p className="my-4 body-medium-regular primary-color-text">
          {newNumbers.length > 0
            ? t('sh_apoc_number_new-number_text2')
            : t('sh_ap_waitingtext_newnumber')}
        </p>
        <ul className="list-unstyled category-link mt-4">
          {newNumbers.map((num) => (
            <li
              className="my-4"
              key={`${num.msisdn.substring(0, 4)}-${num.msisdn.substring(4)}-phone`}
            >
              <a
                className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                onClick={() => {
                  if (window && window.utag) {
                    activationType === appActivationFlowType.newNumber
                      ? window.utag.link(
                          UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.NEW_NUMBER.SELECT_NEW_NUMBER_ON_CLICK
                        )
                      : window.utag.link(
                          UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.NEW_NUMBER.SELECT_NEW_NUMBER_ON_CLICK
                        );

                    activationType === appActivationFlowType.newNumber
                      ? window.utag.link(
                          UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.NEW_NUMBER.APPLY_ON_CLICK
                        )
                      : window.utag.link(
                          UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.NEW_NUMBER.APPLY_ON_CLICK
                        );
                  }

                  setFormValue({
                    ...formValue,
                    newNumber: num.msisdn,
                    isNewNumber: 'true'
                  });
                  if (activationType === appActivationFlowType.SIM_ACTIVATION) {
                    onSubmit({
                      ...formValue,
                      newNumber: num.msisdn,
                      isNewNumberSelected: true,
                      isNewNumber: 'true'
                    });
                  } else {
                    setNumberPortingScreen(appActivationStep2Screens.SIM_ESIM_SELECTION);
                  }
                  setModal(
                    activationType === appActivationFlowType.SIM_ACTIVATION ? '' : 'simInfo'
                  );
                }}
              >
                {`${num.msisdn.substring(0, 4)}-${num.msisdn.substring(4)}`}{' '}
                <i className="fa fa-arrow-right" />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
}

export default NewNumberModal;
