import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { appAlert, useAppConfig } from '@dom-digital-online-media/dom-app-config-sdk';
import { useAlert } from '@context/Utils';
import { useActivationTariff } from '../ActivationTariff';
import { generateUUID } from '@utils/scriptLoader';
import { appStorage, formatDate } from '@utils/globalConstant';
import { useConfig } from '@config/ContextManager';

export const SimActivationContext = createContext({});

const STATIC_ACTIVATION_VARIABLE = {
  SALUTATION: 3,
  IDENTITY_DOCUMENT: {
    nationality: 1,
    identityType: 11,
    legitimationMethod: 3,
    identityNumber: '123456EF',
    validUntil: '2035-01-01',
    placeOfIssue: 'Ratingen'
  }
};

export function SimActivationContextProvider({
  children,
  onFormUpdate,
  nextStep,
  setActivatedSimData,
  activationInfo
}) {
  // States
  const [isLoading, setIsLoading] = useState(false);

  // Context
  const { onActivationSim } = useMobileOne();
  const { showAlert } = useAlert();
  const { bookableTariff } = useActivationTariff();
  const { env } = useAppConfig();
  const {
    config: { storage }
  } = useConfig();

  // Functions
  const onLoad = async () => {};

  const onSubmit = async (values) => {
    const updatedIccid =
      values.iccid === 'undefined' || !values.iccid || values.iccid === undefined
        ? ''
        : `${values.iccid}`;
    const updatedPuk =
      values.puk === 'undefined' || !values.puk || values.puk === undefined ? '' : `${values.puk}`;
    setIsLoading(true);
    onFormUpdate(values);

    // const activationMsisdn = values.newNumber === "" || values.newNumber === "undefined" || !values.newNumber || values.newNumber === undefined ? values.currentNumber : values.newNumber;
    const reqPayload = {
      activationData: {
        iccid: updatedIccid,
        puk: updatedPuk,
        msisdn: values.newNumber || values.currentNumber,
        simType: values.eSim === 'true' ? 1 : 0
      },
      number: {
        charge: 0,
        goldenNumber: false,
        msisdn: values.newNumber || values.currentNumber,
        silverNumber: false
      },
      activationType: updatedIccid ? 0 : 1,
      chosenTariffId: values.activeTariff.id,
      cscPassword: values.cscPassword,
      hotlinePassword: values.hotlinePassword,
      legitimationMethod: 3,
      // identityDocument: STATIC_ACTIVATION_VARIABLE.IDENTITY_DOCUMENT,
      // identityDocument: {
      //   identityNumber: '1234567',
      //   identityType: 1,
      //   legitimationMethod: 1,
      //   nationality: 1,
      //   placeOfIssue: 'DOM',
      //   validUntil: '23-02-2038'
      // },
      invitationCode: values.codeForValidation || values?.activeTariff?.promoCode || '',
      personalData: {
        address: {
          additionalInfo: '',
          city: values.city,
          countryCode: values.countryCode,
          houseNumber: values.houseNumber,
          street: values.street,
          zip: values.zip
        },
        alternatePhoneNumber: {
          number: '',
          prefix: ''
        },
        birthDate: formatDate(values.birthDate),
        emailAddress: values.email,
        firstName: values.firstName,
        flags: {
          acceptedCreditCheck: values.acceptedCreditCheck,
          termsAndConditions: values.termsAndConditions,
          brandPartnerCustomMarketing: true,
          employee: true,
          marketingMultibrand: true,
          thirdParty: true
        },
        lastName: values.lastName,
        // salutation: 0,
        salutation: STATIC_ACTIVATION_VARIABLE.SALUTATION,
        title: 0
      },
      uuid: generateUUID()
    };
    if (values.currentNumber) {
      const storedNumbers = JSON.parse(await storage.getItem('postpaid-numbers')) || [];
      if (storedNumbers) {
        reqPayload.activationData.msisdn = storedNumbers[0].msisdn;
        reqPayload.number.msisdn = storedNumbers[0].msisdn;
      } else {
        const { status, data } = await onPostpaidNumber({ numberCount: 6 });
        if (status === 200) {
          reqPayload.activationData.msisdn = data[0].msisdn;
          reqPayload.number.msisdn = data[0].msisdn;
        }
      }

      reqPayload.activationMNPData = {
        currentProvider: Number(values.provider),
        mnp: true,
        oldNumber: {
          number: values.currentNumber.substring(4),
          prefix: values.currentNumber.substring(0, 4)
        },
        type: 1
      };
      reqPayload.personalData.alternatePhoneNumber = {
        number: values.currentNumber.substring(4),
        prefix: values.currentNumber.substring(0, 4)
      };
    } else {
      reqPayload.personalData.alternatePhoneNumber = {
        number: values.newNumber.substring(4),
        prefix: values.newNumber.substring(0, 4)
      };
    }
    if (values.paymentMethod === 'paypal') {
      reqPayload.paypalData = {
        ...values.payment.paymentMethodDetails,
        paymentHandle: values.payment.paymentHandle
      };
    } else if (values.paymentMethod === 'creditcard_braintree') {
      reqPayload.creditCardData = {
        ...values.payment.paymentMethodDetails,
        paymentHandle: values.payment.paymentHandle,
        pseudoCardNumber: values.payment.paymentMethodDetails.maskedCreditCardNumber,
        referenceNumber: values.payment.paymentTransactionId
      };
    }
    if (updatedIccid === '') {
      delete reqPayload.activationData.iccid;
      delete reqPayload.activationData.puk;
    }
    // Affiliate
    let isAffiliate = window.affiliatedata; // localStorage.getItem(appStorage.AFFILIATE_DATA);
    if (isAffiliate && reqPayload.activationType === 1) {
      if (`${isAffiliate}`.length > env.REACT_APP_PROVISION_INFO_MAX_LENGTH) {
        isAffiliate = isAffiliate.substring(0, env.REACT_APP_PROVISION_INFO_MAX_LENGTH);
      }
      reqPayload.provisionInfo = `?${isAffiliate}`;
      // localStorage.removeItem(appStorage.AFFILIATE_DATA);
    } else {
      reqPayload.provisionInfo = '';
    }
    onActivationSim(reqPayload)
      .then(({ success, data }) => {
        // console.log('onActivationSim', success);
        if (data && data.signupId) {
          setActivatedSimData(data);
          nextStep();
        }
        localStorage.clear();
      })
      .catch((error) => {
        // console.log('onActivationSim', error);
        showAlert({
          type: appAlert.ERROR,
          message: `Error in request. ${error.error[0].messageBody}`
        });
      })
      .finally(() => setIsLoading(false));
    // nextStep();
  };

  // Hooks
  // Default Hook // No use
  useEffect(() => {}, []);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      // Functions
      onLoad,
      onSubmit
    }),
    [
      // States

      isLoading,
      setIsLoading,

      // Functions
      onLoad,
      onSubmit
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <SimActivationContext.Provider value={contextPayload}>{children}</SimActivationContext.Provider>
  );
}
SimActivationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onFormUpdate: PropTypes.any,
  nextStep: PropTypes.any
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useSimActivation = () => useContext(SimActivationContext);

export default SimActivationContext;
