import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { useCustomer } from '@context/MobileOne/Customer';
import { useAlert } from '@context/Utils';
import { appAlert, appRoute } from '@utils/globalConstant';

export const OptionContext = createContext({});

export function OptionContextProvider({ children }) {
  // Context
  const { showAlert } = useAlert();
  const { customerData, getCustomerData } = useCustomer();
  const { onAllOptions, onBookableOptions, onBookedOptions, onBookOption } = useMobileOne();
  const navigate = useNavigate();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [bookableOptions, setBookableOptions] = useState([]);
  const [bookedOptions, setBookedOptions] = useState([]);

  // Validations
  const termsValidations = Yup.object().shape({
    terms: Yup.boolean().isTrue('please_select_terms_and_conditions')
  });

  // Functions
  // Get all options and storing it in state
  const getAllOptions = async () => {
    try {
      const { data } = await onAllOptions();
      if (data) setAllOptions(data);
      return data;
    } catch (error) {
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      setIsLoading(false);
      return error;
    }
  };

  // Get bookable options and storing it in state
  const getBookableOptions = async () => {
    try {
      const { data } = await onBookableOptions();
      if (data) setBookableOptions(data);
      return data;
    } catch (error) {
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      setIsLoading(false);
      return error;
    }
  };

  // Get booked options and storing it in state
  const getBookedOptions = async () => {
    try {
      const { data } = await onBookedOptions();
      if (data) setBookedOptions(data);
      return data;
    } catch (error) {
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      setIsLoading(false);
      return error;
    }
  };

  // Function loads after we get customer data, and returns all options, bookable and booked options
  const afterLoad = async () => {
    try {
      setIsLoading(true);
      await getAllOptions();
      await getBookableOptions();
      await getBookedOptions();
      setIsLoading(false);
      return null;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return error;
    }
  };

  // Book an option if possible
  const bookAnOption = async (optionId) => {
    try {
      setIsLoading(true);
      const { data, status, success } = await onBookOption(optionId);
      console.log({ data, success, status });

      if (success) {
        showAlert({
          type: appAlert.SUCCESS,
          message: 'Successfully updated tariff'
        });
      } else {
        showAlert({
          type: appAlert.ERROR,
          message: 'Error in processing, try again after sometime.'
        });
      }
      await getCustomerData();
      navigate(appRoute.TARIFF_OPTION);
      setIsLoading(false);

      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return error;
    }
  };

  // Hooks
  // to check the all options, bookable and booked options
  useEffect(() => {
    const { msisdn, ...restCustomerData } = customerData;
    if (restCustomerData && msisdn) {
      afterLoad();
    }
  }, [customerData]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      allOptions,
      bookableOptions,
      bookedOptions,

      bookAnOption,
      termsValidations
    }),
    [
      // States
      isLoading,
      setIsLoading,
      allOptions,
      bookableOptions,
      bookedOptions,

      bookAnOption,
      termsValidations
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <OptionContext.Provider value={contextPayload}>{children}</OptionContext.Provider>;
}
OptionContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useOption = () => useContext(OptionContext);

export default OptionContext;
