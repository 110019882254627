import React from 'react';
import { Col, Container, Row } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appRoute } from '@utils/globalConstant';
import { Button } from '@core/Inputs';
import { useNavigate } from 'react-router-dom';

export function HelpAndServices() {
  // Contexts
  const { t } = useStaticContent();
  const navigate = useNavigate();

  return (
    <Container>
      <Row className="my-2">
        <Col md={6} xs={12} className="my-2">
          <h1>{t('sh_tab_5_help_service_h1')}</h1>
        </Col>
        <Col xs={12} className="my-2">
          <h3 className="my-2">{t('sh_tab_5_help_service_subheader')}</h3>
          <div className="my-4">{t('sh_tab_5_help_service_subheader_text')}</div>
          <Button className="btn-dark" onClick={() => navigate(appRoute.FAQ_LIST)}>
            {t('sh_tab_5_help_service_faq_btn')}
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        <Col xs={12} className="my-2">
          <h3 className="my-2">{t('sh_tab_5_help_service_subheader2')}</h3>
          <div className="my-4">{t('sh_tab_5_help_service_subheader_text2')}</div>
          <div className="my-4">{t('sh_tab_two_sim_card_esim_dropdown1_text2')}</div>
          <Button className="btn-dark" onClick={() => navigate(appRoute.FAQ_LIST)}>
            {t('sh_tab_two_sim_card_esim_button')}
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        <Col xs={12} className="my-2">
          <div className="my-4">{t('sh_tab_5_help_service_text3')}</div>
          <Button className="btn-dark" onClick={() => navigate(appRoute.FAQ_LIST)}>
            {t('sh_tab_5_help_service_mail_btn')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default HelpAndServices;
