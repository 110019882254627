import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
// import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';

import { useConfig } from '@config/ContextManager';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { useActivation } from '@context/MobileOne';
import { useAlert, useLayout } from '@context/Utils';
import { appActivationFlowType, appImages, appRoute, appStorage } from '@utils/globalConstant';
import { Link } from '@core/Inputs/Link';

import { UTAG_LINK } from '@utils/utag';
import './index.scss';
import { usePayment } from '@context/MobileOne/Activation/Payments';

export function Header() {
  // Contexts
  const { t } = useStaticContent();
  const { showAlert } = useAlert();
  // const { isUserLoggedIn, setUserLogin, onLogout } = useAuth();
  const {
    config: { storage }
  } = useConfig();
  const { initiatePOSPurchas, modal } = useActivationTariff();
  const { setActivationInitialValues, setCurrentRoute } = usePayment();
  const { setActivationType } = useActivation();

  const {
    hoverHeader,
    setHoverHeader,
    showLightHeader,
    showScrollDarkHeader,
    toggleHover,
    onBlur,
    toggleHoverMenu,
    onBlurMenu,
    hoverMenu,
    showMobileMenu,
    setShowMobileMenu
  } = useLayout();

  // Functions

  // const currentTime = new Date().getTime();
  // const localStorageTimeLeft = currentTime + 1800000;

  // const onLogoutPress = async () => {
  //   const authToken = await storage.encryptedGetItem(appStorage.AUTH_TOKEN);
  //   const refreshToken = await storage.encryptedGetItem(appStorage.AUTH_REFRESH_TOKEN);
  //   try {
  //     // const response =
  //     await onLogout(refreshToken, authToken);
  //     // if (response.status === 200) {
  //     // console.log("success response--------", response);
  //     setUserLogin(false);
  //     localStorage.clear();
  //     sessionStorage.clear();
  //     showAlert({ type: appAlert.SUCCESS, message: 'Logged out successfully!' });
  //     // navigate(appRoute.LOGIN);
  //     window.location.href = appRoute.LOGIN;
  //     // }
  //   } catch (err) {
  //     // console.log('Logout error', err);
  //     localStorage.clear();
  //     sessionStorage.clear();
  //     deleteAllCookies();
  //     showAlert({ type: appAlert.SUCCESS, message: 'Logged out successfully!' });
  //     // navigate(appRoute.LOGIN);
  //     window.location.href = appRoute.LOGIN;

  //     // if (err.status === 401 || err.status === StatusCodes.FORBIDDEN) {
  //     //   onTokenExpire().then((res) => {
  //     //     if (res.status === 200) {
  //     //       // console.log("onLogoutPress error response", res);
  //     //       onLogoutPress();
  //     //     }
  //     //   }).catch((error) => {
  //     //     console.log("onTokenExpire---------", error);
  //     //     setUserLogin(false);
  //     //     localStorage.clear();
  //     //     sessionStorage.clear();
  //     //     navigate(appRoute.LOGIN);
  //     //   });
  //     // }
  //   }
  // };

  // Hooks
  // useEffect(() => {
  //   if (hoverHeader || showMobileMenu) {
  //     // console.log({ hoverHeader, showMobileMenu });
  //     document.body.className = 'overlay';
  //   } else {
  //     document.body.className = '';
  //   }
  //   return () => {
  //     document.body.className = '';
  //   };
  // }, [hoverHeader, showMobileMenu]);

  return (
    <header
      className={`position-fixed w-100 ${!modal ? 'z-3' : ''}`}
      onMouseEnter={toggleHover}
      onMouseLeave={onBlur}
    >
      <nav
        className={`navbar navbar-expand-lg navbar-dark ${
          (hoverHeader || showLightHeader || showScrollDarkHeader || showMobileMenu) &&
          'bg-primary-color'
        } py-3`}
        aria-label="Ninth navbar example"
      >
        <div className="container">
          <NavLink
            className="navbar-brand"
            to={appRoute.HOME}
            onClick={() => {
              if (window && window.utag) {
                window.utag.link(UTAG_LINK.HEADER_ON_CLICK.MAIN_MENU_ON_CLICK);
              }
            }}
          >
            {' '}
            <img
              className="logo mx-100"
              src={
                hoverHeader || showLightHeader || showScrollDarkHeader || showMobileMenu
                  ? appImages.logowhite
                  : appImages.logodark
              }
              alt="Share logo"
            />
          </NavLink>
          <button
            className="navbar-toggler d-flex d-lg-none align-items-center shadow-none border-0 p-0 collapsed"
            type="button"
            onClick={() => {
              setShowMobileMenu(!showMobileMenu);
              setHoverHeader(false);
              if (window && window.utag) {
                window.utag.link(UTAG_LINK.HEADER_ON_CLICK.MAIN_MENU_ON_CLICK);
              }
            }}
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div
              className={`menu-button-text m-0 mx-3 label-medium ${
                showLightHeader || !!hoverHeader || showMobileMenu || showScrollDarkHeader
                  ? 'text-white'
                  : 'primary-color-text'
              }`}
            >
              Menü
            </div>
            <span>
              {showMobileMenu ? (
                <img
                  className="logo mx-100"
                  width={20}
                  src={appImages.closemenudark}
                  alt="Close menu "
                />
              ) : (
                <img
                  className="logo mx-100"
                  src={
                    showLightHeader || !!hoverHeader || showScrollDarkHeader
                      ? appImages.humbermenuwhite
                      : appImages.humbermenudark
                  }
                  alt="humberg menu "
                />
              )}
              {/* when in mobile menu open at that time show close icon and hide humber menu */}
            </span>
          </button>

          <div
            className={`navbar-collapse collapse ${showMobileMenu && 'show'}`}
            id="navbarsExample07XL"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a
                  className={`${
                    hoverHeader || showLightHeader || showScrollDarkHeader
                      ? 'text-white'
                      : 'primary-color-text'
                  } body-medium-regular text-decoration-none d-none d-lg-block px-4`}
                  aria-current="page"
                  id="dropdown07XL"
                  data-bs-toggle="dropdown"
                  onMouseEnter={() => toggleHoverMenu(1)}
                >
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_h1') }} />
                </a>
                <div
                  className={`submenu dropdown-menu px-2 py-0 px-lg-0 m-0 ${
                    hoverMenu === 1 && 'show'
                  }`}
                  onMouseLeave={onBlurMenu}
                >
                  <ul
                    className="inner-submenu mt-5"
                    aria-labelledby="dropdown07XL"
                    // onMouseLeave={onBlur}
                  >
                    <li>
                      <div
                        className="dropdown-menu-title dropdown-item pink-color-text text-decoration-none px-0"
                        dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_h1') }}
                      />
                    </li>
                    <li>
                      <Link
                        className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                        // onClick={initiatePOSPurchas}
                        href={appRoute.HOME}
                        to={appRoute.HOME}
                        onClick={() => {
                          setShowMobileMenu(false);
                          if (window && window.utag) {
                            window.utag.link(UTAG_LINK.HEADER_ON_CLICK.TARIFF_ON_CLICK);
                          }
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_sub1') }} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                        href={appRoute.ACTIVATE_SIM_CARD}
                        to={appRoute.ACTIVATE_SIM_CARD}
                        onClick={() => {
                          setShowMobileMenu(false);
                          if (window && window.utag) {
                            window.utag.link(UTAG_LINK.HEADER_ON_CLICK.TARIFF_ON_CLICK);
                          }
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_sub2') }} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                        href={appRoute.TOPUP_CREDIT}
                        to={appRoute.TOPUP_CREDIT}
                        onClick={() => {
                          setShowMobileMenu(false);
                          if (window && window.utag) {
                            window.utag.link(UTAG_LINK.HEADER_ON_CLICK.TARIFF_ON_CLICK);
                          }
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_sub3') }} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                        href={appRoute.DOWNLOAD_APP}
                        to={appRoute.DOWNLOAD_APP}
                        onClick={() => {
                          setShowMobileMenu(false);
                          if (window && window.utag) {
                            window.utag.link(UTAG_LINK.HEADER_ON_CLICK.TARIFF_ON_CLICK);
                          }
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_sub4') }} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={`${
                    hoverHeader || showLightHeader || showScrollDarkHeader
                      ? 'text-white'
                      : 'primary-color-text'
                  } body-medium-regular text-decoration-none d-none d-lg-block px-4`}
                  id="dropdown07XL"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onMouseEnter={() => toggleHoverMenu(3)}
                  to={appRoute.ACTIVATION}
                  onClick={() => {
                    if (window && window.utag) {
                      window.utag.link(UTAG_LINK.HEADER_ON_CLICK.ACTIVATION_ON_CLICK);
                    }
                    setShowMobileMenu(false);
                    setActivationType(appActivationFlowType.SIM_ACTIVATION);
                  }}
                >
                  <div
                    className="d-none d-lg-block "
                    dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_sub2') }}
                  />
                </Link>
                <div
                  className={`submenu dropdown-menu px-2 py-0 px-lg-0 m-0 ${
                    hoverMenu === 3 && 'show'
                  }`}
                  onMouseLeave={onBlurMenu}
                >
                  <ul
                    className="inner-submenu mt-4 mt-lg-5"
                    aria-labelledby="dropdown07XL"
                    // onMouseLeave={onBlur}
                  >
                    <li>
                      <Link
                        className="dropdown-menu-title dropdown-item pink-color-text text-decoration-none px-0 d-block d-lg-none"
                        to={appRoute.ACTIVATION}
                        onClick={() => {
                          if (window && window.utag) {
                            window.utag.link(UTAG_LINK.HEADER_ON_CLICK.ACTIVATION_ON_CLICK);
                          }
                          setShowMobileMenu(false);
                          setActivationType(appActivationFlowType.SIM_ACTIVATION);
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_sub2') }} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className={`${
                    hoverHeader || showLightHeader || showScrollDarkHeader
                      ? 'text-white'
                      : 'primary-color-text'
                  } body-medium-regular text-decoration-none d-none d-lg-block px-4`}
                  id="dropdown07XL"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onMouseEnter={() => toggleHoverMenu(2)}
                >
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_h2') }} />
                </a>
                <div
                  className={`submenu dropdown-menu px-2 py-0 px-lg-0 m-0 ${
                    hoverMenu === 2 && 'show'
                  }`}
                  onMouseLeave={onBlurMenu}
                >
                  <ul
                    className="inner-submenu mt-4 mt-lg-5"
                    aria-labelledby="dropdown07XL"
                    // onMouseLeave={onBlur}
                  >
                    <li>
                      <div
                        className="dropdown-menu-title dropdown-item pink-color-text text-decoration-none px-0"
                        dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_h2') }}
                      />
                    </li>
                    <li>
                      <Link
                        className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                        href={appRoute.WHY_SHARE_MOBILE}
                        to={appRoute.WHY_SHARE_MOBILE}
                        onClick={() => {
                          setShowMobileMenu(false);
                          if (window && window.utag) {
                            window.utag.link(UTAG_LINK.HEADER_ON_CLICK.ABOUT_US_ON_CLICK);
                          }
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_sub5') }} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                        href={appRoute.GENERIC_GREETINGS}
                        to={appRoute.GENERIC_GREETINGS}
                        onClick={() => {
                          setShowMobileMenu(false);
                          if (window && window.utag) {
                            window.utag.link(UTAG_LINK.HEADER_ON_CLICK.ABOUT_US_ON_CLICK);
                          }
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_sub6') }} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={`${
                    hoverHeader || showLightHeader || showScrollDarkHeader
                      ? 'text-white'
                      : 'primary-color-text'
                  } body-medium-regular text-decoration-none d-none d-lg-block px-4`}
                  id="dropdown07XL"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onMouseEnter={() => toggleHoverMenu(3)}
                  to={appRoute.SHARING}
                  onClick={() => {
                    if (window && window.utag) {
                      window.utag.link(UTAG_LINK.HEADER_ON_CLICK.FOR_FRIENDS_ON_CLICK);
                    }
                    setShowMobileMenu(false);
                  }}
                >
                  <div
                    className="d-none d-lg-block "
                    dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_h3') }}
                  />
                </Link>
                <div
                  className={`submenu dropdown-menu px-2 py-0 px-lg-0 m-0 ${
                    hoverMenu === 3 && 'show'
                  }`}
                  onMouseLeave={onBlurMenu}
                >
                  <ul
                    className="inner-submenu mt-4 mt-lg-5"
                    aria-labelledby="dropdown07XL"
                    // onMouseLeave={onBlur}
                  >
                    <li>
                      <Link
                        className="dropdown-menu-title dropdown-item pink-color-text text-decoration-none px-0 d-block d-lg-none"
                        to={appRoute.SHARING}
                        onClick={() => {
                          setShowMobileMenu(false);
                          if (window && window.utag) {
                            window.utag.link(UTAG_LINK.HEADER_ON_CLICK.FOR_FRIENDS_ON_CLICK);
                          }
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_h3') }} />
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                        to={appRoute.SHARING}
                        onClick={() => setShowMobileMenu(false)}>
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_h3') }} />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className={`${
                    hoverHeader || showLightHeader || showScrollDarkHeader
                      ? 'text-white'
                      : 'primary-color-text'
                  } body-medium-regular text-decoration-none d-none d-lg-block px-4`}
                  id="dropdown07XL"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onMouseEnter={() => toggleHoverMenu(4)}
                >
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_h4') }} />
                </a>
                <div
                  className={`submenu dropdown-menu px-2 py-0 px-lg-0 m-0 ${
                    hoverMenu === 4 && 'show'
                  }`}
                  onMouseLeave={onBlurMenu}
                >
                  <ul
                    className="inner-submenu mt-4 mt-lg-5"
                    aria-labelledby="dropdown07XL"
                    // onMouseLeave={onBlur}
                  >
                    <li>
                      <div
                        className="dropdown-menu-title dropdown-item pink-color-text text-decoration-none px-0"
                        dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_h4') }}
                      />
                    </li>
                    <li>
                      <Link
                        className="dropdown-item body-medium-regular text-white text-decoration-none px-0"
                        href={appRoute.FAQ_LIST}
                        to={appRoute.FAQ_LIST}
                        onClick={() => {
                          setShowMobileMenu(false);
                          if (window && window.utag) {
                            window.utag.link(UTAG_LINK.HEADER_ON_CLICK.HELP_SERVICE_ON_CLICK);
                          }
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_sub7') }} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item body-medium-regular text-white text-decoration-none px-0"
                        href={appRoute.ROAMING}
                        to={appRoute.ROAMING}
                        onClick={() => {
                          setShowMobileMenu(false);
                          if (window && window.utag) {
                            window.utag.link(UTAG_LINK.HEADER_ON_CLICK.HELP_SERVICE_ON_CLICK);
                          }
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_sub8') }} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item body-medium-regular text-white text-decoration-none px-0"
                        href={appRoute.CONTACT}
                        to={appRoute.CONTACT}
                        onClick={() => {
                          setShowMobileMenu(false);
                          if (window && window.utag) {
                            window.utag.link(UTAG_LINK.HEADER_ON_CLICK.HELP_SERVICE_ON_CLICK);
                          }
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: t('sh_web_header_navi_sub9') }} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            {/* <div className="w-full justify-content-end align-items-center overview-powered-logo">
              <p
                className={`${
                  hoverHeader || showLightHeader || showScrollDarkHeader || showMobileMenu
                    ? 'text-white'
                    : 'primary-color-text'
                } label-small link-light tariff-link shadow-none m-0`}
              >
                {t('sh_apoc_tarif_overview_powered')}
              </p>
              <img src={appImages.CongStarWhite02} />
            </div> */}
          </div>
        </div>
      </nav>
    </header>
  );

  // return (
  //   <>
  //     <header className="header_area desktop-header">
  //       <div className="main_header_area animated">
  //         <div className="container">
  //           <nav id="navigation1" className="navigation navigation-landscape">
  //             <div className="nav-header">
  //               <a className="nav-brand" href={appRoute.HOME}>
  //                 <img src="image/logo.svg" />
  //               </a>
  //               <div className="nav-toggle"></div>
  //             </div>

  //             <div className="nav-menus-wrapper">
  //               <ul className="nav-menu align-to-right">
  //                 <li className="huhuh">
  //                   {/* onMouseOut={()=>{document.getElementById("main").className = "bg-default text-white pt-3"}} onMouseOver={()=>{document.getElementById("main").className = "bg-default text-white bg-deem pt-3"}} */}
  //                   <a href="#">Tarif</a>
  //                   <div className="megamenu-panel">
  //                     <div className="megamenu-lists">
  //                       <ul className="megamenu-list list-col-4"></ul>
  //                       <ul className="megamenu-list list-col-4"></ul>
  //                       <ul className="megamenu-list list-col-4">
  //                         <li className="label-li">TARIF</li>
  //                         <li>
  //                           <NavDropdown.Item as={Link} onClick={initiatePOSPurchas}>
  //                             Tut Gutes Tarif
  //                           </NavDropdown.Item>
  //                         </li>
  //                         <li>
  //                           <NavDropdown.Item
  //                             as={Link}
  //                             href={appRoute.ACTIVATE_SIM_CARD}
  //                             to={appRoute.ACTIVATE_SIM_CARD}
  //                           >
  //                             SIM-Karte aktivieren
  //                           </NavDropdown.Item>
  //                         </li>
  //                         <li>
  //                           <NavDropdown.Item
  //                             as={Link}
  //                             href={appRoute.TOPUP_CREDIT}
  //                             to={appRoute.TOPUP_CREDIT}
  //                           >
  //                             Guthaben aufladen
  //                           </NavDropdown.Item>
  //                         </li>
  //                         <li>
  //                           <NavDropdown.Item
  //                             as={Link}
  //                             href={appRoute.DOWNLOAD_APP}
  //                             to={appRoute.DOWNLOAD_APP}
  //                           >
  //                             share mobile App
  //                           </NavDropdown.Item>
  //                         </li>
  //                       </ul>
  //                     </div>
  //                     <div className="curve"></div>
  //                   </div>
  //                 </li>
  //                 <li className="huhuh">
  //                   <a href="#">Über uns</a>
  //                   <div className="megamenu-panel">
  //                     <div className="megamenu-lists">
  //                       <ul className="megamenu-list list-col-4"></ul>
  //                       <ul className="megamenu-list list-col-4"></ul>
  //                       <ul className="megamenu-list uber-uns list-col-4">
  //                         <li className="label-li">Über UNS</li>
  //                         <li>
  //                           <NavDropdown.Item
  //                             as={Link}
  //                             href={appRoute.WHY_SHARE_MOBILE}
  //                             to={appRoute.WHY_SHARE_MOBILE}
  //                           >
  //                             Über share mobile
  //                           </NavDropdown.Item>
  //                         </li>
  //                         <li>
  //                           <NavDropdown.Item
  //                             as={Link}
  //                             href={appRoute.GENERIC_GREETINGS}
  //                             to={appRoute.GENERIC_GREETINGS}
  //                           >
  //                             Projekte
  //                           </NavDropdown.Item>
  //                         </li>
  //                       </ul>
  //                     </div>
  //                     <div className="curve"></div>
  //                   </div>
  //                 </li>
  //                 <li className="huhuh">
  //                   <Nav.Link as={Link} to={appRoute.SHARING}>
  //                     Freunde werben
  //                   </Nav.Link>
  //                   {/* <div className="megamenu-panel">
  //                     <div className="megamenu-lists">
  //                       <ul className="megamenu-list list-col-4">
  //                       </ul>
  //                       <ul className="megamenu-list list-col-3">
  //                       </ul>
  //                       <ul className="megamenu-list uber-uns list-col-4">
  //                         <li className="label-li">Über uns</li>
  //                         <li><a href="#" target="_blank">Über share mobile</a></li>
  //                         <li><a href="#" target="_blank">Projekte</a></li>
  //                       </ul>
  //                     </div>
  //                     <div className="curve"></div>
  //                   </div> */}
  //                 </li>
  //                 {/* bg-default text-white pt-3 */}
  //                 <li className="huhuh">
  //                   <a href="#">Hilfe & Service</a>
  //                   <div className="megamenu-panel">
  //                     <div className="megamenu-lists">
  //                       <ul className="megamenu-list list-col-3"></ul>
  //                       <ul className="megamenu-list list-col-3"></ul>
  //                       <ul className="megamenu-list uber-uns list-col-4">
  //                         <li className="label-li">Hilfe & Service</li>

  //                         <li>
  //                           <NavDropdown.Item
  //                             as={Link}
  //                             href={appRoute.FAQ_LIST}
  //                             to={appRoute.FAQ_LIST}
  //                           >
  //                             FAQ
  //                           </NavDropdown.Item>
  //                         </li>
  //                         <li>
  //                           <NavDropdown.Item
  //                             as={Link}
  //                             href={appRoute.ROAMING}
  //                             to={appRoute.ROAMING}
  //                           >
  //                             Roaming im Ausland
  //                           </NavDropdown.Item>
  //                         </li>
  //                         <li>
  //                           <NavDropdown.Item
  //                             as={Link}
  //                             href={appRoute.CONTACT}
  //                             to={appRoute.CONTACT}
  //                           >
  //                             Kontakt
  //                           </NavDropdown.Item>
  //                         </li>
  //                       </ul>
  //                     </div>
  //                     <div className="curve"></div>
  //                   </div>
  //                 </li>
  //                 <li className="huhuh">
  //                   <a href="#">
  //                     Share.eu{' '}
  //                     <i className="ms-2 nav-icon-div fa-solid fa-up-right-from-square"></i>
  //                   </a>
  //                 </li>
  //               </ul>
  //             </div>
  //           </nav>
  //         </div>
  //       </div>
  //     </header>

  //     <header className="mobile-header">
  //       <nav className="navbar navbar-dark bg-primary-color shadow">
  //         <div className="container-fluid">
  //           <a className="nav-brand" href={appRoute.HOME}>
  //             <img src="image/logo.svg" />
  //           </a>

  //           <button
  //             className="navbar-toggler border-0 label-small text-white shadow-none"
  //             type="button"
  //             data-bs-toggle="modal"
  //             data-bs-target="#exampleModal"
  //           >
  //             Menü
  //             <span className="navbar-toggler-icon ms-2 text-white"></span>
  //           </button>
  //         </div>
  //       </nav>

  //       <div
  //         className="modal true"
  //         id="exampleModal"
  //         tabindex="-1"
  //         aria-labelledby="exampleModalLabel"
  //         aria-hidden="true"
  //       >
  //         <div className="modal-dialog modal-fullscreen">
  //           <div className="modal-content bg-primary-color">
  //             <div className="modal-header border-0">
  //               <a className="nav-brand" href={appRoute.HOME}>
  //                 <img src="image/logo.svg" />
  //               </a>
  //               <div className="mobile-h-close">
  //                 <h5 className="modal-title text-white label-small" id="exampleModalLabel">
  //                   Menü
  //                 </h5>
  //                 <button
  //                   type="button"
  //                   className="bg-transparent border-0 btn-light text-white"
  //                   data-bs-dismiss="modal"
  //                   aria-label="Close"
  //                 >
  //                   <i className="fa fa-close"></i>
  //                 </button>
  //               </div>
  //             </div>
  //             <div className="modal-body">
  //               <ul className="list-group list-group-flush">
  //                 <li className="list-group-item text-pink">
  //                   TARIF
  //                   <a as={Link} onClick={initiatePOSPurchas} className="text-off-white">
  //                     {' '}
  //                     Tut Gutes Tarif{' '}
  //                   </a>
  //                   <a
  //                     as={Link}
  //                     href={appRoute.ACTIVATE_SIM_CARD}
  //                     to={appRoute.ACTIVATE_SIM_CARD}
  //                     className="text-off-white"
  //                   >
  //                     {' '}
  //                     SIM-Karte aktivieren{' '}
  //                   </a>
  //                   <a
  //                     as={Link}
  //                     href={appRoute.TOPUP_CREDIT}
  //                     to={appRoute.TOPUP_CREDIT}
  //                     className="text-off-white"
  //                   >
  //                     {' '}
  //                     Guthaben aufladen{' '}
  //                   </a>
  //                   <a
  //                     as={Link}
  //                     href={appRoute.DOWNLOAD_APP}
  //                     to={appRoute.DOWNLOAD_APP}
  //                     className="text-off-white"
  //                   >
  //                     {' '}
  //                     share mobile App{' '}
  //                   </a>
  //                 </li>

  //                 <li className="list-group-item text-pink">
  //                   Über uns
  //                   <a
  //                     as={Link}
  //                     href={appRoute.WHY_SHARE_MOBILE}
  //                     to={appRoute.WHY_SHARE_MOBILE}
  //                     className="text-off-white"
  //                   >
  //                     {' '}
  //                     Über share mobile{' '}
  //                   </a>
  //                   <a href="" className="text-off-white">
  //                     {' '}
  //                     Projekte{' '}
  //                   </a>
  //                 </li>

  //                 <li className="list-group-item text-pink">
  //                   Freunde werben
  //                   <a
  //                     as={Link}
  //                     href={appRoute.GENERIC_GREETINGS}
  //                     to={appRoute.GENERIC_GREETINGS}
  //                     className="text-off-white"
  //                   >
  //                     {' '}
  //                     Freunde werben{' '}
  //                   </a>
  //                 </li>

  //                 <li className="list-group-item text-pink">
  //                   Hilfe & Service
  //                   <a
  //                     as={Link}
  //                     href={appRoute.FAQ_LIST}
  //                     to={appRoute.FAQ_LIST}
  //                     className="text-off-white"
  //                   >
  //                     {' '}
  //                     FAQ{' '}
  //                   </a>
  //                   <a
  //                     as={Link}
  //                     href={appRoute.ROAMING}
  //                     to={appRoute.ROAMING}
  //                     className="text-off-white"
  //                   >
  //                     {' '}
  //                     Roaming im Ausland{' '}
  //                   </a>
  //                   <a
  //                     as={Link}
  //                     href={appRoute.CONTACT}
  //                     to={appRoute.CONTACT}
  //                     className="text-off-white"
  //                   >
  //                     {' '}
  //                     Kontakt
  //                   </a>
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </header>
  //   </>

  //   //   <Navbar
  //   //   collapseOnSelect
  //   //   expand="lg"

  //   //   className="px-3 py-2 bg-default   custom-nav "
  //   // >
  //   //   <Container>
  //   //     <Navbar.Brand as={Link} to={appRoute.HOME}>
  //   //      <img src='image/logo.svg' />
  //   //     </Navbar.Brand>
  //   //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  //   //     <Navbar.Collapse id="responsive-navbar-nav">
  //   //       <Nav className="ms-auto">
  //   //         <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small text-secondary">
  //   //           <Navbar.Collapse id="tarif-nav-bar">
  //   //             <nav>
  //   //               <NavDropdown id="tarif-nav-dropdown" title="Tarif" menuVariant="dark">
  //   //                 <NavDropdown.Item as={Link} onClick={initiatePOSPurchas}>
  //   //                   Tut Gutes Tarif
  //   //                 </NavDropdown.Item>
  //   //                 <NavDropdown.Item
  //   //                   as={Link}
  //   //                   href={appRoute.ACTIVATE_SIM_CARD}
  //   //                   to={appRoute.ACTIVATE_SIM_CARD}
  //   //                 >
  //   //                   SIM-Karte aktivieren
  //   //                 </NavDropdown.Item>
  //   //                 <NavDropdown.Item
  //   //                   as={Link}
  //   //                   href={appRoute.TOPUP_CREDIT}
  //   //                   to={appRoute.TOPUP_CREDIT}
  //   //                 >
  //   //                   Guthaben aufladen
  //   //                 </NavDropdown.Item>
  //   //                 <NavDropdown.Item
  //   //                   as={Link}
  //   //                   href={appRoute.DOWNLOAD_APP}
  //   //                   to={appRoute.DOWNLOAD_APP}
  //   //                 >
  //   //                   share mobile App
  //   //                 </NavDropdown.Item>
  //   //               </NavDropdown>
  //   //             </nav>
  //   //           </Navbar.Collapse>

  //   //           <li className="nav-item dropdown">
  //   //             <Navbar.Collapse id="about-us-nav-bar">
  //   //               <nav>
  //   //                 <NavDropdown id="about-us-nav-dropdown" title="Überuns" menuVariant="dark">
  //   //                   <NavDropdown.Item
  //   //                     as={Link}
  //   //                     href={appRoute.WHY_SHARE_MOBILE}
  //   //                     to={appRoute.WHY_SHARE_MOBILE}
  //   //                   >
  //   //                     Über share mobile
  //   //                   </NavDropdown.Item>
  //   //                   <NavDropdown.Item
  //   //                     as={Link}
  //   //                     href={appRoute.GENERIC_GREETINGS}
  //   //                     to={appRoute.GENERIC_GREETINGS}
  //   //                   >
  //   //                     Unser Hilfsprojekt EIDU
  //   //                   </NavDropdown.Item>
  //   //                 </NavDropdown>
  //   //               </nav>
  //   //             </Navbar.Collapse>
  //   //           </li>
  //   //           {/* <li>
  //   //                 <Nav.Link as={Link} to={appRoute.LOGIN}>
  //   //                   Tarif
  //   //                 </Nav.Link>
  //   //               </li> */}
  //   //           {/* <li>
  //   //                 <Nav.Link as={Link} to={appRoute.LOGIN}>
  //   //                   Überuns
  //   //                 </Nav.Link>
  //   //               </li> */}
  //   //           <li>
  //   //             <Nav.Link as={Link} to={appRoute.SHARING}>
  //   //               Freundewerben
  //   //             </Nav.Link>
  //   //           </li>
  //   //           <li>
  //   //             <Navbar.Collapse id="help-service-nav-bar">
  //   //               <nav>
  //   //                 <NavDropdown
  //   //                   id="help-service-nav-dropdown"
  //   //                   title="Hilfe & Service"
  //   //                   menuVariant="dark"
  //   //                 >

  //   //                   <NavDropdown.Item as={Link} href={appRoute.FAQ_LIST} to={appRoute.FAQ_LIST}>
  //   //                     FAQ
  //   //                   </NavDropdown.Item>
  //   //                   <NavDropdown.Item as={Link} href={appRoute.ROAMING} to={appRoute.ROAMING}>
  //   //                     Roaming im Ausland
  //   //                   </NavDropdown.Item>
  //   //                   <NavDropdown.Item as={Link} href={appRoute.CONTACT} to={appRoute.CONTACT}>
  //   //                     Kontakt
  //   //                   </NavDropdown.Item>
  //   //                 </NavDropdown>
  //   //               </nav>
  //   //             </Navbar.Collapse>
  //   //           </li>
  //   //           {/* <li>
  //   //                 <Nav.Link as={Link} to={appRoute.LOGIN}>
  //   //                   Login
  //   //                 </Nav.Link>
  //   //               </li> */}
  //   //         </ul>
  //   //       </Nav>
  //   //     </Navbar.Collapse>
  //   //   </Container>
  //   // </Navbar>
  // );
}

export default Header;
