import React, { useEffect } from 'react';
// import { Spinner as BSpinner } from 'react-bootstrap';

import { Col, Container, Row } from '@core/Layout';
// import { TariffCatalogue } from '@part/Tariff';
// import { OptionCatalogue } from '@part/Option';
// import { DataPassCatalogue } from '@part/DataPass';
// import { SpeedOnCatalogue } from '@part/SpeedOn';
// import { RoamingCatalogue } from '@part/Roaming';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAccount, useCustomer, useOption, useTariff } from '@context/MobileOne';
import { Button } from '@core/Inputs';
import { useNavigate } from 'react-router-dom';
import { appAlert, appRoute } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';

export function Tariff() {
  // Contexts
  const {
    isLoading: isCustomerLoading,
    customerData: { msisdn, customerNumber }
  } = useCustomer();
  const { isLoading: isOptionLoading } = useOption();
  const { setIsLoading } = useTariff();
  const { isCancleContractProcessed } = useAccount();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const { activeTariff } = useTariff();
  const { onBookableTariffIsPossibleCall } = useMobileOne();

  useEffect(() => {
    setIsLoading(isCustomerLoading || isOptionLoading);
    // Clean up
    return () => {
      setIsLoading(false);
    };
  }, [isCustomerLoading, isOptionLoading]);

  useEffect(() => {
    if (isCancleContractProcessed) {
      showAlert({ type: appAlert.DARK, message: t('sh_cancel_contract_confirmation_info') });
    }
  }, [isCancleContractProcessed]);

  const tariffChange = async () => {
    const { data, success } = await onBookableTariffIsPossibleCall();
    if (success) {
      navigate(appRoute.TARIFF_CHANGE);
    } else {
      showAlert({ type: appAlert.ERROR, message: data.errorMessage });
    }
  };

  return (
    <>
      <Container>
        {/* Page Title */}
        <Row>
          <Col xs={12}>
            <center>
              <h3>{t('sh_tab_two_db_h1')}</h3>
            </center>
          </Col>
        </Row>
        <br />
        {/* Account Information */}
        <Row>
          <Col xs={6} className="d-flex align-items-start flex-column">
            <p>
              <strong>{t('sh_tab_two_number_subheadline')}</strong>
            </p>
            <p>
              <strong>{t('sh_tab_two_customernumber_subheadline')}</strong>
            </p>
            <p>
              <strong>{t('sh_tab_two_duration_subheadline')}</strong>
            </p>
            <p>
              <strong>{t('sh_tab_two_cancellation_period_subheadline')}</strong>
            </p>
          </Col>
          <Col xs={6} className="d-flex align-items-end flex-column">
            <p>{msisdn}</p>
            <p>{customerNumber}</p>
            <p>
              {activeTariff[0] && !activeTariff[0]?.endDate
                ? 'N/A'
                : `Bis ${activeTariff[0]?.endDate}`}
            </p>
            <p>Zu sofort moglich</p>
          </Col>
        </Row>
        {/* Buttons */}
        <Row>
          <center>
            <Col xs={12} className="d-grid gap-2">
              <Button
                variant="outline-dark"
                className="d-flex align-items-center justify-content-between"
                onClick={() => navigate(appRoute.SIM_CARD_E_SIM)}
              >
                <span>{t('sh_tab_two_sim_card_and_esim_button')}</span>
                <i className="bi bi-chevron-right" />
              </Button>
            </Col>
            <br />
            {isCancleContractProcessed ? (
              <></>
            ) : (
              <Col xs={12} className="d-grid gap-2">
                <Button
                  variant="outline-dark"
                  className="d-flex align-items-center justify-content-between"
                  onClick={() => navigate(appRoute.EVN)}
                >
                  <span>{t('sh_tab_two_evn_button')}</span>
                  <i className="bi bi-chevron-right" />
                </Button>
              </Col>
            )}
          </center>
        </Row>
        {/* Tariff Details */}
        {activeTariff.length > 0 && (
          <Row>
            <Col xs={12}>
              <br />
              <h4>
                <strong>{activeTariff[0].tariff.name}</strong>
              </h4>
            </Col>
            <Col xs={12}>
              {activeTariff[0]?.tariff.additionalInfo?.bullets.map((item, key) => (
                <div key={key}>
                  <i className="bi bi-check-circle" /> &nbsp; {item.text} <br />
                </div>
              ))}
            </Col>
            <Col xs={12}>
              <div className="d-flex m-2" style={{ alignItems: 'center' }}>
                <h3>{activeTariff[0]?.tariff?.starterPackPrice}&euro;</h3> /{' '}
                {activeTariff[0]?.tariff?.duration}&nbsp;{activeTariff[0]?.tariff?.durationUnit}
              </div>
            </Col>
          </Row>
        )}
        {/* Button */}
        <Row>
          {isCancleContractProcessed ? (
            <p>
              <b>{t('sh_cancel_contract_date')}</b>
            </p>
          ) : (
            <center>
              <Col xs={12} className="d-grid gap-2">
                <Button
                  variant="outline-dark"
                  className="d-flex align-items-center justify-content-between mt-2"
                  onClick={tariffChange}
                >
                  <span>{t('sh_tab_two_tariff_change_button')}</span>
                  <i className="bi bi-chevron-right" />
                </Button>
              </Col>
              <Col xs={12}>
                <Button
                  variant="link"
                  className="d-flex align-items-center justify-content-between mt-2"
                  onClick={() => navigate(appRoute.CANCEL_CONTRACT)}
                >
                  {t('sh_tab_two_cancel_contract_link')}
                </Button>
              </Col>
            </center>
          )}
        </Row>
      </Container>

      {/* <Container>
        <div >
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <h2>
              {t('dashboard-tariff')} &amp; {t('dashboard-options')}
            </h2>
            {isLoading && <BSpinner size="xl" animation="border" role="status" />}
          </div>

          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('dashboard-bookable-tariff-catalogue')}</h5>
            {isLoading && <BSpinner size="sm" animation="border" role="status" />}
          </div>

          <hr />
          <TariffCatalogue />
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('dashboard-bookable-options')}</h5>
            {isLoading && <BSpinner size="sm" animation="border" role="status" />}
          </div>

          <hr />
          <OptionCatalogue />
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('dashboard-datapass-catalogue')}</h5>
            {isLoading && <BSpinner size="sm" animation="border" role="status" />}
          </div>

          <hr />
          <DataPassCatalogue />
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('dashboard-speedon-catalogue')}</h5>
            {isLoading && <BSpinner size="sm" animation="border" role="status" />}
          </div>

          <hr />
          <SpeedOnCatalogue />
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('dashboard-roaming-catalogue')}</h5>
            {isLoading && <BSpinner size="sm" animation="border" role="status" />}
          </div>

          <hr />
          <RoamingCatalogue />
          <hr />
        </div>
      </Container> */}
    </>
  );
}

export default Tariff;

export * from './Change';
export * from './Evn';
export * from './SimCardESim';
