import React from 'react';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { appActivationFlowType, appTariff } from '@utils/globalConstant';
import { useActivation } from '@context/MobileOne';

export function TariffDetailsModal({ setModal }) {
  // Context
  const { t } = useStaticContent();
  const { activationType } = useActivation();
  const { activeTariff } = useActivationTariff();

  return (
    <Modal
      showCloseButton
      onCloseClick={() => {
        setModal('');
      }}
      stepHeader={
        <div dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details') }} />
      }
      header={activeTariff.name}
    >
      <div className="my-4">
        <h5
          className="primary-color-text mb-3"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_h1') }}
        />
        <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub1') }}
        />
        {activeTariff.id == appTariff.FLAT_M ? (
          <div
            className="m-0 body-large-regular grey-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_textM') }}
          />
        ) : (
          <div
            className="m-0 body-large-regular grey-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_textL') }}
          />
        )}
        <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub2') }}
        />
        <div
          className="m-0 body-large-regular grey-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text2') }}
        />
        {/* Removed EINMALIGE BEREITSTELLUNGSGEBÜHR for March-April campaign for activation journey*/}
        {/* {activationType === appActivationFlowType.NEW_ONLINE && (
          <div
            className="m-0 body-large-bold grey-color-text text-uppercase"
            dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub3') }}
          />
        )} */}
        {/* <div
          className="m-0 body-large-regular grey-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text3') }}
        /> */}
      </div>

      <div className="my-4">
        <h5
          className="primary-color-text mb-3"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_h2') }}
        />
        <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub4') }}
        />
        <div
          className="m-0 body-large-regular grey-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text4') }}
        />
      </div>

      <div className="my-4">
        <h5
          className="primary-color-text mb-3"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_h3') }}
        />
        <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub5') }}
        />
        <div
          className="m-0 body-large-regular grey-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text5') }}
        />
      </div>

      <div className="my-4">
        <h5
          className="primary-color-text mb-3"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_h4') }}
        />
        <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub6') }}
        />
        {activeTariff.id === appTariff.FLAT_M ? (
          <div
            className="m-0 body-large-regular grey-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text6') }}
          />
        ) : (
          <div
            className="m-0 body-large-regular grey-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text6L') }}
          />
        )}
      </div>

      <div className="my-4">
        <h5
          className="primary-color-text mb-3"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_h5') }}
        />
        {/* <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub7') }}
        /> */}
        <div
          className="m-0 body-large-regular grey-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text7') }}
        />
      </div>

      <div className="my-4">
        <h5
          className="primary-color-text mb-3"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_h6') }}
        />
        <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub8') }}
        />
        <div
          className="m-0 body-large-regular grey-color-text mb-3"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text8') }}
        />
        <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub9') }}
        />
        <div
          className="m-0 body-large-regular grey-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text9') }}
        />
        <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub10') }}
        />
        <div
          className="m-0 body-large-regular grey-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text10') }}
        />
        <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub11') }}
        />
        <div
          className="m-0 body-large-regular grey-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text11') }}
        />
      </div>

      <div className="my-4">
        <h5
          className="primary-color-text mb-3"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_h7') }}
        />
        <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub12') }}
        />
        <div
          className="my-4 body-large-regular grey-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text12') }}
        />
        <div
          className="m-0 body-large-bold grey-color-text text-uppercase"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_sub13') }}
        />
        <div
          className="my-4 body-large-regular grey-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text13') }}
        />
      </div>

      <div className="bg-offWhite-color col-12 mt-5">
        <div className="card border-0 box-radius">
          <div className="card-body py-3">
            <h5
              className="card-title grey-color-text heading-small"
              dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_h8') }}
            />
            <div
              className="my-3 card-text body-medium-regular grey-color-text w-75"
              dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text14') }}
            />
            <div
              className="card-text body-medium-regular pink-color-text w-75"
              dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details_text15') }}
            />
          </div>
        </div>
      </div>

      <div>
        <ul className="body-large-regular grey-color-text list-unstyled">
          <li>
            {activeTariff.id === appTariff.FLAT_M ? (
              <div
                className="my-4"
                dangerouslySetInnerHTML={{ __html: t('sh_Footnote1_Tarife_M') }}
              />
            ) : (
              <div
                className="my-4"
                dangerouslySetInnerHTML={{ __html: t('sh_Footnote2_Tarife_L') }}
              />
            )}
          </li>
          <li>
            <div
              className="my-4"
              dangerouslySetInnerHTML={{ __html: t('sh_Footnote5_1GB_datapass') }}
            />
          </li>
          <li>
            <div
              className="my-4"
              dangerouslySetInnerHTML={{ __html: t('sh_Footnote5_2GB_datapass') }}
            />
          </li>
          <li>
            <div
              className="my-4"
              dangerouslySetInnerHTML={{ __html: t('sh_Footnote6_4GB_datapass') }}
            />
          </li>
        </ul>
      </div>
    </Modal>
  );
}

export default TariffDetailsModal;
