import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useLayout } from '@context/Utils';

import Accordion from '@core/Layout/Accordion';
import { Section } from '@core/Layout';
import {
  appImages,
  appSecBgColorTypes,
  appSecBottomElementTypes,
  appSecTopElementTypes,
  deviceRegex
} from '@utils/globalConstant';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_LINK, UTAG_VIEWS } from '@utils/utag';
import { Image } from '@core/Utils';

export function Sharing() {
  // Constants
  const FAQ_CATEGORY_ID = '81';

  // States
  const [faqList, setFaqList] = useState([]);
  const [isIosDevice, setIsIosDevice] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);

  // Contexts
  const { t, staticContentData, faqStaticData } = useStaticContent();
  const { setLightHeader } = useLayout();
  const { setMetaTags } = useMetaTag();

  //  Hooks
  useEffect(() => {
    setLightHeader(false);
    let deviceInfo = navigator.userAgent;
    setIsIosDevice(deviceRegex.ISIOS.test(deviceInfo));
    setIsAndroidDevice(deviceRegex.ISANDRIOD.test(deviceInfo));
    return () => {
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.SHARING_MOUNTING);
    }
  }, [window.utag]);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_friends_meta_title'),
      description: t('sh_web_page_friends_meta_description'),

      twitterTitle: t('sh_web_page_friends_meta_title'),
      twitterDescription: t('sh_web_page_friends_meta_description'),

      ogTitle: t('sh_web_page_friends_meta_title'),
      ogDescription: t('sh_web_page_friends_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  useEffect(() => {
    if (faqStaticData) {
      setFaqList(
        faqStaticData
          .filter(({ categoryID }) => categoryID === FAQ_CATEGORY_ID)
          .map((category) => ({
            title: category.question,
            component: category.answer
          }))
      );
    }
    return () => {
      setFaqList([]);
    };
  }, [faqStaticData]);

  return (
    <>
      {/* <section className="position-relative py-0 pb-0 pb-lg-0 mb-0 mb-lg-5 mb-md-5 mb-xl-0">
        <div className="container-fluid pb-0 pt-5">
          <div className="row py-5 pt-10 pb-0 flex-column-reverse flex-xl-row flex-md-row">
            <div className="col-xl-5 col-md-6 col-lg-5 col-12 me-5 z-1 text-xl-end me-md-0 ps-md-0 me-xl-5 ps-xl-3 ps-0">
              <Image
                className="mx-100"
                refs="sh_web_friends_content-1"
                alt="top banner"
                ipadStyle={{ height: '412', width: '372' }}
                mobileStyle={{ height: '310', width: '300' }}
              />
            </div>
            <div className="col-xl-5 col-lg-6 col-md-6 col-12 ps-3 ps-md-3 ps-xl-5">
              <h1
                className="primary-color-text my-5 mt-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_h1') }}
              />
              <div
                className="body-large-regular primary-color-text mb-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline') }}
              />
            </div>
          </div>
        </div>
      </section> */}
      <Section
        bgColorCode={appSecBgColorTypes.WHITE}
        // bottomElementType={appSecTopElementTypes.TYPE_1}
      >
        <div className="container-fluid pt-10">
          <div className="row py-5 pt-10 pb-0 flex-column-reverse flex-xl-row flex-md-row">
            {/* <div className="col-xl-5 col-md-6 col-lg-5 col-12 me-5 z-1 text-xl-end me-md-0 ps-md-0 me-xl-5 ps-xl-3 ps-0"> */}
            <div
              className="col-xl-5 col-md-6 col-lg-5 col-12 me-5 z-1 text-xl-end me-md-0 ps-md-0 me-xl-5 ps-xl-3 ps-0"
              style={{ marginBottom: '-50px' }}
            >
              {/* <img
                className="mx-100 mobile-width-100"
                src={appImages.forfriend_topbanner}
                alt="top banner"
              /> */}
              <Image
                className="mx-100"
                refs="sh_web_friends_content-1"
                alt="top banner"
                ipadStyle={{ height: '412', width: '372' }}
                mobileStyle={{ height: '310', width: '300' }}
              />
            </div>
            <div className="col-xl-5 col-lg-6 col-md-6 col-12 ps-3 ps-md-3 ps-xl-5">
              <h1
                className="primary-color-text my-5 mt-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_h1') }}
              />
              <div
                className="body-large-regular primary-color-text mb-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline') }}
              />
            </div>
          </div>
        </div>
      </Section>

      {/* <section className="get-more-gb-row position-relative bg-primary-color friends-purple-shade-1 friends-purple-shade-2 pt-10 py-5 pb-20">
        <div className="container pt-5 pt-sm-0 pb-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="d-flex align-items-center justify-content-center flex-column flex-xl-row flex-sm-row">
                <div className="me-5 text-center mobile-100 w-25">
                  <img className="mx-100" src={appImages.forfriend_bubble} alt="bubble img" />
                  <div
                    className="heading-small white-color-text mt-5 mb-3"
                    dangerouslySetInnerHTML={{
                      __html: t('sh_web_mobile_friends_bullet_points_h1')
                    }}
                  />
                  <div
                    className="body-large-regular white-color-text"
                    dangerouslySetInnerHTML={{
                      __html: t('sh_web_mobile_friends_bullet_points1')
                    }}
                  />
                </div>

                <div className="ms-5 w-25 text-center mobile-100">
                  <img className="mx-100" src={appImages.forfriend_heartic} alt="heart img" />
                  <div
                    className="heading-small white-color-text mt-5 mb-3"
                    dangerouslySetInnerHTML={{
                      __html: t('sh_web_mobile_friends_bullet_points_h2')
                    }}
                  />
                  <div
                    className="body-large-regular white-color-text"
                    dangerouslySetInnerHTML={{
                      __html: t('sh_web_mobile_friends_bullet_points2')
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Section
        bgColorCode={appSecBgColorTypes.PRIMARY}
        // bottomElementType={appSecBottomElementTypes.TYPE_4}
      >
        {/* <div className="container py-5"> */}
        <div className="container pt-5">
          <div className="row py-5">
            <div className="col-md-6">
              <div className="d-flex align-items-center justify-content-center flex-column flex-xl-row flex-sm-row">
                <div className="text-center">
                  <img className="mx-100" src={appImages.forfriend_bubble} alt="bubble img" />
                  <div
                    className="heading-small white-color-text my-3"
                    dangerouslySetInnerHTML={{
                      __html: t('sh_web_mobile_friends_bullet_points_h1')
                    }}
                  />
                  <div
                    className="body-large-regular white-color-text px-5 mb-5"
                    dangerouslySetInnerHTML={{
                      __html: t('sh_web_mobile_friends_bullet_points1')
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center justify-content-center flex-column flex-xl-row flex-sm-row">
                <div className="text-center">
                  <img className="mx-100" src={appImages.forfriend_heartic} alt="heart img" />
                  <div
                    className="heading-small white-color-text my-3"
                    dangerouslySetInnerHTML={{
                      __html: t('sh_web_mobile_friends_bullet_points_h2')
                    }}
                  />
                  <div
                    className="body-large-regular white-color-text px-5 mb-5"
                    dangerouslySetInnerHTML={{
                      __html: t('sh_web_mobile_friends_bullet_points2')
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* <section className="digital-edu-row position-relative bg-offWhite-color gray-shade-1 gray-shade-3 my-5 mt-0 mt-xl-5 mt-lg-5 mt-md-5 pb-xl-5">
        <div className="container-fluid pb-0 pb-sm-5 pt-4">
          <div className="row pb-0 pb-lg-5 mb-0 mb-sm-5 position-relative">
            <div className="col-xl-4 offset-xl-3 col-lg-5 offset-lg-1 col-md-6 pt-5">
              <h4
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline2') }}
              />
              <div
                className="body-large-regular primary-color-text pt-2"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline2_text') }}
              />
            </div>
            <div className="digital-education-img w-auto z-1 mt-5 mt-md-0 d-none d-sm-block">
              <Image
                className="mx-100"
                refs="sh_web_friends_content-2"
                alt="top banner"
                mobileStyle={{ height: '324', width: '258' }}
                ipadStyle={{ height: '452', width: '360' }}
              />
            </div>
            <div className="digital-education-img d-block d-sm-none z-1 mt-5 mt-md-0">
              <Image
                className="mx-100"
                refs="sh_web_friends_content-2"
                alt="top banner"
                mobileStyle={{ height: '324', width: '258' }}
                ipadStyle={{ height: '452', width: '360' }}
              />
            </div>
          </div>
        </div>
      </section> */}
      <Section bgColorCode={appSecBgColorTypes.OFFWHITE}>
        {/* <div className="container-fluid py-4"> */}
        <div className="container-fluid py-5">
          <div
            className="row position-relative"
            style={{ minHeight: '542px', display: 'flex', alignItems: 'center' }}
          >
            {/* <div className="col-xl-4 offset-xl-3 col-lg-5 offset-lg-1 col-md-6 col-12 ps-md-2 ps-sm-2 ps-xs-2 pt-5 pb-4"> */}
            <div className="col-xl-4 offset-xl-3 col-lg-5 offset-lg-1 col-md-6 col-12 ps-md-2 ps-sm-2 ps-xs-2">
              <h4
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline2') }}
              />
              <div
                className="body-large-regular primary-color-text pt-2"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline2_text') }}
              />
            </div>
            <div className="digital-education-img w-auto z-1 mt-5 mt-md-0 d-none d-sm-block">
              <Image
                className="mx-100"
                refs="sh_web_friends_content-2"
                alt="top banner"
                mobileStyle={{ height: '324', width: '258' }}
                ipadStyle={{ height: '452', width: '360' }}
              />
            </div>
            <div
              className="digital-education-img d-block d-sm-none z-1 mt-5 mt-md-0"
              style={{ marginBottom: '-80px' }}
            >
              <Image
                className="mx-100"
                refs="sh_web_friends_content-2"
                alt="top banner"
                mobileStyle={{ height: '324', width: '258' }}
                ipadStyle={{ height: '452', width: '360' }}
              />
            </div>
          </div>
        </div>
      </Section>

      {/* <section className="how-it-done-row position-relative mb-0 mb-lg-5 pt-5">
        <div className="container pb-0 pb-lg-5 mb-0 mb-sm-3 mb-lg-0">
          <div className="row">
            <div className="col-xl-6 offset-xl-2 ps-5">
              <h4
                className="h4-heading primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_headline2') }}
              />
              <div
                className="body-large-regular primary-color-text pt-2"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline3') }}
              />
            </div>
          </div>
          <div className="row mt-4 mb-5 mb-md-5">
            <div className="col-xl-11 offset-xl-1 ps-3 mb-md-5">
              <div className="d-flex justify-content-center flex-column flex-xl-row flex-lg-row flex-md-row">
                <div className="w-50 text-center">
                  <img
                    className="mx-100"
                    src={appImages.forfriend_howitdoneic1}
                    alt="digital education"
                  />
                  <div
                    className="heading-small primary-color-text w-100 d-block mb-2"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_how_to_1') }}
                  />
                  <div
                    className="body-medium-regular primary-color-text w-75 m-auto"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_how_to_2') }}
                  />
                </div>

                <div className="w-50 text-center">
                  <img
                    className="mx-100"
                    src={appImages.forfriend_howitdoneic2}
                    alt="digital education"
                  />
                  <div
                    className="heading-small primary-color-text w-100 d-block mb-2"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_how_to_3') }}
                  />
                  <div
                    className="body-medium-regular primary-color-text w-75 m-auto"
                    dangerouslySetInnerHTML={{
                      __html: t('sh_web_mobile_friends_advantage_headline')
                    }}
                  />
                </div>

                <div className="w-50 text-center">
                  <img
                    className="mx-100"
                    src={appImages.forfriend_howitdoneic3}
                    alt="digital education"
                  />
                  <div
                    className="heading-small primary-color-text w-100 d-block mb-2"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_advantage1') }}
                  />
                  <div
                    className="body-medium-regular primary-color-text w-75 m-auto"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_advantage2') }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Section
        bgColorCode={appSecBgColorTypes.WHITE}
        // topElementType={appSecBottomElementTypes.TYPE_8}
        // bottomElementType={appSecBottomElementTypes.TYPE_1}
      >
        <div className="container-fluid">
          <div className="row pb-0 pb-lg-5 pt-5 pt-md-0">
            <div className="col-xl-4 offset-xl-3 col-lg-5 offset-lg-1 col-md-6 col-12 ps-md-2 ps-sm-2 ps-xs-2 pt-5">
              <h4
                className="h4-heading primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_headline2') }}
              />
              <div
                className="body-large-regular primary-color-text pt-2"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline3') }}
              />
            </div>
          </div>
          <div className="row pb-0 pb-lg-5">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 offset-md-0 col-12 pt-5 mb-5 row">
              <div className="col-lg-4 col-md-4 col-12 text-center">
                <img
                  className="mx-100"
                  src={appImages.forfriend_howitdoneic1}
                  alt="digital education"
                />
                <div
                  className="heading-small primary-color-text w-100 d-block mb-2"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_how_to_1') }}
                />
                <div
                  className="body-medium-regular primary-color-text w-75 m-auto"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_how_to_2') }}
                />
              </div>

              <div className="col-lg-4 col-md-4 col-12 text-center">
                <img
                  className="mx-100"
                  src={appImages.forfriend_howitdoneic2}
                  alt="digital education"
                />
                <div
                  className="heading-small primary-color-text w-100 d-block mb-2"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_how_to_3') }}
                />
                <div
                  className="body-medium-regular primary-color-text w-75 m-auto"
                  dangerouslySetInnerHTML={{
                    __html: t('sh_web_mobile_friends_advantage_headline')
                  }}
                />
              </div>

              <div className="col-lg-4 col-md-4 col-12 text-center">
                <img
                  className="mx-100"
                  src={appImages.forfriend_howitdoneic3}
                  alt="digital education"
                />
                <div
                  className="heading-small primary-color-text w-100 d-block mb-2"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_advantage1') }}
                />
                <div
                  className="body-medium-regular primary-color-text w-75 m-auto"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_advantage2') }}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* <section className="share-mobile-app-row position-relative bg-primary-color friends-purple-shade-2 friends-purple-shade-3 pt-10 py-5 pb-5 pb-xl-0 mb-5">
        <div className="container pb-0 pb-xl-5">
          <div className="row">
            <div className="col-xl-6 offset-xl-2 col-md-8 offset-md-2">
              <h4
                className="white-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline4') }}
              />
              <div
                className="body-large-regular white-color-text pt-2 mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline4_text') }}
              />
              <div className="d-flex flex-wrap flex-column flex-sm-row pb-0 pb-sm-5 pb-lg-0 pt-2">
                {isIosDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    target="_blank"
                    href={t('sh_ap_appstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                      }
                    }}>
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </a>
                )}
                {isAndroidDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    target="_blank"
                    href={t('sh_ap_playstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                      }
                    }}>
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </a>
                )}
                {!isAndroidDevice && !isIosDevice && (
                  <>
                    <span className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0">
                      <img className="mx-100 grow" src={appImages.qrcode} alt="Qrcode" />
                    </span>
                    <a
                      className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      target="_blank"
                      href={t('sh_ap_playstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                        }
                      }}>
                      <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                    </a>
                    <a
                      className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      target="_blank"
                      href={t('sh_ap_appstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                        }
                      }}>
                      <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Section bgColorCode={appSecBgColorTypes.PRIMARY}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 offset-xl-3 col-lg-10 offset-lg-1 col-12 py-5 ps-md-2 ps-sm-2 ps-xs-2">
              <h4
                className="white-color-text mb-4 pe-2"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline4') }}
              />
              <div
                className="body-large-regular white-color-text pt-2 mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_subheadline4_text') }}
              />
              <div className="d-flex flex-wrap flex-column flex-sm-row pb-0 pb-sm-5 pb-lg-0 pt-2">
                {isIosDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    target="_blank"
                    href={t('sh_ap_appstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </a>
                )}
                {isAndroidDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    target="_blank"
                    href={t('sh_ap_playstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </a>
                )}
                {!isAndroidDevice && !isIosDevice && (
                  <>
                    <span className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0">
                      <img className="mx-100 grow" src={appImages.qrcode} alt="Qrcode" />
                    </span>
                    <a
                      className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      target="_blank"
                      href={t('sh_ap_playstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                    </a>
                    <a
                      className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      target="_blank"
                      href={t('sh_ap_appstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 my-5 py-5 mb-5 mb-sm-0">
        <div className="container my-0 py-0 pt-4 pt-sm-0 my-lg-5 py-lg-5">
          <div className="row">
            <div className="col-xl-4 offset-xl-2 col-lg-5 col-md-5">
              <h4
                className="h4-heading primary-color-text mb-0 mb-lg-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_headline3') }}
              />
            </div>
            <div className="col-xl-6 col-lg-7 col-md-7 mt-0 mt-md-5">
              <div className="accordion accordion-flush my-0 my-md-5" id="accordionFlushExample">
                <Accordion items={faqList} />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Section
        bgColorCode={appSecBgColorTypes.OFFWHITE}
        // topElementType={appSecBottomElementTypes.TYPE_5}
      >
        <div className="container-fluid py-5">
          <div className="row">
            <div className="col-xl-4 offset-xl-3 col-lg-5 offset-lg-1 col-md-5">
              <h4
                className="h4-heading primary-color-text mb-0 mb-lg-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_friends_headline3') }}
              />
            </div>
            <div className="col-xl-4 col-lg-5 col-md-7 mt-0 mt-md-5">
              <div className="accordion accordion-flush my-0 my-md-5" id="accordionFlushExample">
                <Accordion items={faqList} />
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section
        bgColorCode={appSecBgColorTypes.WHITE}
        // bottomElementType={appSecBottomElementTypes.TYPE_8}
      >
        <div></div>
      </Section>
    </>
  );
}

export default Sharing;
