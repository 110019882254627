import React, { useEffect } from 'react';
import { Button } from '@core/Inputs';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';

import './style.scss';
import { appActivationFlowType, appButtonType } from '@utils/globalConstant';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';

export function Legitimation() {
  const { t } = useStaticContent();

  const {
    activatedSimData: { signupId = '', postidentUrl = '' },
    activationInfo,
    activationType
  } = useActivation();

  useEffect(() => {
    setTimeout(() => {
      const {
        isPromoApplied,
        activeTariff: { name = '' } = { name: '' },
        isNewNumber
      } = activationInfo;
      if (window && window.utag && signupId) {
        const tariffName = `${name}`.toLocaleLowerCase().replaceAll(' ', '-');
        const tagInfo = {
          page_content_id:
            activationType === appActivationFlowType.NEW_ONLINE
              ? 'www.share-mobile_de.checkout.bestaetigung'
              : 'www.share-mobile_de.aktivieren.bestaetigung',
          customer_type:
            activationType === appActivationFlowType.NEW_ONLINE
              ? 'online-neukunde'
              : 'pos-neukunde',
          mgm_code: isPromoApplied ? 'mgm-aktiv' : 'mgm-nicht-aktiv',
          shop_order_phone_number: isNewNumber === 'true' ? 'neue-rufnummer' : 'rufnummermitnahme',
          shop_order_sim: activationInfo.eSim === 'true' ? 'e-sim' : 'sim-karte',
          shop_order_payment_mode:
            activationInfo &&
            activationInfo.payment &&
            activationInfo.payment.paymentMethod === 'paypal'
              ? 'paypal'
              : 'kreditkarte',
          shop_product_status: 'conf',
          shop_order_id: signupId,
          shop_product_name: tariffName === 'allnet-flat-m' ? 'allnet-flat-m' : 'allnet-flat-l',
          shop_product_price: tariffName === 'allnet-flat-m' ? '10.00' : '15.00',
          shop_order_value: tariffName === 'allnet-flat-m' ? '10.00' : '15.00',
          shop_product_quantity: '1'
        };
        window.utag.view(tagInfo);
      }
    }, 500);
    // if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE && signupId) {
    //   const tariffName = `${name}`.toLocaleLowerCase().replaceAll(' ', '-');
    //   const tagInfo = isPromoApplied
    //     ? isNewNumberSelected
    //       ? tariffName ===
    //         UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING
    //           .ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE.shop_product_name
    //         ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE(
    //             signupId
    //           )
    //         : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_M_NEW_NUMBER_MGM_ACTIVE(
    //             signupId
    //           )
    //       : tariffName ===
    //         UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING
    //           .ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE.shop_product_name
    //       ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_ACTIVE(
    //           signupId
    //         )
    //       : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_ACTIVE(
    //           signupId
    //         )
    //     : isNewNumberSelected
    //     ? tariffName ===
    //       UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING
    //         .ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE.shop_product_name
    //       ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE(
    //           signupId
    //         )
    //       : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_M_NEW_NUMBER_MGM_NON_ACTIVE(
    //           signupId
    //         )
    //     : tariffName ===
    //       UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING
    //         .ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE.shop_product_name
    //     ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_NON_ACTIVE(
    //         signupId
    //       )
    //     : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_NON_ACTIVE(
    //         signupId
    //       );

    //   window.utag.view(tagInfo);
    // }
    // if (
    //   window &&
    //   window.utag &&
    //   activationType === appActivationFlowType.SIM_ACTIVATION &&
    //   signupId
    // ) {
    //   const tariffName = `${name}`.toLocaleLowerCase().replaceAll(' ', '-');
    //   const tagInfo = isPromoApplied
    //     ? isNewNumberSelected
    //       ? tariffName ===
    //         UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING
    //           .ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE.shop_product_name
    //         ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE(
    //             signupId
    //           )
    //         : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_M_NEW_NUMBER_MGM_ACTIVE(
    //             signupId
    //           )
    //       : tariffName ===
    //         UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING
    //           .ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE.shop_product_name
    //       ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_ACTIVE(
    //           signupId
    //         )
    //       : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_ACTIVE(
    //           signupId
    //         )
    //     : isNewNumberSelected
    //     ? tariffName ===
    //       UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING
    //         .ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE.shop_product_name
    //       ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE(
    //           signupId
    //         )
    //       : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_M_NEW_NUMBER_MGM_NON_ACTIVE(
    //           signupId
    //         )
    //     : tariffName ===
    //       UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING
    //         .ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE.shop_product_name
    //     ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_NON_ACTIVE(
    //         signupId
    //       )
    //     : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.ORDER_CONFIRMATION_MOUNTING.ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_NON_ACTIVE(
    //         signupId
    //       );

    //   window.utag.view(tagInfo);
    // }
  }, [window.utag, signupId]);

  return (
    <>
      <section className="bg-primary-color activation-order-section pb-20">
        <div className="container">
          {/* <div className="row position-relative">
            <div className="col-12 orange-gray-bubble" />
          </div> */}

          <div className="col-12 legitimation-head offset-sm-2 col-sm-8 offset-md-2 col-md-8 offset-lg-2 col-lg-7 mt-5 pt-4">
            <div className="legitimation-text-wrapper">
              <h6 className="text-uppercase text-white mb-3">
                {t(
                  activationType === appActivationFlowType.SIM_ACTIVATION
                    ? 'sh_apoc_step10/10'
                    : 'sh_apoc_summary_step9'
                )}
              </h6>
              <h1
                style={{ lineHeight: '113%' }}
                className="pink-color-text pink-text-wrapper mb-3 pb-3"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_legitimation_h1') }}
              />
              <p
                className="mb-3 pb-3 text-white body-large-regular"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_legitimation_h2') }}
              />
            </div>

            <Button
              buttonType={appButtonType.PRIMARY}
              className="bg-offWhite-color w-50"
              onClick={() => {
                if (window && window.utag) {
                  activationType === appActivationFlowType.NEW_ONLINE
                    ? window.utag.link(
                        UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.ORDER_CONFIRMATION
                          .START_LEGITIMATION_ON_CLICK
                      )
                    : window.utag.link(
                        UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.ORDER_CONFIRMATION
                          .START_LEGITIMATION_ON_CLICK
                      );
                }

                window.location.href = postidentUrl;
              }}
            >
              {t('sh_apoc_legitimation_button')}
            </Button>

            <div className="my-3 py-3">
              <span
                className="text-white body-large-regular"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_legitimation_subheader') }}
              />
              <span className="pink-color-text body-large-bold"> {signupId}</span>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-offWhite-color legitimation-section gray-shade-1 gray-shade-3 position-relative py-5 mb-5">
        <div className="container">
          <div className="row">
            <div class="col-12 position-relative">
              <div class="orange-yellow-bubble"></div>
            </div>
            <div className="col-12 offset-sm-3 sec-heading col-sm-6 offset-md-2 col-md-8 offset-lg-3 col-lg-6 pb-5 pt-3 mt-3 mb-5">
              <h4
                className="primary-color-text mb-2"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_legitimation_text') }}
              />
              <p
                className="body-large-regular mb-0 primary-color-text mb-3"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_legitimation_text1') }}
              />
              <p
                className="body-large-regular mb-0 mb-sm-5 primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_legitimation_text2') }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Legitimation;
