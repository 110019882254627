import React, { useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Col, Row } from '@core/Layout';
import { Button } from '@core/Inputs';
import { Modal } from 'react-bootstrap';
import { useAccount } from '@context/MobileOne';
import { simLockStatusTitle } from '@dom-digital-online-media/dom-app-config-sdk';
import { appRoute } from '@utils/globalConstant';
import { useNavigate } from 'react-router-dom';

export function SimCardLock() {
  // States
  const [modalPopup, setModalPopup] = useState(false);

  // context
  const { t } = useStaticContent();
  const { lockStatus, handleLockStatus, onSimLockSubmit, isLoading } = useAccount();
  const navigate = useNavigate();

  function buttonText() {
    if (handleLockStatus(lockStatus) === simLockStatusTitle.ACTIVE) {
      return t('sh_tab_two_sim_card_esim_dropdown5_lock_button');
    }
    if (handleLockStatus(lockStatus) === simLockStatusTitle.SIM_LOCKED) {
      return t('sh_tab_two_sim_card_esim_dropdown5_unlock_button');
    }
    return <></>;
  }

  async function changeSimCardSetting() {
    await onSimLockSubmit();
    setModalPopup(false);
    navigate(appRoute.TARIFF_OPTION);
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <p>{t('sh_tab_two_sim_card_esim_dropdown5_text1')}</p>
          <Button
            variant="link"
            className="p-0 mb-3"
            onClick={() => {
              setModalPopup(true);
            }}
          >
            {buttonText()}
          </Button>
        </Col>
      </Row>
      <Modal show={modalPopup} onHide={() => setModalPopup(false)} size="lg">
        <Modal.Header closeButton>
          <div>
            <Modal.Title>{t('sh_tab_two_sim_card_esim_dropdown5_lock_info')}</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <p>{t('sh_tab_two_sim_card_esim_dropdown5_lock_text')}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalPopup(false)}>
            {t('sh_tab_two_sim_card_esim_dropdown5_lock_cancel')}
          </Button>
          <Button variant="secondary" onClick={() => changeSimCardSetting()} isLoading={isLoading}>
            {t('sh_tab_two_sim_card_esim_dropdown5_lock_confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SimCardLock;
