// Main App
// Initialize App with default landing page.

// import { ThemeProvider } from '@mui/material';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Buffer } from 'buffer';

import { AppRoutes } from '@config/AppRoutes';
import { ContextManager } from '@config/ContextManager';
import { WebThemeProvider } from './theme';

// @ts-ignore
window.Buffer = Buffer;
// import { appTheme } from '@theme';

function App() {
  return (
    <BrowserRouter>
      {/* <ThemeProvider theme={appTheme}> */}
      <WebThemeProvider>
        <ContextManager>
          <AppRoutes />
        </ContextManager>
      </WebThemeProvider>
      {/* </ThemeProvider> */}
    </BrowserRouter>
  );
}

export default App;
