import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import PropTypes from 'prop-types';
import { useLayout } from '@context/Utils';
import { findBreakPoint } from '@utils/globalConstant';
import './index.scss';

export function Image({
  refs,
  resRefs,
  alt,
  className,
  isBannerImage,
  defaultStyle,
  mobileStyle,
  ipadStyle,
  sectionStyle,
  sectionWithImage,
  imgName,
  children,
  ...props
}) {
  // Constant

  // Context
  const { mediaImage } = useStaticContent();
  const { screenSize } = useLayout();

  // State
  const [imgSrc, setImgSrc] = useState('');
  const [imgData, setImgData] = useState({});
  const [imgWidth, setImgWidth] = useState('');
  const [imgHeight, setImgHeight] = useState('');

  // Hooks
  useEffect(() => {
    let screenResolution = findBreakPoint(screenSize);
    let getImage = '';
    if (mediaImage) {
      if (
        (screenResolution === 'lg' || screenResolution === 'md') &&
        (ipadStyle.height || ipadStyle.width)
      ) {
        getImage = mediaImage.find((item) => item.image_ref === refs);
        if (!isBannerImage) {
          setImgWidth(`${ipadStyle.width}px`);
          setImgHeight(`${ipadStyle.height}px`);
        }
      } else if (
        screenResolution === 'xxl' ||
        screenResolution === 'xl' ||
        screenResolution === 'lg' ||
        screenResolution === 'md'
      ) {
        getImage = mediaImage.find((item) => item.image_ref === refs);
        if (!isBannerImage) {
          defaultStyle?.width
            ? setImgWidth(`${defaultStyle.width}px`)
            : setImgWidth(`${getImage.responsive_image.basic_width}px`);
          defaultStyle?.height
            ? setImgHeight(`${defaultStyle.height}px`)
            : setImgHeight(`${getImage.responsive_image.basic_height}px`);
        }
      } else {
        if (resRefs) {
          getImage = mediaImage.find((item) => item.image_ref === resRefs);
        } else {
          getImage = mediaImage.find((item) => item.image_ref === refs);
        }
        if (!isBannerImage) {
          mobileStyle?.width ? setImgWidth(`${mobileStyle.width}px`) : setImgWidth('');
          mobileStyle?.height ? setImgHeight(`${mobileStyle.height}px`) : setImgHeight('');
        }
      }
      setImgSrc(getImage.media_url_display);
      setImgData(getImage);
    }

    return () => {
      setImgSrc('');
      setImgWidth('');
      setImgWidth('');
      setImgHeight('');
    };
  }, [mediaImage, screenSize]);

  return sectionWithImage ? (
    <section
      {...props}
      className={className}
      style={{
        // ...sectionStyle,
        background: `url(${imgSrc}) no-repeat`
      }}
    >
      {children}
    </section>
  ) : imgSrc ? (
    <>
      <img
        className={className}
        src={imgSrc}
        alt={alt}
        style={{ height: imgHeight, width: imgWidth }}
        loading="lazy"
      />
      {imgName && (
        <p className="label-small grey-color-text text-end img-name" style={{ width: imgWidth }}>
          {imgName}
        </p>
      )}
    </>
  ) : (
    <div
      className={className}
      data-image-refs={refs}
      data-image-src={imgSrc}
      data-image-status="not-found"
    />
  );
}

Image.propTypes = {
  refs: PropTypes.string,
  resRefs: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  sectionWithImage: PropTypes.bool,
  isBannerImage: PropTypes.bool,
  defaultStyle: PropTypes.shape({ height: PropTypes.string, width: PropTypes.string }),
  mobileStyle: PropTypes.shape({ height: PropTypes.string, width: PropTypes.string }),
  ipadStyle: PropTypes.shape({ height: PropTypes.string, width: PropTypes.string }),
  sectionStyle: PropTypes.shape({}),
  imgName: PropTypes.string,
  children: PropTypes.node
};

Image.defaultProps = {
  refs: '',
  resRefs: '',
  alt: '',
  className: '',
  sectionWithImage: false,
  isBannerImage: false,
  defaultStyle: { height: '', width: '' },
  mobileStyle: { height: '', width: '' },
  ipadStyle: { height: '', width: '' },
  sectionStyle: {},
  imgName: '',
  children: ''
};

export default Image;
