/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Alert } from '@core/Utils';

import { Header } from './Header';
import { Footer } from './Footer';
import { useLocation } from 'react-router-dom';
import { appQueryParams, useQuery } from '@utils/globalConstant';
import { useLayout } from '@context/Utils';

import './index.scss';

export function withLayout(WrappedComponent) {
  // Wrap all of the components into one layout.

  function Layout(props) {
    // Context
    const { search } = useLocation();
    const query = useQuery();
    const { hoverMenu, showMobileMenu, appWebView, setAppWebView } = useLayout();

    useEffect(() => {
      if (!!query.get(appQueryParams.APP_WEB_VIEW)) {
        setAppWebView(true);
      } else {
        setAppWebView(false);
      }
    }, [search]);

    return (
      <>
        {!appWebView && <Header />}
        {/* className=' text-white ' */}
        <main id="main" className={hoverMenu > -1 || showMobileMenu ? 'overlay' : ''}>
          <Alert />
          <WrappedComponent {...props} />
        </main>

        {!appWebView && <Footer />}
      </>
    );
  }

  return Layout;
}

export default withLayout;
