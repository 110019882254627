import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useNumberPorting } from '@context/MobileOne/Activation/NumberPorting';
import { useActivation } from '@context/MobileOne';
import { Formik } from 'formik';
import { Button, Radio } from '@core/Inputs';
import {
  appActivationFlowType,
  appActivationStep2Modals,
  appActivationStep2Screens,
  appButtonType
} from '@utils/globalConstant';
import { Modal } from '@core/Utils';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';

export function SimTypeSelection({ modal, setModal }) {
  // Context
  const { t } = useStaticContent();
  const { formValue, setFormValue, onSubmit } = useNumberPorting();
  const { activationType, activationInfo, setNumberPortingScreen } = useActivation();

  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.SELECT_SIM_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.SELECT_SIM_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  return (
    <>
      <Formik
        initialValues={formValue}
        enableReinitialize
        onSubmit={(values) => {
          if (values.eSim === 'false') {
            if (window && window.utag) {
              window.utag.link(
                UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.SELECT_SIM.CONTINUE_CLASSIC_SIM_ON_CLICK
              );
            }
            setModal(appActivationStep2Modals.NONE);
            onSubmit(values);
          } else {
            if (window && window.utag) {
              window.utag.link(
                UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.SELECT_SIM.CONTINUE_ESIM_ON_CLICK
              );
            }
            setFormValue(values);
            setModal(appActivationStep2Modals.ESIM_BRAND_SELECTION);
          }
        }}
      >
        {({ values: { eSim }, handleChange, handleBlur, handleSubmit, errors }) => (
          <section className="bg-default banner-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-3 pb-5 mt-5">
                  <div className="back-btn">
                    <a
                      className="text-white body-medium-bold text-decoration-none link-light"
                      onClick={() => {
                        setNumberPortingScreen(appActivationStep2Screens.PHONE_MNP_NEW_SELECTION);
                      }}
                    >
                      <i className="fa fa-arrow-left me-3"></i>
                      {t('sh_back_button')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 mt-sm-5 mb-5 mb-sm-0">
                  <h6 className="text-uppercase text-white">{t('sh_apoc_SIM_step2')}</h6>
                  <h1 className="pink-color-text mt-2 mt-sm-2">{t('sh_apoc_SIM_h1')}</h1>
                  <div className="mt-4 mt-sm-5">
                    <p
                      className="body-large-regular text-white"
                      dangerouslySetInnerHTML={{ __html: t('sh_apoc_SIM_subheader1') }}
                    />

                    <div className="form-check ps-0">
                      <Radio
                        type="primary"
                        label={t('sh_apoc_SIM_subheader2')}
                        description={t('sh_apoc_SIM_text1')}
                        name="eSim"
                        onChange={(e) => {
                          if (window && window.utag) {
                            window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.SELECT_SIM.CLASSIC_SIM_ON_CLICK
                            );
                          }
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value="false"
                        isDefaultChecked={eSim === 'false'}
                        id="inline-tdm-1"
                        className="m-0 custom-form-check"
                      />
                    </div>
                    <div className="form-check ps-0">
                      <Radio
                        type="primary"
                        label={t('sh_apoc_SIM_subheader3')}
                        description={t('sh_apoc_SIM_text2')}
                        name="eSim"
                        onChange={(e) => {
                          if (window && window.utag) {
                            window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.SELECT_SIM.ESIM_ON_CLICK
                            );
                          }
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value="true"
                        isDefaultChecked={eSim === 'true'}
                        id="inline-tdm-2"
                        className="m-0 custom-form-check"
                        promotionText={t('sh_apoc_SIM_subheader3_badge2')}
                      />
                      <Button
                        buttonType={appButtonType.LINK}
                        className="text-white ps-3 ms-2 p-0"
                        type="button"
                        onClick={() => {
                          if (window && window.utag) {
                            window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.SELECT_SIM.ESIM_INFO_ON_CLICK
                            );
                          }
                          setModal(appActivationStep2Modals.ESIM_INFO);
                        }}
                      >
                        {t('sh_apoc_SIM_eSIM-info')}
                      </Button>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-7">
                        <div className="mb-5 pt-4">
                          <Button
                            buttonType={appButtonType.PRIMARY}
                            type="button"
                            className="w-100"
                            onClick={handleSubmit}
                            disabled={eSim === ''}
                          >
                            {t('sh_apoc_SIM_next-button')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Formik>

      {modal === appActivationStep2Modals.ESIM_INFO && (
        <Modal
          showCloseButton
          onCloseClick={() => {
            setModal(appActivationStep2Modals.NONE);
          }}
          stepHeader={t('sh_apoc_eSIM-info_header')}
          header={t('sh_apoc_eSIM-info_h1')}
          description={t('sh_apoc_eSIM-info_text1')}
        />
      )}
    </>
  );
}
export default SimTypeSelection;
