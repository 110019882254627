import { useTariff } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { Button } from '@core/Inputs';
import { Card, CardBody, CardFooter, CardHeader } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appRoute } from '@utils/globalConstant';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export function CustomerUsage() {
  const { t } = useStaticContent();
  const { dataUsage } = useDashBoard();
  const { activeTariff } = useTariff();
  const [activeOption, setActiveOption] = useState(null);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  useEffect(() => {
    if (activeTariff.length) {
      setActiveOption(activeTariff[0]?.bookedOptions?.find((opt) => opt.status.name === 'aktiv'));
    }
  }, [activeTariff]);
  useEffect(() => {
    if (activeOption) {
      const hoursDifference = moment(
        `${activeOption.endDate} ${activeOption.endTime}`,
        'DD.MM.YYYY hh:mm:ss'
      ).diff(moment(new Date(), 'DD.MM.YYYY hh:mm:ss'), 'h');
      setDays(Math.floor(hoursDifference / 24));
      const hour = Math.round((hoursDifference / 24).toFixed(2).toString().split('.')[1]);
      if (hour > 0) {
        setHours(Math.round((24 / 100) * hour));
      }
    }
  }, [activeOption]);
  return (
    <Card sx={{ width: '25rem' }}>
      <CardHeader>
        <div className="d-flex align-items-center justify-content-between">
          <h3>{t('sh_db_usage_h2')}</h3>
          <Link to={appRoute.USAGE_DETAILS}>{t('sh_db_details')}</Link>
        </div>
      </CardHeader>
      {dataUsage.name ? (
        <CardBody>
          <div className="d-flex">
            <h3>
              {dataUsage?.remainingValue} {dataUsage?.remainingUnit}
            </h3>
            <p className="m-2">
              {t('sh_db_usage_text1')} {dataUsage?.initialValue} {dataUsage?.initialUnit}
            </p>
            <p className="m-2">{t('sh_db_usage_text2')}</p>
          </div>
          {dataUsage.remainingValue === dataUsage.initialValue ? (
            <ProgressBar now={100} label="0%" />
          ) : (
            <ProgressBar
              now={(dataUsage.remainingValue / dataUsage.initialValue) * 100}
              label={`${(dataUsage.remainingValue / dataUsage.initialValue) * 100}%`}
            />
          )}

          <p>
            {t('sh_db_usage_text3')} {days} Tage {hours} Std.
          </p>
        </CardBody>
      ) : (
        <p>No Usage Data</p>
      )}
      <CardFooter>
        <Button variant="primary">{t('sh_db_usage_button')}</Button>
      </CardFooter>
    </Card>
  );
}

export default CustomerUsage;
