import React, { useEffect } from 'react';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';

export function FootNotesModal({ setModal }) {
  // Context
  const { t } = useStaticContent();
  const { activeTariff } = useActivationTariff();

  return (
    <Modal
      showCloseButton
      closeButtonText="sh_apoc_tarif_overview_tarif-details_cancel"
      onCloseClick={() => {
        setModal('');
      }}
      header={activeTariff.name}
    >
      {activeTariff.id === 6501 && (
        <div dangerouslySetInnerHTML={{ __html: t('sh_Footnote1_Tarife_M') }} />
      )}

      {activeTariff.id === 6500 && (
        <div dangerouslySetInnerHTML={{ __html: t('sh_Footnote2_Tarife_L') }} />
      )}
    </Modal>
  );
}

export default FootNotesModal;
