/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { appImages, appRoute } from '@utils/globalConstant';
import { Link } from '@core/Inputs';

import './index.scss';
import { UTAG_LINK } from '@utils/utag';

export function Footer() {
  const { t } = useStaticContent();

  return (
    <>
      {/* <footer className="pb-3">
        <div className="container">
          <div className="row pb-lg-5">
            <div className="col-md-12">
              <div className="footer-logo mb-5">
                <img src="image/footer-logo.svg" />
              </div>
            </div>

            <div className="col-md-4">
              <div className="footer-text-div">
                <p className="mb-0 text-purple">Sag einfach mal “Hi” über unser </p>
                <a href="" className="text-purple">
                  Kontaktformular
                </a>
              </div>
            </div>

            <div className="col-md-5">
              <div className="footer-text-div">
                <p className="mb-0 text-purple">Oldschool per Brief? </p>
                <p className="mb-0 text-purple">Geht auch:</p>
                <p className="mb-0 text-purple fw-bold">share GmbH </p>
                <p className="mb-0 text-purple fw-bold">
                  Erkelenzdamm 59-61 <br />
                  10999 Berlin, Deutschland{' '}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex social-media">
                <use xlinkHref="#twitter" className="me-2" />
                <img src="image/twitter.svg" />
                <use xlinkHref="#facebook" className="me-2" />
                <img src="image/fb.svg" />
                <use xlinkHref="#instagram" className="me-2" />
                <img src="image/insta.svg" />
                <a href="" className="me-2">
                  <img src="image/youtube.svg" />{' '}
                </a>
                <a href="" className="me-2">
                  <img src="image/musicly.svg" />{' '}
                </a>
              </div>
            </div>
          </div>

          <div className="row pt-lg-5">
            <div className="col-md-9">
              <div className="bottom-footer mb-2">
                <Link to={appRoute.DATA_PROT} className="fw-bold  text-purple">
                  Datenschutz
                </Link>
                <Link to={appRoute.AGB} className="fw-bold  text-purple">
                  AGB
                </Link>
                <Link to={appRoute.IMPRINT} className="fw-bold  text-purple">
                  Impressum
                </Link>
                <Link to={appRoute.CUS_INFO} className="fw-bold  text-purple">
                  Verbraucherinformationen
                </Link>
                <Link to={appRoute.REVOCATION} className="fw-bold  text-purple">
                  Widerruf
                </Link>
                <Link to={appRoute.CANCEL_CONTRACT} className="fw-bold  text-purple">
                  Vertrag jetzt kündigen
                </Link>
              </div>
              <div className="copyright-sec">
                <p className="text-purple ">© 2023, share GmbH</p>
              </div>
            </div>

            <div className="col-md-3">
              <img src="image/certificate.png" />
            </div>
          </div>
        </div>
      </footer> */}
      <footer className="container">
        <div className="row">
          <div className="col-sm-12">
            <ul className="d-lg-flex d-grid gap-2 justify-content-between list-inline body-medium-bold mt-3 mt-sm-5 my-lg-5">
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.IMPRINT_ON_CLICK);
                  }
                }}
              >
                <Link className="primary-color-text text-decoration-none" to={appRoute.IMPRINT}>
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h1') }} />
                </Link>
              </li>
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.AGB_ON_CLICK);
                  }
                }}
              >
                <Link className="primary-color-text text-decoration-none" to={appRoute.AGB}>
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h2') }} />
                </Link>
              </li>
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.CONSUM_INFO_ON_CLICK);
                  }
                }}
              >
                <Link className="primary-color-text text-decoration-none" to={appRoute.CUS_INFO}>
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h3') }} />
                </Link>
              </li>
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.PRIVACY_ON_CLICK);
                  }
                }}
              >
                <Link className="primary-color-text text-decoration-none" to={appRoute.DATA_PROT}>
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h4') }} />
                </Link>
              </li>
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.WIDERRUF_ON_CLICK);
                  }
                }}
              >
                <Link className="primary-color-text text-decoration-none" to={appRoute.REVOCATION}>
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h5') }} />
                </Link>
              </li>
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.CANCEL_CONTRACT_ON_CLICK);
                  }
                }}
              >
                <Link
                  className="primary-color-text text-decoration-none"
                  to={appRoute.CANCEL_CONTRACT}
                >
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h6') }} />
                </Link>
              </li>
            </ul>

            <ul className="list-inline d-grid gap-2 d-lg-flex justify-content-between mt-4 my-lg-4 align-items-center">
              <li
                className="body-large-regular primary-color-text mt-3 mt-lg-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_footer_copyright_text') }}
              />
              <li className="d-none d-lg-flex flex-row align-items-center justify-content-center">
                <span className="">
                  <img src={appImages.congstarLogoPoweredBy} alt="Congstar" />
                </span>
              </li>
              <li className="d-flex d-lg-none flex-row align-items-center justify-content-start">
                <span className="">
                  <img
                    className="responsive-logo"
                    src={appImages.congstarResponsiveLogoPoweredBy}
                    alt="Congstar"
                  />
                </span>
              </li>
            </ul>

            {/* <ul className="list-unstyled d-flex">
              <li className="ms-3">
                <Link className="link-dark" to="/">
                  <svg className="bi" width="24" height="24">
                    <use xlinkHref="#twitter" />
                  </svg>
                </Link>
              </li>
              <li className="ms-3">
                <Link className="link-dark" to="/">
                  <svg className="bi" width="24" height="24">
                    <use xlinkHref="#instagram" />
                  </svg>
                </Link>
              </li>
              <li className="ms-3">
                <Link className="link-dark" to="/">
                  <svg className="bi" width="24" height="24">
                    <use xlinkHref="#facebook" />
                  </svg>
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </footer>

      {/* <Container>
        <footer className="py-5">
          <div className="row">
            <div className="col-2 p-0 text-center">
              <Link to={appRoute.IMPRINT} className="nav-link p-0 text-muted">
                Impressum
              </Link>
            </div>
            <div className="col-2 p-0 text-center">
              <Link to={appRoute.AGB} className="nav-link p-0 text-muted">
                AGB
              </Link>
            </div>
            <div className="col-2 p-0 text-center">
              <Link to={appRoute.CUS_INFO} className="nav-link p-0 text-muted">
                Verbraucher-informationen
              </Link>
            </div>

            <div className="col-2 p-0 text-center">
              <Link to={appRoute.DATA_PROT} className="nav-link p-0 text-muted">
                Datenschutz
              </Link>
            </div>
            <div className="col-2 p-0 text-center">
              <Link to={appRoute.REVOCATION} className="nav-link p-0 text-muted">
                Widerruf
              </Link>
            </div>
            <div className="col-2 p-0 text-center">
              <Link to={appRoute.CANCEL_CONTRACT} className="nav-link p-0 text-muted">
                Vertrag jetzt kündigen
              </Link>
            </div>
          </div>

          <div className="d-flex justify-content-between py-4 my-4 border-top">
            <p>© 2023 Company, Inc. All rights reserved.</p>
            <ul className="list-unstyled d-flex">
              <li className="ms-3">
                <Link className="link-dark" to="/">
                  <svg className="bi" width="24" height="24">
                    <use xlinkHref="#twitter" />
                  </svg>
                </Link>
              </li>
              <li className="ms-3">
                <Link className="link-dark" to="/">
                  <svg className="bi" width="24" height="24">
                    <use xlinkHref="#instagram" />
                  </svg>
                </Link>
              </li>
              <li className="ms-3">
                <Link className="link-dark" to="/">
                  <svg className="bi" width="24" height="24">
                    <use xlinkHref="#facebook" />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      </Container> */}
    </>
  );
}

export default Footer;
