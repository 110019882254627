/* eslint-disable */
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Form, FormCheck, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Button, Switch } from '@core/Inputs';
import { Col, Row } from '@core/Layout';
import { usePayment } from '@context/MobileOne/Activation/Payments';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne';
import { appRoute } from '@utils/globalConstant';

export function PaymentRegister({ setIsPaymentRegister }) {
  // Context
  const { t } = useStaticContent();
  const {
    isLoading,
    formValue,
    validationSchema,
    paymentMethods,
    validateForm,
    getPaymentMethods
  } = usePayment();
  const { addPaymentMethod } = useCustomer();

  useEffect(() => {
    getPaymentMethods();
  }, []);
  return (
    <>
      <Row>
        <Col md={3}>
          <Button variant="link" onClick={() => setIsPaymentRegister(false)}>
            <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
          </Button>
        </Col>
        <Col md={6}>
          <Formik
            initialValues={{ ...formValue }}
            validationSchema={validationSchema}
            validate={validateForm}
            onSubmit={addPaymentMethod}
          >
            {({
              values: { paymentMethod, enoughCredit },
              errors,
              handleBlur,
              handleChange,
              handleSubmit
            }) => (
              <Form onSubmit={handleSubmit}>
                <h3>{t('sh_apoc_payment_h1')}</h3>
                <p>{t('sh_apoc_payment_subheader1')}</p>
                <Col>
                  {paymentMethods.map((pm) => (
                    <>
                      <FormCheck
                        label={
                          pm === 'paypal'
                            ? t('sh_apoc_payment_option1')
                            : t('sh_apoc_payment_option2')
                        }
                        name="paymentMethod"
                        type="radio"
                        className="m-0"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={pm}
                        checked={pm === paymentMethod}
                        key={pm}
                      />
                      <p style={{ marginLeft: '1.25rem' }}>
                        {pm === 'paypal'
                          ? t('sh_apoc_payment_option1_text')
                          : t('sh_apoc_payment_option2_text')}
                      </p>
                    </>
                  ))}
                  <FormControl.Feedback type="invalid" className="d-block">
                    {errors.paymentMethod}
                  </FormControl.Feedback>
                </Col>
                <hr />
                <Col xs={12} className="d-flex justify-content-between align-items-center">
                  <p>{t('sh_apoc_payment_text1')}</p>
                  <Switch
                    name="enoughCredit"
                    id="enoughCredit"
                    isDefaultChecked={enoughCredit}
                    onChange={() => {
                      handleChange({
                        target: {
                          id: 'enoughCredit',
                          name: 'enoughCredit',
                          value: !enoughCredit
                        }
                      });
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <p>{t('sh_apoc_payment_text2')}</p>
                  <br />
                  <Button
                    variant="primary"
                    type="submit"
                    isLoading={isLoading}
                    disabled={enoughCredit === false}
                  >
                    {t('sh_apoc_payment_next_button')}
                  </Button>
                </Col>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}

export default PaymentRegister;
