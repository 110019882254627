import React from 'react';
// import { Container } from '@core/Layout';
// import { ActivationWelcome } from '@page/Activation';
import { StickyLayer } from '@core/Layout';
import './style.scss';

// import logowhite from '@theme/assets/images/logo-w.svg';
// import logodark from '@theme/assets/images/logo-d.svg';
// import humbermenuwhite from '@theme/assets/images/humbergmenu-white.svg';
// import humbermenudark from '@theme/assets/images/humbergmenu-dark.svg';
// import closemenudark from '@theme/assets/images/close-ic.svg';
// import sharemobileapp from '@theme/assets/images/share-mob-app.svg';
// import sharemobileapp_m from '@theme/assets/images/share-mob-app-m.svg';
// import androidapp from '@theme/assets/images/android-app.svg';
// import iosapp from '@theme/assets/images/ios-app.svg';
// import qrcode from '@theme/assets/images/qr-code.svg';

// Images of aufladen
// import sharemobileappaufladen from '@theme/assets/images/share-mob-app-aufladen.svg';
// import sharemobileappaufladen_m from '@theme/assets/images/share-mob-app-aufladen-m.svg';
// import rewelogo from '@theme/assets/images/rewe-logo.svg';
// import rt_arw from '@theme/assets/images/rt-arw.svg';
// Images of aufladen

// Images of Sim card activation page
// import simcard_activations from '@theme/assets/images/sim-card-activation.svg';
// import simcard_activations_m from '@theme/assets/images/sim-card-activation-m.svg';
// Images of Sim card activation page

// Images of for friends page
// import forfriend_topbanner from '@theme/assets/images/for-friend-top-bnr.jpg';
// import forfriend_bubble from '@theme/assets/images/friends-bubble.svg';
// import forfriend_heartic from '@theme/assets/images/friends-heart-icon.svg';
// import forfriend_education from '@theme/assets/images/digital-education-img.jpg';
// import forfriend_howitdoneic1 from '@theme/assets/images/how-it-done-icon1.svg';
// import forfriend_howitdoneic2 from '@theme/assets/images/how-it-done-icon2.svg';
// import forfriend_howitdoneic3 from '@theme/assets/images/how-it-done-icon3.svg';
// Images of for friends page

// Images of Projet page
// import project_btmbanner from '@theme/assets/images/project-banner.jpg';
// import project_details from '@theme/assets/images/project-detail.jpg';
// import project_detailsmobile from '@theme/assets/images/project-detail-mobile.jpg';
// import project_phone_d from '@theme/assets/images/project-phone-d.svg';
// import project_btmbannermobile from '@theme/assets/images/project-banner-mobile.jpg';

// Images of Projet page

export function Test2() {
  return (
    <>
      <header className="position-fixed z-3 w-100">
        {/* Dark header start */}
        {/* <nav
          className="navbar navbar-expand-lg navbar-dark bg-primary-color mt-3 py-3"
          aria-label="Ninth navbar example"
        >
          <div className="container">
            <a className="navbar-brand" href="#">
              {' '}
              <img className="logo mx-100" src={appImages.logowhite} alt="Share logo" />
            </a>
            <button
              className="navbar-toggler d-flex d-lg-none align-items-center shadow-none border-0 p-0 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarsExample07XL"
              aria-controls="navbarsExample07XL"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <h6 className="m-0 mx-3 text-white">Menü</h6>
              <span>
                <img className="logo mx-100" src={appImages.humbermenuwhite} alt="humberg menu " />
                // when in mobile menu open at that time show close icon and hide humber menu //
                <img className="logo mx-100" width={20} src={appImages.closemenudark} alt="Close menu " />

              </span>
            </button>

            <div className="navbar-collapse collapse" id="navbarsExample07XL">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  <a
                    className="text-white body-medium-regular text-decoration-none d-none d-lg-block px-4 active"
                    aria-current="page"
                    href="#"
                    id="dropdown07XL"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Tarif
                  </a>
                  <div className="submenu dropdown-menu px-2 py-0 px-lg-0 m-0">
                    <ul className="inner-submenu mt-5" aria-labelledby="dropdown07XL">
                      <li>
                        <h6
                          className="dropdown-item pink-color-text text-decoration-none px-0"
                          href="#"
                        >
                          Tarif
                        </h6>
                      </li>
                      <li>
                        <a
                          className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                          href="#"
                        >
                          Tut Gutes Tarif
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                          href="#"
                        >
                          SIM-Karte aktivieren
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                          href="#"
                        >
                          Guthaben aufladen
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                          href="#"
                        >
                          share mobile App
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="text-white body-medium-regular text-decoration-none d-none d-lg-block px-4"
                    href="#"
                    id="dropdown07XL"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Über uns
                  </a>
                  <div className="submenu dropdown-menu px-2 py-0 px-lg-0 m-0">
                    <ul className="inner-submenu mt-4 mt-lg-5" aria-labelledby="dropdown07XL">
                      <li>
                        <h6
                          className="dropdown-item pink-color-text text-decoration-none px-0"
                          href="#"
                        >
                          Über uns
                        </h6>
                      </li>
                      <li>
                        <a
                          className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                          href="#"
                        >
                          Über share mobile
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                          href="#"
                        >
                          Projekte
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="text-white body-medium-regular text-decoration-none d-none d-lg-block px-4"
                    href="#"
                    id="dropdown07XL"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Freunde werben
                  </a>
                  <div className="submenu dropdown-menu px-2 py-0 px-lg-0 m-0">
                    <ul className="inner-submenu mt-4 mt-lg-5" aria-labelledby="dropdown07XL">
                      <li>
                        <h6
                          className="dropdown-item pink-color-text text-decoration-none px-0"
                          href="#"
                        >
                          Freunde werben
                        </h6>
                      </li>
                      <li>
                        <a
                          className="dropdown-item text-white body-medium-regular text-decoration-none px-0"
                          href="#"
                        >
                          Freunde werben
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="text-white body-medium-regular text-decoration-none d-none d-lg-block px-4 pe-4 pe-xl-0"
                    href="#"
                    id="dropdown07XL"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Hilfe & Service
                  </a>
                  <div className="submenu dropdown-menu px-2 py-0 px-lg-0 m-0">
                    <ul className="inner-submenu mt-4 mt-lg-5" aria-labelledby="dropdown07XL">
                      <li>
                        <h6
                          className="dropdown-item pink-color-text text-decoration-none px-0"
                          href="#"
                        >
                          Hilfe & Service
                        </h6>
                      </li>
                      <li>
                        <a className="dropdown-item text-white text-decoration-none px-0" href="#">
                          FAQ
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item text-white text-decoration-none px-0" href="#">
                          Roaming im Ausland
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item text-white text-decoration-none px-0" href="#">
                          Kontakt
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav> */}
        {/* Dark header End */}

        {/* Light header start */}

        {/* <nav className="navbar navbar-expand-lg  mt-3 py-3" aria-label="Ninth navbar example">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img className="logo mx-100" src={appImages.logodark} alt="Share logo" />
            </a>
            <button
              className="navbar-toggler d-flex d-lg-none shadow-none align-items-center border-0 p-0 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarsExample07XL"
              aria-controls="navbarsExample07XL"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <h6 className="m-0 mx-3">Menü</h6>
              <span>
                <img className="logo mx-100" src={appImages.humbermenudark} alt="humberg menu " />
              </span>
            </button>

            <div className="navbar-collapse collapse" id="navbarsExample07XL">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="primary-color-text text-decoration-none body-medium-regular px-4 active"
                    aria-current="page"
                    href="#"
                  >
                    Tarif
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="primary-color-text text-decoration-none body-medium-regular px-4"
                    href="#"
                  >
                    Über uns
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="primary-color-text text-decoration-none body-medium-regular px-4"
                    href="#"
                  >
                    Freunde werben
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="primary-color-text text-decoration-none body-medium-regular  d-none d-lg-block px-4 pe-4 pe-xl-0"
                    href="#"
                    id="dropdown07XL"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Hilfe & Service
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdown07XL">
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav> */}
        {/* Light header End */}
      </header>
      {/* APP download page start */}
      {/* <section className="position-relative gray-shade-2 py-5">
          <div className="container pt-0 pt-lg-5 py-5 mt-0 mt-lg-4">
            <div className="row py-5">
              <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-8 col-12">
                <h1 className="primary-color-text my-5">share mobile App</h1>
                <p className="body-large-regular primary-color-text">
                  Deinen <strong className="body-large-bold ">Tut Gutes Tarif</strong> kannst du
                  direkt in der kostenlosen share mobile App bestellen und deine im Supermarkt
                  gekaufte SIM-Karte aktivieren.
                </p>
                <div className="d-block d-md-none py-5"></div>
                <div className="position-absolute d-block d-lg-none z-1 bottom-0 end-0">
                  <img className="mx-100" src={appImages.sharemobileapp_m} alt="Share Mobile app" />
                </div>
              </div>
            </div>
          </div>
          <div className="position-absolute d-none d-lg-block z-1 bottom-0 end-0">
            <img className="mx-100" src={appImages.sharemobileapp} alt="Share Mobile app" />
          </div>
        </section>
        <section className="position-relative bg-offWhite-color gray-shade-1 py-5 mb-5">
          <div className="container pb-5">
            <div className="row">
              <div className="col-xl-6 offset-xl-1 col-md-12 col-12">
                <h4 className="primary-color-text mb-5">Verbrauch und Guthaben: Per App im Griff.</h4>
                <p className="body-large-regular primary-color-text">
                  In der share mobile App kannst dein Guthaben verwalten, deinen Verbrauch einsehen,
                  Datenpässe zubuchen und vieles mehr.
                </p>
                <p className="body-large-regular primary-color-text">
                  Natürlich kannst du dort auch deinen geleisteten Social Impact verfolgen und mehr
                  über das unterstützte Hilfsprojekt erfahren.
                </p>
                <h5 className="primary-color-text mb-4 mb-lg-5">
                  share mobile App kostenlos herunterladen:
                </h5>
                <div className="d-flex flex-wrap flex-column flex-sm-row pb-0 pb-sm-4 pb-lg-5">
                  <span className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0">
                    <img className="mx-100" src={appImages.qrcode} alt="QR code" />
                  </span>
                  <button className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0">
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </button>
                  <button className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0">
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      {/* APP download page End */}

      {/* Help & Services page start */}
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 py-5">
        <div className="container pt-0 pt-lg-5 py-5 mt-0 mt-lg-4">
          <div className="row py-5 pb-0 pb-sm-5">
            <div className="col-xl-7 offset-xl-1 col-lg-8 col-md-8 col-12">
              <h1 className="primary-color-text my-5 mb-4">Kontakt</h1>
              <p className="body-large-regular primary-color-text my-4">
                Du möchtest dein Anliegen persönlich klären? Kein Problem, unser Service steht dir
                zu folgenden Zeiten zur Verfügung:
              </p>
              <strong className="body-large-bold primary-color-text">0221 – 79700 440 </strong>
              <p className="body-large-regular primary-color-text m-0">
                Montag - Samstag: 8 Uhr – 20 Uhr
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* Help & Services page End */}

      {/* aufladen page start */}
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 py-5">
        <div className="container pt-0 pt-lg-5 py-5 mt-0 mt-lg-4">
          <div className="row py-5">
            <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-8 col-12">
              <h1 className="primary-color-text my-5">Guthaben aufladen</h1>
              <p className="body-large-regular primary-color-text">
                Lade dein Guthaben per App auf – wo immer du gerade bist.
              </p>
              <div className="d-block d-md-none py-5"></div>
              <div className="position-absolute d-block d-lg-none z-1 bottom-0 end-0">
                <img className="mx-100" src={appImages.sharemobileappaufladen_m} alt="aufladen app" />
                <img className="mx-100" src={appImages.sharemobileapp_m} alt="Share Mobile app" />
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute d-none d-lg-block z-1 bottom-0 end-0">
          <img className="mx-100" src={appImages.sharemobileappaufladen} alt="aufladen app" />
          <img className="mx-100" src={appImages.sharemobileapp} alt="Share Mobile app" />
        </div>
      </section>
      <section className="position-relative py-5 mb-0 mb-lg-5">
        <div className="container pb-0 pb-lg-5 mb-0 mb-sm-3 mb-lg-0">
          <div className="row">
            <div className="col-xl-6 offset-xl-1 col-md-10 col-lg-8 col-12">
              <h4 className="primary-color-text mb-4">Alles im Griff mit der share mobile App</h4>
              <p className="body-large-regular primary-color-text mb-4">
                Du möchtest dein Guthaben einmalig aufladen? Geh hierfür in der Verbrauchsübersicht deiner share mobile App auf „Aufladen“, um einmalig per PayPal, Kreditkarte oder Auflade-Code aufzuladen.
              </p>
              <h5 className="primary-color-text mb-4">
                Du findest eine automatische Aufladung bequemer?
              </h5>
              <p className="body-large-regular primary-color-text mb-4">
                Richte dir in der App die automatische Aufladung ein und du wirst nie mehr ohne Guthaben unterwegs sein. Du bestimmst, ob dein Guthaben immer zu einem festen Tag im Monat oder bei Unterschreiten eines von dir gewählten Betrags automatisch aufgeladen wird. Diese Einstellung kannst du jederzeit ändern.
              </p>
              <h5 className="primary-color-text mb-4">
                share mobile App kostenlos herunterladen:
              </h5>
              <div className="d-flex flex-wrap flex-column flex-sm-row pb-0 pb-sm-5 pb-lg-0">
                <span className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0">
                  <img className="mx-100" src={appImages.qrcode} alt="QR code" />
                </span>
                <button className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex">
                  <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                </button>
                <button className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex">
              <div className="d-flex flex-wrap flex-column flex-sm-row pb-0 pb-sm-4 pb-lg-5">
                <span className="pe-0 pe-lg-3 pb-3 pb-sm-0 border-0">
                  <img className="mx-100" src={appImages.qrcode} alt="QR code" />
                </span>
                <button className="pe-0 pe-lg-3 pb-3 pb-sm-0 border-0">
                  <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                </button>
                <button className="pe-0 pe-lg-3 pb-3 pb-sm-0 border-0">
                  <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative bg-offWhite-color gray-shade-1 gray-shade-3 pt-5 mb-5 mb-lg-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 offset-xl-1 col-lg-6 col-md-8 col-12">
              <h4 className="primary-color-text">Du kannst dein Guthaben natürlich auch im Markt kaufen.</h4>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-8 col-12 my-0 my-lg-5 pb-5">
              <p className="pt-0 pt-lg-2 d-none d-lg-block">&nbsp;</p>
              <p className="body-large-regular primary-color-text m-0 mt-5 pt-0 pt-lg-5">Shop in deiner Nähe finden:</p>
              <button type="button" className="btn btn-primary heading-small my-4 px-4">
                <img className="mx-100" src={appImages.rewelogo} alt="REWE Logo" />
                <strong className="px-4">REWE Markt</strong>
                <img className="mx-100" src={appImages.rt_arw} alt="right arrow" />
              </button>
              <p className="body-large-regular primary-color-text pb-5 m-0">Du hast noch weitere Fragen zur Aufladung? Hier geht's zu den häufig gestellten Fragen.</p>
            </div>
          </div>
        </div>
      </section> */}
      {/* aufladen page End */}

      {/* sim card activation page start */}
      {/* <section className="position-relative py-5 pb-0 pb-lg-5 mb-0 mb-lg-5">
        <div className="container pt-0 pt-lg-5 py-5 pb-0 pb-md-5 mt-0 mt-lg-4">
          <div className="row py-5 pb-0">
            <div className="col-xl-6 offset-xl-1 col-md-12 col-lg-7 col-12">
              <h1 className="primary-color-text my-5 mb-4">SIM-Karte aktivieren</h1>
              <p className="body-large-regular primary-color-text mb-4">
                Du hast deine SIM-Karte im Supermarkt gekauft? Dann musst du sie nur noch
                aktivieren, um deinen Tut Gutes Tarif nutzen zu können.
              </p>
              <p className="body-large-regular primary-color-text mb-4">
                Lade dir hierfür die kostenlose share mobile App herunter und gehe nach dem Öffnen
                der App auf „Zur Aktivierung“. Halte deine SIM-Kartennummer und die PUK1 bereit, um
                den Aktivierungsprozess zu beginnen.
              </p>
              <span className="d-block d-lg-none">
                <img
                  className="logo mx-100"
                  src={appImages.simcard_activations_m}
                  alt="sim card activation "
                />
              </span>
              <h5 className="primary-color-text mb-4">share mobile App kostenlos herunterladen:</h5>
              <div className="d-flex flex-wrap flex-column flex-sm-row">
                <span className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0">
                  <img className="mx-100" src={appImages.qrcode} alt="QR code" />
                </span>
                <button className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex">
                  <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                </button>
                <button className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex">
                  <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                </button>
              </div>
              <p className="body-large-regular primary-color-text my-4">
                In der App kannst du außerdem dein Guthaben aufladen, Datenpässe zubuchen, einen
                Tarifwechsel durchführen und vieles mehr.
              </p>
            </div>
            <div className="col-xl-5 col-lg-5 col-12 d-none d-lg-flex align-self-end justify-content-center">
              <img className="logo mx-100" src={appImages.simcard_activations} alt="sim card activation " />
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative bg-offWhite-color gray-shade-1 gray-shade-3 my-5 py-5">
        <div className="container pb-0 pb-sm-5">
          <div className="row pb-0 pb-lg-4">
            <div className="col-xl-7 offset-xl-1 col-md-10 col-lg-8 col-12">
              <h4 className="primary-color-text mb-4">Keine App oder weitere Fragen?</h4>
              <p className="body-large-regular primary-color-text mb-4">
                Du möchtest deine SIM-Karte ohne App aktivieren? Hier geht's zur Aktivierung auf
                unserer Webseite.
              </p>
              <p className="body-large-regular primary-color-text mb-4">
                Du hast weitere Fragen zur SIM-Kartenaktivierung? Hier geht's zu den häufig
                gestellten Fragen.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* sim card activation page End */}

      {/* for friends page start */}
      {/* <section className="position-relative py-0 pb-0 pb-lg-0 mb-0 mb-lg-5 mb-md-5 mb-xl-0">
        <div className="container-fluid pb-0 pt-5">
          <div className="row py-5 pt-10 pb-0 flex-column-reverse flex-xl-row flex-md-row">
            <div className="col-xl-5 col-md-6 col-lg-5 col-12 me-5 z-1 text-xl-end me-md-0 ps-md-0 me-xl-5 ps-xl-3 ps-0">
              <img className="mx-100 mobile-width-100" src={appImages.forfriend_topbanner} alt="top banner" />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-12 pe-5">
              <h1 className="primary-color-text my-5 mt-0">Teilen verdoppelt die Freude</h1>
              <p className="body-large-regular primary-color-text mb-4">
                Freund:innen zu share einladen und noch mehr Gutes tun: Mit jeder erfolgreichen
                Einladung über die share mobile App erhältst du mehr GB und jemand anders mehr
                Bildung.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="get-more-gb-row position-relative bg-primary-color friends-purple-shade-1 friends-purple-shade-2 pt-10 py-5 pb-20">
        <div className="container pb-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="d-flex align-items-center justify-content-center flex-column flex-xl-row flex-md-row">
                <div className="me-5 text-center mobile-100 w-25">
                  <img className="mx-100" src={appImages.forfriend_bubble} alt="bubble img" />
                  <p className="heading-small white-color-text mt-5 mb-3">Mehr GB erhalten</p>
                  <span className="body-large-regular white-color-text">
                    Die geworbene Person und du erhalten jeweils dauerhaft 1 GB Datenvolumen mehr.
                  </span>
                </div>
                <div className="ms-5 w-25 text-center mobile-100">
                  <img className="mx-100" src={appImages.forfriend_heartic} alt="heart img" />
                  <p className="heading-small white-color-text mt-5 mb-3">Bildung spenden</p>
                  <span className="body-large-regular white-color-text">
                    Mit jeder erfolgreichen Einladung sammeln wir mehr Spenden für Bildungsprojekte.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="digital-edu-row position-relative bg-offWhite-color gray-shade-1 gray-shade-3 my-5 mt-0 mt-xl-5 mt-lg-5 mt-md-5 pb-xl-5">
        <div className="container-fluid pb-0 pb-sm-5 pt-4">
          <div className="row pb-0 pb-lg-4 position-relative">
            <div className="col-xl-5 offset-xl-3 col-lg-5 offset-lg-1 pt-10">
              <h4 className="primary-color-text mb-4">
                1 GB mehr für euch – digitale Bildung für andere
              </h4>
              <p className="body-large-regular primary-color-text pt-2">
                Eins ist klar: Je mehr Menschen gemeinsam etwas Großartiges angehen, desto mehr
                können sie bewegen. Darum lade gerne so viele Freund:innen wie möglich ein und
                ermöglicht gemeinsam noch mehr digitale Bildung.
              </p>
            </div>
            <div className="digital-education-img w-auto z-1">
              <img className="mx-100 w-100" src={appImages.forfriend_education} alt="digital education" />
            </div>
          </div>
        </div>
      </section>
      <section className="how-it-done-row position-relative mb-0 mb-lg-5 pt-10">
        <div className="container pb-0 mt-5 pb-lg-5 mb-0 mb-sm-3 mb-lg-0">
          <div className="row">
            <div className="col-xl-6 offset-xl-2 ps-5">
              <h4 className="h4-heading primary-color-text mb-4">So geht’s</h4>
              <p className="body-large-regular primary-color-text pt-2">
                Lade deine Freund:innen einfach über die share mobile App ein – und in drei
                einfachen Schritten gibt’s mehr Datenvolumen für euch und mehr Bildung für andere!
              </p>
            </div>
          </div>
          <div className="row mt-4 mb-5 mb-md-5">
            <div className="col-xl-11 offset-xl-1 ps-3 mb-md-5">
              <div className="d-flex justify-content-center flex-column flex-xl-row flex-lg-row flex-md-row">
                <div className="w-50 text-center">
                  <img className="mx-100" src={appImages.forfriend_howitdoneic1} alt="digital education" />
                  <span className="heading-small primary-color-text w-100 d-block mb-2">
                    App öffnen
                  </span>
                  <p className="body-medium-regular primary-color-text w-75 m-auto">
                    Öffne die share mobile App auf deinem Smartphone.
                  </p>
                </div>
                <div className="w-50 text-center">
                  <img className="mx-100" src={appImages.forfriend_howitdoneic2} alt="digital education" />
                  <span className="heading-small primary-color-text w-100 d-block mb-2">
                    Code kopieren
                  </span>
                  <p className="body-medium-regular primary-color-text w-75 m-auto">
                    Deinen persönlichen Teilen-Code findest du in der Navigation unter „Mehr“.
                  </p>
                </div>
                <div className="w-50 text-center">
                  <img className="mx-100" src={appImages.forfriend_howitdoneic3} alt="digital education" />
                  <span className="heading-small primary-color-text w-100 d-block mb-2">
                    Teilen
                  </span>
                  <p className="body-medium-regular primary-color-text w-75 m-auto">
                    Teile deinen Code z. B. via WhatsApp. Und mit jeder erfolgreichen Einladung
                    erhältst du (und dein:e Freund:in) 1 GB mehr!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="share-mobile-app-row position-relative bg-primary-color friends-purple-shade-2 friends-purple-shade-3 pt-10 py-5 pb-5 pb-md-0">
        <div className="container pb-5">
          <div className="row pb-20">
            <div className="col-xl-6 offset-xl-2 ps-5 ps-5">
              <h4 className="white-color-text mb-4">Du hast die share mobile App noch nicht?</h4>
              <p className="body-large-regular white-color-text pt-2 mb-4">
                Dann lade sie am besten direkt herunter. Und schon kannst du deine Freund:innen
                einladen.
              </p>
              <div className="d-flex flex-wrap flex-column flex-sm-row pb-0 pb-sm-5 pb-lg-0 pt-2">
                <span className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0">
                  <img className="mx-100" src={appImages.qrcode} alt="Qrcode" />
                </span>
                <a className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex">
                  <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                </a>
                <a className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex">
                  <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="question-row position-relative bg-offWhite-color gray-shade-1 my-5 pt-10 mt-sm-0">
        <div className="container pb-0 pb-lg-5 mb-0 mb-sm-3 mb-lg-0">
          <div className="row">
            <div className="col-xl-4 offset-xl-2 col-lg-5 ps-5">
              <h4 className="h4-heading primary-color-text mb-4">
                Deine Fragen – unsere Antworten
              </h4>
            </div>
            <div className="col-xl-6 col-lg-7 ps-5 mt-5">
              <div className="accordion accordion-flush mt-5" id="accordionFlushExample">
                <div className="accordion-item border-primary bg-transparent">
                  <div id="flush-headingOne">
                    <button
                      className="d-flex justify-content-between accordion-button collapsed body-large-bold shadow-none bg-transparent primary-color-text px-0 py-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Wie funktioniert die Freundschaftswerbung mit share mobile for friends?
                      <i className="fa fa-angle-down purple-light-color-text" aria-hidden="true"></i>
                      <i className="fa fa-angle-up purple-light-color-text" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body body-large-regular primary-color-text px-0 pt-0">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                      eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                      voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
                      kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                      ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                      tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                      vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-primary bg-transparent">
                  <div id="flush-headingTwo">
                    <button
                      className="d-flex justify-content-between accordion-button collapsed body-large-bold shadow-none bg-transparent primary-color-text px-0 py-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Wie viele Freund:innen kann ich zu share mobile holen?
                      <i className="fa fa-angle-down purple-light-color-text" aria-hidden="true"></i>
                      <i className="fa fa-angle-up purple-light-color-text" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body body-large-regular primary-color-text px-0 pt-0">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                      eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                      voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
                      kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                      ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                      tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                      vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-primary bg-transparent">
                  <div id="flush-headingThree">
                    <button
                      className="d-flex justify-content-between accordion-button collapsed body-large-bold shadow-none bg-transparent primary-color-text px-0 py-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Ich wurde mit share mobile for friends geworben und mein:e Freund:in
                      (Werber:in) kündigt den share mobile Tarif. Verliere ich nun mein extra
                      Datenvolumen?
                      <i className="fa fa-angle-down purple-light-color-text" aria-hidden="true"></i>
                      <i className="fa fa-angle-up purple-light-color-text" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body body-large-regular primary-color-text px-0 pt-0">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                      eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                      voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
                      kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                      ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                      tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                      vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* for friends page end */}

      {/* project page start */}
      {/* <section className="project-top-banner bg-primary-color project-purple-shade-1 position-relative mb-lg-5 mb-md-5 mb-xl-0 pt-10">
        <div className="container pb-0 pb-lg-5 mb-0 mb-sm-3 mb-lg-0">
          <div className="row">
            <div className="col-xl-7 offset-xl-2 col-lg-8 ps-5 pt-10 pb-md-5">
              <h1 className="pink-color-text">Tu Gutes für digitale Bildung</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="project-btm-banner project-image-shade-1 position-relative mt-4 mt-xl-0">
        <div>
          <img
            className="mx-100 w-100 d-none d-md-block d-xl-block"
            src={appImages.project_btmbanner}
            alt="Banner"
          />
          <img
            className="mx-100 d-block d-md-none d-xl-none"
            src={appImages.project_btmbannermobile}
            alt="Banner Mobile"
          />
        </div>
      </section>

      <section className="project-digital-pltform mb-0 mb-xl-5 mt-5 mt-xl-0">
        <div className="container pb-0 pb-lg-5 mb-0 mb-sm-3 mb-lg-0 pt-5 pt-xl-0">
          <div className="row mb-0 mb-xl-5">
            <div className="col-xl-10 offset-xl-2 col-lg-12 ps-5 pt-10 mb-5">
              <h2 className="primary-color-text mb-3">EIDU: Digitale Plattform als Chance</h2>
              <h5 className="primary-color-text mb-3">Digitalisierung als Bildungsgrundlage</h5>
              <p className="body-large-regular">
                Wir möchten positive Veränderungen vorantreiben. Im Bereich digitale Bildung tun wir
                dies zusammen mit EIDU. EIDU hat eine digitale Lernplattform entwickelt, die bereits
                an Tausenden Schulen im Einsatz ist. Gemeinsam wollen wir noch viel mehr Lehrende
                und Lernende erreichen.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="project-share-details position-relative bg-offWhite-color gray-shade-1 gray-top-shade pb-5 pb-xl-0">
        <div className="container pb-0 pb-lg-5 mb-0 mb-sm-3 mb-lg-0">
          <div className="row">
            <div className="col-xl-4 col-md-4 mt-5 image-block">
              <img
                className="mx-100 w-100 d-none d-xl-block"
                src={appImages.project_details}
                alt="Project Details"
              />
              <img
                className="mx-100 d-block d-xl-none"
                src={appImages.project_detailsmobile}
                alt="Project Details Mobile"
              />
              <span className="d-block grey-color-text label-small px-5 py-3">Foto: EIDU</span>
            </div>
            <div className="col-xl-6 col-md-8 offset-xl-1">
              <div className="d-flex flex-column h-100 justify-content-end">
                <h2 className="primary-color-text mb-3 pe-5 pe-xl-0">Innovative Bildung fördern</h2>
                <h5 className="primary-color-text mb-3">Mit Know-how und Technik</h5>
                <p className="body-large-regular">
                  Schon mal von der weltweiten Bildungskrise gehört? 6 von 10 Kindern erreichen die
                  Mindestkompetenzen im Lesen und Rechnen nicht und die Studierendenquote in Afrika
                  liegt südlich der Sahara bei 9 % (Quelle: BMZ). So setzt sich EIDU für hochwertige
                  Bildung an Schulen ein: Das Team stellt Technik und Lehrpläne bereit, schult
                  Lehrende und arbeitet mit Schulen und Regierungen zusammen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="project-help-row mb-5 mt-5 pt-1">
        <div className="container pb-0 pb-lg-5 mb-0 mb-sm-3 mb-lg-0">
          <div className="row mt-5 pb-5">
            <div className="col-xl-10 offset-xl-2 col-lg-12 ps-5 pt-10 pb-5">
              <h2 className="primary-color-text mb-3">Wo wir gemeinsam helfen</h2>
              <p className="body-large-regular">
                EIDU hat den Plan gefasst, die Schulbildung in Kenia bis 2025 komplett zu
                digitalisieren und über 2 Millionen Kinder zu erreichen. Jede:r Schüler:in erhält
                einen eigenen Account und kann so täglich mit spielerischen Aufgaben üben. Die
                Lehrer:innen erhalten kontinuierliches Coaching zu ihrer beruflichen Entwicklung.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="project-share-formet-row position-relative bg-offWhite-color gray-shade-1 project-share-formet-gray-shade-2 mb-5 pb-5 pt-5 pt-xl-0">
        <div className="container pb-5 pt-5 pt-xl-0">
          <div className="row pb-5">
            <div className="col-xl-7 offset-xl-2 col-lg-7 col-md-8 ps-5 pt-10 pe-5">
              <h2 className="primary-color-text mb-3">Tarifwelt im share-Format</h2>
              <h5 className="primary-color-text mb-3">Lernergebnisse, die sich sehen lassen</h5>
              <p className="primary-color-text body-large-regular pe-3 mb-4">
                Mit jedem Monat share mobile ermöglichst du einen Monat digitale Bildung. Wir
                möchten dir deinen persönlichen Beitrag so transparent wie möglich zeigen. Updates
                zum Projektfortschritt und deinem positiven Beitrag dazu findest du im Impact Tab in
                der share mobile App.
              </p>
              <button className="btn btn-secondary heading-small w-50 d-flex align-items-center justify-content-center mb-3">
                FAQs
              </button>
              <button className="btn btn-outline-secondary heading-small w-50 d-flex align-items-center justify-content-center">
                Mehr über share
              </button>
            </div>
          </div>
        </div>
        <div className="position-absolute d-block z-1 bottom-0 end-0 share-project-img">
          <img className="mx-100" src={appImages.project_phone_d} alt="Share Project Mobile app" />
        </div>
      </section> */}
      {/* project page end */}

      {/* Data Protection page start */}
      {/* <section className="position-relative py-5 pb-0 pb-lg-5 mb-0 mb-lg-5">
        <div className="container pt-0 pt-lg-5 py-5 pb-0 pb-md-5 mt-0 mt-lg-4">
          <div className="row py-5 pb-0">
            <div className="col-xl-6 offset-xl-1 col-md-12 col-lg-7 col-12">
              <h1 className="primary-color-text my-5 mb-4">Hinweise zum Datenschutzc</h1>
              <p className="body-large-regular primary-color-text mb-4">
                Auf dieser Seite findest du alle Datenschutzhinweise zum Ansehen und Herunterladen.
              </p>
              <p className="body-large-regular primary-color-text mb-4">
                <a href="" className="primary-color-text">
                  Hier
                </a>{' '}
                kannst du deine Cookie-Einstellungen jederzeit ändern.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative bg-offWhite-color gray-shade-1 gray-shade-3 my-5 py-5">
        <div className="container pb-0 pb-sm-2">
          <div className="row pb-0 pb-lg-4">
            <div className="col-xl-7 offset-xl-2 col-md-10 col-lg-8 col-12">
              <ol className="mb-4 pb-2 px-4">
                <li className="body-large-regular primary-color-text">
                  <a className="primary-color-text">Allgemeine Datenschutzhinweise</a>
                </li>
                <li className="body-large-regular primary-color-text">
                  <a className="primary-color-text">
                    Datenschutzhinweis für den Abschluss eines Mobilfunkvertrags
                  </a>
                </li>
                <li className="body-large-regular primary-color-text">
                  <a className="primary-color-text">
                    Datenschutzhinweis für die share mobile Webseite und App
                  </a>
                </li>
              </ol>
              <h5 className="primary-color-text mb-4">Compliance - Hinweise</h5>
              <p className="body-large-regular primary-color-text">
                Bei Anhaltspunkten für Verstöße gegen Gesetze oder Regelungen von share mobile bzw.
                der Telekom hast du die Möglichkeit, uns diese über unser Hinweisgeberportal „Tell
                me!“ zu melden. Das Hinweisgeberportal der Telekom erreichst du unter{' '}
                <a href="" className="primary-color-text">
                  http://www.telekom.com/tell-me.
                </a>
              </p>
            </div>
          </div>
        </div>
      </section> */}

      {/* On open please add "show d-block" classes to modal*/}
      {/* <div
          className="modal fade share-modal show d-block"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content bg-offWhite-color border-0">
              <div className="modal-header justify-content-start p-0 border-0">
                <button
                  type="button"
                  className="btn-close mx-0 me-1 shadow-none"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <h5 className="modal-title body-medium-bold grey-color-text">Abbrechen</h5>
              </div>

              <div className="modal-body mt-3 p-0">
                <h3 className="primary-color-text">Cookie-Einwilligung und Datenverarbeitung</h3>
                <div>
                  <p className="mt-4 body-medium-regular primary-color-text">
                    Wir verwenden verschiedene Cookies und ähnliche Technologien, um dir das bestmögliche Online-Erlebnis zu bieten und Inhalte auf deine Interessen anzupassen. Mittels der eingesetzten Technologien können Informationen auf deinem Endgerät gespeichert, angereichert und gelesen werden.
                  </p>
                  <p className="mt-4 body-medium-regular primary-color-text">
                  Mit einem Klick auf „Alle akzeptieren“ stimmst du dem Zugriff auf dein Endgerät zu sowie der Verarbeitung deiner Daten, der Webseiten- sowie partner- und geräteübergreifenden Erstellung und Verarbeitung von individuellen Nutzungsprofilen sowie der Weitergabe deiner Daten an Drittanbieter, die zum Teil deine Daten in Ländern außerhalb der europäischen Union verarbeiten (DSGVO Art. 49). Die Telekom kann unter Umständen nicht in allen Fällen sicherstellen, dass das europäische Datenschutzniveau eingehalten wird.
                  </p>
                </div>
                <div className="mt-4 d-flex align-items-center justify-content-center flex-column">
                  <button className="btn btn-secondary heading-small w-75 d-flex align-items-center justify-content-center mb-4">
                    Alle akzeptieren
                  </button>
                  <button className="btn btn-outline-secondary heading-small w-75 d-flex align-items-center justify-content-center mb-4">
                    Einstellungen ändern
                  </button>
                  <button className="btn btn-secondary heading-small w-75 d-flex align-items-center justify-content-center mb-3 mb-4">
                    Nur erforderliche
                  </button>
                  <a href='' className="body-medium-bold primary-color-text d-block m-1 text-decoration-none">Datenschutzhinweis</a>
                  <a href='' className="body-medium-bold primary-color-text d-block m-0 text-decoration-none">Impressum</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* Data Protection page end */}

      {/* withdrawal page start */}
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 py-5">
          <div className="container pt-0 pt-lg-5 py-5 mt-0 mt-lg-4">
            <div className="row py-5 pb-0 pb-sm-5">
              <div className="col-xl-7 offset-xl-1 col-lg-8 col-md-8 col-12">
                <h1 className="primary-color-text my-5 mb-4">Widerrufsrecht</h1>
                <strong className="body-large-bold primary-color-text">Widerruf bei Dienstleistungen</strong>
                <p className="body-large-regular primary-color-text my-4 mt-0">
                  Wenn du share mobile als Verbraucher einen Auftrag für eine Dienstleistung (Nutzung eines Verbindungstarifs) unter ausschließlicher Verwendung von Fernkommunikationsmitteln (die share mobile App) oder außerhalb von Geschäftsräumen erteilst, steht dir ein Widerrufsrecht gemäß nachfolgender Widerrufsbelehrung zu.
                </p>
                <p className='body-large-regular primary-color-text mb-0'>Hier findest du Einzelheiten zu</p>
                <strong className="body-large-bold primary-color-text">Deinem Widerrufsrecht: </strong>
                <a href='' className="body-large-regular primary-color-text d-block m-0">
                  Widerrufsbelehrung und Widerrufsformular
                </a>
              </div>
            </div>
          </div>
        </section> */}
      {/* withdrawal page end */}

      {/* AGB page start */}
      {/* <section className="position-relative py-5 pb-0 pb-lg-5 mb-0 mb-lg-5">
          <div className="container pt-0 pt-lg-5 py-5 pb-0 pb-md-5 mt-0 mt-lg-4">
            <div className="row py-5 pb-0">
              <div className="col-xl-6 offset-xl-1 col-md-12 col-lg-7 col-12">
                <h1 className="primary-color-text my-5 mb-4">AGB</h1>
                <p className="body-large-regular primary-color-text mb-4">
                  Unsere Allgemeinen Geschäftsbedingungen auf einen Blick.
                </p>
                <p className="body-large-regular primary-color-text mb-4">
                  Hier kannst du dir schnell und bequem unsere AGB herunterladen. Zum Öffnen der PDF-Dateien benötigst du den kostenlosen <a href='' className='primary-color-text'>Acrobat Reader</a>.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative bg-offWhite-color gray-shade-1 gray-shade-3 my-5 py-5">
          <div className="container pb-0 pb-sm-2">
            <div className="row pb-0 pb-lg-4">
              <div className="col-xl-7 offset-xl-2 col-md-10 col-lg-8 col-12">
                <ol className='mb-4 pb-2 px-4'>
                  <li className='body-large-regular primary-color-text'><a href='' className='primary-color-text'>Allgemeine Geschäftsbedingungen</a></li>
                  <li className='body-large-regular primary-color-text'><a href='' className='primary-color-text'>Leistungsbeschreibung</a></li>
                  <li className='body-large-regular primary-color-text'><a href='' className='primary-color-text'>Messverfahren</a></li>
                  <li className='body-large-regular primary-color-text'><a href='' className='primary-color-text'>Sicherheitsverfahren</a></li>
                  <li className='body-large-regular primary-color-text'><a href='' className='primary-color-text'>Verkehrsmanagement</a></li>
                  <li className='body-large-regular primary-color-text'><a href='' className='primary-color-text'>Hinweise zu Batterien und Altgeräten</a></li>
                  <li className='body-large-regular primary-color-text'><a href='' className='primary-color-text'>Teilnahmebedingungen share mobile for friends</a></li>
                  <li className='body-large-regular primary-color-text'><a href='' className='primary-color-text'>Preisliste</a></li>
                  <li className='body-large-regular primary-color-text'><a href='' className='primary-color-text'>Produktinformationsblatt Allnet M</a></li>
                  <li className='body-large-regular primary-color-text'><a href='' className='primary-color-text'>Produktinformationsblatt Allnet L</a></li>
                </ol>
                <p className="body-large-regular primary-color-text">
                  Informationen zum Widerrufsrecht findest du hier.
                </p>
              </div>
            </div>
          </div>
        </section>       */}
      {/* AGB page end */}

      {/* Mandatory information page start */}
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 py-5">
          <div className="container pt-0 pt-lg-5 py-5 mt-0 mt-lg-4">
            <div className="row py-5 pb-0 pb-sm-5">
              <div className="col-xl-7 offset-xl-1 col-lg-8 col-md-8 col-12 pe-xl-5">
                <h1 className="primary-color-text my-5 mb-4">Pflichtangaben</h1>
                <strong className="body-large-bold primary-color-text">share mobile – eine Marke der Telekom Deutschland GmbH</strong>
                <p className="body-large-regular primary-color-text my-4 mt-0">Pflichtangaben gemäß §35a GmbHG</p>
                <strong className="body-large-bold primary-color-text">Telekom Deutschland GmbH</strong>
                <p className="body-large-regular primary-color-text mt-0 mb-0"><strong className="body-large-bold primary-color-text">Aufsichtsrat: </strong> Dr. Christian P. Illek (Vorsitzender)</p>
                <p className="body-large-regular primary-color-text my-4 mt-0 pe-xl-5"><strong className="body-large-bold primary-color-text">Geschäftsführung: </strong> Srinivasan Gopalan (Sprecher), Dr. Ferri Abolhassan, André Almeida, Dr. Kerstin Baumgart, Dr. Abdurazak Mudesir, Hagen Rickmann, Simone Thiäner (Arbeitsdirektorin), Klaus Werner</p>
                <p className="body-large-regular primary-color-text mt-0 mb-0"><strong className="body-large-bold primary-color-text">Handelsregister: </strong> Amtsgericht Bonn HRB 5919</p>
                <p className="body-large-regular primary-color-text mt-0 mb-0"><strong className="body-large-bold primary-color-text">Sitz der Gesellschaft: </strong> Bonn</p>
                <p className="body-large-regular primary-color-text mt-0 mb-0"><strong className="body-large-bold primary-color-text">WEEE-Reg.-Nr.: </strong> DE60800328</p>
              </div>
            </div>
          </div>
        </section> */}
      {/* Mandatory information page end */}

      {/* Imprint page start */}
      {/* <section className="position-relative py-5 pb-0 pb-lg-5 mb-0 mb-lg-5">
          <div className="container pt-0 pt-lg-5 py-5 pb-0 pb-md-5 mt-0 mt-lg-4">
            <div className="row py-5 pb-0">
              <div className="col-xl-6 offset-xl-1 col-md-12 col-lg-7 col-12 pe-xl-5">
                <h1 className="primary-color-text my-5 mb-4">Impressum</h1>
                <strong className="body-large-bold primary-color-text">Kontakt</strong>
                <p className="body-large-regular primary-color-text mb-4 pe-xl-5">
                  Wir beraten dich gern! Kostenlos, von Montag bis Freitag von 8 Uhr bis 20 Uhr. Nutze den <a href='' className='primary-color-text'>direkten Kontakt</a>. zu uns!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative bg-offWhite-color gray-shade-1 gray-shade-3 my-5 py-5">
          <div className="container pb-0 pb-sm-2">
            <div className="row pb-0 pb-lg-4">
              <div className="col-xl-7 offset-xl-2 col-md-10 col-lg-8 col-12">
                <div className='mb-4 pb-2'>
                  <strong className="body-large-bold primary-color-text mb-3 d-block">Adresse</strong>
                  <p className="body-large-regular primary-color-text mb-0">Telekom Deutschland GmbH </p>
                  <p className="body-large-regular primary-color-text mb-0">Landgrabenweg 151 </p>
                  <p className="body-large-regular primary-color-text mb-0">53227 Bonn</p>
                  <p className="body-large-regular primary-color-text mb-0">Telefon: 0221–79700 440</p>
                  <p className="body-large-regular primary-color-text mb-0">Persönlicher Kontakt zu unserem Service: </p>
                  <p className="body-large-regular primary-color-text mb-0">service[at]share-mobile.de</p>
                </div>
                <div className='mb-4 pb-2'>
                  <strong className="body-large-bold primary-color-text mb-3 d-block">Postanschrift</strong>
                  <p className="body-large-regular primary-color-text mb-0">share mobile Kundenbetreuung</p>
                  <p className="body-large-regular primary-color-text mb-0">c/o FiDUS Business Solutions Josef-Schappe-Straße 21 </p>
                  <p className="body-large-regular primary-color-text mb-0">40882 Ratingen</p>
                </div>
                <div className='mb-4 pb-2'>
                  <strong className="body-large-bold primary-color-text mb-3 d-block">Powered by</strong>
                  <p className="body-large-regular primary-color-text mb-0">congstar GmbH</p>
                  <p className="body-large-regular primary-color-text mb-0">Bayenwerft 12-14 </p>
                  <p className="body-large-regular primary-color-text mb-0">50678 Köln</p>
                  <p className="body-large-regular primary-color-text mb-0">Geschäftsführung: Axel Orbach, Stefanie Becker</p>
                </div>
                <div className='mb-4 pb-2'>
                  <strong className="body-large-bold primary-color-text mb-3 d-block">Handelsregister</strong>
                  <p className="body-large-regular primary-color-text mb-0">Amtsgericht Bonn HRB 5919</p>
                  <p className="body-large-regular primary-color-text mb-0">Sitz der Gesellschaft Bonn </p>
                  <p className="body-large-regular primary-color-text mb-0">USt-IdNr. DE 122265872 </p>
                  <p className="body-large-regular primary-color-text mb-0">WEEE-Reg.-Nr.: DE60800328 </p>
                  <p className="body-large-regular primary-color-text mb-0">E-Mail: <a href='' className='primary-color-text'> impressum@share-mobile.de</a></p>
                </div>
                <div className='mb-4 pb-2'>
                  <strong className="body-large-bold primary-color-text mb-3 d-block">Vertretungsberechtigt (geschäftsführend)</strong>
                  <p className="body-large-regular primary-color-text mb-0"><strong className="body-large-bold primary-color-text">Srinivasan Gopalan,</strong> Sprecher der Geschäftsführung </p>
                  <p className="body-large-regular primary-color-text mb-0"><strong className="body-large-bold primary-color-text">Dr. Ferri Abolhassan,</strong> Geschäftsführer Vertrieb & Service</p>
                  <p className="body-large-regular primary-color-text mb-0"><strong className="body-large-bold primary-color-text">André Almeida,</strong> Geschäftsführer Privatkunden </p>
                  <p className="body-large-regular primary-color-text mb-0"><strong className="body-large-bold primary-color-text">Dr. Kerstin Baumgart,</strong> Geschäftsführerin Wholesale </p>
                  <p className="body-large-regular primary-color-text mb-0"><strong className="body-large-bold primary-color-text">Dr. Abdurazak Mudesir,</strong> Geschäftsführer Technologie </p>
                  <p className="body-large-regular primary-color-text mb-0"><strong className="body-large-bold primary-color-text">Hagen Rickmann,</strong> Geschäftsführer Geschäftskunden </p>
                  <p className="body-large-regular primary-color-text mb-0"><strong className="body-large-bold primary-color-text">Simone Thiäner,</strong> Geschäftsführerin Personal (Arbeitsdirektorin) </p>
                  <p className="body-large-regular primary-color-text mb-0"><strong className="body-large-bold primary-color-text">Klaus Werner,</strong> Geschäftsführer Finanzen</p>
                </div>
                <div className='mb-4 pb-2'>
                  <strong className="body-large-bold primary-color-text mb-3 d-block">Aufsichtsbehörde</strong>
                  <p className="body-large-regular primary-color-text mb-0">Bundesnetzagentur für Elektrizität, Gas, Telekommunikation,</p>
                  <p className="body-large-regular primary-color-text mb-0">Post und Eisenbahnen </p>
                  <p className="body-large-regular primary-color-text mb-0">Tulpenfeld 4 </p>
                  <p className="body-large-regular primary-color-text mb-0">53113 Bonn</p>
                </div>
                <div className='mb-4 pb-2'>
                  <strong className="body-large-bold primary-color-text mb-3 d-block">Haftungsausschluss</strong>
                  <p className="body-large-regular primary-color-text mb-4">Die Telekom Deutschland GmbH übernimmt keine Garantie dafür, dass die auf dieser Website bereitgestellten Informationen vollständig, richtig und in jedem Fall aktuell sind. Insbesondere übernimmt die Telekom Deutschland GmbH keine Haftung für Inhalte, die ausdrücklich oder konkludent als fremde Inhalte gekennzeichnet sind. Die Telekom Deutschland GmbH ist nicht dafür verantwortlich, dass solche Inhalte vollständig, richtig, aktuell und rechtmäßig sind und nicht in unzulässiger Weise in Rechtsgüter Dritter eingreifen. Dies gilt auch für Inhalte von Webseiten, auf die durch einen Link verwiesen wird.</p>
                  <p className="body-large-regular primary-color-text mb-4">Die Telekom Deutschland GmbH behält sich das Recht vor, ohne vorherige Ankündigung Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen oder diese zu entfernen. </p>
                  <p className="body-large-regular primary-color-text mb-0">Auf keinen Fall haftet die Telekom Deutschland GmbH für Schäden, die durch fehlende Nutzungsmöglichkeiten oder Datenverluste im Zusammenhang mit der Nutzung von Dokumenten oder Informationen bzw. der Erbringung von Dienstleistungen entstehen, die auf dieser Website zugänglich sind. </p>
                </div>
                <div className='mb-4 pb-2'>
                  <strong className="body-large-bold primary-color-text mb-3 d-block">Außergerichtliche Streitbeilegung</strong>
                  <strong className="body-large-bold primary-color-text mb-3 d-block">a) Informationen zur Verbraucherstreitbeilegung nach § 36 Verbraucherstreitbeilegungsgesetz (VSBG)
Verbraucherschlichtungsstelle Telekommunikation der Bundesnetzagentur in Bonn</strong>
                  <p className="body-large-regular primary-color-text mb-4">Zur Beilegung eines Streits mit der Telekom über die in § 68 TKG genannten Fälle kann der Kunde bei der Verbraucherschlichtungsstelle Telekommunikation der Bundesnetzagentur in Bonn (Verbraucherschlichtungsstelle) durch einen Antrag ein Schlichtungsverfahren einleiten. Die Telekom ist bereit, an Schlichtungsverfahren von der Bundesnetzagentur teilzunehmen.</p>
                  <p className="body-large-regular primary-color-text mb-0">Die Kontaktdaten der Verbraucherschlichtungsstelle lauten:</p>
                  <p className="body-large-regular primary-color-text mb-0">Bundesnetzagentur Verbraucherschlichtungsstelle</p>
                  <p className="body-large-regular primary-color-text mb-0">Telekommunikation (Referat 216)</p>
                  <p className="body-large-regular primary-color-text mb-0">Postfach 80 01 </p>
                  <p className="body-large-regular primary-color-text mb-0">53105 Bonn</p>
                  <p className="body-large-regular primary-color-text mb-0 mt-3"><a href='' className='primary-color-text'>www.bundesnetzagentur.de</a></p>
                </div>
                <div className='mb-4 pb-2'>
                  <strong className="body-large-bold primary-color-text mb-3 d-block">Sonstige Streitfälle</strong>
                  <p className="body-large-regular primary-color-text mb-0">Im Übrigen nimmt die Telekom nicht an</p>
                  <p className="body-large-regular primary-color-text mb-0">Streitbeilegungsverfahren vor einer anderen </p>
                  <p className="body-large-regular primary-color-text mb-0">Verbraucherschlichtungsstelle teil. Der Telekom ist vielmehr </p>
                  <p className="body-large-regular primary-color-text mb-0">daran gelegen, Streitigkeiten mit ihren Kunden im direkten</p>
                  <p className="body-large-regular primary-color-text mb-0">Kontakt zu klären. Bitte wenden Sie sich an den Service der Telekom.</p>
                </div>
                <div className='mb-4 pb-2'>
                  <strong className="body-large-bold primary-color-text mb-3 d-block">b) Informationen zur Online-Streitbeilegung nach Artikel 14 Abs. 1 ODR-VO</strong>
                  <p className="body-large-regular primary-color-text mb-0">Die EU-Kommission stellt eine Plattform zur Online-Streitbeilegung (OS-Plattform) verbraucherrechtlicher Streitigkeiten bereit, die aus Online-Kaufverträgen und Online-Dienstleistungsverträgen resultieren. Diese Plattform erreichen Sie im Internet unter: <a href='' href='' className='primary-color-text'> https://ec.europa.eu/consumers/odr/</a></p>
                </div>
              </div>
            </div>
          </div>
        </section>   */}
      {/* Imprint page end */}

      {/* Instructions for devices page start */}
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 py-5">
        <div className="container pt-0 pt-lg-5 py-5 mt-0 mt-lg-4">
          <div className="row py-5 pb-0 pb-sm-5">
            <div className="col-xl-7 offset-xl-1 col-lg-8 col-md-8 col-12 pe-xl-5">
              <h1 className="primary-color-text my-5 mb-4">
                Hinweise für deine Elektrogeräte/ Batterie-Rückgabe
              </h1>
              <strong className="body-large-bold primary-color-text">
                Hinweise nach § 18 Abs. 3 ElektroG
              </strong>
              <p className="body-large-regular primary-color-text my-4 mt-0">
                Endnutzer sind verpflichtet, Elektro-Altgeräte einer vom unsortierten
                Siedlungsabfall getrennten Erfassung zuzuführen. Dies bedeutet, dass
                Elektro-Altgeräte nicht im normalen Hausmüll entsorgt werden dürfen, sondern im
                Rahmen der bestehenden Sammelstellen, insbesondere bei öffentlichen
                Entsorgungsträgern, Vertreibern und Herstellern, zurückgegeben werden müssen. Dies
                wird durch das Symbol der durchgestrichenen Mülltonne zum Ausdruck gebracht.
                Zahlreiche Vertreiber von Elektro- und Elektronikgeräten sind nach den Kriterien aus
                § 17 Abs. 1 und Abs. 2 ElektroG zur unentgeltlichen Rücknahme von Elektro-Altgeräten
                verpflichtet. Die Telekom als Vertreiber hat folgende Rückgabemöglichkeiten
                geschaffen:
              </p>
              <p className="body-large-regular primary-color-text mt-0 mb-4">
                <a href="" className="primary-color-text text-break">
                  www.telekom.de/hilfe/geraete-zubehoer/umwelt/recycling/elektrogeraeteruecknahme
                </a>
              </p>
              <p className="body-large-regular primary-color-text mt-0 mb-4">
                Endnutzer sind weiterhin dazu verpflichtet, Altbatterien und Altakkumulatoren, die
                nicht vom Altgerät umschlossen sind, sowie Lampen, die zerstörungsfrei aus dem
                Altgerät entnommen werden können, vor der Abgabe an einer Erfassungsstelle vom
                Altgerät zerstörungsfrei zu trennen. Schließlich ist darauf hinzuweisen, dass
                Endnutzer selbst dafür verantwortlich sind, personenbezogene Daten auf den zu
                entsorgenden Elektro-Altgeräten vor der Abgabe an einer Erfassungsstelle zu löschen.
                Die Telekom ist bei der Stiftung Elektro-Altgeräte-Register unter der WEEE-Reg.-Nr.:
                DE 60800328 registriert. Hinweis für den Entsorgungsträger: Das Datum der
                Herstellung bzw. des Inverkehrbringens ist auf dem Typenschild nach DIN EN 60062,
                Ziffer 5, angegeben. Hersteller haben jährlich die Erfüllung der Zielvorgaben nach
                dem Elektro- und Elektronikgerätegesetz (ElektroG) zu veröffentlichen. Informationen
                hierzu veröffentlicht das Bundesministerium für Umwelt, Naturschutz und nukleare
                Sicherheit.
              </p>
              <strong className="body-large-bold primary-color-text">
                Hinweise nach § 18 Abs. 1 zum Batteriegesetz
              </strong>
              <p className="body-large-regular primary-color-text my-4 mt-0 mb-0">
                Da in unseren Sendungen Batterien und Akkus enthalten sein können, sind wir nach dem
                Batteriegesetz (BattG) verpflichtet, dich auf Folgendes hinzuweisen: Batterien und
                Akkus dürfen nicht im Hausmüll entsorgt werden, sondern du bist zur Rückgabe
                gebrauchter Batterien und Akkus gesetzlich verpflichtet. Altbatterien können
                Schadstoffe enthalten, die bei nicht sachgemäßer Lagerung oder Entsorgung die Umwelt
                oder deine Gesundheit schädigen können. Batterien enthalten, aber auch wichtige
                Rohstoffe wie z.B. Eisen, Zink, Mangan oder Nickel und können verwertet werden. Du
                kannst die Batterien nach Gebrauch entweder an uns zurücksenden oder in
                unmittelbarer Nähe (z.B. im Handel oder in kommunalen Sammelstellen oder in unserem
                Versandlager) unentgeltlich zurückgeben. Die Abgabe in Verkaufsstellen ist dabei auf
                die für Endnutzer übliche Mengen sowie solche Altbatterien beschränkt, die der
                Vertreiber als Neubatterien in seinem Sortiment führt oder geführt hat. Das Zeichen
                mit der durchgekreuzten Mülltonne bedeutet, dass du Batterien und Akkus nicht im
                Hausmüll entsorgen darfst. Unter diesem Zeichen findest du zusätzlich nachstehende
                Symbole mit folgender Bedeutung:{' '}
              </p>
              <p className="body-large-regular primary-color-text mt-0 mb-0">
                Pb: Batterie enthält Blei
              </p>
              <p className="body-large-regular primary-color-text mt-0 mb-0">
                Cd: Batterie enthält Cadmium
              </p>
              <p className="body-large-regular primary-color-text mt-0 mb-0">
                Hg: Batterie enthält Quecksilber
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* Don't remove this BR */}
      {/* <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" /> */}
      {/* Instructions for devices page end */}

      {/* Cancelation form page start */}

      {/* <section className="position-relative py-5 pb-0 pb-lg-5 mb-0 mb-lg-5">
        <div className="container pt-0 pt-lg-5 py-5 pb-0 pb-md-5 mt-0 mt-lg-4">
          <div className="row py-5 pb-0">
            <div className="col-xl-7 offset-xl-1 col-md-8 offset-md-2 col-lg-7 col-12">
              <h1 className="primary-color-text my-5 pb-0 pb-xl-4">Mach’s gut</h1>
              <div className='ps-xl-5 ms-xl-5'>
                <p className="body-large-bold primary-color-text mb-4">
                  Du möchtest uns verlassen? Wir werden dich vermissen.
                </p>
                <p className="body-large-regular primary-color-text mb-0">
                  Aber das ist okay, denn du gestaltest dein Leben ganz nach deinen Vorstellungen. Denn erstens kommt es anders, und zweitens als man denkt. Verstehen wir.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 gray-shade-3 my-5 pt-5 pb-0 pb-md-5 pb-xl-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 offset-xl-2 col-md-8 offset-md-2 col-lg-8 col-12">
              <h4 className="primary-color-text mb-5">Kündigungsformular</h4>
              <div className='cancelation-form-row'>
                <form>
                  <div className='row'>
                    <div className='col-xl-12'>
                      <h6 className='primary-color-text heading-small mb-3 mb-xl-3'>Persönliche Daten</h6>
                    </div>
                    <div className='col-xl-6 col-sm-6 col-12 mb-4 mb-xl-4'>
                      <input className="form-control" type="text" placeholder='Vorname' />
                    </div>
                    <div className='col-xl-6 col-sm-6 col-12 mb-4 mb-xl-4'>
                      <input className="form-control" type="text" placeholder='Nachname' />
                    </div>
                    <div className='col-xl-12 col-12 mb-5 mb-xl-5'>
                      <input className="form-control" type="text" placeholder='E-Mail-Adresse' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xl-12 col-12'>
                      <h6 className='primary-color-text heading-small mb-3 mb-xl-3'>Identifikation</h6>
                    </div>
                    <div className='col-xl-6 mb-4 mb-xl-5'>
                      <input className="form-control" type="text" placeholder='Kundennummer' />
                    </div>
                    <div className='col-xl-6 mb-5 mb-xl-5'>
                      <input className="form-control" type="text" placeholder='Zu kündigende Rufnummer' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xl-12'>
                      <h6 className='primary-color-text heading-small mb-3 mb-xl-3'>IBAN für Guthabenerstattung</h6>
                    </div>
                    <div className='col-xl-12 mb-5 mb-xl-5'>
                      <input className="form-control" type="text" placeholder='IBAN' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xl-12'>
                      <h6 className='primary-color-text heading-small mb-3 mb-xl-3'>Kündigungsgrund</h6>
                    </div>
                    <div className='col-xl-12 mb-5 mb-xl-5'>
                      <select
                        className="form-select primary-color-text shadow-none body-medium-regular border-0"
                        aria-label="Default select example"
                      >
                        <option selected>Warum möchtest du kündigen?</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xl-7 col-7'>
                     <button type="button" className="btn btn-secondary w-100">Vertrag kündigen</button>
                    </div>
                    <div className='col-xl-12'>
                      <p className='primary-color-text body-large-regular mt-5 mb-4 mb-xl-0'>Bitte beachte, dass die Kündigung zu sofort wirksam wird und nicht zurückgezogen werden kann.</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <br className="d-none d-xl-block" />
      <br className="d-none d-xl-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" /> */}
      {/* Cancelation form page end */}

      {/* Thank You page start */}

      <section className="position-relative bg-offWhite-color gray-shade-1 py-5 pb-0 pb-lg-5 mb-5">
        <div className="container pt-0 pt-lg-5 py-5 pb-0 mt-0 mt-lg-4">
          <div className="row py-5 pb-0">
            <div className="col-xl-7 offset-xl-1 col-md-8 offset-md-2 col-lg-7 col-12">
              <h1 className="primary-color-text mt-5 mb-4">Vielen Dank!</h1>
              <p className="body-large-regular primary-color-text mb-4">
                Deine Kündigung ist erfolgreich eingegangen. Danke, dass du dazu beigetragen hast,
                digitale Bildung zu ermöglichen.
              </p>
              <p className="body-large-regular primary-color-text mb-4">
                Außer Mobilfunk gibt’s im Shop noch viele tolle Produkte mit sozialem Impact – schau
                doch mal rein:
              </p>
              <div className="col-md-7 col-sm-6 col-12 mb-5 mb-xl-0">
                <button type="button" className="btn btn-secondary w-100">
                  Zum share Shop
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative py-4 my-5 d-none d-xl-block">&nbsp;</section>
      <section className="bg-offWhite-color">
        <StickyLayer />
      </section>

      {/* Thank You page End */}
    </>
  );
}

export default Test2;
