import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import {
  appRoute,
  appStaticPdf,
  appSecBottomElementTypes,
  appSecBgColorTypes
} from '@utils/globalConstant';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_LINK, UTAG_VIEWS } from '@utils/utag';
import { useLayout } from '@context/Utils';
import { Section } from '@core/Layout';

export function AGB() {
  // State
  const [agbServicePdf, setAgbServicePdf] = useState([]);
  const [agbArchivePdf, setAgbArchivePdf] = useState([]);

  // Contexts
  const { setLightHeader } = useLayout();
  const { t, getPdfList, staticContentData } = useStaticContent();
  const { setMetaTags } = useMetaTag();

  useEffect(() => {
    setLightHeader(false);

    return () => {
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.AGB_MOUNTING);
    }
  }, [window.utag]);

  useEffect(() => {
    if (staticContentData) {
      let services = getPdfList('sh_pdfLists').find(
        (item) => item.listName === appStaticPdf.AGB_SERVICES
      );

      let archives = getPdfList('sh_pdfLists').find(
        (item) => item.listName === appStaticPdf.AGB_ARCHIVE
      );

      let servicesList = services.listContent;
      // const subpage = {
      //   name: t('sh_web_agb_pdf6'),
      //   media_url_display: appRoute.AGB_INSTRUCTIONS,
      //   UTAG_ON_CLICK: UTAG_LINK.AGB_ON_CLICK.AGB_INSTRUCTIONS_ON_CLICK.link_id
      // };
      // servicesList.splice(4, 1, subpage);
      // console.log('servicesList', servicesList);

      setAgbServicePdf(servicesList);
      setAgbArchivePdf(archives.listContent);
    }

    setMetaTags({
      title: t('sh_web_page_legal_agb_meta_title'),
      description: t('sh_web_page_legal_agb_meta_description'),

      twitterTitle: t('sh_web_page_legal_agb_meta_title'),
      twitterDescription: t('sh_web_page_legal_agb_meta_description'),

      ogTitle: t('sh_web_page_legal_agb_meta_title'),
      ogDescription: t('sh_web_page_legal_agb_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  return (
    <>
      {/* <section className="position-relative py-5 pb-0 pb-lg-5 mb-0 mb-lg-5"> */}
      <section className="position-relative pt-lg-5 pb-56 pb-lg-80">
        {/* <div className="container pt-0 pt-lg-5 py-5 pb-0 pb-md-5 mt-0 mt-lg-4"> */}
        <div className="container pt-5 mt-0 mt-lg-4">
          <div className="row py-5 pb-0">
            <div className="col-xl-6 offset-xl-1 col-md-6 offset-md-2 col-12">
              <h1
                className="primary-color-text my-5 mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_agb_h1') }}
              />
              <div
                className="body-large-regular primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_agb_text1') }}
              />
              <div
                className="body-large-regular primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_agb_text2') }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <Section
        // className="position-relative mb-sm-0 gray-shade-3"
        className="position-relative mb-sm-0"
        // topElementType={appSecTopElementTypes.}
        bottomElementType={appSecBottomElementTypes.TYPE_4}
        // bgColorCode={appSecBgColorTypes.OFFWHITE}
      > */}

      {/* <section className="position-relative bg-offWhite-color gray-shade-1 gray-shade-3 my-5 py-5 mb-5 mb-sm-0"> */}
      <section className="bg-offWhite-color py-56 py-lg-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 offset-xl-2 col-md-7 offset-md-2 col-12">
              <ol className="my-4">
                {agbServicePdf.map((item, index) => (
                  <li
                    key={`${index + 1}`}
                    className="body-large-regular primary-color-text text-decoration-underline"
                    onClick={() => {
                      if (window && window.utag && item.UTAG_ON_CLICK) {
                        window.utag.link({
                          link_id: item.UTAG_ON_CLICK
                        });
                      }
                      window.open(item.media_url_display);
                    }}
                  >
                    <a className="link primary-color-text">{item.name}</a>
                  </li>
                ))}
              </ol>
              {agbArchivePdf && agbArchivePdf.length > 0 && (
                <>
                  <div
                    className="body-large-regular primary-color-text mb-0 mb-sm-4"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_agb_archiv') }}
                  />
                  <ol className="mb-4">
                    {agbArchivePdf.map((item, index) => (
                      <li
                        key={`${index + 1}`}
                        className="body-large-regular primary-color-text text-decoration-underline"
                        onClick={() => {
                          if (window && window.utag && item.UTAG_ON_CLICK) {
                            window.utag.link({
                              link_id: item.UTAG_ON_CLICK
                            });
                          }
                          window.open(item.media_url_display);
                        }}
                      >
                        <a className="link primary-color-text">{item.name}</a>
                      </li>
                    ))}
                  </ol>
                </>
              )}
              <div
                className="body-large-regular primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_agb_info') }}
              />
            </div>
          </div>
        </div>
      </section>
      {/* </Section> */}
    </>
  );
}

export default AGB;
