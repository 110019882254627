import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';

import { Button, InputFeedback, Radio, Switch } from '@core/Inputs';
import { usePayment } from '@context/MobileOne/Activation/Payments';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';
import { appButtonType, appPaymentMethods } from '@utils/globalConstant';
import { UTAG_LINK, utag } from '@utils/utag';

export function PaymentFlow() {
  // Context
  const { t } = useStaticContent();
  const {
    isLoading,
    formValue,
    validationSchema,
    onSubmit,
    paymentMethods,
    validateForm,
    getPaymentMethods
  } = usePayment();
  const { activationInfo, setActivationInfo, prevStep } = useActivation();
  useEffect(() => {
    if (!paymentMethods.length) {
      getPaymentMethods();
    }
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-3 pb-5 mt-5">
            <div className="back-btn">
              <a
                className="text-white body-medium-bold text-decoration-none link-light"
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(
                      UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD.BACK_ON_CLICK
                    );
                  }
                  prevStep();
                }}
              >
                <i className="fa fa-arrow-left me-3"></i>
                {t('sh_back_button')}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-sm-5">
        <div className="row mb-5 mb-sm-0">
          <div className="col-12 col-md-11 offset-md-1 col-lg-7 mb-5 mb-sm-0">
            <h6 className="text-uppercase text-white">{t('sh_apoc_payment_step5')}</h6>
            <h1 className="pink-color-text mt-2 mt-sm-2">{t('sh_apoc_payment_h1')}</h1>
            <div className="row">
              <div className="col-12 col-sm-9">
                <p className="text-white body-large-regular mt-3 mt-sm-5 mb-0">
                  {t('sh_apoc_payment_subheader1')}
                </p>
              </div>
            </div>
            {paymentMethods && paymentMethods.length > 0 && (
              <Formik
                initialValues={{ ...formValue }}
                validationSchema={validationSchema}
                enableReinitialize
                validate={validateForm}
                onSubmit={(values) => {
                  if (window && window.utag) {
                    values.paymentMethod === 'paypal'
                      ? window.utag.link(
                          UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                            .CONTINUE_PAYPAL_ON_CLICK
                        )
                      : window.utag.link(
                          UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                            .CONTINUE_CREDIT_CARD_ON_CLICK
                        );
                  }
                  onSubmit(values);
                }}
              >
                {({
                  values: { paymentMethod, enoughCredit },
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="col-12 col-sm-10 mt-4 mt-sm-5 mb-4">
                      <div className="d-inline">
                        {paymentMethods.map((pm) => (
                          <div
                            key={`payment-flow-${pm}`}
                            onClick={(event) => setFieldValue('paymentMethod', pm)}
                            className="py-2"
                          >
                            <Radio
                              label={
                                pm === appPaymentMethods.PAYPAL
                                  ? t('sh_apoc_payment_option1')
                                  : pm === appPaymentMethods.CREDIT_CARD
                                  ? t('sh_apoc_payment_option2')
                                  : pm
                              }
                              description={t(
                                pm === appPaymentMethods.CREDIT_CARD
                                  ? 'sh_apoc_payment_option2_text'
                                  : ''
                              )}
                              name="paymentMethod"
                              isDefaultChecked={pm === paymentMethod}
                              onChange={(event) => {
                                if (window && window.utag) {
                                  pm === appPaymentMethods.PAYPAL
                                    ? window.utag.link(
                                        UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                                          .PAYPAL_ON_CLICK
                                      )
                                    : window.utag.link(
                                        UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                                          .CREDIT_CARD_ON_CLICK
                                      );
                                }
                                handleChange(event);

                                // setFieldTouched('paymentMethod', true, false);
                                // setFieldValue('paymentMethod', event.target.value);
                              }}
                              onBlur={handleBlur}
                              value={pm}
                              checked={pm === paymentMethod}
                              id={pm}
                              key={pm}
                            />
                            {/* <FormCheck
                            label={
                              pm === 'paypal'
                                ? t('sh_apoc_payment_option1')
                                : t('sh_apoc_payment_option2')
                            }
                            name="paymentMethod"
                            type="radio"
                            className="m-0"
                            // onChange={handleChange}
                            onChange={(event) => setFieldValue('paymentMethod', event.target.value)}
                            onBlur={handleBlur}
                            value={pm}
                            checked={pm === paymentMethod}
                            key={pm}
                          />
                          <p
                            style={{ marginLeft: '1.25rem' }}
                            onClick={() => setFieldValue('paymentMethod', pm)}
                          >
                            {pm === 'paypal'
                              ? t('sh_apoc_payment_option1_text')
                              : t('sh_apoc_payment_option2_text')}
                          </p> */}
                          </div>
                        ))}
                        {touched.paymentMethod && errors.paymentMethod && (
                          <InputFeedback
                            id={`payment-error`}
                            isInvalid
                            feedBackMessage={errors.paymentMethod}
                          />
                        )}
                        {/* <FormControl.Feedback type="invalid" className="d-block">
                        {errors.paymentMethod}
                      </FormControl.Feedback> */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-10 mb-4 mb-sm-0">
                      {/* <p className="body-medium-regular offWhite-color-text mt-5">
                      {t('sh_apoc_payment_text1')}
                    </p> */}
                      <Switch
                        label={t('sh_apoc_payment_text1')}
                        type="secondary"
                        name="enoughCredit"
                        id="enoughCredit"
                        isDefaultChecked={enoughCredit}
                        value={!enoughCredit}
                        onChange={() => {
                          if (window && window.utag) {
                            !enoughCredit
                              ? window.utag.link(
                                  UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                                    .ENABLE_AUTO_CHARGING
                                )
                              : window.utag.link(
                                  UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                                    .DISABLE_AUTO_CHARGING
                                );
                          }

                          handleChange({
                            target: {
                              id: 'enoughCredit',
                              name: 'enoughCredit',
                              value: !enoughCredit
                            }
                          });
                          setActivationInfo({
                            ...activationInfo,
                            enoughCredit: !activationInfo.enoughCredit
                          });
                        }}
                      />
                      <p className="body-medium-regular offWhite-color-text mt-5">
                        {t('sh_apoc_payment_text2')}
                      </p>
                      <Button
                        buttonType={appButtonType.PRIMARY}
                        className="w-100"
                        type="submit"
                        isLoading={isLoading}
                      >
                        {t('sh_apoc_payment_next_button')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentFlow;
