import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Modal } from '@core/Utils';
import { useNumberPorting } from '@context/MobileOne/Activation/NumberPorting';
import { Button, Text } from '@core/Inputs';
import { appActivationFlowType, appButtonType } from '@utils/globalConstant';
import { useActivation } from '@context/MobileOne';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';

export function CurrentNumber({ setModal }) {
  // Context
  const { t } = useStaticContent();
  const { validationCurrentNumber, isLoading, formValue, setFormValue } = useNumberPorting();
  const { activationInfo, activationType } = useActivation();

  // State
  const [currentNumber, setCurrentNumber] = useState(formValue.currentNumber);

  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.CURRENT_NUMBER_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.CURRENT_NUMBER_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
      if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.CURRENT_NUMBER_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.CURRENT_NUMBER_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  return (
    <Modal
      showBackButton
      onBackClick={() => setModal('oldNumberProvider')}
      stepHeader={t('sh_apoc_number-portability_step2')}
      header={t('sh_apoc_number-portability_h1_1')}
      description={t('sh_apoc_number-portability_subheader2')}
    >
      <div className="mt-4">
        <Text
          id="currentNumber"
          name="currentNumber"
          onChange={(e) => {
            setCurrentNumber(e.target.value);
            setFormValue({ ...formValue, currentNumber: e.target.value });
          }}
          value={formValue.currentNumber}
          placeholder={t('sh_apoc_number-portability_input2')}
        />

        <div className="row pt-3">
          <div className="col">
            <Button
              buttonType={appButtonType.SECONDARY}
              className="w-100"
              onClick={() => {
                if (window && window.utag) {
                  activationType === appActivationFlowType.NEW_ONLINE
                    ? window.utag.link(
                        UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.NUMBER_PORTABILITY_NUMBER
                          .CURRENT_NUMBER_ON_CLICK
                      )
                    : window.utag.link(
                        UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.NUMBER_PORTABILITY_NUMBER
                          .CURRENT_NUMBER_ON_CLICK
                      );
                }
                setModal('startWithOldNumber');
              }}
              isLoading={isLoading}
              disabled={!currentNumber || !validationCurrentNumber.test(currentNumber)}
            >
              {t('sh_apoc_number-portability_button')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CurrentNumber;
