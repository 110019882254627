import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Form, Formik } from 'formik';
import { FormCheck, FormControl } from 'react-bootstrap';
import { Col, Row } from '@core/Layout';
import { Button, Select } from '@core/Inputs';
import { useRecharge } from '@context/MobileOne/recharge';

export function AutomaticRecharge() {
  // Context
  const { t } = useStaticContent();
  const {
    validationSchemaRechargeOpt1,
    validationSchemaRechargeOpt2,
    validationSchemaRechargeOpt3,
    formValue,
    setFormValue,
    onSubmit,
    isLoading
  } = useRecharge();

  // State

  // Function
  return (
    <Row>
      <Col md={4}>
        <Button variant="link" onClick={() => setFormValue({ rechargeOption: '' })}>
          <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
        </Button>
      </Col>
      <Col md={8}>
        {formValue.rechargeOption === t('sh_comfort_charge_opt1') && (
          <>
            <h3>{t('sh_comfort_charge_amount_h1')}</h3>
            <Formik
              initialValues={{ ...formValue }}
              validationSchema={validationSchemaRechargeOpt1}
              onSubmit={onSubmit}
            >
              {({
                values: { automaticRecharge, enoughBalance },
                handleBlur,
                handleChange,
                handleSubmit,
                errors
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="m-2">
                      <h6>{t('sh_comfort_charge_amount_subheadline')}</h6>
                      {[
                        t('sh_comfort_charge_amount_opt1'),
                        t('sh_comfort_charge_amount_opt2'),
                        t('sh_comfort_charge_amount_opt3')
                      ].map((opt) => (
                        <FormCheck
                          label={opt}
                          name="automaticRecharge"
                          type="radio"
                          className="m-1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={opt}
                          checked={automaticRecharge === opt}
                        />
                      ))}
                      <FormControl.Feedback type="invalid" className="d-block">
                        {errors.automaticRecharge}
                      </FormControl.Feedback>
                    </Col>
                    <Col xs={12}>
                      <b>{t('sh_comfort_charge_amount_subheadline2')}</b>
                      <FormCheck
                        label={t('sh_comfort_charge_amount_checkbox')}
                        name="enoughBalance"
                        type="checkbox"
                        className="m-1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={enoughBalance}
                        checked={enoughBalance}
                      />
                      <FormControl.Feedback type="invalid" className="d-block">
                        {errors.enoughBalance}
                      </FormControl.Feedback>
                    </Col>
                    <Col xs={12} className="pt-3">
                      <Button variant="primary" type="submit" isLoading={isLoading}>
                        {t('sh_comfort_charge_amount_button').replace(
                          '{{value}}',
                          automaticRecharge || ''
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </>
        )}

        {formValue.rechargeOption === t('sh_comfort_charge_opt2') && (
          <>
            <h3>{t('sh_comfort_charge_date_h1')}</h3>
            <Formik
              initialValues={{ ...formValue }}
              validationSchema={validationSchemaRechargeOpt2}
              onSubmit={onSubmit}
            >
              {({
                values: { automaticRecharge, date },
                handleBlur,
                handleChange,
                handleSubmit,
                errors,
                touched
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={6}>
                      <b>{t('sh_comfort_charge_date_subheadline')}</b>
                      <Select
                        defaultOption={t('sh_comfort_charge_date_datepicker')}
                        id="date"
                        name="date"
                        options={Array.from(Array(31).keys()).map((opt) => opt + 1)}
                        value={date}
                        onChange={(e) =>
                          handleChange({
                            target: {
                              id: 'date',
                              name: 'date',
                              value: e.target.value
                            }
                          })
                        }
                        getOptionId={(option) => option}
                        getOptionName={(option) => option}
                        isInvalid={!!touched.date && !!errors.date}
                        onBlur={handleBlur}
                      />
                      <FormControl.Feedback type="invalid" className="d-block">
                        {errors.date}
                      </FormControl.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="m-2">
                      <h6>{t('sh_comfort_charge_amount_subheadline')}</h6>
                      {[
                        t('sh_comfort_charge_amount_opt1'),
                        t('sh_comfort_charge_amount_opt2'),
                        t('sh_comfort_charge_amount_opt3')
                      ].map((opt) => (
                        <FormCheck
                          label={opt}
                          name="automaticRecharge"
                          type="radio"
                          className="m-1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={opt}
                          checked={automaticRecharge === opt}
                        />
                      ))}
                      <FormControl.Feedback type="invalid" className="d-block">
                        {errors.automaticRecharge}
                      </FormControl.Feedback>
                    </Col>

                    <Col xs={12} className="pt-3">
                      <Button variant="primary" type="submit" isLoading={isLoading}>
                        {t('sh_comfort_charge_amount_button').replace(
                          '{{value}}',
                          automaticRecharge || ''
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </>
        )}

        {formValue.rechargeOption === t('sh_comfort_charge_opt3') && (
          <>
            <h3>{t('sh_comfort_charge_tarif_h1')}</h3>
            <Formik
              initialValues={{ ...formValue }}
              validationSchema={validationSchemaRechargeOpt3}
              onSubmit={onSubmit}
            >
              {({
                values: { automaticRecharge },
                handleBlur,
                handleChange,
                handleSubmit,
                errors
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="m-2">
                      <h6>{t('sh_comfort_charge_tarif_subheadline')}</h6>
                      {[
                        t('sh_comfort_charge_amount_opt1'),
                        t('sh_comfort_charge_amount_opt2'),
                        t('sh_comfort_charge_amount_opt3')
                      ].map((opt) => (
                        <FormCheck
                          label={opt}
                          name="automaticRecharge"
                          type="radio"
                          className="m-1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={opt}
                          checked={automaticRecharge === opt}
                        />
                      ))}
                      <FormControl.Feedback type="invalid" className="d-block">
                        {errors.automaticRecharge}
                      </FormControl.Feedback>
                    </Col>

                    <Col xs={12} className="pt-3">
                      <Button variant="primary" type="submit" isLoading={isLoading}>
                        {t('sh_comfort_charge_amount_button').replace(
                          '{{value}}',
                          automaticRecharge || ''
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Col>
    </Row>
  );
}

export default AutomaticRecharge;
