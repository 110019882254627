import React from 'react';
import { Col, Container, Row } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function LegitimacyFailed() {
  // Contexts
  const { t } = useStaticContent();

  return (
    <Container>
      <br />
      <br />
      <Row>
        <Col md={6} xs={12} className="mb-5">
          <h1 dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_failed_h1') }} />
          <br />
          <p dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_failed_text') }} />
          <b>
            <p
              className="m-0"
              dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_failed_number') }}
            />
          </b>
          <p dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_failed_opening_time') }} />
        </Col>
      </Row>
    </Container>
  );
}

export default LegitimacyFailed;
