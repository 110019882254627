import React, { useEffect, useState } from 'react';
import { appImages } from '@utils/globalConstant';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import './index.scss';

export function SplashScreen({ splashOut }) {
  const { t } = useStaticContent();

  const [showSpinner, hideSpinner] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      hideSpinner(true);
    }, 300);
  }, []);

  return (
    <div
      className={`bg-primary-color animate__animated ${
        splashOut ? 'animate__fadeOut' : 'animate__fadeIn'
      }`}
    >
      <div className="splash-screen-background">
        <div
          className={`splash-screen-icon animate__animated ${
            splashOut ? 'animate__bounceOutDown' : 'animate__backInDown animate__delay-1s'
          }`}
        >
          <img src={appImages.splashScreenDarkLogo} alt="Splash-Screen" />
        </div>
        {showSpinner && (
          <div
            className={`animate__animated ${
              splashOut ? 'animate__bounceOutDown' : 'animate__fadeIn animate_slower'
            }  loading-spinner py-5`}
          >
            <div className="spinner-border" role="status" style={{ color: 'white' }}>
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="d-flex position-absolute bottom-0 pb-5 w-full justify-content-center align-items-center overview-powered-logo">
          <p className="text-white label-small link-light tariff-link shadow-none m-0">
            {t('sh_apoc_tarif_overview_powered') !== 'sh_apoc_tarif_overview_powered'
              ? t('sh_apoc_tarif_overview_powered')
              : 'powered by'}
          </p>
          <img src={appImages.CongStarWhite02} />
        </div>
      </div>
    </div>
  );
}

export default SplashScreen;
