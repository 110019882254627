/* eslint-disable */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { formValidation } from '@dom-digital-online-media/dom-app-config-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';
import { appRoute, appTariff } from '@utils/globalConstant';
import { UTAG_LINK, utag } from '@utils/utag';

export const ActivationTariffContext = createContext({});

export function ActivationTariffContextProvider({ children, onFormUpdate, nextStep }) {
  // Constant
  const initialValue = {
    tariff: '',
    isPromoApplied: '',
    codeForValidation: ''
    // Selected Tariff ID (Use the fist index of bookableTariff by Defalut)
    // Selected Option ID (If Selected Tariff contains options)
  };
  const navigate = useNavigate();

  // Static content context
  const { t, staticContentData } = useStaticContent();
  const { onPosPurchase, onOldCustomerPurchas, setCurrentStep } = useActivation();

  // Validations
  const validationSchema = Yup.lazy(({ isPromoApplied }) => {
    if (isPromoApplied) {
      return Yup.object().shape({
        tariff: formValidation({ required: 'please_select' }),
        codeForValidation: formValidation({ required: t('sh_apoc_tarif_voucher_err_no_code') })
      });
    }
    return Yup.object().shape({
      tariff: formValidation({ required: 'please_select' })
    });
  });

  const validationSchemaMgmCode = Yup.object().shape({
    codeForValidation: formValidation({ required: t('sh_apoc_tarif_voucher_err_no_code') })
  });

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [isVviDocLoading, setIsVviDocLoading] = useState(false);
  const [formValue, setFormValue] = useState(initialValue);
  const [activeTariff, setActiveTariff] = useState(null);
  const [vviDocuments, setVviDocuments] = useState({
    vfzUrl: '',
    agbUrl: '',
    preislisteUrl: '',
    leistungsbeschreibungUrl: ''
  });
  const [modal, setModal] = useState('');

  // Tariff from Validate Sim Response
  const defaultState = {
    msisdn: '',
    puk: '2345',
    bookableTariffs: [
      // {
      //   id: 6501,
      //   name: 'Allnet Flat M',
      //   description:
      //     '6 GB Daten inklusive Voice/SMS Flatrate in Deutschland. Die Bandbreite wird nach dem Verbrauch des Inklusivdatenvolumens von 6 GB im jeweiligen Monat von max. 25 Mbit/s im Download und max. 10 Mbit/s im Upload reduziert auf: 64 Kbit/s im Download, 16 Kbit/s im Upload.',
      //   defaultTariff: true,
      //   price: 10,
      //   starterPackPrice: 10,
      //   duration: 30,
      //   durationUnit: ' Tage',
      //   tariffType: 'Prepaid',
      //   sortOrder: 3,
      //   additionalInfo: {
      //     primaryColor: null,
      //     secondaryColor: null,
      //     activePicture: null,
      //     inactivePicture: null,
      //     document: {
      //       productInformation: {
      //         id: 158
      //       },
      //       priceList: {
      //         id: 146
      //       },
      //       tariffDetails: {
      //         id: 158
      //       }
      //     },
      //     volume: {
      //       DATA: {
      //         unit: 'GB',
      //         value: 6
      //       }
      //     },
      //     bullets: [
      //       {
      //         text: 'Rufnummer für 10€ Bonus mitnehmen',
      //         iconUrl: null
      //       },
      //       {
      //         text: 'Mit eSIM nutzbar',
      //         iconUrl: null
      //       },
      //       {
      //         text: 'Kindern Bildung spenden',
      //         iconUrl: null
      //       }
      //     ]
      //   },
      //   detailInfo: {
      //     header: 'Allnet Flat M',
      //     subHeader: 'statt <s>5 GB</s>',
      //     text: null,
      //     iconUrl: null,
      //     navigateUrl: null,
      //     additionalInfo: {
      //       activePicture: null,
      //       inactivePicture: null,
      //       bullets: [
      //         {
      //           header:
      //             "Dauerhaft  <span class='body-medium-bold' style='font-weight:bold'>6 GB</span> inkl.LTE 25 in bester D-Netz-Qualität",
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Zusätzlich 12 Monate digitale Bildung spenden',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Telefonie- & SMS-Flat in alle dt. Netze',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'EU-Roaming inklusive',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Ohne Vertragslaufzeit',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         }
      //       ]
      //     }
      //   },
      //   operationsInfo: {
      //     ON_MGM_CODE_APPLY: {
      //       header: null,
      //       subHeader: '{{totalDataCount}} GB (<s>statt {{originalDataCount}} GB</s>) Datenvolumen',
      //       text: null,
      //       iconUrl: null,
      //       navigateUrl: null,
      //       activePicture: null,
      //       inactivePicture: null,
      //       bullets: [
      //         {
      //           header:
      //             '{{totalDataCount}} GB (<s>statt {{originalDataCount}} GB</s>) inkl. LTE 25 in bester D-Netz-Qualität',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Telefonie- & SMS-Flat in alle dt. Netze',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'EU-Roaming inklusive',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Ohne Vertragslaufzeit',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         }
      //       ]
      //     },
      //     ACTIVE: {
      //       header: 'AKTIVER TARIF',
      //       text: null,
      //       iconUrl: null,
      //       navigateUrl: null,
      //       activePicture: null,
      //       inactivePicture: null,
      //       bullets: [
      //         {
      //           header: '6 GB inkl. LTE 25 in bester D-Netz-Qualität',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Telefonie- & SMS-Flat in alle dt. Netze',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'EU-Roaming inklusive',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Ohne Vertragslaufzeit',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         }
      //       ]
      //     },
      //     RECOMMENDED: {
      //       header: 'UNSERE EMPFEHLUNG',
      //       text: null,
      //       iconUrl: null,
      //       navigateUrl: null,
      //       activePicture: null,
      //       inactivePicture: null
      //     },
      //     ON_SUCCESS_BOOKING: {
      //       header: 'Booking in progress',
      //       text: 'Thank you for booking! once processed, you will receive an email with the details of your booking.',
      //       iconUrl: null,
      //       navigateUrl: 'internal:/tarif',
      //       activePicture: 'https://www.mobileone.eu/edeka/start_300_active.png',
      //       inactivePicture: 'https://www.mobileone.eu/edeka/start_300_inactive.png'
      //     },
      //     ON_FAILURE_BOOKING: {
      //       header: 'Booking failed',
      //       text: 'Thank you for request! once processed, you will receive an email with the details of your booking.',
      //       iconUrl: null,
      //       navigateUrl: 'internal:/tarif',
      //       activePicture: 'https://www.mobileone.eu/edeka/start_300_active.png',
      //       inactivePicture: 'https://www.mobileone.eu/edeka/start_300_inactive.png'
      //     }
      //   },
      //   prices: [],
      //   discounts: []
      // },
      // {
      //   id: 6500,
      //   name: 'Allnet Flat L',
      //   description:
      //     '12 GB Daten inklusive Voice/SMS Flatrate in Deutschland. Die Bandbreite wird nach dem Verbrauch des Inklusivdatenvolumens von 12 GB im jeweiligen Monat von max. 25 Mbit/s im Download und max. 10 Mbit/s im Upload reduziert auf: 64 Kbit/s im Download, 16 Kbit/s im Upload.',
      //   defaultTariff: false,
      //   price: 15,
      //   starterPackPrice: 15,
      //   duration: 30,
      //   durationUnit: ' Tage',
      //   tariffType: 'Prepaid',
      //   sortOrder: 64,
      //   additionalInfo: {
      //     primaryColor: null,
      //     secondaryColor: null,
      //     activePicture: null,
      //     inactivePicture: null,
      //     document: {
      //       productInformation: {
      //         id: 159
      //       },
      //       priceList: {
      //         id: 146
      //       },
      //       tariffDetails: {
      //         id: 159
      //       }
      //     },
      //     volume: {
      //       DATA: {
      //         unit: 'GB',
      //         value: 12
      //       }
      //     },
      //     bullets: [
      //       {
      //         text: 'Rufnummer für 10 € Bonus mitnehmen',
      //         iconUrl: null
      //       },
      //       {
      //         text: 'Mit eSIM nutzbar',
      //         iconUrl: null
      //       },
      //       {
      //         text: 'Kindern Bildung spenden',
      //         iconUrl: null
      //       }
      //     ]
      //   },
      //   detailInfo: {
      //     header: 'Allnet Flat L',
      //     subHeader: 'statt <s>10 GB</s>',
      //     text: null,
      //     iconUrl: null,
      //     navigateUrl: null,
      //     additionalInfo: {
      //       activePicture: null,
      //       inactivePicture: null,
      //       bullets: [
      //         {
      //           header:
      //             "Dauerhaft <span class='body-medium-bold' style='font-weight:bold'>12 GB</span> mit LTE 25 in bester D-Netz-Qualität",
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Zusätzlich 12 Monate digitale Bildung spenden',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Telefonie- & SMS-Flat in alle dt. Netze',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'EU-Roaming inklusive',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Ohne Vertragslaufzeit',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         }
      //       ]
      //     }
      //   },
      //   operationsInfo: {
      //     ON_MGM_CODE_APPLY: {
      //       header: null,
      //       subHeader: '{{totalDataCount}} GB (<s>statt {{originalDataCount}} GB</s>) Datenvolumen',
      //       text: null,
      //       iconUrl: null,
      //       navigateUrl: null,
      //       activePicture: null,
      //       inactivePicture: null,
      //       bullets: [
      //         {
      //           header:
      //             '{{totalDataCount}} GB (<s>statt {{originalDataCount}} GB</s>) inkl. LTE 25 in bester D-Netz-Qualität',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Telefonie- & SMS-Flat in alle dt. Netze',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'EU-Roaming inklusive',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Ohne Vertragslaufzeit',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         }
      //       ]
      //     },
      //     ACTIVE: {
      //       header: 'AKTIVER TARIF',
      //       text: null,
      //       iconUrl: null,
      //       navigateUrl: null,
      //       activePicture: null,
      //       inactivePicture: null,
      //       bullets: [
      //         {
      //           header: '12 GB inkl. LTE 25 in bester D-Netz-Qualität',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Telefonie- & SMS-Flat in alle dt. Netze',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'EU-Roaming inklusive',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         },
      //         {
      //           header: 'Ohne Vertragslaufzeit',
      //           text: null,
      //           iconUrl: null,
      //           navigateUrl: null,
      //           additionalInfo: null
      //         }
      //       ]
      //     },
      //     RECOMMENDED: {
      //       header: 'UNSERE EMPFEHLUNG',
      //       text: null,
      //       iconUrl: null,
      //       navigateUrl: null,
      //       activePicture: null,
      //       inactivePicture: null
      //     },
      //     ON_SUCCESS_BOOKING: {
      //       header: 'Booking in progress',
      //       text: 'Thank you for booking! once processed, you will receive an email with the details of your booking.',
      //       iconUrl: null,
      //       navigateUrl: 'internal:/tarif',
      //       activePicture: 'https://www.mobileone.eu/edeka/start_300_active.png',
      //       inactivePicture: 'https://www.mobileone.eu/edeka/start_300_inactive.png'
      //     },
      //     ON_FAILURE_BOOKING: {
      //       header: 'Booking failed',
      //       text: 'Thank you for request! once processed, you will receive an email with the details of your booking.',
      //       iconUrl: null,
      //       navigateUrl: 'internal:/tarif',
      //       activePicture: 'https://www.mobileone.eu/edeka/start_300_active.png',
      //       inactivePicture: 'https://www.mobileone.eu/edeka/start_300_inactive.png'
      //     }
      //   },
      //   prices: [],
      //   discounts: []
      // }
    ]
  };
  const [allTariffs, setAllTariffs] = useState(defaultState);
  const [bookableTariff, setBookableTariff] = useState(defaultState);

  // Context
  const { onPrepaidTariff, onValidateCode, onVviDocuments, onVviURLDocuments } = useMobileOne();
  // Functions
  // Get bookableTariff from validateSim when component mounts
  const getPrepaidTariffs = async (isNextStep = false) => {
    try {
      const { data, status } = await onPrepaidTariff();
      if (status === 200) {
        // setBookableTariff({ bookableTariffs: data });
        setAllTariffs({ bookableTariffs: data });
        if (isNextStep) {
          nextStep();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onLoad = async () => {};
  const applyMGMCode = async ({ codeForValidation, setErrors, setModal = () => {} }) => {
    try {
      setIsLoading(true);
      if (codeForValidation) {
        const { data } = await onValidateCode({ codeForValidation });
        if (data.valid) {
          const updateTariffs = bookableTariff.bookableTariffs.map((tariff) => {
            tariff.promoCode = codeForValidation;
            const originalDataCount = tariff.additionalInfo.volume.DATA.value;

            // tariff.additionalInfo.volume.DATA.value += data.bonusGb;
            const additionalVolume = originalDataCount + data.bonusGb;

            tariff.operationsInfo.promoCode = codeForValidation;
            tariff.operationsInfo.codeGBVolume = data.bonusGb;

            tariff.operationsInfo.ON_MGM_CODE_APPLY.subHeader =
              tariff.operationsInfo.ON_MGM_CODE_APPLY.subHeader.replace(
                '{{totalDataCount}}',
                `<h2>${additionalVolume}<h2>`
              );

            tariff.operationsInfo.ON_MGM_CODE_APPLY.subHeader =
              tariff.operationsInfo.ON_MGM_CODE_APPLY.subHeader.replace(
                '{{originalDataCount}}',
                originalDataCount
              );

            tariff.operationsInfo.ON_MGM_CODE_APPLY.bullets[0].header =
              tariff.operationsInfo.ON_MGM_CODE_APPLY.bullets[0].header.replace(
                '{{originalDataCount}}',
                originalDataCount
              );

            tariff.operationsInfo.ON_MGM_CODE_APPLY.bullets[0].header =
              tariff.operationsInfo.ON_MGM_CODE_APPLY.bullets[0].header.replace(
                '{{totalDataCount}}',
                additionalVolume // tariff.additionalInfo.volume.DATA.value
              );
            return tariff;
          });
          setActiveTariff(updateTariffs.find((ut) => ut.id === activeTariff.id));
          setBookableTariff({
            ...bookableTariff,
            bookableTariffs: updateTariffs
          });
          // Close modal on success
          setIsLoading(false);
          setModal('');
        } else {
          setErrors({ codeForValidation: t('sh_ap_tarif_voucher_err_wrong_code') });
          setIsLoading(false);
        }
      } else {
        setErrors({ codeForValidation: t('sh_ap_tarif_voucher_err_wrong_code') });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  // const getVVIDocuments = async () => {
  //   try {
  //     setIsVviDocLoading(true);
  //     const { data, status } = await onVviDocuments({ tariffId: activeTariff.id, mnp: true });
  //     if (status === 200) {
  //       setVviDocuments(data);
  //     }
  //     setIsVviDocLoading(false);
  //   } catch (error) {
  //     setIsVviDocLoading(false);
  //     console.log(error);
  //   }
  // };

  const getVVIDocuments = async () => {
    try {
      setIsVviDocLoading(true);
      const { data, status } = await onVviURLDocuments({ tariffId: activeTariff.id, mnp: false });
      if (status === 200) {
        setVviDocuments(data);
      }
      setIsVviDocLoading(false);
    } catch (error) {
      setIsVviDocLoading(false);
      console.log(error);
    }
  };

  const onSubmit = async (values) => {
    // console.log(values);
    onFormUpdate({ ...values, activeTariff });
    nextStep();
  };

  const onNewCustomerSubmit = async (values) => {
    if (window && window.utag) {
      appTariff.FLAT_M === values.tariff
        ? window.utag.link(
            UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M.SELECT_TARIFF_ON_CLICK
          )
        : window.utag.link(
            UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L.SELECT_TARIFF_ON_CLICK
          );
    }

    // console.log(values);
    // setCurrentStep(4);
    onFormUpdate({ ...values, activeTariff });
    navigate(appRoute.NEW_ONLINE_CUSTOMER, { state: { ...values, activeTariff } });
  };

  const initiatePOSPurchas = async () => {
    await getPrepaidTariffs();
    onPosPurchase();
    navigate(appRoute.ACTIVATION);
  };
  const initiateOldCustomerPurchase = () => {
    onOldCustomerPurchase();
    navigate(appRoute.ACTIVATION);
  };

  // Hooks
  // Default Hook // No use
  useEffect(() => {
    // getPrepaidTariffs();
  }, []);

  // useEffect(() => {
  //   if (staticContentData && bookableTariff && bookableTariff?.bookableTariffs && !activeTariff) {
  //     bookableTariff.bookableTariffs = bookableTariff.bookableTariffs.map((bt) => {
  //       const scTariff = staticContentData.tariff_share.find((stf) => stf.id === bt.id);
  //       scTariff.duration = bt.duration;
  //       scTariff.durationUnit = bt.durationUnit;
  //       return scTariff;
  //     });
  //     const defaultTariff =
  //       !bookableTariff.msisdn ||
  //       bookableTariff.bookableTariffs[0].name.toLowerCase() === 'allen flat m'
  //         ? bookableTariff.bookableTariffs.find((bt) => bt.defaultTariff)
  //         : bookableTariff.bookableTariffs[0];

  //     setBookableTariff(JSON.parse(JSON.stringify(bookableTariff)));

  //     if (defaultTariff) {
  //       setFormValue({
  //         ...formValue,
  //         tariff: defaultTariff.id
  //       });
  //       setActiveTariff({ ...defaultTariff });
  //     }
  //   }
  // }, [staticContentData]);

  useEffect(() => {
    if (staticContentData && allTariffs && allTariffs.bookableTariffs) {
      allTariffs.bookableTariffs = allTariffs.bookableTariffs.map((bt) => {
        const scTariff = staticContentData.tariff_share.find((stf) => stf.id === bt.id);
        scTariff.duration = bt.duration;
        scTariff.durationUnit = bt.durationUnit;
        return scTariff;
      });
      const defaultTariff =
        !allTariffs.msisdn || allTariffs.bookableTariffs[0].name.toLowerCase() === 'allen flat m'
          ? allTariffs.bookableTariffs.find((bt) => bt.defaultTariff)
          : allTariffs.bookableTariffs[0];

      setBookableTariff(JSON.parse(JSON.stringify(allTariffs)));

      if (defaultTariff && !activeTariff) {
        setFormValue({
          ...formValue,
          tariff: defaultTariff.id
        });
        setActiveTariff({ ...defaultTariff });
      }
    }
  }, [staticContentData, allTariffs]);

  // Update props on form submit
  useEffect(() => {
    onFormUpdate(formValue);
  }, [formValue]);

  // Get bookableTariff from validateSim;
  // useEffect(() => { }, [validateSim]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // Constants
      initialValue,
      validationSchema,
      validationSchemaMgmCode,

      // States
      isLoading,
      setIsLoading,
      isVviDocLoading,
      setIsVviDocLoading,
      formValue,
      setFormValue,
      bookableTariff,
      setBookableTariff,
      activeTariff,
      setActiveTariff,
      allTariffs,
      setAllTariffs,
      vviDocuments,
      modal,
      setModal,

      // Functions
      onLoad,
      onSubmit,
      applyMGMCode,
      getPrepaidTariffs,
      getVVIDocuments,
      initiatePOSPurchas,
      initiateOldCustomerPurchase,
      onNewCustomerSubmit
    }),
    [
      // Constants
      initialValue,
      validationSchema,
      validationSchemaMgmCode,

      // States
      isLoading,
      setIsLoading,
      isVviDocLoading,
      setIsVviDocLoading,
      formValue,
      setFormValue,
      bookableTariff,
      setBookableTariff,
      activeTariff,
      setActiveTariff,
      allTariffs,
      setAllTariffs,
      vviDocuments,
      modal,
      setModal,

      // Functions
      onLoad,
      onSubmit,
      applyMGMCode,
      getPrepaidTariffs,
      getVVIDocuments,
      initiatePOSPurchas,
      initiateOldCustomerPurchase,
      onNewCustomerSubmit
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <ActivationTariffContext.Provider value={contextPayload}>
      {children}
    </ActivationTariffContext.Provider>
  );
}
ActivationTariffContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useActivationTariff = () => useContext(ActivationTariffContext);

export default ActivationTariffContext;
