import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appAlert, formValidation } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { UTAG_VIEWS } from '@utils/utag';

export const SimValidationContext = createContext({});

export function SimValidationContextProvider({ children, onFormUpdate, onPosPurchase, nextStep }) {
  // Constant
  const initialValue = {
    // iccid  || ICCID
    // PUK
    iccid: '',
    puk: ''
  };

  // Static content Context
  const { t } = useStaticContent();

  // Validations
  const validationSchema = Yup.object().shape({
    iccid: formValidation({
      required: t('sh_apoc_activation_err_no_sim'),
      regex: /^\d{5,20}$/,
      validErrorMessage: t('sh_apoc_activation_err_wrong_sim')
    }),
    puk: formValidation({
      required: t('sh_apoc_activation_err_no_puk'),
      regex: /^\d{4,8}$/,
      validErrorMessage: t('sh_apoc_activation_err_wrong_puk')
    })
  });

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(initialValue);

  // Context
  // MO Context
  const { onValidateSim, onPrepaidTariff } = useMobileOne();
  const { showAlert } = useAlert();
  const { setBookableTariff } = useActivationTariff();

  // Functions
  const onLoad = async () => {};

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      setFormValue({ ...values, iccid: values.iccid.replaceAll(' ', '') });
      await onValidateSim({ ...values, iccid: values.iccid.replaceAll(' ', '') })
        .then(async (res) => {
          if (res.status === 200) {
            res.data.msisdn = !res.data.msisdn ? 'msisdn' : res.data.msisdn;
            if (res.data.bookableTariffs[0].name === 'allnet flat m') {
              const { data } = await onPrepaidTariff();
              setBookableTariff({
                msisdn: res.data.msisdn,
                puk: res.data.puk,
                bookableTariffs: data
              });
            } else {
              setBookableTariff(res.data);
            }
            nextStep();
          }
        })
        .catch((error) => {
          // For testing purpose set static response when api gets code other then 200 and hide error alert
          // onPosPurchase();
          // setIsOldCustomer(true);
          window.utag.view(UTAG_VIEWS.POS_CUSTOMER_MOUNTING.SIM_ACTIVATION_ERROR);
          showAlert({
            type: appAlert.ERROR,
            message: t('sh_ap_activation_error-msg') // `Error in request. ${error.error[0].messageBody}`
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
      return true;
    } catch (error) {
      setIsLoading(false);
      showAlert({
        type: appAlert.ERROR,
        message: t('sh_ap_activation_error-msg') // `Error in request. ${error.error[0].messageBody}`
      });
      return false;
    }
  };
  const validateForm = (values) => {
    const errors = {};
    if (!values.iccid) {
      errors.iccid = t('sh_ap_activation_err_no_sim');
    }
    if (!values.puk) {
      errors.puk = t('sh_ap_activation_err_no_puk');
    }
    if (values.iccid && !/^[0-9\s]{20,24}$/.test(values.iccid)) {
      errors.iccid = t('sh_apoc_activation_error-msg');
    }
    if (values.puk && !/^\d{4,8}$/.test(values.puk)) {
      errors.puk = t('sh_apoc_activation_error-msg');
    }
    return errors;
  };

  // Call /validate-sim
  // Store response in setValidateSim

  // Hooks
  // Default Hook // No use
  useEffect(() => {}, []);

  // Update props on form submit
  useEffect(() => {
    onFormUpdate(formValue);
  }, [formValue]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // Constants
      initialValue,
      formValidation,
      validateForm,
      // States

      isLoading,
      setIsLoading,
      formValue,
      setFormValue,

      // Functions
      onLoad,
      onSubmit
    }),
    [
      // Constants
      initialValue,
      validationSchema,
      validateForm,
      // States

      isLoading,
      setIsLoading,
      formValue,
      setFormValue,

      // Functions
      onLoad,
      onSubmit
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <SimValidationContext.Provider value={contextPayload}>{children}</SimValidationContext.Provider>
  );
}
SimValidationContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useSimValidation = () => useContext(SimValidationContext);

export default SimValidationContext;
