import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  Routes,
  Route,
  useSearchParams,
  useLocation,
  useNavigate,
  Navigate
} from 'react-router-dom';

import {
  Test,
  Home,
  Login,
  ForgotPassword,
  Activation,
  Account,
  PageNotFound,
  Charge,
  PaymentCallBack,
  DashBoard,
  CancelContract,
  CreditDetails,
  Recharge,
  UsageDetails,
  DownloadApp,
  TopupCredit,
  Privacy,
  CancellationRights,
  MandatoryDisclosure,
  Sharing,
  WhyShareMobile,
  Maintenance,
  GenericGreetings,
  ActivateSimCard,
  LegitimacySuccess,
  LegitimacyFailed,
  Contact,
  Giveaway,
  Roaming,
  AGB,
  Imprint,
  CustomerInformation,
  FAQ,
  FaqTopic,
  FaqDetails,
  HelpAndServices,
  ActivationWelcome,
  NewOnlineCustomer
} from '@page/index';

import { withLayout } from '@part/Layout';
import {
  appRoute,
  appAlert,
  storageKeys,
  appDynamicScripts,
  appQueryParams,
  appStorage
} from '@utils/globalConstant';

import { useConfig } from '@config/ContextManager';
import { useAppConfig } from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useAlert } from '@context/Utils';

import {
  BookDataPass,
  BookOption,
  BookRoaming,
  BookSpeedOn,
  BookPassOffers
} from '@page/TariffOption';
import { ReferFriend } from '@part/ReferFriend';
import { Tariff, ChangeTariff, Evn, SimCardESim } from '@page/TariffOption/Tariff';
import { scriptLoader } from '@utils/scriptLoader';
import { PrivateRoute } from './PrivateRoute';
import Test2 from '@page/Test/test2';
import { IdentFailed, IdentPending, IdentSuccess } from '@page/Activation/PostidentStatus';
import { ReturnPolicy } from '@page/AGB/ReturnPolicy';
import { CancelContractSuccess } from '@page/CancelContract/Success';
import Redirect from '@page/Redirect';

export function AllRoutes() {
  const [oauthVerifyLoading, setOauthVerifyLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const { showAlert } = useAlert();
  const { verifyLogin, setUserLogin } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    config: { storage, env: serverConfig }
  } = useConfig();
  const { env } = useAppConfig();

  const getAccess = async (code) => {
    try {
      const codeVerifier = await storage.getItem(appStorage.AUTH_CODE_VERIFIER);

      if (code && codeVerifier) {
        setOauthVerifyLoading(false);

        await storage.encryptedSetItem(appStorage.AUTH_CODE, code);
        // eslint-disable-next-line no-debugger
        const { data } = await verifyLogin(code, codeVerifier);

        // setUserLogin(true);
        await storage.encryptedSetItem(appStorage.AUTH_TOKEN, data.access_token);
        await storage.encryptedSetItem(appStorage.AUTH_REFRESH_TOKEN, data.refresh_token);
        await storage.encryptedSetItem(appStorage.USER_AUTH_DATA, JSON.stringify(data));

        // Clear up url;
        setSearchParams('');
        // Check if user is comming from previous route
        const APP_PREVIOUS_ROUTE_STORAGE = 'navigateTo';
        if (localStorage && localStorage.getItem(APP_PREVIOUS_ROUTE_STORAGE)) {
          setTimeout(() => {
            const previousRoute = localStorage.getItem(APP_PREVIOUS_ROUTE_STORAGE);
            localStorage.removeItem(APP_PREVIOUS_ROUTE_STORAGE);
            navigate(previousRoute);
          }, 150);
        }
        showAlert({ type: appAlert.SUCCESS, message: 'Welcome' });
        return true;
      }
      setUserLogin(false);
      setOauthVerifyLoading(false);
      setSearchParams('');
      return false;
    } catch (error) {
      console.log(error);
      setOauthVerifyLoading(false);
      // Show alert and clear up navigation
      showAlert({ type: appAlert.ERROR, message: 'Something went wrong!' });
      setSearchParams('');
      return false;
    }
  };

  const oauthValidate = async () => {
    const accessToken = await storage.encryptedGetItem(appStorage.AUTH_TOKEN);
    const refreshToken = await storage.encryptedGetItem(appStorage.AUTH_REFRESH_TOKEN);
    setUserLogin(accessToken && refreshToken);
    setTimeout(() => {
      setOauthVerifyLoading(false);
    }, 150);
    return accessToken && refreshToken;
  };

  const callbackValidator = async () => {
    try {
      const sessionState = searchParams.get(storageKeys.SESSION_STATE);
      const code = searchParams.get(storageKeys.CODE);

      // eslint-disable-next-line no-debugger
      if (code && sessionState) {
        await storage.encryptedSetItem(storageKeys.CODE, code);
        await storage.encryptedSetItem(storageKeys.SESSION_STATE, sessionState);
        await getAccess(code);
      } else {
        await oauthValidate();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const affiliationValidator = async () => {
    let params = Object.fromEntries(searchParams.entries());

    const sp = searchParams.get(appQueryParams.AFFILIATE.PARTNER_ID);
    const spfr = searchParams.get(appQueryParams.AFFILIATE.SPEICAL_REPORT_ID);
    const clickref = searchParams.get(appQueryParams.AFFILIATE.CLICK_REF);
    const cgm = searchParams.get(appQueryParams.AFFILIATE.CGM);
    const dclid = searchParams.get(appQueryParams.AFFILIATE.DC_LID);
    const gclid = searchParams.get(appQueryParams.AFFILIATE.GC_LID);

    const SORT_ORDER = ['sp', 'spfr', 'clickref', 'cgm', 'dclid', 'gclid'];

    if (sp || spfr || clickref || cgm || dclid || gclid) {
      const extraParams = (Object.keys(params) || []).filter(
        (key) => !SORT_ORDER.find((so_key) => so_key.toLowerCase() === `${key}`)
      );
      let sortedObjects = {
        sp,
        spfr,
        clickref,
        cgm,
        dclid,
        gclid
      };
      if (extraParams && extraParams.length > 0) {
        extraParams.forEach((key) => {
          sortedObjects = { ...sortedObjects, [key]: params[key] };
        });
      }

      let uparams = new URLSearchParams(sortedObjects).toString();
      if (`${uparams}`.length > env.REACT_APP_PROVISION_INFO_MAX_LENGTH) {
        uparams = uparams.substring(0, env.REACT_APP_PROVISION_INFO_MAX_LENGTH);
      }

      localStorage.setItem(appStorage.AFFILIATE_DATA, uparams);
      // setSearchParams('');
    } else {
      // localStorage.removeItem(appStorage.AFFILIATE_DATA);
    }
  };

  // Default Hook
  // Authentication callback
  // Load Dyanamic scripts
  useEffect(() => {
    callbackValidator();
    affiliationValidator();
  }, []);
  useEffect(() => {
    affiliationValidator();
  }, [env]);

  useEffect(() => {
    if (env.REACT_APP_TEALIUM_ACCOUNT || serverConfig.REACT_APP_TEALIUM_ACCOUNT) {
      // Initiate tealium
      // scriptLoader({
      //   src: appDynamicScripts.TEALIUM_UTAG(
      //     env.REACT_APP_TEALIUM_DOMAIN || serverConfig.REACT_APP_TEALIUM_DOMAIN,
      //     env.REACT_APP_TEALIUM_ACCOUNT || serverConfig.REACT_APP_TEALIUM_ACCOUNT,
      //     env.REACT_APP_TEALIUM_PROFILE || serverConfig.REACT_APP_TEALIUM_PROFILE,
      //     env.REACT_APP_TEALIUM_ENVIRONMENT || serverConfig.REACT_APP_TEALIUM_ENVIRONMENT
      //   ),
      //   appendOnElement: 'tealiumScript',
      //   id: 'tealiumScript'
      // });
    }
  }, [env, serverConfig]);

  // useEffect(() => {
  //   oauthValidate();
  // }, [location]);

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // Optional if you want to skip the scrolling animation
    });
  }, [location.pathname]);

  if (oauthVerifyLoading) {
    return <>Loading...</>;
  }

  return (
    <Routes>
      {/* Payment Return UI Page */}
      <Route path={appRoute.PAYMENT_CALLBACK} element={<PaymentCallBack />} />

      {/* Testing UI Page */}
      <Route path={appRoute.TEST} element={<Test />} />

      <Route path={appRoute.TEST2} element={<Test2 />} />

      {/* App Login Page */}
      <Route index path={appRoute.LOGIN} element={<Login />} />

      {/* App Forgot Password */}
      <Route index path={appRoute.FORGOT_PASSWORD} element={<ForgotPassword />} />

      {/* App Home Page */}
      <Route index path={appRoute.HOME} element={<Home />} />

      {/* App SIM Activation Page */}
      <Route index path={appRoute.NEW_ONLINE_CUSTOMER} element={<NewOnlineCustomer />} />

      {/* App SIM Activation Page */}
      <Route index path={appRoute.ACTIVATION} element={<Activation />} />

      {/* Cancel Contract Page */}
      <Route index path={appRoute.CANCEL_CONTRACT} element={<CancelContract />} />

      {/* Cancel Contract Success Page */}
      <Route index path={appRoute.CANCEL_CONTRACT_SUCCESS} element={<CancelContractSuccess />} />

      {/* App Download Screen */}
      <Route index path={appRoute.DOWNLOAD_APP} element={<DownloadApp />} />

      {/* Topup Credit Screen */}
      <Route index path={appRoute.TOPUP_CREDIT} element={<TopupCredit />} />

      {/* Privacy Screen */}
      <Route index path={appRoute.PRIVACY} element={<Privacy />} />

      {/* Cancellation Rights Screen */}
      <Route index path={appRoute.CANCELLATION_RIGHTS} element={<CancellationRights />} />

      {/* Mandatory Disclosure Screen */}
      <Route index path={appRoute.MANDATORY_DISCLOSURE} element={<MandatoryDisclosure />} />

      {/* Sharing Screen */}
      <Route index path={appRoute.SHARING} element={<Sharing />} />

      {/* Why Share Mobile Screen */}
      <Route index path={appRoute.WHY_SHARE_MOBILE} element={<WhyShareMobile />} />

      {/* MPS-Maintenance Screen */}
      <Route index path={appRoute.MAINTENANCE} element={<Maintenance />} />

      {/* Generic Grettings Screen */}
      <Route index path={appRoute.GENERIC_GREETINGS} element={<GenericGreetings />} />

      {/* Activate Sim Card Screen */}
      <Route index path={appRoute.ACTIVATE_SIM_CARD} element={<ActivateSimCard />} />

      {/* Activation Sim Card Welcome */}
      <Route index path={appRoute.ACTIVATION_WELCOME} element={<ActivationWelcome />} />

      {/* Legitimacy Success Screen */}
      <Route index path={appRoute.LEGITIMACY_SUCCESS} element={<LegitimacySuccess />} />

      {/* Legitimacy Failed Screen */}
      <Route index path={appRoute.LEGITIMACY_FAILED} element={<LegitimacyFailed />} />

      {/* Contact Screen */}
      <Route index path={appRoute.CONTACT} element={<Contact />} />

      {/* Digital Education Givaway Screen */}
      <Route index path={appRoute.GIVEAWAY} element={<Giveaway />} />

      {/* Roaming Screen */}
      <Route index path={appRoute.ROAMING} element={<Roaming />} />

      {/* AGB Screen */}
      <Route index path={appRoute.AGB} element={<AGB />} />

      {/* AGB Instructions Screen */}
      <Route index path={appRoute.AGB_INSTRUCTIONS} element={<ReturnPolicy />} />

      {/* Imprint Screen */}
      <Route index path={appRoute.IMPRINT} element={<Imprint />} />

      {/* Customer Information Screen */}
      <Route index path={appRoute.CUSOMER_INFORMATION} element={<CustomerInformation />} />

      {/* PostIdent Success Page */}
      <Route index path={appRoute.IDENT_SUCCESS} element={<IdentSuccess />} />

      {/* PostIdent Pending Page */}
      <Route index path={appRoute.IDENT_PENDING} element={<IdentPending />} />

      {/* PostIdent Failed Page */}
      <Route index path={appRoute.IDENT_FAILED} element={<IdentFailed />} />

      {/* PostIdent Failed Page */}
      <Route index path={appRoute.REDIRECT()} element={<Redirect />} />

      {/* Dashboard Page */}
      <Route
        index
        path={appRoute.DASHBOARD}
        element={
          <PrivateRoute>
            <DashBoard />
          </PrivateRoute>
        }
      />

      <Route
        index
        path={appRoute.CREDIT_DETAILS}
        element={
          <PrivateRoute>
            <CreditDetails />
          </PrivateRoute>
        }
      />

      <Route
        index
        path={appRoute.RECHARGE}
        element={
          <PrivateRoute>
            <Recharge />
          </PrivateRoute>
        }
      />

      <Route
        index
        path={appRoute.USAGE_DETAILS}
        element={
          <PrivateRoute>
            <UsageDetails />
          </PrivateRoute>
        }
      />

      {/* Tariff & Option Page */}
      <Route
        index
        path={appRoute.TARIFF_OPTION}
        element={
          <PrivateRoute>
            <Tariff />
          </PrivateRoute>
        }
      />

      {/* Tariff Change Page */}
      <Route
        index
        path={appRoute.TARIFF_CHANGE}
        element={
          <PrivateRoute>
            <ChangeTariff />
          </PrivateRoute>
        }
      />

      {/* Option Book Page */}
      <Route
        index
        path={appRoute.OPTION_BOOK()}
        element={
          <PrivateRoute>
            <BookOption />
          </PrivateRoute>
        }
      />

      {/* SpeedOn Book Page */}
      <Route
        index
        path={appRoute.SPEEDON_BOOK()}
        element={
          <PrivateRoute>
            <BookSpeedOn />
          </PrivateRoute>
        }
      />

      {/* PassOffer Book Page */}
      <Route
        index
        path={appRoute.PASSOFFER_BOOK()}
        element={
          <PrivateRoute>
            <BookPassOffers />
          </PrivateRoute>
        }
      />

      {/* DataPass Book Page */}
      <Route
        index
        path={appRoute.DATAPASS_BOOK()}
        element={
          <PrivateRoute>
            <BookDataPass />
          </PrivateRoute>
        }
      />

      {/* Roaming Book Page */}
      <Route
        index
        path={appRoute.ROAMING_BOOK()}
        element={
          <PrivateRoute>
            <BookRoaming />
          </PrivateRoute>
        }
      />

      {/* User App Account */}
      <Route
        index
        path={appRoute.USER_ACCOUNT}
        element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        }
      />
      {/* User Charge Account */}
      <Route
        index
        path={appRoute.CHARGES}
        element={
          <PrivateRoute>
            <Charge />
          </PrivateRoute>
        }
      />

      {/* Refer Friend Page */}
      <Route
        index
        path={appRoute.REFER_FRIEND}
        element={
          <PrivateRoute>
            <ReferFriend />
          </PrivateRoute>
        }
      />

      {/* EVN (Einzelverbindungsnachweise) Page */}
      <Route
        index
        path={appRoute.EVN}
        element={
          <PrivateRoute>
            <Evn />
          </PrivateRoute>
        }
      />

      {/* Sim Lock Page */}
      <Route
        index
        path={appRoute.SIM_CARD_E_SIM}
        element={
          <PrivateRoute>
            <SimCardESim />
          </PrivateRoute>
        }
      />

      {/* Public Paths */}
      <Route path={appRoute.FAQ_LIST} element={<FAQ />} />
      <Route path={appRoute.FAQ_TOPIC()} element={<FaqTopic />} />
      <Route path={appRoute.FAQ_DETAILS()} element={<FaqDetails />} />
      <Route path={appRoute.HELP_AND_SERVICE} element={<HelpAndServices />} />
      <Route path={appRoute.NOT_FOUND} element={<PageNotFound />} />
      <Route path={appRoute.NOT_FOUND} element={<PageNotFound />} />
      <Route path="*" element={<Navigate to={appRoute.NOT_FOUND} replace />} />
    </Routes>
  );
}

// AppRoutes.propTypes = {
//   location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired
// };

// export default withRouter(withAlert(withToast(withLayout(AppRoutes))));
export const AppRoutes = withLayout(AllRoutes);
export default AppRoutes;
