import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Modal } from '@core/Utils';

export function OldNumberModal({ setModal }) {
  // Context
  const { t } = useStaticContent();

  return (
    <Modal
      showCloseButton
      closeButtonText="sh_apoc_tarif_overview_tarif-details_cancel"
      onCloseClick={() => {
        setModal('');
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: t('sh_apoc_number_keep-number_bonus_footnote') }} />
    </Modal>
  );
}

export default OldNumberModal;
