import React, { useEffect } from 'react';
import { useTariff } from '@context/MobileOne';
import EvnAllowed from './Allowed';
import EvnNotAllowed from './NotAllowed';

export function Evn() {
  // Context
  const { getEvnLookupSettings, isEvnAllowed } = useTariff();

  // Hooks
  useEffect(() => {
    getEvnLookupSettings();
  }, []);

  return isEvnAllowed ? <EvnAllowed /> : <EvnNotAllowed />;
}

export default Evn;
