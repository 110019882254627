import React from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';

import { useVoucher } from '@context/AlphaComm';
import { Button, Text } from '@core/Inputs';
import { Col, Row } from '@core/Layout';
// import PropTypes from 'prop-types'

export function Voucher() {
  const { isLoading, voucherCodeForm, voucherValidations, onSubmit } = useVoucher();

  return (
    <div className="py-3">
      <hr />
      <h2>Top up credit now</h2>
      <hr />
      <Formik
        initialValues={voucherCodeForm}
        enableReinitialize
        validationSchema={voucherValidations}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={3} lg={3} className="py-3">
                <span>Enter voucher code</span>
              </Col>
              <Col xs={12} md={5} lg={4} className="d-inline-flex align-items-center">
                <Text
                  type="text"
                  id="voucherCode"
                  name="voucherCode"
                  onChange={handleChange}
                  value={values.voucherCode}
                  placeholder="Enter voucherCode address"
                  isValid={touched.voucherCode && !errors.voucherCode}
                  isInvalid={touched.voucherCode && !!errors.voucherCode}
                  onBlur={handleBlur}
                  error={errors.voucherCode}
                />
              </Col>

              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="my-2 py-2 d-flex align-items-center justify-content-between"
              >
                <strong>Type</strong>
                <Form.Check
                  inline
                  label="TDM1"
                  name="type"
                  type="radio"
                  className="m-0"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="TDM1"
                  checked={values.type === 'TDM1'}
                  id="inline-tdm-1"
                />
                <Form.Check
                  inline
                  label="TDM2"
                  name="type"
                  type="radio"
                  className="m-0"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="TDM2"
                  checked={values.type === 'TDM2'}
                  id="inline-tdm-2"
                />
              </Col>
              <Col xs={12} sm={6} md={12} lg={2} className="text-end my-2">
                <Button variant="primary" type="submit" isLoading={isLoading}>
                  Redeem
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}

Voucher.propTypes = {};

export default Voucher;
