import React from 'react';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function LanderZoneModal({ setModal }) {
  // Context
  const { t } = useStaticContent();

  return (
    <Modal
      showCloseButton
      onCloseClick={() => {
        setModal('');
      }}
      header={t('sh_web_roaming_pup1_h1')}
    >
      <div dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup1_text1') }} />
    </Modal>
  );
}

export default LanderZoneModal;
