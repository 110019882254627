import React from 'react';
import { Container } from '@core/Layout';
import { Stepper } from '@part/Stepper';
import { useOnlineTopUp } from '@context/AlphaComm';

import { Load } from './Load';
import { OnlineTopUp, Payment, OnlineTopupPersonalData } from './OnlineTopUp';
// import PropTypes from 'prop-types'

export function Charge() {
  const { currentStep, setCurrentStep } = useOnlineTopUp();

  const stepItems = [
    { title: 'Load', Component: <Load /> },
    { title: 'Selection', Component: <OnlineTopUp /> },
    { title: 'Personal Data', Component: <OnlineTopupPersonalData /> },
    { title: 'Payment', Component: <Payment /> }
  ];
  return (
    <Container>
      <Stepper
        {...{
          currentStep,
          setCurrentStep,
          items: stepItems
        }}
      />
    </Container>
  );
}

Charge.propTypes = {};

export default Charge;

export * from './Voucher';
export * from './OnlineTopUp';
export * from './ChargeHistory';
