import React from 'react';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function LegalModal({ modalValue, setModalValue, setOpenModal }) {
  // Constant
  let header = '';
  let description = <></>;
  let stepHeader = '';

  // Context
  const { t } = useStaticContent();

  switch (modalValue) {
    case 'product_service':
      header = t('sh_apoc_summary_legal-notice_modal1_h');
      description = (
        <div
          className="primary-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_summary_legal-notice_modal1_text') }}
        />
      );
      break;

    case 'contract_data':
      header = t('sh_apoc_summary_legal-notice_modal2_h');
      description = (
        <div
          className="primary-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_summary_legal-notice_modal2_text') }}
        />
      );
      break;

    case 'useage_data':
      header = t('sh_apoc_summary_legal-notice_modal3_h');
      description = (
        <div
          className="primary-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_summary_legal-notice_modal3_text') }}
        />
      );
      break;

    case 'terminal_data':
      header = t('sh_apoc_summary_legal-notice_modal4_h');
      description = (
        <div
          className="primary-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_summary_legal-notice_modal4_text') }}
        />
      );
      break;

    case 'withdrawal_modal':
      stepHeader = t('sh_Verbraucher_info-service');
      header = t('sh_Verbraucher_info_h8');
      description = (
        <div
          className="primary-color-text"
          dangerouslySetInnerHTML={{ __html: t('sh_widerrufsrecht_text8') }}
        />
      );
      break;

    default:
      break;
  }

  return (
    <Modal
      showCloseButton
      onCloseClick={() => {
        setModalValue('');
        setOpenModal(false);
      }}
      closeButtonText="sh_apoc_tarif_overview_tarif-details_cancel"
      stepHeader={stepHeader}
      header={header}
      description={description}
    />
  );
}

export default LegalModal;
