import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { getFaqLinkId } from '@utils/globalConstant';

export function Accordion({ items, moreItems }) {
  // Context
  const { t } = useStaticContent();
  // State
  const [accordionItems, setAccordionItems] = useState([]);
  const [showFullList, setShowFullList] = useState(false);
  const [openItem, setOpenItem] = useState(-1);

  // Hooks
  useEffect(() => {
    if (items && !showFullList) {
      setAccordionItems(items);
    }

    if (items && showFullList) {
      setAccordionItems(moreItems);
    }

    return () => {
      setAccordionItems([]);
    };
  }, [items, showFullList]);

  // Functions
  const toggle = (index, title) => {
    if (openItem === index) {
      setOpenItem(-1);
    } else {
      setOpenItem(index);
      if (window && window.utag) {
        window.utag.link({ link_id: getFaqLinkId(title) });
      }
    }
  };
  return (
    <>
      <div className="accordion accordion-flush mt-lg-5 mb-0" id="accordionFlushExample">
        {accordionItems.map((block, blockIndex) => (
          <div
            className={`accordion-item border-primary bg-transparent ${
              blockIndex === items.length - 1 && 'border-bottom'
            }`}
            key={`${blockIndex + 1}`}
          >
            <div id={`flush-heading${blockIndex}`}>
              <button
                className={`d-flex justify-content-between accordion-button body-large-bold shadow-none bg-transparent primary-color-text px-0 py-4 ${
                  blockIndex === openItem ? '' : 'collapsed'
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#${blockIndex}-accordion`}
                aria-expanded={blockIndex === openItem}
                aria-controls={`${blockIndex}-accordion`}
                onClick={() => toggle(blockIndex, block.title)}
              >
                <h3
                  className="accordion-heading button-text"
                  dangerouslySetInnerHTML={{ __html: block.title }}
                />
                <i className="fa fa-angle-down fa-lg purple-light-color-text" aria-hidden="true" />
                <i className="fa fa-angle-up fa-lg purple-light-color-text" aria-hidden="true" />
              </button>
            </div>
            <div
              id={`${blockIndex}-accordion`}
              className={`accordion-collapse collapse ${blockIndex === openItem ? 'show' : ''}`}
              aria-labelledby={`flush-heading${blockIndex}`}
              data-bs-parent="#accordionFlushExample"
            >
              {block.component && (
                <div
                  className="accordion-body body-large-regular primary-color-text px-0 pt-0"
                  dangerouslySetInnerHTML={{ __html: block.component }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {moreItems.length > 0 && !showFullList && (
        <button
          className="btn btn-secondary w-auto mt-5 px-5"
          onClick={() => setShowFullList(true)}
        >
          {t('sh_web_faq_detail_text')} ({moreItems.length - 5})
        </button>
      )}
    </>
  );
}

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired
    })
  ).isRequired,
  moreItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired
    })
  )
};

Accordion.defaultProps = {
  moreItems: []
};

export default Accordion;
