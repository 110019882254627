import React, { useState } from 'react';
import { Col, Row } from '@core/Layout';
import { Switch } from '@core/Inputs';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { useTariff } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';

export function Terms({ handleChange, errors, touched }) {
  const { t } = useStaticContent();
  const [openDoc, setOpenDoc] = useState(false);
  const { changeTariff, setChangeTariff } = useTariff();
  const { customerBalance } = useDashBoard();
  return (
    <>
      <hr />
      <Row>
        <Col xs={12}>
          <b>{t('sh_sm_switch_summary_subheadline4')}</b>
        </Col>
        <Col xs={9}>
          <p dangerouslySetInnerHTML={{ __html: t('sh_sm_switch_summary_text4') }} />
        </Col>
        <Col xs={3}>
          <Switch
            id="termsAndConditions"
            name="termsAndConditions"
            isDefaultChecked={changeTariff.termsAndConditions}
            onChange={() => {
              handleChange({
                target: {
                  id: 'termsAndConditions',
                  name: 'termsAndConditions',
                  value: !changeTariff.termsAndConditions
                }
              });
              setChangeTariff({
                ...changeTariff,
                termsAndConditions: !changeTariff.termsAndConditions
              });
            }}
            isValid={touched.termsAndConditions && !errors.termsAndConditions}
            isInvalid={!!touched.termsAndConditions && !!errors.termsAndConditions}
            error={
              touched.termsAndConditions && errors.termsAndConditions
                ? errors.termsAndConditions
                : null
            }
          />
        </Col>
      </Row>
      <hr />

      <Row>
        <Col xs={12} className="d-flex align-items-center justify-content-flex-start">
          <p dangerouslySetInnerHTML={{ __html: t('sh_sm_switch_summary_text5') }} />
          {/* eslint-disable */}
          <span role="button" onClick={() => setOpenDoc(!openDoc)} style={{ paddingLeft: '2rem' }}>
            {' '}
            <i
              className={!openDoc ? 'bi bi-chevron-down' : 'bi bi-chevron-up'}
              style={{ fontWeight: 'bold' }}
            />{' '}
          </span>
        </Col>
        {openDoc && (
          <Col md={12}>
            <ListGroup>
              {[1, 2, 3, 4].map((bl) => (
                <ListGroupItem key={bl}>
                  <a
                    href={'https://spreadspace-cms-whitelabel.d.dom.de/api/media/99'}
                    target="_blank"
                  >
                    Document {bl}
                  </a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        )}
      </Row>
      <hr />
      <Row>
        <Col xs={9}>
          <p dangerouslySetInnerHTML={{ __html: t('sh_apoc_summary_legal-notice_text3') }} />
        </Col>
        <Col xs={3}>
          <Switch
            name="acceptedCreditCheck"
            id="acceptedCreditCheck"
            isDefaultChecked={changeTariff.acceptedCreditCheck}
            onChange={() => {
              handleChange({
                target: {
                  id: 'acceptedCreditCheck',
                  name: 'acceptedCreditCheck',
                  value: !changeTariff.acceptedCreditCheck
                }
              });
              setChangeTariff({
                ...changeTariff,
                acceptedCreditCheck: !changeTariff.acceptedCreditCheck
              });
            }}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={12}>
          <h3>{t('sh_sm_switch_summary_subheadline5')}</h3>
        </Col>
        <Col
          md={12}
          className="d-flex align-items-baseline justify-content-between border-bottom border-secondary mt-2"
        >
          <p>{changeTariff.name}</p>
          <b>{changeTariff.starterPackPrice} &euro;</b>
        </Col>
        <Col
          md={12}
          className={`d-flex align-items-center justify-content-between border-bottom border-secondary mt-2}`}
        >
          <p>Static content required</p>
          <b>0,00 &euro;</b>
        </Col>
        <Col md={12} className={`d-flex align-items-center justify-content-between mt-2`}>
          <p>{t('sh_sm_switch_summary_user_credit')}</p>
          <b
            className={`${
              customerBalance.totalBalance - changeTariff.starterPackPrice < 0 ? 'text-danger' : ''
            }`}
          >
            {customerBalance.totalBalance} &euro;
          </b>
        </Col>
      </Row>
    </>
  );
}

Terms.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({})
};
Terms.defaultProps = {
  handleBlur: () => {},
  touched: () => {},
  errors: {}
};
export default Terms;
