import React from 'react';
import PropTypes from 'prop-types';

export function CardFooter({ sx, children }) {
  return (
    <div className="card-footer" {...{ style: sx }}>
      {children}
    </div>
  );
}

CardFooter.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

CardFooter.defaultProps = {
  sx: {}
};

export default CardFooter;
