import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useTariff } from '@context/MobileOne';
import { Col, Row } from '@core/Layout';
import { QrCode } from '@core/Utils';
import { appImages } from '@utils/globalConstant';

export function ESimAndroid() {
  // States
  // Contexts
  const { t } = useStaticContent();
  const { simData } = useTariff();
  // Functions
  // Hooks

  return (
    <>
      <Row>
        <Col xs={12}>
          <p dangerouslySetInnerHTML={{ __html: t('sh_tab_two_sim_card_esim_dropdown4_text1') }} />
          {/* <br /> */}
          <b
            dangerouslySetInnerHTML={{ __html: t('sh_tab_two_sim_card_esim_dropdown4_subheader1') }}
          />
          <p dangerouslySetInnerHTML={{ __html: t('sh_tab_two_sim_card_esim_dropdown4_text2') }} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <img className="mx-100 grow" src={appImages.qrcode} alt="QR code" />
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <b
          dangerouslySetInnerHTML={{ __html: t('sh_tab_two_sim_card_esim_dropdown4_subheader2') }}
        />
        <p dangerouslySetInnerHTML={{ __html: t('sh_tab_two_sim_card_esim_dropdown4_text3') }} />
        <Col xs={6} className="d-flex align-items-start flex-column">
          <br />
          <p>
            <strong>{t('sh_tab_two_sim_card_esim_dropdown4_sm_dp_adress')}</strong>
          </p>
          <p>
            <strong>{t('sh_tab_two_sim_card_esim_dropdown4_ac')}</strong>
          </p>
        </Col>
        <Col xs={6} className="d-flex align-items-end flex-column">
          <br />
          <p>{simData.activationCodePart1 ? simData.activationCodePart1 : 'null'}</p>
          <p>{simData.activationCodePart2 ? simData.activationCodePart2 : 'null'}</p>
        </Col>
      </Row>
    </>
  );
}

export default ESimAndroid;
