import React from 'react';
import PropTypes from 'prop-types';

export function CardImg({ sx, ...props }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <img className="card-img-top" {...{ style: sx, ...props }} />;
}

CardImg.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

CardImg.defaultProps = {
  sx: {}
};

export default CardImg;
