import React from 'react';
import { Col, Row } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Form, Formik } from 'formik';
import { FormCheck, FormControl } from 'react-bootstrap';
import { Button, Link, Switch } from '@core/Inputs';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { useCustomer } from '@context/MobileOne';

export function SofortOptions() {
  // Context
  const { t } = useStaticContent();
  const { validationSchemaSofort, formValue, setFormValue, onSubmit, isLoading } = useDashBoard();
  const { personalData } = useCustomer();

  // State

  // Function
  const initiatePayment = (sofortOption) => {
    setFormValue({
      ...formValue,
      sofortOption,
      isPaymentInitiated: true
    });
    localStorage.setItem('recharge-options', JSON.stringify({ ...formValue, sofortOption }));
  };
  return (
    <Row>
      <Col md={4}>
        <Button
          variant="link"
          onClick={() =>
            setFormValue({
              ...formValue,
              creditOption: '',
              sofortOption: '',
              isSofortSelected: false
            })
          }
        >
          <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
        </Button>
      </Col>
      <Col md={4}>
        <h3>{t('sh_db_charge_balance_h1')}</h3>
        <Formik
          initialValues={{ ...formValue }}
          validationSchema={validationSchemaSofort}
          onSubmit={onSubmit}
        >
          {({
            values: { sofortOption, isSofortSelected },
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <h6>{t('sh_db_charge_balance_amount')}</h6>
                <Col className="m-2">
                  {[15, 30, 50].map((opt, index) => (
                    <FormCheck
                      label={t(`sh_db_charge_balance_amount${index + 1}`)}
                      name="sofortOption"
                      type="radio"
                      className="m-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={opt}
                      checked={Number(sofortOption) === opt}
                    />
                  ))}
                  <FormControl.Feedback type="invalid" className="d-block">
                    {errors.sofortOption}
                  </FormControl.Feedback>
                  <hr />
                  <p>
                    {' '}
                    {t('sh_db_charge_balance_info1')
                      .replace('{{amount}}', sofortOption)
                      .replace('{{email}}', personalData.email.emailAddress)}
                  </p>
                  <Link to="/">{t('sh_db_charge_balance_pay_options')}</Link>
                  <hr />
                  <div className="d-flex justify-content-between w-100">
                    <span dangerouslySetInnerHTML={{ __html: t('sh_db_charge_balance_agb') }} />

                    <Switch
                      id="isSofortSelected"
                      name="isSofortSelected"
                      isDefaultChecked={isSofortSelected}
                      onChange={() => {
                        handleChange({
                          target: {
                            id: 'isSofortSelected',
                            name: 'isSofortSelected',
                            value: !isSofortSelected
                          }
                        });
                      }}
                      isValid={touched.isSofortSelected && !errors.isSofortSelected}
                      isInvalid={!!touched.isSofortSelected && !!errors.isSofortSelected}
                      error={
                        touched.isSofortSelected && errors.isSofortSelected
                          ? errors.isSofortSelected
                          : null
                      }
                    />
                  </div>
                </Col>
                {!formValue.payment && (
                  <Col xs={12} className="pt-3">
                    <p>Du hast noch keine Zahlungsmethode hinterlegen</p>
                    <Button
                      variant="primary"
                      type="button"
                      isLoading={isLoading}
                      onClick={() => initiatePayment(sofortOption)}
                      disabled={isSofortSelected === false}
                    >
                      Zahlungsmethode hinterlegen
                    </Button>
                  </Col>
                )}
                <Col xs={12} className="pt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    isLoading={isLoading}
                    disabled={isSofortSelected === false}
                  >
                    {t('sh_db_charge_balance_confirm_button').replace('{{amount}}', sofortOption)}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}

export default SofortOptions;
