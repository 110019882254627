import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useLayout } from '@context/Utils';
import { Button } from '@core/Inputs';
import { appButtonType, appImages, appRoute } from '@utils/globalConstant';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_LINK, UTAG_VIEWS } from '@utils/utag';

import './style.scss';
import { Image } from '@core/Utils';

export function GenericGreetings() {
  // States

  // Contexts
  const { t, staticContentData } = useStaticContent();
  const { setLightHeader } = useLayout();
  const { setMetaTags } = useMetaTag();
  const navigate = useNavigate();

  //  Hooks
  useEffect(() => {
    setLightHeader(true);

    return () => {
      setLightHeader(false);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.GENERIC_GREETINGS_MOUNTING);
    }
  }, [window.utag]);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_project_meta_title'),
      description: t('sh_web_page_project_meta_description'),

      twitterTitle: t('sh_web_page_project_meta_title'),
      twitterDescription: t('sh_web_page_project_meta_description'),

      ogTitle: t('sh_web_page_project_meta_title'),
      ogDescription: t('sh_web_page_project_meta_description')
    });
    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  return (
    <>
      {/* <section className="project-top-banner bg-primary-color project-purple-shade-1 position-relative pt-10 pb-5"> */}
      <section className="project-top-banner bg-primary-color position-relative pt-10 pb-5">
        <div className="container mb-0 mb-sm-3 mb-lg-0">
          <div className="row position-relative mt-0 mt-xl-5">
            {/* <div className="col-12 orange-gray-bubble"></div> */}
            <div className="col-xl-7 offset-xl-1 col-lg-8 col-md-8 offset-md-1 pt-10 pb-sm-5">
              <h1
                className="pink-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_h1') }}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="project-btm-banner project-image-shade-1 position-relative"> */}
      <section className="project-btm-banner position-relative">
        <div>
          {/* <img
            className="mx-100 w-100 d-none d-sm-block d-xl-block"
            src={appImages.project_btmbanner}
            alt="Banner"
          />
          <img
            className="mx-100 w-100 d-block d-sm-none d-xl-none"
            src={appImages.project_btmbannermobile}
            alt="Banner Mobile"
          /> */}
          <Image
            className="mx-100 w-100"
            refs="sh_web_lg_project_bg-1"
            resRefs="sh_web_md_project_bg-1"
            alt="Banner Mobile"
            defaultStyle={{ height: 'auto', width: '' }}
            /* ipadStyle={{ height: '410', width: '' }}
            mobileStyle={{ height: '325', width: '' }} */
          />
        </div>
      </section>

      {/* <section className="project-digital-pltform mb-0 mb-md-5 mt-5 mt-sm-4 mt-xl-0"> */}
      <section className="project-digital-pltform py-56 py-lg-80">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-10 offset-md-1 py-0 py-sm-5 mb-5"> */}
            <div className="col-md-10 offset-md-1">
              <h2
                className="primary-color-text mb-3"
                dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_headline') }}
              />
              <h5
                className="primary-color-text cnt mb-3"
                dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_subheadline') }}
              />
              <p
                className="body-large-regular primary-color-text mb-0 mb-xl-4 pe-0 me-0 pe-lg-5 me-lg-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_text') }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="project-share-details position-relative bg-offWhite-color gray-shade-1 gray-top-shade pb-0 pb-sm-5"> */}
      <section className="project-share-details position-relative bg-offWhite-color py-56 py-lg-80">
        {/* <div className="container mb-0 mb-lg-5 py-0 py-md-5"> */}
        <div className="container">
          {/* <div className="row position-relative py-4"> */}
          <div className="row position-relative">
            {/* <div className="orange-white-bubble-2"></div> */}
            {/* <div className="col-xl-4 col-lg-3 col-md-4 col-sm-4 image-block z-1"> */}
            <div className="col-xl-4 col-lg-3 col-md-4 col-sm-4 image-block z-1">
              {/* <img
                className="mx-100 w-100 d-none d-lg-block"
                src={appImages.project_details}
                alt="Project Details"
              /> */}
              {/* <img
                className="mx-100 d-block d-lg-none"
                src={appImages.project_detailsmobile}
                alt="Project Details Mobile"
              /> */}
              <Image
                className="mx-100"
                refs="sh_web_project_content-1"
                alt="Project Details"
                ipadStyle={{ height: '306', width: '224' }}
                mobileStyle={{ height: '314', width: '195' }}
              />
              <span className="d-none grey-color-text label-small px-5 py-3"></span>
            </div>
            <div className="col-xl-6 offset-xl-1 col-lg-8 offset-lg-1 col-md-8 col-sm-8  z-1">
              <div className="d-flex flex-column h-100 justify-content-end">
                <h2
                  className="primary-color-text mt-5 mt-sm-0 mb-3 pe-5 pe-xl-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_bullet_point_1') }}
                />
                <h5
                  className="primary-color-text cnt mb-3"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_bullet_point_2') }}
                />
                <p
                  // className="body-large-regular primary-color-text mb-5 mb-sm-0"
                  className="body-large-regular primary-color-text"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_bullet_point_3') }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="project-help-row my-5"> */}
      <section className="project-help-row py-56 py-lg-80">
        <div className="container mb-0 mb-sm-3 mb-lg-0">
          {/* <div className="row pb-0 pb-sm-5"> */}
          <div className="row">
            {/* <div className="col-xl-8 offset-xl-1 col-md-10 offset-md-1 py-5"> */}
            <div className="col-xl-8 offset-xl-1 col-md-10 offset-md-1">
              <h2
                className="primary-color-text mb-3"
                dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_headline2') }}
              />
              <p
                // className="body-large-regular primary-color-text mb-5 mb-md-0 mb-xl-5"
                className="body-large-regular primary-color-text mb-5 mb-md-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_text2') }}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="project-share-formet-row position-relative bg-offWhite-color gray-shade-1 project-share-formet-gray-shade-2 pb-0 pb-sm-5 mb-5 mb-sm-0"> */}
      <section className="project-share-formet-row position-relative bg-offWhite-color py-56 py-lg-80">
        <div className="container">
          {/* <div className="row py-5 my-0 my-xl-5"> */}
          <div className="row">
            <div className="col-12 position-relative d-block d-lg-none mb-5">
              <div className="share-project-img position-absolute d-flex justify-content-end p-0">
                <Image
                  className="mx-100"
                  refs="sh_web_lg_project_content-2"
                  resRefs="sh_web_md_project_content-2"
                  alt="Share Project Mobile app"
                  ipadStyle={{ height: '346', width: '360' }}
                  mobileStyle={{ height: '264', width: '275' }}
                />
              </div>
            </div>
            <div className="col-xl-6 offset-xl-1 col-lg-5 offset-lg-1 col-sm-6 pt-5 pb-0 pb-lg-5 mt-5 mt-sm-0">
              <h2
                className="primary-color-text mb-3"
                dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_headline3') }}
              />
              <h5
                className="primary-color-text cnt mb-3"
                dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_subheadline3') }}
              />
              <p
                className="primary-color-text body-large-regular mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_project_page_text3') }}
              />
              <Button
                buttonType={appButtonType.SECONDARY}
                className="heading-small w-50 d-flex align-items-center justify-content-center mb-3"
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.GENERIC_GREETINGS_ON_CLICK.FAQ_ON_CLICK);
                  }
                  window.open(appRoute.FAQ_LIST, '_blank', 'noreferrer');
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('sh_web_project_page_headline4_bulletpoint')
                  }}
                />
              </Button>
              <Button
                buttonType={appButtonType.OUTLINE_SECONDARY}
                className="heading-small w-50 d-flex align-items-center justify-content-center mb-3"
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(
                      UTAG_LINK.GENERIC_GREETINGS_ON_CLICK.MORE_ABOUT_SHARE_ON_CLICK
                    );
                  }
                  window.open(appRoute.REDIRECT('https://share.eu/about'), '_blank', 'noreferrer');
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('sh_web_project_page_headline4_bulletpoint1')
                  }}
                />
              </Button>
            </div>
          </div>
        </div>
        {/* <div className="position-absolute d-none d-lg-block z-1 top-0 end-0  mt-5 pt-3"> */}
        <div className="position-absolute d-none d-lg-block z-1 bottom-0 end-0  mt-5 pt-3">
          {/* <img className="mx-100" src={appImages.project_phone_d} alt="Share Project Mobile app" /> */}
          <Image
            className="mx-100"
            refs="sh_web_lg_project_content-2"
            resRefs="sh_web_md_project_content-2"
            alt="Share Project Mobile app"
          />
        </div>
      </section>
    </>
  );
}

export default GenericGreetings;
