/* eslint-disable */
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { simLockStatus, simLockStatusTitle } from '@dom-digital-online-media/dom-app-config-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { appAlert, appRoute, appStorage, formValidation } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
import { useCustomer } from '../Customer';
import { utag, UTAG_LINK } from '@utils/utag';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export const AccountContext = createContext({});

export function AccountContextProvider({ children, config }) {
  // States
  // API Data Storage States
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [domWithProduct, setDomWithProduct] = useState(null);
  const [lockStatus, setLockStatus] = useState();
  const [isCancleContractProcessed, setIsCancleContractProcessed] = useState(false);

  // Context
  const { t } = useStaticContent();
  const { showAlert } = useAlert();
  const {
    onChangeCustomerDataCall,
    onChangePasswordCall,
    onSimLock,
    onUnSimLock,
    onCustomerEmailApproveCall,
    onEmailResendVerificationIsPossibleCall,
    onEmailResendVerificationCall,
    onCancelContractCall
  } = useMobileOne();
  const { customerData, personalData, customerProducts, getCustomerData } = useCustomer();
  const { onPasswordLogin } = useAuth();
  const navigate = useNavigate();
  // VALIDATION

  const cancelContractInitialValue = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    customerNumber: '',
    phoneNumber: '',
    iban: '',
    cancellationReason: ''
  };

  const cancelContractValidation = yup.object().shape({
    firstName: formValidation({
      required: t('sh_ap_personal_err_wrong_first-name'),
      regex: /^[A-Za-zÄÖÜßäöü-\s]{2,25}$/,
      validErrorMessage: t('sh_apoc_personal_err_wrong_first-name')
    }),
    lastName: formValidation({
      required: t('sh_ap_personal_err_wrong_last-name'),
      regex: /^[A-Za-zÄÖÜßäöü-\s]{2,25}$/,
      validErrorMessage: t('sh_apoc_personal_err_wrong_last-name')
    }),
    emailAddress: formValidation({
      required: t('sh_apoc_email_err_no_email'),
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      validErrorMessage: t('sh_apoc_email_err_wrong_email')
    }),
    customerNumber: formValidation({
      required: t('sh_web_cancel_contract_placeholder4_err_msg'),
      regex: /^\d{5,20}$/,
      validErrorMessage: t('sh_web_cancel_contract_placeholder4_wrong_err_msg')
    }),
    phoneNumber: formValidation({
      required: t('sh_web_cancel_contract_place0holder5_err_msg'),
      regex: /^\d{5,20}$/,
      validErrorMessage: t('sh_web_cancel_contract_place0holder5_wrong_err_msg')
    }),
    iban: formValidation({
      required: t('sh_web_cancel_contract_placeholder6_err_msg')
      // regex: /^[a-zA-Z0-9]{5,20}$/,
      // validErrorMessage: t('sh_web_cancel_contract_placeholder6_err_msg')
    })
  });

  const [cancelContractForm, setCancelContractForm] = useState(cancelContractInitialValue);

  const onCancelConractFormSubmit = async ({
    cancellationReason,
    customerNumber,
    emailAddress,
    firstName,
    iban,
    lastName,
    phoneNumber
  }) => {
    try {
      setIsLoading(true);
      const params = {
        cancellationReason,
        customerNumber,
        emailAddress,
        firstName,
        iban,
        lastName,
        msisdn: phoneNumber
      };

      const res = await onCancelContractCall(params);
      const { data, success } = res;
      if (success) {
        setIsCancleContractProcessed(true);
        showAlert({
          type: appAlert.SUCCESS,
          message: data?.domMessage
        });
        navigate(appRoute.CANCEL_CONTRACT_SUCCESS);
        setIsLoading(false);
        return true;
      } else {
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      setIsLoading(false);
      showAlert({
        type: appAlert.ERROR,
        message: error?.error[0]?.messageBody
      });
    }
  };

  const changeEmailInitialValue = {
    email: ''
  };

  const changeEmailValidation = yup.object().shape({
    email: formValidation({
      required: t('sh_apoc_email_err_wrong_email'),
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      validErrorMessage: t('sh_apoc_email_err_wrong_email')
    })
  });

  const emailCodeInitialValue = {
    emailCode: ''
  };

  const changeEmailCodeValidation = yup.object().shape({
    emailCode: formValidation({
      required: t('sh_apoc_email_err_wrong_code'),
      validErrorMessage: t('sh_apoc_email_err_wrong_code'),
      minLength: 4,
      minLengthError: t('sh_apoc_email_err_wrong_code'),
      maxLength: 4,
      maxLengthError: t('sh_apoc_email_err_wrong_code')
    })
  });

  const [changeEmailForm, setChangeEmailForm] = useState(changeEmailInitialValue);

  const loginInitialValue = {
    msisdn: '',
    password: ''
  };
  const [loginForm, setLoginForm] = useState(loginInitialValue);

  const loginFormValidations = yup.object().shape({
    msisdn: formValidation({
      required: 'please_enter_msidn',
      regex: /^\d{5,20}$/,
      validErrorMessage: 'please_valid_msidn_number'
    }),
    password: formValidation({
      required: 'please_enter_password',
      validErrorMessage: 'please_valid_password'
    })
  });
  const hotlinePasswordInitialValue = {
    pswrdForHtLine: customerData ? customerData.hotlinePassword : ''
  };

  const hotlinePasswordValidation = yup.object().shape({
    pswrdForHtLine: formValidation({
      required: 'please_enter_hotline_password',
      regex: /[(A-Z)|(Ö)|(Ä)|(Ü)|(a-z)|(0-9)|(ü)|(ö)|(ä)|(ß)]{4,20}/,
      validErrorMessage: 'please_valid_hotline_password'
    })
  });

  const [hotlinePasswordForm, setHotlinePasswordForm] = useState(hotlinePasswordInitialValue);

  const changePasswordInitialValue = {
    oldPassword: '',
    newPassword: ''
    // confirmPassword: ''
  };

  const changePasswordValidation = yup.object().shape({
    oldPassword: formValidation({
      required: 'please_enter_old_password',
      regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{8,16}$/,
      validErrorMessage: 'please_valid_old_password'
    }),
    newPassword: formValidation({
      required: 'please_enter_new_password',
      regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{8,16}$/,
      validErrorMessage: 'please_valid_new_password'
    }),
    confirmPassword: formValidation({
      required: 'please_enter_confirm_password',
      regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{8,16}$/,
      validErrorMessage: 'please_valid_confirm_password'
    })
  });

  const [changePasswordForm, setChangePasswordForm] = useState(changePasswordInitialValue);

  const customPasswordSchema = (values) => {
    const errors = {};
    if (!values.oldPassword && !values.newPassword && !values.confirmPassword) {
      errors.oldPassword = 'please_enter_old_password';
      errors.newPassword = 'please_enter_new_password';
      errors.confirmPassword = 'please_enter_confirm_password';
    } else if (values.oldPassword === values.newPassword) {
      errors.newPassword = 'old_password_and_new_password_must_not_be_same';
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'Confirm_Password_new_password_must_be_same';
    }
    return errors;
  };

  const forgotPasswordInitialValue = {
    msisdn: ''
  };

  const forgotPasswordValidation = yup.object().shape({
    msisdn: formValidation({
      required: t('sh_ap_activation_err_no_sim'),
      regex: /^\d{5,20}$/,
      validErrorMessage: t('sh_ap_activation_err_no_sim')
    })
  });

  const [forgotPasswordForm, setForgotPasswordForm] = useState(forgotPasswordInitialValue);

  const [isCodeSend, setCodeSend] = useState(false);
  const [isCodeValid, setCodeValid] = useState(false);

  const otpCodeInitialValue = {
    code_one: '',
    code_two: '',
    code_three: '',
    code_four: '',
    code_five: '',
    code_six: ''
  };

  const otpCodeValidation = yup.object().shape({
    code_one: formValidation({
      type: 'number',
      minLength: 0,
      maxLength: 9,
      required: 'please_enter_otp',
      validErrorMessage: 'please_valid_otp'
    }),
    code_two: formValidation({
      type: 'number',
      minLength: 0,
      maxLength: 9,
      required: 'please_enter_otp',
      validErrorMessage: 'please_valid_otp'
    }),
    code_three: formValidation({
      type: 'number',
      minLength: 0,
      maxLength: 9,
      required: 'please_enter_otp',
      validErrorMessage: 'please_valid_otp'
    }),
    code_four: formValidation({
      type: 'number',
      minLength: 0,
      maxLength: 9,
      required: 'please_enter_otp',
      validErrorMessage: 'please_valid_otp'
    }),
    code_five: formValidation({
      type: 'number',
      minLength: 0,
      maxLength: 9,
      required: 'please_enter_otp',
      validErrorMessage: 'please_valid_otp'
    }),
    code_six: formValidation({
      type: 'number',
      minLength: 0,
      maxLength: 9,
      required: 'please_enter_otp',
      validErrorMessage: 'please_valid_otp'
    })
  });

  const [otpCodeForm, setOtpCodeForm] = useState(otpCodeInitialValue);

  const newPasswordInitialValue = {
    password: ''
  };

  const newPasswordValidation = yup.object().shape({
    password: formValidation({
      required: t('sh_apoc_login_password_info'),
      regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{8,16}$/,
      validErrorMessage: t('sh_apoc_login_password_info')
    })
  });

  const [newPasswordForm, setNewPasswordForm] = useState(newPasswordInitialValue);

  // Functions
  // Personal Data -----------------------------
  const loadPersonalData = () => {
    try {
      setIsLoading(true);
      const { email: { emailAddress = '' } = {} } = personalData;
      if (emailAddress) setEmail(emailAddress);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const login = async ({ msisdn, password }) => {
    setIsLoading(true);
    try {
      if (window && window.utag) {
        window.utag.link(UTAG_LINK.LOGIN_ON_SUBMIT);
      }
      const { data, success } = await onPasswordLogin({ username: msisdn, password });
      if (success) {
        await config.storage.encryptedSetItem(appStorage.AUTH_TOKEN, data.access_token);
        await config.storage.encryptedSetItem(appStorage.AUTH_REFRESH_TOKEN, data.refresh_token);

        navigate(appRoute.DASHBOARD);
        setIsLoading(false);
      }
    } catch (error) {
      showAlert({ type: appAlert.ERROR, message: error?.error[0]?.messageBody });
      setIsLoading(false);
    }
  };
  // Customer Products -------------------------
  const loadCustomerProducts = () => {
    try {
      setIsLoading(true);
      const findFirstProduct = customerProducts.find((a) => a.status && a.status.name);
      const {
        status: { name: simStatus, name = false }
      } = findFirstProduct;
      if (name) {
        if (simStatus === simLockStatus.ACTIVE) {
          setLockStatus(simLockStatus.ACTIVE);
        } else if (simStatus === simLockStatus.SIM_LOCK_INPROGRESS) {
          setLockStatus(simLockStatus.SIM_LOCK_INPROGRESS);
        } else if (simStatus === simLockStatus.SIM_LOCKED) {
          setLockStatus(simLockStatus.SIM_LOCKED);
        } else if (simStatus === simLockStatus.SIM_UNLOCK_REQUESTED) {
          setLockStatus(simLockStatus.SIM_UNLOCK_REQUESTED);
        } else if (simStatus === simLockStatus.ACTIVATION_INPROGRESS) {
          setLockStatus(simLockStatus.ACTIVATION_INPROGRESS);
        } else {
          setLockStatus(simStatus);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // change EMAIl--------------------
  const onChangeEmailSubmit = async (values) => {
    try {
      setChangeEmailForm({ email: values.email });
      setIsLoading(true);
      const { data, success } = await onChangeCustomerDataCall({ emailAddress: values.email });
      if (success) {
        showAlert({ type: appAlert.SUCCESS, message: t('sh_md_change_email_subheadline2') });
      } else {
        showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      setIsLoading(false);
      // await getCustomerData();
      // showAlert({ type: appAlert.SUCCESS, message: 'Email updated' });
      return data;
      // .then((value) => {
      //   console.log('', 'Email changes Successfully')
      // }).catch((err) => {
      //   console.log('', err.error[0].messageBody)
      // })
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: `Error in request. ${error.message}` });
      return false;
    }
  };

  const onVerifyEmail = async (values) => {
    try {
      setIsLoading(true);
      const {
        data: { success, errorMessage }
      } = await onCustomerEmailApproveCall({ hash: values.emailCode });
      if (success) {
        await getCustomerData();
        showAlert({ type: appAlert.WARNING, message: t('sh_md_change_email_success_msg') });
        return data;
      } else {
        showAlert({ type: appAlert.ERROR, message: errorMessage });
      }
      setIsLoading(false);
      // return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: `Error in request. ${error.message}` });
      return false;
    }
  };

  const onResendEmailChangeCode = async () => {
    //TO DO: need to add Resend email code api call Done
    try {
      const { success } = await onEmailResendVerificationIsPossibleCall();

      if (success) {
        await onEmailResendVerificationCall();
        showAlert({ type: appAlert.SUCCESS, message: 'Code resend successfully' });
      } else {
        showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
    } catch (error) {
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: `Error in request. ${error.message}` });
      return false;
    }
  };

  // change hotline password--------------------
  const onHotlinePasswordSubmit = async (values) => {
    try {
      setIsLoading(true);
      const { data, success } = await onChangeCustomerDataCall({
        hotlinePassword: values.pswrdForHtLine
      });
      if (success) {
        showAlert({ type: appAlert.SUCCESS, message: 'Hotline password updated' });
      } else {
        showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      await getCustomerData();
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: `Error in request. ${error.message}` });
      return false;
    }
  };

  // change password--------------------
  const onChangePasswordSubmit = async (values) => {
    try {
      setIsLoading(true);
      const { data, success } = await onChangePasswordCall({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      });
      if (success) {
        showAlert({ type: appAlert.SUCCESS, message: 'Password updated' });
        navigate(appRoute.DASHBOARD);
      } else {
        showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      await getCustomerData();
      setIsLoading(false);
      return data;
      // .then((value) => {
      // }).catch((err) => {
      //   setIsLoading(false)
      //   // console.log('onChangePasswordSubmit error==>', err)
      //   console.log('', err.error[0].messageBody)
      // })
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: `Error in request. ${error.message}` });
      return false;
    }
  };

  // Lock  SIM--------------------
  const lockSimCard = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await onSimLock();
      if (success) {
        showAlert({ type: appAlert.SUCCESS, message: 'Hotline password updated' });
      } else {
        showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      await getCustomerData();
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: `Error in request. ${error.message}` });
      return false;
    }
  };

  // unlock SIM--------------------
  const unLockSimCard = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await onUnSimLock();
      if (success) {
        showAlert({ type: appAlert.SUCCESS, message: 'Hotline password updated' });
      } else {
        showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      await getCustomerData();
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: `Error in request. ${error.message}` });
      return false;
    }
  };

  // handle LockTitleStatus
  const handleLockStatus = () => {
    switch (lockStatus) {
      case simLockStatus.ACTIVE:
        return simLockStatusTitle.ACTIVE;
      case simLockStatus.SIM_LOCK_INPROGRESS:
        return simLockStatusTitle.SIM_LOCK_INPROGRESS;
      case simLockStatus.SIM_LOCKED:
        return simLockStatusTitle.SIM_LOCKED;
      case simLockStatus.SIM_UNLOCK_REQUESTED:
        return simLockStatusTitle.SIM_UNLOCK_REQUESTED;
      default:
        return simLockStatusTitle.DEFAULT;
    }
  };

  // handle LockTitleStatus
  const onSimLockSubmit = () => {
    if (lockStatus !== simLockStatus.ACTIVATION_INPROGRESS) {
      if (handleLockStatus(lockStatus) === simLockStatusTitle.ACTIVE) {
        lockSimCard();
      } else if (handleLockStatus(lockStatus) === simLockStatusTitle.SIM_LOCKED) {
        unLockSimCard();
      }
    }
  };

  // Handle forgot password
  const onForgotPassword = (values) => {
    // console.log(values);
    setCodeSend(true);
  };

  // Validate otp code
  const onValidateCode = (values) => {
    // console.log(values);
    setCodeSend(false);
    setCodeValid(true);
  };

  // Resend otp code
  const onResendCode = (values) => {
    // console.log(values);
    setCodeSend(false);
    setCodeValid(false);
  };

  // Submit new password
  const onSubmitNewPassword = (values) => {
    // console.log(values);
    showAlert({ type: appAlert.WARNING, message: t('sh_login_pw_success_msg') });
    setCodeSend(false);
    setCodeValid(false);
  };

  // Hooks
  // useEffect(()=>{
  //   const { firstName, lastName, ...restPersonalData } = personalData;
  //   if (firstName || email) {
  //     loadPersonalData();
  //   }
  // }, [personalData]);
  useEffect(() => {
    if (customerProducts && customerProducts.length > 0) {
      loadCustomerProducts();
    }
  }, [customerProducts]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      email,
      setEmail,
      lockStatus,
      setLockStatus,
      loginInitialValue,
      loginForm,
      setLoginForm,
      loginFormValidations,
      forgotPasswordInitialValue,
      forgotPasswordForm,
      setForgotPasswordForm,
      forgotPasswordValidation,
      isCodeSend,
      setCodeSend,
      isCodeValid,
      setCodeValid,
      otpCodeValidation,
      otpCodeInitialValue,
      otpCodeForm,
      setOtpCodeForm,
      newPasswordInitialValue,
      newPasswordValidation,
      newPasswordForm,
      setNewPasswordForm,
      isCancleContractProcessed,
      setIsCancleContractProcessed,

      // API calls
      onChangeEmailSubmit,
      onHotlinePasswordSubmit,
      onChangePasswordSubmit,
      lockSimCard,
      unLockSimCard,
      login,

      // Form Initial States & Validations
      cancelContractInitialValue,
      cancelContractValidation,
      cancelContractForm,
      setCancelContractForm,
      changeEmailInitialValue,
      changeEmailValidation,
      changeEmailForm,
      setChangeEmailForm,
      hotlinePasswordInitialValue,
      hotlinePasswordValidation,
      hotlinePasswordForm,
      setHotlinePasswordForm,
      changePasswordInitialValue,
      changePasswordValidation,
      changePasswordForm,
      setChangePasswordForm,
      customPasswordSchema,
      emailCodeInitialValue,
      changeEmailCodeValidation,

      // functions
      handleLockStatus,
      onSimLockSubmit,
      onForgotPassword,
      onResendCode,
      onValidateCode,
      onResendEmailChangeCode,
      onSubmitNewPassword,
      onVerifyEmail,
      onCancelConractFormSubmit
    }),
    [
      // States
      isLoading,
      setIsLoading,
      email,
      setEmail,
      lockStatus,
      setLockStatus,
      loginInitialValue,
      loginForm,
      setLoginForm,
      loginFormValidations,
      forgotPasswordInitialValue,
      forgotPasswordForm,
      setForgotPasswordForm,
      forgotPasswordValidation,
      isCodeSend,
      setCodeSend,
      isCodeValid,
      setCodeValid,
      otpCodeValidation,
      otpCodeInitialValue,
      otpCodeForm,
      setOtpCodeForm,
      newPasswordInitialValue,
      newPasswordValidation,
      newPasswordForm,
      setNewPasswordForm,
      isCancleContractProcessed,
      setIsCancleContractProcessed,

      // API calls
      onChangeEmailSubmit,
      onHotlinePasswordSubmit,
      onChangePasswordSubmit,
      lockSimCard,
      unLockSimCard,
      login,

      // Form Initial States & Validations
      cancelContractInitialValue,
      cancelContractValidation,
      cancelContractForm,
      setCancelContractForm,
      changeEmailInitialValue,
      changeEmailValidation,
      changeEmailForm,
      setChangeEmailForm,
      hotlinePasswordInitialValue,
      hotlinePasswordValidation,
      hotlinePasswordForm,
      setHotlinePasswordForm,

      changePasswordInitialValue,
      changePasswordValidation,
      changePasswordForm,
      setChangePasswordForm,
      customPasswordSchema,
      emailCodeInitialValue,
      changeEmailCodeValidation,

      // functions
      handleLockStatus,
      onSimLockSubmit,
      onForgotPassword,
      onResendCode,
      onValidateCode,
      onResendEmailChangeCode,
      onSubmitNewPassword,
      onVerifyEmail,
      onCancelConractFormSubmit
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <AccountContext.Provider value={contextPayload}>{children}</AccountContext.Provider>;
}

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useAccount = () => useContext(AccountContext);

export default AccountContext;
