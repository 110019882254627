import { Button } from '@core/Inputs';
import { Col, Row } from '@core/Layout';
import { Formik, Form } from 'formik';
import { Spinner as BSpinner } from 'react-bootstrap';

import React from 'react';
import { useOnlineTopUp } from '@context/AlphaComm';
import { PaymentMethod } from '@part/Payment';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
// import PropTypes from 'prop-types'

export function Payment() {
  const { t } = useStaticContent();
  const {
    isLoading,
    prevStep,
    nextStep,
    onStepFourSubmit,
    paymentMethod,
    paymentMethodForm,
    paymentValidations
  } = useOnlineTopUp();
  return (
    <>
      <div className="position-relative">
        {isLoading && (
          <div className="opacity-75 position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
            <BSpinner size="xl" animation="border" role="status" />
          </div>
        )}
        <Formik
          initialValues={paymentMethodForm}
          enableReinitialize
          validationSchema={paymentValidations}
          onSubmit={onStepFourSubmit}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <hr />
              <h2>Payment Flow</h2>
              <hr />
              <Row>
                {paymentMethod.map((payment) => (
                  <Col lg={4} md={12} key={`${payment.name}-method`}>
                    <PaymentMethod
                      name={payment.name}
                      onBlur={handleBlur}
                      onSelect={(id) =>
                        handleChange('paymentMethod')({
                          target: { name: 'paymentMethod', value: id }
                        })
                      }
                      isSelected={values.paymentMethod === payment.name}
                    />
                  </Col>
                ))}
              </Row>

              <Row>
                <Col className="text-start">
                  <Button variant="primary" onClick={prevStep}>
                    {t('sh_back_button')}
                  </Button>
                </Col>
                <Col className="text-end">
                  <Button variant="primary" type="submit" isLoading={isLoading}>
                    Next
                  </Button>
                  <Button variant="primary" isLoading={isLoading} onClick={nextStep}>
                    Skip
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

Payment.propTypes = {};

export default Payment;
