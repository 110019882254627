import React, { Fragment, useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Form, Formik } from 'formik';
import { Button, InputFeedback, Radio } from '@core/Inputs';
import { useActivationAutoTopUp } from '@context/MobileOne/Activation/ActivationAutoTopUp';
import { useActivation } from '@context/MobileOne';
import { usePayment } from '@context/MobileOne/Activation/Payments';
import { useAlert } from '@context/Utils';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';
import { appActivationFlowType, appButtonType, appPaymentMethods } from '@utils/globalConstant';
import { PaymentFlow } from '../PaymentFlow';

export function Charging() {
  // Context
  const { formValue, validationSchema, onSubmit, isLoading } = useActivationAutoTopUp();
  /* isLoading, formValue, validationSchema, onSubmit, paymentMethods, validateForm, */
  const { getPaymentMethods, paymentMethods } = usePayment();
  const { alert } = useAlert();

  const { t } = useStaticContent();
  const { prevStep, activationType, activationInfo } = useActivation();
  // State

  // Function
  useEffect(() => {
    if (!paymentMethods.length) {
      getPaymentMethods();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.PAYMENT_METHOD_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.PAYMENT_METHOD_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
      if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.PAYMENT_METHOD_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.PAYMENT_METHOD_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  return (
    <>
      {activationType === appActivationFlowType.NEW_ONLINE ? (
        <PaymentFlow />
      ) : (
        <Fragment>
          <div className="container">
            <div className="row position-relative">
              {/* <div className="col-12 orange-gray-bubble"></div> */}
              <div className="col-md-3 pb-5 mt-5 mb-2">
                <div className="back-btn">
                  <a
                    className="text-white body-medium-bold text-decoration-none link-light"
                    onClick={prevStep}
                  >
                    <i className="fa fa-arrow-left me-3" />
                    {t('sh_back_button')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-xs-5">
            <div className="row mb-5 mb-sm-0">
              <div className="col-12 col-md-11 offset-md-1 col-lg-7 offset-lg-1 mb-5 mb-sm-0">
                <h6 className={`text-uppercase text-white${alert.length > 0 ? ' mt-3 pt-5' : ''}`}>
                  {t('sh_apoc_step6/9')}
                </h6>
                <h1 className="pink-color-text">{t('sh_apoc_top-up_h1')}</h1>
                <div className="row">
                  <div className="col-12 col-sm-9">
                    <p className="text-white body-large-regular font-24 mt-3 mt-sm-4 mb-0">
                      {t('sh_apoc_top-up_text1')}
                    </p>
                  </div>
                </div>

                <Formik
                  initialValues={{
                    ...formValue,
                    automaticCharging: formValue.automaticCharging || 'false'
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    if (window && window.utag) {
                      values.automaticCharging === 'true'
                        ? values.paymentMethod === 'paypal'
                          ? window.utag.link(
                              UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                                .CONTINUE_PAYPAL_ON_CLICK
                            )
                          : window.utag.link(
                              UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                                .CONTINUE_CREDIT_CARD_ON_CLICK
                            )
                        : window.utag.link(
                            UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                              .CONTINUE_WITHOUT_CHARGE_ON_CLICK
                          );
                    }

                    values.automaticCharging === 'true'
                      ? onSubmit(values)
                      : onSubmit({ ...values, paymentMethod: '' });
                  }}
                >
                  {({
                    values: { automaticCharging, paymentMethod },
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    errors,
                    touched
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="col-12 col-sm-6 mt-4 mt-sm-5 mb-4">
                        <div className="d-inline">
                          <Radio
                            label={t('sh_apoc_top-up_subheader1')}
                            description={t('sh_apoc_top-up_text2')}
                            name="automaticCharging"
                            onChange={(event) => {
                              if (window && window.utag) {
                                window.utag.link(
                                  UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                                    .DISABLE_AUTO_CHARGING
                                );
                              }
                              setFieldValue('automaticCharging', event.target.value);
                            }}
                            onBlur={handleBlur}
                            value="false"
                            isDefaultChecked={automaticCharging === 'false'}
                            id="inline-tdm-1"
                          />
                        </div>
                        <div className="d-inline">
                          <Radio
                            label={t('sh_apoc_top-up_subheader2')}
                            description={t('sh_apoc_top-up_text3')}
                            name="automaticCharging"
                            onChange={(event) => {
                              if (window && window.utag) {
                                window.utag.link(
                                  UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                                    .ENABLE_AUTO_CHARGING
                                );
                              }
                              setFieldValue('automaticCharging', event.target.value);
                            }}
                            onBlur={handleBlur}
                            value="true"
                            isDefaultChecked={automaticCharging === 'true'}
                            id="inline-tdm-2"
                          />
                        </div>
                        {/* <FormCheck
                              label={t('sh_apoc_top-up_subheader1')}
                              name="automaticCharging"
                              type="radio"
                              className="white-color-text m-0"
                              onChange={(event) =>
                                setFieldValue('automaticCharging', event.target.value)
                              }
                              onBlur={handleBlur}
                              value="false"
                              checked={automaticCharging === 'false'}
                              id="inline-tdm-1"
                            />
                          </div>
                          <div className="d-inline">
                            <FormCheck
                              label={t('sh_apoc_top-up_subheader2')}
                              name="automaticCharging"
                              type="radio"
                              className="white-color-text m-0"
                              onChange={(event) =>
                                setFieldValue('automaticCharging', event.target.value)
                              }
                              onBlur={handleBlur}
                              value="true"
                              checked={automaticCharging === 'true'}
                              id="inline-tdm-2"
                            />
                          </div> */}
                        {/* <FormControl.Feedback type="invalid" className="d-block">
                          {errors.automaticCharging}
                        </FormControl.Feedback> */}
                        {touched.automaticCharging && errors.automaticCharging && (
                          <InputFeedback
                            id={`auto-charging-error`}
                            isInvalid
                            feedBackMessage={errors.automaticCharging}
                          />
                        )}
                      </div>
                      {automaticCharging === 'true' && (
                        <>
                          <p className="body-medium-regular font-16 offWhite-color-text mb-3 pb-3 mt-3">
                            {t('sh_apoc_top-up_text4')}
                          </p>
                          <p className="body-medium-bold pink-color-text font-20 mb-3 pb-3">
                            {t('sh_ap_top-up_choose')}
                          </p>
                          <div className="col-12" style={{ paddingLeft: '1.4rem' }}>
                            {paymentMethods.map((pm, index) => (
                              <Fragment key={`${index + 1}`}>
                                <div className="mt-3 pt-1">
                                  <Radio
                                    label={
                                      pm === appPaymentMethods.PAYPAL
                                        ? t('sh_apoc_payment_option1')
                                        : pm === appPaymentMethods.CREDIT_CARD
                                        ? t('sh_apoc_payment_option2')
                                        : pm
                                    }
                                    description={t(
                                      pm === appPaymentMethods.CREDIT_CARD
                                        ? 'sh_apoc_payment_option2_text'
                                        : ''
                                    )}
                                    name="paymentMethod"
                                    id={pm}
                                    onChange={(e) => {
                                      if (window && window.utag) {
                                        pm === appPaymentMethods.PAYPAL
                                          ? window.utag.link(
                                              UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                                                .PAYPAL_ON_CLICK
                                            )
                                          : window.utag.link(
                                              UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.PAYMENT_METHOD
                                                .CREDIT_CARD_ON_CLICK
                                            );
                                      }

                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={pm}
                                    isDefaultChecked={pm === paymentMethod}
                                    key={pm}
                                  />
                                </div>
                                {/* <FormCheck
                                  label={
                                    pm === 'paypal'
                                      ? t('sh_apoc_payment_option1')
                                      : t('sh_apoc_payment_option2')
                                  }
                                  name="paymentMethod"
                                  type="radio"
                                  className="white-color-text m-0"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={pm}
                                  checked={pm === paymentMethod}
                                  key={pm}
                                /> */}
                              </Fragment>
                            ))}
                            {touched.paymentMethod && errors.paymentMethod && (
                              <div className="invalid-feedback d-block" id={`auto-charging-error`}>
                                {errors.paymentMethod}
                              </div>
                            )}
                            {/* <FormControl.Feedback type="invalid" className="d-block">
                              {errors.paymentMethod}
                            </FormControl.Feedback> */}
                          </div>
                        </>
                      )}
                      <div className="col-12 col-sm-7 my-4 mt-5">
                        <Button
                          buttonType={appButtonType.PRIMARY}
                          className="w-100 bg-offWhite-color"
                          type="submit"
                          isLoading={isLoading}
                        >
                          {t('sh_apoc_tarif_overview_next_button')}
                        </Button>
                      </div>
                      {automaticCharging === 'false' && (
                        <div className="col-12 col-sm-10 mb-4 mb-sm-0">
                          <p className="body-medium-regular font-16 offWhite-color-text mt-5">
                            {t('sh_apoc_top-up_text4')}
                          </p>
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      <div />
    </>
  );
}

export default Charging;
