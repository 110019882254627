import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Col, Row } from '@core/Layout';
import { Button } from '@core/Inputs';

export function SimCardExchange() {
  // context
  const { t } = useStaticContent();

  return (
    <>
      <Row>
        <Col xs={12}>
          <p>{t('sh_tab_two_sim_card_esim_dropdown2_text1')}</p>
          <li>
            <b>{t('sh_tab_two_sim_card_esim_dropdown2_text2')}</b>
          </li>
          <li>
            <b>{t('sh_tab_two_sim_card_esim_dropdown2_text3')}</b>
          </li>
          <li>
            <b>{t('sh_tab_two_sim_card_esim_dropdown2_text4')}</b>
            <p>{t('sh_tab_two_sim_card_esim_dropdown2_text5')}</p>
          </li>
          <br />
          <h5>{t('sh_tab_two_sim_card_esim_subheadline1')}</h5>
          <p>{t('sh_tab_two_sim_card_esim_dropdown1_text2')}</p>

          <Button variant="dark">{t('sh_tab_two_sim_card_esim_button')}</Button>
        </Col>
      </Row>
    </>
  );
}

export default SimCardExchange;
