/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import { Card, CardBody, CardHeader, CardText, CardTitle } from '@core/Layout/Card';
import { Row, Col } from '@core/Layout/Container';

export function PaymentProduct({
  id,
  key,
  productType,
  minAmount,
  maxAmount,

  currency,
  priceRange,

  channel,
  optionType,

  // isSelected,
  onSelect
}) {
  return (
    <div key={key} onClick={() => onSelect(id)}>
      <Card border="primary">
        <CardHeader>
          <Row className="justify-content-md-center p-0">
            <Col xs={10}>
              {productType} - {id}
            </Col>
            <Col xs={2} className="text-center justify-content-center">
              <Form.Check
                type="radio"
                id={`payment-product-${id}`}
                name="selectedProduct"
                // checked={isSelected}
                onChange={() => onSelect(id)}
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <CardTitle>
            min - {minAmount} {currency}, max - {maxAmount} {currency}
          </CardTitle>
          <CardText>
            Option Type - {optionType} <br />
            Price Range - {priceRange} <br />
            Channel - {channel} <br />
          </CardText>
        </CardBody>
      </Card>
    </div>
  );
}

PaymentProduct.propTypes = {
  id: PropTypes.number,
  productType: PropTypes.string,
  minAmount: PropTypes.number,
  maxAmount: PropTypes.number,
  currency: PropTypes.string,
  priceRange: PropTypes.string,
  channel: PropTypes.string,
  optionType: PropTypes.string,
  // isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func
};

PaymentProduct.defaultProps = {
  id: '',
  productType: '',
  minAmount: '',
  maxAmount: '',
  currency: '',
  priceRange: '',
  channel: '',
  optionType: '',
  onSelect: () => {}
};

export default PaymentProduct;
