import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from '@core/Layout';
// import { Card, CardBody, CardHeader, CardText } from '@core/Layout/Card';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import { Button, Text } from '@core/Inputs';
import { useTariff } from '@context/MobileOne';

export function YourTariff({
  setFieldValue,
  handleChange,
  errors,
  touched,
  setTouched,
  setFieldTouched,
  setErrors,
  codeForValidation,
  handleBlur
}) {
  const { t, staticContentData } = useStaticContent();
  const [modal, setModal] = useState();
  const { changeTariff, setChangeTariff, applyMGMCode } = useTariff();

  useEffect(() => {
    if (changeTariff && changeTariff.promoCode) {
      setChangeTariff({
        ...changeTariff,
        isPromoApplied: true
      });
    }
  }, []);

  return (
    <>
      <Row>
        <Col xs={12}>
          <b>{t('sh_sm_switch_summary_subheadline1')}</b>
        </Col>
      </Row>
      {changeTariff && changeTariff.detailInfo && (
        <>
          <Row>
            <Col xs={12} className="mb-3">
              <span dangerouslySetInnerHTML={{ __html: changeTariff.detailInfo.header }} />
              <ListGroup>
                {!changeTariff.promoCode
                  ? changeTariff.detailInfo.additionalInfo.bullets.map((bt, index) => (
                      <ListGroupItem key={index} dangerouslySetInnerHTML={{ __html: bt.header }} />
                    ))
                  : changeTariff.operationsInfo.ON_MGM_CODE_APPLY.bullets.map((bt, index) => (
                      <ListGroupItem
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: bt.header
                        }}
                      />
                    ))}
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="d-flex align-items-center justify-content-flex-start">
              <Button className="btn-link" onClick={() => setModal('tariffDetails')}>
                {t('sh_sm_switch_summary_tarifdetails_link')}
              </Button>

              <a
                className="btn-link"
                target="_blank"
                href={changeTariff.additionalInfo.document.productInformation.url}
                rel="noreferrer"
              >
                {t('sh_sm_switch_summary_pib_link')}
              </a>
              {/* removed the Preisliste from everywhere refs #44000 */}
              {/* <a
              style={{ marginLeft: "8px" }}
              className="btn-link"
              target="_blank"
              rel="noreferrer"
              href={changeTariff.additionalInfo.document.priceList.url}>
              {t('sh_apoc_tarif_overview_prices')}
            </a> */}
            </Col>
            <Col md={12}>
              <div className="d-flex m-2" style={{ alignItems: 'center' }}>
                <h3>{changeTariff.starterPackPrice}&euro;</h3> /{' '}
                <p>
                  {changeTariff.duration}&nbsp;{changeTariff.durationUnit}
                </p>
                <p> {t('sh_sm_switch_summary_text2')} </p>
              </div>
            </Col>
            <Col md={12}>
              <Card sx={{ backgroundColor: '#adb5bd', color: 'white' }}>
                <CardBody
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <div className="d-block">
                    <h5>{changeTariff.starterPackPrice} &euro; Startguthaben</h5>
                    Damit sind die ersten {changeTariff.duration} {changeTariff.durationUnit}{' '}
                    inklusive.
                  </div>
                  <Button variant="outline-primary" onClick={() => setModal('priceDetails')}>
                    I
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md={12} className="mt-2">
              <input type="hidden" name="isPromoApplied" value={changeTariff.isPromoApplied} />
              {!changeTariff.isPromoApplied ? (
                <Button
                  variant="link"
                  onClick={() => {
                    setFieldValue('isPromoApplied', true);
                    setChangeTariff({
                      ...changeTariff,
                      isPromoApplied: true
                    });
                  }}
                >
                  {t('sh_apoc_tarif_overview_voucher')}
                </Button>
              ) : (
                <>
                  {!changeTariff.promoCode ? (
                    <>
                      <Text
                        type="text"
                        id="codeForValidation"
                        name="codeForValidation"
                        onChange={handleChange}
                        value={codeForValidation}
                        placeholder="Code"
                        isInvalid={!!touched.codeForValidation && !!errors.codeForValidation}
                        onBlur={handleBlur}
                        error={
                          touched.codeForValidation && errors.codeForValidation
                            ? errors.codeForValidation
                            : null
                        }
                      />
                      <Button
                        type="button"
                        onClick={() => {
                          setTouched({ codeForValidation: true });
                          applyMGMCode({ codeForValidation, setErrors });
                        }}
                      >
                        {t('sh_apoc_voucher_redeem_button')}
                      </Button>
                    </>
                  ) : (
                    t('sh_apoc_tarif_overview_voucher-msg')
                      .replace('\\add code here', changeTariff.operationsInfo.promoCode)
                      .replace(
                        '"\\add GB here"',
                        `+${changeTariff.operationsInfo.codeGBVolume} GB.`
                      )
                  )}
                  <Button
                    variant="link"
                    onClick={() => {
                      setFieldValue('isPromoApplied', false);
                      setFieldValue('codeForValidation', '');
                      setFieldTouched('codeForValidation', false, false);
                      const defaultTariff = staticContentData.tariff_share.find(
                        (bt) => bt.id === changeTariff.id
                      );
                      setChangeTariff({ ...defaultTariff });
                    }}
                    className="m-1"
                  >
                    {t('sh_apoc_tarif_overview_delete')}
                  </Button>
                </>
              )}
            </Col>
          </Row>

          <Modal show={modal === 'tariffDetails'} onHide={() => setModal('')} size="lg">
            <Modal.Header closeButton>
              <div>
                <p className="m-0">{t('sh_apoc_tarif_details_header')}</p>
                <Modal.Title>
                  <span dangerouslySetInnerHTML={{ __html: changeTariff.detailInfo.header }} />
                </Modal.Title>
              </div>
            </Modal.Header>

            <Modal.Body>
              <span dangerouslySetInnerHTML={{ __html: changeTariff.detailInfo.text }} />
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => setModal('')}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default YourTariff;
