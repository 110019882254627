import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne/Customer';
import { appAlert, appRoute, formValidation } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';

export const TariffContext = createContext({});

export function TariffContextProvider({ children }) {
  // Context
  const { showAlert } = useAlert();
  const { customerData, getCustomerData } = useCustomer();
  const {
    onActiveProductCall,
    onBookableTariffCall,
    onEvnCall,
    onEvnLookupSettingsCall,
    onEvnChangeSettingsCall,
    onChangeTariffCall,
    onValidateCode,
    onSimData
  } = useMobileOne();
  const navigate = useNavigate();
  const { t } = useStaticContent();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [allTariff, setAllTariff] = useState([]);
  const [activeTariff, setActiveTariff] = useState([]);
  const [changeTariff, setChangeTariff] = useState(null);
  const [bookableTariff, setBookableTariff] = useState([]);
  const [evnLookupSettings, setEvnLookupSetings] = useState([]);
  const [simData, setSimSata] = useState([]);

  // Validations
  const termsValidations = Yup.object().shape({
    terms: Yup.boolean().isTrue('please_select_terms_and_conditions')
  });

  // Evn Setting Initial Values & Validation
  const evnSettingInitialValue = {
    evnSetting: ''
  };
  const evnSettingValidation = Yup.object().shape({
    evnSetting: formValidation({
      required: 'please_enter_evn_setting',
      regex: /^\d{1}$/,
      validErrorMessage: 'invalid_evn_setting'
    })
  });
  const [changeEvnSettingForm, setChangeEvnSettingForm] = useState(evnSettingInitialValue);
  const [isEvnAllowed, setIsEvnAllowed] = useState(false);

  // Functions
  // Get current evn setting and storing it in state
  const getEvnCurrentSettings = async (allEvn) => {
    try {
      const { data = {} } = await onEvnCall();
      if (data) {
        const currentSettings = allEvn.find((evn) => evn.id === data.evnSetting);
        setIsEvnAllowed(data.evnAllowed);
        setChangeEvnSettingForm({ evnSetting: currentSettings.id });
      }
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // Get all evn setting list and storing it in state
  const getEvnLookupSettings = async () => {
    try {
      const { data = [] } = await onEvnLookupSettingsCall();
      if (data && data.length > 0) {
        getEvnCurrentSettings(data);
        setEvnLookupSetings(data);
      }
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onEvnChangeSettingSubmit = async (evnSetting) => {
    try {
      await onEvnChangeSettingsCall(evnSetting);
    } catch (error) {
      console.log(error);
    }
  };

  // Get customer active tariff and storing it in state
  const getActiveProductCall = async () => {
    try {
      const { data = [] } = await onActiveProductCall();
      if (data) setActiveTariff(data);
      return data;
    } catch (error) {
      console.log(error);
      // showAlert({
      //   type: appAlert.ERROR,
      //   message: `Error in request. ${error.error[0].messageBody}`
      // });
      setIsLoading(false);
      return error;
    }
  };

  // Get customer bookable tariff and storing it in state
  const getBookableTariffCall = async () => {
    try {
      const { data } = await onBookableTariffCall();
      if (data) setBookableTariff(data);
      return data;
    } catch (error) {
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      setIsLoading(false);
      return error;
    }
  };

  // Function loads after we get customer data, and returns active and bookable tariff
  const afterLoad = async () => {
    try {
      setIsLoading(true);
      await getActiveProductCall();
      await getBookableTariffCall();
      setIsLoading(false);
      return null;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return error;
    }
  };

  // Find tariff status name to display in tariff product card.
  const getTariffStatusName = (tariffId) => {
    const findTariff = activeTariff.find(
      ({ tariff: { id } }) => parseInt(id, 10) === parseInt(tariffId, 10)
    );
    if (findTariff) {
      const {
        status: { name = false }
      } = findTariff;
      return name;
    }
    return false;
  };

  const getSimData = async () => {
    try {
      const { data = [] } = await onSimData();
      if (data) {
        setSimSata(data);
        return data;
      }
      return data;
    } catch (error) {
      console.log('catch', error);
      return false;
    }
  };

  // Switch Active Tariff
  const onSubmit = async ({ id }) => {
    try {
      setIsLoading(true);
      const { data, success, status } = await onChangeTariffCall({ tariffId: id });
      console.log({ data, success, status });
      await getCustomerData();
      if (success) {
        showAlert({
          type: appAlert.SUCCESS,
          message: 'Successfully updated tariff'
        });
      } else {
        showAlert({
          type: appAlert.ERROR,
          message: 'Error in processing, try again after sometime.'
        });
      }
      setChangeTariff(null);
      navigate(appRoute.TARIFF_OPTION);
      setIsLoading(false);
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      return error;
    }
  };
  const applyMGMCode = async ({ codeForValidation, setErrors }) => {
    if (codeForValidation) {
      const { data } = await onValidateCode({ codeForValidation });
      if (data.valid) {
        changeTariff.promoCode = codeForValidation;
        const originalDataCount = changeTariff.additionalInfo.volume.DATA.value;

        changeTariff.additionalInfo.volume.DATA.value += data.bonusGb;

        changeTariff.operationsInfo.promoCode = codeForValidation;
        changeTariff.operationsInfo.codeGBVolume = data.bonusGb;

        changeTariff.operationsInfo.ON_MGM_CODE_APPLY.subHeader =
          changeTariff.operationsInfo.ON_MGM_CODE_APPLY.subHeader.replace(
            '{{totalDataCount}}',
            `<h2>${changeTariff.additionalInfo.volume.DATA.value}<h2>`
          );

        changeTariff.operationsInfo.ON_MGM_CODE_APPLY.subHeader =
          changeTariff.operationsInfo.ON_MGM_CODE_APPLY.subHeader.replace(
            '{{originalDataCount}}',
            originalDataCount
          );

        changeTariff.operationsInfo.ON_MGM_CODE_APPLY.bullets[0].header =
          changeTariff.operationsInfo.ON_MGM_CODE_APPLY.bullets[0].header.replace(
            '{{originalDataCount}}',
            originalDataCount
          );

        changeTariff.operationsInfo.ON_MGM_CODE_APPLY.bullets[0].header =
          changeTariff.operationsInfo.ON_MGM_CODE_APPLY.bullets[0].header.replace(
            '{{totalDataCount}}',
            changeTariff.additionalInfo.volume.DATA.value
          );
        setChangeTariff({ ...changeTariff });
      } else {
        setErrors({ codeForValidation: t('sh_apoc_tarif_voucher_err_no_code') });
      }
    } else {
      setErrors({
        codeForValidation: t('sh_apoc_tarif_voucher_err_no_code')
      });
    }
  };
  // Hooks
  useEffect(() => {
    const { msisdn, ...restCustomerData } = customerData;
    if (restCustomerData && msisdn) {
      afterLoad();
    }
  }, [customerData]);

  // Merge both Active Tariffs & Bookable Tariffs to show all list
  useEffect(() => {
    setAllTariff([
      ...activeTariff.map(({ tariff }) => ({
        ...tariff,
        additionalInfo: tariff.additionalInfo
          ? tariff.additionalInfo
          : {
              primaryColor: '#fff',
              secondaryColor: '#fff',
              bullets: tariff?.additionalInfo?.bullets ? tariff?.additionalInfo?.bullets : []
            }
      })),
      ...bookableTariff.map((tariff) => ({
        ...tariff,
        additionalInfo: tariff.additionalInfo
          ? tariff.additionalInfo
          : {
              primaryColor: '#fff',
              secondaryColor: '#fff',
              bullets: tariff.bullets ? tariff.bullets : []
            }
      }))
    ]);
    return () => {
      setAllTariff([]);
    };
  }, [activeTariff, bookableTariff]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      evnLookupSettings,
      setEvnLookupSetings,
      isEvnAllowed,
      setIsEvnAllowed,
      changeEvnSettingForm,
      setChangeEvnSettingForm,
      setActiveTariff,
      setChangeTariff,
      setBookableTariff,
      simData,
      setSimSata,

      changeTariff,
      allTariff,
      activeTariff,
      bookableTariff,
      termsValidations,
      getEvnLookupSettings,

      // Form Initial States & Validations
      evnSettingInitialValue,
      evnSettingValidation,

      // Functions
      onSubmit,
      applyMGMCode,
      getTariffStatusName,
      onEvnChangeSettingSubmit,
      getSimData
    }),
    [
      // States
      isLoading,
      setIsLoading,
      evnLookupSettings,
      setEvnLookupSetings,
      isEvnAllowed,
      setIsEvnAllowed,
      changeEvnSettingForm,
      setChangeEvnSettingForm,
      setActiveTariff,
      setChangeTariff,
      setBookableTariff,
      simData,
      setSimSata,

      changeTariff,
      allTariff,
      activeTariff,
      bookableTariff,
      termsValidations,

      // Form Initial States & Validations
      evnSettingInitialValue,
      evnSettingValidation,

      // Functions
      getTariffStatusName,
      onSubmit,
      applyMGMCode,
      getEvnLookupSettings,
      onEvnChangeSettingSubmit,
      getSimData
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <TariffContext.Provider value={contextPayload}>{children}</TariffContext.Provider>;
}
TariffContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useTariff = () => useContext(TariffContext);

export default TariffContext;
