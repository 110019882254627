import React, { useEffect, useState } from 'react';
import { Button, Switch } from '@core/Inputs';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useNumberPorting } from '@context/MobileOne/Activation/NumberPorting';
import { useActivation } from '@context/MobileOne';
import {
  appActivationFlowType,
  appActivationStep2Screens,
  appButtonType
} from '@utils/globalConstant';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';

export function StartWithOldNumber({ setModal }) {
  // State
  const [isNumberTransferred, setIsNumberTransferred] = useState(false);

  // Context
  const { t } = useStaticContent();
  const { onSubmit, isLoading, formValue, newNumbers } = useNumberPorting();
  const { activationInfo, setActivationInfo, activationType, setNumberPortingScreen } =
    useActivation();

  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.CONTINUE_NUMBER_PORTABILITY_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.CONTINUE_NUMBER_PORTABILITY_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
      if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.CONTINUE_NUMBER_PORTABILITY_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.CONTINUE_NUMBER_PORTABILITY_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  return (
    <Modal
      showBackButton
      onBackClick={() => setModal('currentNumber')}
      stepHeader={t('sh_apoc_number-portability_step3')}
      header={t('sh_apoc_number-portability_h1_2')}
      description={t('sh_apoc_number-portability_subheader')}
    >
      <div className="mt-4">
        <div
          // className="col"
          className="d-flex justify-content-between align-items-center"
        >
          <Switch
            label={t('sh_apoc_number-portability_toggle_text')}
            name="isNumberTransferred"
            id="isNumberTransferred"
            isDefaultChecked={isNumberTransferred}
            onChange={() => {
              if (window && window.utag) {
                activationType === appActivationFlowType.NEW_ONLINE
                  ? !isNumberTransferred
                    ? window.utag.link(
                        UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.NUMBER_PORTABILITY_INFORMATION
                          .ACTIVATE_FAST_ON_CLICK
                      )
                    : window.utag.link(
                        UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.NUMBER_PORTABILITY_INFORMATION
                          .ACTIVATE_NORMAL_ON_CLICK
                      )
                  : !isNumberTransferred
                  ? window.utag.link(
                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.NUMBER_PORTABILITY_INFORMATION
                        .ACTIVATE_FAST_ON_CLICK
                    )
                  : window.utag.link(
                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.NUMBER_PORTABILITY_INFORMATION
                        .ACTIVATE_NORMAL_ON_CLICK
                    );
              }

              setIsNumberTransferred(!isNumberTransferred);
              setActivationInfo({
                ...activationInfo,
                newNumber: newNumbers[0].msisdn,
                isNumberTransferred: !isNumberTransferred
              });
            }}
          />
        </div>
      </div>
      <div className="row pt-3">
        <div className="col">
          <Button
            buttonType={appButtonType.SECONDARY}
            className="w-100"
            type="submit"
            isLoading={isLoading}
            disabled={!isNumberTransferred}
            onClick={() => {
              if (window && window.utag) {
                activationType === appActivationFlowType.NEW_ONLINE
                  ? window.utag.link(
                      UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.NUMBER_PORTABILITY_INFORMATION
                        .TRANSFER_FAST_ON_CLICK
                    )
                  : window.utag.link(
                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.NUMBER_PORTABILITY_INFORMATION
                        .TRANSFER_FAST_ON_CLICK
                    );
              }

              if (activationType === appActivationFlowType.SIM_ACTIVATION) {
                onSubmit({
                  ...formValue,
                  newNumber: '',
                  isNewNumber: false
                });
              } else {
                setNumberPortingScreen(appActivationStep2Screens.SIM_ESIM_SELECTION);
              }
              setModal(activationType === appActivationFlowType.SIM_ACTIVATION ? '' : 'simInfo');
            }}
          >
            {t('sh_apoc_tarif_overview_next_button')}
          </Button>
        </div>
        <div className="row pt-3">
          <div className="col">
            <p>{t('sh_apoc_number-portability_text4')}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default StartWithOldNumber;
