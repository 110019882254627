import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appAlert } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { generateUUID } from '@utils/scriptLoader';

export const EmailVerificationContext = createContext({});

export function EmailVerificationContextProvider({
  children,
  onFormUpdate,
  nextStep,
  currentStep,
  setCurrentStep,
  editStep,
  setEditStep,
  activationInfo
}) {
  // State
  const [uniqueId, setUniqueId] = useState('');

  // Constant
  const initialEmail = {
    email: ''
  };

  const initialCode = {
    code: ''
  };

  // Contexts
  const { t } = useStaticContent();
  const { showAlert } = useAlert();
  const { onTwoFactorAuthPinCall, onTwoFactorAuthVerificationCall } = useMobileOne();

  // Validations
  const validationSchemaEmail = Yup.object().shape({
    email: Yup.string()
      .required(t('sh_apoc_email_err_wrong_email'))
      .email(t('sh_apoc_email_err_wrong_email'))
  });

  // Validations
  const validationSchemaCode = Yup.object().shape({
    code: Yup.number()
      .typeError(t('sh_apoc_email_err_wrong_code'))
      .required(t('sh_apoc_email_err_no_code'))
      .test('code', t('sh_apoc_email_err_wrong_code'), (hp) => /^\d{4}$/.test(hp))
  });

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(initialEmail);

  // Context
  // Functions
  const onLoad = async () => {};

  const verifyEmail = async ({ email, resendEmail = false }) => {
    try {
      setIsLoading(true);
      const uniqueId = generateUUID();
      const params = {
        email,
        uniqueId
      };
      const res = await onTwoFactorAuthPinCall(params);
      if (res.success) {
        resendEmail &&
          showAlert({
            type: appAlert.SUCCESS,
            message: `${t('sh_apoc_email_popup_newcode')}`.replace(
              ': {{email_costumer}}',
              `: ${email}`
            )
          });

        onFormUpdate({ email });
        setUniqueId(uniqueId);
        setIsLoading(false);
        !resendEmail && nextStep();
      } else {
        setIsLoading(false);
        showAlert({
          type: appAlert.ERROR,
          message: res.message
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: error.error[0].messageBody
      });
    }
  };
  const verifyCode = async ({ code }) => {
    try {
      setIsLoading(true);
      const params = {
        pin: parseInt(code),
        uniqueId
      };
      const res = await onTwoFactorAuthVerificationCall(params);
      if (res.success) {
        setIsLoading(false);
        showAlert({
          type: appAlert.SUCCESS,
          message: t('sh_apoc_top-up_msg')
        });
        if (editStep === currentStep) {
          setEditStep(-1);
          setCurrentStep(activationType === appActivationFlowType.SIM_ACTIVATION ? 8 : 6);
        } else {
          nextStep();
        }
      } else {
        setIsLoading(false);
        showAlert({
          type: appAlert.ERROR,
          message: res.error[0].message
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: error.error[0].messageBody
      });
    }
  };
  const validateEmail = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = t('sh_apoc_email_err_no_email');
    }
    return errors;
  };
  const validateCode = (values) => {
    const errors = {};
    if (!values.code) {
      errors.code = t('sh_apoc_email_err_no_code');
    }

    return errors;
  };
  // Hooks
  // Default Hook // No use
  useEffect(() => {}, []);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // Constants
      initialEmail,
      validationSchemaEmail,
      initialCode,
      validationSchemaCode,

      // States

      isLoading,
      setIsLoading,
      formValue,
      setFormValue,

      // Functions
      onLoad,
      validateEmail,
      validateCode,
      verifyEmail,
      verifyCode
    }),
    [
      // Constants
      initialEmail,
      validationSchemaEmail,
      initialCode,
      validationSchemaCode,
      validateCode,
      // States

      isLoading,
      setIsLoading,
      formValue,
      setFormValue,

      // Functions
      onLoad,
      validateEmail,
      verifyEmail,
      verifyCode
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <EmailVerificationContext.Provider value={contextPayload}>
      {children}
    </EmailVerificationContext.Provider>
  );
}
EmailVerificationContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useEmailVerification = () => useContext(EmailVerificationContext);

export default EmailVerificationContext;
