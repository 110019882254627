import React, { useState } from 'react';
import { Col, Container, Row } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useNavigate } from 'react-router-dom';
import { Button, Link } from '@core/Inputs';
import { appRoute } from '@utils/globalConstant';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { CreditOptions } from '@part/CreditDetails/CreditOptions';
import { SofortOptions } from '@part/CreditDetails/SofortOptions';
import { CashcodeOptions } from '@part/CreditDetails/CashcodeOptions';
import { PaymentFlow } from '@page/Activation';
import { Modal, Table } from 'react-bootstrap';

export function CreditDetails() {
  // Context
  const { t } = useStaticContent();
  const { customerBalance, formValue, setFormValue, getVoucherHistory, history } = useDashBoard();
  const [voucherHistory, setVoucherHistory] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {!formValue.isRechargeOptions && !formValue.creditOption && (
        <Container>
          <Row className="d-flex align-items-center">
            <Col md="4">
              <Link to={appRoute.DASHBOARD}>
                <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
              </Link>
            </Col>
            <Col md="8">
              <h4 className="mt-3 mb-3">{t('sh_db_credit_h3')}</h4>
            </Col>
          </Row>
          <Row>
            <Col md={{ offset: 4, span: 8 }}>
              <h3> {customerBalance.totalBalance} &euro;</h3>
              <p>{t('sh_db_cons_details_info1')}</p>
              <p>
                <b>{t('sh_db_charge_info1')}</b>
              </p>
              <p>
                <Button
                  variant="link"
                  onClick={() => {
                    setVoucherHistory(true);
                    getVoucherHistory();
                  }}
                >
                  {t('sh_db_charge_show_history')}
                </Button>
              </p>
              <Button
                variant="primary"
                onClick={() =>
                  setFormValue({
                    ...formValue,
                    isRechargeOptions: true
                  })
                }
              >
                {t('sh_db_charge_button')}
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: '2rem' }}>
            <Col md={{ offset: 4, span: 8 }}>
              <h5> {t('sh_db_charge_auto_top_up')}</h5>
              <p>{t('sh_db_charge_auto_info')}</p>
              <p>
                <Link to="/">
                  {t('sh_db_charge_auto_deactivate')} <i className="bi bi-chevron-right" />
                </Link>
              </p>
              <Button variant="primary" onClick={() => navigate(appRoute.RECHARGE)}>
                {t('sh_db_charge_auto_button')}
              </Button>
            </Col>
          </Row>
        </Container>
      )}
      {formValue.isRechargeOptions && !formValue.creditOption && <CreditOptions />}

      {formValue.creditOption === t('sh_db_charge_balance_h1') && !formValue.isPaymentInitiated && (
        <SofortOptions />
      )}
      {formValue.creditOption === t('sh_db_charge_balance_h2') && <CashcodeOptions />}
      {formValue.isPaymentInitiated && <PaymentFlow />}

      {voucherHistory && (
        <Modal show={voucherHistory} onHide={() => setVoucherHistory(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('sh_db_charge_history')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <tbody>
                {history.map((hr) => (
                  <tr>
                    <td>{hr.amount} &euro;</td>
                    <td style={{ textAlign: 'right' }}>{hr.date}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default CreditDetails;
