import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Modal } from '@core/Utils';
import {
  appActivationFlowType,
  appActivationStep2Modals,
  appButtonType,
  mobileBrands,
  appeSimDeviceListing
} from '@utils/globalConstant';
import { Button } from '@core/Inputs';
import { useNumberPorting } from '@context/MobileOne/Activation/NumberPorting';
import { useActivation } from '@context/MobileOne';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';

export function ESimBrandDeviceListing({ mobileBrand, setModal }) {
  // Context
  const { t } = useStaticContent();
  const { formValue, setFormValue, onSubmit } = useNumberPorting();
  const { activationInfo, activationType } = useActivation();

  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ESIM_DEVICE_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.ESIM_DEVICE_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  // State
  const [devices, setDevices] = useState('');
  const [brandName, setBrandName] = useState('');

  useEffect(() => {
    if (mobileBrand.id) {
      const brand = appeSimDeviceListing.find(({ id }) => id === mobileBrand.id);
      if (brand && brand.devices) {
        setBrandName(brand.showTitleOnDetail ? `${t(brand.title)}-` : '');
        setDevices(t(brand.devices));
      }
    }
  }, [mobileBrand.id]);

  return (
    <Modal
      showBackButton
      onBackClick={() => {
        setModal(appActivationStep2Modals.ESIM_BRAND_SELECTION);
      }}
      stepHeader={t('sh_apoc_eSIM_header2')}
      header={t('sh_apoc_eSIM_h1')}
    >
      <div className="mt-4">
        <p className="body-medium-regular">
          {`${t('sh_apoc_eSIM__device_h1')}`.replace('{{device}}-', brandName)}
        </p>
        <div className="mt-4" dangerouslySetInnerHTML={{ __html: devices }}></div>
        <div className="row">
          <div className="col-12">
            <div className="py-2">
              <Button
                buttonType={appButtonType.SECONDARY}
                className="w-100"
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(
                      UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.ESIM_DEVICE.LISTED_DEVICE_ON_CLICK
                    );
                  }
                  setModal(appActivationStep2Modals.NONE);
                  onSubmit({ ...formValue, isDeviceAvail: 'true' });
                }}
              >
                {t('sh_apoc_eSIM_devices_confirm')}
              </Button>
            </div>
          </div>
          <div className="col-12">
            <div className="py-2">
              <Button
                buttonType={appButtonType.PRIMARY}
                className="w-100"
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(
                      UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.ESIM_DEVICE.DEVICE_NOT_LISTED_ON_CLICK
                    );
                  }
                  setFormValue({ ...formValue, eSim: 'false' });
                  setModal(appActivationStep2Modals.NONE);
                }}
              >
                {t('sh_apoc_eSIM_devices_decline')}
              </Button>
            </div>
          </div>
        </div>
        <div
          className="body-medium-regular"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_eSIM_devices_info') }}
        ></div>
      </div>
    </Modal>
  );
}

export default ESimBrandDeviceListing;
