/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Col,
  Container,
  Row
} from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button, Link } from '@core/Inputs';
import { appRoute } from '@utils/globalConstant';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { useTariff } from '@context/MobileOne';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment/moment';

export function UsageDetails() {
  const { t } = useStaticContent();
  const { dataUsage } = useDashBoard();
  const { activeTariff } = useTariff();
  const [days, setDays] = useState(0);
  const [activeOption, setActiveOption] = useState(null);
  const [hours, setHours] = useState(0);
  useEffect(() => {
    if (activeTariff.length) {
      setActiveOption(activeTariff[0]?.bookedOptions?.find((opt) => opt.status.name === 'aktiv'));
    }
  }, [activeTariff]);
  useEffect(() => {
    if (activeOption) {
      const hoursDifference = moment(
        `${activeOption.endDate} ${activeOption.endTime}`,
        'DD.MM.YYYY hh:mm:ss'
      ).diff(moment(new Date(), 'DD.MM.YYYY hh:mm:ss'), 'h');
      setDays(Math.floor(hoursDifference / 24));
      const hour = Math.round((hoursDifference / 24).toFixed(2).toString().split('.')[1]);
      if (hour > 0) {
        setHours(Math.round((24 / 100) * hour));
      }
    }
  }, [activeOption]);
  return (
    <>
      <Container>
        <Row className="d-flex align-items-center">
          <Col md="4">
            <Link to={appRoute.DASHBOARD}>
              <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
            </Link>
          </Col>
          <Col md="8">
            <h4 className="mt-3 mb-3">{t('sh_db_usage_h2')}</h4>
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 4, span: 8 }}>
            <Card sx={{ width: '25rem' }}>
              {dataUsage.name ? (
                <CardBody>
                  <div className="d-flex">
                    <h3>{dataUsage.remainingValue} GB</h3>
                    <p className="m-2">
                      {t('sh_db_usage_text1')} {dataUsage.initialValue}
                    </p>
                    <p className="m-2">{t('sh_db_usage_text2')}</p>
                  </div>
                  {dataUsage.remainingValue === dataUsage.initialValue ? (
                    <ProgressBar now={100} label="0%" />
                  ) : (
                    <ProgressBar
                      now={(dataUsage.remainingValue / dataUsage.initialValue) * 100}
                      label={`${(dataUsage.remainingValue / dataUsage.initialValue) * 100}%`}
                    />
                  )}

                  <CardText>{t('sh_db_cons_details_info1')}</CardText>
                  <div className="pt-2">
                    <span className="d-flex align-items-flex-start justify-content-between">
                      <CardText>{t('sh_db_cons_details_info2')}</CardText>
                      <b>
                        {days} Tage {hours} Std.
                      </b>
                    </span>
                    <span className="d-flex align-items-flex-start justify-content-between">
                      <CardText>{t('sh_db_cons_details_info3')}</CardText>
                      <b>{activeOption?.endDate}</b>
                    </span>
                  </div>
                </CardBody>
              ) : (
                <p>No Usage Data</p>
              )}
              <CardFooter>
                <Button variant="primary">{t('sh_db_cons_details_button')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UsageDetails;
