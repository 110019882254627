import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { formValidation } from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';

import { useConfig } from '@config/ContextManager';
import { useActivationTariff } from '../ActivationTariff';
import { useActivation } from '..';
import { appActivationFlowType, appStorage } from '@utils/globalConstant';

export const NumberPortingContext = createContext({});

export function NumberPortingContextProvider({ children, onFormUpdate, nextStep, activationInfo }) {
  // Constant
  const initialValue = {
    isNewNumber: 'false',
    // newNumber: '',
    isNewNumberSelected: false,
    currentNumber: '',
    provider: '',
    startWithNumber: '',
    isCurrentNumberEntered: false,
    startWithNumberSelected: false,
    isNumberTransferred: false,
    selectSIM: '',
    device: '',
    isDeviceAvail: false,
    eSim: 'false'
  };
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(initialValue);
  const [newNumbers, setNewNumbers] = useState([]);
  const [providers, setProviders] = useState([]);
  const [oldData, setOldData] = useState({});
  const [isOldData, setIsOldData] = useState(false);

  // Context
  const { t } = useStaticContent();
  const { currentStep, setCurrentStep, editStep, setEditStep, activationType } = useActivation();
  const {
    config: { storage }
  } = useConfig();

  // Validations
  const validationSchemaPortType = Yup.object().shape({
    isNewNumber: formValidation({ required: 'please_select' })
  });
  const validationSchemaSelectNumber = Yup.object().shape({
    newNumber: formValidation({ required: 'please_select' })
  });
  const validationSchemaProvider = Yup.object().shape({
    provider: formValidation({ required: 'please_select_provider' })
  });
  const validationSchemaCurrentNumber = Yup.object().shape({
    currentNumber: formValidation({
      required: t('sh_apoc_number-portability_input2_err_msg'),
      regex: /^\d{11,14}$/,
      validErrorMessage: t('sh_apoc_number-portability_input2_err_msg')
    })
  });
  const validationCurrentNumber = /^\d{11,14}$/;

  const validationSchemaStartWithNumber = Yup.object().shape({
    // startWithNumber: formValidation({ required: 'please_select' }),
    isNumberTransferred: Yup.boolean().required().oneOf([true], 'mark_as_true')
  });
  const validationSchemaSIMSelection = Yup.object().shape({
    selectSIM: formValidation({ required: 'please_select_sim' })
  });
  const validationSchemaDevice = Yup.object().shape({
    device: formValidation({ required: 'please_select_device' })
  });

  // Context
  const { onPostpaidNumber, onSimProvider } = useMobileOne();
  const { bookableTariff, setBookableTariff } = useActivationTariff();
  // Functions
  const getSimProvider = async () => {
    try {
      const { data = [] } = await onSimProvider();
      if (data && data.length > 0) {
        let providersArr = data.map((prd) => {
          return {
            value: prd.id,
            label: prd.value
          };
        });
        setProviders(providersArr);
        await storage.setItem(appStorage.SIM_PROVIDERS, JSON.stringify(providersArr));
        /* eslint-disable */
      }
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const getNumbers = async () => {
    const { status, data } = await onPostpaidNumber({ numberCount: 6 });
    if (status === 200) {
      setNewNumbers(data);
      await storage.setItem(appStorage.POSTPAID_NUMBERS, JSON.stringify(data));
    }
  };
  const onLoad = async () => {
    getSimProvider();
    getNumbers();
    if (newNumbers.length === 0 && providers.length === 0) {
      // getSimProvider();
      // getNumbers();
    } else {
      setIsOldData(true);
      setOldData({
        isNewNumber: activationInfo.isNewNumber,
        newNumber: activationInfo.newNumber,
        isNewNumberSelected: activationInfo.isNewNumberSelected,
        currentNumber: activationInfo.currentNumber,
        provider: activationInfo.provider,
        startWithNumber: activationInfo.startWithNumber,
        isCurrentNumberEntered: activationInfo.isCurrentNumberEntered,
        startWithNumberSelected: activationInfo.startWithNumberSelected,
        isNumberTransferred: activationInfo.isNumberTransferred,
        selectSIM: activationInfo.selectSIM,
        device: activationInfo.device,
        isDeviceAvail: activationInfo.isDeviceAvail
      });
    }
  };

  const onSubmit = async (values) => {
    // TODO:
    // 1. Call only on new / old sim number flow complete for activate sim card
    // 2. Call only on sim / e-sim flow complete for new online customer
    setIsLoading(true);

    onFormUpdate({
      ...activationInfo,
      ...formValue,
      ...values
    });
    setFormValue({
      ...formValue,
      ...values
    });

    let { currentNumber, newNumber } = values;
    if (bookableTariff.msisdn === 'msisdn' && (currentNumber || newNumber)) {
      currentNumber =
        currentNumber && currentNumber.length === 10 ? `0${currentNumber}` : currentNumber;
      setBookableTariff({
        ...bookableTariff,
        msisdn: currentNumber || newNumber
      });
    }

    if (editStep === currentStep) {
      setCurrentStep(activationType === appActivationFlowType.SIM_ACTIVATION ? 8 : 6);
      setEditStep(-1);
    } else {
      nextStep();
    }
    setIsLoading(false);
  };

  // Hooks
  // Default Hook // No use
  useEffect(() => {}, []);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // Constants
      initialValue,
      validationSchemaPortType,
      validationSchemaSelectNumber,
      validationSchemaProvider,
      validationSchemaCurrentNumber,
      validationCurrentNumber,
      validationSchemaStartWithNumber,
      validationSchemaSIMSelection,
      validationSchemaDevice,
      // States

      isLoading,
      setIsLoading,
      formValue,
      setFormValue,
      providers,
      setProviders,
      newNumbers,
      setNewNumbers,

      // Functions
      onLoad,
      onSubmit,
      getSimProvider,
      getNumbers,

      oldData,
      setOldData,
      isOldData,
      setIsOldData
    }),
    [
      // Constants
      initialValue,
      validationSchemaPortType,
      validationSchemaSelectNumber,
      validationSchemaProvider,
      validationSchemaCurrentNumber,
      validationCurrentNumber,
      validationSchemaStartWithNumber,
      validationSchemaSIMSelection,
      validationSchemaDevice,

      // States

      isLoading,
      setIsLoading,
      formValue,
      setFormValue,
      providers,
      setProviders,
      newNumbers,
      setNewNumbers,

      // Functions
      onLoad,
      onSubmit,
      getSimProvider,
      getNumbers,

      oldData,
      setOldData,
      isOldData,
      setIsOldData
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <NumberPortingContext.Provider value={contextPayload}>{children}</NumberPortingContext.Provider>
  );
}
NumberPortingContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useNumberPorting = () => useContext(NumberPortingContext);

export default NumberPortingContext;
