import React from 'react';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function NewTariffModal({ setModal }) {
  // Context
  const { t } = useStaticContent();
  return (
    <Modal
      showCloseButton
      closeButtonText="sh_apoc_tarif_overview_tarif-details_cancel"
      onCloseClick={() => {
        setModal('');
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_pink_footnote') }} />
    </Modal>
  );
}

export default NewTariffModal;
