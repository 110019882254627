import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from '@core/Utils';
import { REDIRECT_TIME_OUT } from '@utils/globalConstant';
import { useAppConfig } from '@dom-digital-online-media/dom-app-config-sdk';

export function Redirect() {
  // Context
  const { env } = useAppConfig();
  const { t } = useStaticContent();
  const [queryParams] = useSearchParams();
  const url = queryParams.get('target');
  const externalUrl = queryParams.get('external');

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace(`${url}`);
    }, env.REACT_APP_REDIRECT_TIME_OUT || REDIRECT_TIME_OUT);
    // }, REDIRECT_TIME_OUT);

    return () => clearTimeout(timeout);
  }, []);

  const onClick = () => {
    window.location.href = url || externalUrl;
  };

  return (
    // <section className="position-relative bg-offWhite-color gray-shade-1 py-5 mb-5 mb-sm-0">
    <section className="position-relative bg-offWhite-color py-5 mb-5 mb-sm-0">
      <div className="container pt-0 pt-lg-5 py-5 my-0 my-lg-5">
        <div className="row py-5 pb-0 pb-sm-5">
          <div className="col-xl-7 offset-xl-1 col-lg-8 col-md-8 col-12">
            <h1
              className="primary-color-text my-5"
              dangerouslySetInnerHTML={{ __html: t('sh_web_waiting_h2') }}
            />
            <div className="row">
              <div className="col-sm-1">
                <Spinner />
              </div>
              <div className="col-sm-11">
                <div
                  className="body-large-regular primary-color-text"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_waiting_text2') }}
                  onClick={onClick}
                  role="link"
                />
              </div>
            </div>
            {externalUrl && (
              <div
                className="body-large-regular primary-color-text my-4 mt-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_waiting_text3') }}
              />
            )}
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </section>
  );
}

export default Redirect;
