import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Spinner as BSpinner } from 'react-bootstrap';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer, useOption } from '@context/MobileOne';
import { Button, Checkbox } from '@core/Inputs';
import { Col, Container, Row } from '@core/Layout';
import { OptionProduct } from '@part/Option';

export function BookOption() {
  // Contexts
  const { t } = useStaticContent();
  const { allOptions, bookableOptions, isLoading, termsValidations, bookAnOption } = useOption();
  const { optionId } = useParams();
  const {
    isLoading: isCustomerLoading,
    customerBalance: { totalBalance = 0 }
  } = useCustomer();
  const navigate = useNavigate();

  // States
  const [bookOption, setBookOption] = useState({});

  // Hooks
  useEffect(() => {
    if (optionId && allOptions.length > 0) {
      const findOption = allOptions.find(({ id }) => parseInt(id, 10) === parseInt(optionId, 10));
      if (findOption) {
        setBookOption(findOption);
      }
    } else if (optionId && bookableOptions.length > 0) {
      const findOption = bookableOptions.find(
        ({ id }) => parseInt(id, 10) === parseInt(optionId, 10)
      );
      if (findOption) {
        setBookOption(findOption);
      }
    }
  }, [allOptions, bookableOptions, optionId]);

  // Functions
  const onSubmit = () => bookAnOption(optionId);

  const isBookingAllowed =
    totalBalance >= bookOption?.price &&
    bookableOptions.findIndex(({ id }) => parseInt(id, 10) === parseInt(optionId, 10)) > -1;

  return (
    <>
      <Container>
        <div>
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <h2>Option Book</h2>
            {(isLoading || isCustomerLoading) && (
              <BSpinner size="xl" animation="border" role="status" />
            )}
          </div>

          <hr />
          <h4 className="py-4">{bookOption.name}</h4>
          <Formik
            initialValues={{
              terms: false
            }}
            enableReinitialize
            validationSchema={termsValidations}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, errors, touched, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} lg={6}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <OptionProduct {...{ ...bookOption }} />
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="">
                      {bookOption.description}
                      <div className="py-4">
                        <Checkbox
                          className="py-4"
                          type="checkbox"
                          label="You must accept my terms!"
                          id="terms"
                          name="terms"
                          onChange={handleChange}
                          value={values.terms}
                          isInvalid={!!touched.terms && !!errors.terms}
                        />
                      </div>
                      <div className="py-3 w-100 d-inline-flex justify-content-between">
                        <Button
                          variant="secondary"
                          type="button"
                          role="tab"
                          onClick={() => navigate(-1)}
                        >
                          {t('sh_back_button')}
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          isLoading={isLoading}
                          disabled={!isBookingAllowed}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </>
  );
}

export default BookOption;
