import React from 'react';
import { Formik } from 'formik';
import { Form, ListGroup } from 'react-bootstrap';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { Text, Button } from '@core/Inputs';
import { useAccount } from '@context/MobileOne';
import { Col, Container, Row } from '@core/Layout';
import { Badge } from '@core/Utils';
import { validatePasswordWithKeys } from '@utils/globalConstant';

export function PasswordChange({ setIsPasswordChange }) {
  // Context
  const { t } = useStaticContent();
  const { changePasswordInitialValue, isLoading, onChangePasswordSubmit } = useAccount();

  return (
    <Container>
      <>
        <Row>
          <Col md={3}>
            <Button variant="link" onClick={() => setIsPasswordChange(false)}>
              <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
            </Button>
          </Col>
          <Col xs={6}>
            <br />
            <Formik
              initialValues={changePasswordInitialValue}
              validate={(value) => validatePasswordWithKeys(value, 'newPassword')}
              onSubmit={onChangePasswordSubmit}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <h4>{t('sh_md_change_password_headline')}</h4>
                  <Col xs={12} className="py-3">
                    <Text
                      label={`${t('sh_md_change_password_subheadline')}:`}
                      type="text"
                      id="oldPassword"
                      name="oldPassword"
                      onChange={handleChange}
                      value={values.oldPassword}
                      placeholder={t('sh_md_change_current_password')}
                      onBlur={handleBlur}
                      error={touched.password && errors.password ? errors.password : null}
                    />
                  </Col>
                  <Col xs={12} className="py-3">
                    <Text
                      label={`${t('sh_md_set_new_password')}:`}
                      type="password"
                      id="newPassword"
                      name="newPassword"
                      onChange={handleChange}
                      value={values.newPassword}
                      placeholder="**************"
                      isInvalid={!!touched.newPassword && !!errors.newPassword}
                      onBlur={handleBlur}
                      error={touched.newPassword && errors.newPassword ? errors.newPassword : null}
                    />
                  </Col>
                  <br />

                  {values.newPassword && (
                    <ListGroup as="ol">
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                      >
                        {t('sh_md_password_requirement1')}
                        <>
                          {errors && errors.otherErrors && !errors.otherErrors.minMax ? (
                            <Badge bg="danger" pill>
                              {t('Invalid')}
                            </Badge>
                          ) : (
                            values.newPassword && (
                              <Badge bg="success" pill>
                                {t('Valid')}
                              </Badge>
                            )
                          )}
                        </>
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                      >
                        {t('sh_md_password_requirement2')}
                        <>
                          {errors &&
                          errors.otherErrors &&
                          (!errors.otherErrors.lowecase || !errors.otherErrors.uppercase) ? (
                            <Badge bg="danger" pill>
                              {t('Invalid')}
                            </Badge>
                          ) : (
                            values.newPassword && (
                              <Badge bg="success" pill>
                                {t('Valid')}
                              </Badge>
                            )
                          )}
                        </>
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                      >
                        {t('sh_md_password_requirement3')}
                        <>
                          {errors && errors.otherErrors && !errors.otherErrors.number ? (
                            <Badge bg="danger" pill>
                              {t('Invalid')}
                            </Badge>
                          ) : (
                            values.newPassword && (
                              <Badge bg="success" pill>
                                {t('Valid')}
                              </Badge>
                            )
                          )}
                        </>
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                      >
                        {t('sh_md_password_requirement4')}
                        <>
                          {errors && errors.otherErrors && !errors.otherErrors.specific ? (
                            <Badge bg="danger" pill>
                              {t('Invalid')}
                            </Badge>
                          ) : (
                            values.newPassword && (
                              <Badge bg="success" pill>
                                {t('Valid')}
                              </Badge>
                            )
                          )}
                        </>
                      </ListGroup.Item>
                    </ListGroup>
                  )}

                  <Col xs={12} className="py-3">
                    <Button variant="primary" type="submit" isLoading={isLoading}>
                      {t('sh_md_set_password_button')}
                    </Button>
                  </Col>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </>
    </Container>
  );
}

export default PasswordChange;
