/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { appStorage } from '@dom-digital-online-media/dom-app-config-sdk';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function setCookie(key, value) {
  try {
    if (key === appStorage.USER_AUTH_DATA && value) {
      const removeToken =
        typeof value === 'object'
          ? value
          : typeof value === 'string'
          ? JSON.parse(value) || {}
          : {};
      delete removeToken.access_token;
      delete removeToken.refresh_token;
      cookies.set(key, JSON.stringify(removeToken));
      return true;
    }
    cookies.set(key, value);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export function getCookie(key) {
  try {
    return cookies.get(key);
  } catch (error) {
    console.log(error);
    return false;
  }
}

export function removeCookie(key) {
  try {
    cookies.remove(key);
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export default {
  setCookie,
  getCookie,
  removeCookie
};
