import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { Button, Text } from '@core/Inputs';

import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { useActivation } from '@context/MobileOne';
import {
  appActivationFlowType,
  appActivationStep1Modals,
  appButtonType,
  appImages,
  appRoute,
  appStaticPdf,
  appTariff
} from '@utils/globalConstant';

import { ALLNET_FLAT_L, UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';
import FootNotesModal from './FootNotesModal';
import MGMCodeModal from './MGMCodeModal';
import { useNavigate } from 'react-router-dom';
import PriceDetailsModal from './PriceDetailsModal';
import TariffDetailsModal from './TariffDetailsModal';
import NewFootNotesModal from './NewFootNotesModal';
import NewTariffModal from './NewTariffModal';
import './style.scss';

export function SelectTariff() {
  // States
  const [modal, setModal] = useState('');
  const [tariffBullet, setTariffBullet] = useState([]);
  const [mgmBullet, setMgmBullet] = useState([]);
  // Context
  const { t, getPdfList, staticContentData } = useStaticContent();
  const {
    activeTariff,
    setActiveTariff,
    bookableTariff,
    setBookableTariff,
    validationSchema,
    formValue,
    isLoading,
    onSubmit,
    setFormValue,
    applyMGMCode
  } = useActivationTariff();
  const { activationInfo, prevStep, activationType } = useActivation();
  const navigate = useNavigate();

  // Hooks
  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied, activeTariff: { name = '' } = { name: '' } } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
        const tariffName = `${name}`.toLocaleLowerCase().replaceAll(' ', '-');
        const tagInfo = isPromoApplied
          ? tariffName === ALLNET_FLAT_L
            ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.SELECT_TARIFF_MOUNTING.ALLNET_FLAT_L_MGM_ACTIVE
            : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.SELECT_TARIFF_MOUNTING.ALLNET_FLAT_M_MGM_ACTIVE
          : tariffName ===
            UTAG_VIEWS.POS_CUSTOMER_MOUNTING.SELECT_TARIFF_MOUNTING.ALLNET_FLAT_L_MGM_NON_ACTIVE
              .shop_product_name
          ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.SELECT_TARIFF_MOUNTING.ALLNET_FLAT_L_MGM_NON_ACTIVE
          : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.SELECT_TARIFF_MOUNTING.ALLNET_FLAT_M_MGM_NON_ACTIVE;

        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  useEffect(() => {
    if (staticContentData) {
      setTimeout(() => {
        const startModal = document.querySelector('.star-btn');
        if (startModal) {
          startModal.addEventListener('click', () => {
            setModal(appActivationStep1Modals.NEW_FOOTNOTES);
          });
        }
      }, 300);
    }
    if (staticContentData && bookableTariff && bookableTariff?.bookableTariffs) {
      bookableTariff.bookableTariffs = bookableTariff.bookableTariffs.map((bt) => {
        const scTariff = staticContentData.tariff_share.find((stf) => stf.id === bt.id);
        scTariff.duration = bt.duration;
        scTariff.durationUnit = bt.durationUnit;
        return scTariff;
      });
      const defaultTariff =
        !bookableTariff.msisdn ||
        bookableTariff.bookableTariffs[0].name.toLowerCase() === 'allen flat m'
          ? bookableTariff.bookableTariffs.find((bt) => bt.defaultTariff)
          : bookableTariff.bookableTariffs[0];

      setBookableTariff(JSON.parse(JSON.stringify(bookableTariff)));

      if (defaultTariff) {
        setFormValue({
          ...formValue,
          tariff: defaultTariff.id
        });
        setActiveTariff({ ...defaultTariff });
      }
    }
  }, [staticContentData]);

  useEffect(() => {
    let tariffDetailBulletsList = [];
    tariffDetailBulletsList = !applyMGMCode.promoCode
      ? activeTariff?.detailInfo?.additionalInfo?.bullets
      : activeTariff.operationsInfo.ON_MGM_CODE_APPLY.bullets;
    if (tariffDetailBulletsList) {
      let value = tariffDetailBulletsList ? tariffDetailBulletsList[2] : '';
      tariffDetailBulletsList = !bookableTariff.msisdn
        ? tariffDetailBulletsList
        : tariffDetailBulletsList.filter((item) => item !== value);
      setTariffBullet(tariffDetailBulletsList);
      setMgmBullet(tariffDetailBulletsList);
    }
  }, [activeTariff, bookableTariff, applyMGMCode]);

  const openPdf = (pdfId, pdfName) => {
    if (staticContentData) {
      let pdfInfo = getPdfList('sh_pdfLists').find((item) => item.listName === pdfName);
      window.open(pdfInfo.listContent.find(({ id }) => id === pdfId).media_url_display);
    }
  };

  return (
    <Fragment>
      <div className="container">
        <div className="row position-relative">
          {/* <div className="col-12 orange-gray-bubble"></div> */}
          <div className="col-md-3 pb-5 back-button">
            <div className="back-btn">
              <a
                className="text-white body-medium-bold text-decoration-none link-light"
                onClick={prevStep}
              >
                <i className="fa fa-arrow-left me-3" />
                {t('sh_back_button')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-sm-5">
        {activeTariff && (
          <>
            <div className="row">
              <div className="col-12 col-md-11 offset-md-1 col-lg-7 offset-lg-1">
                <h6 className="text-uppercase text-white mt-2 mb-0">{t('sh_apoc_step2/9')}</h6>
                {/* sx={{ backgroundColor: activeTariff.additionalInfo.primaryColor }} */}
                <h1
                  className="pink-color-text mt-2 mt-sm-2"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_h2') }}
                />
                {bookableTariff.bookableTariffs.length > 1 && (
                  <p
                    className="text-white body-large-regular body-large-24 mt-sm-4 mt-4"
                    dangerouslySetInnerHTML={{ __html: t('sh_apoc_step2/9_text2') }}
                  />
                )}
                <Formik
                  enableReinitialize
                  initialValues={
                    activationInfo?.activeTariff?.id
                      ? {
                          ...formValue,
                          isPromoApplied: activeTariff.promoCode || false,
                          codeForValidation: activeTariff.promoCode,
                          tariff: activationInfo.activeTariff.id
                        }
                      : {
                          ...formValue,
                          isPromoApplied: activeTariff.promoCode || false,
                          codeForValidation: activeTariff.promoCode
                        }
                  }
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    if (window && window.utag) {
                      appTariff.FLAT_M === values.tariff
                        ? window.utag.link(
                            UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_M
                              .SELECT_TARIFF_ON_CLICK
                          )
                        : window.utag.link(
                            UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_L
                              .SELECT_TARIFF_ON_CLICK
                          );
                    }
                    onSubmit(values);
                  }}
                >
                  {({
                    values: { tariff, isPromoApplied, codeForValidation },
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    handleBlur,
                    handleChange,
                    errors,
                    touched,
                    setErrors,
                    setTouched
                  }) => (
                    <Form
                      className="col-md-10 pt-3 pb-5 pb-sm-0 mb-5 mb-sm-0"
                      onSubmit={handleSubmit}
                    >
                      <div className="border border-white p-3 box-radius position-relative">
                        {/* <div className="tariff-image text-center w-full">
                          <img
                            src={appImages.newPinkTeaser}
                            role="button"
                            onClick={() => setModal(appActivationStep1Modals.NEW_TARIFF_MODAL)}
                            alt="Bubble Image"
                          />
                        </div> */}
                        <div className="d-flex justify-content-between mt-4 mb-4 pb-2">
                          {bookableTariff.bookableTariffs?.map((tariff, index) => (
                            <div
                              key={`${tariff.id}-tariff-card`}
                              role="button"
                              className={
                                activeTariff.id === tariff.id
                                  ? 'tarrif-selection active'
                                  : 'tarrif-selection'
                              }
                              onClick={() => {
                                if (window && window.utag) {
                                  tariff.id === appTariff.FLAT_M
                                    ? window.utag.link(
                                        UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_M
                                          .SELECT_ALLNET_FLAT_M_ON_CLICK
                                      )
                                    : window.utag.link(
                                        UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_L
                                          .SELECT_ALLNET_FLAT_L_ON_CLICK
                                      );
                                }

                                setFieldValue('tariff', tariff.id);
                                setActiveTariff({ ...tariff });
                              }}
                            >
                              <div className="d-flex align-items-baseline">
                                <h3 className="body-medium-regular mango-color-text">
                                  {tariff.name}
                                </h3>
                              </div>
                              <div className="mango-color-text">
                                <h2 className="mb-0">
                                  {tariff.additionalInfo.volume?.DATA?.value}{' '}
                                  {tariff.additionalInfo.volume?.DATA?.unit}
                                </h2>
                                <div className="d-flex align-items-baseline">
                                  <h3
                                    className="body-medium-regular mango-color-text"
                                    dangerouslySetInnerHTML={{
                                      __html: tariff?.detailInfo?.subHeader
                                    }}
                                  />
                                  {/* <h3
                                    className="body-medium-regular mango-color-text tarif-overview-text"
                                    dangerouslySetInnerHTML={{
                                      __html: t(
                                        tariff.id === appTariff.FLAT_M
                                          ? 'sh_apoc_tarif_overview_info_text2'
                                          : 'sh_apoc_tarif_overview_info_text3'
                                      )
                                    }}
                                  /> */}
                                </div>
                                {/* {!activeTariff.promoCode ? (
                                ) : (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: tariff?.operationsInfo?.ON_MGM_CODE_APPLY?.subHeader
                                    }}
                                  />
                                )} */}

                                {bookableTariff.bookableTariffs?.length > 1 &&
                                  tariff.id === 6500 && (
                                    <p
                                      className="mb-0 body-small-regular font-14"
                                      dangerouslySetInnerHTML={{
                                        __html: t('sh_apoc_tarif_overview_text3')
                                      }}
                                    />
                                  )}
                                {bookableTariff.bookableTariffs?.length > 1 &&
                                  tariff.id === 6501 && (
                                    <p
                                      className="mb-0 body-small-regular font-14"
                                      dangerouslySetInnerHTML={{
                                        __html: t('sh_apoc_tarif_overview_text2')
                                      }}
                                    />
                                  )}
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* <div className="tarrif-selection">
                            <div className="d-flex align-items-baseline">
                              <h3 className="body-medium-regular mango-color-text">Allnet Flat L</h3>
                            </div>
                            <div className="mango-color-text">
                              <h2 className="mb-0">8 GB</h2>
                              <p className="mb-0">Dein gekauftes Starterpaket.</p>
                            </div>


                          </div> */}
                        {/* <div className="d-flex justify-content-between p-3 bg-purple-light-color box-radius my-4">
                          <div className="d-block">
                            <p className="label-medium text-uppercase text-white">
                              {t('sh_apoc_start_initial_price_header').replace(
                                '{{price}}',
                                activeTariff.starterPackPrice
                              )}
                            </p>
                            <p className="label-small text-white mb-0">
                              {t('sh_apoc_tarif_overview_text5')}
                            </p>
                          </div>
                          <Button
                            buttonType={appButtonType.LINK}
                            type="button"
                            className="shadow-none bg-transparent"
                            onClick={() => {
                              if (window && window.utag) {
                                activeTariff.id === appTariff.FLAT_M
                                  ? window.utag.link(
                                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_M
                                        .MORE_INFO_ON_CLICK
                                    )
                                  : window.utag.link(
                                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_L
                                        .MORE_INFO_ON_CLICK
                                    );
                              }
                              setModal('priceDetails');
                            }}
                          >
                            {' '}
                            <img
                              className="img-fluid"
                              src={appImages.InfoIconWhite}
                              alt="Info icon"
                            />
                          </Button>
                        </div> */}

                        <ul className="list-unstyled tarrif-feature d-grid gap-2">
                          {/* {!activeTariff.promoCode
                            ? tariffBullet.map((bt, index) => (
                                <div
                                  className="body-medium-regular text-white list-group-item"
                                  key={`${index}-bullet-tariff`}
                                  dangerouslySetInnerHTML={{ __html: bt.header }}
                                />
                              ))
                            : mgmBullet.map((bt, index) => (
                                <div
                                  className="body-medium-regular text-white list-group-item"
                                  key={`${index}-bullet-tariff-2`}
                                  dangerouslySetInnerHTML={{
                                    __html: bt.header
                                  }}
                                />
                              ))} */}
                          {(
                            activeTariff?.detailInfo?.additionalInfo
                              ?.bulletsActivationTariffSelection || []
                          ).map((bullet, key) => (
                            <li
                              className="body-medium-regular text-white list-group-item"
                              key={`${key + 1}-tariff-bullets`}
                              dangerouslySetInnerHTML={{ __html: bullet.header }}
                            />
                          ))}
                        </ul>
                        <div className="d-flex justify-content-between p-3 bg-purple-light-color box-radius my-4">
                          <div className="d-block">
                            <p className="label-medium text-uppercase text-white">
                              {t('sh_apoc_start_initial_price_header').replace(
                                '{{price}}',
                                activeTariff.starterPackPrice
                              )}
                            </p>
                            <p className="label-small text-white mb-0">
                              {t('sh_apoc_tarif_overview_text5')}
                            </p>
                          </div>
                          <Button
                            buttonType={appButtonType.LINK}
                            type="button"
                            className="shadow-none bg-transparent"
                            onClick={() => {
                              if (window && window.utag) {
                                activeTariff.id === appTariff.FLAT_M
                                  ? window.utag.link(
                                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_M
                                        .MORE_INFO_ON_CLICK
                                    )
                                  : window.utag.link(
                                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_L
                                        .MORE_INFO_ON_CLICK
                                    );
                              }
                              setModal(appActivationStep1Modals.PRICE_DETAILS);
                            }}
                          >
                            {' '}
                            <img
                              className="img-fluid"
                              src={appImages.InfoIconWhite}
                              alt="Info icon"
                            />
                          </Button>
                        </div>
                        <div
                          className=" d-md-flex justify-content-between mb-2"
                          style={{ alignItems: 'center' }}
                        >
                          <div
                            className="d-flex m-2 align-text-bottom"
                            style={{ alignItems: 'center' }}
                          >
                            <h4 className="text-white">{activeTariff.price} &euro; </h4>{' '}
                            <p className="text-white body-medium-regular mb-0">
                              &nbsp; / {activeTariff.duration}&nbsp;{activeTariff.durationUnit}
                            </p>
                            <div
                              className="astrick"
                              role="button"
                              onClick={() => setModal(appActivationStep1Modals.FOOTNOTES)}
                            >
                              {/* <i
                                className="bi bi-star-fill primary-color-text"
                                style={{ position: 'relative' }}
                              /> */}
                              <img
                                className="img-fluid"
                                src={appImages.footnote}
                                alt="foot note icon"
                              />
                            </div>
                            {/* <Button
                              type="button"
                              className="px-1 py-0 border border-2 rounded-circle btn-sm"
                              onClick={() => setModal('footNotes')}
                              style={{ marginTop: '-15px', marginLeft: '2px' }}>
                              {
                                <i
                                  className="bi bi-star-fill text-white"
                                  style={{ position: 'relative' }}
                                />
                              }
                            </Button> */}
                          </div>
                          <div className="pb-2">
                            {modal === appActivationStep1Modals.MGM_CODE && (
                              <MGMCodeModal
                                {...{
                                  setModal,
                                  handleChange,
                                  handleBlur,
                                  codeForValidation,
                                  touched,
                                  errors,
                                  setErrors,
                                  setTouched,
                                  applyMGMCode,
                                  isLoading
                                }}
                              />
                            )}
                            <div xs={12} className={activeTariff.promoCode ? 'col d-flex' : 'col'}>
                              <input type="hidden" name="isPromoApplied" value={isPromoApplied} />
                              {!isPromoApplied && (
                                <a
                                  className="text-white label-small p-0 mb-5 mb-sm-0 link-light tariff-link shadow-none"
                                  variant="link"
                                  onClick={() => {
                                    if (window && window.utag) {
                                      activeTariff.id === appTariff.FLAT_M
                                        ? window.utag.link(
                                            UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF
                                              .ALLNET_FLAT_M.REDEEM_CODE_ON_CLICK
                                          )
                                        : window.utag.link(
                                            UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF
                                              .ALLNET_FLAT_L.REDEEM_CODE_ON_CLICK
                                          );
                                    }
                                    setModal(appActivationStep1Modals.MGM_CODE);
                                  }}
                                >
                                  {t('sh_apoc_tarif_overview_voucher')}
                                </a>
                              )}
                              {/* {!isPromoApplied ? (
                                <a
                                  className="text-white label-small p-0 mb-5 mb-sm-0 link-light shadow-none"
                                  variant="link"
                                  onClick={() => {
                                    setModal(appActivationStep1Modals.MGM_CODE);
                                  }}>
                                  {t('sh_apoc_tarif_overview_voucher')}
                                </a>
                              ) : (
                                <>
                                  {activeTariff.promoCode && (
                                    <div className="d-block">
                                      <b>
                                        {t('sh_apoc_tarif_overview_voucher').replace(
                                          ' ',
                                          ` "${activeTariff.operationsInfo.promoCode}" `
                                        )}
                                      </b>
                                      <p>
                                        {t('sh_apoc_tarif_overview_voucher-msg2').replace(
                                          '{{value}}',
                                          `${activeTariff.operationsInfo.codeGBVolume}`
                                        )}
                                      </p>
                                    </div>
                                  )}
                                  <a
                                    className="m-3 text-white body-medium-regular p-0 mb-5 mb-sm-0 link-light shadow-none"
                                    variant="link"
                                    onClick={() => {
                                      setFieldValue('isPromoApplied', false);
                                      setFieldValue('codeForValidation', '');
                                      setFieldTouched('codeForValidation', false, false);
                                      const bookableTariffsIds =
                                        bookableTariff?.bookableTariffs.map((bt) => bt.id);
                                      bookableTariff.bookableTariffs = [
                                        ...staticContentData.tariff_share.filter(
                                          (bt) => bookableTariffsIds.indexOf(bt.id) > -1
                                        )
                                      ];
                                      const defaultTariff = !bookableTariff.msisdn
                                        ? bookableTariff.bookableTariffs.find(
                                            (bt) => bt.defaultTariff
                                          )
                                        : bookableTariff.bookableTariffs[0];

                                      setFormValue({ ...formValue, tariff: defaultTariff.id });
                                      setActiveTariff(defaultTariff);
                                      setBookableTariff(JSON.parse(JSON.stringify(bookableTariff)));
                                    }}>
                                    {t('sh_apoc_tarif_overview_delete')}
                                  </a>
                                </>
                              )} */}
                            </div>
                          </div>
                        </div>
                        {activationType === appActivationFlowType.NEW_ONLINE && (
                          <div className="d-flex">
                            <div
                              className="row text-white label-small link-light tariff-link shadow-none m-0 p-0 mt-2"
                              dangerouslySetInnerHTML={{
                                __html: t('sh_apoc_tarif_overview_text7')
                              }}
                            />
                            <div
                              className="astrick"
                              role="button"
                              onClick={() => {
                                setModal(appActivationStep1Modals.NEW_TARIFF_MODAL);
                              }}
                            >
                              {/* <i
                        className="bi bi-star-fill primary-color-text"
                        style={{ position: 'relative' }}
                      /> */}
                              <img
                                className="img-fluid"
                                src={appImages.footnote}
                                alt="foot note icon"
                              />
                            </div>
                          </div>
                        )}
                        {activeTariff?.promoCode && (
                          <div className="row p-3 bg-purple-light-color box-radius mx-0 mt-2">
                            <div className="col-12 p-0">
                              <p className="label-small text-white">
                                {t('sh_apoc_tarif_overview_voucher-code-msg1').replace(
                                  '{{mgmCode}}',
                                  activeTariff?.promoCode
                                )}
                              </p>
                              <p className="label-medium text-uppercase text-white mb-0">
                                {t('sh_apoc_tarif_overview_voucher-code-msg2').replace(
                                  '{{dataVolume}}',
                                  ' ' + activeTariff?.operationsInfo?.codeGBVolume
                                )}
                              </p>
                            </div>
                            <div className="col-12 px-0 pt-2">
                              <a
                                className="text-white label-small p-0 mb-5 mb-sm-0 link-light tariff-link shadow-none"
                                variant="link"
                                onClick={() => {
                                  setFieldValue('isPromoApplied', false);
                                  setFieldValue('codeForValidation', '');
                                  setFieldTouched('codeForValidation', false, false);
                                  const bookableTariffsIds = bookableTariff?.bookableTariffs.map(
                                    (bt) => bt.id
                                  );
                                  bookableTariff.bookableTariffs = [
                                    ...staticContentData.tariff_share.filter(
                                      (bt) => bookableTariffsIds.indexOf(bt.id) > -1
                                    )
                                  ];
                                  const defaultTariff =
                                    bookableTariff.bookableTariffs.length > 1
                                      ? bookableTariff.bookableTariffs.find(
                                          (bt) => bt.id === activeTariff.id
                                        )
                                      : bookableTariff.bookableTariffs[0];

                                  setFormValue({ ...formValue, tariff: defaultTariff.id });
                                  setActiveTariff(defaultTariff);
                                  setBookableTariff(JSON.parse(JSON.stringify(bookableTariff)));
                                }}
                              >
                                {t('sh_apoc_tarif_overview_delete')}
                              </a>
                            </div>
                          </div>
                        )}
                        <div className={activeTariff?.promoCode ? 'mt-4' : 'mt-2'}>
                          <Button
                            buttonType={appButtonType.PRIMARY}
                            className="w-100 bg-offWhite-color"
                            type="submit"
                            isLoading={isLoading}
                          >
                            {t('sh_apoc_activation_button')}
                          </Button>
                        </div>

                        <div className="d-grid gap-3 d-md-flex justify-content-between mt-4 pt-2">
                          <a
                            className="text-white label-small shadow-none"
                            variant="link"
                            onClick={() => {
                              if (window && window.utag) {
                                activeTariff.id === appTariff.FLAT_M
                                  ? window.utag.link(
                                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_M
                                        .TARIFF_DETAILS_ON_CLICK
                                    )
                                  : window.utag.link(
                                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_L
                                        .TARIFF_DETAILS_ON_CLICK
                                    );
                              }

                              setModal(appActivationStep1Modals.TARIFF_DETAILS);
                            }}
                          >
                            {t('sh_apoc_tarif_overview_tarif-details')}
                          </a>
                          <a
                            className="btn-link text-white label-small shadow-none"
                            variant="link"
                            onClick={() => {
                              if (window && window.utag) {
                                activeTariff.id === appTariff.FLAT_M
                                  ? window.utag.link(
                                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_M
                                        .PRODUCT_INFORMATION_ON_CLICK
                                    )
                                  : window.utag.link(
                                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_L
                                        .PRODUCT_INFORMATION_ON_CLICK
                                    );
                              }

                              openPdf(
                                activeTariff?.additionalInfo?.document?.productInformation.id,
                                appStaticPdf.PRODUCT_INFO
                              );
                            }}
                          >
                            {t('sh_apoc_tarif_overview_product-info')}
                          </a>
                          <a
                            className="btn-link text-white label-small shadow-none"
                            variant="link"
                            onClick={() => {
                              if (window && window.utag) {
                                activeTariff?.id === appTariff.FLAT_M
                                  ? window.utag.link(
                                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_M
                                        .PRICE_LIST_ON_CLICK
                                    )
                                  : window.utag.link(
                                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SELECT_TARIFF.ALLNET_FLAT_L
                                        .PRICE_LIST_ON_CLICK
                                    );
                              }

                              openPdf(
                                activeTariff?.additionalInfo?.document?.priceList.id,
                                appStaticPdf.AGB_SERVICES
                              );
                            }}
                          >
                            {t('sh_apoc_tarif_overview_prices')}
                          </a>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>

            {modal === appActivationStep1Modals.TARIFF_DETAILS && (
              <TariffDetailsModal setModal={setModal} />
            )}
            {modal === appActivationStep1Modals.FOOTNOTES && <FootNotesModal setModal={setModal} />}
            {modal === appActivationStep1Modals.NEW_FOOTNOTES && (
              <NewFootNotesModal setModal={setModal} />
            )}
            {modal === appActivationStep1Modals.NEW_TARIFF_MODAL && (
              <NewTariffModal setModal={setModal} />
            )}
            {modal === appActivationStep1Modals.PRICE_DETAILS && (
              <PriceDetailsModal setModal={setModal} />
            )}
          </>
        )}
      </div>
    </Fragment>
  );
}

export default SelectTariff;
