// Tracking Views
// These methods are called in the following order when
// - <COMPONENT>_MOUNTING - A instance of a component is being created and inserted into the DOM:
// - <COMPONENT>_UPDATING -  An update can be caused by changes to props or state.
// - <COMPONENT>_UNMOUNTING - When a component is being removed from the DOM
// - <COMPONENT>_ERROR - An error occured while any operation on the component

export const ALLNET_FLAT_M = {
  shop_product_name: 'allnet-flat-m',
  shop_product_price: '10.00',
  shop_product_quantity: '1'
};

export const ALLNET_FLAT_L = {
  shop_product_name: 'allnet-flat-l',
  shop_product_price: '15.00',
  shop_product_quantity: '1'
};

export const UTAG_VIEWS = {
  LOGIN_MOUNTING: {
    page_type: 'web',
    view_name: 'login',
    event_type: 'load',
    event_name: 'on_load',
    tealium_event: 'view_loaded'
  },

  // 4.1 Landingpages
  APP_DOWNLOAD_MOUNTING: {
    page_content_id: 'www.share-mobile_de.app-download'
  },

  TOPUP_CREDIT_MOUNTING: {
    page_content_id: 'www.share-mobile_de.aufladen'
  },

  CONTACT_MOUNTING: {
    page_content_id: 'www.share-mobile_de.hilfe-service.kontakt'
  },

  SIM_ACTIVATION_MOUNTING: {
    page_content_id: 'www.share-mobile_de.aktivieren'
  },

  PRIVACY_MOUNTING: {
    page_content_id: 'www.share-mobile_de.datenschutz'
  },

  CANCELLATION_RIGHTS_MOUNTING: {
    page_content_id: 'www.share-mobile_de.widerruf'
  },

  RETURN_POLICY_MOUNTING: {
    page_content_id: 'www.share-mobile_de.hinweise-zu-batterien-und-altgeraeten'
  },

  AGB_MOUNTING: {
    page_content_id: 'www.share-mobile_de.agb'
  },

  // TODO: Page Not Added
  MANDATORY_INFO_MOUNTING: {
    page_content_id: 'www.share-mobile_de.pflichtangaben'
  },

  SHARING_MOUNTING: {
    page_content_id: 'www.share-mobile_de.for-friends'
  },

  IDENT_PENDING_MOUNTING: {
    page_content_id: 'www.share-mobile_de.aktivieren.ident-ausstehend'
  },

  IDENT_SUCCESS_MOUNTING: {
    page_content_id: 'www.share-mobile_de.aktivieren.ident-erfolgreich'
  },

  IDENT_FAILED_MOUNTING: {
    page_content_id: 'www.share-mobile_de.aktivieren.ident-fehlgeschlagen'
  },

  FAQ_MOUNTING: {
    page_content_id: 'www.share-mobile_de.hilfe-service.faq'
  },

  ROAMING_MOUNTING: {
    page_content_id: 'www.share-mobile_de.roaming'
  },

  CANCEL_CONTRACT_MOUNTING: {
    page_content_id: 'www.share-mobile_de.vertrag-jetzt-kuendigen'
  },

  CANCEL_CONTRACT_SUCCESS_MOUNTING: {
    page_content_id: 'www.share-mobile_de.vertrag-jetzt-kuendigen.abgeschlossen'
  },

  GENERIC_GREETINGS_MOUNTING: {
    page_content_id: 'www.share-mobile_de.projekt'
  },

  WHY_SHARE_MOBILE_MOUNTING: {
    page_content_id: 'www.share-mobile_de.ueber-share-mobile'
  },

  PAGE_NOT_FOUND_MOUNTING: {
    page_content_id: 'www.share-mobile_de.404'
  },

  // 4.2 Bestellprozess – Online-Neukunde
  ONLINE_CUSTOMER_MOUNTING: {
    // 4.2.1 Home
    HOME_MOUNTING: {
      ROOT: {
        page_content_id: 'www.share-mobile_de.home',
        shop_product_status: 'view',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv, mgm-nicht-aktiv',
        shop_product_name: 'allnet-flat-m;allnet-flat-l',
        shop_product_price: '10.00;15.00',
        shop_product_quantity: '1;1',
        shop_product_status: 'view'
      },

      ALLNET_FLAT_M_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.home',
        shop_product_status: 'view',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_M_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.home',
        shop_product_status: 'view',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_L_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.home',
        shop_product_status: 'view',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        ...ALLNET_FLAT_L
      },
      ALLNET_FLAT_L_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.home',
        shop_product_status: 'view',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        ...ALLNET_FLAT_L
      }
    },

    // 4.2.1.1 Code einlösen
    REDEEN_CODE_MOUNTING: {
      page_content_id: 'www.share-mobile_de.home.code-einloesen',
      customer_type: 'online-neukunde'
    },

    // 4.2.2 Rufnummer
    NUMBER_MOUNTING: {
      ALLNET_FLAT_M_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme',
        shop_product_status: 'add',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_M_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme',
        shop_product_status: 'add',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_L_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme',
        shop_product_status: 'add',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        ...ALLNET_FLAT_L
      },
      ALLNET_FLAT_L_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme',
        shop_product_status: 'add',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        ...ALLNET_FLAT_L
      }
    },

    // 4.2.2.1 Neue Rufnummer
    NEW_NUMBER_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme.neue-rufnummer',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme.neue-rufnummer',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.2.2 Rufnummernmitnahme - Anbieter
    NUMBER_PORTABILITY_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme.anbieter',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme.anbieter',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.2.3 Rufnummernmitnahme - Rufnummer
    CURRENT_NUMBER_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme.rufnummer',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme.rufnummer',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.2.4 Rufnummernmitnahme - Informationen
    CONTINUE_NUMBER_PORTABILITY_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme.zeitpunkt',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.rufnummernmitnahme.zeitpunkt',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.3 Sim
    SELECT_SIM_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.sim-auswahl',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.sim-auswahl',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.3.1 eSIM Details
    ESIM_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.sim-auswahl.esim-details',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.sim-auswahl.esim-details',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.3.2 eSIM Geräteunterstützung
    ESIM_DEVICE_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.sim-auswahl.esim-geraetepruefung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.sim-auswahl.esim-geraetepruefung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.4 Email
    EMAIL_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.e-mail-authentifizierung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.e-mail-authentifizierung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.4.1 Email - Bestätigungscode
    EMAIL_CONFIRMATION_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.e-mail-authentifizierung.bestaetigungscode',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.e-mail-authentifizierung.bestaetigungscode',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.5 Zahlungsmethode
    PAYMENT_METHOD_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.zahlungsmethode',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.zahlungsmethode',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.6 Persönliche Daten
    PERSONAL_DATA_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.persoenliche-daten',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.persoenliche-daten',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.7 Login
    LOGIN_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.login-daten',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.login-daten',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.2.8 Zusammenfassung
    OVERVIEW_MOUNTING: {
      ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.zusammenfassung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_M_NEW_NUMBER_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.zusammenfassung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.zusammenfassung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_M_NEW_NUMBER_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.zusammenfassung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.zusammenfassung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        ...ALLNET_FLAT_L
      },
      ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.zusammenfassung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        ...ALLNET_FLAT_L
      },
      ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.zusammenfassung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        ...ALLNET_FLAT_L
      },
      ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.checkout.zusammenfassung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        ...ALLNET_FLAT_L
      }
    },

    // 4.2.9 Bestellbestätigung
    ORDER_CONFIRMATION_MOUNTING: {
      ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.checkout.bestaetigung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_M.shop_product_price,
        ...ALLNET_FLAT_M
      }),
      ALLNET_FLAT_M_NEW_NUMBER_MGM_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.checkout.bestaetigung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_M.shop_product_price,
        ...ALLNET_FLAT_M
      }),
      ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_NON_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.checkout.bestaetigung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_M.shop_product_price,
        ...ALLNET_FLAT_M
      }),
      ALLNET_FLAT_M_NEW_NUMBER_MGM_NON_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.checkout.bestaetigung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_M.shop_product_price,
        ...ALLNET_FLAT_M
      }),
      ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.checkout.bestaetigung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_L.shop_product_price,
        ...ALLNET_FLAT_L
      }),
      ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.checkout.bestaetigung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_L.shop_product_price,
        ...ALLNET_FLAT_L
      }),
      ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_NON_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.checkout.bestaetigung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_L.shop_product_price,
        ...ALLNET_FLAT_L
      }),
      ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.checkout.bestaetigung',
        customer_type: 'online-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_L.shop_product_price,
        ...ALLNET_FLAT_L
      })
    }
  },

  // 4.3 Aktivierungsstrecke
  POS_CUSTOMER_MOUNTING: {
    // 4.3.1 SIM-Karte aktivieren
    SIM_ACTIVATION_MOUNTING: {
      page_content_id: 'www.share-mobile_de.aktivieren.kartendaten',
      customer_type: 'pos-neukunde',
      mgm_code: 'mgm-nicht-aktiv'
    },

    SIM_ACTIVATION_ERROR: {
      page_content_id: 'www.share-mobile_de.aktivieren.kartendaten',
      customer_type: 'pos-neukunde',
      mgm_code: 'mgm-nicht-aktiv'
    },

    // 4.3.2 Tarifübersicht
    SELECT_TARIFF_MOUNTING: {
      ALLNET_FLAT_M_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.tarifauswahl',
        shop_product_status: 'view',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_M_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.tarifauswahl',
        shop_product_status: 'view',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_L_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.tarifauswahl',
        shop_product_status: 'view',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        ...ALLNET_FLAT_L
      },
      ALLNET_FLAT_L_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.tarifauswahl',
        shop_product_status: 'view',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        ...ALLNET_FLAT_L
      }
    },

    // 4.3.2.1 Code einlösen
    REDEEN_CODE_MOUNTING: {
      page_content_id: 'www.share-mobile_de.aktivieren.code-einloesen',
      customer_type: 'pos-neukunde'
    },

    // 4.3.3 Rufnummer
    NUMBER_MOUNTING: {
      ALLNET_FLAT_M_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.rufnummernmitnahme',
        shop_product_status: 'add',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_M_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.rufnummernmitnahme',
        shop_product_status: 'add',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_L_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.rufnummernmitnahme',
        shop_product_status: 'add',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        ...ALLNET_FLAT_L
      },
      ALLNET_FLAT_L_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.rufnummernmitnahme',
        shop_product_status: 'add',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        ...ALLNET_FLAT_L
      }
    },

    // 4.3.3.1 Neue Rufnummer
    NEW_NUMBER_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.rufnummernmitnahme.neue-rufnummer',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.rufnummernmitnahme.neue-rufnummer',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.3.3.2 Rufnummernmitnahme - Anbieter
    NUMBER_PORTABILITY_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.rufnummernmitnahme.anbieter',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.rufnummernmitnahme.anbieter',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.3.3.3 Rufnummernmitnahme - Rufnummer
    CURRENT_NUMBER_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktiviere.rufnummernmitnahme.rufnummer',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktiviere.rufnummernmitnahme.rufnummer',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.3.3.4 Rufnummernmitnahme - Informationen
    CONTINUE_NUMBER_PORTABILITY_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.rufnummernmitnahme.zeitpunkt',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.rufnummernmitnahme.zeitpunkt',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.3.5 Email
    EMAIL_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.e-mail-authentifizierung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.e-mail-authentifizierung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.3.5.1 Email - Bestätigungscode
    EMAIL_CONFIRMATION_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id:
          'www.share-mobile_de.aktivieren.e-mail-authentifizierung.bestaetigungscode',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id:
          'www.share-mobile_de.aktivieren.e-mail-authentifizierung.bestaetigungscode',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.3.6 Automatische Aufladung
    PAYMENT_METHOD_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.automatische-aufladung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.automatische-aufladung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.3.7 Persönliche Daten
    PERSONAL_DATA_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.persoenliche-daten',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.persoenliche-daten',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.3.8 Login
    LOGIN_MOUNTING: {
      MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.login-daten',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv'
      },
      MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.login-daten',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv'
      }
    },

    // 4.3.9 Zusammenfassung
    OVERVIEW_MOUNTING: {
      ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.zusammenfassung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'view',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_M_NEW_NUMBER_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.zusammenfassung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'view',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.zusammenfassung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'view',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_M_NEW_NUMBER_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.zusammenfassung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'view',
        ...ALLNET_FLAT_M
      },
      ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.zusammenfassung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'view',
        ...ALLNET_FLAT_L
      },
      ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.zusammenfassung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'view',
        ...ALLNET_FLAT_L
      },
      ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.zusammenfassung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'view',
        ...ALLNET_FLAT_L
      },
      ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE: {
        page_content_id: 'www.share-mobile_de.aktivieren.zusammenfassung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'view',
        ...ALLNET_FLAT_L
      }
    },

    // 4.3.10 Bestellbestätigung
    ORDER_CONFIRMATION_MOUNTING: {
      ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.aktivieren.bestaetigung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_M.shop_product_price,
        ...ALLNET_FLAT_M
      }),
      ALLNET_FLAT_M_NEW_NUMBER_MGM_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.aktivieren.bestaetigung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_M.shop_product_price,
        ...ALLNET_FLAT_M
      }),
      ALLNET_FLAT_M_NUMBER_PORTABILITY_MGM_NON_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.aktivieren.bestaetigung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_M.shop_product_price,
        ...ALLNET_FLAT_M
      }),
      ALLNET_FLAT_M_NEW_NUMBER_MGM_NON_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.aktivieren.bestaetigung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_M.shop_product_price,
        ...ALLNET_FLAT_M
      }),
      ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.aktivieren.bestaetigung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_L.shop_product_price,
        ...ALLNET_FLAT_L
      }),
      ALLNET_FLAT_L_NEW_NUMBER_MGM_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.aktivieren.bestaetigung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_L.shop_product_price,
        ...ALLNET_FLAT_L
      }),
      ALLNET_FLAT_L_NUMBER_PORTABILITY_MGM_NON_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.aktivieren.bestaetigung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'rufnummermitnahme',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_L.shop_product_price,
        ...ALLNET_FLAT_L
      }),
      ALLNET_FLAT_L_NEW_NUMBER_MGM_NON_ACTIVE: (signupId) => ({
        page_content_id: 'www.share-mobile_de.aktivieren.bestaetigung',
        customer_type: 'pos-neukunde',
        mgm_code: 'mgm-nicht-aktiv',
        shop_order_phone_number: 'neue-rufnummer',
        shop_order_sim: 'e-sim',
        shop_order_payment_mode: 'paypal',
        shop_product_status: 'conf',
        shop_order_id: signupId,
        shop_order_value: ALLNET_FLAT_L.shop_product_price,
        ...ALLNET_FLAT_L
      })
    }
  }
};

// Tracking Events
// Below are the event that can be added to HTML elements to define event actions.
// Global Events
//  ------------------------------ TBD --------------------------------
// Window Event Attributes
// - <HANDLER_ELEMENT>_ON_LOAD - Fires after the page is finished loading
// - <HANDLER_ELEMENT>_ON_OFFLINE - To be run when the starts to work offline
// - <HANDLER_ELEMENT>_ON_ONLINE - To be run when the starts to work online
// - <HANDLER_ELEMENT>_ON_RESIZE - Fires when the window is resized
// - <HANDLER_ELEMENT>_ON_ROTATE - Fires when the window is rotated

// Form Events
// - <HANDLER_ELEMENT>_ON_BLUR - Fires when the user pastes some content in an element
// - <HANDLER_ELEMENT>_ON_CHANGE -Fires the moment when the value of the element is changed
// - <HANDLER_ELEMENT>_ON_FOCUS - Fires the moment when the element gets focus
// - <HANDLER_ELEMENT>_ON_INPUT - Script to be run when an element gets user input
// - <HANDLER_ELEMENT>_ON_INVALID - Script to be run when an element is invalid
// - <HANDLER_ELEMENT>_ON_RESET - Fires when the Reset button in a form is clicked
// - <HANDLER_ELEMENT>_ON_SUBMIT - Fires when a form is submitted

// Keyboard Events
// - <HANDLER_ELEMENT>_ON_KEY_DOWN - Fires when a user is pressing a key
// - <HANDLER_ELEMENT>_ON_KEY_PRESS - Fires when a user presses a key
// - <HANDLER_ELEMENT>_ON_KEY_UP - Fires when a user releases a key

//  Mouse Events
// - <HANDLER_ELEMENT>_ON_CLICK - Fires on a mouse click / touch on the element
// - <HANDLER_ELEMENT>_ON_WHEEL - Fires when the mouse wheel rolls up or down over an element

// Clipboard Events
// - <HANDLER_ELEMENT>_ON_COPY - Fires when the user copies the content of an element
// - <HANDLER_ELEMENT>_ON_CUT - Fires when the user cuts the content of an element
// - <HANDLER_ELEMENT>_ON_PASTE - Fires when the user pastes some content in an element

export const UTAG_LINK = {
  LOGIN_ON_SUBMIT: {
    page_type: 'web',
    view_name: 'login',
    event_type: 'submit',
    event_name: 'on_login_submit',
    tealium_event: 'on_login_submit'
  },

  // 4.1.1.1 App Download
  HEADER_ON_CLICK: {
    MAIN_MENU_ON_CLICK: {
      link_id: 'menue'
    },
    ACTIVATION_ON_CLICK: {
      link_id: 'sim-karte-aktivieren'
    },
    TARIFF_ON_CLICK: {
      link_id: 'tarif'
    },
    ABOUT_US_ON_CLICK: {
      link_id: 'ueber-uns'
    },
    FOR_FRIENDS_ON_CLICK: {
      link_id: 'freunde-werben'
    },
    HELP_SERVICE_ON_CLICK: {
      link_id: 'hilfe-service'
    },
    PLAY_STORE_ICON_ON_CLICK: {
      link_id: 'download-google-play'
    },
    APP_STORE_ICON_ON_CLICK: {
      link_id: 'download-app-store'
    }
  },
  FOOTER_ON_CLICK: {
    IMPRINT_ON_CLICK: {
      link_id: 'impressum'
    },
    AGB_ON_CLICK: {
      link_id: 'agb'
    },
    CONSUM_INFO_ON_CLICK: {
      link_id: 'verbraucherinformation'
    },
    PRIVACY_ON_CLICK: {
      link_id: 'datenschutz'
    },
    WIDERRUF_ON_CLICK: {
      link_id: 'widerruf'
    },
    CANCEL_CONTRACT_ON_CLICK: {
      link_id: 'vertrag-jetzt-kuendigen'
    }
  },

  AUFLADEN_ON_CLICK: {
    REWE_MARKET_ON_CLICK: {
      link_id: 'rewe-markt'
    }
  },

  ROAMING_ON_CLICK: {
    COUNTRY_GROUP_ON_CLICK: {
      link_id: 'laendergruppen'
    }
  },

  CANCEL_CONTRACT_ON_CLICK: {
    TARMINATE_ON_CLICK: {
      link_id: 'vertrag-kuendigen'
    },
    SUCCESS_ON_SUBMIT: {
      link_id: 'zum-share-shop'
    }
  },

  GENERIC_GREETINGS_ON_CLICK: {
    FAQ_ON_CLICK: {
      link_id: 'faq'
    },
    MORE_ABOUT_SHARE_ON_CLICK: {
      link_id: 'mehr-ueber-share'
    }
  },

  // 4.1.2.3 AGB
  AGB_ON_CLICK: {
    AGB_INSTRUCTIONS_ON_CLICK: {
      link_id: 'hinweise-batterien-altgeraete'
    }
  },

  // 4.2 Bestellprozess – Online-Neukunde
  ONLINE_CUSTOMER_ON_SUBMIT: {
    // 4.2.1 Home
    HOME: {
      ALLNET_FLAT_M: {
        SELECT_ALLNET_FLAT_M_ON_CLICK: {
          link_id: 'auswahl-allnet-flat-m'
        },
        SELECT_TARIFF_ON_CLICK: {
          link_id: 'tarif-auswaehlen-allnet-flat-m'
        },
        REDEEM_CODE_ON_CLICK: {
          link_id: 'code-einloesen'
        },
        TARIFF_DETAILS_ON_CLICK: {
          link_id: 'tarifdetails-allnet-flat-m'
        },
        PRODUCT_INFORMATION_ON_CLICK: {
          link_id: 'produktinformationsblatt-allnet-flat-m'
        },
        PRICE_LIST_ON_CLICK: {
          link_id: 'preisliste-allnet-flat-m'
        },
        MORE_ABOUT_PROJECT_ON_CLICK: {
          link_id: 'mehr-zum-projekt'
        },
        GOOGLE_PLAY_ON_CLICK: {
          link_id: 'download-google-play'
        },
        APP_STORE_ON_CLICK: {
          link_id: 'download-app-store'
        },
        FAQ_ON_CLICK: {
          link_id: 'faq-<FAQ-Titel>-oeffnen'
        },
        SEE_ALL_FAQ_ON_CLICK: {
          link_id: 'alle-faq-ansehen'
        },
        CONTINUE_TARIFF_ON_CLICK: {
          link_id: 'zum-tarif'
        },
        CONTINUE_SURVEY_ON_CLICK: {
          link_id: 'zum-umfrage'
        },
        CONTINUE_TARIFF_BUTTON_1_ON_CLICK: {
          link_id: 'zum-tarif-oben'
        }
      },
      ALLNET_FLAT_L: {
        SELECT_ALLNET_FLAT_L_ON_CLICK: {
          link_id: 'auswahl-allnet-flat-l'
        },
        SELECT_TARIFF_ON_CLICK: {
          link_id: 'tarif-auswaehlen-allnet-flat-l'
        },
        REDEEM_CODE_ON_CLICK: {
          link_id: 'code-einloesen'
        },
        TARIFF_DETAILS_ON_CLICK: {
          link_id: 'tarifdetails-allnet-flat-l'
        },
        PRODUCT_INFORMATION_ON_CLICK: {
          link_id: 'produktinformationsblatt-allnet-flat-l'
        },
        PRICE_LIST_ON_CLICK: {
          link_id: 'preisliste-allnet-flat-l'
        },
        MORE_ABOUT_PROJECT_ON_CLICK: {
          link_id: 'mehr-zum-projekt'
        },
        GOOGLE_PLAY_ON_CLICK: {
          link_id: 'download-google-play'
        },
        APP_STORE_ON_CLICK: {
          link_id: 'download-app-store'
        },
        FAQ_ON_CLICK: {
          link_id: 'faq-<FAQ-Titel>-oeffnen'
        },
        SEE_ALL_FAQ_ON_CLICK: {
          link_id: 'alle-faq-ansehen'
        },
        CONTINUE_TARIFF_ON_CLICK: {
          link_id: 'zum-tarif'
        },
        CONTINUE_SURVEY_ON_CLICK: {
          link_id: 'zum-umfrage'
        },
        CONTINUE_TARIFF_BUTTON_1_ON_CLICK: {
          link_id: 'zum-tarif-oben'
        }
      }
    },

    // 4.2.1.1 MGM Code
    MGM_CODE: {
      REDEEM_ON_CLICK: {
        link_id: 'einloesen'
      },
      CLOSE_ON_CLICK: {
        link_id: 'schliessen'
      }
    },

    // 4.2.2 Rufnummer
    NUMBER: {
      NUMBER_PORTABILITY_ON_CLICK: {
        link_id: 'auswahl-rufnummermitnahme'
      },
      NEW_NUMBER_ON_CLICK: {
        link_id: 'auswahl-neue-rufnummer'
      },
      CONTINUE_NUMBER_PORTABILITY_ON_CLICK: {
        link_id: 'weiter-rufnummermitnahme'
      },
      CONTINUE_NEW_NUMBER_ON_CLICK: {
        link_id: 'weiter-neue-rufnummer'
      },
      RETURN_ON_CLICK: {
        link_id: 'zurueck'
      }
    },

    // 4.2.2.1 Neue Rufnummer
    NEW_NUMBER: {
      SELECT_NEW_NUMBER_ON_CLICK: {
        link_id: 'auswahl-rufnummer'
      },
      APPLY_ON_CLICK: {
        link_id: 'uebernehmen'
      }
    },

    // 4.2.2.2 Rufnummernmitnahme - Anbieter
    NUMBER_PORTABILITY_PROVIDER: {
      ALPHATEL_ON_CLICK: {
        link_id: 'alphatel'
      },
      DEBITEL_ON_CLICK: {
        link_id: 'debitel'
      },
      TELEKOM_ON_CLICK: {
        link_id: 'telekom'
      }
    },

    // 4.2.2.3 Rufnummernmitnahme - Rufnummer
    NUMBER_PORTABILITY_NUMBER: {
      CURRENT_NUMBER_ON_CLICK: {
        link_id: 'uebernehmen'
      }
    },

    // 4.2.2.4 Rufnummernmitnahme - Informationen
    NUMBER_PORTABILITY_INFORMATION: {
      ACTIVATE_FAST_ON_CLICK: {
        link_id: 'auswahl-schnellstmoeglich'
      },
      ACTIVATE_NORMAL_ON_CLICK: {
        link_id: 'auswahl-nicht-schnellstmoeglich'
      },
      TRANSFER_FAST_ON_CLICK: {
        link_id: 'uebernehmen-schnellstmoeglich'
      },
      TRANSFER_NORMAL_ON_CLICK: {
        link_id: 'uebernehmen-nicht-schnellstmoeglich'
      }
    },

    // 4.2.3 Sim
    SELECT_SIM: {
      CLASSIC_SIM_ON_CLICK: {
        link_id: 'auswahl-klassische-sim-karte'
      },
      ESIM_ON_CLICK: {
        link_id: 'auswahl-esim'
      },
      ESIM_INFO_ON_CLICK: {
        link_id: 'esim-details'
      },
      CONTINUE_CLASSIC_SIM_ON_CLICK: {
        link_id: 'weiter-klassische-sim-karte'
      },
      CONTINUE_ESIM_ON_CLICK: {
        link_id: 'weiter-esim'
      }
    },

    // 4.2.3.2 eSIM Geräteunterstützung
    ESIM_DEVICE: {
      DEVICE_PROVIDER_ON_CLICK: {
        link_id: '<hersteller>-weiterBeispiel:apple-weiter'
      },
      LISTED_DEVICE_ON_CLICK: {
        link_id: 'geraet-aufgelistet'
      },
      DEVICE_NOT_LISTED_ON_CLICK: {
        link_id: 'geraet-nicht-aufgelistet'
      }
    },

    // 4.2.4 Email
    EMAIL: {
      REQUEST_CODE_ON_CLICK: {
        link_id: 'code-anfordern'
      },
      BACK_ON_CLICK: {
        link_id: 'zurueck'
      }
    },

    // 4.2.4.1 Email - Bestätigungscode
    EMAIL_VERIFICATION: {
      REQUEST_CODE_ON_CLICK: {
        link_id: 'e-mail-verifizieren'
      },
      RESEND_CODE_ON_CLICK: {
        link_id: 'code-erneut-zusenden'
      },
      BACK_ON_CLICK: {
        link_id: 'zurueck'
      }
    },

    // 4.2.5 Zahlungsmethode
    PAYMENT_METHOD: {
      PAYPAL_ON_CLICK: {
        link_id: 'auswahl-paypal'
      },
      CREDIT_CARD_ON_CLICK: {
        link_id: 'auswahl-kreditkarte'
      },
      ENABLE_AUTO_CHARGING: {
        link_id: 'automatisch-aufladen-aktivieren'
      },
      DISABLE_AUTO_CHARGING: {
        link_id: 'automatisch-aufladen-deaktivieren'
      },
      CONTINUE_PAYPAL_ON_CLICK: {
        link_id: 'weiter-paypal'
      },
      CONTINUE_CREDIT_CARD_ON_CLICK: {
        link_id: 'weiter-kreditkarte'
      },
      BACK_ON_CLICK: {
        link_id: 'zurueck'
      }
    },

    // 4.2.6 Persönliche Daten
    PERSONAL_DATA: {
      NEXT_ON_CLICK: {
        link_id: 'weiter'
      },
      BACK_ON_CLICK: {
        link_id: 'zurueck'
      }
    },

    // 4.2.7 Login
    LOGIN: {
      VIEW_PASSWORD_ON_CLICK: {
        link_id: 'passwort-anzeigen'
      },
      NEXT_ON_CLICK: {
        link_id: 'weiter'
      }
    },

    // 4.2.8 Zusammenfassung
    OVERIEW: {
      REDEEM_CODE_ON_CLICK: {
        link_id: 'code-einloesen'
      },
      REMOVE_CODE_ON_CLICK: {
        link_id: 'code-entfernen'
      },
      ALLNET_M_TARIFF_DETAILS_ON_CLICK: {
        link_id: 'tarifdetails-allnet-flat-m'
      },
      ALLNET_M_PRODUCT_INFORMATION_ON_CLICK: {
        link_id: 'produktinformationsblatt-allnet-flat-m'
      },
      ALLNET_L_TARIFF_DETAILS_ON_CLICK: {
        link_id: 'tarifdetails-allnet-flat-l'
      },
      ALLNET_L_PRODUCT_INFORMATION_ON_CLICK: {
        link_id: 'produktinformationsblatt-allnet-flat-l'
      },
      MORE_INFO_ON_CLICK: {
        link_id: 'mehr-infos-startguthaben'
      },
      EDIT_NUMBER_ON_CLICK: {
        link_id: 'bearbeiten-rufnummer'
      },
      EDIT_PAYMENT_METHOD_ON_CLICK: {
        link_id: 'bearbeiten-zahlungsmethode'
      },
      EDIT_PERSONAL_DATA_ON_CLICK: {
        link_id: 'bearbeiten-persoenliche-daten'
      },
      ACCEPT_PRIVACY_ON_CLICK: {
        link_id: 'agb-datenschutzhinweise-akzeptieren'
      },
      ACCEPT_TERMS_ON_CLICK: {
        link_id: 'agb-datenschutzhinweise-nicht-akzeptieren'
      },
      ACCEPT_AGB_ON_CLICK: {
        link_id: 'agb'
      },
      CONTRACT_SUMMARY_ON_CLICK: {
        link_id: 'vertragszusammenfassung-anzeigen'
      },
      ACCEPT_WITHDRAWL_RIGHTS_ON_CLICK: {
        link_id: 'widerrufsrecht-akzeptieren'
      },
      NOT_ACCEPT_WITHDRAWL_RIGHTS_ON_CLICK: {
        link_id: 'widerrufsrecht-nicht-akzeptieren'
      },
      WITHDRAWL_RIGHTS_ON_CLICK: {
        link_id: 'widerrufsrecht'
      },
      ENABLE_INFOSERVICE_ON_CLICK: {
        link_id: 'infoservice-aktivieren'
      },
      DISABLE_INFOSERVICE_ON_CLICK: {
        link_id: 'infoservice-deaktivieren'
      },
      PRODUCT_AND_SERVICE_ON_CLICK: {
        link_id: 'produkt-und-dienste'
      },
      DATA_USAGE_ON_CLICK: {
        link_id: 'nutzungsdaten'
      },
      END_DEVICES_ON_CLICK: {
        link_id: 'endgeraete'
      },
      CONTRACT_DATA_ON_CLICK: {
        link_id: 'vertragsdaten'
      },
      WITHDRAWL_RIGHTS_INFOSERVICE_ON_CLICK: {
        link_id: 'widerrufsrecht-infoservice'
      },
      PRIVACY_INFOSERVICE_ON_CLICK: {
        link_id: 'datenschutzhinweise-infoservice'
      },
      ORDER_ON_CLICK: {
        link_id: 'kostenpflichtig-bestellen'
      },
      BACK_ON_CLICK: {
        link_id: 'zurueck'
      }
    },

    // 4.2.9 Bestellbestätigung
    ORDER_CONFIRMATION: {
      START_LEGITIMATION_ON_CLICK: {
        link_id: 'legitimation-starten'
      }
    }
  },

  // 4.3 Aktivierungsstrecke
  POS_CUSTOMER_ON_SUBMIT: {
    // 4.3.1 SIM-Karte aktivieren
    SIM_ACTIVATION: {
      CONFIRM_ON_CLICK: {
        link_id: 'bestaetigen'
      }
    },

    // 4.3.2 Tarifübersicht
    SELECT_TARIFF: {
      ALLNET_FLAT_M: {
        SELECT_ALLNET_FLAT_M_ON_CLICK: {
          link_id: 'auswahl-allnet-flat-m'
        },
        SELECT_TARIFF_ON_CLICK: {
          link_id: 'tarif-auswaehlen'
        },
        SELECT_TARIFF_BUTTON_ON_CLICK: {
          link_id: 'tarif-auswaehlen-allnet-flat-m'
        },
        REDEEM_CODE_ON_CLICK: {
          link_id: 'code-einloesen'
        },
        MORE_INFO_ON_CLICK: {
          link_id: 'mehr-infos-startguthaben'
        },
        TARIFF_DETAILS_ON_CLICK: {
          link_id: 'tarifdetails-allnet-flat-m'
        },
        PRODUCT_INFORMATION_ON_CLICK: {
          link_id: 'produktinformationsblatt-allnet-flat-m'
        },
        PRICE_LIST_ON_CLICK: {
          link_id: 'preisliste-allnet-flat-m'
        }
      },
      ALLNET_FLAT_L: {
        SELECT_ALLNET_FLAT_L_ON_CLICK: {
          link_id: 'auswahl-allnet-flat-l'
        },
        SELECT_TARIFF_ON_CLICK: {
          link_id: 'tarif-auswaehlen'
        },
        SELECT_TARIFF_BUTTON_ON_CLICK: {
          link_id: 'tarif-auswaehlen-allnet-flat-l'
        },
        REDEEM_CODE_ON_CLICK: {
          link_id: 'code-einloesen'
        },
        MORE_INFO_ON_CLICK: {
          link_id: 'mehr-infos-startguthaben'
        },
        TARIFF_DETAILS_ON_CLICK: {
          link_id: 'tarifdetails-allnet-flat-l'
        },
        PRODUCT_INFORMATION_ON_CLICK: {
          link_id: 'produktinformationsblatt-allnet-flat-l'
        },
        PRICE_LIST_ON_CLICK: {
          link_id: 'preisliste-allnet-flat-l'
        }
      }
    },

    // 4.3.1.1 MGM Code
    MGM_CODE: {
      REDEEM_ON_CLICK: {
        link_id: 'einloesen'
      },
      CLOSE_ON_CLICK: {
        link_id: 'schliessen'
      }
    },

    // 4.3.3 Rufnummer
    NUMBER: {
      NUMBER_PORTABILITY_ON_CLICK: {
        link_id: 'auswahl-rufnummermitnahme'
      },
      NEW_NUMBER_ON_CLICK: {
        link_id: 'auswahl-neue-rufnummer'
      },
      CONTINUE_NUMBER_PORTABILITY_ON_CLICK: {
        link_id: 'weiter-rufnummermitnahme'
      },
      CONTINUE_NEW_NUMBER_ON_CLICK: {
        link_id: 'weiter-neue-rufnummer'
      },
      RETURN_ON_CLICK: {
        link_id: 'zurueck'
      }
    },

    // 4.3.3.1 Neue Rufnummer
    NEW_NUMBER: {
      SELECT_NEW_NUMBER_ON_CLICK: {
        link_id: 'auswahl-rufnummermitnahme'
      },
      APPLY_ON_CLICK: {
        link_id: 'uebernehmen'
      }
    },

    // 4.3.3.2 Rufnummernmitnahme - Anbieter
    NUMBER_PORTABILITY_PROVIDER: {
      ALPHATEL_ON_CLICK: {
        link_id: 'alphatel'
      },
      DEBITEL_ON_CLICK: {
        link_id: 'debitel'
      },
      TELEKOM_ON_CLICK: {
        link_id: 'telekom'
      }
    },

    // 4.3.3.3 Rufnummernmitnahme - Rufnummer
    NUMBER_PORTABILITY_NUMBER: {
      CURRENT_NUMBER_ON_CLICK: {
        link_id: 'uebernehmen'
      }
    },

    // 4.3.3.4 Rufnummernmitnahme - Informationen
    NUMBER_PORTABILITY_INFORMATION: {
      ACTIVATE_FAST_ON_CLICK: {
        link_id: 'auswahl-schnellstmoeglich'
      },
      ACTIVATE_NORMAL_ON_CLICK: {
        link_id: 'auswahl-nicht-schnellstmoeglich'
      },
      TRANSFER_FAST_ON_CLICK: {
        link_id: 'uebernehmen-schnellstmoeglich'
      },
      TRANSFER_NORMAL_ON_CLICK: {
        link_id: 'uebernehmen-nicht-schnellstmoeglich'
      }
    },

    // 4.3.5 Email
    EMAIL: {
      REQUEST_CODE_ON_CLICK: {
        link_id: 'code-anfordern'
      },
      BACK_ON_CLICK: {
        link_id: 'zurueck'
      }
    },

    // 4.3.5.1 Email - Bestätigungscode
    EMAIL_VERIFICATION: {
      REQUEST_CODE_ON_CLICK: {
        link_id: 'e-mail-verifizieren'
      },
      RESEND_CODE_ON_CLICK: {
        link_id: 'code-erneut-zusenden'
      },
      BACK_ON_CLICK: {
        link_id: 'zurueck'
      }
    },

    // 4.3.6 Automatische Aufladung
    PAYMENT_METHOD: {
      PAYPAL_ON_CLICK: {
        link_id: 'auswahl-mit-paypal-verknüpfen'
      },
      CREDIT_CARD_ON_CLICK: {
        link_id: 'auswahl-kreditkarte-verwenden'
      },
      ENABLE_AUTO_CHARGING: {
        link_id: 'auswahl-automatische-aufladung-aktivieren'
      },
      DISABLE_AUTO_CHARGING: {
        link_id: 'auswahl-ohne-automatische-aufladung'
      },
      CONTINUE_PAYPAL_ON_CLICK: {
        link_id: 'weiter-mit-automatischeaufladung-paypal'
      },
      CONTINUE_CREDIT_CARD_ON_CLICK: {
        link_id: 'weiter-mit-automatische-aufladungkreditkarte'
      },
      CONTINUE_WITHOUT_CHARGE_ON_CLICK: {
        link_id: 'weiter-ohne-automatische-aufladung'
      }
    },

    // 4.3.7 Persönliche Daten
    PERSONAL_DATA: {
      NEXT_ON_CLICK: {
        link_id: 'weiter'
      },
      BACK_ON_CLICK: {
        link_id: 'zurueck'
      }
    },

    // 4.3.8 Login
    LOGIN: {
      VIEW_PASSWORD_ON_CLICK: {
        link_id: 'passwort-anzeigen'
      },
      NEXT_ON_CLICK: {
        link_id: 'weiter'
      }
    },

    // 4.3.9 Zusammenfassung
    OVERIEW: {
      REDEEM_CODE_ON_CLICK: {
        link_id: 'code-einloesen'
      },
      REMOVE_CODE_ON_CLICK: {
        link_id: 'code-entfernen'
      },
      ALLNET_M_TARIFF_DETAILS_ON_CLICK: {
        link_id: 'tarifdetails-allnet-flat-m'
      },
      ALLNET_M_PRODUCT_INFORMATION_ON_CLICK: {
        link_id: 'produktinformationsblatt-allnet-flat-m'
      },
      ALLNET_L_TARIFF_DETAILS_ON_CLICK: {
        link_id: 'tarifdetails-allnet-flat-l'
      },
      ALLNET_L_PRODUCT_INFORMATION_ON_CLICK: {
        link_id: 'produktinformationsblatt-allnet-flat-l'
      },
      MORE_INFO_ON_CLICK: {
        link_id: 'mehr-infos-startguthaben'
      },
      EDIT_NUMBER_ON_CLICK: {
        link_id: 'bearbeiten-rufnummer'
      },
      EDIT_PAYMENT_METHOD_ON_CLICK: {
        link_id: 'bearbeiten-zahlungsmethode'
      },
      EDIT_PERSONAL_DATA_ON_CLICK: {
        link_id: 'bearbeiten-persoenliche-daten'
      },
      ACCEPT_PRIVACY_ON_CLICK: {
        link_id: 'agb-datenschutzhinweise-akzeptieren'
      },
      ACCEPT_TERMS_ON_CLICK: {
        link_id: 'agb-datenschutzhinweise-nicht-akzeptieren'
      },
      ACCEPT_AGB_ON_CLICK: {
        link_id: 'agb'
      },
      CONTRACT_SUMMARY_ON_CLICK: {
        link_id: 'vertragszusammenfassung-anzeigen'
      },
      ACCEPT_WITHDRAWL_RIGHTS_ON_CLICK: {
        link_id: 'widerrufsrecht-akzeptieren'
      },
      NOT_ACCEPT_WITHDRAWL_RIGHTS_ON_CLICK: {
        link_id: 'widerrufsrecht-nicht-akzeptieren'
      },
      WITHDRAWL_RIGHTS_ON_CLICK: {
        link_id: 'widerrufsrecht'
      },
      ENABLE_INFOSERVICE_ON_CLICK: {
        link_id: 'infoservice-aktivieren'
      },
      DISABLE_INFOSERVICE_ON_CLICK: {
        link_id: 'infoservice-deaktivieren'
      },
      PRODUCT_AND_SERVICE_ON_CLICK: {
        link_id: 'produkt-und-dienste'
      },
      DATA_USAGE_ON_CLICK: {
        link_id: 'nutzungsdaten'
      },
      END_DEVICES_ON_CLICK: {
        link_id: 'endgeraete'
      },
      CONTRACT_DATA_ON_CLICK: {
        link_id: 'vertragsdaten'
      },
      WITHDRAWL_RIGHTS_INFOSERVICE_ON_CLICK: {
        link_id: 'widerrufsrecht-infoservice'
      },
      PRIVACY_INFOSERVICE_ON_CLICK: {
        link_id: 'datenschutzhinweise-infoservice'
      },
      ORDER_ON_CLICK: {
        link_id: 'kostenpflichtig-bestellen'
      },
      BACK_ON_CLICK: {
        link_id: 'zurueck'
      }
    },

    // 4.3.9 Bestellbestätigung
    ORDER_CONFIRMATION: {
      START_LEGITIMATION_ON_CLICK: {
        link_id: 'legitimation-starten'
      }
    }
  }
};

// //////////////////////////////////////////////////////////////
// /////////// Tealium Global Variables /////////////////////////
// //////////////////////////////////////////////////////////////

// If you aren't worried about calling utag before it has loaded (e.g. user interactions)
// This will try to call Tealium but will just drop the event if utag is not loaded

export const utag = window.utag || { link: () => {}, view: () => {} };

// If the utag call could possibly happen before utag has loaded (e.g. page load)
// This will make the call as soon as utag has loaded (it still drops it if utag doesn't load in a certain amount of time)

export const withUtag = async () => {
  if (window.utag) return window.utag;
  let i = 0;
  while (i < 50) {
    // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 200));
    if (window.utag) return window.utag;
    i += 1;
  }
  return { link: () => {}, view: () => {} };
};
