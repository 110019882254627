import React from 'react';
import PropTypes from 'prop-types';

export function CardHeader({ sx, children }) {
  return (
    <div className="card-header" {...{ style: sx }}>
      {children}
    </div>
  );
}

CardHeader.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

CardHeader.defaultProps = {
  sx: {}
};

export default CardHeader;
