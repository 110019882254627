/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Image, QrCode } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import {
  appActivationFlowType,
  appActivationStep1Modals,
  appButtonType,
  appImages,
  appRoute,
  appStorage,
  deviceRegex
} from '@utils/globalConstant';
import { useMetaTag } from '@context/Utils/MetaTag';
import './style.scss';
import { useLayout } from '@context/Utils';
import { UTAG_LINK, UTAG_VIEWS } from '@utils/utag';
import { Button } from '@core/Inputs';
import FootNotesModal from './FootNoteModal';
import { useActivation } from '@context/MobileOne';
import { usePayment } from '@context/MobileOne/Activation/Payments';

export function ActivateSimCard() {
  const navigate = useNavigate();

  // States
  const [isIosDevice, setIsIosDevice] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);

  // Contexts
  const { setLightHeader } = useLayout();
  const { t, staticContentData } = useStaticContent();
  const { setMetaTags, metaTags } = useMetaTag();
  const { setActivationType } = useActivation();
  const [modal, setModal] = useState('');

  // Functions

  //  Hooks
  useEffect(() => {
    setLightHeader(false);
    let deviceInfo = navigator.userAgent;
    setIsIosDevice(deviceRegex.ISIOS.test(deviceInfo));
    setIsAndroidDevice(deviceRegex.ISANDRIOD.test(deviceInfo));

    return () => {
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      const tagInfo = UTAG_VIEWS.SIM_ACTIVATION_MOUNTING;
      window.utag.view(tagInfo);
    }
  }, [window.utag]);

  useEffect(() => {
    if (staticContentData) {
      const footNoteModal = document.querySelector('#aciton-btn');
      if (footNoteModal) {
        footNoteModal.addEventListener('click', () => {
          setModal(appActivationStep1Modals.FOOTNOTES);
        });
      }
      setMetaTags({
        title: t('sh_web_page_info_aktiv_meta_title'),
        description: t('sh_web_page_info_aktiv_meta_description'),

        twitterTitle: t('sh_web_page_info_aktiv_meta_title'),
        twitterDescription: t('sh_web_page_info_aktiv_meta_description'),

        ogTitle: t('sh_web_page_info_aktiv_meta_title'),
        ogDescription: t('sh_web_page_info_aktiv_meta_description')
      });
    }

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  return (
    <>
      <section className="position-relative py-5 pb-0 pb-lg-5">
        <div className="container pt-0 pt-lg-5 py-5 pb-0 pb-md-5 mt-0 mt-lg-4">
          <div className="row py-5 pb-0">
            {/* <div className="col-12 position-relative">
              <div className="orange-gray-bubble"></div>{' '}
            </div> */}
            <div className="col-xl-6 offset-xl-1 col-lg-7 col-md-6  col-12 z-1">
              <h1
                className="primary-color-text mb-5"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_activation_subheader1') }}
              />
              {/* <div className="pink-box rounded-2 bg-pink-color p-3 mb-5">
                <div className="d-flex">
                  <img className="action-icon py-2 pe-2" src={appImages.action} />
                  <div>
                    <strong className="label-medium primary-color-text text-uppercase">
                      {t('sh_web_apoc_activation_pink_box_h')}
                    </strong>
                    <div
                      className="d-flex my-2 py-1 align-text-bottom"
                      style={{ alignItems: 'center' }}
                    >
                      <div
                        className="label-medium primary-color-text font-14 mb-0 pink-box-text"
                        dangerouslySetInnerHTML={{
                          __html: t('sh_web_apoc_activation_pink_box_text ')
                        }}
                      />
                    </div>
                    <p
                      className="box-link mb-0"
                      dangerouslySetInnerHTML={{
                        __html: t('sh_web_apoc_activation_pink_box_link')
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <div
                className="body-large-regular primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_activation_text') }}
              />
              <div className="row p-0 m-0">
                <Link
                  buttonType={appButtonType.SECONDARY}
                  className="btn btn-secondary col-md-6 col-xs-12 mb-5 w-full"
                  to={appRoute.ACTIVATION}
                  onClick={() => {
                    if (window && window.utag) {
                      window.utag.link({ link_id: 'zur-aktivierung' });
                    }
                    setActivationType(appActivationFlowType.SIM_ACTIVATION);
                  }}
                >
                  {t('sh_login_text2')}
                </Link>
              </div>
              <h5
                className="primary-color-text mb-4 cnt"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_activation_h2') }}
              />
              <p
                className="body-large-regular primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_activation_text2') }}
              />
              <span className="d-block d-md-none text-center mb-4">
                <Image
                  className="logo mx-100"
                  refs="sh_web_activation_content-1"
                  alt="sim card activation "
                  mobileStyle={{ height: '484', width: '325' }}
                />
              </span>
              <h5
                className="primary-color-text mb-4 cnt"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_app_h3') }}
              />
              <div className="d-flex flex-wrap flex-column flex-sm-row">
                {isIosDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    // href={t('sh_web_app_store_link')}
                    // to={t('sh_web_app_store_link')}
                    // target="_blank"
                    target="_blank"
                    href={t('sh_ap_appstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </a>
                )}
                {isAndroidDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    // href={t('https://www.google.com/')}
                    // to={t('https://www.google.com/')}
                    // target="_blank"
                    target="_blank"
                    href={t('sh_ap_playstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </a>
                )}
                {!isAndroidDevice && !isIosDevice && (
                  <>
                    <span className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0">
                      <img className="mx-100 grow" src={appImages.qrcode} alt="QR code" />
                    </span>
                    <a
                      className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      // href={t('sh_web_play_store_link')}
                      // to={t('sh_web_play_store_link')}
                      // target="_blank"
                      target="_blank"
                      href={t('sh_ap_playstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                    </a>
                    <a
                      className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      // href={t('sh_web_app_store_link')}
                      // to={t('sh_web_app_store_link')}
                      // target="_blank"
                      target="_blank"
                      href={t('sh_ap_appstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                    </a>
                  </>
                )}
              </div>

              <p
                className="body-large-regular primary-color-text my-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_activation_text3') }}
              />
            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-12 d-none d-md-flex align-self-end justify-content-center">
              {/* <img
                className="logo mx-100"
                src={appImages.simcard_activations}
                alt="sim card activation "
              /> */}
              <Image
                className="logo mx-100"
                refs="sh_web_activation_content-1"
                alt="sim card activation "
                defaultStyle={{ height: '484', width: '325' }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-offWhite-color py-5 mb-sm-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 offset-xl-1 col-lg-8 col-md-8 col-12">
              <h4
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_app_h4') }}
              />
              <div
                className="body-large-regular primary-color-text mb-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_activation_text4') }}
              />
            </div>
          </div>
        </div>
      </section>
      {modal === appActivationStep1Modals.FOOTNOTES && <FootNotesModal setModal={setModal} />}
    </>
  );
}

export default ActivateSimCard;
