import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useLayout } from '@context/Utils';
import { appImages, deviceRegex } from '@utils/globalConstant';
import { UTAG_LINK, UTAG_VIEWS } from '@utils/utag';

export function IdentSuccess() {
  // States
  const [isIosDevice, setIsIosDevice] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);

  // Context
  const { t } = useStaticContent();
  const { setLightHeader } = useLayout();

  // Hooks
  useEffect(() => {
    setLightHeader(true);
    let deviceInfo = navigator.userAgent;
    setIsIosDevice(deviceRegex.ISIOS.test(deviceInfo));
    setIsAndroidDevice(deviceRegex.ISANDRIOD.test(deviceInfo));

    return () => {
      setLightHeader(false);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.IDENT_SUCCESS_MOUNTING);
    }
  }, [window.utag]);

  return (
    <>
      <section className="position-relative bg-primary-color purple-shade-1 py-5">
        <div className="container my-5 py-5">
          <div className="row position-relative py-0 py-lg-5 my-0 my-xl-3 my-xxl-5">
            <div className="col-12 orange-gray-bubble"></div>
            <div className="col-12 col-xl-7 offset-xl-1 col-lg-8 offset-lg-1 col-md-6 offset-md-1">
              <h1 className="pink-color-text mt-2 mt-sm-2">{t('sh_web_apoc_welcome_h1')}</h1>
              <div className="row">
                <div className="col-12 col-lg-9">
                  <div
                    className="text-white body-large-regular position-relative z-1 my-5"
                    dangerouslySetInnerHTML={{ __html: t('sh_web_app_subtext1') }}
                  ></div>
                  <ul className="list-unstyled category-link position-relative z-1 mt-4">
                    <li className="my-4">
                      <a
                        className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                        onClick={() => document.getElementById('welcome_ques1').scrollIntoView()}
                      >
                        <div
                          className="body-medium-bold primary-color-text"
                          dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_welcome_button1') }}
                        />
                        <i className="fa fa-arrow-right"></i>
                      </a>
                    </li>
                    <li className="my-4">
                      <a
                        className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                        onClick={() => document.getElementById('welcome_ques2').scrollIntoView()}
                      >
                        <div
                          className="body-medium-bold primary-color-text"
                          dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_welcome_button2') }}
                        />
                        <i className="fa fa-arrow-right" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative bg-offWhite-color gray-shade-1 py-5 mb-5 mb-sm-0">
        <div className="container pb-0 pb-sm-5">
          <div className="row">
            <div className="col-12 orange-yellow-bubble"></div>
            <div className="col-xl-6 offset-xl-2 col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
              <h4
                id="welcome_ques1"
                className="primary-color-text mb-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_welcome_ques1') }}
              />
              <div
                className="body-large-regular primary-color-text mb-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_welcome_text') }}
              />
              <div
                className="primary-color-text heading-small cnt mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_subtext2') }}
              />
              <div className="d-flex flex-wrap flex-column flex-sm-row pb-0 pb-sm-4 pb-lg-5 mb-4">
                {!isAndroidDevice && !isIosDevice && (
                  <>
                    <span className="pe-0 pe-sm-3 pb-3 pb-sm-0">
                      <img className="mx-100 grow" src={appImages.qrcode} alt="QR code" />
                    </span>
                    <a
                      className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0"
                      target="_blank"
                      href={t('sh_ap_playstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                    </a>
                    <a
                      className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0"
                      target="_blank"
                      href={t('sh_ap_appstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                    </a>
                  </>
                )}

                {isIosDevice && (
                  <a
                    className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0"
                    target="_blank"
                    href={t('sh_ap_appstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </a>
                )}
                {isAndroidDevice && (
                  <a
                    className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0"
                    target="_blank"
                    href={t('sh_ap_playstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </a>
                )}
              </div>
              <h4
                id="welcome_ques2"
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_welcome_ques2') }}
              />
              <div
                className="body-large-regular primary-color-text mb-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_apoc_welcome_text_2') }}
              />
              <div
                className="primary-color-text heading-small cnt mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_subtext2') }}
              />
              <div className="d-flex flex-wrap flex-column flex-sm-row pb-0 pb-sm-4 pb-lg-5 mb-0 mb-xl-5">
                {!isAndroidDevice && !isIosDevice && (
                  <>
                    <span className="pe-0 pe-sm-3 pb-3 pb-sm-0">
                      <img className="mx-100 grow" src={appImages.qrcode} alt="QR code" />
                    </span>
                    <a
                      className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0"
                      target="_blank"
                      href={t('sh_ap_playstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                    </a>
                    <a
                      className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0"
                      target="_blank"
                      href={t('sh_ap_appstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                    </a>
                  </>
                )}

                {isIosDevice && (
                  <a
                    className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0"
                    target="_blank"
                    href={t('sh_ap_appstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </a>
                )}
                {isAndroidDevice && (
                  <a
                    className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0"
                    target="_blank"
                    href={t('sh_ap_playstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default IdentSuccess;
