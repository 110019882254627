/* eslint-disable react/prop-types */
import React from 'react';
import { appAlert, appImages } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';

import './index.scss';

function SuccessAlert({ message }) {
  return (
    <div
      className="alert alert-success share-alert-success bg-mango-color border-0 text-md-center body-medium-regular font-16 primary-color-text d-flex"
      role="alert"
      key={message}
    >
      <div className="my-auto">
        <img className="me-2" src={appImages.InfoIcon} alt="info-icon" />
      </div>{' '}
      <div className="my-auto">{message}</div>
    </div>
  );
}

function ErrorAlert({ message }) {
  return (
    <div
      className="alert alert-error share-alert-error bg-error-color border-0 text-md-center body-medium-regular font-16 text-white d-flex"
      role="alert"
      key={message}
    >
      <div className="my-auto">
        <img className="me-2" src={appImages.InfoIconWhite} alt="info-icon" />
      </div>{' '}
      <div className="my-auto">{message}</div>
    </div>
  );
}

function InfoAlert({ message }) {
  return (
    <div
      className="alert alert-info share-alert-info bg-disabled-color border-0 text-md-center body-medium-regular font-16 primary-color-text d-flex"
      role="alert"
      key={message}
    >
      <div className="my-auto">
        <img className="me-2" src={appImages.InfoIcon} alt="info-icon" />{' '}
      </div>
      <div className="my-auto">{message}</div>
    </div>
  );
}

export function Alert() {
  const { alert } = useAlert();
  if (!alert) {
    return <></>;
  }

  const showAlert = (al, i) => {
    let defaultAlert = <></>;
    switch (al.type) {
      case appAlert.SUCCESS:
        defaultAlert = SuccessAlert(al, i);
        break;
      case appAlert.ERROR:
        defaultAlert = ErrorAlert(al, i);
        break;
      case appAlert.WARNING:
        defaultAlert = WarningAlert(al, i);
        break;

      default:
        // appAlert.INFO
        defaultAlert = InfoAlert(al, i);
        break;
    }
    return defaultAlert;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="app-alert">
          <div className="app-alert-list">{alert.map(showAlert)}</div>
        </div>
      </div>
    </div>
  );
}

export default Alert;
