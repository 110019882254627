import React from 'react';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function PinkBoxModal({ setModal }) {
  // Context
  const { t } = useStaticContent();
  return (
    <Modal
      showCloseButton
      closeButtonText="sh_apoc_tarif_overview_tarif-details_cancel"
      onCloseClick={() => {
        setModal('');
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: t('sh_Footnote_Tarife_star_pink_new') }} />
    </Modal>
  );
}

export default PinkBoxModal;
