import React, { useEffect, useState } from 'react';
import { useNumberPorting } from '@context/MobileOne/Activation/NumberPorting';
import {
  appActivationFlowType,
  appActivationStep2Modals,
  appActivationStep2Screens
} from '@utils/globalConstant';
import { useActivation } from '@context/MobileOne';
import { UTAG_VIEWS } from '@utils/utag';
import { NewRoamingNumberSelection } from './NewRoamingNumberSelection';
import { NewNumberModal } from './NewNumberModal';
import { CurrentNumber, OldNumberProvider, StartWithOldNumber } from './PortingModal';
import { SimTypeSelection } from './SimTypeSelection';
import { ESimBrandDeviceListing, ESimBrandSelection } from './ESimModal';

// className='row'
export function PhoneNumberPorting() {
  // Constants

  // States
  const [modal, setModal] = useState(appActivationStep2Modals.NONE);
  const [mobileBrand, setMobileBrand] = useState('');

  // Contexts
  const { onLoad } = useNumberPorting();
  const { activationType, activationInfo, numberPortingScreen } = useActivation();

  // Functions

  // Hooks
  useEffect(() => {
    setTimeout(() => {
      onLoad();
      const { isPromoApplied, activeTariff: { name = '' } = { name: '' } } = activationInfo;
      const tariffName = `${name}`.toLocaleLowerCase().replaceAll(' ', '-');
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? tariffName ===
            UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_L_MGM_ACTIVE
              .shop_product_name
            ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_L_MGM_ACTIVE
            : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_M_MGM_ACTIVE
          : tariffName ===
            UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_L_MGM_NON_ACTIVE
              .shop_product_name
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_L_MGM_NON_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_M_MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }

      if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
        const tagInfo = isPromoApplied
          ? tariffName ===
            UTAG_VIEWS.POS_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_L_MGM_ACTIVE
              .shop_product_name
            ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_L_MGM_ACTIVE
            : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_M_MGM_ACTIVE
          : tariffName ===
            UTAG_VIEWS.POS_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_L_MGM_NON_ACTIVE
              .shop_product_name
          ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_L_MGM_NON_ACTIVE
          : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.NUMBER_MOUNTING.ALLNET_FLAT_M_MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  return (
    <div className="col" md={{ span: '12' }}>
      {/* New numnber or mnp selection screen*/}
      {numberPortingScreen === appActivationStep2Screens.PHONE_MNP_NEW_SELECTION && (
        <NewRoamingNumberSelection modal={modal} setModal={setModal} />
      )}

      {/* Modals for MNP & New number selection */}
      {modal === appActivationStep2Modals.NEW_NUMBER && <NewNumberModal setModal={setModal} />}

      {modal === appActivationStep2Modals.OLD_NUMBER_PROVIDER && (
        <OldNumberProvider setModal={setModal} />
      )}

      {modal === appActivationStep2Modals.CURRENT_NUMBER && <CurrentNumber setModal={setModal} />}

      {modal === appActivationStep2Modals.START_WITH_OLD_NUMBER && (
        <StartWithOldNumber setModal={setModal} />
      )}

      {/* Sim-Esim screen */}
      {numberPortingScreen === appActivationStep2Screens.SIM_ESIM_SELECTION && (
        <SimTypeSelection modal={modal} setModal={setModal} />
      )}

      {/* Modals for eSim  */}
      {modal === appActivationStep2Modals.ESIM_BRAND_SELECTION && (
        <ESimBrandSelection setMobileBrand={setMobileBrand} setModal={setModal} />
      )}
      {modal === appActivationStep2Modals.ESIM_BRAND_DEVICES && (
        <ESimBrandDeviceListing mobileBrand={mobileBrand} setModal={setModal} />
      )}
    </div>
  );
}
export default PhoneNumberPorting;
