import React from 'react';

export function InputFeedback({ id, isValid, isInvalid, feedBackMessage }) {
  const validClassName = isValid ? 'valid-feedback' : false;
  const inValidClassName = isInvalid ? 'invalid-feedback' : false;
  return (
    <div
      id={id}
      className={
        validClassName || inValidClassName ? validClassName || inValidClassName : 'feedback'
      }
    >
      {feedBackMessage}
    </div>
  );
}

export default InputFeedback;
