import React from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText
  // CardTitle, CardImg,
} from '@core/Layout/Card';
import PropTypes from 'prop-types';
import { appUsageMeasurementsUnits } from '@utils/globalConstant';
import { Col, Row } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

/**
 * Inidividual Option Product
 * TODO: Extend it with different type ACTIVE, INPROGRESS & USAGE etc
 * @param id
 * @param name
 * @param header
 * @param description
 * @param price
 * @param duration
 * @param durationUnit
 * @param bookable
 * @param booked
 * @param cancellable
 * @param endDate
 * @param endTime
 * @returns
 */
export function RoamingProduct({
  id,
  name,
  description,
  price,

  duration,
  durationUnit,

  bookable,
  booked,
  cancellable

  // endDate,
  // endTime,
}) {
  const { t } = useStaticContent();
  return (
    <Card>
      {/* <CardImg variant="top" src="https://source.unsplash.com/random/1000x400?cartoon" /> */}
      <CardHeader>
        <strong>
          {name} - {id}
        </strong>
      </CardHeader>
      <CardBody>
        {/* <CardTitle>Card name</CardTitle> */}
        <CardText>{description}</CardText>
        <Row className="justify-content-md-center p-0">
          <Col xs={6} className="m-0 p-1">
            <ul className="list-group">
              <li className="list-group-item p-2">
                {t('activation-price')} - {price}
              </li>
              <li className="list-group-item p-2">
                {t('activation-duration')} - {duration}&nbsp;{durationUnit}
              </li>
            </ul>
          </Col>

          <Col xs={6} className="m-0 p-1">
            <ul className="list-group">
              <li className="list-group-item p-2">
                {t('dashboard-bookable')} - {JSON.stringify(bookable)}
              </li>
              <li className="list-group-item p-2">
                {t('dashboard-booked')} - {JSON.stringify(booked)}
              </li>
              <li className="list-group-item p-2">
                {t('dashboard-cancellable')} - {JSON.stringify(cancellable)}
              </li>
              {/* <li className="list-group-item">
                EndDate     - {JSON.stringify(endDate)}
              </li>
              <li className="list-group-item">
                EndTime     - {JSON.stringify(endTime)}
              </li> */}
            </ul>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>{/* <small className="text-muted">Last updated 3 mins ago</small> */}</CardFooter>
    </Card>
  );
}

RoamingProduct.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  durationUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bookable: PropTypes.bool,
  booked: PropTypes.bool,
  cancellable: PropTypes.bool
  // endDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // endTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

RoamingProduct.defaultProps = {
  id: 0,
  name: '',
  description: '',
  price: 0,
  duration: 0,
  durationUnit: appUsageMeasurementsUnits.GB,
  bookable: false,
  booked: false,
  cancellable: false
  // endDate: '',
  // endTime: '',
};

export default RoamingProduct;
