import React from 'react';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function ESimSmartPhoneListModal({ setModal }) {
  // Context
  const { t } = useStaticContent();

  return (
    <Modal
      showCloseButton
      onCloseClick={() => {
        setModal('');
      }}
      header={t('sh_web_roaming_pup2_h1')}
    >
      <div dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup2_text1') }} />
      <div className="pt-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup2_h2') }} />
      <div className="pb-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup2_text2') }} />
      <div className="pt-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup2_h3') }} />
      <div className="pb-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup2_text3') }} />
      <div className="pt-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup2_h4') }} />
      <div className="pb-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup2_text4') }} />
      <div className="pt-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup2_h5') }} />
      <div className="pb-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup2_text5') }} />
      <div className="py-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup2_text6') }} />
    </Modal>
  );
}

export default ESimSmartPhoneListModal;
