import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import slugify from 'slugify';
import { useNumberPorting } from '@context/MobileOne/Activation/NumberPorting';
import { Modal } from '@core/Utils';
import { Text } from '@core/Inputs';
import { useActivation } from '@context/MobileOne';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';
import { appActivationFlowType } from '@utils/globalConstant';

export function OldNumberProvider({ setModal }) {
  // State
  const [searchProvider, setSearchProvider] = useState('');
  const [filterProvider, setFilterProvider] = useState([]);

  // Context
  const { t } = useStaticContent();
  const { formValue, providers, setFormValue } = useNumberPorting();
  const { activationInfo, activationType } = useActivation();

  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.NUMBER_PORTABILITY_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.NUMBER_PORTABILITY_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
      if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.NUMBER_PORTABILITY_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.NUMBER_PORTABILITY_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  return (
    <Modal
      showCloseButton
      onCloseClick={() => {
        setModal('');
        setFormValue({
          ...formValue,
          provider: '',
          currentNumber: '',
          isNewNumberSelected: 'false',
          isNewNumber: 'false'
        });
      }}
      stepHeader={t('sh_apoc_number-portability_step1')}
      header={t('sh_apoc_number-portability_h1')}
      description={t('sh_apoc_number-portability_subheader1')}
      customDesClass="font-20"
    >
      <div className="mt-4">
        <Text
          placeholder={t('sh_apoc_number-portability_input1')}
          value={searchProvider}
          onChange={(e) => {
            setSearchProvider(e.target.value);
            // providers.filter((item) =>
            //   `${item.label}`.toLowerCase().startsWith(`${e.target.value}`.toLowerCase())
            // )
            const provders = providers.filter((item) =>
              `${item.label}`.toLowerCase().includes(`${e.target.value}`.toLowerCase())
            );
            if (provders.length > 0) {
              setFilterProvider(provders);
            } else {
              setFilterProvider([]);
            }
          }}
        />
        <ul className="list-unstyled category-link mt-4">
          {filterProvider.length === 0
            ? filterProvider.length === 0 && searchProvider.length === 0
              ? providers.map((provider, index) => (
                  <li className="my-4" key={`brand-info-${provider.value}`}>
                    <a
                      className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                      onClick={() => {
                        window.utag.link({
                          link_id: slugify(provider.label)
                        });

                        setFormValue({ ...formValue, provider: provider.value });
                        setModal('currentNumber');
                      }}
                    >
                      {provider.label}
                      <i className="fa fa-arrow-right" />
                    </a>
                  </li>
                ))
              : null
            : filterProvider.map((provider, index) => (
                <li className="my-4" key={`brand-info-${provider.value}`}>
                  <a
                    className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                    onClick={() => {
                      window.utag.link({
                        link_id: slugify(provider.label)
                      });

                      setFormValue({ ...formValue, provider: provider.value });
                      setModal('currentNumber');
                    }}
                  >
                    {provider.label}
                    <i className="fa fa-arrow-right" />
                  </a>
                </li>
              ))}
        </ul>
      </div>
    </Modal>
  );
}

export default OldNumberProvider;
