import React from 'react';
import { useAccount } from '@context/MobileOne';
import { ResetPassword } from './ResetPassword';
import { NewPassword } from './NewPassword';
import { ValidateCode } from './ValidateCode';

export function ForgotPassword() {
  // Context
  const { isCodeSend, isCodeValid } = useAccount();

  if (isCodeSend) {
    return <ValidateCode />;
  }
  if (isCodeValid) {
    return <NewPassword />;
  }
  return <ResetPassword />;
}

export default ForgotPassword;

export * from './NewPassword';
export * from './ResetPassword';
export * from './ValidateCode';
