import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { useAlert } from '@context/Utils';
import { appAlert, appPaymentStaticData } from '@utils/globalConstant';
import { useMpsPayment } from '@dom-digital-online-media/dom-mps-sdk';

export const CustomerContext = createContext({});

export function CustomerContextProvider({ children, config }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [personalData, setPersonalData] = useState({});
  const [customerProducts, setCustomerProducts] = useState([]);
  const [customerBalance, setCustomerBalance] = useState({});
  const [customerUsage, setCustomerUsage] = useState({});

  // Context
  const { showAlert } = useAlert();
  const { isUserLoggedIn } = useAuth();
  const { onCustomerWithProductCall, onCustomerBalanceCall, onUsagesCall } = useMobileOne();
  const { onPaymentResgister } = useMpsPayment();
  // Validations

  // Functions
  // Get customer data and storing it in individual state
  const getCustomerData = async () => {
    try {
      const {
        data: {
          customerData: resCustomerData = {},
          customerData: { msisdn = false },
          personalData: resPersonalData = {},
          products: resProducts = []
        }
      } = await onCustomerWithProductCall();
      if (resCustomerData && msisdn) {
        setCustomerData(resCustomerData);
        setPersonalData(resPersonalData);
        setCustomerProducts(resProducts);
      }
      return msisdn;
    } catch (error) {
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      setIsLoading(false);
      return error;
    }
  };

  // Get customer balance and storing it in state
  const getCustomerBalance = async () => {
    try {
      const { data } = await onCustomerBalanceCall();
      if (data) setCustomerBalance(data);
      return data;
    } catch (error) {
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      setIsLoading(false);
      return error;
    }
  };

  // Get customer usage and storing it in state
  const getCustomerUsage = async () => {
    try {
      const { data } = await onUsagesCall();
      if (data) setCustomerUsage(data);
      return data;
    } catch (error) {
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      setIsLoading(false);
      return error;
    }
  };

  // when function loads will return customer data
  const onLoad = async () => {
    try {
      setIsLoading(true);
      await getCustomerData();
      return true;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return error;
    }
  };

  // Function loads after we get customer data, and returns balance & usage
  const afterLoad = async () => {
    try {
      await getCustomerBalance();
      await getCustomerUsage();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const addPaymentMethod = async (values) => {
    try {
      const { data } = await onPaymentResgister({
        businessPartnerConfigId: config.env.REACT_APP_PAYMENT_BUSINESS_PARTNER_ID,
        returnUrl: `${config.env.REACT_APP_SHOPPING_RETURN_URL}user/account`,
        description: appPaymentStaticData.description,
        currency: 'EUR',
        locale: 'de_DE',
        uiDetails: {
          binding_legal_terms: '*** Binding legal terms ***'
        },
        themeName: 'default',
        paymentMethod: values.paymentMethod
      });
      window.location = `${data.redirectUrl}`;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  // Hooks
  // when user logged in get customer data
  useEffect(() => {
    if (isUserLoggedIn) {
      onLoad();
    }
  }, [isUserLoggedIn]);

  // to check the customerBalance and usaCustcustomerUmerge
  useEffect(() => {
    const { msisdn = false, ...restCustomerData } = customerData;
    if (restCustomerData && msisdn) {
      afterLoad();
    }
  }, [customerData]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      customerData,
      personalData,
      customerProducts,
      customerBalance,
      customerUsage,

      // API Calls
      getCustomerData,
      addPaymentMethod
    }),
    [
      // States
      isLoading,
      setIsLoading,
      customerData,
      personalData,
      customerProducts,
      customerBalance,
      customerUsage,

      // API Calls
      getCustomerData,
      addPaymentMethod
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <CustomerContext.Provider value={contextPayload}>{children}</CustomerContext.Provider>;
}
CustomerContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useCustomer = () => useContext(CustomerContext);

export default CustomerContext;
