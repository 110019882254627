/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import QRCode from 'react-qr-code';
import PropTypes from 'prop-types';

export function QrCode({ value, size, qrwidth, qrheight }) {
  return (
    <QRCode
      size={size}
      style={{ height: qrheight, maxWidth: '100%', width: qrwidth }}
      value={value}
      // viewBox={`0 0 256 256`}
    />
  );
}

QrCode.prototype = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string
};

export default QrCode;
