import React, { useEffect } from 'react';
import { Formik } from 'formik';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Modal } from '@core/Utils';
import { Button, Text } from '@core/Inputs';
import { appButtonType, appTariff } from '@utils/globalConstant';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';

export function MGMCodeModal({
  setModal,
  handleChange: formikHandleChange,
  handleBlur: formikHandleBlur,
  codeForValidation: formikCodeForValidation,
  touched: formikTouched,
  errors: formikErrors,
  setErrors,
  setTouched: formikSetTouched,
  applyMGMCode,
  isLoading
}) {
  // Context
  const { t } = useStaticContent();
  const { activeTariff, validationSchemaMgmCode } = useActivationTariff();
  // State
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.REDEEN_CODE_MOUNTING);
    }
    if (window && window.utag) {
      activeTariff.id === appTariff.FLAT_M
        ? window.utag.link(
            UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M.REDEEM_CODE_ON_CLICK
          )
        : window.utag.link(
            UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L.REDEEM_CODE_ON_CLICK
          );
    }
  }, [window.utag]);

  const onSubmit = ({ codeForValidation }) => {
    if (window && window.utag) {
      window.utag.link(UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.MGM_CODE.REDEEM_ON_CLICK);
    }
    formikSetTouched({ codeForValidation: true });
    applyMGMCode({ codeForValidation, setErrors, setModal });
  };

  return (
    <Modal
      showCloseButton
      onCloseClick={() => {
        if (window && window.utag) {
          window.utag.link(UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.MGM_CODE.CLOSE_ON_CLICK);
        }
        setModal('');
      }}
      header={t('sh_apoc_voucher_h1')}
      description={t('sh_apoc_voucher_text1')}
    >
      <Formik
        initialValues={{
          codeForValidation: formikCodeForValidation
        }}
        validationSchema={validationSchemaMgmCode}
        onSubmit={onSubmit}
      >
        {({
          values: { codeForValidation },
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <div className="row">
            <div className="col-10">
              <Text
                type="text"
                id="codeForValidation"
                name="codeForValidation"
                onChange={(e) => {
                  handleChange(e);
                  formikHandleChange(e);
                }}
                value={codeForValidation}
                placeholder={t('sh_apoc_voucher_input')}
                isInvalid={
                  (!!touched.codeForValidation || !!formikTouched.codeForValidation) &&
                  (!!errors.codeForValidation || !!formikErrors.codeForValidation)
                }
                onBlur={(e) => {
                  handleBlur(e);
                  formikHandleBlur(e);
                }}
                error={
                  formikErrors.codeForValidation
                    ? formikErrors.codeForValidation
                    : touched.codeForValidation && errors.codeForValidation
                    ? errors.codeForValidation
                    : null
                }
              />
              <Button
                className="w-100"
                buttonType={appButtonType.SECONDARY}
                isLoading={isLoading}
                onClick={handleSubmit}
              >
                {t('sh_apoc_voucher_redeem_button')}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default MGMCodeModal;
