import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appImages } from '@utils/globalConstant';
import './style.scss';

export function Modal({
  showCloseButton,
  closeButtonText,
  onCloseClick,
  showBackButton,
  onBackClick,
  stepHeader,
  header,
  description,
  customDesClass,
  customBodyClass,
  children
}) {
  // States
  const [random, setRandom] = useState(0.0);
  const [key, setKey] = useState(0);

  // Context
  const { t } = useStaticContent();

  // Functions
  const registerKeyPress = useCallback((e) => {
    setRandom(Math.random());
    setKey(e.keyCode);
  }, []);

  // Hooks
  useEffect(() => {
    if (key === 27) {
      onCloseClick();
    }
  }, [random]);

  useEffect(() => {
    window.addEventListener('keydown', registerKeyPress);
  }, [registerKeyPress]);

  return (
    <div
      className="modal fade show d-block share-modal"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-offWhite-color border-0">
          {/* {showCloseButton} */}
          {showCloseButton && (
            <div className="modal-header justify-content-start p-0 border-0">
              <button
                type="button"
                className="close-modal d-flex align-items-center bg-transparent border-0 mx-0 shadow-none"
                data-bs-dismiss="modal"
                onClick={onCloseClick}
                aria-label="Close"
              >
                {/* <img className="" src={appImages.modalclose} alt="Modal popup close" /> */}
                <i className="bi bi-x" />
                <h5 className="modal-title body-medium-bold ms-1" role="button">
                  {t(closeButtonText ? closeButtonText : 'sh_apoc_number-portability_cancel')}
                </h5>
              </button>
            </div>
          )}
          {showBackButton && (
            <div className="modal-header justify-content-start p-0 border-0">
              <button
                type="button"
                className="back-modal d-flex align-items-center bg-transparent border-0 mx-0 shadow-none"
                data-bs-dismiss="modal"
                onClick={onBackClick}
                aria-label="back"
              >
                {/* <img className="" src={appImages.modalback} alt="Modal popup back" /> */}
                <i className="bi bi-arrow-left-short" />
                <h5 className="modal-title body-medium-bold ms-1" role="button">
                  {t('sh_back_button')}
                </h5>
              </button>
            </div>
          )}

          <div className={`modal-body mt-3 p-2 ${customBodyClass}`}>
            {stepHeader && <h6 className="text-uppercase grey-color-text">{stepHeader}</h6>}
            {header && (
              <h3
                className="primary-color-text text-break"
                dangerouslySetInnerHTML={{ __html: header }}
              />
            )}
            {description && (
              <p className={`my-4 body-large-regular grey-color-text ${customDesClass}`}>
                {description}
              </p>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  setShowModal: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onBackClick: PropTypes.func,
  stepHeader: PropTypes.any,
  header: PropTypes.string,
  description: PropTypes.string,
  customDesClass: PropTypes.string,
  customBodyClass: PropTypes.string,
  closeButtonText: PropTypes.string,
  children: PropTypes.node.isRequired
};

Modal.defaultProps = {
  setShowModal: false,
  showCloseButton: false,
  onCloseClick: () => {},
  onBackClick: () => {},
  showBackButton: false,
  stepHeader: '',
  header: '',
  closeButtonText: '',
  description: '',
  customDesClass: '',
  customBodyClass: ''
};

export default Modal;
