import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import moment from 'moment';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { appAlert } from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import {
  appActivationFlowType,
  appRegex,
  formValidation,
  inputValidation
} from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
import { useActivationTariff } from '../ActivationTariff';
import { useActivation } from '..';
import { UTAG_LINK, utag } from '@utils/utag';

export const PersonalDataContext = createContext({});

export function PersonalDataContextProvider({ children, onFormUpdate, nextStep }) {
  // Constant
  const initialValue = {
    firstName: '',
    lastName: '',
    houseNumber: '',
    street: '',
    zip: '',
    city: '',
    birthDate: '',
    hotlinePassword: '',
    additionalInfo: ''
    /*      countryCode: 1,
    salutation: '',
    emailAddress: '',
    confirmEmailAddress: '',
    alternateNumberPrefix: '',
    alternateNumber: '',
    identityType: '',
    identityNumber: '',
    nationality: '',
    placeOfIssue: '',
    validUntil: '',
    legitimationMethod: '3',
    confirmHotlinePassword: '',
    title: '7', */
  };

  const passwordInitialValue = {
    cscPassword: ''
  };

  // Static content context
  const { t } = useStaticContent();
  const { bookableTariff } = useActivationTariff();
  const { currentStep, setCurrentStep, editStep, setEditStep, activationType } = useActivation();
  const [changePasswordErrorMsg, setChangePasswordErrorMsg] = useState('');

  // Validations
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('sh_apoc_personal_first-name_error-msg')
      .matches(
        /^[a-zA-Z(Ö)(Ä)(Ü)(ü)(ö)(ä)(ß)\s\x2D]{0,25}$/,
        'sh_apoc_personal_first-name_error-msg'
      ),
    lastName: Yup.string()
      .required('sh_apoc_personal_last-name_error-msg')
      .matches(
        /^[a-zA-Z(Ö)(Ä)(Ü)(ü)(ö)(ä)(ß)\s\x2D]{0,40}$/,
        'sh_apoc_personal_last-name_error-msg'
      ),
    houseNumber: Yup.string()
      .required('sh_apoc_personal_street-nr_error-msg')
      .matches(/^[A-Za-z0-9ÄÖÜßäöü.-\s\x2D]{0,25}$/, 'sh_apoc_personal_street-nr_error-msg'),
    street: Yup.string()
      .required('sh_apoc_personal_street_error-msg')
      .max(100, 'sh_apoc_personal_street_error-msg'),
    zip: Yup.string()
      .required('sh_apoc_personal_zip_error-msg')
      .matches(/[0-9]{4,25}/, 'sh_apoc_personal_zip_error-msg'),
    city: Yup.string()
      .required('sh_apoc_personal_town_error-msg')
      .matches(/^[A-Za-zÄÖÜßäöü()"-\s]{0,75}$/, 'sh_apoc_personal_town_error-msg'),
    hotlinePassword: Yup.string()
      .required('sh_apoc_personal_hotline-pin_error-msg')
      .length(4, 'sh_apoc_personal_hotline-pin_error-msg'),
    birthDate: Yup.string()
      .required('sh_apoc_personal_err_no_birth-date')
      .nullable()
      .test('birthDate', t('sh_apoc_personal_err_min-date'), (bDate) => {
        const currentDate = moment([
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ]);
        const birthDate = new Date(bDate);
        const enterDate = moment([
          birthDate.getFullYear(),
          birthDate.getMonth(),
          birthDate.getDate()
        ]);
        return (
          currentDate.diff(enterDate, 'days') > 5844 && currentDate.diff(enterDate, 'days') < 43800
        );
      })
  });

  const passwordValidationSchema = Yup.object().shape({
    cscPassword: formValidation({
      required: t('sh_apoc_login_password_info'),
      regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{8,16}$/,
      validErrorMessage: t('sh_apoc_login_password_info')
    })
  });

  const validateSchemaPersonalData = validationSchema;
  // const validateSchemaPersonalData =
  //   activationType === appActivationFlowType.NEW_ONLINE
  //     ? validationSchema
  //     : validationSchema.shape({
  //         birthDate: Yup.date()
  //           .required('sh_apoc_personal_err_no_birth-date')
  //           .test('birthDate', t('sh_apoc_personal_err_wrong_birth-date'), (bDate) => {
  //             const currentDate = moment([
  //               new Date().getFullYear(),
  //               new Date().getMonth(),
  //               new Date().getDate()
  //             ]);
  //             const birthDate = new Date(bDate);
  //             const enterDate = moment([
  //               birthDate.getFullYear(),
  //               birthDate.getMonth(),
  //               birthDate.getDate()
  //             ]);
  //             return currentDate.diff(enterDate, 'days') > 5844;
  //           })
  //       });

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    houseNumber: '',
    street: '',
    zip: '',
    city: '',
    countryCode: '',
    hotlinePassword: ''
  });
  const [passwordValue, setPasswordValue] = useState(passwordInitialValue);

  // Context
  const { showAlert } = useAlert();
  const { onValidateAddress } = useMobileOne(); // oncountriesCall

  // Functions
  const onSubmit = async (values) => {
    try {
      // Fill Personal Data & Go to Next Step;
      // console.log(values);
      setIsLoading(true);
      if (window && window.utag) {
        activationType === appActivationFlowType.NEW_ONLINE
          ? window.utag.link(UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.PERSONAL_DATA.NEXT_ON_CLICK)
          : window.utag.link(UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.PERSONAL_DATA.NEXT_ON_CLICK);
      }

      const { data } = await onValidateAddress({
        ...values,
        zip: values.zip.toString(),
        countryCode:
          activationType === appActivationFlowType.SIM_ACTIVATION ? Number(values.countryCode) : 1
      });
      if (data) {
        setFormValue(values);
        onFormUpdate({
          ...values,
          city: data.data.city,
          houseNumber: data.data.houseNumber,
          street: data.data.street,
          zip: data.data.zip,
          countryCode: data.data.countryCode
        });
        setIsLoading(false);
        // showAlert({ type: appAlert.SUCCESS, message: t('sh_apoc_personal_adress_validation_success') });
        if (editStep === currentStep) {
          setCurrentStep(activationType === appActivationFlowType.SIM_ACTIVATION ? 8 : 6);
          setEditStep(-1);
        } else {
          nextStep();
        }
      } else {
        if (editStep === currentStep) {
          setCurrentStep(activationType === appActivationFlowType.SIM_ACTIVATION ? 8 : 6);
          setEditStep(-1);
        } else {
          nextStep();
        }
      }
      return values;
    } catch (error) {
      setIsLoading(false);
      showAlert({ type: appAlert.ERROR, message: t('sh_apoc_personal_adress_validation_failed') });
      return false;
    }
  };

  const validatePwd = (password) => {
    const pattern =
      t('sh_password_regex') !== 'sh_password_regex'
        ? new RegExp(`${t('sh_password_regex')}`)
        : appRegex.validatePwd;
    return pattern.test(password);
  };

  const validatePwdStringLength = (password) => {
    const pattern =
      t('sh_password_string_length_regex') !== 'sh_password_string_length_regex'
        ? new RegExp(`${t('sh_password_string_length_regex')}`)
        : appRegex.validatePwdStringLength;
    return pattern.test(password);
  };
  const validatePwdUpperOrLowerCase = (password) => {
    const pattern =
      t('sh_password_upper_or_lower_case_regex') !== 'sh_password_upper_or_lower_case_regex'
        ? new RegExp(`${t('sh_password_upper_or_lower_case_regex')}`)
        : appRegex.validatePwdUpperOrLowerCase;
    return pattern.test(password);
  };
  const validatePwdNumbers = (password) => {
    const pattern =
      t('sh_password_numbers_regex') !== 'sh_password_numbers_regex'
        ? new RegExp(`${t('sh_password_numbers_regex')}`)
        : appRegex.validatePwdNumbers;
    return pattern.test(password);
  };
  const validatePwdSpecialChar = (password) => {
    const pattern =
      t('sh_password_special_chars_regex') !== 'sh_password_special_chars_regex'
        ? new RegExp(`${t('sh_password_special_chars_regex')}`)
        : appRegex.validatePwdSpecialChar;
    return pattern.test(password);
  };

  const validatePasswordWithKeys = (values, firstPasswordKey) => {
    // string, controlName, password

    const errors = Object.keys(values).map((controlName) => {
      const string = values[controlName];
      if (!string) {
        return {
          [controlName]: 'Pflichtfeld' // t('ek_ap_password_pw1_error-msg')
        };
      }

      // const hasLength = !validateStringLength(values[firstPasswordKey], 12, 100);
      // const hasUpperCase = !validateUpperCase(values[firstPasswordKey]);
      // const hasLowerCase = !validateLowerCase(values[firstPasswordKey]);
      // const hasNumber = !validateNumber(values[firstPasswordKey]);
      // const hasSpecial = !validateSpecial(values[firstPasswordKey]);
      // const hasVerified = !validatePwd(values[firstPasswordKey]);

      const hasLength = !validatePwdStringLength(values[firstPasswordKey]);
      const hasUpperCase = !validatePwdUpperOrLowerCase(values[firstPasswordKey]);
      const hasLowerCase = !validatePwdUpperOrLowerCase(values[firstPasswordKey]);
      const hasNumber = !validatePwdNumbers(values[firstPasswordKey]);
      const hasSpecial = !validatePwdSpecialChar(values[firstPasswordKey]);

      let errorMsg = {};

      if (
        // hasVerified ||
        hasLength ||
        hasUpperCase ||
        hasLowerCase ||
        hasNumber ||
        hasSpecial
        // ||
        // values[firstPasswordKey] !== values[confirmPasswordKey]
      ) {
        errorMsg = {
          [firstPasswordKey]: t('sh_password_error_invalid-char'),
          otherErrors: {
            // [inputValidation.INVALID]: hasVerified,
            [inputValidation.MIN_MAX_ERROR]: hasLength,
            [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
            [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
            [inputValidation.NUMBER_ERROR]: hasNumber,
            [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial
            // [inputValidation.MATCH]: values[firstPasswordKey] !== values[confirmPasswordKey]
          }
        };
      }
      return errorMsg;
    });
    return Object.assign(...errors);
  };

  const changePassword = async (values) => {
    try {
      if (!validatePwd(values.cscPassword)) {
        setChangePasswordErrorMsg(t('sh_password_error_invalid-char'));
        setIsLoading(false);
        return false;
      }
      // Fill Personal Data & Go to Next Step;
      setIsLoading(true);
      if (window && window.utag) {
        activationType === appActivationFlowType.NEW_ONLINE
          ? window.utag.link(UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.LOGIN.NEXT_ON_CLICK)
          : window.utag.link(UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.LOGIN.NEXT_ON_CLICK);
      }

      setPasswordValue(values);
      onFormUpdate(values);
      nextStep();
      /* const { data } = await onValidateAddress(values);
      if (data) {
        setFormValue(values);
        onFormUpdate(values)
        showAlert({ type: appAlert.SUCCESS, message: 'Data Submitted Successfully..' });
        nextStep();
      } */
      setIsLoading(false);
      return values;
    } catch (error) {
      setIsLoading(false);
      console.log('Submit Error', error);
      return false;
    }
  };
  // Validate form submission
  const onValidatePersonalData = async (data) => {
    console.log(await validationSchema.validate(data));
  };

  // Hooks
  // Default Hook // No use
  useEffect(() => {
    // onLoad();
  }, []);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // Constants
      initialValue,
      passwordInitialValue,
      passwordValue,
      setPasswordValue,
      validationSchema,
      passwordValidationSchema,
      validateSchemaPersonalData,

      // States
      isLoading,
      setIsLoading,
      formValue,
      setFormValue,
      changePasswordErrorMsg,
      setChangePasswordErrorMsg,

      // Functions
      onSubmit,
      changePassword,
      onValidatePersonalData,
      validatePasswordWithKeys
    }),
    [
      // Constants
      initialValue,
      passwordValue,
      setPasswordValue,
      passwordInitialValue,
      validationSchema,
      passwordValidationSchema,
      validateSchemaPersonalData,

      // States
      isLoading,
      setIsLoading,
      formValue,
      setFormValue,
      changePasswordErrorMsg,
      setChangePasswordErrorMsg,

      // Functions
      onSubmit,
      changePassword,
      onValidatePersonalData,
      validatePasswordWithKeys
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <PersonalDataContext.Provider value={contextPayload}>{children}</PersonalDataContext.Provider>
  );
}
PersonalDataContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const usePersonalData = () => useContext(PersonalDataContext);

export default PersonalDataContext;
