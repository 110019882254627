import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Col, Container, Row } from '@core/Layout';
import { Button, Select } from '@core/Inputs';
import { useTariff } from '@context/MobileOne';
import { appRoute } from '@utils/globalConstant';

export function EvnAllowed() {
  const pdfOptions = [];
  // Context
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const {
    changeEvnSettingForm,
    evnSettingValidation,
    evnLookupSettings,
    onEvnChangeSettingSubmit,
    isLoading
  } = useTariff();

  return (
    <Container className="px-5">
      {/* Page Title */}
      <Row>
        <Col xs={2}>
          <Button
            className="p-0 mt-4"
            variant="link"
            onClick={() => navigate(appRoute.TARIFF_OPTION)}
          >
            <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
          </Button>
        </Col>
        <Col xs={8}>
          <center>
            <br />
            <h3>{t('sh_tab_two_evn_headline')}</h3>
          </center>
        </Col>
      </Row>
      <br />
      {/* EVN */}

      <Formik
        enableReinitialize
        initialValues={changeEvnSettingForm}
        validationSchema={evnSettingValidation}
        onSubmit={onEvnChangeSettingSubmit}
      >
        {({ values: { evnSetting }, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <h5>{t('sh_tab_two_evn_subheadline1')}</h5>
                <Select
                  id="evnSetting"
                  name="evnSetting"
                  options={evnLookupSettings}
                  value={evnSetting}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        id: 'evnSetting',
                        name: 'evnSetting',
                        value: e.target.value
                      }
                    });
                  }}
                  getOptionId={(option) => option.id}
                  getOptionName={(option) => option.value}
                  defaultOption={false}
                />
              </Col>
              <Col xs={12} className="d-grid gap-2 mt-4">
                <Button variant="dark" type="submit" isLoading={isLoading}>
                  {t('sh_tab_two_evn_confirm_button')}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <br />
      <br />
      <Row>
        <Col xs={12}>
          <h5>{t('sh_tab_two_evn_subheadline2')}</h5>
          <Select id="envPdf" name="envPdf" options={pdfOptions} />
        </Col>
        <Col xs={12} className="d-grid gap-2 mt-4">
          <Button variant="dark">{t('sh_tab_two_evn_pdf_button')}</Button>
        </Col>
      </Row>
      {/* Buttons */}
    </Container>
  );
}

export default EvnAllowed;
