import React from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { Text, Button } from '@core/Inputs';
import { useAccount } from '@context/MobileOne';
import { Col, Container, Row } from '@core/Layout';

export function ResetPassword() {
  // Context
  const { t } = useStaticContent();
  const { forgotPasswordInitialValue, forgotPasswordValidation, isLoading, onForgotPassword } =
    useAccount();

  return (
    <Container>
      <>
        <br />
        <Row>
          <Col xs={12}>
            <h4>{t('sh_login_pw_h1')}</h4>
          </Col>
          <Col xs={12}>
            <p dangerouslySetInnerHTML={{ __html: t('sh_login_pw_msg1') }} />
          </Col>
        </Row>
        <br />
        <Row>
          <Formik
            initialValues={forgotPasswordInitialValue}
            validationSchema={forgotPasswordValidation}
            onSubmit={onForgotPassword}
          >
            {({ values: { msisdn }, handleBlur, handleChange, errors, touched, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Col xs={12} className="py-3">
                  <Text
                    type="text"
                    label=""
                    id="msisdn"
                    name="msisdn"
                    onChange={handleChange}
                    value={msisdn}
                    placeholder={t('sh_login_phone_number')}
                    isValid={touched.msisdn && !errors.msisdn}
                    isInvalid={!!touched.msisdn && !!errors.msisdn}
                    onBlur={handleBlur}
                    error={touched.msisdn && errors.msisdn ? errors.msisdn : null}
                  />
                </Col>
                <Col xs={12} className="py-3">
                  <Button variant="primary" type="submit" isLoading={isLoading}>
                    {t('sh_ap_number_next-button')}
                  </Button>
                </Col>
              </Form>
            )}
          </Formik>
        </Row>
      </>
    </Container>
  );
}

export default ResetPassword;
