import React from 'react';
import { ChargeHistory } from '../ChargeHistory';
import { Online } from '../OnlineTopUp';
import { Voucher } from '../Voucher';
// import PropTypes from 'prop-types'

export function Load() {
  return (
    <>
      <Voucher />
      <Online />
      <ChargeHistory />
    </>
  );
}

Load.propTypes = {};

export default Load;
