import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

export function Switch({
  label,
  type,
  id,
  name,
  onChange,
  onBlur,
  value,
  isInvalid,
  isDisabled,
  isDefaultChecked
}) {
  const primarySwitch = (
    <div className="form-check form-switch d-flex justify-content-between align-items-center ps-0">
      <label className="form-check-label body-medium-regular primary-color-text" htmlFor={id}>
        <div
          className="body-medium-regular"
          dangerouslySetInnerHTML={{
            __html: label
          }}
        />
      </label>
      <input
        {...{
          name,
          id,
          value,
          onChange,
          onBlur,
          isInvalid
        }}
        className="form-check-input shadow-none"
        type="checkbox"
        defaultChecked={isDefaultChecked}
        disabled={isDisabled}
      />
    </div>
  );

  const secondarySwitch = (
    <div className="form-check form-switch dark-switch d-flex justify-content-between align-items-center ps-0">
      <label className="form-check-label body-medium-regular text-white" htmlFor={id}>
        <div
          className="body-large-regular"
          dangerouslySetInnerHTML={{
            __html: label
          }}
        />
      </label>
      <input
        {...{
          name,
          id,
          value,
          onChange,
          onBlur
        }}
        className={`form-check-input shadow-none ${isInvalid ? 'is-invalid' : ''}`}
        type="checkbox"
        defaultChecked={isDefaultChecked}
        disabled={isDisabled}
      />
    </div>
  );

  if (type === 'primary') return primarySwitch;
  if (type === 'secondary') return secondarySwitch;
}

Switch.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDefaultChecked: PropTypes.bool
};

Switch.defaultProps = {
  label: '',
  type: 'primary',
  id: '',
  name: '',
  onChange: () => {},
  onBlur: () => {},
  isInvalid: null,
  isDisabled: null,
  isDefaultChecked: null
};

export default Switch;
