import React from 'react';
import { Col, Row } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Form, Formik } from 'formik';
import { FormCheck, FormControl } from 'react-bootstrap';
import { Button } from '@core/Inputs';
import { useDashBoard } from '@context/MobileOne/DashBoard';

export function CreditOptions() {
  // Context
  const { t } = useStaticContent();
  const { validationSchema, formValue, onSubmit, isLoading, setFormValue } = useDashBoard();
  // State

  // Function

  return (
    <Row>
      <Col md={4}>
        <Button
          variant="link"
          onClick={() =>
            setFormValue({
              isRechargeOptions: false,
              creditOption: '',
              sofortOption: '',
              isSofortSelected: false,
              cashcode: '',
              isCashcodeEntered: false
            })
          }
        >
          <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
        </Button>
      </Col>
      <Col md={8}>
        <h3>{t('sh_db_charge_button')}</h3>
        <Formik
          initialValues={{ ...formValue }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values: { creditOption }, handleBlur, handleChange, handleSubmit, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <div className="d-inline">
                    <FormCheck
                      label={t('sh_db_charge_balance_h1')}
                      name="creditOption"
                      type="radio"
                      className="m-0"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={t('sh_db_charge_balance_h1')}
                      checked={creditOption === t('sh_db_charge_balance_h1')}
                      id="inline-tdm-1"
                    />
                    <p className="mx-4">{t('sh_db_charge_balance_subheadline1')}</p>
                  </div>
                  <div className="d-inline">
                    <FormCheck
                      label={t('sh_db_charge_balance_h2')}
                      name="creditOption"
                      type="radio"
                      className="m-0"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={t('sh_db_charge_balance_h2')}
                      checked={creditOption === t('sh_db_charge_balance_h2')}
                      id="inline-tdm-2"
                    />
                    <p className="mx-4">{t('sh_db_charge_balance_subheadline2')}</p>
                  </div>
                  <FormControl.Feedback type="invalid" className="d-block">
                    {errors.creditOption}
                  </FormControl.Feedback>
                </Col>
                <Col xs={12} className="pt-3">
                  <Button variant="primary" type="submit" isLoading={isLoading}>
                    {t('sh_db_charge_balance_next_button')}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}

export default CreditOptions;
