import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button, Select, Text } from '@core/Inputs';
import { useAccount } from '@context/MobileOne';
import { cancelContractReason, contractCancellationReasons } from '@utils/globalConstant';
import { useNavigate } from 'react-router-dom';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_LINK, UTAG_VIEWS } from '@utils/utag';
import { useLayout } from '@context/Utils';
import './style.scss';

export function CancelContract() {
  // Context
  const { t, staticContentData } = useStaticContent();
  const navigate = useNavigate();
  const {
    isLoading,
    cancelContractInitialValue,
    cancelContractValidation,
    onCancelConractFormSubmit
  } = useAccount();
  const { setMetaTags } = useMetaTag();
  const { setLightHeader } = useLayout();

  useEffect(() => {
    setLightHeader(false);

    return () => {
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_cancellation_meta_title'),
      description: t('sh_web_page_cancellation_meta_description'),

      twitterTitle: t('sh_web_page_cancellation_meta_title'),
      twitterDescription: t('sh_web_page_cancellation_meta_description'),

      ogTitle: t('sh_web_page_cancellation_meta_title'),
      ogDescription: t('sh_web_page_cancellation_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.CANCEL_CONTRACT_MOUNTING);
    }
  }, [window.utag]);

  return (
    <>
      {/* <section className="position-relative py-5 pb-0 pb-lg-5 mb-0 mb-lg-5"> */}
      <section className="position-relative pt-5 pb-56 pb-lg-80">
        {/* <div className="container pt-0 pt-lg-5 py-5 pb-0 pb-md-5 mt-0 mt-lg-4"> */}
        <div className="container pt-5 mt-0 mt-lg-4">
          {/* <div className="row py-5 pb-0"> */}
          <div className="row pt-lg-5">
            <div className="col-xl-7 offset-xl-1 col-md-8 offset-md-2 col-lg-7 col-12">
              <h1
                className="primary-color-text my-5 pb-0 pb-xl-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_cancel_contract_h1') }}
              />
              <div className="ps-xl-5 ms-xl-5">
                <div
                  className="body-large-bold primary-color-text mb-4"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_cancel_contract_text') }}
                />
                <div
                  // className="body-large-regular primary-color-text mb-4 mb-sm-0"
                  className="body-large-regular primary-color-text"
                  dangerouslySetInnerHTML={{ __html: t('sh_web_cancel_contract_text2') }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="position-relative bg-offWhite-color gray-can-shade gray-shade-3 my-5 pt-5 pb-0"> */}
      <section className="position-relative bg-offWhite-color py-56 py-lg-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 offset-xl-2 col-md-8 offset-md-2 col-lg-8 col-12">
              <h4
                className="primary-color-text mb-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_cancel_contract_subheadline') }}
              />

              <div className="cancelation-form-row">
                <Formik
                  initialValues={cancelContractInitialValue}
                  validationSchema={cancelContractValidation}
                  validateOnMount
                  onSubmit={(values) => {
                    if (window && window.utag) {
                      window.utag.link(UTAG_LINK.CANCEL_CONTRACT_ON_CLICK.TARMINATE_ON_CLICK);
                    }
                    onCancelConractFormSubmit(values);
                  }}
                >
                  {({
                    values: {
                      firstName,
                      lastName,
                      emailAddress,
                      customerNumber,
                      phoneNumber,
                      iban,
                      cancellationReason
                    },
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    errors
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-xl-12">
                          <h6
                            className="primary-color-text heading-small mb-3 mb-xl-2"
                            dangerouslySetInnerHTML={{
                              __html: t('sh_web_cancel_contract_personal_data_subheadline')
                            }}
                          />
                        </div>
                        <div className="col-xl-6 col-sm-6 col-12 mb-0 mb-xl-4">
                          <Text
                            type="text"
                            id="firstName"
                            name="firstName"
                            onChange={handleChange}
                            value={firstName}
                            placeholder={t('sh_web_cancel_contract_placeholder1')}
                            isValid={touched.firstName && !errors.firstName}
                            isInvalid={!!touched.firstName && !!errors.firstName}
                            onBlur={handleBlur}
                            error={touched.firstName && errors.firstName ? errors.firstName : null}
                          />
                        </div>
                        <div className="col-xl-6 col-sm-6 col-12 mb-0 mb-md-4 mb-xl-4">
                          <Text
                            type="text"
                            id="lastName"
                            name="lastName"
                            onChange={handleChange}
                            value={lastName}
                            placeholder={t('sh_web_cancel_contract_placeholder2')}
                            isValid={touched.lastName && !errors.lastName}
                            isInvalid={!!touched.lastName && !!errors.lastName}
                            onBlur={handleBlur}
                            error={touched.lastName && errors.lastName ? errors.lastName : null}
                          />
                        </div>
                        <div className="col-xl-12 col-12 mb-5 mb-xl-5">
                          <Text
                            type="text"
                            id="emailAddress"
                            name="emailAddress"
                            onChange={handleChange}
                            value={emailAddress}
                            placeholder={t('sh_web_cancel_contract_placeholder3')}
                            isValid={touched.emailAddress && !errors.emailAddress}
                            isInvalid={!!touched.emailAddress && !!errors.emailAddress}
                            onBlur={handleBlur}
                            error={
                              touched.emailAddress && errors.emailAddress
                                ? errors.emailAddress
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12 col-12">
                          <h6
                            className="primary-color-text heading-small mb-3 mb-xl-2"
                            dangerouslySetInnerHTML={{
                              __html: t('sh_web_cancel_contract_identification_subheadline')
                            }}
                          />
                        </div>
                        <div className="col-xl-6 mb-0 mb-md-4 mb-xl-5">
                          <Text
                            type="text"
                            id="customerNumber"
                            name="customerNumber"
                            onChange={handleChange}
                            value={customerNumber}
                            placeholder={t('sh_web_cancel_contract_placeholder4')}
                            isValid={touched.customerNumber && !errors.customerNumber}
                            isInvalid={!!touched.customerNumber && !!errors.customerNumber}
                            onBlur={handleBlur}
                            error={
                              touched.customerNumber && errors.customerNumber
                                ? errors.customerNumber
                                : null
                            }
                          />
                        </div>
                        <div className="col-xl-6 mb-5 mb-xl-5">
                          <Text
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={handleChange}
                            value={phoneNumber}
                            placeholder={t('sh_web_cancel_contract_placeholder5')}
                            isValid={touched.phoneNumber && !errors.phoneNumber}
                            isInvalid={!!touched.phoneNumber && !!errors.phoneNumber}
                            onBlur={handleBlur}
                            error={
                              touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : null
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <h6
                            className="primary-color-text heading-small mb-3 mb-xl-2"
                            dangerouslySetInnerHTML={{
                              __html: t('sh_web_cancel_contract_iban_subheadline')
                            }}
                          />
                        </div>
                        <div className="col-xl-12 mb-5 mb-xl-5">
                          <Text
                            type="text"
                            id="iban"
                            name="iban"
                            onChange={handleChange}
                            value={iban}
                            placeholder={t('sh_web_cancel_contract_placeholder6')}
                            // isValid={touched.iban && !errors.iban}
                            isInvalid={!!touched.iban && !!errors.iban}
                            onBlur={handleBlur}
                            error={touched.iban && errors.iban ? errors.iban : null}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <h6
                            className="primary-color-text heading-small mb-3 mb-xl-0"
                            dangerouslySetInnerHTML={{
                              __html: t('sh_web_cancel_contract_reason_subheadline')
                            }}
                          />
                        </div>
                        <div className="col-xl-12 mb-5 mt-2 mb-xl-5">
                          <Select
                            placeholder={t('sh_login_cancel_reason_op1')}
                            id="cancellationReason"
                            name="cancellationReason"
                            defaultOption={t('sh_login_cancel_reason_op1')}
                            options={cancelContractReason}
                            value={cancellationReason}
                            onChange={(e) =>
                              handleChange({
                                target: {
                                  id: 'cancellationReason',
                                  name: 'cancellationReason',
                                  value: e.target.value
                                }
                              })
                            }
                            getOptionId={(option) => option.id}
                            getOptionName={(option) => t(option.value)}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-7 col-12">
                          <Button
                            type="submit"
                            isLoading={isLoading}
                            className="btn btn-secondary w-100"
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: t('sh_web_cancel_contract_btn') }}
                            />
                          </Button>
                        </div>
                        <div className="col-xl-12">
                          <div
                            // className="primary-color-text body-large-regular mt-5 mb-5 mb-xl-0"
                            className="primary-color-text body-large-regular mt-5"
                            dangerouslySetInnerHTML={{ __html: t('sh_web_cancel_contract_text3') }}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="position-relative py-0 py-lg-2 py-xl-5">&nbsp;</section> */}
    </>
  );
}

export default CancelContract;
