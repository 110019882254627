/* eslint-disable react/jsx-fragments */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { appActivationFlowType } from '@utils/globalConstant';

export function Stepper({
  currentStep,
  setCurrentStep,

  items
}) {
  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // Optional if you want to skip the scrolling animation
    });
  }, [currentStep]);

  return (
    <section
      className={`${
        appActivationFlowType.SIM_ACTIVATION && currentStep === 9
          ? ''
          : 'bg-primary-color pt-5 pb-5 sim-activation-sec'
      }`}
    >
      {/* <div className="bs-stepper-header" role="tablist">
          {items.map((step, stepIndex) => (
            <Fragment key={`stepper-header-${step.title}`}>
              <div className={currentStep === stepIndex ? 'step active' : 'step'}>
                <button
                  type="button"
                  disabled={stepIndex > currentStep}
                  className={`step-trigger ${stepIndex === 0 ? 'ps-0' : ''} ${
                    stepIndex === items.length - 1 ? 'pe-0' : ''
                  }`}
                  // role="tab"
                  onClick={() => setCurrentStep(stepIndex)}
                >
                  <span className="bs-stepper-circle">{stepIndex + 1}</span>
                  <span className="bs-stepper-label">{step.title}</span>
                </button>
              </div>
              {stepIndex > -1 && stepIndex < items.length - 1 && <div className="line" />}
            </Fragment>
          ))}
        </div> */}
      {items.map(
        (step, stepIndex) =>
          currentStep === stepIndex && (
            <Fragment key={`stepper-content-${step.title}`}>{step.Component}</Fragment>
          )
      )}
    </section>
  );
}

Stepper.propTypes = {
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      Component: PropTypes.node.isRequired
    })
  ).isRequired
};

Stepper.defaultProps = {
  currentStep: 0,
  setCurrentStep: () => {}
};

export default Stepper;
