import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useMetaTag } from '@context/Utils/MetaTag';
import { useLayout } from '@context/Utils';

export function Imprint() {
  // Contexts
  const { setLightHeader } = useLayout();
  const { t, staticContentData } = useStaticContent();
  const { setMetaTags } = useMetaTag();

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_legal_impressum_meta_title'),
      description: t('sh_web_page_legal_impressum_meta_description'),

      twitterTitle: t('sh_web_page_legal_impressum_meta_title'),
      twitterDescription: t('sh_web_page_legal_impressum_meta_description'),

      ogTitle: t('sh_web_page_legal_impressum_meta_title'),
      ogDescription: t('sh_web_page_legal_impressum_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  useEffect(() => {
    setLightHeader(false);

    return () => {
      setLightHeader(true);
    };
  }, []);

  return (
    <>
      {/* <section className="position-relative py-5 pb-0 pb-lg-5 mb-0 mb-lg-5"> */}
      <section className="position-relative pt-5 pb-0">
        <div className="container pt-0 pt-lg-5 py-5 pb-0 pb-md-5 mt-0 mt-lg-4">
          {/* <div className="row py-5 pb-0"> */}
          <div className="row py-5 pb-4">
            <div className="col-xl-5 offset-xl-1 col-md-6 offset-md-1 col-12">
              <h1
                className="primary-color-text my-5 mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h0') }}
              />
              <div
                className="body-large-bold primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h1') }}
              />
              <div
                className="body-large-regular primary-color-text mb-4 pe-xl-5"
                dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text1') }}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 gray-shade-3 my-5 py-5 mb-5 mb-sm-0"> */}
      <section className="position-relative bg-offWhite-color pt-56 pt-lg-80">
        {/* <div className="container pb-0 pb-sm-2"> */}
        <div className="container">
          {/* <div className="row pb-0 pb-lg-4"> */}
          <div className="row pb-4">
            <div className="col-xl-7 offset-xl-2 col-md-8 offset-md-2 col-12">
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h2') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text2') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h3') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text3') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h4') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text4') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h5') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text5') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h6') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text6') }}
                />
              </div>
              <div className="mb-0 mb-sm-5 pb-0 pb-xl-3">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h7') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-4"
                  dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text7') }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Container>
        <br />
        <br />
        <Row>
          <Col md={6} xs={12} className="mb-5">
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h0') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h1') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text1') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h2') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text2') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h3') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text3') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h4') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text4') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h5') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text5') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h6') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text6') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_h7') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_Impressum_text7') }} />
            <div dangerouslySetInnerHTML={{ __html: t('test') }} />
          </Col>
        </Row>
      </Container> */}
    </>
  );
}

export default Imprint;
