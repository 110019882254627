import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_VIEWS } from '@utils/utag';
import './style.scss';

export function ReturnPolicy() {
  // Context
  const { t, staticContentData } = useStaticContent();
  const { setMetaTags } = useMetaTag();

  // Hooks
  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_return_policy_agb_meta_title'),
      description: t('sh_web_page_return_policy_agb_meta_description'),

      twitterTitle: t('sh_web_page_return_policy_agb_meta_title'),
      twitterDescription: t('sh_web_page_return_policy_agb_meta_description'),

      ogTitle: t('sh_web_page_return_policy_agb_meta_title'),
      ogDescription: t('sh_web_page_return_policy_agb_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.RETURN_POLICY_MOUNTING);
    }
  }, [window.utag]);

  return (
    <section className="position-relative bg-offWhite-color gray-shade-1 py-5">
      <div className="container pt-0 pt-lg-5 py-5 mt-0 mt-lg-4">
        <div className="row py-5 pb-0 pb-sm-5">
          <div className="col-xl-7 offset-xl-1 col-lg-8 col-md-8 col-12 pe-xl-5">
            <h1
              className="primary-color-text my-5 mb-4"
              dangerouslySetInnerHTML={{ __html: t('sh_agb_hinweise_h0') }}
            />
            <div
              className="body-large-bold primary-color-text"
              dangerouslySetInnerHTML={{ __html: t('sh_agb_hinweise_h1') }}
            />
            <div
              className="body-large-regular link-text primary-color-text my-4 mt-0"
              dangerouslySetInnerHTML={{ __html: t('sh_agb_hinweise_text1') }}
            />
            <div
              className="body-large-bold primary-color-text"
              dangerouslySetInnerHTML={{ __html: t('sh_agb_hinweise_h2') }}
            />
            <div
              className="body-large-regular primary-color-text my-4 mt-0 mb-4"
              dangerouslySetInnerHTML={{ __html: t('sh_agb_hinweise_text2') }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReturnPolicy;
