import React from 'react';
import { Col, Row } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import InputMask from 'react-input-mask';
import { Form, Formik } from 'formik';
import { Button, Switch } from '@core/Inputs';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { FormControl } from 'react-bootstrap';

export function CashcodeOptions() {
  // Context
  const { t } = useStaticContent();
  const { validationSchemaCashcode, formValue, setFormValue, onSubmit, isLoading } = useDashBoard();
  // State

  // Function

  return (
    <Row>
      <Col md={4}>
        <Button
          variant="link"
          onClick={() =>
            setFormValue({
              ...formValue,
              creditOption: '',
              cashcode: '',
              isCashcodeEntered: false
            })
          }
        >
          <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
        </Button>
      </Col>
      <Col md={4}>
        <h3>{t('sh_db_charge_balance_h2')}</h3>
        <Formik
          initialValues={{ ...formValue }}
          validationSchema={validationSchemaCashcode}
          onSubmit={onSubmit}
        >
          {({
            values: { cashcode, isCashcodeEntered },
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <h6>{t('sh_db_charge_balance_amount')}</h6>
                <Col className="m-2">
                  <p>{t('sh_db_charge_balance_cash_code_info')}</p>
                  <InputMask
                    mask="9999-9999-9999-9"
                    maskChar={null}
                    onBlur={handleBlur}
                    placeholder={t('sh_db_charge_balance_cash_code')}
                    onChange={handleChange}
                    name="cashcode"
                    value={cashcode}
                    className={
                      !!touched.cashcode && !!errors.cashcode
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    isInvalid={!!touched.cashcode && !!errors.cashcode}
                    error={touched.cashcode && errors.cashcode ? errors.cashcode : null}
                  />
                  <FormControl.Feedback type="invalid" className="d-block">
                    {errors.cashcode}
                  </FormControl.Feedback>
                  {/*   <Text
                                        type="text"
                                        placeholder={t('sh_db_charge_balance_cash_code')}
                                        id="cashcode"
                                        name="cashcode"
                                        onChange={handleChange}
                                        value={cashcode}
                                        // isValid={touched.cscPassword && !errors.cscPassword}
                                        isInvalid={!!touched.cashcode && !!errors.cashcode}
                                        onBlur={handleBlur}
                                        error={touched.cashcode && errors.cashcode ? errors.cashcode : null}
                                    /> */}
                  <hr />
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <p dangerouslySetInnerHTML={{ __html: t('sh_db_charge_balance_agb') }} />
                    <Switch
                      id="isCashcodeEntered"
                      name="isCashcodeEntered"
                      isDefaultChecked={isCashcodeEntered}
                      onChange={() => {
                        handleChange({
                          target: {
                            id: 'isCashcodeEntered',
                            name: 'isCashcodeEntered',
                            value: !isCashcodeEntered
                          }
                        });
                      }}
                      isValid={touched.isCashcodeEntered && !errors.isCashcodeEntered}
                      isInvalid={!!touched.isCashcodeEntered && !!errors.isCashcodeEntered}
                      error={
                        touched.isCashcodeEntered && errors.isCashcodeEntered
                          ? errors.isCashcodeEntered
                          : null
                      }
                    />
                  </div>
                </Col>
                <Col xs={12} className="pt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    isLoading={isLoading}
                    dangerouslySetInnerHTML={{ __html: t('sh_db_charge_balance_cash_code_button') }}
                    disabled={isCashcodeEntered === false}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}

export default CashcodeOptions;
