import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { usePersonalData } from '@context/MobileOne/Activation/PersonalData';
import { Button, Text } from '@core/Inputs';

import { appActivationFlowType, appButtonType } from '@utils/globalConstant';
import { useActivation } from '@context/MobileOne';
import { UTAG_VIEWS } from '@utils/utag';

import './style.scss';

export function LoginPassword() {
  // Constants

  // States

  // Contexts
  const {
    isLoading,
    changePassword,
    passwordValue,
    validatePasswordWithKeys,
    changePasswordErrorMsg,
    setChangePasswordErrorMsg
  } = usePersonalData();
  const { activationInfo, prevStep, activationType } = useActivation();
  const { t } = useStaticContent();

  // Hooks
  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.LOGIN_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.LOGIN_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
      if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.LOGIN_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.LOGIN_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  return (
    <>
      <div className="container">
        <div className="row position-relative">
          {/* <div className="col-12 orange-gray-bubble"></div> */}
          <div className="col-md-3 pb-5 mt-5">
            <div className="back-btn">
              <a
                href="#"
                className="text-white body-medium-bold text-decoration-none link-light"
                onClick={prevStep}
              >
                <i className="fa fa-arrow-left me-3"></i>
                {t('sh_back_button')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-sm-5">
        <div className="row">
          <div className="col-12 col-md-11 offset-md-1 col-lg-8 offset-lg-1 mb-2 mb-sm-0">
            <h6 className="text-uppercase text-white mb-4">
              {t(
                activationType === appActivationFlowType.SIM_ACTIVATION
                  ? 'sh_apoc_step8/9'
                  : 'sh_apoc_login_step7'
              )}
            </h6>
            <h1 className="pink-color-text mt-2 mb-sm-5">{t('sh_apoc_login_h1')}</h1>
            <h6 className="text-white body-large-regular body-large-20 mt-4 mt-sm-0 mb-4">
              {t('sh_apoc_login_subheader1')}
            </h6>
            <h6 className="offWhite-color-text heading-small font-16">
              {t('sh_apoc_login_username')}
            </h6>
            <p className="pink-color-text heading-small font-16">
              {activationInfo.currentNumber
                ? activationInfo.currentNumber
                : activationInfo.newNumber}
            </p>
          </div>
        </div>

        <div className="row mt-sm-4 mb-5 mb-sm-0">
          <Formik
            initialValues={{ ...passwordValue }}
            validate={(v) => validatePasswordWithKeys(v, 'cscPassword')}
            validateOnMount
            onSubmit={changePassword}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldTouched
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="col-12 col-md-11 offset-md-1 col-lg-7 offset-lg-1 mb-5 mb-sm-0">
                  <div className="row mb-4">
                    <div className="col-12 col-sm-7">
                      <label className="offWhite-color-text heading-small mb-2 font-16">
                        {t('sh_apoc_login_subheader2')}
                      </label>
                      <Text
                        type="password"
                        id="cscPassword"
                        name="cscPassword"
                        onChange={(e) => {
                          setFieldTouched('cscPassword');
                          handleChange(e);
                          setChangePasswordErrorMsg('');
                        }}
                        value={values.cscPassword}
                        placeholder={t('sh_apoc_login_password_input')}
                        isInvalid={!!touched.cscPassword && !!errors.cscPassword}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <p className="text-white body-medium-bold">{t('sh_apoc_login_password_info')}</p>
                  <p
                    className={`${
                      (!touched.cscPassword || !errors?.otherErrors) && 'offWhite-color-text'
                    }
                      ${
                        touched.cscPassword &&
                        !errors?.otherErrors &&
                        values.cscPassword &&
                        'success-color-text'
                      }
                      body-small-regular mt-3 m-0 ${
                        touched.cscPassword && errors?.otherErrors
                          ? !errors?.otherErrors?.minMax
                            ? 'success-color-text'
                            : 'error-color-text'
                          : ''
                      }`}
                  >
                    {t('sh_pwd_require1')}
                  </p>
                  <p
                    className={`${
                      (!touched.cscPassword || !errors?.otherErrors) && 'offWhite-color-text'
                    }
                      ${
                        touched.cscPassword &&
                        !errors?.otherErrors &&
                        values.cscPassword &&
                        'success-color-text'
                      }
                      body-small-regular m-0 ${
                        touched.cscPassword && errors?.otherErrors
                          ? !errors?.otherErrors?.uppercase
                            ? 'success-color-text'
                            : 'error-color-text'
                          : ''
                      }`}
                  >
                    {t('sh_pwd_require2')}
                  </p>
                  <p
                    className={`${
                      (!touched.cscPassword || !errors?.otherErrors) && 'offWhite-color-text'
                    }
                      ${
                        touched.cscPassword &&
                        !errors?.otherErrors &&
                        values.cscPassword &&
                        'success-color-text'
                      }
                      body-small-regular m-0 ${
                        touched.cscPassword && errors?.otherErrors
                          ? !errors?.otherErrors?.number
                            ? 'success-color-text'
                            : 'error-color-text'
                          : ''
                      }`}
                  >
                    {t('sh_pwd_require3')}
                  </p>
                  <p
                    className={`${
                      (!touched.cscPassword || !errors?.otherErrors) && 'offWhite-color-text'
                    }
                      ${
                        touched.cscPassword &&
                        !errors?.otherErrors &&
                        values.cscPassword &&
                        'success-color-text'
                      }
                      body-small-regular m-0 ${
                        touched.cscPassword && errors?.otherErrors
                          ? !errors?.otherErrors?.specific
                            ? 'success-color-text'
                            : 'error-color-text'
                          : ''
                      }`}
                  >
                    {t('sh_pwd_require4')}
                  </p>
                  {changePasswordErrorMsg && (
                    <p class="body-small-regular m-0 error-color-text">{changePasswordErrorMsg}</p>
                  )}
                  <div className="row mb-5 mb-sm-4">
                    <div className="col-12 col-sm-7">
                      <Button
                        buttonType={appButtonType.PRIMARY}
                        className="bg-offWhite-color w-100 mt-5"
                        type="submit"
                        isLoading={isLoading}
                        disabled={
                          !values.cscPassword ||
                          changePasswordErrorMsg ||
                          (errors &&
                            errors.otherErrors &&
                            Object.keys(errors.otherErrors || {}).filter(
                              (v) => errors && errors.otherErrors && errors.otherErrors[v]
                            ).length > 0)
                        }
                      >
                        {t('sh_apoc_login_next_button')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default LoginPassword;
