import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import globalConstant from '@utils/globalConstant';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_VIEWS } from '@utils/utag';
import './style.scss';
import { useLayout } from '@context/Utils';

export function Privacy() {
  // States
  const [pdfLink, setPdfLink] = useState([]);

  // Context
  const { t, getPdfList, staticContentData } = useStaticContent();
  const { setLightHeader } = useLayout();
  const { appStaticPdf } = globalConstant;
  const { setMetaTags } = useMetaTag();

  // Function
  const onPdfClick = (contentId) => {
    // console.log('contentId', contentId);
    if (window && window.utag && contentId) {
      window.utag.link({
        link_id: contentId
      });
    }
  };

  // Hooks
  useEffect(() => {
    setLightHeader(false);
    return () => {
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.PRIVACY_MOUNTING);
    }
  }, [window.utag]);

  useEffect(() => {
    if (staticContentData) {
      let arr = getPdfList('sh_pdfLists').find(
        (item) => item.listName === appStaticPdf.DATA_PRIVACY
      );
      // console.log('arr', arr);
      setPdfLink(arr.listContent);
    }

    setMetaTags({
      title: t('sh_web_page_legal_datenschutz_meta_title'),
      description: t('sh_web_page_legal_datenschutz_meta_description'),

      twitterTitle: t('sh_web_page_legal_datenschutz_meta_title'),
      twitterDescription: t('sh_web_page_legal_datenschutz_meta_description'),

      ogTitle: t('sh_web_page_legal_datenschutz_meta_title'),
      ogDescription: t('sh_web_page_legal_datenschutz_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  return (
    <>
      {/* <section className="position-relative py-5 pb-0 pb-lg-5 mb-0 mb-lg-5"> */}
      <section className="position-relative pt-5 pb-56 pb-lg-80">
        <div className="container pt-0 pt-lg-5 mt-0 mt-lg-4">
          {/* <div className="row py-5 pb-4 pb-sm-5"> */}
          <div className="row pt-5">
            <div className="col-xl-6 offset-xl-1 col-lg-7 offset-lg-1 col-md-7 offset-md-2 col-sm-10 col-12">
              <h1
                className="primary-color-text my-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_privacy_h1') }}
              />
              <div
                className="body-large-regular primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_privacy_subheader') }}
              />
              <div
                className="body-large-regular primary-color-text mb-0 mb-md-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_privacy_subheader_2') }}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 gray-shade-3 grey-shade my-5 my-md-0 my-xl-5 py-5"> */}
      <section className="position-relative bg-offWhite-color py-56 py-lg-80">
        {/* <div className="container pb-0 pb-sm-5"> */}
        <div className="container">
          {/* <div className="row pb-0 pb-xl-5"> */}
          <div className="row">
            <div className="col-xl-7 offset-xl-2 col-lg-8 col-md-8 offset-md-2 col-12">
              <ol className="mb-4 mt-3 mt-sm-0 pb-2 px-4">
                {pdfLink.map((item, index) => (
                  <li className="body-large-regular primary-color-text" key={`${index + 1}`}>
                    {item.id < 0 ? (
                      <a
                        className="link primary-color-text"
                        target="_blank"
                        href={item.externalLink}
                        onClick={() => onPdfClick(item.UTAG_ON_CLICK)}
                      >
                        {item.name || item.externalLink}
                      </a>
                    ) : (
                      <a
                        className="link primary-color-text"
                        target="_blank"
                        href={item.media_url_display}
                        onClick={() => onPdfClick(item.UTAG_ON_CLICK)}
                      >
                        {item.name}
                      </a>
                    )}
                  </li>
                ))}
              </ol>
              <h5
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_privacy_notes') }}
              />
              <div
                // className="body-large-regular primary-color-text mb-0 mb-lg-5"
                className="body-large-regular primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_privacy_text') }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacy;
