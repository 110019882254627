import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useOption } from '@context/MobileOne';
import { Button, Checkbox } from '@core/Inputs';
import { Col, Container, Row } from '@core/Layout';
import { PassOfferProduct } from '@part/PassOffer/Product';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { usePassOffer } from '@context/MobileOne/TariffOption/PassOffer';

export function BookPassOffers() {
  // States
  const [passOfferDetails, setPassOfferDetails] = useState({});

  // Contexts
  const { t } = useStaticContent();
  const { isLoading, termsValidations } = useOption();
  const { passCode } = useParams();
  const { passOffers } = useDashBoard();
  const navigate = useNavigate();
  const { bookPassOffer } = usePassOffer();

  // Hooks
  useEffect(() => {
    if (passCode && passOffers.length > 0) {
      const findPassOffers = passOffers.find(({ passCode: id }) => id === passCode);
      if (findPassOffers) {
        setPassOfferDetails(findPassOffers);
      }
    }
  }, [passOffers, passCode]);

  // Functions
  const onSubmit = () => bookPassOffer(passCode);

  return (
    <>
      <Container>
        <div>
          <hr />
          <h2>Offer Passes</h2>
          <hr />
          <h4 className="py-4">{passOfferDetails.name}</h4>
          <Formik
            initialValues={{
              terms: false
            }}
            enableReinitialize
            validationSchema={termsValidations}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, errors, touched, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} lg={6}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <PassOfferProduct {...{ ...passOfferDetails }} />
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="">
                      {passOfferDetails.tariffZonesName}
                      <div className="py-4">
                        <Checkbox
                          className="py-4"
                          type="checkbox"
                          label="You must accept my terms!"
                          id="terms"
                          name="terms"
                          onChange={handleChange}
                          value={values.terms}
                          isInvalid={!!touched.terms && !!errors.terms}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className="py-3 d-inline-flex justify-content-between">
                    <Button
                      variant="secondary"
                      type="button"
                      role="tab"
                      onClick={() => navigate(-1)}
                    >
                      {t('sh_back_button')}
                    </Button>
                    <Button variant="primary" type="submit" isLoading={isLoading}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </>
  );
}

export default BookPassOffers;
