/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appActivationFlowType } from '@utils/globalConstant';
import { UTAG_LINK, utag } from '@utils/utag';

export function UserAccount({ activationType, activationInfo, setEditStep, setCurrentStep }) {
  const { t } = useStaticContent();
  const birthDate = new Date(activationInfo.birthDate);
  const yyyy = birthDate.getFullYear();
  let mm = birthDate.getMonth() + 1; // Months start at 0!
  let dd = birthDate.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return (
    <div className="row">
      <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1 mt-sm-3">
        <hr className="border-light-purple border-1 mb-5" style={{ opacity: 0.5 }} />
        <div className="d-flex justify-content-between align-items-start mb-2">
          <h6 className="heading-small pink-color-text">{t('sh_apoc_summary_personal_h')}</h6>
          <a
            className="text-white body-medium-regular p-0 mb-3 mb-sm-0 link-light shadow-none"
            variant="link"
            onClick={() => {
              if (window && window.utag) {
                activationType === appActivationFlowType.NEW_ONLINE
                  ? window.utag.link(
                      UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.EDIT_PERSONAL_DATA_ON_CLICK
                    )
                  : window.utag.link(
                      UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.EDIT_PERSONAL_DATA_ON_CLICK
                    );
              }

              setEditStep(activationType === appActivationFlowType.SIM_ACTIVATION ? 6 : 4);
              setCurrentStep(activationType === appActivationFlowType.SIM_ACTIVATION ? 6 : 4);
            }}
          >
            {t('sh_apoc_summary_personal_data')}
          </a>
        </div>
        <p className="text-white body-large-regular m-0">
          {activationInfo.firstName} {activationInfo.lastName}
        </p>
        <p className="text-white body-large-regular m-0">
          {activationInfo.street} {activationInfo.houseNumber}
        </p>
        <p className="text-white body-large-regular m-0">
          {activationInfo.zip} {activationInfo.city}
        </p>
        <p className="text-white body-large-regular mt-3">
          <b className="body-large-bold">{t('sh_apoc_summary_personal_subheader1')} </b>
          {`${dd}.${mm}.${yyyy}`}
        </p>
        <p className="text-white body-large-regular text-white mt-3 mb-0 mb-sm-2">
          <b className="body-large-bold">{t('sh_apoc_summary_personal_subheader2')} </b>
          {activationInfo.email}
        </p>
      </div>
    </div>
  );
}

UserAccount.propTypes = {
  activationInfo: PropTypes.object
};
UserAccount.defaultProps = {
  activationInfo: {}
};

export default UserAccount;
