import React from 'react';
import { Button } from '@core/Inputs';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appActivationFlowType, appButtonType } from '@utils/globalConstant';
import { UTAG_LINK, utag } from '@utils/utag';

export default function SelectedPaymentMethod({
  activationType,
  setActivationInfo,
  activationInfo,
  setEditStep,
  setCurrentStep
}) {
  const { t } = useStaticContent();

  return (
    <>
      {activationInfo.paymentMethod && activationInfo.payment && (
        <div className="row">
          {/* <div className="col-12 col-md-8 offset-md-1 col-lg-6 offset-lg-1 mb-5 mb-sm-0"> */}
          <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1 mt-sm-3">
            <hr className="border-light-purple border-1 mb-5" style={{ opacity: 0.5 }} />
            <div className="d-flex justify-content-between align-items-start mb-2">
              <h6 className="heading-small pink-color-text">{t('sh_apoc_summary_top-up_h')}</h6>
              <a
                className="text-white body-medium-regular p-0 mb-5 mb-sm-0 link-light shadow-none"
                variant="link"
                onClick={() => {
                  // delete activationInfo.payment;
                  if (window && window.utag) {
                    activationType === appActivationFlowType.NEW_ONLINE
                      ? window.utag.link(
                          UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.EDIT_PAYMENT_METHOD_ON_CLICK
                        )
                      : window.utag.link(
                          UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.EDIT_PAYMENT_METHOD_ON_CLICK
                        );
                  }

                  setActivationInfo({ ...activationInfo, paymentMethod: '' });
                  setEditStep(activationType === appActivationFlowType.SIM_ACTIVATION ? 5 : 3);
                  setCurrentStep(activationType === appActivationFlowType.SIM_ACTIVATION ? 5 : 3);
                }}
              >
                {t('sh_apoc_summary_adjust')}
              </a>
            </div>
            {/* <h5 className="pink-color-text">{t('sh_apoc_summary_top-up_h')}</h5>
            <p className="text-white">
              <b className="text-capitalize">
                {activationInfo.paymentMethod === 'creditcard_braintree'
                  ? 'creditcard'
                  : activationInfo.paymentMethod}
                :{' '}
              </b>{' '}
              {activationInfo.email}{' '}
            </p>
            <p className="text-white">
              {t('sh_sm_switch_summary_text3').replace('{{amount}}', 15)}
            </p> */}
          </div>
          <div className="col-12 col-md-8 offset-md-1 col-lg-6 offset-lg-1 mb-5 mb-sm-0">
            <p className="text-white">
              <b className="text-capitalize">
                {/* {activationInfo.paymentMethod === 'creditcard_braintree'
                  ? 'creditcard'
                  : activationInfo.paymentMethod} */}
                {t(
                  activationInfo.paymentMethod === 'creditcard_braintree'
                    ? 'sh_apoc_payment_option2'
                    : 'sh_apoc_payment_option1'
                )}
                :{' '}
              </b>{' '}
              {/* {JSON.stringify(activationInfo.payment)} */}
              {activationInfo.paymentMethod === 'creditcard_braintree'
                ? activationInfo?.payment?.paymentMethodDetails?.maskedCreditCardNumber
                : activationInfo.paymentMethod === 'paypal'
                ? activationInfo?.payment?.paymentMethodDetails?.accountQualifier
                : activationInfo.email}{' '}
            </p>
            {activationInfo.enoughCredit ||
            activationType === appActivationFlowType.SIM_ACTIVATION ? (
              <p className="text-white">
                {t('sh_apoc_summary_top-up_text').replace('{{amount}}', 15)}
              </p>
            ) : (
              <p className="text-white">{t('sh_apoc_summary_top-up_text1')}</p>
            )}
          </div>
          {/* <div className="col-6">
            <Button
              buttonType={appButtonType.LINK}
              variant="link"
              className="text-white"
              onClick={() => {
                setCurrentStep(4);
                delete activationInfo.payment;
                delete activationInfo.checkoutSessionId;
                setActivationInfo({ ...activationInfo });
              }}
            >
              {t('sh_apoc_summary_adjust')}
            </Button>
          </div> */}
        </div>
      )}
      <></>
    </>
  );
}
