import React from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useNumberPorting } from '@context/MobileOne/Activation/NumberPorting';
import { useActivation } from '@context/MobileOne';
import { Formik } from 'formik';
import { Button, Radio } from '@core/Inputs';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { Modal } from '@core/Utils';
import {
  appActivationFlowType,
  appActivationStep2Modals,
  appButtonType,
  appRoute
} from '@utils/globalConstant';
import { useNavigate } from 'react-router-dom';
import { UTAG_LINK, utag } from '@utils/utag';

export function NewRoamingNumberSelection({ modal, setModal, setScreen }) {
  // Context
  const { t } = useStaticContent();
  const { activeTariff } = useActivationTariff();
  const { validationSchemaPortType, formValue } = useNumberPorting();
  const { prevStep, activationType } = useActivation();
  const navigate = useNavigate();

  const newNumberOnSubmit = () => {
    if (window && window.utag) {
      activationType === appActivationFlowType.NEW_ONLINE
        ? window.utag.link(UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.NUMBER.CONTINUE_NEW_NUMBER_ON_CLICK)
        : window.utag.link(UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.NUMBER.CONTINUE_NEW_NUMBER_ON_CLICK);
    }
    setModal('newNumbers');
  };

  const oldNumberOnSubmit = () => {
    if (window && window.utag) {
      activationType === appActivationFlowType.NEW_ONLINE
        ? window.utag.link(
            UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.NUMBER.CONTINUE_NUMBER_PORTABILITY_ON_CLICK
          )
        : window.utag.link(
            UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.NUMBER.CONTINUE_NUMBER_PORTABILITY_ON_CLICK
          );
    }
    setModal('oldNumberProvider');
  };

  return (
    <>
      <Formik
        initialValues={formValue}
        validationSchema={validationSchemaPortType}
        enableReinitialize
        onSubmit={(values) => {
          values.isNewNumber === 'true' ? newNumberOnSubmit() : oldNumberOnSubmit();
          // onSubmit(values);
        }}
      >
        {({ values: { isNewNumber }, handleChange, handleBlur, handleSubmit, errors }) => (
          <section className="bg-default banner-sec">
            <div className="container">
              <div className="row position-relative">
                {/* <div className="col-12 orange-gray-bubble" /> */}
                <div className="col-md-3 pb-5 mt-5">
                  <div className="back-btn">
                    <a
                      className="text-white body-medium-bold text-decoration-none link-light"
                      onClick={() => {
                        if (window && window.utag) {
                          activationType === appActivationFlowType.NEW_ONLINE
                            ? window.utag.link(
                                UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.NUMBER.RETURN_ON_CLICK
                              )
                            : window.utag.link(
                                UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.NUMBER.RETURN_ON_CLICK
                              );
                        }

                        activationType === appActivationFlowType.SIM_ACTIVATION
                          ? prevStep()
                          : navigate(appRoute.HOME);
                      }}
                    >
                      <i className="fa fa-arrow-left me-3"></i>
                      {t('sh_back_button')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-9 offset-md-1 col-lg-8 offset-lg-1 mt-sm-5 mb-5 mb-sm-0">
                  <h6 className="text-uppercase text-white mb-0">
                    {t(
                      activationType === appActivationFlowType.SIM_ACTIVATION
                        ? 'sh_apoc_step3/9'
                        : 'sh_apoc_number_step1'
                    )}
                  </h6>
                  <h1 className="pink-color-text mt-2 mt-sm-2">{t('sh_apoc_number_h1')}</h1>
                  <div className="mt-4 mt-sm-5">
                    <p
                      className="body-large-regular font-24 text-white"
                      dangerouslySetInnerHTML={{ __html: t('sh_apoc_rufnummer_text') }}
                    />

                    <div className="form-check ps-0">
                      <Radio
                        type="primary"
                        label={t('sh_apoc_number_keep-number_h')}
                        description={t('sh_apoc_number_keep-number_text')}
                        labelClass="font-20"
                        name="isNewNumber"
                        onChange={(e) => {
                          if (window && window.utag) {
                            activationType === appActivationFlowType.NEW_ONLINE
                              ? window.utag.link(
                                  UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.NUMBER
                                    .NUMBER_PORTABILITY_ON_CLICK
                                )
                              : window.utag.link(
                                  UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.NUMBER
                                    .NUMBER_PORTABILITY_ON_CLICK
                                );
                          }
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value="false"
                        isDefaultChecked={isNewNumber === 'false'}
                        id="inline-tdm-1"
                        className="m-0 custom-form-check"
                        showPromotionStar
                        promotionText={t('sh_apoc_number_keep-number_bonus')}
                        promotionalAction={() => setModal(appActivationStep2Modals.FOOTNOTES)}
                      />
                    </div>
                    <div className="form-check ps-0">
                      <Radio
                        type="primary"
                        label={t('sh_apoc_number_new-number_h')}
                        labelClass="font-20"
                        description={t('sh_apoc_number_new-number_text1')}
                        name="isNewNumber"
                        onChange={(e) => {
                          if (window && window.utag) {
                            activationType === appActivationFlowType.NEW_ONLINE
                              ? window.utag.link(
                                  UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.NUMBER.NEW_NUMBER_ON_CLICK
                                )
                              : window.utag.link(
                                  UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.NUMBER.NEW_NUMBER_ON_CLICK
                                );
                          }

                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value="true"
                        isDefaultChecked={isNewNumber === 'true'}
                        id="inline-tdm-2"
                        className="m-0 custom-form-check"
                      />
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-7">
                        <div className="mb-5 pt-4">
                          <Button
                            type="submit"
                            className="w-100 bg-offWhite-color"
                            onClick={handleSubmit}
                          >
                            {t('sh_apoc_tarif_overview_next_button')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Formik>

      {modal === appActivationStep2Modals.FOOTNOTES && (
        <Modal
          showCloseButton
          closeButtonText="sh_apoc_tarif_overview_tarif-details_cancel"
          onCloseClick={() => {
            setModal(appActivationStep2Modals.NONE);
          }}
          header={''}
        >
          <div className="my-4 body-large-regular grey-color-text">
            <div
              dangerouslySetInnerHTML={{ __html: t('sh_apoc_number_keep-number_bonus_footnote') }}
            />
          </div>
        </Modal>
      )}
    </>
  );
}
export default NewRoamingNumberSelection;
