/**
 * Mark/store the script as fully loaded in a global variable.
 * @param src URL of the script
 */
function markScriptFullyLoaded(src) {
  try {
    let scriptLoadMap = window.scriptLoadMap || [];
    const index = scriptLoadMap.findIndex((script) => script && script.src === src);
    if (index > -1) {
      scriptLoadMap[index].loaded = true;
      window.scriptLoadMap = scriptLoadMap;
    } else {
      scriptLoadMap = [{ src, loaded: true }];
      window.scriptLoadMap = scriptLoadMap;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * Returns true if the script has been added to the page
 * @param src URL of the script
 */
function isScriptAdded(src) {
  return Boolean(document.querySelector(`script[src="${src}"]`));
}

/**
 * Returns true if the script has been fully loaded
 * @param src URL of the script
 */
function isScriptFullyLoaded(src) {
  const index = (window.scriptLoadMap || []).findIndex((script) => script && script.src === src);
  return index > -1 && window.scriptLoadMap[index] && window.scriptLoadMap[index].loaded;
}

/**
 * Load a 3rd gen script.
 * @param src URL of the script
 * @param appendOnElement Append on head / body / element
 * @param onLoadCallback Callback function when the script is fully loaded
 * @param onLoadErrorCallback Callback function when the script fails to load
 * @param retryCount How many times retry laoding the script? (Not implimented here. Logic goes into js.onerror function)
 */
export function scriptLoader(
  { src, appendOnElement, id },
  onLoadCallback = () => {},
  onLoadErrorCallback = () => {}
) {
  if (!src) return;
  // Check if the script is already loaded
  if (isScriptAdded(src)) {
    // If script already loaded successfully, trigger the callback function
    if (isScriptFullyLoaded(src)) onLoadCallback();

    // eslint-disable-next-line no-console
    console.warn('Script already loaded. Skipping: ', src);
    return;
  }

  // Loading the script...
  const js = document.createElement('script');
  js.setAttribute('async', '');
  js.src = src;
  js.async = true;
  js.id = id;

  js.onload = () => {
    markScriptFullyLoaded(src);

    // Optional callback on script load
    if (onLoadCallback) onLoadCallback();
  };

  js.onerror = () => {
    // Remove the script node (to be able to try again later)
    const js2 = document.querySelector(`script[src="${src}"]`);
    js2.parentNode.removeChild(js2);

    // Optional callback on script load failure
    if (onLoadErrorCallback) onLoadErrorCallback();
  };
  if (appendOnElement === 'head') {
    document.head.appendChild(js);
  } else if (appendOnElement === 'body') {
    document.body.appendChild(js);
  } else {
    document.getElementById(appendOnElement).appendChild(js);
  }
}

/**
 * Generates random string of UUID
 * @returns UUID
 */
export function generateUUID() {
  var d = new Date().getTime();
  var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export default scriptLoader;
