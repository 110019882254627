import React, { useEffect } from 'react';
import { useAlphaComm } from '@dom-digital-online-media/dom-alphacomm-sdk';
import { Col, Container, Row } from '@core/Layout';
import { useSearchParams } from 'react-router-dom';
import { appAlert } from '@utils/globalConstant'; //  /* appStorage */
import { useAlert } from '@context/Utils';

export function PaymentCallBack() {
  const { onPaymentCommitOrder } = useAlphaComm();
  const { showAlert } = useAlert();

  const [queryParams] = useSearchParams();

  const orderVerification = async (params) => {
    try {
      const { data = {}, success } = await onPaymentCommitOrder(params);
      if (data && success) {
        showAlert({ type: appAlert.SUCCESS, message: 'Payment Successfull..' });
      }
    } catch (error) {
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
    }
  };

  useEffect(() => {
    // const orderNumber = sessionStorage.getItem(appStorage.ORDER_NUMBER);
    // const cartName = sessionStorage.getItem(appStorage.CART_NAME);

    const queryParamsData = {
      paymentToken: queryParams.get('paymentTokenId'),
      orderNumber: queryParams.get('orderNumber'),
      cartName: queryParams.get('cartName')
    };

    if (queryParamsData.paymentToken || queryParamsData.orderNumber || queryParamsData.cartName) {
      // if (queryParams.get('paymentTokenId') && (orderNumber === queryParams.get('orderNumber') || cartName === queryParams.get('cartName'))){
      showAlert({ type: appAlert.SUCCESS, message: 'Data Verfied Successfull..' });
      // const params = {
      //   "orderNumber": orderNumber,
      //   "cartName": cartName,
      //   "paymentToken": queryParams.get('paymentTokenId')
      // };
      orderVerification(queryParamsData);
    } else {
      showAlert({ type: appAlert.ERROR, message: `Invalid Data` });
    }
    // TODO REDIRECT TO HOME PAGE
    // sessionStorage.removeItem(appStorage.CART_NAME);
    // sessionStorage.removeItem(appStorage.ORDER_NUMBER);
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h3>Payment</h3>
        </Col>
      </Row>
    </Container>
  );
}

export default PaymentCallBack;
