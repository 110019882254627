import React from 'react';
import { Col, Container, Row } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Spinner } from '@core/Utils';

export function Maintenance() {
  // Contexts
  const { t } = useStaticContent();

  return (
    <Container>
      <br />
      <br />
      <Row>
        <Col md={6} xs={12}>
          <h1 dangerouslySetInnerHTML={{ __html: t('sh_web_waiting_h1') }} />
          <b>
            <p dangerouslySetInnerHTML={{ __html: t('sh_web_waiting_subheadline') }} />
          </b>
          <Row>
            <Col xs="1 me-3">
              <Spinner />
            </Col>
            <Col xs="10">
              <p dangerouslySetInnerHTML={{ __html: t('sh_web_waiting_text') }} />
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
    </Container>
  );
}

export default Maintenance;
