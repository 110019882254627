import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import ReactInputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useSimValidation } from '@context/MobileOne/Activation/SimValidation';
// import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { Button, InputFeedback, Text } from '@core/Inputs';

import { appButtonType, appImages, appRoute } from '@utils/globalConstant';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';
import { useActivation } from '@context/MobileOne';
import { Image } from '@core/Utils';

export function ValidateSim() {
  // Constants

  // States

  // Contexts
  const { prevStep } = useActivation();
  const { validationSchema, validateForm, initialValue, isLoading, onSubmit } = useSimValidation();
  const { t } = useStaticContent();
  const navigate = useNavigate();

  // Functions
  // const initiatePOSPurchas = async () => {
  //   await getPrepaidTariffs();
  //   onPosPurchase();
  // };

  // Hooks
  useEffect(() => {
    if (window && window.utag) {
      const tagInfo = UTAG_VIEWS.POS_CUSTOMER_MOUNTING.SIM_ACTIVATION_MOUNTING;
      window.utag.view(tagInfo);
    }
  }, [window.utag]);

  return (
    <section className="">
      <div className="container">
        <div className="row position-relative">
          {/* <div className="col-12 orange-gray-bubble"></div> */}
          <div className="col-md-3 pb-lg-5 mt-lg-5 pb-0 mt-0">
            <div className="back-btn">
              {/* <a
                className="text-white body-medium-bold text-decoration-none link-light"
                onClick={() => navigate(appRoute.HOME)}
              >
                <i className="fa fa-arrow-left me-3"></i>
                {t('sh_back_button')}
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 pt-5">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-1">
            <h6
              className="text-uppercase text-white mb-4"
              dangerouslySetInnerHTML={{ __html: t('sh_apoc_step1/9') }}
            />
            <h1
              className="pink-color-text mt-2 mt-sm-2"
              dangerouslySetInnerHTML={{ __html: t('sh_apoc_kartendaten') }}
            />
            <p
              className="text-white body-large-regular mt-3 mt-sm-5"
              dangerouslySetInnerHTML={{ __html: t('sh_apoc_activation_text1') }}
            />
            <div className="col-12 col-md-5 d-md-none d-block my-3">
              {/* <img className="img-fluid" src={appImages.SIMKartennummer} alt="SIM-Kartennummer" /> */}
              <div className="text-center">
                <Image
                  className="img-fluid position-relative"
                  refs="sh_web_activationprocess_content-1"
                  alt="SIM-Kartennummer"
                  mobileStyle={{ height: '312', width: '327' }}
                />
              </div>
              <div
                className="text-white body-small-regular mt-3"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_activation_info-sim') }}
              />
            </div>
            <Formik
              initialValues={initialValue}
              validationSchema={validationSchema}
              validate={validateForm}
              onSubmit={(values) => {
                if (window && window.utag) {
                  window.utag.link(
                    UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.SIM_ACTIVATION.CONFIRM_ON_CLICK
                  );
                }
                onSubmit(values);
              }}
            >
              {({
                values: { iccid, puk },
                handleBlur,
                handleChange,
                errors,
                touched,
                handleSubmit,
                isValid
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="col-sm-12 col-lg-7 pb-5 pb-sm-0 mb-5 mb-sm-0">
                    <ReactInputMask
                      mask="9999 9999 9999 9999 9999"
                      maskChar={null}
                      onBlur={handleBlur}
                      placeholder={t('sh_apoc_activation_simnr_input')}
                      onChange={handleChange}
                      name="iccid"
                      value={iccid}
                      className={
                        !!touched.iccid && !!errors.iccid
                          ? 'form-control is-invalid mb-0'
                          : 'form-control mb-3'
                      }
                      // isInvalid={!!touched.iccid && !!errors.iccid}
                      error={touched.iccid && errors.iccid ? errors.iccid : null}
                    />
                    {touched.iccid && errors.iccid && (
                      <InputFeedback
                        id={'input-iccid-error'}
                        isInvalid
                        feedBackMessage={errors.iccid}
                      />
                    )}
                    {/* <FormControl.Feedback type="invalid" className="d-block mb-3">
                {touched.iccid && errors.iccid ? errors.iccid : null}
              </FormControl.Feedback> */}

                    <Text
                      type="number"
                      id="puk"
                      name="puk"
                      onChange={handleChange}
                      value={puk}
                      placeholder={t('sh_apoc_activation_puk_input')}
                      isValid={touched.puk && !errors.puk}
                      isInvalid={!!touched.puk && !!errors.puk}
                      onBlur={handleBlur}
                      error={touched.puk && errors.puk ? errors.puk : null}
                    />

                    <Button
                      buttonType={appButtonType.PRIMARY}
                      className="w-100 bg-offWhite-color"
                      type="submit"
                      isLoading={isLoading}
                    >
                      {t('sh_apoc_activation_confirm_button')}
                    </Button>
                    {/* <Button buttonType={appButtonType.LINK} type="button" onClick={() => getPrepaidTariffs(true)}>
                  {t('activation-activation')}
                </Button> */}
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="col-12 col-md-5 d-md-block d-none mt-1">
            {/* <img
              className="img-fluid position-relative"
              src={appImages.SIMKartennummer}
              alt="SIM-Kartennummer"
            /> */}
            <div className="text-center">
              <Image
                className="img-fluid position-relative"
                refs="sh_web_activationprocess_content-1"
                alt="SIM-Kartennummer"
                defaultStyle={{ height: '365', width: '382' }}
              />
            </div>
            <div
              className="text-white body-small-regular mt-3 mx-auto"
              style={{ width: '382px' }}
              dangerouslySetInnerHTML={{ __html: t('sh_apoc_activation_info-sim') }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ValidateSim;
