import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { useConfig } from '@config/ContextManager';
import { useActivation } from '@context/MobileOne';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { Stepper } from '@part/Stepper';
import { usePayment } from '@context/MobileOne/Activation/Payments';
import {
  appActivationFlowType,
  appActivationStep2Screens,
  appImages,
  appRoute,
  appStorage
} from '@utils/globalConstant';
import { useLayout } from '@context/Utils';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_VIEWS } from '@utils/utag';
import { SelectTariff } from './SelectTariff';
import { PersonalData } from './PersonalData';
import { LoginPassword } from './LoginPassword';
import { OverView } from './OverView';
import { Legitimation } from './Legitimation';
import { EmailVerification } from './EmailVerification';
import { EmailSend } from './EmailSend';
import { PhoneNumberPorting } from './PhoneNumberPorting';
import { ValidateSim } from './ValidateSim';
import { Charging } from './Charging';

import './style.scss';

export function Activation() {
  const location = useLocation();
  const {
    currentStep,
    activationType,
    setCurrentStep,
    getLookup,
    prevStep,
    activationInfo,
    setNumberPortingScreen
  } = useActivation();

  const { t, staticContentData } = useStaticContent();
  const { getPaymentResult } = usePayment();
  const { setActiveTariff } = useActivationTariff();
  const { setLightHeader } = useLayout();
  const { setMetaTags } = useMetaTag();
  const {
    config: { storage }
  } = useConfig();

  useEffect(() => {
    (async () => {
      const storeActivationInfo = JSON.parse(await storage.getItem(appStorage.ACTIVATION_INFO));
      const activationType = await storage.getItem(appStorage.ACTIVATION_FLOW_TYPE);
      if (
        storeActivationInfo &&
        ((activationType === appActivationFlowType.SIM_ACTIVATION &&
          location.pathname.includes(appRoute.ACTIVATION)) ||
          (activationType === appActivationFlowType.NEW_ONLINE &&
            location.pathname.includes(appRoute.NEW_ONLINE_CUSTOMER)))
      ) {
        getPaymentResult();
        const { activeTariff = {} } = storeActivationInfo || {};
        if (activeTariff) {
          setActiveTariff(activeTariff);
        }
      }
      getLookup();
      setLightHeader(true);
    })();

    return () => {
      setLightHeader(false);
      setCurrentStep(0);
      setNumberPortingScreen(appActivationStep2Screens.PHONE_MNP_NEW_SELECTION);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.SIM_ACTIVATION_MOUNTING);
    }
  }, [window.utag]);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_sim_aktiv_meta_title'),
      description: t('sh_web_page_sim_aktiv_meta_description'),

      twitterTitle: t('sh_web_page_sim_aktiv_meta_title'),
      twitterDescription: t('sh_web_page_sim_aktiv_meta_description'),

      ogTitle: t('sh_web_page_sim_aktiv_meta_title'),
      ogDescription: t('sh_web_page_sim_aktiv_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  const simActivationSteps = [
    { title: '', Component: <ValidateSim /> },
    { title: '', Component: <SelectTariff /> },
    { title: '', Component: <PhoneNumberPorting /> },
    { title: '', Component: <EmailSend /> },
    { title: '', Component: <EmailVerification /> },
    { title: '', Component: <Charging /> },
    { title: '', Component: <PersonalData /> },
    { title: '', Component: <LoginPassword /> },
    { title: '', Component: <OverView /> },
    { title: '', Component: <Legitimation /> }
  ];

  const newCustomerSteps = [
    { title: '', Component: <PhoneNumberPorting /> },
    { title: '', Component: <EmailSend /> },
    { title: '', Component: <EmailVerification /> },
    { title: '', Component: <Charging /> },
    { title: '', Component: <PersonalData /> },
    { title: '', Component: <LoginPassword /> },
    { title: '', Component: <OverView /> },
    { title: '', Component: <Legitimation /> }
  ];

  return (
    <div>
      <Stepper
        {...{
          currentStep,
          setCurrentStep,
          items:
            activationType === appActivationFlowType.SIM_ACTIVATION
              ? simActivationSteps
              : newCustomerSteps
        }}
      />
    </div>
  );
}

export * from './Charging';
export * from './SelectTariff';
export * from './Complete';
export * from './Legitimation';
export * from './LoginPassword';
export * from './OverView';
export * from './PaymentActivation';
export * from './PaymentFlow';
export * from './PersonalData';
export * from './PersonalInfo';
export * from './ValidateSim';
export * from './PhoneNumberPorting';
export * from './EmailVerification';
export * from './EmailSend';
/*  eslint-disable */
export * from './Welcome';

export default Activation;
