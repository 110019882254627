import React from 'react';
import { Col, Row } from '@core/Layout';
import { Form } from 'react-bootstrap';
import {
  appAutoTopUpPeriodType,
  appAutoTopUpType,
  appPaymentProductType
} from '@utils/globalConstant';
import { useFormikContext } from 'formik';
import { useOnlineTopUp } from '@context/AlphaComm';
// import { getAutomaticallyTopup, getUniqueTopUp } from '@dom-digital-online-media/dom-app-config-sdk';
// import PropTypes from 'prop-types'

export function Type() {
  const { allPaymentProducts, setPaymentProducts } = useOnlineTopUp();

  const { values, errors, touched, handleBlur, handleChange } = useFormikContext();

  const onChangeProductType = (e) => {
    if (allPaymentProducts.length > 0 && e.target.value === appAutoTopUpType.UNIQUE) {
      setPaymentProducts(
        allPaymentProducts.filter(
          ({ productType }) =>
            productType === appPaymentProductType.DIRECT ||
            productType === appPaymentProductType.PREPAID
        )
      );
      handleChange('type')(e);
      handleChange('productId')({ target: { value: '' } });
      handleChange('productAmount')({ target: { value: '' } });
    }

    if (allPaymentProducts.length > 0 && e.target.value === appAutoTopUpType.AUTOMATIC) {
      setPaymentProducts(
        allPaymentProducts.filter(
          ({ productType }) => productType === appPaymentProductType.LOW_BALANCE
        )
      );
      handleChange('type')(e);
      handleChange('periodType')({ target: { value: appAutoTopUpPeriodType.LOW_BALANCE } });
      handleChange('productId')({ target: { value: '' } });
      handleChange('productAmount')({ target: { value: '' } });
    }
  };
  return (
    <>
      <div className="py-3">
        <hr />
        <h2>Type</h2>
        <hr />
        <h5>Different type of charging</h5>
        <Row>
          <Col xs={12}>
            <span>Charge directly or set up automatic charging</span>
          </Col>
          <Col xs={12} lg={6} xxl={6} className="py-4">
            <Form.Check
              label={<strong>Unique</strong>}
              feedback={
                touched.type && !!errors.type
                  ? errors.type
                  : 'Top up online instantly. For yourself or for others'
              }
              feedbackType={touched.type && !!errors.type ? 'invalid' : 'help'}
              name="type"
              type="radio"
              className="m-0"
              onChange={onChangeProductType}
              onBlur={handleBlur}
              value={appAutoTopUpType.UNIQUE}
              checked={values.type === appAutoTopUpType.UNIQUE}
              isInvalid={touched.type && !!errors.type}
              id={appAutoTopUpType.UNIQUE}
            />
            {/* {(touched.type && errors.type) ? (
              <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
            ) : (
              <Form.Control.Feedback type="help">
                Top up online instantly. For yourself or for others
              </Form.Control.Feedback>
            )} */}
            {/* <Form.Check type="radio">
              <Form.Check.Input type="radio"
                id={appAutoTopUpType.UNIQUE}
                name={appAutoTopUpType.UNIQUE}
                value={appAutoTopUpType.UNIQUE}
                checked={values.type === appAutoTopUpType.UNIQUE}
                onChange={(e) => handleChange('type', e.target.value)}
                onBlur={handleBlur} />
              <Form.Check.Label>
                <strong>Unique</strong>
              </Form.Check.Label>
              <Form.Control.Feedback type="help">
                Top up online instantly. For yourself or for others
              </Form.Control.Feedback>
              {touched.type && errors.type && (<Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>)}
            </Form.Check> */}
          </Col>
          <Col xs={12} lg={6} xxl={6} className="py-4">
            <Form.Check
              label={<strong>Automatic</strong>}
              feedback={
                touched.type && !!errors.type
                  ? errors.type
                  : 'Top up online instantly. For yourself or for others'
              }
              feedbackType={touched.type && !!errors.type ? 'invalid' : 'help'}
              name="type"
              type="radio"
              className="m-0 bold"
              onChange={onChangeProductType}
              onBlur={handleBlur}
              value={appAutoTopUpType.AUTOMATIC}
              checked={values.type === appAutoTopUpType.AUTOMATIC}
              isInvalid={touched.type && !!errors.type}
              id={appAutoTopUpType.AUTOMATIC}
            />
            {/* {(touched.type && errors.type) ? (
              <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
            ) : (
              <Form.Control.Feedback type="help">
                Top up online instantly. For yourself or for others
              </Form.Control.Feedback>
            )} */}
            {/* {touched.type && errors.type && (<Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>)} */}
            {/* <Form.Check type="radio">
              <Form.Check.Input type="radio"
                id={appAutoTopUpType.AUTOMATIC}
                name={appAutoTopUpType.AUTOMATIC}
                value={appAutoTopUpType.AUTOMATIC}
                checked={values.type === appAutoTopUpType.AUTOMATIC}
                onChange={(e) => handleChange('type', e.target.value)}
                onBlur={handleBlur} />
              <Form.Check.Label>
                <strong>Automatically</strong>
              </Form.Check.Label>
              <Form.Control.Feedback type="help">
                Top up when your balance is low or on a specific day
              </Form.Control.Feedback>
              {touched.type && errors.type && (
                <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
              )}
            </Form.Check> */}
          </Col>
        </Row>
      </div>
    </>
  );
}

Type.propTypes = {
  // values: PropTypes.shape({ type: PropTypes.string }).isRequired,
  // errors: PropTypes.shape({ type: PropTypes.string }).isRequired,
  // touched: PropTypes.shape({ type: PropTypes.bool }).isRequired,
  // handleBlur: PropTypes.func.isRequired,
  // handleChange: PropTypes.func.isRequired
};

export default Type;
