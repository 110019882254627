/* eslint-disable no-console */
import { createContext, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// A context to load all app configuration from server
const MetaTagContext = createContext();

// The top level component that will wrap our app's core features
export const MetaTagProvider = function ({ children }) {
  // State
  const [metaTags, setMetaTags] = useState({
    title: '',
    description: '',
    twitterTitle: '',
    twitterDescription: '',
    ogType: 'website',
    ogTitle: '',
    ogDescription: ''
  });

  // Render the children as normal
  const renderContent = () => (
    <>
      <Helmet preload>
        <meta charSet="utf-8" />
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />

        <meta name="twitter:title" content={metaTags.twitterTitle} />
        <meta name="twitter:description" content={metaTags.twitterDescription} />

        <meta property="og:type" content={metaTags.ogType} />
        <meta property="og:title" content={metaTags.ogTitle} />
        <meta property="og:description" content={metaTags.ogDescription} />
        {/* <script>
          {
            `window.utag_cfg_ovrd = "window.utag_cfg_ovrd || {};"
            window.utag_cfg_ovrd.noview = "true";`
          }
        </script> */}
      </Helmet>
      {children}
    </>
  );

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(() => ({ metaTags, setMetaTags }), [metaTags, setMetaTags]);

  // Sample constructed header metadata
  // <title>  sh_web_page_<page/disposal>_meta_title  </title>
  // <meta name="description" content="  sh_web_page_<page/disposal>_meta_description  ">

  // <meta name="twitter:title" content="  sh_web_page_<page/disposal>_meta_title  ">
  // <meta name="twitter:description" content="  sh_web_page_<page/disposal>_meta_description  ">

  // <meta property="og:type" content="website">
  // <meta property="og:title" content="  sh_web_page_<page/disposal>_meta_title  ">
  // <meta property="og:description" content="  sh_web_page_<page/disposal>_meta_description  ">

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <MetaTagContext.Provider value={contextPayload}>{renderContent()}</MetaTagContext.Provider>
  );
};

MetaTagProvider.propTypes = {
  children: PropTypes.node
};

MetaTagProvider.defaultProps = {
  children: null
};

export default MetaTagProvider;

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useMetaTag = () => useContext(MetaTagContext);
