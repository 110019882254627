import React from 'react';

export function Spinner() {
  return (
    <div className="spinner-border" role="status" style={{ color: '#503a64' }}>
      <span className="visually-hidden">Loading...</span>
    </div>
  );
}

export default Spinner;
