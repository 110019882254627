import React, { useEffect, useState } from 'react';
import {
  appSecBgColorTypes,
  appSecBottomElementTypes,
  appSecTopElementTypes
} from '@utils/globalConstant';

import PropTypes from 'prop-types';
import './style.scss';
import { useLayout } from '@context/Utils';

export function Section({ children, topElementType, bottomElementType, bgColorCode, ...props }) {
  // Context states
  const { screenSize } = useLayout();

  // Case for topElement
  const [topElement, setTopElement] = useState(false);
  useEffect(() => {
    let imgPath = false;
    switch (topElementType) {
      case appSecTopElementTypes.TYPE_1:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Top_Type_1.svg'
            : '/image/curve-shapes/Top_Type_1.svg';
        break;
      case appSecTopElementTypes.TYPE_2:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Top_Type_2.svg'
            : '/image/curve-shapes/Top_Type_2.svg';
        break;
      case appSecTopElementTypes.TYPE_3:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Top_Type_3.svg'
            : '/image/curve-shapes/Top_Type_3.svg';
        break;
      case appSecTopElementTypes.TYPE_4:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Top_Type_4.svg'
            : '/image/curve-shapes/Top_Type_4.svg';
        break;
      case appSecTopElementTypes.TYPE_5:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Bottom_Type_1.svg'
            : '/image/curve-shapes/Bottom_Type_1.svg';
        break;
      case appSecTopElementTypes.TYPE_6:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Bottom_Type_2.svg'
            : '/image/curve-shapes/Bottom_Type_2.svg';
        break;
      case appSecTopElementTypes.TYPE_7:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Bottom_Type_3.svg'
            : '/image/curve-shapes/Bottom_Type_3.svg';
        break;
      case appSecTopElementTypes.TYPE_8:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Bottom_Type_4.svg'
            : '/image/curve-shapes/Bottom_Type_4.svg';
        break;
      case appSecTopElementTypes.TYPE_9:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Bottom_Type_5.svg'
            : '/image/curve-shapes/Bottom_Type_5.svg';
        break;
      default:
        imgPath = false;
        break;
    }
    setTopElement(imgPath);
    return () => {
      setTopElement(false);
    };
  }, [topElementType, screenSize]);

  // Case for bottomElement
  const [bottomElement, setBottomElement] = useState(false);
  useEffect(() => {
    let imgPath = '';
    switch (bottomElementType) {
      case appSecBottomElementTypes.TYPE_1:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Top_Type_1.svg'
            : '/image/curve-shapes/Top_Type_1.svg';
        break;
      case appSecBottomElementTypes.TYPE_2:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Top_Type_2.svg'
            : '/image/curve-shapes/Top_Type_2.svg';
        break;
      case appSecBottomElementTypes.TYPE_3:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Top_Type_3.svg'
            : '/image/curve-shapes/Top_Type_3.svg';
        break;
      case appSecBottomElementTypes.TYPE_4:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Bottom_Type_4.svg'
            : '/image/curve-shapes/Bottom_Type_4.svg';
        break;
      case appSecBottomElementTypes.TYPE_5:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Bottom_Type_1.svg'
            : '/image/curve-shapes/Bottom_Type_1.svg';
        break;
      case appSecBottomElementTypes.TYPE_6:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Bottom_Type_2.svg'
            : '/image/curve-shapes/Bottom_Type_2.svg';
        break;
      case appSecBottomElementTypes.TYPE_7:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Bottom_Type_3.svg'
            : '/image/curve-shapes/Bottom_Type_3.svg';
        break;
      case appSecBottomElementTypes.TYPE_8:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Bottom_Type_4.svg'
            : '/image/curve-shapes/Bottom_Type_4.svg';
        break;
      case appSecBottomElementTypes.TYPE_9:
        imgPath =
          screenSize < 768
            ? '/image/curve-shapes/M_Bottom_Type_5.svg'
            : '/image/curve-shapes/Bottom_Type_5.svg';
        break;
      default:
        imgPath = false;
        break;
    }
    setBottomElement(imgPath);
    return () => {
      setBottomElement(false);
    };
  }, [bottomElementType, screenSize]);

  // Case for bgColor
  const [bgColor, setBgColor] = useState(appSecBgColorTypes.default);
  useEffect(() => {
    let color = '';
    switch (bgColorCode) {
      case appSecBgColorTypes.PRIMARY:
        color = '#534164';
        break;
      case appSecBgColorTypes.SECONDARY:
        color = '#534164';
        break;
      case appSecBgColorTypes.WHITE:
        color = '#FFFFFF';
        break;
      case appSecBgColorTypes.OFFWHITE:
        color = '#EFECE8';
        break;
      default:
        color = appSecBgColorTypes.default;
        break;
    }
    setBgColor(color);
    return () => {
      setBgColor(appSecBgColorTypes.default);
    };
  }, [bgColorCode]);

  return (
    <section
      {...props}
      style={{
        backgroundColor: bgColor
      }}
    >
      {topElement && <img className="swoosh-element top" src={topElement} alt="Top Element" />}
      {children}
      {bottomElement && (
        <img className="swoosh-element bottom" src={bottomElement} alt="Bottom Element" />
      )}
    </section>
  );
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  topElementType: PropTypes.oneOf(Object.values(appSecTopElementTypes)),
  bottomElementType: PropTypes.oneOf(Object.values(appSecBottomElementTypes)),
  bgColorCode: PropTypes.oneOf(Object.values(appSecBgColorTypes))
};

Section.defaultProps = {
  topElementType: false,
  bottomElementType: false,
  bgColorCode: ''
};

export default Section;
