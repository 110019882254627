import React from 'react';
import PropTypes from 'prop-types';
import { appImages } from '@utils/globalConstant';

export function Radio({
  type,
  label,
  promotionText,
  promotionalAction,
  showPromotionStar,
  description,
  linkTitle,
  linkUrl,
  badgeTitle,
  badgeAction,
  id,
  name,
  value,
  onChange,
  onBlur,
  isInvalid,
  isDisabled,
  isDefaultChecked,
  labelClass,
  ...rest
}) {
  const primaryRadioButton = (
    <div className="form-check radio-check">
      <input
        className="form-check-input"
        type="radio"
        checked={isDefaultChecked}
        {...{
          name,
          id,
          value,
          onChange,
          onBlur,
          disabled: isDisabled,
          rest
        }}
      />
      <label className="form-check-label text-white ms-1 mb-1" htmlFor={id}>
        <p className={`body-large-bold mb-0 ${labelClass}`}>{label}</p>
        {promotionText.length > 0 && (
          <div className="d-flex radio-footnote">
            <p
              className="my-1 body-medium-regular pink-color-text mb-2 promotional-text"
              // onClick={promotionalAction}
              dangerouslySetInnerHTML={{ __html: promotionText }}
            />
            {showPromotionStar && (
              <div
                className="ps-1"
                role="button"
                onClick={promotionalAction}
                // onClick={() => {
                //   setModal(appActivationStep1Modals.NEW_TARIFF_MODAL);
                // }}
              >
                <img className="img-fluid" src={appImages.footnote} alt="foot note icon" />
              </div>
            )}
          </div>
        )}
        {description.length > 0 && (
          <div
            className="body-medium-regular font-16 radio-description offWhite-color-text mb-2"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {linkTitle.length > 0 && (
          <a className="text-white label-medium" href={linkUrl}>
            {linkTitle}
          </a>
        )}
      </label>
    </div>
  );

  const secondaryRadioButton = (
    <div className="form-check radio-check dark-radio">
      <input
        className="form-check-input"
        type="radio"
        checked={isDefaultChecked}
        {...{
          name,
          id,
          value,
          onChange,
          onBlur,
          disabled: isDisabled
        }}
      />
      <label className="form-check-label text-white ms-1" htmlFor="flexRadioDefault9">
        <p className={`body-large-bold mb-0 ${labelClass}`}>
          {label}{' '}
          <span className="badge text-bg-dark" onClick={badgeAction}>
            {badgeTitle}
          </span>
        </p>
        <p className="my-1 body-medium-regular pink-color-text mb-2" onClick={promotionalAction}>
          {promotionText}
        </p>
        <p className="body-medium-regular font-16 offWhite-color-text mb-2">{description}</p>
        <a className="text-white label-medium" href={linkUrl}>
          {linkTitle}
        </a>
      </label>
    </div>
  );

  if (type === 'primary') return primaryRadioButton;
  if (type === 'secondary') return secondaryRadioButton;
}

Radio.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  promotionText: PropTypes.string,
  showPromotionStar: PropTypes.bool,
  labelClass: PropTypes.string,
  promotionalAction: PropTypes.func,
  description: PropTypes.string,
  linkTitle: PropTypes.string,
  linkUrl: PropTypes.string,
  badgeTitle: PropTypes.string,
  badgeAction: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDefaultChecked: PropTypes.bool
};

Radio.defaultProps = {
  type: 'primary',
  label: '',
  labelClass: '',
  promotionText: '',
  showPromotionStar: false,
  promotionalAction: () => {},
  description: '',
  linkTitle: '',
  linkUrl: '',
  badgeTitle: '',
  badgeAction: () => {},
  id: '',
  name: '',
  onChange: () => {},
  onBlur: () => {},
  isInvalid: null,
  isDisabled: null,
  isDefaultChecked: null
};

export default Radio;
