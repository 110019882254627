import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { formValidation } from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useMpsPayment } from '@dom-digital-online-media/dom-mps-sdk';

import { useConfig } from '@config/ContextManager';
import { useActivationTariff } from '../ActivationTariff';
import { useActivation } from '..';
import { appActivationFlowType, appPaymentStaticData, appStorage } from '@utils/globalConstant';

export const ActivationAutoTopUpContext = createContext({});

export function ActivationAutoTopUpContextProvider({
  children,
  onFormUpdate,
  nextStep,
  activationInfo,
  env
}) {
  // Constant

  const { currentStep, setCurrentStep, editStep, setEditStep } = useActivation();
  const { t } = useStaticContent();
  const {
    config: { storage }
  } = useConfig();

  const initialValue = {
    automaticCharging: activationInfo.automaticCharging || '',
    paymentMethod: activationInfo.paymentMethod || ''
  };

  // Validations
  const validationSchema = Yup.lazy(({ automaticCharging }) => {
    console.log(automaticCharging);
    if (automaticCharging === 'true') {
      return Yup.object().shape({
        automaticCharging: formValidation({ required: 'please_select' }),
        paymentMethod: formValidation({ required: t('sh_ap_top-up_err_msg') })
      });
    }
    return Yup.object().shape({
      automaticCharging: formValidation({ required: 'please_select' })
    });
  });

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(initialValue);

  // Context
  const { onPaymentResgister } = useMpsPayment();
  const { activeTariff, bookableTariff } = useActivationTariff();
  // Functions
  const onLoad = async () => {};

  const onSubmit = async (values) => {
    setIsLoading(true);
    setFormValue(values);
    onFormUpdate({
      ...values,
      acceptedCreditCheck: false,
      termsAndConditions: false,
      brandPartnerCustomMarketing: true
    });
    if (values.automaticCharging === 'false') {
      if (editStep === currentStep) {
        setCurrentStep(7);
        setEditStep(-1);
      } else {
        nextStep();
      }
    } else if (values.automaticCharging === 'true') {
      const { data } = await onPaymentResgister({
        businessPartnerConfigId: env.REACT_APP_PAYMENT_BUSINESS_PARTNER_ID,
        returnUrl: `${env.REACT_APP_SHOPPING_RETURN_URL}aktivieren/kartendaten/`,
        description: appPaymentStaticData.description,
        currency: 'EUR',
        locale: 'de_DE',
        uiDetails: {
          binding_legal_terms: '*** Binding legal terms ***'
        },
        themeName: 'default',
        paymentMethod: values.paymentMethod
      });
      /* eslint-disable */
      activationInfo.isPromoApplied = activeTariff.promoCode ? true : false;
      await storage.setItem(
        appStorage.ACTIVATION_INFO,
        JSON.stringify({
          ...activationInfo,
          ...values,
          checkoutSessionId: data.checkoutSessionId,
          bookableTariff
        })
      );
      await storage.setItem(appStorage.ACTIVATION_FLOW_TYPE, appActivationFlowType.SIM_ACTIVATION);
      window.location = `${data.redirectUrl}`;
    }
    setIsLoading(false);
  };

  // Hooks
  // Default Hook // No use
  useEffect(() => {}, []);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // Constants
      initialValue,
      validationSchema,

      // States

      isLoading,
      setIsLoading,
      formValue,
      setFormValue,

      // Functions
      onLoad,
      onSubmit
    }),
    [
      // Constants
      initialValue,
      validationSchema,

      // States

      isLoading,
      setIsLoading,
      formValue,
      setFormValue,

      // Functions
      onLoad,
      onSubmit
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <ActivationAutoTopUpContext.Provider value={contextPayload}>
      {children}
    </ActivationAutoTopUpContext.Provider>
  );
}
ActivationAutoTopUpContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useActivationAutoTopUp = () => useContext(ActivationAutoTopUpContext);

export default ActivationAutoTopUpContext;
