import React, { useEffect } from 'react';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { useActivation } from '@context/MobileOne';
import { appActivationFlowType, appTariff } from '@utils/globalConstant';
import { UTAG_LINK, utag } from '@utils/utag';

export function TariffDetailsModal({ setModal }) {
  // Context
  const { t } = useStaticContent();
  const { activeTariff } = useActivationTariff();
  const { activationType } = useActivation();

  // useEffect(() => {
  //   if (window && window.utag) {
  //     activeTariff.id === appTariff.FLAT_M
  //       ? window.utag.link(
  //           UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M.TARIFF_DETAILS_ON_CLICK
  //         )
  //       : window.utag.link(
  //           UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L.TARIFF_DETAILS_ON_CLICK
  //         );
  //   }
  // }, [window.utag]);

  return (
    <Modal
      showCloseButton
      customBodyClass="tariff-detail"
      closeButtonText={'sh_apoc_tarif_overview_tarif-details_cancel'}
      onCloseClick={() => {
        setModal('');
      }}
      stepHeader={
        <div
          className="text-bold"
          dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif-details') }}
        />
      }
      header={activeTariff.name}
    >
      <div className="new-customer-activation">
        <div className="mt-4">
          <h5
            className="py-3 primary-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_h1') }}
          />

          <div className="py-2">
            <div
              className="m-0 body-large-bold grey-color-text text-uppercase"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_sub1') }}
            />

            <div
              className="m-0 body-large-regular grey-color-text"
              dangerouslySetInnerHTML={{
                __html: t(
                  activeTariff.id == appTariff.FLAT_M
                    ? 'sh_new_customer_tarif_details_textM'
                    : 'sh_new_customer_tarif_details_textL'
                )
              }}
            />
          </div>
          <div className="py-2">
            <div
              className="m-0 body-large-bold grey-color-text text-uppercase"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_sub2') }}
            />
            <div
              className="m-0 body-large-regular grey-color-text"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_text2') }}
            />
          </div>

          <div className="py-2">
            <div
              className="m-0 body-large-bold grey-color-text text-uppercase"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_sub3') }}
            />
            <div
              className="m-0 body-large-regular grey-color-text"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_text3') }}
            />
          </div>
        </div>

        <div className="">
          <h5
            className="py-3 m-0 primary-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_h2') }}
          />
          <div className="py-2">
            <div
              className="m-0 body-large-bold grey-color-text text-uppercase"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_sub4') }}
            />
            <div
              className="m-0 body-large-regular grey-color-text"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_text4') }}
            />
          </div>
        </div>

        <div className="">
          <h5
            className="py-3 m-0 primary-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_h3') }}
          />
          <div className="py-2">
            <div
              className="m-0 body-large-bold grey-color-text text-uppercase"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_sub5') }}
            />
            <div
              className="m-0 body-large-regular grey-color-text"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_text5') }}
            />
          </div>
        </div>

        <div className="">
          <h5
            className="py-3 m-0 primary-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_h4') }}
          />
          <div className="py-2">
            <div
              className="m-0 body-large-bold grey-color-text text-uppercase"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_sub6') }}
            />
            <div
              className="m-0 body-large-regular grey-color-text"
              dangerouslySetInnerHTML={{
                __html: t(
                  activeTariff.id === appTariff.FLAT_M
                    ? 'sh_new_customer_tarif_details_text6m'
                    : 'sh_new_customer_tarif_details_text6L'
                )
              }}
            />
          </div>
        </div>

        <div className="">
          <h5
            className="py-3 m-0 primary-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_h5') }}
          />
          <div className="py-2">
            <div
              className="m-0 body-large-regular grey-color-text"
              dangerouslySetInnerHTML={{
                __html: t('sh_new_customer_tarif_details_text7')
              }}
            />
          </div>
        </div>

        <div className="">
          <h5
            className="py-3 m-0 primary-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_h6') }}
          />
          <div className="py-2">
            <div
              className="m-0 body-large-bold grey-color-text text-uppercase"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_sub8') }}
            />
            <div
              className="m-0 body-large-regular grey-color-text"
              dangerouslySetInnerHTML={{
                __html: t('sh_new_customer_tarif_details_text8')
              }}
            />
          </div>
          <div className="py-2">
            <div
              className="m-0 body-large-bold grey-color-text text-uppercase"
              dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_sub9') }}
            />
            <div
              className="m-0 body-large-regular grey-color-text"
              dangerouslySetInnerHTML={{
                __html: t('sh_new_customer_tarif_details_text9')
              }}
            />
          </div>
          <div className="py-2">
            <div
              className="m-0 body-large-bold grey-color-text text-uppercase"
              dangerouslySetInnerHTML={{
                __html: t('sh_new_customer_tarif_details_sub10')
              }}
            />
            <div
              className="m-0 body-large-regular grey-color-text"
              dangerouslySetInnerHTML={{
                __html: t('sh_new_customer_tarif_details_text10')
              }}
            />
          </div>
          <div className="py-2">
            <div
              className="m-0 body-large-bold grey-color-text text-uppercase"
              dangerouslySetInnerHTML={{
                __html: t('sh_new_customer_tarif_details_sub11')
              }}
            />
            <div
              className="m-0 body-large-regular grey-color-text"
              dangerouslySetInnerHTML={{
                __html: t('sh_new_customer_tarif_details_text11')
              }}
            />
          </div>
        </div>

        <div className="">
          <h5
            className="py-3 m-0 primary-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_h7') }}
          />
          <div
            className="py-2 m-0 body-large-bold grey-color-text text-uppercase"
            dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_sub12') }}
          />
          <div
            className="m-0 body-large-regular grey-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_text12') }}
          />
          <div
            className="pt-2 m-0 body-large-bold grey-color-text text-uppercase"
            dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_sub13') }}
          />
          <div
            className="body-large-regular grey-color-text"
            dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_text13') }}
          />
        </div>

        <div className="bg-offWhite-color col-12 py-4 m-0">
          <div className="card border-0 box-radius">
            <div className="card-body py-3">
              <h5
                className="card-title grey-color-text heading-small"
                dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_h8') }}
              />
              <div
                className="my-4 card-text body-medium-regular grey-color-text w-75"
                dangerouslySetInnerHTML={{
                  __html: t('sh_new_customer_tarif_details_text14')
                }}
              />
              <div
                className="card-text body-medium-regular pink-color-text w-75"
                dangerouslySetInnerHTML={{
                  __html: t('sh_new_customer_tarif_details_text15')
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="body-large-regular grey-color-text p-0">
            {/* <div
                className="body-large-bold grey-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_new_customer_tarif_details_M_headline') }}
              /> */}
            <div
              className="my-4"
              dangerouslySetInnerHTML={{
                __html: t(
                  activeTariff.id === appTariff.FLAT_M
                    ? 'sh_Footnote1_Tarife_M'
                    : 'sh_Footnote2_Tarife_L'
                )
              }}
            />
            <div
              className="body-large-bold grey-color-text"
              dangerouslySetInnerHTML={{
                __html: t('sh_new_customer_tarif_details_starterpaket_h')
              }}
            />
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: t('sh_apoc_tarif_overview_tarif-details_starterpaket_text')
              }}
            />
            <div
              className="my-4"
              dangerouslySetInnerHTML={{
                __html: t('sh_new_customer_tarif_details_1GB_datapass')
              }}
            />
            <div
              className="my-4"
              dangerouslySetInnerHTML={{
                __html: t('sh_new_customer_tarif_details_2GB_datapass')
              }}
            />
            <div
              className="my-4"
              dangerouslySetInnerHTML={{
                __html: t('sh_new_customer_tarif_details_4GB_datapass')
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TariffDetailsModal;
