/* eslint-disable no-console */
import { createContext, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import PropTypes, { func } from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAppConfig } from '@dom-digital-online-media/dom-app-config-sdk';

import { scriptLoader } from '@utils/scriptLoader';
import { appAlert, appDynamicScripts } from '@utils/globalConstant';
import { SplashScreen } from '@core/Utils';
import { useConfig } from '@config/ContextManager';

// A context to load all app configuration from server
const LayoutContext = createContext();

// The top level component that will wrap our app's core features
export const LayoutProvider = function ({ children }) {
  // Const
  const LIGHT_HEADER_SCROLL_SWITCH = 85; // 85px

  // Context
  const { onStaticContentCall, onStaticKeyValuePairCall } = useStaticContent();
  const {
    config: { storage, env: serverConfig }
  } = useConfig();
  const { env } = useAppConfig();

  // States
  const [appWebView, setAppWebView] = useState(false);
  const [secondaryHeader, setSecondaryHeader] = useState(false);
  const [hoverHeader, setHoverHeader] = useState(false);
  const [hoverMenu, setHoverMenu] = useState(-1);
  const [showLightHeader, setLightHeader] = useState(false);
  const [showScrollDarkHeader, setScrollDarkHeader] = useState(false);
  const [y, setY] = useState(window.scrollY);
  const [isLoading, setIsLoading] = useState(true);
  const [isTealuimLoaded, setIsTealuimLoaded] = useState(false);
  const [splashOut, setSplashOut] = useState(false);
  const [screenSize, setScreenSize] = useState(
    window && window.innerWidth ? window.innerWidth : 1080
  );
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  // Functions
  const onBlur = () => {
    setHoverHeader(false);
    setHoverMenu(-1);
  };
  const toggleHover = () => setHoverHeader(!hoverHeader);

  const onBlurMenu = () => setHoverMenu(-1);
  const toggleHoverMenu = (id) => setHoverMenu(id);

  const handleScrollNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        // Switch to light mode
        if (window.scrollY < LIGHT_HEADER_SCROLL_SWITCH) {
          setScrollDarkHeader(false);
        }
      } else if (y < window.scrollY) {
        // Switch header color to dark if scrolling is greater then 85px
        if (window.scrollY > LIGHT_HEADER_SCROLL_SWITCH) {
          setScrollDarkHeader(true);
        }
      }
      setY(window.scrollY);
    },
    [y]
  );

  const getStaticContent = async () => {
    try {
      if (env.REACT_APP_BACKEND_URL) {
        setIsLoading(true);
        await onStaticKeyValuePairCall(
          JSON.stringify({ platform: 'web', lang: 'de', client: env.REACT_APP_APPID_CLIENTID })
        );
        await onStaticContentCall({
          platform: 'web',
          client: env.REACT_APP_APPID_CLIENTID,
          lang: 'de'
        });
        setTimeout(() => {
          setSplashOut(true);
        }, 1500);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      setIsLoading(false);
      setSplashOut(true);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScrollNavigation);

    return () => {
      window.removeEventListener('scroll', handleScrollNavigation);
    };
  }, [handleScrollNavigation]);

  // useEffect(() => {
  //   setScreenSize(window.innerWidth);
  // }, [window.innerWidth]);

  useEffect(() => {
    // setY(window.scrollY);
    getStaticContent();

    if (window) {
      window.addEventListener('resize', function () {
        setScreenSize(window.innerWidth);
      });
    }
  }, []);

  useEffect(() => {
    if (!isLoading && (env.REACT_APP_TEALIUM_ACCOUNT || serverConfig.REACT_APP_TEALIUM_ACCOUNT)) {
      // Initiate tealium
      scriptLoader(
        {
          src: appDynamicScripts.TEALIUM_UTAG(
            env.REACT_APP_TEALIUM_DOMAIN || serverConfig.REACT_APP_TEALIUM_DOMAIN,
            env.REACT_APP_TEALIUM_ACCOUNT || serverConfig.REACT_APP_TEALIUM_ACCOUNT,
            env.REACT_APP_TEALIUM_PROFILE || serverConfig.REACT_APP_TEALIUM_PROFILE,
            env.REACT_APP_TEALIUM_ENVIRONMENT || serverConfig.REACT_APP_TEALIUM_ENVIRONMENT
          ),
          appendOnElement: 'tealiumScript',
          id: 'tealiumScript'
        },
        () => {
          // window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
          // window.utag_cfg_ovrd.noview = "true";
          setIsTealuimLoaded(true);
        }
      );
    }
  }, [isLoading, env, serverConfig]);

  // useEffect(() => {
  //   console.log(y);
  // }, [y]);

  // useEffect(() => {
  //   window.addEventListener('scroll', (e) => handleScrollNavigation(e));

  //   return () => {
  //     // return a cleanup function to unregister our function since it will run multiple times
  //     window.removeEventListener('scroll', (e) => handleScrollNavigation(e));
  //   };
  // }, [y]);

  // Render the children as normal
  // eslint-disable-next-line react/react-in-jsx-scope
  // const renderContent = isLoading ? (<SplashScreen />) : ({children});

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      appWebView,
      setAppWebView,
      secondaryHeader,
      setSecondaryHeader,
      hoverHeader,
      setHoverHeader,
      showLightHeader,
      setLightHeader,
      hoverMenu,
      setHoverMenu,
      showScrollDarkHeader,
      setScrollDarkHeader,
      screenSize,
      showMobileMenu,
      setShowMobileMenu,
      isTealuimLoaded,
      setIsTealuimLoaded,

      // Functions
      onBlur,
      toggleHover,
      onBlurMenu,
      toggleHoverMenu
    }),
    [
      appWebView,
      setAppWebView,
      secondaryHeader,
      setSecondaryHeader,
      hoverHeader,
      setHoverHeader,
      showLightHeader,
      setLightHeader,
      hoverMenu,
      setHoverMenu,
      showScrollDarkHeader,
      setScrollDarkHeader,
      screenSize,
      showMobileMenu,
      setShowMobileMenu,
      isTealuimLoaded,
      setIsTealuimLoaded,

      // Functions
      onBlur,
      toggleHover,
      onBlurMenu,
      toggleHoverMenu
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <LayoutContext.Provider value={contextPayload}>
      {isLoading && <SplashScreen splashOut={splashOut} />}
      <div className={isLoading ? 'd-none' : 'animate__animated animate__fadeIn animate__faster'}>
        {children}
      </div>
    </LayoutContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.node
};

LayoutProvider.defaultProps = {
  children: null
};

export default LayoutProvider;

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useLayout = () => useContext(LayoutContext);
