import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import './style.scss';

import Accordion from '@core/Layout/Accordion';
import { appImages, appSortOrder, deviceRegex } from '@utils/globalConstant';
import { useMetaTag } from '@context/Utils/MetaTag';
import { useLayout } from '@context/Utils';

export function FaqTopic() {
  const [queryParams] = useSearchParams();
  const categoryId = queryParams.get('categoryId');

  const [faqList, setFaqList] = useState([]);
  const [isIosDevice, setIsIosDevice] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);
  const { setLightHeader } = useLayout();
  const { faqStaticData, t, staticContentData } = useStaticContent();
  const { setMetaTags } = useMetaTag();

  useEffect(() => {
    setLightHeader(false);
    let deviceInfo = navigator.userAgent;
    setIsIosDevice(deviceRegex.ISIOS.test(deviceInfo));
    setIsAndroidDevice(deviceRegex.ISANDRIOD.test(deviceInfo));

    return () => {
      setLightHeader(true);
      setIsIosDevice(false);
      setIsAndroidDevice(false);
    };
  }, []);

  useEffect(() => {
    if (faqStaticData) {
      const findUniqueCategory = faqStaticData
        .map((item) => item.categoryID)
        .filter((value, index, self) => self.indexOf(value) === index);

      const groupFaqList = findUniqueCategory.map((_categoryID) => ({
        categoryID: _categoryID,
        category: faqStaticData.find(({ categoryID }) => _categoryID === categoryID).category,
        faqList: faqStaticData.filter(({ categoryID }) => _categoryID === categoryID)
      }));

      const sortCatList = staticContentData?.sh_faqSortList.find(
        (list) => list.listName === appSortOrder.FAQ_MAIN_CAT_LIST
      );

      if (sortCatList && sortCatList.listOrder) {
        const sortedList = sortCatList.listOrder.map((item) =>
          groupFaqList.find((faq) => `${faq.categoryID}` === `${item.id}`)
        );
        setFaqList(sortedList);
      }
    }
    return () => {
      setFaqList([]);
    };
  }, [faqStaticData]);

  useEffect(() => {
    // alert(categoryId);
    setTimeout(() => {
      const element = document.getElementById(categoryId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1500);
  }, [document.getElementById(categoryId)]);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_faqus_hilfe_thema_meta_title'),
      description: t('sh_web_page_faqus_hilfe_thema_meta_description'),

      twitterTitle: t('sh_web_page_faqus_hilfe_thema_meta_title'),
      twitterDescription: t('sh_web_page_faqus_hilfe_thema_meta_description'),

      ogTitle: t('sh_web_page_faqus_hilfe_thema_meta_title'),
      ogDescription: t('sh_web_page_faqus_hilfe_thema_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  const topItemList = (faqItem) =>
    faqItem.faqList
      .filter((faqItem, index) => faqItem && index < 5)
      .map((item) => ({
        title: item.question,
        // component: <div dangerouslySetInnerHTML={{ __html: item.answer }}></div>,
        component: `${item.answer}`,
        internalID: item.internalID,
        categoryID: item.categoryID
      }));

  const faqItemList = (faqItem) =>
    faqItem.faqList.map((item) => ({
      title: item.question,
      // component: <div dangerouslySetInnerHTML={{ __html: item.answer }}></div>,
      component: `${item.answer}`,
      internalID: item.internalID,
      categoryID: item.categoryID
    }));

  return (
    <>
      <section className="frequently-asked-section uebersicht-section pt-10 pt-md-0 pt-md-0 position-relative">
        <div className="container mb-lg-5 pt-10 pt-5 my-4 mb-md-0 mt-0 mt-lg-5">
          <div className="row mt-5">
            <div className="col-12 orange-gray-bubble"></div>
            <div className="col-12 col-md-6 offset-md-1 mt-5 mt-md-0">
              <h1
                className="primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_faq_whole_page_h1') }}
              />
            </div>
          </div>
        </div>
      </section>
      {faqList.map((item, index) => (
        <section
          // className={
          //   index % 2 === 0
          //     ? 'frequently-asked-section py-4 py-sm-3 py-lg-5 my-5'
          //     : 'share-social-QA-section bg-offWhite-color gray-shade-1 gray-shade-3 position-relative py-4 py-sm-3 py-lg-5 my-5'
          // }
          className={
            index % 2 === 0
              ? 'frequently-asked-section'
              : 'share-social-QA-section bg-offWhite-color position-relative'
          }
          key={`${index + 1}`}
          id={item.categoryID}
        >
          <div className="container">
            {/* <div className="row mt-0 mt-sm-5 mb-5 mb-lg-0"> */}
            <div className="row py-56 py-lg-80">
              <div className="col-12 col-md-5 col-lg-4">
                <h4 className="primary-color-text text-break">
                  <div dangerouslySetInnerHTML={{ __html: item.category }} />
                </h4>
              </div>
              {/* <div className="col-12 col-md-7 col-lg-7 offset-lg-1 my-lg-5"> */}
              <div className="col-12 col-md-7 col-lg-7 offset-lg-1">
                {item.faqList.length > 5 ? (
                  <Accordion items={topItemList(item)} moreItems={faqItemList(item)} />
                ) : (
                  <Accordion items={faqItemList(item)} />
                )}
              </div>
            </div>
          </div>
        </section>
      ))}
      {/* <section className="questions-clarified-section position-relative bg-primary-color friends-purple-shade-2 purple-curve-shade-2 pt-5 pb-2"> */}
      <section className="position-relative bg-primary-color py-56 py-lg-80">
        <div className="container">
          <div className="row">
            {/* <div className="col-12 offset-md-1 col-md-7 offset-lg-1 col-lg-5 pb-md-5"> */}
            <div className="col-12 offset-md-1 col-md-7 offset-lg-1 col-lg-5">
              <h3
                className="offWhite-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_ques_subtext1') }}
              />
              <p
                className="body-large-regular offWhite-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_ques_subtext2') }}
              />
              <div className="d-flex flex-wrap flex-column flex-sm-row pt-2">
                {isIosDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    target="_blank"
                    href={t('sh_ap_appstore')}
                  >
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </a>
                )}
                {isAndroidDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    target="_blank"
                    href={t('sh_ap_playstore')}
                  >
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </a>
                )}
                {!isAndroidDevice && !isIosDevice && (
                  <>
                    <span className="pe-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 border-0">
                      <img className="mx-100 grow" src={appImages.qrcode} alt="Qrcode" />
                    </span>
                    <a
                      className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      target="_blank"
                      href={t('sh_ap_playstore')}
                    >
                      <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                    </a>
                    <a
                      className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      target="_blank"
                      href={t('sh_ap_appstore')}
                    >
                      <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <br />
      <br className="d-block d-lg-none d-xl-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" /> */}
    </>
  );
}

export default FaqTopic;
