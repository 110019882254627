import React from 'react';
import PropTypes from 'prop-types';
import InputFeedback from '../InputFeedback';

// TODO: Extend Select properties
// Type = Default = Single Select, MultiSelect, DropDown,
// Icon = True | False,
// Icon Position = START | END,
// Default things to include
// List | Items, Disable, IconComponent, Size, sx<InlineStyle>

export function Select({
  placeholder,
  name,
  id,
  options,
  value,
  getOptionId,
  getOptionName,
  onChange,
  onBlur,
  isInvalid,
  defaultOption,
  error,
  defaultValue
}) {
  const getId = (option) => option.id || getOptionId(option);
  const getName = (option) => option.name || getOptionName(option);
  return (
    <>
      <select
        className={`form-select  shadow-none body-medium-regular border-0 ${
          !value ? 'show-placeholder' : 'primary-color-text'
        } ${isInvalid ? 'is-invalid' : ''}`}
        value={value}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        aria-label="Select"
        placeholder={placeholder}
      >
        {defaultOption && (
          <option className="default-option show-placeholder" selected value="" disabled hidden>
            {defaultOption}
          </option>
        )}
        {options.map((item) => (
          <option key={getId(item)} value={getId(item)}>
            {getName(item)}
          </option>
        ))}
      </select>
      {error && <InputFeedback id={id} isInvalid feedBackMessage={error} />}

      {/* {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        aria-label="Default select example"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isInvalid={isInvalid}
        name={name}
      >
        {!defaultOption ? (
          <option value="">Select an option</option>
        ) : (
          <option value="">{defaultOption}</option>
        )}
        {options.map((item) => (
          <option key={getId(item)} value={getId(item)}>
            {getName(item)}
          </option>
        ))}
      </Form.Select> */}
      {/* <label htmlFor="validationCustom04" className="form-label">
        State
      </label> */}

      {/* <select
        className={`form-select
        ${isInvalid ? 'is-invalid' : ''}
      `}
        value={value}
        onChange={onChange}
        onBlur={onBlur}>
        {!defaultOption ? (
          <option value="">Select an option</option>
        ) : (
          <option value="">{defaultOption}</option>
        )}
        {options.map((item) => (
          <option key={getId(item)} value={getId(item)}>
            {getName(item)}
          </option>
        ))}
      </select>
      {error && <InputFeedback id={id} isInvalid feedBackMessage={error} />} */}

      {/* {help && <InputFeedback id={`${id}Block`} feedBackMessage={help} />} */}
    </>
  );
}
Select.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultOption: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getOptionId: PropTypes.func,
  getOptionName: PropTypes.func,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  onBlur: PropTypes.func
};
Select.defaultProps = {
  label: '',
  placeholder: '',
  defaultOption: '',
  defaultValue: '',
  value: '',
  name: '',
  id: '',
  getOptionId: (option) => option.id,
  getOptionName: (option) => option.name,
  onChange: () => {},
  isInvalid: null,
  onBlur: () => {}
};
export default Select;
