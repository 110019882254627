import React, { useEffect } from 'react';
import { Col, Container, Row } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useLayout } from '@context/Utils';
import { useMetaTag } from '@context/Utils/MetaTag';

export function CustomerInformation() {
  // Contexts
  const { t, staticContentData } = useStaticContent();
  const { setLightHeader } = useLayout();
  const { setMetaTags } = useMetaTag();

  useEffect(() => {
    setLightHeader(false);

    return () => {
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_Verbraucherinformationen_meta_title'),
      description: '',

      twitterTitle: t('sh_web_page_Verbraucherinformationen_meta_title'),
      twitterDescription: '',

      ogTitle: t('sh_web_page_Verbraucherinformationen_meta_title'),
      ogDescription: ''
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  return (
    <>
      {/* <section className="position-relative py-5 pb-0 pb-lg-5 mb-0 mb-lg-5"> */}
      <section className="position-relative pt-5 pb-56 pb-lg-80">
        {/* <div className="container pt-0 pt-lg-5 py-5 pb-0 pb-md-5 mt-0 mt-lg-4"> */}
        <div className="container pt-0 pt-lg-5 mt-0 mt-lg-4">
          <div className="row py-5 pb-0">
            <div className="col-xl-6 offset-xl-1 col-md-6 offset-md-1 col-12">
              <h1
                className="primary-color-text my-5 mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h1') }}
              />
            </div>
            <div className="col-xl-5 offset-xl-1 col-md-6 offset-md-1 col-12">
              <div
                className="body-large-regular primary-color-text mb-4 pe-xl-5"
                dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text1') }}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 gray-shade-3 my-5 py-5 mb-5 mb-sm-0"> */}
      <section className="position-relative bg-offWhite-color pb-5 pb-lg-0 pt-56 pt-lg-80">
        <div className="container pb-0 pb-sm-2">
          <div className="row pb-0 pb-lg-4">
            <div className="col-xl-7 offset-xl-2 col-md-8 offset-md-2 col-12">
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h2') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text2') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h3') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text3') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h4') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text4') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h5') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text5') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h7') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text7') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h8') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text8') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h9') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text9') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h6') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text6') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h10') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text10') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h11') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text11') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h12') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text12') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h13') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text13') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h14') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text14') }}
                />
              </div>
              <div className="mb-4 pb-2">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h15') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text15') }}
                />
              </div>
              <div className="mb-0 mb-sm-5 pb-0 pb-xl-3">
                <div
                  className="body-large-bold primary-color-text mb-3 d-block"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h16') }}
                />
                <div
                  className="body-large-regular primary-color-text mb-0"
                  dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text16') }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

    // <Container>
    //   <br />
    //   <br />
    //   <Row>
    //     <Col md={6} xs={12} className="mb-5">
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h1') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text1') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h2') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text2') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h3') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text3') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h4') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text4') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h5') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text5') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h6') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text6') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h7') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text7') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h8') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text8') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h9') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text9') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h10') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text10') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h11') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text11') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h12') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text12') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h13') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text13') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h14') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text14') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h15') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text15') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_h16') }} />
    //       <div dangerouslySetInnerHTML={{ __html: t('sh_Verbraucher_info_text16') }} />
    //     </Col>
    //   </Row>
    // </Container>
  );
}

export default CustomerInformation;
