/* eslint-disable camelcase */
import React from 'react';
import { Formik } from 'formik';
import { Form, ListGroup } from 'react-bootstrap';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { Text, Button } from '@core/Inputs';
import { useAccount } from '@context/MobileOne';
import { Col, Container, Row } from '@core/Layout';
import { Badge } from '@core/Utils';
import { validatePasswordWithKeys } from '@utils/globalConstant';

export function NewPassword() {
  // Context
  const { t } = useStaticContent();
  const { newPasswordInitialValue, newPasswordValidation, isLoading, onSubmitNewPassword } =
    useAccount();

  return (
    <Container>
      <>
        <br />
        <Row>
          <Col xs={12}>
            <h4 dangerouslySetInnerHTML={{ __html: t('Dein neues Passwort') }} />
          </Col>
          <Col xs={12}>
            <span>{t('sh_login_pw_subheadline2')}</span>
          </Col>
        </Row>
        <br />
        <Formik
          initialValues={newPasswordInitialValue}
          validationSchema={newPasswordValidation}
          validate={(value) => validatePasswordWithKeys(value, 'password')}
          onSubmit={onSubmitNewPassword}
        >
          {({ values: { password }, handleBlur, handleChange, errors, touched, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} className="py-3">
                  <Text
                    type="password"
                    id="password"
                    name="password"
                    onChange={handleChange}
                    value={password}
                    placeholder={t('sh_login_pw_new_pw')}
                    isInvalid={!!touched.password && !!errors.password}
                    onBlur={handleBlur}
                    error={touched.password && errors.password ? errors.password : null}
                  />
                </Col>
                <br />
                <h5 dangerouslySetInnerHTML={{ __html: t('sh_login_pw_criteria') }} />
                <ListGroup as="ol">
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    {t('sh_login_pw_criteria1')}
                    <>
                      {errors && errors.otherErrors && !errors.otherErrors.minMax ? (
                        <Badge bg="danger" pill>
                          {t('Invalid')}
                        </Badge>
                      ) : (
                        touched.password &&
                        password && (
                          <Badge bg="success" pill>
                            {t('Valid')}
                          </Badge>
                        )
                      )}
                    </>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    {t('sh_login_pw_criteria2')}
                    <>
                      {errors && errors.otherErrors && !errors.otherErrors.uppercase ? (
                        <Badge bg="danger" pill>
                          {t('Invalid')}
                        </Badge>
                      ) : (
                        touched.password &&
                        password && (
                          <Badge bg="success" pill>
                            {t('Valid')}
                          </Badge>
                        )
                      )}
                    </>
                  </ListGroup.Item>

                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    {t('sh_login_pw_criteria3')}
                    <>
                      {errors &&
                      errors.otherErrors &&
                      !errors.otherErrors.lowecase &&
                      !errors.otherErrors.uppercase ? (
                        <Badge bg="danger" pill>
                          {t('Invalid')}
                        </Badge>
                      ) : (
                        touched.password &&
                        password && (
                          <Badge bg="success" pill>
                            {t('Valid')}
                          </Badge>
                        )
                      )}
                    </>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    {t('sh_login_pw_criteria4')}
                    <>
                      {errors && errors.otherErrors && !errors.otherErrors.specific ? (
                        <Badge bg="danger" pill>
                          {t('Invalid')}
                        </Badge>
                      ) : (
                        touched.password &&
                        password && (
                          <Badge bg="success" pill>
                            {t('Valid')}
                          </Badge>
                        )
                      )}
                    </>
                  </ListGroup.Item>
                </ListGroup>
              </Row>

              <Col xs={12} className="py-3">
                <Button variant="primary" type="submit" isLoading={isLoading}>
                  {t('sh_login_pw_finish_button')}
                </Button>
              </Col>
            </Form>
          )}
        </Formik>
      </>
    </Container>
  );
}

export default NewPassword;
