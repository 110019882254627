import React, { useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Col, Container, Row } from '@core/Layout';
import { Button, Switch, Text } from '@core/Inputs';
import { useCustomer } from '@context/MobileOne';
import { appAlert } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
import { Modal } from 'react-bootstrap';

export function ReferFriend() {
  // State
  const [termsChecked, setTermsChecked] = useState(false);
  const [modalPopup, setModalPopup] = useState(false);

  // context
  const { t } = useStaticContent();
  const {
    customerData: { broCode }
  } = useCustomer();
  const { showAlert } = useAlert();

  // function
  const shareCode = () => {
    if (!termsChecked) {
      return showAlert({ type: appAlert.WARNING, message: t('sh_db_mgm_err1') });
    }
    // API Integration Here
    return true;
  };

  return (
    <Container>
      <Row>
        <Col xs={12} className="py-3">
          <h2 dangerouslySetInnerHTML={{ __html: t('sh_db_mgm_h1') }} />
          <p>
            {t('sh_db_mgm_subheadline1_1')}{' '}
            <b dangerouslySetInnerHTML={{ __html: t('sh_db_mgm_subheadline1_2') }} />
          </p>

          <p dangerouslySetInnerHTML={{ __html: t('sh_db_mgm_code') }} />
          <Text
            type="copytext"
            id="referCode"
            name="referCode"
            value={broCode === null || broCode === undefined ? 'SHARE1' : broCode}
            placeholder="*********"
            isDisabled
            showCode={termsChecked}
          />
          <br />
          <Row className="d-flex justify-content-sm-around">
            <Col xs={8}>
              <p className="m-0" dangerouslySetInnerHTML={{ __html: t('sh_db_mgm_legal_1') }} />
              <Button
                variant="link"
                className="mb-4 p-0"
                onClick={() => {
                  setModalPopup(true);
                }}
              >
                {t('sh_db_mgm_legal_2')}
              </Button>
            </Col>
            <Col xs={4} className="d-flex align-items-end flex-column">
              <Switch
                name="referSwitch"
                id="referSwitch"
                isDefaultChecked={termsChecked}
                onChange={() => setTermsChecked(!termsChecked)}
              />
            </Col>
          </Row>
          <Button type="submit" variant="dark" disabled={!termsChecked} onClick={shareCode}>
            {t('sh_db_mgm_confirm_button')}
          </Button>
        </Col>
      </Row>

      <Modal show={modalPopup} onHide={() => setModalPopup(false)} size="lg">
        <Modal.Header closeButton>
          <div>
            <p className="m-0">{t('sh_db_mgm_legal_headline1')}</p>
            <Modal.Title>{t('sh_db_mgm_legal_headline2')}</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <p>{t('sh_db_mgm_legal_text')}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalPopup(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ReferFriend;
