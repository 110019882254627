import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { useActivation } from '@context/MobileOne';
import { usePayment } from '@context/MobileOne/Activation/Payments';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import {
  appActivationFlowType,
  appActivationStep1Modals,
  appButtonType,
  appImages,
  appRoute,
  appStaticPdf,
  appTariff
} from '@utils/globalConstant';
import { Button } from '@core/Inputs';
import { UTAG_LINK, utag } from '@utils/utag';
import { useLayout } from '@context/Utils';

import FootNotesModal from './FootNotesModal';
import PriceDetailsModal from './PriceDetailsModal';
import MGMCodeModal from './MGMCodeModal';
import TariffDetailsModal from './TariffDetailsModal';
import NewFootNotesModal from './NewFootNotesModal';
import NewTariffModal from './NewTariffModal';
import PinkTariffBubbleModal from './PinkTariffBubbleModal';

export function TariffPicker({ customeClass = '' }) {
  // Const
  const DEFAULT_SELECTED_TARIFF = 6501;

  // State
  // const [modal, setModal] = useState('');
  const [tariffBullet, setTariffBullet] = useState([]);
  const [mgmBullet, setMgmBullet] = useState([]);

  // Context

  const { t, getPdfList, staticContentData } = useStaticContent();
  const {
    getPrepaidTariffs,
    activeTariff,
    setActiveTariff,
    bookableTariff,
    bookableTariff: { bookableTariffs = {}, msisdn = '' },
    allTariffs,
    setBookableTariff,
    validationSchema,
    formValue,
    isLoading,
    setFormValue,
    applyMGMCode,
    onNewCustomerSubmit: onSubmit,
    modal,
    setModal
  } = useActivationTariff();
  // const { screenSize } = useLayout();
  const { activationInfo, setActivationType } = useActivation();
  const { setCurrentRoute } = usePayment();
  // const navigate = useNavigate();

  // Hooks
  // useEffect(() => {
  //   if (staticContentData && allTariffs && allTariffs?.bookableTariffs && !activeTariff) {
  //     allTariffs.bookableTariffs = allTariffs.bookableTariffs.map((bt) => {
  //       const scTariff = staticContentData.tariff_share.find((stf) => stf.id === bt.id);
  //       scTariff.duration = bt.duration;
  //       scTariff.durationUnit = bt.durationUnit;
  //       return scTariff;
  //     });
  //     const defaultTariff =
  //       !allTariffs.msisdn ||
  //       allTariffs.bookableTariffs[0].name.toLowerCase() === 'allen flat m'
  //         ? allTariffs.bookableTariffs.find((bt) => bt.defaultTariff)
  //         : allTariffs.bookableTariffs[0];

  //     setBookableTariff(JSON.parse(JSON.stringify(allTariffs)));

  //     if (defaultTariff) {
  //       setFormValue({
  //         ...formValue,
  //         tariff: defaultTariff.id
  //       });
  //       setActiveTariff({ ...defaultTariff });
  //     }
  //   }
  // }, [staticContentData, allTariffs]);

  useEffect(() => {
    // console.log({ activeTariff });
    let tariffDetailBulletsList = [];
    tariffDetailBulletsList = !applyMGMCode?.promoCode
      ? activeTariff?.detailInfo?.additionalInfo?.bullets
      : activeTariff.operationsInfo.ON_MGM_CODE_APPLY.bullets;
    if (tariffDetailBulletsList) {
      let value = tariffDetailBulletsList ? tariffDetailBulletsList[2] : '';
      tariffDetailBulletsList = !bookableTariff.msisdn
        ? tariffDetailBulletsList
        : tariffDetailBulletsList.filter((item) => item !== value);
      setTariffBullet(tariffDetailBulletsList);
      setMgmBullet(tariffDetailBulletsList);
    }
  }, [activeTariff, bookableTariff, applyMGMCode]);

  // Hooks
  useEffect(() => {
    getPrepaidTariffs();
  }, []);

  useEffect(() => {
    if (staticContentData) {
      setTimeout(() => {
        const starModal = document.querySelector('.star-btn');
        if (starModal) {
          starModal.addEventListener('click', () => {
            setModal(appActivationStep1Modals.NEW_FOOTNOTES);
          });
        }
      }, 100);
    }

    // return () => {
    //   const starModal = document.querySelector('.star-btn');
    //   if (starModal) {
    //     console.log('erturn');
    //     starModal.removeEventListener('click');
    //   }
    // };
  }, [staticContentData]);

  // useEffect(() => {
  //   if (staticContentData) {
  //     setTimeout(() => {
  //       const starModal = document.querySelector('.star-btn');
  //       console.log('startModalin the iff starModal', starModal);
  //       if (starModal) {
  //         console.log('startModalin the iff ');
  //         starModal.addEventListener('click', () => {
  //           console.log('startModal');
  //           setModal(appActivationStep1Modals.NEW_FOOTNOTES);
  //         });
  //       }
  //     }, 100);
  //   }

  //   // return () => {
  //   //   const starModal = document.querySelector('.star-btn');
  //   //   if (starModal) {
  //   //     console.log('erturn');
  //   //     starModal.removeEventListener('click');
  //   //   }
  //   // };
  // }, [staticContentData]);

  // useEffect(() => {
  //   if (staticContentData && bookableTariff && bookableTariff?.bookableTariffs && !activeTariff) {
  //     bookableTariff.bookableTariffs = bookableTariff.bookableTariffs.map((bt) => {
  //       const scTariff = staticContentData.tariff_share.find((stf) => stf.id === bt.id);
  //       scTariff.duration = bt.duration;
  //       scTariff.durationUnit = bt.durationUnit;
  //       return scTariff;
  //     });
  //     setBookableTariff(JSON.parse(JSON.stringify(bookableTariff)));
  //   }
  // }, [staticContentData]);

  // useEffect(() => {
  //   let tariff = bookableTariffs.find(({ id }) => id === DEFAULT_SELECTED_TARIFF);
  //   tariff ? setSelectedTariff(tariff) : setSelectedTariff(bookableTariffs[0]);
  //   console.log({tariff});
  //   return () => {
  //     setSelectedTariff({});
  //   };
  // }, [bookableTariff.bookableTariffs]);

  // useEffect(() => {
  //   console.log('activeTariff', activeTariff);
  // }, [activeTariff]);

  // useEffect(() => {
  //   initiatePOSPurchas();
  // }, []);

  // useEffect(() => {
  //   if (staticContentData) {
  //     setTimeout(() => {
  //       const startModal = document.querySelector('.star-btn');
  //       if (startModal) {
  //         startModal.addEventListener('click', () => {
  //           setModal(appActivationStep1Modals.NEW_FOOTNOTES);
  //         });
  //       }
  //     }, 300);
  //   }
  // }, [staticContentData]);

  // Functions
  const onNewCustomerSubmit = () => {
    const values = {
      tariff: activeTariff.id,
      isPromoApplied: false,
      codeForValidation: ''
    };
    setActivationType(appActivationFlowType.NEW_ONLINE);
    onSubmit(values);
  };

  const openPdf = (pdfId, pdfName) => {
    if (staticContentData) {
      let pdfInfo = getPdfList('sh_pdfLists').find((item) => item.listName === pdfName);
      window.open(pdfInfo.listContent.find(({ id }) => id === pdfId).media_url_display);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={
          activationInfo?.activeTariff?.id
            ? {
                ...formValue,
                isPromoApplied: activeTariff?.promoCode || false,
                codeForValidation: activeTariff?.promoCode,
                tariff: activationInfo.activeTariff.id
              }
            : {
                ...formValue,
                isPromoApplied: activeTariff?.promoCode || false,
                codeForValidation: activeTariff?.promoCode
              }
        }
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values: { tariff, isPromoApplied, codeForValidation },
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleBlur,
          handleChange,
          errors,
          touched,
          setErrors,
          setTouched
        }) => (
          <Form>
            <div className={`w-100 rounded ${customeClass}`}>
              <div className="bg-primary-color tariff-shadow box-radius position-relative z-2">
                {/* <div className="tariff-image text-center w-full">
                  <img
                    src={appImages.onlinePosPinkTeaser}
                    onClick={() => setModal(appActivationStep1Modals.PINK_TARIFF_BUBBLE_MODAL)}
                    id="tariff-banner-img"
                    role="button"
                    alt="Bubble Image"
                  />
                </div> */}
                {t('sh_apoc_tarif_voucher_top') &&
                  t('sh_apoc_tarif_voucher_top') != 'sh_apoc_tarif_voucher_top' && (
                    <div className="tariff-voucher-top">{t('sh_apoc_tarif_voucher_top')}</div>
                  )}
                <div className="d-flex justify-content-between mt-4">
                  {bookableTariffs?.map((tariff, index) => (
                    <div
                      key={`${index + 1}-tariff-card`}
                      role="button"
                      className={
                        activeTariff?.id === tariff?.id
                          ? 'tarrif-selection shadow  active'
                          : 'tarrif-selection shadow'
                      }
                      onClick={() => {
                        if (window && window.utag) {
                          tariff.id === appTariff.FLAT_M
                            ? window.utag.link(
                                UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                                  .SELECT_ALLNET_FLAT_M_ON_CLICK
                              )
                            : window.utag.link(
                                UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                                  .SELECT_ALLNET_FLAT_L_ON_CLICK
                              );
                        }

                        setFieldValue('tariff', tariff.id);
                        setActiveTariff(tariff);
                      }}
                    >
                      <div className="d-flex align-items-baseline">
                        <div className="custom-tariff-name mb-2 body-medium-regular mango-color-text">
                          {tariff.name}
                        </div>
                      </div>
                      <div className="mango-color-text">
                        <div className="custom-data-text mb-0">
                          {tariff.additionalInfo?.volume?.DATA?.value}{' '}
                          {tariff.additionalInfo?.volume?.DATA?.unit}
                        </div>
                      </div>
                      <div className="d-flex align-items-baseline">
                        {/* <h3
                          className="body-medium-regular mango-color-text"
                          dangerouslySetInnerHTML={{ __html: tariff?.detailInfo?.subHeader }}
                        /> */}
                        <div
                          className="mb-2 body-medium-regular mango-color-text tarif-overview-text"
                          dangerouslySetInnerHTML={{
                            __html: t(
                              tariff.id === appTariff.FLAT_M
                                ? 'sh_apoc_tarif_overview_info_text2'
                                : 'sh_apoc_tarif_overview_info_text3'
                            )
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className="d-flex justify-content-between p-3 bg-purple-light-color box-radius mt-4">
                  <div className="d-block">
                    <p className="label-medium text-uppercase text-white">
                      {t('sh_apoc_start_initial_price_header').replace(
                        '{{price}}',
                        activeTariff?.starterPackPrice
                      )}
                    </p>
                    <p className="label-small text-white mb-0">
                      {t('sh_apoc_start_initial_price_subheader')}
                    </p>
                  </div>
                  <button
                    type="button"
                    className="btn btn-link shadow-none bg-transparent"
                    onClick={() => setModal(appActivationStep1Modals.PRICE_DETAILS)}
                  >
                    {' '}
                    <img className="img-fluid" src={appImages.InfoIconWhite} alt="Info icon" />
                  </button>
                </div> */}
                <ul className="list-unstyled tarrif-feature mt-4 d-grid gap-2">
                  {(
                    activeTariff?.detailInfo?.additionalInfo?.bulletsActivationTariffSelection || []
                  ).map((bullet, key) => (
                    <li
                      className="body-medium-regular text-white list-group-item"
                      key={`${key + 1}-tariff-bullets`}
                      dangerouslySetInnerHTML={{ __html: bullet.header }}
                    />
                  ))}
                </ul>
                <div className="d-flex justify-content-between p-3 bg-purple-light-color box-radius mt-4">
                  <div className="d-block">
                    <p className="label-medium text-uppercase text-white">
                      {t('sh_apoc_start_initial_price_header').replace(
                        '{{price}}',
                        activeTariff?.starterPackPrice
                      )}
                    </p>
                    <p className="label-small text-white mb-0">
                      {t('sh_apoc_start_initial_price_subheader')}
                    </p>
                  </div>
                  <button
                    type="button"
                    className="btn btn-link shadow-none bg-transparent"
                    onClick={() => setModal(appActivationStep1Modals.PRICE_DETAILS)}
                  >
                    {' '}
                    <img className="img-fluid" src={appImages.InfoIconWhite} alt="Info icon" />
                  </button>
                </div>
                <div
                  className="d-sm-flex justify-content-between mt-3 pt-3"
                  style={{ alignItems: 'center' }}
                >
                  <div className="d-flex align-text-bottom" style={{ alignItems: 'center' }}>
                    <div className="custom-price-text mb-2 text-white">
                      {activeTariff?.starterPackPrice} €{' '}
                    </div>
                    <p className="text-white body-medium-regular mb-0 mx-1">
                      / {activeTariff?.duration} &nbsp;{activeTariff?.durationUnit}
                    </p>
                    <div
                      className="astrick"
                      role="button"
                      onClick={() => {
                        setModal(appActivationStep1Modals.FOOTNOTES);
                      }}
                    >
                      {/* <i
                        className="bi bi-star-fill primary-color-text"
                        style={{ position: 'relative' }}
                      /> */}
                      <img className="img-fluid" src={appImages.footnote} alt="foot note icon" />
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex d-block d-md-none">
                      <div
                        className="text-white label-small link-light tariff-link shadow-none mb-2"
                        dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text7') }}
                      />
                      {/* <div
                        className="astrick"
                        role="button"
                        onClick={() => {
                          setModal(appActivationStep1Modals.NEW_TARIFF_MODAL);
                        }}
                      >
                        <img className="img-fluid" src={appImages.footnote} alt="foot note icon" />
                      </div> */}
                    </div>

                    <div xs={12} className="col">
                      <input type="hidden" name="isPromoApplied" defaultValue="false" />
                      {!isPromoApplied && (
                        <a
                          className="text-white label-small p-0 mb-5 mb-sm-0 link-light tariff-link shadow-none"
                          variant="link"
                          onClick={() => {
                            setModal(appActivationStep1Modals.MGM_CODE);
                          }}
                        >
                          {t('sh_apoc_tarif_overview_voucher')}
                        </a>
                      )}
                    </div>
                    {/* {activeTariff?.promoCode && (
                      <>
                        <div className="col text-white label-small p-0 mb-5 mb-sm-0 link-light shadow-none">
                          <div className="">
                            <b>
                              {t('sh_apoc_tarif_overview_voucher').replace(
                                ' ',
                                ` "${activeTariff?.operationsInfo?.promoCode}" `
                              )}
                            </b>
                            <p>
                              {t('sh_apoc_tarif_overview_voucher-msg2').replace(
                                '{{value}}',
                                `${activeTariff.operationsInfo.codeGBVolume}`
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col text-end">
                          <a
                            className="text-white label-small p-0 mb-5 mb-sm-0 link-light shadow-none"
                            variant="link"
                            onClick={() => {
                              setFieldValue('isPromoApplied', false);
                              setFieldValue('codeForValidation', '');
                              setFieldTouched('codeForValidation', false, false);
                              const bookableTariffsIds = bookableTariff?.bookableTariffs.map(
                                (bt) => bt.id
                              );
                              bookableTariff.bookableTariffs = [
                                ...staticContentData.tariff_share.filter(
                                  (bt) => bookableTariffsIds.indexOf(bt.id) > -1
                                )
                              ];
                              const defaultTariff = !bookableTariff.msisdn
                                ? bookableTariff.bookableTariffs.find((bt) => bt.defaultTariff)
                                : bookableTariff.bookableTariffs[0];

                              setFormValue({ ...formValue, tariff: defaultTariff.id });
                              setActiveTariff(defaultTariff);
                              setBookableTariff(JSON.parse(JSON.stringify(bookableTariff)));
                            }}>
                            {t('sh_apoc_tarif_overview_delete')}
                          </a>
                        </div>
                      </>
                    )} */}
                  </div>
                </div>
                <div className="d-md-flex d-none">
                  <div
                    className="text-white label-small link-light shadow-none"
                    dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text7') }}
                  />
                  {/* <div
                    className="astrick"
                    role="button"
                    onClick={() => {
                      setModal(appActivationStep1Modals.NEW_TARIFF_MODAL);
                    }}
                  >
                    <img className="img-fluid" src={appImages.footnote} alt="foot note icon" />
                  </div> */}
                </div>
                {activeTariff?.promoCode && (
                  <div className="row p-3 bg-purple-light-color box-radius mx-0 mt-4">
                    <div className="col-12 p-0">
                      <p className="label-medium text-uppercase text-white">
                        {t('sh_apoc_tarif_overview_voucher-code-msg1').replace(
                          '{{mgmCode}}',
                          activeTariff?.promoCode
                        )}
                      </p>
                      <p className="label-small text-white mb-0">
                        {t('sh_apoc_tarif_overview_voucher-code-msg2').replace(
                          '{{dataVolume}}',
                          activeTariff?.operationsInfo?.codeGBVolume
                        )}
                      </p>
                    </div>
                    <div className="col-12 px-0 pt-2">
                      <a
                        className="text-white label-small p-0 mb-5 mb-sm-0 link-light tariff-link shadow-none"
                        variant="link"
                        onClick={() => {
                          setFieldValue('isPromoApplied', false);
                          setFieldValue('codeForValidation', '');
                          setFieldTouched('codeForValidation', false, false);
                          const bookableTariffsIds = bookableTariff?.bookableTariffs.map(
                            (bt) => bt.id
                          );
                          bookableTariff.bookableTariffs = [
                            ...staticContentData.tariff_share.filter(
                              (bt) => bookableTariffsIds.indexOf(bt.id) > -1
                            )
                          ];

                          const defaultTariff =
                            bookableTariff.bookableTariffs.length > 1
                              ? bookableTariff.bookableTariffs.find(
                                  (bt) => bt.id === activeTariff.id
                                )
                              : bookableTariff.bookableTariffs[0];

                          setFormValue({ ...formValue, tariff: defaultTariff.id });
                          setActiveTariff(defaultTariff);
                          setBookableTariff(JSON.parse(JSON.stringify(bookableTariff)));
                        }}
                      >
                        {t('sh_apoc_tarif_overview_delete')}
                      </a>
                    </div>
                  </div>
                )}
                <div className="mt-4">
                  <Button
                    buttonType={appButtonType.PRIMARY}
                    type="button"
                    className="w-100"
                    onClick={onNewCustomerSubmit}
                  >
                    {t('sh_apoc_tarif_overview_tarif_button')}
                  </Button>
                </div>
                {/* <button type='button' className="btn btn-primary w-100">
            {t('sh_apoc_tarif_overview_tarif_button')}
          </button> */}
                <div className="d-grid gap-3 d-sm-flex justify-content-between mt-4 pt-2">
                  <a
                    className="text-white label-small link-light tariff-link shadow-none"
                    variant="link"
                    onClick={() => {
                      if (window && window.utag) {
                        activeTariff.id === appTariff.FLAT_M
                          ? window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                                .TARIFF_DETAILS_ON_CLICK
                            )
                          : window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                                .TARIFF_DETAILS_ON_CLICK
                            );
                      }

                      setModal(appActivationStep1Modals.TARIFF_DETAILS);
                    }}
                  >
                    {t('sh_apoc_tarif_overview_tarif-details')}
                  </a>
                  <a
                    className="btn-link text-white label-small link-light tariff-link shadow-none"
                    variant="link"
                    onClick={() => {
                      if (window && window.utag) {
                        activeTariff.id === appTariff.FLAT_M
                          ? window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                                .PRODUCT_INFORMATION_ON_CLICK
                            )
                          : window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                                .PRODUCT_INFORMATION_ON_CLICK
                            );
                      }

                      openPdf(
                        activeTariff?.additionalInfo?.document?.productInformation.id,
                        appStaticPdf.PRODUCT_INFO
                      );
                    }}
                  >
                    {t('sh_apoc_tarif_overview_product-info')}
                  </a>
                  <a
                    className="btn-link text-white label-small link-light tariff-link shadow-none"
                    variant="link"
                    onClick={() => {
                      if (window && window.utag) {
                        activeTariff.id === appTariff.FLAT_M
                          ? window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                                .PRICE_LIST_ON_CLICK
                            )
                          : window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                                .PRICE_LIST_ON_CLICK
                            );
                      }

                      openPdf(
                        activeTariff?.additionalInfo?.document?.priceList.id,
                        appStaticPdf.AGB_SERVICES
                      );
                    }}
                  >
                    {t('sh_apoc_tarif_overview_prices')}
                  </a>
                </div>
                <div className="d-flex justify-content-end align-items-center pt-4">
                  <p className="text-white label-small link-light tariff-link shadow-none m-0">
                    {t('sh_apoc_tarif_overview_powered')}
                  </p>
                  <img src={appImages.CongStarWhite02} />
                </div>
              </div>
            </div>

            {modal === appActivationStep1Modals.TARIFF_DETAILS && (
              <TariffDetailsModal setModal={setModal} />
            )}

            {modal === appActivationStep1Modals.PRICE_DETAILS && (
              <PriceDetailsModal setModal={setModal} />
            )}

            {modal === appActivationStep1Modals.FOOTNOTES && <FootNotesModal setModal={setModal} />}
            {modal === appActivationStep1Modals.PINK_TARIFF_BUBBLE_MODAL && (
              <PinkTariffBubbleModal setModal={setModal} />
            )}
            {modal === appActivationStep1Modals.NEW_FOOTNOTES && (
              <NewFootNotesModal setModal={setModal} />
            )}
            {modal === appActivationStep1Modals.NEW_TARIFF_MODAL && (
              <NewTariffModal setModal={setModal} />
            )}

            {modal === appActivationStep1Modals.MGM_CODE && (
              <MGMCodeModal
                {...{
                  isLoading,
                  setModal,
                  handleChange,
                  handleBlur,
                  codeForValidation,
                  touched,
                  errors,
                  setErrors,
                  setTouched,
                  applyMGMCode
                }}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default TariffPicker;
