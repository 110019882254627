// /* eslint-disable react/prop-types */
// /* eslint-disable react/jsx-curly-brace-presence */
// /* eslint-disable react/destructuring-assignment */
// /* eslint-disable react/jsx-props-no-spreading */
// import React from 'react';

// // TODO: Extend Buttons properties
// // Type = Default = Contained, Outline, Text, Flat
// // Icon = True | False,
// // Icon Position = START | END,
// // Default things to include
// // Color, Disable, IconComponent, Size, sx<InlineStyle>
// export function Button({ isLoading, disabled, children, ...props }) {
//   return (
//     <button className="btn" {...props} disabled={disabled || isLoading}>
//       {isLoading ? <span className="spinner-border spinner-border-sm" role="status" /> : children}
//     </button>
//   );
// }

// export default Button;

import React from 'react';
import { appButtonType } from '@utils/globalConstant';
import { Spinner } from '@core/Utils';

export function Button({
  buttonType = appButtonType.PRIMARY,
  className,
  isLoading,
  icon,
  isIcon,
  disabled,
  children,
  ...props
}) {
  let buttonComponent = <></>;

  switch (buttonType) {
    case appButtonType.PRIMARY:
      buttonComponent = (
        <button
          {...props}
          className={`${appButtonType.PRIMARY} ${className}`}
          disabled={disabled || isLoading}
        >
          {isLoading ? <Spinner /> : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;

    case appButtonType.SECONDARY:
      buttonComponent = (
        <button
          {...props}
          className={`${appButtonType.SECONDARY} ${className}`}
          disabled={disabled || isLoading}
        >
          {isLoading ? <Spinner /> : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;

    case appButtonType.DANGER:
      buttonComponent = (
        <button
          {...props}
          className={`${appButtonType.DANGER} ${className}`}
          disabled={disabled || isLoading}
        >
          {isLoading ? <Spinner /> : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;

    case appButtonType.LINK:
      buttonComponent = (
        <button
          {...props}
          className={`${appButtonType.LINK} ${className}`}
          disabled={disabled || isLoading}
        >
          {isLoading ? <Spinner /> : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;

    case appButtonType.DISABLED:
      buttonComponent = (
        <button
          {...props}
          className={`${appButtonType.DISABLED} ${className}`}
          disabled={disabled || isLoading}
        >
          {isLoading ? <Spinner /> : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;

    case appButtonType.OUTLINE_SECONDARY:
      buttonComponent = (
        <button
          {...props}
          className={`${appButtonType.OUTLINE_SECONDARY} ${className}`}
          disabled={disabled || isLoading}
        >
          {isLoading ? <Spinner /> : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;

    default:
      buttonComponent = (
        <button
          {...props}
          className={`${appButtonType.PRIMARY} ${className}`}
          disabled={disabled || isLoading}
        >
          {isLoading ? <Spinner /> : children}
          {isIcon && !isLoading && icon}
        </button>
      );
      break;
  }

  return buttonComponent;

  // return (

  //     <button {...props} disabled={disabled || isLoading}>
  //       {isLoading ? <Spinner size="sm" animation="border" role="status" /> : children}
  //     </button>

  // );
}

export default Button;
