import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import { Card, CardBody, CardHeader, CardTitle } from '@core/Layout/Card';
import { Row, Col } from '@core/Layout/Container';

export function PaymentMethod({ name, isSelected, onSelect, onBlur }) {
  return (
    <>
      <Card border="primary" sx={{ width: '18rem' }} onClick={() => onSelect(name)}>
        <CardHeader>
          <Row className="justify-content-md-center p-0">
            <Col xs={10}>{name}</Col>
            <Col xs={2} className="justify-content-md-center">
              <Form.Check
                type="radio"
                id={`payment-method-${name}`}
                checked={isSelected}
                onBlur={onBlur}
                onChange={() => onSelect(name)}
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <CardTitle>Method Name - {name}</CardTitle>
        </CardBody>
      </Card>
      <br />
    </>
  );
}

PaymentMethod.propTypes = {
  name: PropTypes.string,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func
};

PaymentMethod.defaultProps = {
  name: '',
  isSelected: false,
  onSelect: () => {},
  onBlur: () => {}
};

export default PaymentMethod;
