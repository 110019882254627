/* eslint-disable  */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';

import {
  appPaymentProductType,
  DEFAULT_FIELD_VALIDATION_TYPE,
  // getLowBalanceTopUp,
  getLowBalanceTopUpWithoutAmount,
  useAppConfig
} from '@dom-digital-online-media/dom-app-config-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { useAlphaComm } from '@dom-digital-online-media/dom-alphacomm-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import {
  appActivationFlowType,
  appActivationStep2Screens,
  appAlert,
  appRoute,
  appStorage,
  formValidation,
  gettingNumberForGerman
} from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
/* eslint-disable */
import { ActivationTariffContextProvider } from '@context/MobileOne/Activation/ActivationTariff';
import { SimValidationContextProvider } from '@context/MobileOne/Activation/SimValidation';
import { EmailVerificationContextProvider } from '@context/MobileOne/Activation/EmailVerification';
import { NumberPortingContextProvider } from '@context/MobileOne/Activation/NumberPorting';
import { PersonalDataContextProvider } from '@context/MobileOne/Activation/PersonalData';
import { ActivationAutoTopUpContextProvider } from './ActivationAutoTopUp';
import { PaymentContextProvider } from './Payments';
import { SimActivationContextProvider } from './SimActivation';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '@config/ContextManager';

export const ActivationContext = createContext({});

const STATIC_ACTIVATION_VARIABLE = {
  SALUTATION: 3,
  IDENTITY_DOCUMENT: {
    nationality: 1,
    identityType: 11,
    legitimationMethod: 3,
    identityNumber: '123456EF',
    validUntil: '2035-01-01',
    placeOfIssue: 'Ratingen'
  }
};

export function ActivationContextProvider({ children }) {
  const location = useLocation();
  // States
  // API Data Storage States
  const [currentStep, setCurrentStep] = useState(0);
  const [editStep, setEditStep] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [numberPortingScreen, setNumberPortingScreen] = useState(
    appActivationStep2Screens.PHONE_MNP_NEW_SELECTION
  );

  const [bookableTariffs, setBookableTariffs] = useState([]);
  const [selectedTariffId, setSelectedTariffId] = useState(0);
  const [paymentProducts, setPaymentProducts] = useState([]);
  const [allPaymentProducts, setAllPaymentProducts] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [simProvider, setSimProvider] = useState([]);
  const [areaCode, setAreaCode] = useState([]);
  const [salutations, setSalutations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [identityTypes, setIdentityTypes] = useState([]);
  const [nationality, setNationality] = useState([]);

  // Special data for some other unique inputs
  const [otherAmount, setOtherAmount] = useState(''); // Input for Direct topup
  // To show address after product selection in payment activation
  const [showAddress, setShowAddress] = useState(false);
  // TODO: Store data in storage for verification once callback / returns.
  const [cartName, setCartName] = useState(null); // Cart ID / Name when added to card.
  const [paymentAuthData, setPaymentAuthData] = useState({});
  const [orderNumber, setOrderNumber] = useState(null); // order ID / Name when processed with payment.
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [postIdentUrl, setPostIdentUrl] = useState(null);
  const [activationInfo, setActivationInfo] = useState(
    sessionStorage.getItem(appStorage.ACTIVATION_INFO)
      ? JSON.parse(sessionStorage.getItem(appStorage.ACTIVATION_INFO))
      : {}
  );

  const [activatedSimData, setActivatedSimData] = useState({});

  const [activationType, setActivationType] = useState(
    location.pathname.includes(appRoute.NEW_ONLINE_CUSTOMER)
      ? appActivationFlowType.NEW_ONLINE
      : appActivationFlowType.SIM_ACTIVATION
  );

  // Context
  const { t } = useStaticContent();
  const { showAlert } = useAlert();
  const { env } = useAppConfig();
  const {
    onValidateSim,
    oncountriesCall,
    onIdentityTpesCall,
    onsalutationCall,
    onActivateSim,
    onNationalitiesCall,
    onAreaCode,
    onSimProvider,
    onDomcountriesCall
  } = useMobileOne();
  const {
    onSsoPaymentToken,
    onPaymentProduct,
    onPaymentAddToCart,
    onPaymentMethod,
    onPaymentCheckoutAddress,
    onCheckoutPaymentStart
  } = useAlphaComm();
  const navigate = useNavigate();
  const {
    config: { storage }
  } = useConfig();

  // Step 1 - Phone Number Initial Values & Validations
  const phoneNumberInitialValue = {
    msisdn: '',
    iccid: '',
    puk: '',
    mnp: false,
    currentProvider: '',
    type: '6',
    oldNumber: '',
    oldNumberPrefix: ''
  };
  const phoneNumberValidations = Yup.object().shape({
    msisdn: formValidation({
      regex: /^\d{5,20}$/,
      validErrorMessage: 'please_valid_msidn_number'
    }),
    iccid: formValidation({
      regex: /^\d{5,20}$/,
      validErrorMessage: 'please_valid_iccid_number'
    }),
    puk: formValidation({
      required: 'please_enter_puk',
      regex: /^\d{4,8}$/,
      validErrorMessage: 'please_valid_puk'
    })
  });
  const phoneNumberValidationsWithMNP = Yup.object().shape({
    msisdn: formValidation({
      regex: /^\d{5,20}$/,
      validErrorMessage: 'please_valid_msidn_number'
    }),
    iccid: formValidation({
      regex: /^\d{5,20}$/,
      validErrorMessage: 'please_valid_iccid_number'
    }),
    puk: formValidation({
      required: 'please_enter_puk',
      regex: /^\d{4,8}$/,
      validErrorMessage: 'please_valid_puk'
    }),
    currentProvider: Yup.string().required('please_select_provider'),
    oldNumberPrefix: formValidation({
      required: 'please_enter_prefix',
      regex: /^\d{4}$/,
      validErrorMessage: 'invalid_prefix'
    }),
    oldNumber: formValidation({
      required: 'please_enter_old_number',
      regex: /^\d{5,20}$/,
      validErrorMessage: 'invalid_number'
    })
  });
  const PhoneNumberCustomValidation = (values) => {
    // eslint-disable-next-line prefer-const
    let errors = {};
    if (!values.msisdn && !values.iccid) {
      errors.msisdn = 'please_msisdn_or_iccid_required';
      errors.iccid = 'please_iccid_or_msisdn_required';
    }

    if (values.msisdn && !values.iccid && !/^\d{5,20}$/.test(values.msisdn)) {
      errors.msisdn = 'invalid_msisdn';
    }

    if (values.iccid && !values.msisdn && !/^\d{5,20}$/.test(values.iccid)) {
      errors.iccid = 'invalid_iccid';
    }

    if (values.msisdn && values.iccid) {
      errors.msisdn = 'please_enter_any_one_of_msisdn_or_iccid';
      errors.iccid = 'please_enter_any_one_of_iccid_or_msisdn';
    }

    return errors;
  };
  const [phoneNumberActivationForm, setPhoneNumberActivationForm] =
    useState(phoneNumberInitialValue);

  // Step 2 - Tariff Activation Initial Values & Validations
  const tariffActivationInitialValue = {
    chosenTariffId: 0
  };
  const tariffActivationValidation = Yup.object().shape({
    chosenTariffId: formValidation({
      type: DEFAULT_FIELD_VALIDATION_TYPE.NUMBER,
      required: 'required_field'
    })
  });
  const [tariffActivationForm, setTariffActivationForm] = useState(tariffActivationInitialValue);

  // Step 3 - Personal Data Initial Values & Validations
  const personalDataInitialValue = {
    salutation: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    houseNumber: '',
    street: '',
    zip: '',
    city: '',
    countryCode: '',
    emailAddress: '',
    confirmEmailAddress: '',
    alternateNumberPrefix: '',
    alternateNumber: '',
    identityType: '',
    identityNumber: '',
    nationality: '',
    placeOfIssue: '',
    validUntil: '',
    legitimationMethod: '3',
    hotlinePassword: '',
    confirmHotlinePassword: '',
    title: '0',
    additionalInfo: ''
  };
  const personalDataValidations = Yup.object().shape({
    salutation: Yup.string().required('please_select_salutation'),
    firstName: formValidation({
      required: t('sh_apoc_personal_first-name_error-msg'),
      regex: /^[A-Za-zÄÖÜßäöü-\s]{2,25}$/,
      validErrorMessage: t('sh_apoc_personal_first-name_error-msg')
    }),
    lastName: formValidation({
      required: t('sh_apoc_personal_last-name_error-msg'),
      regex: /^[A-Za-zÄÖÜßäöü-\s]{2,25}$/,
      validErrorMessage: t('sh_apoc_personal_last-name_error-msg')
    }),
    birthDate: formValidation({
      required: t('sh_apoc_personal_birth-date_error-msg')
    }),
    houseNumber: formValidation({
      required: t('sh_apoc_personal_street-nr_error-msg'),
      regex: /^[A-Za-z0-9ÄÖÜßäöü.-]{0,25}$/,
      validErrorMessage: t('sh_apoc_personal_street-nr_error-msg')
    }),
    street: formValidation({
      required: t('sh_apoc_personal_street_error-msg'),
      regex: /^.{0,100}$/,
      validErrorMessage: t('sh_apoc_personal_street_error-msg')
    }),
    zip: formValidation({
      required: t('sh_apoc_personal_zip_error-msg'),
      regex: /^[0-9]{0,25}$/,
      validErrorMessage: t('sh_apoc_personal_zip_error-msg')
    }),
    city: formValidation({
      required: t('sh_apoc_personal_town_error-msg'),
      regex: /^.{0,75}$/,
      validErrorMessage: t('sh_apoc_personal_town_error-msg')
    }),
    countryCode: formValidation({
      required: 'please_enter_countryCode',
      regex: /^.{0,75}$/,
      validErrorMessage: 'please_valid_countryCode'
    }),
    emailAddress: formValidation({
      required: t('sh_apoc_email_err_no_email'),
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      validErrorMessage: t('sh_apoc_email_err_wrong_email')
    }),
    confirmEmailAddress: Yup.string()
      .oneOf([Yup.ref('emailAddress')], 'Both emailAddress need to be the same')
      .required('please_enter_confirm_emailAddress'),
    alternateNumberPrefix: formValidation({
      required: 'please_enter_prefix',
      regex: /^\d{1,6}$/,
      validErrorMessage: 'invalid_prefix'
    }),
    alternateNumber: formValidation({
      required: 'please_enter_alternate_number',
      regex: /^\d{5,20}$/,
      validErrorMessage: 'invalid_number'
    }),
    identityType: Yup.string().required('please_select_nationality'),
    identityNumber: formValidation({
      required: 'please_enter_identity_no',
      regex: /^[A-Za-z0-9.ÄÖÜßäöü-\s]{1,40}$/,
      validErrorMessage: 'please_valid_identity_no'
    }),
    nationality: Yup.string().required('please_select_nationality'),
    placeOfIssue: formValidation({
      required: 'please_enter_place_of_issue',
      regex: /^[A-Za-z0-9.ÄÖÜßäöü-\s]{1,40}$/,
      validErrorMessage: 'please_valid_place_of_issue'
    }),
    validUntil: formValidation({
      required: 'please_select_date'
    }),
    hotlinePassword: formValidation({
      required: t('sh_apoc_personal_hotline-pin_error-msg'),
      regex: /[(A-Z)|(Ö)|(Ä)|(Ü)|(a-z)|(0-9)|(ü)|(ö)|(ä)|(ß)]{4,20}/,
      validErrorMessage: t('sh_apoc_personal_hotline-pin_error-msg')
    }),
    confirmHotlinePassword: Yup.string()
      .oneOf([Yup.ref('hotlinePassword')], 'Both password need to be the same')
      .required('please_enter_confirm_password')
  });
  const [personalDataForm, setPersonalDataForm] = useState(personalDataInitialValue);

  // Step 4 - Login Password Initial Values & Validations
  const loginPasswordInitialValue = {
    cscPassword: '',
    confirmCscPassword: ''
  };
  const loginPasswordValidation = Yup.object().shape({
    cscPassword: formValidation({
      required: 'please_enter_password',
      regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{8,16}$/,
      validErrorMessage: 'please_valid_password'
    }),
    confirmCscPassword: Yup.string()
      .oneOf([Yup.ref('cscPassword')], 'Both password need to be the same')
      .required('please_enter_confirm_password')
  });
  const [loginPasswordForm, setLoginPasswordForm] = useState(loginPasswordInitialValue);

  // Step 5 - Overview Initial Values & Validations
  const overviewInitialValue = {
    voucher: '',
    termsAndConditions: false,
    thirdParty: false,
    acceptedCreditCheck: false,
    brandPartnerCustomMarketing: false,
    employee: false,
    marketingMultibrand: false
  };
  const overviewValidation = Yup.object().shape({
    msisdn: formValidation({
      regex: /^\d{5,20}$/,
      validErrorMessage: 'please_valid_msidn_number'
    }),
    cscPassword: formValidation({
      required: 'please_enter_password',
      regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{8,16}$/,
      validErrorMessage: 'please_valid_password'
    }),
    salutation: Yup.string().required('please_select_salutation'),
    firstName: formValidation({
      required: t('sh_apoc_personal_first-name_error-msg'),
      regex: /^[A-Za-zÄÖÜßäöü-\s]{2,25}$/,
      validErrorMessage: t('sh_apoc_personal_first-name_error-msg')
    }),
    lastName: formValidation({
      required: t('sh_apoc_personal_last-name_error-msg'),
      regex: /^[A-Za-zÄÖÜßäöü-\s]{2,25}$/,
      validErrorMessage: t('sh_apoc_personal_last-name_error-msg')
    }),
    birthDate: formValidation({
      required: t('sh_apoc_personal_birth-date_error-msg')
    }),
    houseNumber: formValidation({
      required: t('sh_apoc_personal_street-nr_error-msg'),
      regex: /^[A-Za-z0-9ÄÖÜßäöü.-]{0,25}$/,
      validErrorMessage: t('sh_apoc_personal_street-nr_error-msg')
    }),
    street: formValidation({
      required: t('sh_apoc_personal_street_error-msg'),
      regex: /^.{0,100}$/,
      validErrorMessage: t('sh_apoc_personal_street_error-msg')
    }),
    emailAddress: formValidation({
      required: t('sh_apoc_email_err_no_email'),
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      validErrorMessage: t('sh_apoc_email_err_wrong_email')
    }),
    identityType: formValidation({
      required: 'please_enter_identityType',
      validErrorMessage: 'please_enter_identityType'
    }),
    identityNumber: formValidation({
      required: 'please_enter_identityNumber',
      validErrorMessage: 'please_enter_identityNumber'
    }),
    nationality: formValidation({
      required: 'please_enter_nationality',
      validErrorMessage: 'please_enter_nationality'
    }),
    placeOfIssue: formValidation({
      required: 'please_enter_placeOfIssue',
      validErrorMessage: 'please_enter_placeOfIssue'
    }),
    validUntil: formValidation({
      required: 'please_enter_validUntil',
      validErrorMessage: 'please_enter_validUntil'
    }),
    termsAndConditions: Yup.boolean().isTrue('please_select_terms_and_conditions'),
    thirdParty: Yup.boolean().isTrue('please_select_terms_and_conditions')
  });
  const [overviewForm, setOverviewForm] = useState(overviewInitialValue);

  // Step 6 - Charging Initial Values & Validations
  const chargingInitialValue = {
    productId: 0,
    productAmount: 0,
    cartName,
    phoneNumber: gettingNumberForGerman(personalDataForm.alternateNumber),
    ipAddress: '203.88.141.230',
    language: 'en',
    paymentMethod: ''
  };
  const chargingValidations = Yup.object().shape({
    productId: formValidation({
      required: 'please_select_payment_product',
      type: DEFAULT_FIELD_VALIDATION_TYPE.NUMBER
    }),
    productAmount: formValidation({
      required: 'please_enter_product_amount',
      type: DEFAULT_FIELD_VALIDATION_TYPE.NUMBER
    }),
    cartName: formValidation({
      required: 'please_enter_cartName',
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    }),
    emailAddress: formValidation({
      required: t('sh_apoc_email_err_no_email'),
      type: DEFAULT_FIELD_VALIDATION_TYPE.EMAIL
    }),
    firstname: formValidation({
      required: t('sh_apoc_personal_first-name_error-msg'),
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    }),
    lastname: formValidation({
      required: t('sh_apoc_personal_last-name_error-msg'),
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    }),
    street: formValidation({
      required: t('sh_apoc_personal_street_error-msg'),
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    }),
    houseNumber: formValidation({
      required: t('sh_apoc_personal_street-nr_error-msg'),
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    }),
    zip: formValidation({
      required: t('sh_apoc_personal_zip_error-msg'),
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    }),
    city: formValidation({
      required: t('sh_apoc_personal_town_error-msg'),
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    }),
    countryCode: formValidation({
      required: 'please_enter_countryCode',
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    }),
    phoneNumber: formValidation({
      required: 'please_enter_phoneNumber',
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    }),
    ipAddress: formValidation({
      required: 'please_enter_ipAddress',
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    }),
    language: formValidation({
      required: 'please_enter_language',
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    }),
    paymentMethod: formValidation({
      required: 'please_select_paymentMethod',
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    })
  });
  const [chargingForm, setChargingForm] = useState(chargingInitialValue);

  // Hooks
  // Data Fetch Functions
  useEffect(() => {
    // navigateAfterPayment();
  }, []);

  // Stepper Functions
  const nextStep = (step) => setCurrentStep(step ? step : currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  // Step 1 On Phone Number Form Submit
  const phoneNumberActivationFormSubmit = async (values) => {
    // Validate sim & get tariff & got to next step;
    try {
      const params = { msisdn: values.msisdn, iccid: values.iccid, puk: values.puk };
      setIsLoading(true);
      setPhoneNumberActivationForm(values);
      onValidateSim(params)
        .then((res) => {
          if (res.status === 200) {
            setBookableTariffs(res.data.bookableTariffs);
            nextStep();
          }
        })
        .finally(() => setIsLoading(false));
      return true;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      return false;
    }
  };

  const navigateAfterPayment = async () => {
    const activateInfo = JSON.parse(await storage.getItem(appStorage.ACTIVATION_INFO)) || {};
    const activationType = (await storage.getItem(appStorage.ACTIVATION_FLOW_TYPE)) || '';
    if (Object.keys(activateInfo).length > 0) {
      navigate(
        activationType === appActivationFlowType.SIM_ACTIVATION
          ? appRoute.ACTIVATION
          : appRoute.NEW_ONLINE_CUSTOMER
      );
    }
  };

  const getCountries = async () => {
    try {
      const { data = [] } = await onDomcountriesCall();
      if (data && data.length > 0) {
        setCountries(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, countryCode: findFirst.id });
        }
      }
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getIdentityTypes = async () => {
    try {
      const { data = [] } = await onIdentityTpesCall();
      if (data && data.length > 0) {
        setIdentityTypes(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, identityType: findFirst.id });
        }
      }
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const getSalutation = async () => {
    try {
      const { data = [] } = await onsalutationCall();
      if (data && data.length > 0) {
        setSalutations(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, salutation: findFirst.id });
          return setTimeout(() => data, 10000);
        }
      }
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const getNationality = async () => {
    try {
      const { data = [] } = await onNationalitiesCall();
      if (data && data.length > 0) {
        setNationality(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, nationality: findFirst.id });
        }
      }
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const getAreaCode = async () => {
    try {
      const { data = [] } = await onAreaCode();
      if (data && data.length > 0) {
        setAreaCode(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, oldNumberPrefix: findFirst.id });
        }
      }
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const getSimProvider = async () => {
    try {
      const { data = [] } = await onSimProvider();
      if (data && data.length > 0) {
        setSimProvider(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, currentProvider: findFirst.id });
          return data;
        }
        return data;
      }
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const getLookup = async () => {
    // await getSalutation();
    await getSalutation();
    await getCountries();
    await getIdentityTypes();
    await getNationality();
    await getAreaCode();
    //getSimProvider();
  };

  // useEffect(() => {
  //   if (personalDataForm.salutation && countries.length === 0) getCountries();
  // }, [personalDataForm.salutation]);
  // useEffect(() => {
  //   if (personalDataForm.countryCode && identityTypes.length === 0) getIdentityTypes();
  // }, [personalDataForm.countryCode]);
  // useEffect(() => {
  //   if (personalDataForm.identityType && nationality.length === 0) getNationality();
  // }, [personalDataForm.identityType]);
  // useEffect(() => {
  //   if (personalDataForm.nationality && areaCode.length === 0) getAreaCode();
  // }, [personalDataForm.nationality]);
  // useEffect(() => {
  //   if (personalDataForm.oldNumberPrefix && simProvider.length === 0) getSimProvider();
  // }, [personalDataForm.oldNumberPrefix]);

  // Step 2 On Tariff Form Submit
  const tariffActivationFormSubmit = (values) => {
    setIsLoading(true);
    setTariffActivationForm(values);
    setIsLoading(false);
    showAlert({ type: appAlert.SUCCESS, message: 'Tariff Selected Successfully..' });
    nextStep();
  };

  // Step 3 On Personal Data Form Submit
  const personalDataFormSubmit = (values) => {
    try {
      // Fill Personal Data & Go to Next Step;
      setIsLoading(true);
      setPersonalDataForm(values);
      setIsLoading(false);
      showAlert({ type: appAlert.SUCCESS, message: 'Data Submitted Successfully..' });
      nextStep();
      return values;
    } catch (error) {
      setIsLoading(false);
      console.log('Submit Error', error);
      return false;
    }
  };

  // Step 5 On Overview Form Submit
  const getPaymentProducts = async () => {
    try {
      const {
        data: { items = [] }
      } = await onPaymentProduct();
      if (items.length > 0) {
        // setPaymentProducts(getLowBalanceTopUp(items));
        const filterProduct = items.filter(
          ({ productType }) => productType === appPaymentProductType.RECURRING
        );
        setPaymentProducts(
          items.filter(({ productType }) => productType === appPaymentProductType.RECURRING)
        );
        setAllPaymentProducts(items);
        setIsLoading(false);
      }
      return items;
    } catch (error) {
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      return false;
    }
  };
  const ssoPaymentToken = async ({ clientId, alphacommHash, msisdn, ...params }) => {
    try {
      const ssoPaymentTokenParams = {
        clientId,
        hashCode: alphacommHash,
        language: 'de',
        msisdn,
        pageId: 'home'
      };
      const { data = {}, success } = await onSsoPaymentToken(ssoPaymentTokenParams);
      if (data && success) {
        setPaymentAuthData(ssoPaymentTokenParams);
        setTimeout(() => {
          getPaymentProducts();
        }, 100);
      }
      return data;
    } catch (error) {
      return false;
    }
  };
  const overviewFormSubmit = async ({
    msisdn,
    puk,
    mnp,
    currentProvider,
    type,
    oldNumber,
    oldNumberPrefix,
    chosenTariffId,
    cscPassword,
    hotlinePassword,
    birthDate,
    emailAddress,
    firstName,
    lastName,
    // salutation,
    title,
    additionalInfo,
    city,
    countryCode,
    houseNumber,
    street,
    zip,
    alternateNumber,
    alternateNumberPrefix,
    acceptedCreditCheck,
    brandPartnerCustomMarketing,
    employee,
    marketingMultibrand,
    termsAndConditions,
    thirdParty
    // identityNumber,
    // identityType,
    // legitimationMethod,
    // nationality: identityNationality,
    // placeOfIssue,
    // validUntil
  }) => {
    try {
      const updatedMsisdn = msisdn.msisdn === 'undefined' ? '' : msisdn.msisdn;
      const updatedPuk = puk.puk === 'undefined' ? '' : puk.puk;
      const params = mnp
        ? {
            activationData: {
              msisdn: updatedMsisdn,
              puk: updatedPuk
            },
            activationMNPData: {
              currentProvider,
              mnp,
              oldNumber: {
                number: oldNumber,
                prefix: oldNumberPrefix.toString()
              },
              type
            },
            chosenTariffId,
            cscPassword,
            hotlinePassword,
            identityDocument: STATIC_ACTIVATION_VARIABLE.IDENTITY_DOCUMENT, // {
            //   identityNumber,
            //   identityType: Number(identityType),
            //   legitimationMethod: Number(legitimationMethod),
            //   nationality: Number(identityNationality),
            //   placeOfIssue,
            //   validUntil
            // },
            personalData: {
              address: {
                additionalInfo,
                city,
                countryCode: Number(countryCode),
                houseNumber,
                street,
                zip
              },
              alternatePhoneNumber: {
                number: alternateNumber,
                prefix: alternateNumberPrefix
              },
              birthDate,
              emailAddress,
              firstName,
              flags: {
                acceptedCreditCheck,
                brandPartnerCustomMarketing,
                employee,
                marketingMultibrand,
                termsAndConditions,
                thirdParty
              },
              lastName,
              salutation: STATIC_ACTIVATION_VARIABLE.SALUTATION, // Number(salutation),
              title: Number(title)
            },
            uuid: generateUUID()
          }
        : {
            activationData: {
              msisdn: updatedMsisdn,
              puk: updatedPuk
            },
            chosenTariffId,
            cscPassword,
            hotlinePassword,
            identityDocument: STATIC_ACTIVATION_VARIABLE.IDENTITY_DOCUMENT, // {
            //   identityNumber,
            //   identityType: Number(identityType),
            //   legitimationMethod: Number(legitimationMethod),
            //   nationality: Number(identityNationality),
            //   placeOfIssue,
            //   validUntil
            // },
            personalData: {
              address: {
                additionalInfo,
                city,
                countryCode: Number(countryCode),
                houseNumber,
                street,
                zip
              },
              alternatePhoneNumber: {
                number: alternateNumber,
                prefix: alternateNumberPrefix
              },
              birthDate,
              emailAddress,
              firstName,
              flags: {
                acceptedCreditCheck,
                brandPartnerCustomMarketing,
                employee,
                marketingMultibrand,
                termsAndConditions,
                thirdParty
              },
              lastName,
              salutation: STATIC_ACTIVATION_VARIABLE.SALUTATION, // Number(salutation),
              title: Number(title)
            },
            uuid: generateUUID()
          };
      const {
        data = {},
        data: { signupId: clientId = '', alphacommHash = '', postidentUrl = '' }
      } = await onActivateSim(params);
      if (data) {
        setActivatedSimData({ signupId: clientId, alphacommHash, postidentUrl });
        // console.log('Sim Activated --- ', JSON.stringify(data));
        showAlert({ type: appAlert.SUCCESS, message: 'Sim Activated Successfully..' });
        showAlert({ type: appAlert.INFO, message: 'Process with payment' });
        await storage.setItem(appStorage.ACTIVATION_DATA, JSON.stringify(data));
        await storage.setItem(appStorage.POSTIDENT, postidentUrl);
        setPostIdentUrl(postidentUrl);
        await ssoPaymentToken({
          clientId,
          alphacommHash,
          msisdn
        });
        // TODO : need to add storage from app config
        setIsLoading(false);
        showAlert({ type: appAlert.SUCCESS, message: 'Sim Activated Successfully..' });
        nextStep();
      }

      setPersonalDataForm(params);
      setChargingForm({
        ...chargingForm,
        emailAddress,
        firstName,
        lastName,
        street,
        houseNumber,
        zip,
        city,
        countryCode,
        phoneNumber: gettingNumberForGerman(alternateNumber),
        ipAddress: '203.88.141.230',
        language: 'en'
      });

      setIsLoading(false);
      showAlert({ type: appAlert.SUCCESS, message: 'Data Submitted Successfully..' });
      nextStep();
      return params;
    } catch (error) {
      setIsLoading(false);
      console.log('onActivateSim error', error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      return false;
    }
  };

  // Step 4 On Login Password Form Submit
  const loginPasswordFormSubmit = (values) => {
    setIsLoading(true);
    setPersonalDataForm({
      ...personalDataForm,
      // msisdn: phoneNumberActivationForm.msisdn,
      cscPassword: values.cscPassword
    });
    setOverviewForm({
      ...overviewForm,
      msisdn: personalDataForm.msisdn,
      chosenTariffId: tariffActivationForm.chosenTariffId
    });
    setIsLoading(false);
    nextStep();
  };

  const processWithPostIdent = async () => {
    const postidentURL = await storage.getItem(appStorage.POSTIDENT);
    if (postIdentUrl) {
      window.location.href = postIdentUrl;
    } else if (postidentURL) {
      window.location.href = postidentURL;
    }
  };

  // Step 5 On Overview Form Submit
  /*   const overviewFormSubmit = async () => {
      //------------------------------------------------------------------
      // Uncomment Code to Activate Sim & Go to Next Step of Products & Payment
      // -----------------------------------------------------------------
      // const { data = {}, data: {signupId: clientId='', alphacommHash=''} success } = await onActivateSim(params);
      // if (data && success) {
      //   console.log('Sim Activated --- ', JSON.stringify(data));
      //   await ssoPaymentToken({
      //     clientId,
      //     alphacommHash,
      //     msisdn
      //   });
      //   // TODO : need to add storage from app config
      //   setIsLoading(false);
      //   showAlert({ type: appAlert.SUCCESS, message: 'Sim Activated Successfully..' });
      //   nextStep();
      // }
      //------------------------------------------------------------------
      // Dummy Sim Activativation to Go to Next Step
      setIsLoading(true);
      await ssoPaymentToken({
        clientId: '1100000121',
        alphacommHash:
          '0f2c34aaa83d23c8767d3964b7ea1d234751215b89bc464bd8adc616cda2f6e2563163031d61a378dfd9284dbbb413b922be48f6440e28c2430b1e6eea0008e7',
        msisdn: '013130109490'
      });
      setIsLoading(false);
      console.log('Activation Data: ', params);
      nextStep();
    }; */

  // Step 6 On Charging Form Submit
  const chargingFormSubmit = async (values) => {
    try {
      // Select payment products & got to next step;
      setChargingForm(values);
      setIsLoading(true);
      // setSelectedPaymentProductId(values.selectedPaymentProduct);
      const selectedProduct = allPaymentProducts.find(
        (p) => p.id === values.selectedPaymentProduct
      );

      if (selectedProduct) {
        const optionTypeValue = await getLowBalanceTopUpWithoutAmount(allPaymentProducts)
          .optionType; // "directTopup",
        const lowBalanceLimit = await getLowBalanceTopUpWithoutAmount(allPaymentProducts).minAmount; // 5
        const phoneNumber = '015147470500'; // personalDataForm.updatedMsisdn;

        const productParams = {
          items: [
            {
              productId: selectedProduct.id,
              option: {
                optionType: optionTypeValue,
                phoneNumber, // simActivationDetails?.activationData?.msisdn,
                amount: otherAmount || selectedProduct.amount,
                lowBalanceOption: {
                  lowBalanceLimit,
                  tariffEnabled: false
                }
              }
            }
          ]
        };
        const {
          data: { cartName: responseCartName = false },
          success
        } = await onPaymentAddToCart(productParams);
        if (responseCartName && success) {
          setCartName(responseCartName);
          setChargingForm({ ...chargingForm, cartName: responseCartName });
          setIsLoading(false);
          showAlert({ type: appAlert.SUCCESS, message: 'Product Selected Successfully..' });
          setShowAddress(true);
        }
        setIsLoading(false);
      }

      // Payment Checkout Address In Background
      const checkoutAddressParams = {
        cartName: values.cartName,
        deliveryAddress: {
          emailAddress: values.emailAddress,
          firstname: values.firstName,
          lastname: values.lastName,
          street: values.street,
          houseNumber: values.houseNumber,
          zip: values.zip,
          city: values.city,
          countryCode: 'DE',
          phoneNumber: values.phoneNumber,
          ipAddress: values.ipAddress,
          language: values.language
        }
      };

      const { data: checkoutAddressData = {}, success: checkoutAdressSuccess } =
        await onPaymentCheckoutAddress(checkoutAddressParams);
      if (checkoutAddressData && checkoutAdressSuccess) {
        const {
          checkoutAddressData: { paymentMethods = {} }
        } = await onPaymentMethod();
        // eslint-disable-next-line prefer-const
        let payments = [];
        if (paymentMethods) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(paymentMethods)) {
            if (value.paymentTokenEnabled) {
              payments.push({ name: key, ...value });
            }
          }
          setPaymentMethod(payments);
          setIsLoading(false);
        }
        showAlert({ type: appAlert.SUCCESS, message: 'Checkout Verified Successfully..' });
        nextStep();
      }

      const paymentParams = {
        cartName,
        paymentMethod: values.paymentMethod,
        returnUrl: env.REACT_APP_SHOPPING_RETURN_URL,
        savePayment: true
      };

      const { data: paymentData = {}, success: paymentSuccess } = await onCheckoutPaymentStart(
        paymentParams
      );
      if (paymentData && paymentSuccess) {
        sessionStorage.setItem(appStorage.CART_NAME, cartName);
        sessionStorage.setItem(appStorage.ORDER_NUMBER, paymentData.orderNumber);
        setIsLoading(false);
        showAlert({ type: appAlert.SUCCESS, message: 'Please Process With The Payment..' });
        window.open(paymentData.payerUrl, '_blank');
      }
      return paymentData;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      return false;
    }
  };

  const onPosPurchase = () => {
    setActivationType(appActivationFlowType.NEW_ONLINE);
    setCurrentStep(0);
  };

  const onOldCustomerPurchase = () => {
    setAvtivationType(appActivationFlowType.SIM_ACTIVATION);
  };

  // const onStepFiveSubmit = async (values) => {
  //   // Checkout address & getch payment methods & got to next step;
  //   try {
  //     setIsLoading(true);
  //     setStepFiveForm(values);

  //     const params = {
  //       cartName: values.cartName,
  //       deliveryAddress: {
  //         emailAddress: values.emailAddress,
  //         firstname: values.firstName,
  //         lastname: values.lastName,
  //         street: values.street,
  //         houseNumber: values.houseNumber,
  //         zip: values.zip,
  //         city: values.city,
  //         countryCode: 'DE',
  //         phoneNumber: values.phoneNumber,
  //         ipAddress: values.ipAddress,
  //         language: values.language
  //       }
  //     };

  //     const { data = {}, success } = await onPaymentCheckoutAddress(params);
  //     if (data && success) {
  //       const {
  //         data: { paymentMethods = {} }
  //       } = await onPaymentMethod();
  //       // eslint-disable-next-line prefer-const
  //       let payments = [];
  //       if (paymentMethods) {
  //         // eslint-disable-next-line no-restricted-syntax
  //         for (const [key, value] of Object.entries(paymentMethods)) {
  //           if (value.paymentTokenEnabled) {
  //             payments.push({ name: key, ...value });
  //           }
  //         }
  //         setPaymentMethod(payments);
  //         setIsLoading(false);
  //       }
  //       showAlert({ type: appAlert.SUCCESS, message: 'Checkout Verified Successfully..' });
  //       nextStep();
  //     }
  //     return data;
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log(error);
  //     showAlert({ type: appAlert.ERROR, message: `Error in request. ${error.error[0].messageBody}` });
  //     return false;
  //   }
  // };

  // const onStepSixSubmit = async (values) => {
  //   // Process payment methods & store all details in the storage;
  //   try {
  //     setIsLoading(true);
  //     const inputParams = {
  //       cartName,
  //       paymentMethod: values.paymentMethod,
  //       returnUrl: env.REACT_APP_SHOPPING_RETURN_URL,
  //       savePayment: true
  //     };

  //     const { data = {}, success } = await onCheckoutPaymentStart(inputParams);
  //     if (data && success) {
  //       sessionStorage.setItem(appStorage.CART_NAME, cartName);
  //       sessionStorage.setItem(appStorage.ORDER_NUMBER, data.orderNumber);
  //       setIsLoading(false);
  //       showAlert({ type: appAlert.SUCCESS, message: 'Please Process With The Payment..' });
  //       window.open(data.payerUrl, '_blank');
  //     }
  //     return data;
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log(error);
  //     showAlert({ type: appAlert.ERROR, message: `Error in request. ${error.error[0].messageBody}` });
  //     return false;
  //   }
  // };

  // We wrap it in a useMemo for performance reason

  const contextPayload = useMemo(
    () => ({
      // States
      currentStep,
      setCurrentStep,
      editStep,
      setEditStep,
      isLoading,
      setIsLoading,
      numberPortingScreen,
      setNumberPortingScreen,
      bookableTariffs,
      setBookableTariffs,
      paymentProducts,
      setPaymentProducts,
      allPaymentProducts,
      setAllPaymentProducts,
      otherAmount,
      setOtherAmount,
      showAddress,
      setShowAddress,
      cartName,
      setCartName,
      orderNumber,
      setOrderNumber,
      paymentUrl,
      setPaymentUrl,
      postIdentUrl,
      setPostIdentUrl,
      paymentMethod,
      setPaymentMethod,
      selectedTariffId,
      setSelectedTariffId,
      simProvider,
      countries,
      setCountries,
      identityTypes,
      setIdentityTypes,
      nationality,
      setNationality,
      areaCode,
      setAreaCode,
      salutations,
      setSalutations,
      activationInfo,
      setActivationInfo,
      activatedSimData,
      setActivatedSimData,

      activationType,
      setActivationType,

      // Form States
      phoneNumberActivationForm,
      setPhoneNumberActivationForm,
      tariffActivationForm,
      setTariffActivationForm,
      personalDataForm,
      setPersonalDataForm,
      overviewForm,
      setOverviewForm,
      chargingForm,
      setChargingForm,
      loginPasswordForm,

      // Form Initial States & Validations
      phoneNumberInitialValue,
      phoneNumberValidations,
      phoneNumberValidationsWithMNP,
      tariffActivationInitialValue,
      tariffActivationValidation,
      personalDataInitialValue,
      personalDataValidations,
      loginPasswordValidation,
      overviewInitialValue,
      overviewValidation,
      chargingInitialValue,
      chargingValidations,

      // Functions
      phoneNumberActivationFormSubmit,
      tariffActivationFormSubmit,
      personalDataFormSubmit,
      loginPasswordFormSubmit,
      overviewFormSubmit,
      chargingFormSubmit,
      getLookup,
      processWithPostIdent,
      onOldCustomerPurchase,
      onPosPurchase,

      // Stepper Functions
      nextStep,
      prevStep,

      // Custom Validation for step one
      PhoneNumberCustomValidation
    }),
    [
      // States
      currentStep,
      setCurrentStep,
      editStep,
      setEditStep,
      isLoading,
      setIsLoading,
      numberPortingScreen,
      setNumberPortingScreen,
      bookableTariffs,
      setBookableTariffs,
      paymentProducts,
      setPaymentProducts,
      allPaymentProducts,
      setAllPaymentProducts,
      otherAmount,
      setOtherAmount,
      showAddress,
      setShowAddress,
      cartName,
      setCartName,
      orderNumber,
      setOrderNumber,
      paymentUrl,
      setPaymentUrl,
      postIdentUrl,
      setPostIdentUrl,
      activationInfo,
      setActivationInfo,
      activatedSimData,
      setActivatedSimData,

      activationType,
      setActivationType,

      // Form States
      phoneNumberActivationForm,
      setPhoneNumberActivationForm,
      tariffActivationForm,
      setTariffActivationForm,
      personalDataForm,
      setPersonalDataForm,
      overviewForm,
      setOverviewForm,
      chargingForm,
      setChargingForm,
      loginPasswordForm,

      // Form Initial States & Validations
      phoneNumberInitialValue,
      phoneNumberValidations,
      phoneNumberValidationsWithMNP,
      tariffActivationInitialValue,
      tariffActivationValidation,
      personalDataInitialValue,
      personalDataValidations,
      loginPasswordValidation,
      overviewInitialValue,
      overviewValidation,
      chargingInitialValue,
      chargingValidations,

      // Functions
      phoneNumberActivationFormSubmit,
      tariffActivationFormSubmit,
      personalDataFormSubmit,
      loginPasswordFormSubmit,
      overviewFormSubmit,
      chargingFormSubmit,
      getLookup,
      processWithPostIdent,
      onOldCustomerPurchase,
      onPosPurchase,

      // Stepper Functions
      nextStep,
      prevStep,

      // Custom Validation for step one
      PhoneNumberCustomValidation
    ]
  );

  // useEffect(() => {
  //   console.log(activationInfo);
  // }, [activationInfo]);

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <ActivationContext.Provider value={contextPayload}>
      <ActivationTariffContextProvider
        onFormUpdate={(values) => setActivationInfo({ ...activationInfo, ...values })}
        {...{ nextStep, activationType, env, activatedSimData, setActivatedSimData }}
      >
        <SimValidationContextProvider
          onFormUpdate={(values) => setActivationInfo({ ...activationInfo, ...values })}
          {...{ nextStep, activationType, onPosPurchase, env, activationInfo }}
        >
          <EmailVerificationContextProvider
            onFormUpdate={(values) => setActivationInfo({ ...activationInfo, ...values })}
            {...{
              nextStep,
              activationType,
              activationInfo,
              currentStep,
              setCurrentStep,
              editStep,
              setEditStep
            }}
          >
            <NumberPortingContextProvider
              onFormUpdate={(values) => setActivationInfo({ ...activationInfo, ...values })}
              {...{ nextStep, activationType, activationInfo }}
            >
              <PersonalDataContextProvider
                onFormUpdate={(values) => setActivationInfo({ ...activationInfo, ...values })}
                {...{ nextStep, activationType, activationInfo }}
              >
                <ActivationAutoTopUpContextProvider
                  onFormUpdate={(values) => setActivationInfo({ ...activationInfo, ...values })}
                  {...{ nextStep, activationType, env, activationInfo }}
                >
                  <PaymentContextProvider
                    onFormUpdate={(values) => setActivationInfo({ ...activationInfo, ...values })}
                    {...{ nextStep, activationType, env, activationInfo, setCurrentStep }}
                  >
                    <SimActivationContextProvider
                      onFormUpdate={(values) => setActivationInfo({ ...activationInfo, ...values })}
                      {...{
                        nextStep,
                        activationType,
                        env,
                        activationInfo,
                        activatedSimData,
                        setActivatedSimData
                      }}
                    >
                      {children}
                    </SimActivationContextProvider>
                  </PaymentContextProvider>
                </ActivationAutoTopUpContextProvider>
              </PersonalDataContextProvider>
            </NumberPortingContextProvider>
          </EmailVerificationContextProvider>
        </SimValidationContextProvider>
      </ActivationTariffContextProvider>
    </ActivationContext.Provider>
  );
}

ActivationContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useActivation = () => useContext(ActivationContext);

export default ActivationContext;
