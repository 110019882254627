import React from 'react';
import { Link } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { useTariff } from '@context/MobileOne';
import { Button } from '@core/Inputs';
import { Card, CardBody, CardText, Col, Container, Row } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';
import { TariffOverView } from '../TariffOverView';

export function ChangeTariff() {
  // Contexts
  const {
    bookableTariff,
    setBookableTariff,
    activeTariff,
    setActiveTariff,
    changeTariff,
    setChangeTariff
  } = useTariff();
  const { t } = useStaticContent();

  return (
    <Container>
      {!changeTariff ? (
        <>
          <Row className="d-flex align-items-center">
            <Col md="4">
              <Link to={appRoute.TARIFF_OPTION}>
                <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
              </Link>
            </Col>
            <Col md="8">
              <h4 className="mt-3 mb-3">{t('sh_sm_switch_headline')}</h4>
            </Col>
          </Row>
          <Row>
            <Col md={{ offset: 4, span: 6 }}>
              {activeTariff.map((at) => (
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="d-block">
                        <h6>{t('sh_sm_switch_subheadline1')}</h6>
                        <h4>{at.tariff.name}</h4>
                      </span>
                      <Button
                        variant="link"
                        onClick={() => {
                          /* eslint-disable */
                          at.detailedView = !at.detailedView ? true : false;
                          setActiveTariff([...activeTariff]);
                        }}
                      >
                        {t('sh_sm_switch_details1')}
                        <i
                          className={at.detailedView ? 'bi bi-chevron-up' : 'bi bi-chevron-down'}
                          style={{ marginLeft: '.75rem' }}
                        />
                      </Button>
                    </div>
                    {at.detailedView && (
                      <div>
                        {at.tariff.additionalInfo?.bullets.map((item, key) => (
                          <div key={key}>
                            <i className="bi bi-check-circle" /> &nbsp; {item.text} <br />
                          </div>
                        ))}
                        <div className="d-flex" style={{ alignItems: 'center' }}>
                          <div className="d-flex m-2" style={{ alignItems: 'center' }}>
                            <h3>{at.tariff.starterPackPrice}&euro;</h3> /{' '}
                            <CardText>
                              {at.tariff.duration}&nbsp;{at.tariff.durationUnit}
                            </CardText>
                          </div>
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
              ))}

              {bookableTariff.map((at) => (
                <Card sx={{ marginTop: '.75rem' }}>
                  <CardBody>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="d-block">
                        <h6>{t('sh_sm_switch_subheadline2')}</h6>
                        <h4>{at.name}</h4>
                      </span>
                      <Button
                        variant="link"
                        onClick={() => {
                          /* eslint-disable */
                          at.detailedView = !at.detailedView ? true : false;
                          setBookableTariff([...bookableTariff]);
                        }}
                      >
                        {t('sh_sm_switch_details1')}
                        <i
                          className={at.detailedView ? 'bi bi-chevron-up' : 'bi bi-chevron-down'}
                          style={{ marginLeft: '.75rem' }}
                        />
                      </Button>
                    </div>
                    {at.detailedView && (
                      <div>
                        {at.additionalInfo?.bullets.map((item, key) => (
                          <div key={key}>
                            <i className="bi bi-check-circle" /> &nbsp; {item.text} <br />
                          </div>
                        ))}
                        <div className="d-flex" style={{ alignItems: 'center' }}>
                          <div className="d-flex m-2" style={{ alignItems: 'center' }}>
                            <h3>{at.starterPackPrice}&euro;</h3> /{' '}
                            <CardText>
                              {at.duration}&nbsp;{at.durationUnit}
                            </CardText>
                          </div>
                        </div>
                        <Button variant="primary" onClick={() => setChangeTariff(at)}>
                          {t('sh_sm_switch_choose')}
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              ))}
            </Col>
          </Row>
          <Row>
            <Col md={{ offset: 4, span: 6 }}>
              <p dangerouslySetInnerHTML={{ __html: t('sh_sm_switch_info1') }} />
              <p dangerouslySetInnerHTML={{ __html: t('sh_sm_switch_info2') }} />
            </Col>
          </Row>
        </>
      ) : (
        <TariffOverView />
      )}
    </Container>
  );
}

export default ChangeTariff;
