import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import './style.scss';
import Accordion from '@core/Layout/Accordion';
import { Link } from 'react-router-dom';
import { appImages, appRoute, appSortOrder, deviceRegex } from '@utils/globalConstant';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_LINK, UTAG_VIEWS } from '@utils/utag';
import { useLayout } from '@context/Utils';

export function FAQ() {
  // States
  const [isIosDevice, setIsIosDevice] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);

  const { setLightHeader } = useLayout();
  const { faqStaticData = [], t, staticContentData } = useStaticContent();
  // const [faqList, setFaqList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [topFaqList, setTopFaqList] = useState([]);

  const { setMetaTags } = useMetaTag();

  useEffect(() => {
    setLightHeader(false);
    let deviceInfo = navigator.userAgent;
    setIsIosDevice(deviceRegex.ISIOS.test(deviceInfo));
    setIsAndroidDevice(deviceRegex.ISANDRIOD.test(deviceInfo));

    return () => {
      setIsIosDevice(false);
      setIsAndroidDevice(false);
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.FAQ_MOUNTING);
    }
  }, [window.utag]);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_faqus_hilfe_meta_title'),
      description: t('sh_web_page_faqus_hilfe_meta_description'),

      twitterTitle: t('sh_web_page_faqus_hilfe_meta_title'),
      twitterDescription: t('sh_web_page_faqus_hilfe_meta_description'),

      ogTitle: t('sh_web_page_faqus_hilfe_meta_title'),
      ogDescription: t('sh_web_page_faqus_hilfe_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  useEffect(() => {
    if (staticContentData && staticContentData.sh_faqSortList && faqStaticData) {
      const sortCatList = staticContentData.sh_faqSortList.find(
        (list) => list.listName === appSortOrder.FAQ_MAIN_CAT_LIST
      );
      const sortTopQnAList = staticContentData.sh_faqSortList.find(
        (list) => list.listName === appSortOrder.FAQ_MAIN_TOP_QNA
      );

      if (sortCatList && sortCatList.listOrder) {
        const catList = sortCatList.listOrder
          .map((item) => ({
            ...item,
            ...faqStaticData.find((faq) => `${faq.categoryID}` === `${item.id}`)
          }))
          .filter((item) => item.id && item.category && item.categoryID)
          .map(({ id, categoryID, category }) => ({
            id,
            categoryID,
            category
          }));

        setCategoryList(catList);
      }

      if (sortTopQnAList && sortTopQnAList.listOrder) {
        const faqList = sortTopQnAList.listOrder
          .map((item) => ({
            ...item,
            ...faqStaticData.find((faq) => `${faq.internalID}` === `${item.id}`)
          }))
          .map((faqItem) => ({
            title: faqItem.question,
            // component: <div dangerouslySetInnerHTML={{ __html: faqItem.answer }}></div>,
            component: `${faqItem.answer}`,
            internalID: faqItem.internalID,
            categoryID: faqItem.categoryID
          }));
        setTopFaqList(faqList);
      }
    }
    // return () => {
    //   setCategoryList([]);
    //   setTopFaqList([]);
    // };
  }, [staticContentData, faqStaticData]);

  return (
    <>
      {/* <section className="frequently-asked-section pt-10 pb-5 pt-5 pt-md-0 position-relative"> */}
      <section className="frequently-asked-section position-relative pt-5 pt-md-0 pb-56 pb-lg-80">
        {/* <div className="container mb-lg-5 pt-10 pt-5 my-4 mb-md-0 mt-4 mt-md-5 top-cnt"> */}
        <div className="container pt-10 pt-5 mb-md-0 mt-4 mt-md-5 top-cnt">
          <div className="row mt-md-5">
            {/* <div className="col-12 orange-gray-bubble"></div> */}
            <div className="col-12 col-lg-4 offset-lg-1 col-md-4">
              <h1
                className="primary-color-text mb-4 mb-sm-0"
                dangerouslySetInnerHTML={{ __html: t('sh_tab_5_faq_h1') }}
              />
            </div>
            <div className="col-12 col-md-8 col-lg-7 mb-lg-5 pt-md-5 mt-md-5">
              <Accordion items={topFaqList} />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="frequently-topic-section bg-offWhite-color py-5 gray-shade-3 position-relative mt-5"> */}
      <section className="frequently-topic-section bg-offWhite-color position-relative py-56 py-lg-80">
        {/* <div className="container pb-5"> */}
        <div className="container">
          {/* <div className="row pb-lg-5"> */}
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
              <h4
                className="primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_faq_overview_subheadline') }}
              />
            </div>
            {/* <div className="col-12 col-md-8 col-lg-6 mt-md-5 mb-5 mb-md-0"> */}
            <div className="col-12 col-md-8 col-lg-6 mt-md-5">
              {/* <ul className="list-unstyled category-link my-md-5"> */}
              <ul className="list-unstyled category-link">
                {categoryList.map((list, index) => (
                  <li className="my-3" key={`${index + 1}`}>
                    <Link
                      className="label-medium primary-color-text d-flex justify-content-between align-items-center"
                      to={appRoute.FAQ_TOPIC(list.categoryID)}
                    >
                      <div dangerouslySetInnerHTML={{ __html: list.category }} />{' '}
                      <i className="fa fa-arrow-right" />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="questions-clarified-section position-relative bg-primary-color friends-purple-shade-2 pt-5 pb-2 py-md-5 purple-curve-shade-2"> */}
      <section className="position-relative bg-primary-color py-56 py-lg-80">
        <div className="container">
          <div className="row">
            {/* <div className="col-12 offset-md-1 col-md-7 col-lg-6 offset-lg-1 col-xl-5 offset-xl-1  pb-md-5"> */}
            <div className="col-12 offset-md-1 col-md-7 col-lg-6 offset-lg-1 col-xl-5 offset-xl-1">
              <h4
                className="offWhite-color-text mb-4 pb-2"
                dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_ques_subtext1') }}
              />
              <div
                className="body-large-regular offWhite-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_ques_subtext2') }}
              />
              <div className="d-flex flex-wrap flex-column flex-sm-row pt-2">
                {isIosDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    target="_blank"
                    href={t('sh_ap_appstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </a>
                )}
                {isAndroidDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    target="_blank"
                    href={t('sh_ap_playstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </a>
                )}
                {!isAndroidDevice && !isIosDevice && (
                  <>
                    <span className="pe-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 border-0">
                      <img className="mx-100 grow" src={appImages.qrcode} alt="Qrcode" />
                    </span>
                    <a
                      className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      target="_blank"
                      href={t('sh_ap_playstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                    </a>
                    <a
                      className="pe-0 ps-0 pe-sm-2 pe-lg-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      target="_blank"
                      href={t('sh_ap_appstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <br />
      <br className="d-block d-lg-none d-xl-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" /> */}
    </>
  );
}

export default FAQ;
