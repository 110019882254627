import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

// import { useActivation } from '@context/MobileOne';
import { useLayout } from '@context/Utils';
import { TariffPicker } from '@part/Tariff/Catalogue/Picker';
import Accordion from '@core/Layout/Accordion';
import { Image } from '@core/Utils/Image';
import {
  appImages,
  appTariff,
  appRoute,
  deviceRegex,
  appSecBgColorTypes,
  homePageModals
  // appActivationFlowType
} from '@utils/globalConstant';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { Section, StickyLayer } from '@core/Layout';
import PinkBoxModal from './PinkBoxModal';
import './style.scss';
import YellowTeaserFootnote from './YellowTeaserFootnote';

export function Home() {
  // States
  const [isIosDevice, setIsIosDevice] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);
  const [homeVideo, setHomeVideo] = useState({});

  // constant
  const tariffSectionId = 'tariff-banner-img';

  // Context
  const { t, staticContentData } = useStaticContent();
  const { setLightHeader, isTealuimLoaded } = useLayout();
  const { setMetaTags } = useMetaTag();
  // const { activationInfo, setActivationType } = useActivation();
  const { activeTariff } = useActivationTariff();
  const navigate = useNavigate();
  const { screenSize } = useLayout();
  const { modal, setModal } = useActivationTariff();

  // Functions
  const getHomeVideo = () => {
    if (staticContentData) {
      const videos = staticContentData.media_video;
      const video = videos.find(({ name }) => name === 'sh_apoc_home_video');
      setHomeVideo(video || {});
    }
  };

  // Hooks
  useEffect(() => {
    setLightHeader(false);

    let deviceInfo = navigator.userAgent;
    setIsIosDevice(deviceRegex.ISIOS.test(deviceInfo));
    setIsAndroidDevice(deviceRegex.ISANDRIOD.test(deviceInfo));
    return () => {
      setLightHeader(false);
    };
  }, []);

  useEffect(() => {
    if (isTealuimLoaded) {
      window.utag.view(UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.HOME_MOUNTING.ROOT);
    }
  }, [isTealuimLoaded]);

  useEffect(() => {
    if (staticContentData) {
      const tariffModal = document.querySelector('#modal-btn');
      if (tariffModal) {
        tariffModal.addEventListener('click', () => {
          setModal(homePageModals.PINK_BOX_MODAL);
        });
      }
    }
    setMetaTags({
      title: t('sh_web_page_homepage_meta_title'),
      description: t('sh_web_page_homepage_meta_description'),

      twitterTitle: t('sh_web_page_homepage_meta_title'),
      twitterDescription: t('sh_web_page_homepage_meta_description'),

      ogTitle: t('sh_web_page_homepage_meta_title'),
      ogDescription: t('sh_web_page_homepage_meta_description')
    });
    getHomeVideo();

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  const topFaqList = [
    { title: t('sh_apoc_tarif_overview_ques1'), component: t('sh_apoc_tarif_overview_answer1') },
    { title: t('sh_apoc_tarif_overview_ques2'), component: t('sh_apoc_tarif_overview_answer2') },
    { title: t('sh_apoc_tarif_overview_ques3'), component: t('sh_apoc_tarif_overview_answer3') },
    { title: t('sh_apoc_tarif_overview_ques4'), component: t('sh_apoc_tarif_overview_answer4') }
  ];

  return (
    <>
      {/* <section className="share-mobile-top-section position-relative pt-10 pt-md-5 pt-xs-5 pb-5">
      </section> */}
      <section
        className="pt-10 pt-md-5 pt-xs-5 pb-5 top-section"
        // id={screenSize > 768 ? tariffSectionId : ''}
        // bgColorCode={appSecBgColorTypes.WHITE}
        // bottomElementType={appSecBottomElementTypes.TYPE_3}
      >
        <div className={`container pt-5 ${!modal ? 'z-1' : ''}`}>
          <div className="row">
            <div className="col-12 col-md-5 col-xl-7 pt-5 pt-sm-0">
              {/* <div
                className={`${
                  screenSize <= 992 && screenSize >= 768
                    ? ''
                    : 'd-flex justify-content-between mb-3'
                }`}
              >
               {screenSize <= 992 && screenSize >= 768 && (
                  <div className="d-flex upper-img justify-content-end py-4">
                    <img
                      className="img-5g"
                      role="button"
                      onClick={() => {
                        setModal(homePageModals.YELLOW_TEASER_FOOTNOTE);
                      }}
                      src={appImages.yellowTeaser}
                      alt="Bubble Image"
                    />
                  </div>
                )} */}
              <h1 className="home-heading mb-sm-3 mt-xxl-3 pb-2 ms-0 mt-md-0 pt-md-1 pt-3">
                {t('sh_apoc_tarif_overview_h1')}
              </h1>
              {/* {!(screenSize <= 992 && screenSize >= 768) && (
                  <div className="d-flex image-div justify-content-end align-items-start">
                    <img
                      className="img-5g"
                      role="button"
                      onClick={() => {
                        setModal(homePageModals.YELLOW_TEASER_FOOTNOTE);
                      }}
                      src={appImages.yellowTeaser}
                      alt="Bubble Image"
                    />
                  </div>
                )} 
              </div> */}
              <div
                className="w-100 primary-color-text body-large-regular pe-xl-5 mr-0"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_step1/9_text1') }}
              />
              <div className="col-12 d-sm-block d-md-none">
                <button
                  className="btn btn-secondary cmn-home-btn mt-2 z-0"
                  onClick={() => {
                    if (window && window.utag) {
                      activeTariff.id === appTariff.FLAT_M
                        ? window.utag.link(
                            UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                              .CONTINUE_TARIFF_BUTTON_1_ON_CLICK
                          )
                        : window.utag.link(
                            UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                              .CONTINUE_TARIFF_BUTTON_1_ON_CLICK
                          );
                    }

                    document
                      .getElementById('tariff-banner-img')
                      .scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif_button4') }}
                  />
                </button>
              </div>
            </div>
            {screenSize > 768 && (
              <div
                className="col-12 col-md-6 col-lg-6 offset-lg-1 offset-xl-0 col-xl-5 mt-5 position-relative"
                // id={tariffSectionId}
              >
                <div className="position-absolute">
                  <div className="ps-md-4">
                    <div className="w-100 shadow mb-5 rounded position-relative">
                      <TariffPicker customeClass="home-tariff" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* <div className={`position-relative ${modal ? '' : 'z-3'}`}>
        {screenSize > 1023 && <StickyLayer />}
      </div> */}
      <section
        className="position-relative z-0"
        // id={screenSize <= 768 ? tariffSectionId : ""}
      >
        <Image
          refs="sh_web_lg_tarif_bg-1"
          resRefs="sh_web_md_tarif_bg-1"
          sectionWithImage
          className="share-bnr-mobile-section position-relative -mb-5 z-0"
        >
          <section
          // topElementType={appSecTopElementTypes.TYPE_9}
          >
            <div className="container pb-lg-5 mb-lg-5">
              <div className="row pb-lg-5">
                {screenSize <= 768 && (
                  <div
                    className=""
                    // id={tariffSectionId}
                  >
                    <div className="w-100 shadow mb-5 rounded position-relative col-xl-6 col-md-12 pt-lg-5 pt-xl-0 mt-xl-5 mt-lg-0 z-3">
                      <TariffPicker customeClass="home-tariff" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </Image>
      </section>
      {/* <Image
        refs="sh_web_lg_tarif_bg-1"
        resRefs="sh_web_md_tarif_bg-1"
        sectionWithImage
        className="share-bnr-mobile-section position-relative pt-10 pb-5"
      >
        <div className="container pb-lg-5 mb-lg-5">
          <div className="row pb-lg-5">
            <div className="d-block d-md-none">
              <div className="w-100 shadow mb-5 rounded position-relative">
                <TariffPicker />
              </div>
            </div>
          </div>
        </div>
      </Image> */}
      <section
        className="share-mobile-cell-section"
        // bottomElementType={appSecBottomElementTypes.TYPE_4}
      >
        <div className="container mt-md-5 mt-lg-0 pb-5 pb-xl-0">
          <div className="row pt-0 pt-md-5 px-0 px-lg-5">
            <div className="col-xl-6 col-lg-6 col-md-12 pt-lg- pt-xl-0 mt-xl-5 mt-lg-3 ps-0">
              <h2
                className="custom-heading-h2 mb-xl-4 mb-lg-4 mb-md-3 mb-sm-3 mb-2"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub2') }}
              />
              {screenSize >= 1200 ? (
                <div
                  className="w-100 primary-color-text body-large-regular pt-1"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text8_new') }}
                />
              ) : (
                <div
                  className="w-100 primary-color-text body-large-regular pt-1"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text8') }}
                />
              )}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 pt-xl-0 mt-xl-5 mt-lg-3 pe-xl-5">
              {Object.keys(homeVideo).length > 0 && (
                <video
                  className="w-100 rounded-3"
                  controls
                  loop
                  playsInline
                  autoPlay={false}
                  preload="auto"
                  name="media"
                  // style={{
                  // background: 'white'
                  //   objectFit: 'cover',
                  //   maxWidth: '100%',
                  // }}
                >
                  <source src={`${homeVideo.media_url_display}#t=0.001`} type="video/mp4" />
                  {/* <source src={`${homeVideo.media_url_display}#t=0.001`} type="video/webm" /> */}
                </video>
              )}
            </div>
          </div>
          <div className="row py-5 mb-0">
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 px-xl-0 pe-4 px-lg-5 px-md-3 px-sm-3">
              <div className="text-center mobile-100 p-0 p-xl-1">
                <img className="" src={appImages.Home_victory} alt="Victory" />
                {/* <div
                  className="heading-small primary-color-text lh-sm d-flex align-items-center mt-n1 justify-content-center der-head"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub4') }}
                /> */}
                <h3
                  className="heading-small primary-color-text lh-sm d-flex align-items-center mt-n1 justify-content-center der-head"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub4') }}
                />
                <div
                  className="body-medium-regular primary-color-text pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3 px-lg-2"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text10') }}
                />
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 px-xl-0 pe-4 px-lg-5 px-md-3 px-sm-3">
              <div className="text-center mobile-100 p-0 p-xl-1">
                <img className="" src={appImages.Home_network} alt="Network Quality" />
                <h3
                  className="heading-small primary-color-text lh-sm d-flex align-items-center mt-n1 justify-content-center der-head"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub3') }}
                />
                <div
                  className="body-medium-regular primary-color-text pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3 px-lg-2"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text9') }}
                />
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 px-xl-0 pe-4 px-lg-5 px-md-3 px-sm-3">
              <div className="text-center mobile-100 p-0 p-xl-1">
                <img className="" src={appImages.globalFreedom} alt="Heart" />
                <h3
                  className="heading-small primary-color-text lh-sm d-flex align-items-center mt-n1 justify-content-center der-head"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub_new') }}
                />
                <div
                  className="body-medium-regular primary-color-text pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3 px-lg-2"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text_new') }}
                />
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 px-xl-0 pe-4 px-lg-5 px-md-3 px-sm-3">
              <div className="text-center mobile-100 p-0 p-xl-1">
                <img className="" src={appImages.Home_heart} alt="Heart" />
                <h3
                  className="heading-small primary-color-text lh-sm d-flex align-items-center mt-n1 justify-content-center der-head"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub5') }}
                />
                <div
                  className="body-medium-regular primary-color-text pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3 px-lg-2"
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text11') }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="surf-donate-section position-relative py-5 bg-offWhite-color">
      </section> */}
      <section style={{ backgroundColor: '#EFECE8' }}>
        <div className="container py-5">
          <div className="row position-relative d-flex align-items-center flex-column-reverse flex-sm-row">
            {/* <div className="col-12 yellow-pink-bubble"></div> */}

            <div className="col-12 col-sm-5 col-md-5 z-1 d-none d-sm-block text-lg-center text-xl-start">
              {/* <img
                className="img-fluid mb-2"
                src={appImages.dein_Tarif}
                alt="Dein Tarif kann mehr"
              /> */}
              <Image
                className="img-fluid mb-2"
                refs="sh_web_md_tarif_content-3"
                resRefs="sh_web_md_tarif_content-3"
                alt="Dein Tarif kann mehr"
                defaultStyle={{ height: '561', width: '384' }}
                mobileStyle={{ height: '474', width: '325' }}
                ipadStyle={{ height: '417', width: '286' }}
                imgName="Foto: Christoph Köstlin"
              />
            </div>
            <div className="col-12 col-md-5 z-1 d-block d-sm-none postion-relative text-center">
              {/* <img
                className="img-fluid mr-5 position-absolute  translate-middle z-1 pink-gray-bubble"
                src={appImages.pink_gray_bubble}
                alt="bubble"
              /> */}
              {/* <img
                className="img-fluid w-100 mw-100 mb-2"
                src={appImages.dein_Tarif_m}
                alt="Dein Tarif kann mehr"
              /> */}
              <Image
                className="img-fluid mw-100 mb-2"
                refs="sh_web_md_tarif_content-3"
                resRefs="sh_web_md_tarif_content-3"
                alt="Dein Tarif kann mehr"
                mobileStyle={{ height: '474', width: '325' }}
                ipadStyle={{ height: '417', width: '286' }}
                imgName="Foto: Christoph Köstlin"
              />
            </div>
            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-5 z-1">
              {/* <h6
                className="pink-color-text text-uppercase mb-3"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub6-1') }}
              /> */}
              <div
                className="custom-subheading pink-color-text text-uppercase mb-3"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub6-1') }}
              />
              <h2
                className="custom-heading-h2 mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub6') }}
              />
              <div
                className="body-large-regular primary-color-text mb-4 pb-3"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text12') }}
              />
              <button
                className="btn btn-secondary cmn-home-btn mb-5"
                onClick={() => {
                  if (window && window.utag) {
                    activeTariff.id === appTariff.FLAT_M
                      ? window.utag.link(
                          UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                            .MORE_ABOUT_PROJECT_ON_CLICK
                        )
                      : window.utag.link(
                          UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                            .MORE_ABOUT_PROJECT_ON_CLICK
                        );
                  }
                  navigate(appRoute.GENERIC_GREETINGS);
                  // window.open(appRoute.GENERIC_GREETINGS, '_self', 'noreferrer');
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif_button2') }}
                />
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="share-mobileApp-section position-relative bg-primary-color friends-purple-shade-2 pt-5 py-lg-5 purple-curve-shade-2">
      </section> */}

      <Section className="pt-5 position-relative" bgColorCode={appSecBgColorTypes.PRIMARY}>
        <div className="container position-relative">
          <div className="row">
            <div className="col-12 col-md-12 offset-lg-1 col-lg-5 col-sm-12 pb-lg-5 mb-lg-3">
              <h2
                className="custom-heading-h2 offWhite-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_app_h1') }}
              />
              <div
                className="body-large-regular offWhite-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text14') }}
              />
              <div className="d-flex flex-wrap flex-sm-nowrap pb-0 pb-sm-5 pb-lg-0 pt-2">
                {isIosDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    onClick={() => {
                      if (window && window.utag) {
                        activeTariff.id === appTariff.FLAT_M
                          ? window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                                .APP_STORE_ON_CLICK
                            )
                          : window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                                .APP_STORE_ON_CLICK
                            );
                      }
                    }}
                    target="_blank"
                    href={t('sh_ap_appstore')}
                  >
                    <img className="" src={appImages.iosapp} alt="iOS app" />
                  </a>
                )}
                {isAndroidDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    onClick={() => {
                      if (window && window.utag) {
                        activeTariff.id === appTariff.FLAT_M
                          ? window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                                .GOOGLE_PLAY_ON_CLICK
                            )
                          : window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                                .GOOGLE_PLAY_ON_CLICK
                            );
                      }
                    }}
                    target="_blank"
                    href={t('sh_ap_playstore')}
                  >
                    <img className="" src={appImages.androidapp} alt="Android app" />
                  </a>
                )}
                {!isAndroidDevice && !isIosDevice && (
                  <>
                    <span className="pe-3 pe-sm-3 pb-3 pb-sm-0 border-0">
                      <img className="grow" src={appImages.qrcode} alt="Qrcode" />
                    </span>
                    <a
                      className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      onClick={() => {
                        if (window && window.utag) {
                          activeTariff.id === appTariff.FLAT_M
                            ? window.utag.link(
                                UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                                  .GOOGLE_PLAY_ON_CLICK
                              )
                            : window.utag.link(
                                UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                                  .GOOGLE_PLAY_ON_CLICK
                              );
                        }
                      }}
                      target="_blank"
                      href={t('sh_ap_playstore')}
                    >
                      <img className="" src={appImages.androidapp} alt="Android app" />
                    </a>
                    <a
                      className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      onClick={() => {
                        if (window && window.utag) {
                          activeTariff.id === appTariff.FLAT_M
                            ? window.utag.link(
                                UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                                  .APP_STORE_ON_CLICK
                              )
                            : window.utag.link(
                                UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                                  .APP_STORE_ON_CLICK
                              );
                        }
                      }}
                      target="_blank"
                      href={t('sh_ap_appstore')}
                    >
                      <img className="" src={appImages.iosapp} alt="iOS app" />
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <figure className="position-absolute pt-4 d-none d-lg-block z-1 end-0 top-0">
          {/* <img className="img-fluid" src={appImages.Home_share_overview} alt="Share Mobile App" /> */}
          <Image
            className="mx-100 img-fluid"
            refs="sh_web_lg_tarif_content-2"
            resRefs="sh_web_md_tarif_content-2"
            alt="Share Mobile App"
            defaultStyle={{ height: '522', width: '608' }}
            ipadStyle={{ height: '418', width: '480' }}
            mobileStyle={{ height: '260', width: '292' }}
          />
        </figure>
        <figure className="d-block d-lg-none m-0">
          {/* <img className="img-fluid" src={appImages.Home_share_overview} alt="Share Mobile App" /> */}
          <div className="d-flex justify-content-end">
            <Image
              className="mx-100 img-fluid"
              refs="sh_web_lg_tarif_content-2"
              resRefs="sh_web_md_tarif_content-2"
              alt="Share Mobile App"
              ipadStyle={{ height: '260', width: '292' }}
              mobileStyle={{ height: '260', width: '292' }}
            />
          </div>
        </figure>
      </Section>

      {/* <section className="home-faq-section question-row position-relative my-5 pt-10 pb-5 mt-sm-0">
      </section> */}
      <section
        className=""
        // topElementType={appSecTopElementTypes.TYPE_5}
        // bottomElementType={appSecBottomElementTypes.TYPE_1}
      >
        <div className="container py-5">
          <div className="row mb-xl-5 pb-lg-4">
            <div className="col-xl-4 offset-xl-1 col-lg-4 offset-lg-1 col-md-5 pe-lg-3">
              <h2
                className="custom-heading-h2 mb-sm-4"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub8') }}
              />
            </div>
            <div className="col-xl-7 col-lg-7 col-md-7 mt-lg-5 mb-2 pt-sm-4">
              <Accordion items={topFaqList} />
              <div className="row">
                <div className="col-12 col-md-8 mt-3 pt-3">
                  <button
                    className="btn btn-secondary cmn-home-btn"
                    onClick={() => {
                      if (window && window.utag) {
                        activeTariff?.id === appTariff.FLAT_M
                          ? window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                                .SEE_ALL_FAQ_ON_CLICK
                            )
                          : window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                                .SEE_ALL_FAQ_ON_CLICK
                            );
                      }
                      // window.open(appRoute.REDIRECT(appRoute.FAQ_LIST));
                      navigate(appRoute.FAQ_LIST);
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('sh_apoc_tarif_overview_tarif_button3')
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="already-convinced-section position-relative bg-primary-color friends-purple-shade-2 py-5 purple-curve-shade-2 mb-0 mb-xl-5">
      </section> */}
      <Section className="" bgColorCode={appSecBgColorTypes.PRIMARY}>
        <div className="container py-5">
          <div className="row py-xl-4 py-3">
            <div className="col-12 offset-lg-1 col-lg-4 col-md-5 pb-md-5 mb-md-3">
              <h4
                className="text-white mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub9') }}
              />
            </div>
            <div className="col-12 col-md-5 mt-md-5 pt-md-4">
              <div
                className="body-large-regular text-white mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text15') }}
                style={{ maxWidth: '376px' }}
              />
              <button
                className="btn btn-primary cmn-home-btn mt-2"
                onClick={() => {
                  if (window && window.utag) {
                    activeTariff.id === appTariff.FLAT_M
                      ? window.utag.link(
                          UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                            .CONTINUE_TARIFF_ON_CLICK
                        )
                      : window.utag.link(
                          UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                            .CONTINUE_TARIFF_ON_CLICK
                        );
                  }
                  document.getElementById(tariffSectionId).scrollIntoView({ behavior: 'smooth' });
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif_button4') }}
                />
              </button>
            </div>
          </div>
        </div>
      </Section>
      {/* <Section
        className=""
        bgColorCode={appSecBgColorTypes.WHITE}
        topElementType={appSecTopElementTypes.TYPE_5}
      ></Section> */}
      {/* <section className="py-0 py-sm-4 py-lg-5 my-4 my-md-4 my-xl-0"> &nbsp; </section> */}
      <Section className="" bgColorCode={appSecBgColorTypes.OFFWHITE}>
        <div className="container py-5">
          <div className="row py-xl-4 py-3">
            <div className="col-12 offset-lg-1 col-lg-4 col-md-5 pb-md-5 mb-md-3">
              <h4
                className="primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_sub10') }}
              />
            </div>
            <div className="col-12 col-md-5 mt-md-5 pt-md-4">
              <div
                className="body-large-regular primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_text16') }}
                style={{ maxWidth: '376px' }}
              />
              <a
                className="btn btn-secondary cmn-home-btn mt-2"
                target="_blank"
                href={t('sh_apoc_tarif_overview_tarif_button5_link')}
                onClick={() => {
                  if (window && window.utag) {
                    activeTariff.id === appTariff.FLAT_M
                      ? window.utag.link(
                          UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_M
                            .CONTINUE_SURVEY_ON_CLICK
                        )
                      : window.utag.link(
                          UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.HOME.ALLNET_FLAT_L
                            .CONTINUE_SURVEY_ON_CLICK
                        );
                  }
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_tarif_button5') }}
                />
              </a>
            </div>
          </div>
        </div>
      </Section>
      {modal === homePageModals.PINK_BOX_MODAL && <PinkBoxModal setModal={setModal} />}
      {modal === homePageModals.YELLOW_TEASER_FOOTNOTE && (
        <YellowTeaserFootnote setModal={setModal} />
      )}
    </>
  );
}

export default Home;
