import React, { useEffect, useState } from 'react';
import { Button } from '@core/Inputs';
import { Col, Container, Row } from '@core/Layout';
import { Image, QrCode } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appImages, deviceRegex } from '@utils/globalConstant';
import './style.scss';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_LINK, UTAG_VIEWS } from '@utils/utag';
import { useLayout } from '@context/Utils';

export function DownloadApp() {
  // States
  const [isIosDevice, setIsIosDevice] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);

  // Contexts
  const { setLightHeader } = useLayout();
  const { t, staticContentData } = useStaticContent();
  const { setMetaTags } = useMetaTag();

  // Functions

  //  Hooks
  useEffect(() => {
    setLightHeader(false);
    let deviceInfo = navigator.userAgent;
    setIsIosDevice(deviceRegex.ISIOS.test(deviceInfo));
    setIsAndroidDevice(deviceRegex.ISANDRIOD.test(deviceInfo));

    return () => {
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.APP_DOWNLOAD_MOUNTING);
    }
  }, [window.utag]);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_info_download_meta_title'),
      description: t('sh_web_page_info_download_meta_description'),

      twitterTitle: t('sh_web_page_info_download_meta_title'),
      twitterDescription: t('sh_web_page_info_download_meta_description'),

      ogTitle: t('sh_web_page_info_download_meta_title'),
      ogDescription: t('sh_web_page_info_download_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  return (
    // <Container>
    //   <br />
    //   <br />
    //   <Row>
    //     <Col md={6} xs={12} className="mb-5 order-md-1">
    //       <h1 dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_h1') }} />
    //       <p dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_text1') }} />
    //       <p dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_text2') }} />
    //     </Col>
    //     <Col md={6} xs={6} className="mb-5 order-md-2 order-last">
    //       <h5>Image</h5>
    //     </Col>
    //     <Col md={6} xs={6} className="mb-5 order-md-3">
    //       <b>
    //         <p
    //           className="m-0"
    //           dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_subtext1') }}
    //         />
    //         <p dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_subtext2') }} />
    //       </b>
    //       {isIosDevice && (
    //         <Button
    //           className="btn-dark"
    //           href={t('sh_web_app_store_link')}
    //           to={t('sh_web_app_store_link')}
    //           target="_blank"
    //         >
    //           App Store
    //         </Button>
    //       )}
    //       {isAndroidDevice && (
    //         <Button
    //           className="btn-dark"
    //           href={t('https://www.google.com/')}
    //           to={t('https://www.google.com/')}
    //           target="_blank"
    //         >
    //           Google Play
    //         </Button>
    //       )}
    //       {!isAndroidDevice && !isIosDevice && (
    //         <>
    //           <QrCode
    //             size={200}
    //             value={t('sh_web_download_app_qr_text')}
    //             qrheight="auto"
    //             qrwidth="auto"
    //           />
    //           &ensp; &ensp;
    //           <Button
    //             className="btn-dark"
    //             href={t('sh_web_play_store_link')}
    //             to={t('sh_web_play_store_link')}
    //             target="_blank"
    //           >
    //             Google Play
    //           </Button>
    //           &ensp; &ensp;
    //           <Button
    //             className="btn-dark"
    //             href={t('sh_web_app_store_link')}
    //             to={t('sh_web_app_store_link')}
    //             target="_blank"
    //           >
    //             App Store
    //           </Button>
    //         </>
    //       )}
    //     </Col>
    //   </Row>
    // </Container>
    <>
      {/* <section className="position-relative gray-shade-2 py-5"> */}
      <section className="position-relative py-5">
        {/* <div className="container pt-0 pt-lg-5 py-5 mt-0 mt-lg-4"> */}
        <div className="container pt-0 pt-lg-5 mt-0 mt-lg-4">
          <div className="row py-5">
            {/* <div className="col-12 position-relative">
              <div className="orange-gray-bubble"></div>{' '}
            </div> */}
            <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-7 col-12">
              <h1
                className="primary-color-text my-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_app_h1') }}
              />
              <p
                className="body-large-regular primary-color-text mb-5 mb-md-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_app_text1') }}
              />
              <div className="d-block d-md-none py-5"></div>
              <div
                className="position-absolute d-block d-lg-none z-2 bottom-0 end-0"
                style={{ marginBottom: '-50px' }}
              >
                {/* <img className="mx-100" src={appImages.sharemobileapp_m} alt="Share Mobile app" /> */}
                <Image
                  className="mx-100"
                  refs="sh_web_lg_app-download_content-1"
                  resRefs="sh_web_md_app-download_content-1"
                  alt="Share Mobile app"
                  mobileStyle={{ height: '260', width: '292' }}
                  ipadStyle={{ height: '260', width: '292' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="position-absolute d-none d-lg-block z-2 bottom-0 end-0"
          style={{ marginBottom: '-80px' }}
        >
          {/* <img className="mx-100" src={appImages.sharemobileapp} alt="Share Mobile app" /> */}
          <Image
            className="mx-100"
            refs="sh_web_lg_app-download_content-1"
            resRefs="sh_web_md_app-download_content-1"
            alt="Share Mobile app"
            defaultStyle={{ height: '522', width: '608' }}
            ipadStyle={{ height: '418', width: '480' }}
          />
        </div>
      </section>
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 py-5 mb-5 mb-sm-0"> */}
      <section className="position-relative bg-offWhite-color py-56 py-lg-80">
        {/* <div className="container pb-5 pb-sm-5"> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-12">
              <h4
                className="primary-color-text mb-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_app_h2') }}
              />
              <p
                className="body-large-regular primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_app_text2') }}
              />
              <h5
                className="primary-color-text mb-4 mb-lg-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_app_h3') }}
              />
              {/* <div className="d-flex flex-wrap flex-column flex-sm-row pb-0 pb-sm-4 pb-lg-5 mb-0 mb-xl-3"> */}
              <div className="d-flex flex-wrap flex-column flex-sm-row">
                {/* <span>
                  <img className="mx-100" src={appImages.qrcode} alt="QR code" />
                </span>
                <button className="px-0 py-3 px-sm-3 py-sm-0 border-0">
                  <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                </button>
                <button className="border-0">
                  <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                </button> */}
                {!isAndroidDevice && !isIosDevice && (
                  <>
                    <span className="pe-0 pe-lg-3 pb-3 pb-sm-0">
                      {/* <QrCode
                        size={52}
                        value={t('sh_web_download_app_qr_text')}
                        qrheight="auto"
                        qrwidth="auto"
                      /> */}
                      <img className="mx-100 grow" src={appImages.qrcode} alt="QR code" />
                    </span>
                    <a
                      className="pe-0 pe-lg-3 pb-3 pb-sm-0 border-0"
                      target="_blank"
                      href={t('sh_ap_playstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                    </a>
                    <a
                      className="pe-0 pe-lg-3 pb-3 pb-sm-0 border-0"
                      target="_blank"
                      href={t('sh_ap_appstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                    </a>
                  </>
                )}

                {isIosDevice && (
                  <a
                    className="pe-0 pe-lg-3 pb-3 pb-sm-0 border-0"
                    target="_blank"
                    href={t('sh_ap_appstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </a>
                )}
                {isAndroidDevice && (
                  <a
                    className="pe-0 pe-lg-3 pb-3 pb-sm-0 border-0"
                    target="_blank"
                    href={t('sh_ap_playstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-1 d-block d-md-none">&nbsp;</section>
    </>
  );
}

export default DownloadApp;
