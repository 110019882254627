/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import BBadge from 'react-bootstrap/Badge';

export function Badge({ children, ...props }) {
  return <BBadge {...props}>{children}</BBadge>;
}

export default Badge;
