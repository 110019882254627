/* eslint-disable */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useAlphaComm } from '@dom-digital-online-media/dom-alphacomm-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import {
  DEFAULT_FIELD_VALIDATION_TYPE,
  generateParamsForAddToCart,
  useAppConfig
} from '@dom-digital-online-media/dom-app-config-sdk';
import {
  appAlert,
  appAutoTopUpPeriodType,
  appAutoTopUpType,
  appPaymentProductType,
  formValidation,
  appStorage
} from '@utils/globalConstant';
// getLowBalanceTopUp,
import { useAlert } from '@context/Utils';
import { useCustomer } from '@context/MobileOne';

export const OnlineTopUpContext = createContext();

export const OnlineTopUpProvider = function ({ children }) {
  // Constants

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [topUpForm, setTopUpForm] = useState({
    type: appAutoTopUpType.UNIQUE, // UNIQUE | AUTOMATIC
    periodType: appAutoTopUpPeriodType.LOW_BALANCE, // LOW_BALANCE | ONCE_PER_MONTH | RATE
    periodDay: '', // Only for  ONCE_PER_MONTH,
    phone: '',
    confirmPhone: '',
    productId: '',
    productType: '',
    productAmount: '' // Only for DIRECT
  });
  const [paymentProducts, setPaymentProducts] = useState([]);
  const [allPaymentProducts, setAllPaymentProducts] = useState([]);

  const [cartName, setCartName] = useState(null); // Cart ID / Name when added to card.
  const [paymentAuthData, setPaymentAuthData] = useState({});
  const [paymentMethod, setPaymentMethod] = useState([]);

  const [personalInfoForm, setPersonalInfoForm] = useState({
    cartName: '',
    email: '',
    firstName: '',
    lastName: '',
    street: '',
    houseNumber: '',
    zip: '',
    city: '',
    phoneNumber: '',
    ipAddress: '203.88.141.230',
    countryCode: 'DE',
    language: 'en'
  });

  const [paymentMethodForm, setPaymentMethodForm] = useState({
    paymentMethod: ''
  });

  // Context
  const { t } = useStaticContent();
  const { isUserLoggedIn } = useAuth();
  const { showAlert } = useAlert();
  const {
    onSsoPaymentToken,
    onPaymentProduct,
    onPaymentAddToCart,
    onPaymentMethod,
    onPaymentCheckoutAddress,
    onCheckoutPaymentStart
  } = useAlphaComm();
  const { env } = useAppConfig();
  const { customerData, personalData, getCustomerData } = useCustomer();

  // Validation
  const topUpValidations = Yup.lazy((values) => {
    // values is the formik.values object
    if (values.type === appAutoTopUpType.AUTOMATIC) {
      if (values.periodType === appAutoTopUpPeriodType.ONCE_PER_MONTH) {
        return Yup.object().shape({
          type: formValidation({ required: 'please_select_type' }),
          periodType: formValidation({ required: 'please_select_period_type' }),
          periodDay: formValidation({ required: 'please_select_period_day' }),
          phone: formValidation({ required: 'please_enter_phone' }),
          confirmPhone: formValidation({ required: 'please_enter_confirm_phone' }).oneOf(
            [Yup.ref('phone'), null],
            'please_match_phone'
          ),
          productId: formValidation({ required: 'please_select_product' })
        });
      }
      return Yup.object().shape({
        type: formValidation({ required: 'please_select_type' }),
        periodType: formValidation({ required: 'please_select_period_type' }),
        phone: formValidation({ required: 'please_enter_phone' }),
        confirmPhone: formValidation({ required: 'please_enter_confirm_phone' }).oneOf(
          [Yup.ref('phone'), null],
          'please_match_phone'
        ),
        productId: formValidation({ required: 'please_select_product' })
      });
    }
    return Yup.object().shape({
      type: formValidation({ required: 'please_select_type' }),
      phone: formValidation({ required: 'please_enter_phone' }),
      confirmPhone: formValidation({ required: 'please_enter_confirm_phone' }).oneOf(
        [Yup.ref('phone'), null],
        'please_match_phone'
      ),
      productId: formValidation({ required: 'please_select_product' })
    });
  });

  const personalInfoValidations = Yup.object().shape({
    cartName: formValidation({ required: 'please_cartName' }),
    email: formValidation({
      required: t('sh_apoc_email_err_no_email'),
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      validErrorMessage: t('sh_apoc_email_err_wrong_email')
    }),
    firstName: formValidation({
      required: t('sh_apoc_personal_first-name_error-msg'),
      regex: /^[A-Za-zÄÖÜßäöü-\s]{2,25}$/,
      validErrorMessage: t('sh_apoc_personal_first-name_error-msg')
    }),
    lastName: formValidation({
      required: t('sh_apoc_personal_last-name_error-msg'),
      regex: /^[A-Za-zÄÖÜßäöü-\s]{2,25}$/,
      validErrorMessage: t('sh_apoc_personal_last-name_error-msg')
    }),
    street: formValidation({
      required: t('sh_apoc_personal_street_error-msg'),
      regex: /^.{0,100}$/,
      validErrorMessage: t('sh_apoc_personal_street_error-msg')
    }),
    houseNumber: formValidation({
      required: t('sh_apoc_personal_street-nr_error-msg'),
      regex: /^[A-Za-z0-9ÄÖÜßäöü.-]{0,25}$/,
      validErrorMessage: t('sh_apoc_personal_street-nr_error-msg')
    }),
    city: formValidation({
      required: t('sh_apoc_personal_town_error-msg'),
      regex: /^.{0,75}$/,
      validErrorMessage: t('sh_apoc_personal_town_error-msg')
    }),
    zip: formValidation({
      required: t('sh_apoc_personal_zip_error-msg'),
      regex: /^[0-9]{0,25}$/,
      validErrorMessage: t('sh_apoc_personal_zip_error-msg')
    }),
    phoneNumber: formValidation({
      required: 'please_enter_phone_number',
      regex: /^[0-9-+]{4,15}$/,
      validErrorMessage: 'please_valid_phone_number'
    }),
    ipAddress: formValidation({ required: 'please_ipAddress' }),
    countryCode: formValidation({ required: 'please_countryCode' }),
    language: formValidation({ required: 'please_language' })
  });

  const paymentValidations = Yup.object().shape({
    paymentMethod: formValidation({
      required: 'please_select_paymentMethod',
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    })
  });

  // This Hook executed when value of these variables (customerData, personalData) changed.
  useEffect(() => {
    const { msisdn } = customerData;
    const {
      email: { emailAddress = '' } = {},
      firstName,
      lastName,
      address: { street = '', houseNumber = '', city = '', zip = '' } = {}
    } = personalData;
    if (msisdn && emailAddress) {
      const phone = msisdn.charAt(0) === '0' ? msisdn.substring(1) : msisdn;
      setTopUpForm({
        ...topUpForm,
        phone: `+49${phone}`,
        confirmPhone: `+49${phone}`
      });
      setPersonalInfoForm({
        ...personalInfoForm,
        phoneNumber: `+49${phone}`,
        email: emailAddress,
        firstName,
        lastName,
        street,
        houseNumber,
        city,
        zip
      });
    }
  }, [customerData, personalData]);

  // const topUpValidations = Yup.object().shape({
  //   type: formValidation({ required: 'please_select_type' }),
  //   periodType: formValidation({ required: 'please_select_period_type' }),
  //   // periodDay: formValidation(),
  //   phone: formValidation({ required: 'please_enter_phone' }),
  //   confirmPhone: formValidation({ required: 'please_enter_confirm_phone' }),
  //   // productId: formValidation(),
  //   // productType: formValidation(),
  //   // productAmount: formValidation(),
  //   // cartName: formValidation(),
  //   // ipAddress: formValidation(),
  //   // orderNumber: formValidation()
  //   // voucherCode: formValidation({
  //   //   required: 'please_select_paymentMethod',
  //   //   type: DEFAULT_FIELD_VALIDATION_TYPE.STRING,
  //   //   minLength: 13,
  //   //   minLengthError: 'min_length_error',
  //   //   maxLength: 16,
  //   //   maxLengthError: 'max_length_error'
  //   // })
  // });

  // Function

  // Stepper Functions
  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  // Payment Products
  const getPaymentProducts = async () => {
    try {
      const {
        data: { items = [] }
      } = await onPaymentProduct();
      if (items.length > 0) {
        // TODO: Update products with selected type.
        // setPaymentProducts(items);
        setPaymentProducts(
          items.filter(
            ({ productType }) =>
              productType === appPaymentProductType.DIRECT ||
              productType === appPaymentProductType.PREPAID
          )
        );
        setAllPaymentProducts(items);
        setIsLoading(false);
      }
      return items;
    } catch (error) {
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      return false;
    }
  };

  // Payment Authentication
  const ssoPaymentToken = async ({ signupId, alphacommHash, msisdn }) => {
    try {
      const ssoPaymentTokenParams = {
        clientId: signupId,
        hashCode: alphacommHash,
        language: 'de',
        msisdn,
        pageId: 'home'
      };
      const { data = {}, success } = await onSsoPaymentToken(ssoPaymentTokenParams);
      if (data && success) {
        setPaymentAuthData(ssoPaymentTokenParams);
        setTimeout(() => {
          getPaymentProducts();
        }, 100);
      }
      return data;
    } catch (error) {
      console.log('sso payment ---', error);
      return false;
    }
  };

  const onStepTwoSubmit = async (values) => {
    try {
      setIsLoading(true);
      const selectedProduct = allPaymentProducts.find((p) => p.id === values.productId);

      if (selectedProduct) {
        const { minAmount = 5 } = allPaymentProducts.find(
          ({ productType, amount }) =>
            (productType === appPaymentProductType.DIRECT ||
              productType === selectedProduct.productType) &&
            !amount
        );

        // eslint-disable-next-line prefer-const
        let data = {
          productId: selectedProduct.id,
          option: {
            phoneNumber: values.phone, // simActivationDetails?.activationData?.msisdn,
            optionType: selectedProduct.optionType,
            // amount: values.productAmount || selectedProduct.amount,
            lowBalanceOption: {
              lowBalanceLimit: minAmount,
              tariffEnabled: false
            },
            recurringOption: {}
          }
        };

        // TODO: Refactor with proper fix
        const type = values.type === appAutoTopUpPeriodType.UNIQUE ? '' : values.type;
        const inputParams = generateParamsForAddToCart(
          values.type,
          values.productAmount,
          data,
          type,
          selectedProduct.amount,
          values.periodDay
        );

        const {
          data: { cartName: responseCartName = false },
          success
        } = await onPaymentAddToCart(inputParams);
        if (responseCartName && success) {
          setCartName(responseCartName);
          sessionStorage.setItem(appStorage.CART_NAME, responseCartName);
          setTopUpForm({ ...topUpForm, cartName: responseCartName });
          setPersonalInfoForm({ ...personalInfoForm, cartName: responseCartName });
          setIsLoading(false);
          showAlert({ type: appAlert.SUCCESS, message: 'Product Selected Successfully..' });
          nextStep();
        } else {
          showAlert({
            type: appAlert.ERROR,
            message: 'Bitter request processed. Try again later.'
          });
        }
        setIsLoading(false);
      }
      setIsLoading(false);
      return values;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      return false;
    }
  };

  const onStepThreeSubmit = async (values) => {
    // Checkout address & getch payment methods & got to next step;
    try {
      setIsLoading(true);
      setPersonalInfoForm(values);

      const params = {
        cartName: values.cartName,
        deliveryAddress: {
          email: values.email,
          firstname: values.firstName,
          lastname: values.lastName,
          street: values.street,
          houseNumber: values.houseNumber,
          zip: values.zip,
          city: values.city,
          countryCode: 'DE',
          phoneNumber: values.phoneNumber,
          ipAddress: values.ipAddress,
          language: values.language
        }
      };

      const { data = {}, success } = await onPaymentCheckoutAddress(params);
      if (data && success) {
        const {
          data: { paymentMethods = {} }
        } = await onPaymentMethod();
        // eslint-disable-next-line prefer-const
        let payments = [];
        if (paymentMethods) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(paymentMethods)) {
            if (value.paymentTokenEnabled) {
              payments.push({ name: key, ...value });
            }
          }
          setPaymentMethod(payments);
          setIsLoading(false);
        }
        showAlert({ type: appAlert.SUCCESS, message: 'Checkout Verified Successfully..' });
        nextStep();
      }
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      return false;
    }
  };

  const onStepFourSubmit = async (values) => {
    // Process payment methods & store all details in the storage;
    try {
      setIsLoading(true);
      const inputParams = {
        cartName,
        paymentMethod: values.paymentMethod,
        returnUrl: env.REACT_APP_SHOPPING_RETURN_URL,
        // returnUrl: "http://localhost:3001/",
        savePayment: true
      };

      const {
        data: { orderNumber, payerUrl },
        success
      } = await onCheckoutPaymentStart(inputParams);
      if (payerUrl && success) {
        setIsLoading(false);
        showAlert({ type: appAlert.SUCCESS, message: 'Please Process With The Payment..' });
        sessionStorage.setItem(appStorage.ORDER_NUMBER, orderNumber);
        window.open(payerUrl, '_blank');
      }
      return { payerUrl };
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${error.error[0].messageBody}`
      });
      return false;
    }
  };

  const onLoad = async () => {
    try {
      // TODO: Exten it with api call to fetch all voucher history.
      console.log('Loading AlphaComm Online TopUp');
      setIsLoading(true);
      await getCustomerData();
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values) => {
    // console.log(values);
    await onStepTwoSubmit(values);
  };

  // Hooks
  useEffect(() => {
    if (isUserLoggedIn && customerData) {
      const { alphacommHash, msisdn, signupId } = customerData;
      if (alphacommHash && msisdn && signupId) {
        setIsLoading(true);
        ssoPaymentToken({ alphacommHash, msisdn, signupId });
      }
    }
    return () => {
      // setHistory([]);
    };
  }, [isUserLoggedIn, customerData]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      currentStep,
      setCurrentStep,
      topUpForm,
      setTopUpForm,
      personalInfoForm,
      setPersonalInfoForm,
      personalInfoValidations,

      paymentProducts,
      setPaymentProducts,
      allPaymentProducts,
      setAllPaymentProducts,
      cartName,
      setCartName,
      paymentAuthData,
      setPaymentAuthData,

      paymentMethod,
      paymentMethodForm,
      setPaymentMethodForm,

      // Validations
      topUpValidations,
      paymentValidations,

      // Functions
      onLoad,
      onSubmit,
      nextStep,
      prevStep,
      ssoPaymentToken,
      onStepTwoSubmit,
      onStepThreeSubmit,
      onStepFourSubmit
    }),
    [
      // States
      isLoading,
      setIsLoading,
      currentStep,
      setCurrentStep,

      topUpForm,
      setTopUpForm,
      personalInfoForm,
      setPersonalInfoForm,
      personalInfoValidations,

      paymentProducts,
      setPaymentProducts,
      allPaymentProducts,
      setAllPaymentProducts,

      cartName,
      setCartName,

      paymentAuthData,
      setPaymentAuthData,

      paymentMethod,
      paymentMethodForm,
      setPaymentMethodForm,

      // Validations
      topUpValidations,
      paymentValidations,

      // Functions
      onLoad,
      onSubmit,
      nextStep,
      prevStep,
      ssoPaymentToken,
      onStepTwoSubmit,
      onStepThreeSubmit,
      onStepFourSubmit
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <OnlineTopUpContext.Provider value={contextPayload}>{children}</OnlineTopUpContext.Provider>
  );
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useOnlineTopUp = () => useContext(OnlineTopUpContext);

export default OnlineTopUpProvider;
