import React from 'react';
import { Formik, Form } from 'formik';
import { Spinner as BSpinner } from 'react-bootstrap';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button } from '@core/Inputs';
import { Col, Row } from '@core/Layout';
import { useOnlineTopUp } from '@context/AlphaComm';
import { appAutoTopUpType } from '@utils/globalConstant';
import { Type } from './Type';
import { TimePeriod } from './TimePeriod';
import { Phone } from './Phone';
import { Amount } from './Amount';
// import PropTypes from 'prop-types'

export function Online() {
  const { nextStep } = useOnlineTopUp();

  return (
    <div className="py-3">
      <hr />
      <h2>Online</h2>
      <hr />
      <Row>
        <Col className="text-end">
          <Button variant="primary" type="submit" isLoading={false} onClick={() => nextStep()}>
            For online charge
          </Button>
        </Col>
      </Row>
    </div>
  );
}

Online.propTypes = {};

export function OnlineTopUp() {
  const { t } = useStaticContent();
  const { isLoading, topUpForm, topUpValidations, onSubmit, prevStep, nextStep } = useOnlineTopUp();

  return (
    <div className="position-relative">
      {isLoading && (
        <div className="opacity-75 position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
          <BSpinner size="xl" animation="border" role="status" />
        </div>
      )}
      <Formik
        initialValues={topUpForm}
        enableReinitialize
        validationSchema={topUpValidations}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <Type />
            {values && values.type === appAutoTopUpType.AUTOMATIC && <TimePeriod />}
            <Phone />
            <Amount />
            <hr />
            <Row>
              <Col className="text-start">
                <Button variant="primary" onClick={prevStep}>
                  {t('sh_back_button')}
                </Button>
              </Col>
              <Col className="text-end">
                <Button variant="primary" type="submit" isLoading={isLoading}>
                  Next
                </Button>
                <Button variant="primary" isLoading={isLoading} onClick={nextStep}>
                  Skip
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}

OnlineTopUp.propTypes = {};

export default OnlineTopUp;

export * from './Amount';
export * from './Payment';
export * from './PersonalData';
export * from './Phone';
export * from './TimePeriod';
export * from './Type';
