/* eslint-disable camelcase */
import React from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { Text, Button, Link } from '@core/Inputs';
import { useAccount } from '@context/MobileOne';
import { Col, Container, Row } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';

export function ValidateCode() {
  // Context
  const { t } = useStaticContent();
  const { otpCodeInitialValue, otpCodeValidation, isLoading, onValidateCode, onResendCode } =
    useAccount();

  return (
    <Container>
      <>
        <br />
        <Row>
          <Col xs={12}>
            <h4>{t('sh_login_pw_h1')}</h4>
          </Col>
          <Col xs={12}>
            <p dangerouslySetInnerHTML={{ __html: t('sh_login_pw_msg2') }} />
          </Col>
        </Row>
        <br />
        <Formik
          initialValues={otpCodeInitialValue}
          validationSchema={otpCodeValidation}
          onSubmit={onValidateCode}
        >
          {({
            values: { code_one, code_two, code_three, code_four, code_five, code_six },
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={1} className="py-3">
                  <Text
                    type="text"
                    label=""
                    id="code_one"
                    name="code_one"
                    maxLength="1"
                    onChange={handleChange}
                    value={code_one}
                    placeholder=""
                    isValid={touched.code_one && !errors.code_one}
                    isInvalid={!!touched.code_one && !!errors.code_one}
                    onBlur={handleBlur}
                    error={touched.code_one && errors.code_one ? errors.code_one : null}
                  />
                </Col>
                <Col xs={1} className="py-3">
                  <Text
                    type="text"
                    label=""
                    id="code_two"
                    name="code_two"
                    maxLength="1"
                    onChange={handleChange}
                    value={code_two}
                    placeholder=""
                    isValid={touched.code_two && !errors.code_two}
                    isInvalid={!!touched.code_two && !!errors.code_two}
                    onBlur={handleBlur}
                    error={touched.code_two && errors.code_two ? errors.code_two : null}
                  />
                </Col>
                <Col xs={1} className="py-3">
                  <Text
                    type="text"
                    label=""
                    id="code_three"
                    name="code_three"
                    maxLength="1"
                    onChange={handleChange}
                    value={code_three}
                    placeholder=""
                    isValid={touched.code_three && !errors.code_three}
                    isInvalid={!!touched.code_three && !!errors.code_three}
                    onBlur={handleBlur}
                    error={touched.code_three && errors.code_three ? errors.code_three : null}
                  />
                </Col>
                <Col xs={1} className="py-3">
                  <center>
                    <b> - </b>
                  </center>
                </Col>
                <Col xs={1} className="py-3">
                  <Text
                    type="text"
                    label=""
                    id="code_four"
                    name="code_four"
                    maxLength="1"
                    onChange={handleChange}
                    value={code_four}
                    placeholder=""
                    isValid={touched.code_four && !errors.code_four}
                    isInvalid={!!touched.code_four && !!errors.code_four}
                    onBlur={handleBlur}
                    error={touched.code_four && errors.code_four ? errors.code_four : null}
                  />
                </Col>
                <Col xs={1} className="py-3">
                  <Text
                    type="text"
                    label=""
                    id="code_five"
                    name="code_five"
                    maxLength="1"
                    onChange={handleChange}
                    value={code_five}
                    placeholder=""
                    isValid={touched.code_five && !errors.code_five}
                    isInvalid={!!touched.code_five && !!errors.code_five}
                    onBlur={handleBlur}
                    error={touched.code_five && errors.code_five ? errors.code_five : null}
                  />
                </Col>
                <Col xs={1} className="py-3">
                  <Text
                    type="text"
                    label=""
                    id="code_six"
                    name="code_six"
                    maxLength="1"
                    onChange={handleChange}
                    value={code_six}
                    placeholder=""
                    isValid={touched.code_six && !errors.code_six}
                    isInvalid={!!touched.code_six && !!errors.code_six}
                    onBlur={handleBlur}
                    error={touched.code_six && errors.code_six ? errors.code_six : null}
                  />
                </Col>
              </Row>
              <Col xs={12} className="py-3">
                <Button variant="primary" type="submit" isLoading={isLoading}>
                  {t('sh_ap_number_next-button')}
                </Button>
              </Col>
            </Form>
          )}
        </Formik>
        <center>
          <Row>
            <Col xs={12}>
              <Link to={appRoute.FORGOT_PASSWORD} onClick={onResendCode}>
                <b>
                  <u>{t('sh_login_pw_no_sms')}</u>
                </b>
              </Link>
            </Col>
          </Row>
        </center>
      </>
    </Container>
  );
}

export default ValidateCode;
