import React, { useEffect, useState } from 'react';
import { Button } from '@core/Inputs';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Card, CardBody } from '@core/Layout';
import { useNumberPorting } from '@context/MobileOne/Activation/NumberPorting';
import { useConfig } from '@config/ContextManager';
import { useActivation } from '@context/MobileOne';
import { appActivationFlowType, appActivationStep2Screens } from '@utils/globalConstant';
import { UTAG_LINK, utag } from '@utils/utag';
import OldNumberModal from './OldNumberModal';

export default function SelectedPhoneNumber({ activationInfo, setCurrentStep, setEditStep }) {
  // State
  const [selectedProvider, setSelectedProvider] = useState('');
  const [modal, setModal] = useState('');

  const { staticContentData, t } = useStaticContent();
  const { setFormValue, providers, setProviders, newNumbers, setNewNumbers } = useNumberPorting();
  const { activationType, setNumberPortingScreen } = useActivation();
  const {
    config: { storage }
  } = useConfig();

  useEffect(() => {
    if (activationInfo.provider && providers && providers.length > 0) {
      setSelectedProvider(providers.find((item) => item.value === activationInfo.provider)?.label);
    }
  }, [providers, activationInfo.provider]);

  useEffect(() => {
    (async () => {
      if (providers.length === 0) {
        setProviders(JSON.parse(await storage.getItem('sim-providers')));
      }
      if (newNumbers.length === 0) {
        setNewNumbers(JSON.parse(await storage.getItem('postpaid-numbers')));
      }
    })();
  }, []);

  useEffect(() => {
    if (staticContentData) {
      const oldNumberModal = document.querySelector('#action-btn');
      if (oldNumberModal) {
        oldNumberModal.addEventListener('click', () => {
          setModal('oldNumberModal');
        });
      }
    }
  }, [staticContentData]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1 mt-sm-3">
          <hr className="border-light-purple border-1 mb-5" style={{ opacity: 0.5 }} />
          <div className="d-flex justify-content-between align-items-start">
            <h6 className="heading-small pink-color-text" style={{ fontWeight: '900' }}>
              {t('sh_apoc_summary_number_h')}
            </h6>
            <a
              className="text-white body-medium-regular p-0 mb-3 mb-sm-0 link-light shadow-none"
              variant="link"
              onClick={() => {
                if (window && window.utag) {
                  activationType === appActivationFlowType.NEW_ONLINE
                    ? window.utag.link(
                        UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.EDIT_NUMBER_ON_CLICK
                      )
                    : window.utag.link(
                        UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.OVERIEW.EDIT_NUMBER_ON_CLICK
                      );
                }

                setEditStep(activationType === appActivationFlowType.SIM_ACTIVATION ? 2 : 0);
                setNumberPortingScreen(appActivationStep2Screens.PHONE_MNP_NEW_SELECTION);
                setCurrentStep(activationType === appActivationFlowType.SIM_ACTIVATION ? 2 : 0);
                setFormValue({
                  isNewNumber: '',
                  newNumber: '',
                  currentNumber: '',
                  provider: '',
                  startWithNumber: '',
                  isCurrentNumberEntered: false,
                  startWithNumberSelected: false,
                  isNumberTransferred: false,
                  selectSIM: '',
                  device: '',
                  isDeviceAvail: false
                });
              }}
            >
              {t('sh_apoc_summary_adjust')}
            </a>
          </div>
          {activationInfo.isNewNumber === 'true' ? (
            <p
              className="text-white body-large-regular mt-2"
              dangerouslySetInnerHTML={{ __html: t('sh_apoc_summary_number_tex3') }}
            />
          ) : (
            <p className="text-white body-large-regular mt-2">
              {t('sh_apoc_summary_number_text1').replace('[1&1]', `${selectedProvider}`)}
            </p>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-8 offset-md-1 col-lg-6 offset-lg-1">
          <Card
            sx={{
              backgroundColor: '#FFFFFF',
              color: '#534164',
              alignItems: 'center',
              borderRadius: '8px'
            }}
          >
            <CardBody sx={{ display: 'flex' }}>
              <div className="d-block text-center">
                <h5>{activationInfo.currentNumber || activationInfo.newNumber}</h5>
              </div>
            </CardBody>
          </Card>
          <p className="body-large-regular mt-3 text-white">{t('sh_apoc_summary_number_text2')}</p>
        </div>
      </div>
      {activationInfo.currentNumber && (
        <div className="row mb-1 mb-sm-4">
          <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1">
            <Card sx={{ backgroundColor: '#786886', color: 'white', borderRadius: '12px' }}>
              <CardBody
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div className="d-block">
                  <p
                    className="body-medium-regular text-white mb-2"
                    dangerouslySetInnerHTML={{ __html: t('sh_apoc_summary_add_credit_text') }}
                  />
                  <p
                    className="body-large-bold mb-1"
                    dangerouslySetInnerHTML={{ __html: t('sh_apoc_summary_add_credit_h') }}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      {modal === 'oldNumberModal' && <OldNumberModal setModal={setModal} />}
    </>
  );
}
