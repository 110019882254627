import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { appActivationFlowType, appRoute } from '@utils/globalConstant';
import { Activation } from '@page/index';
import { useActivation } from '@context/MobileOne';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';

export function NewOnlineCustomer() {
  const navigation = useNavigate();
  const location = useLocation();
  const { setActivationType, setActivationInfo, setCurrentStep } = useActivation();
  const { setActiveTariff } = useActivationTariff();

  useEffect(() => {
    if (!location.state) {
      // navigation(appRoute.HOME);
    } else {
      setActivationType(appActivationFlowType.NEW_ONLINE);
      setActivationInfo(location.state);
      setActiveTariff(location.state.activeTariff);
    }
    return () => {
      setCurrentStep(0);
    };
  }, []);

  return <Activation />;
}

export default NewOnlineCustomer;
