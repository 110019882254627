import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Text, Button } from '@core/Inputs';
import { Col, Row } from '@core/Layout';
import { Formik, Form } from 'formik';
import { Spinner as BSpinner } from 'react-bootstrap';

import { useOnlineTopUp } from '@context/AlphaComm';
// import PropTypes from 'prop-types'

export function OnlineTopupPersonalData() {
  const { t } = useStaticContent();
  const {
    isLoading,
    prevStep,
    nextStep,
    personalInfoForm,
    personalInfoValidations,
    onStepThreeSubmit
  } = useOnlineTopUp();
  return (
    <>
      <div className="position-relative">
        {isLoading && (
          <div className="opacity-75 position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
            <BSpinner size="xl" animation="border" role="status" />
          </div>
        )}
        <Formik
          initialValues={personalInfoForm}
          enableReinitialize
          validationSchema={personalInfoValidations}
          onSubmit={onStepThreeSubmit}
        >
          {({ values, handleChange, errors, touched, handleBlur }) => (
            <Form>
              <hr />
              <h2>Address data</h2>
              <hr />
              <Row>
                <Col xs={12}>
                  <span>Enter the phone number you want to top up</span>
                </Col>

                <Col className="py-2">
                  <Text
                    type="text"
                    label="Cart Name"
                    name="cartName"
                    onChange={handleChange}
                    value={values.cartName}
                    isValid={touched.cartName && !errors.cartName}
                    isInvalid={!!touched.cartName && !!errors.cartName}
                    onBlur={handleBlur}
                    disabled
                    error={touched.cartName && errors.cartName ? errors.cartName : null}
                  />
                </Col>
                <Col className="py-2">
                  <Text
                    type="text"
                    label="ip Address"
                    name="ipAddress"
                    onChange={handleChange}
                    value={values.ipAddress}
                    isValid={touched.ipAddress && !errors.ipAddress}
                    isInvalid={!!touched.ipAddress && !!errors.ipAddress}
                    onBlur={handleBlur}
                    error={touched.ipAddress && errors.ipAddress ? errors.ipAddress : null}
                  />
                </Col>
                <Col className="py-2">
                  <Text
                    type="text"
                    label="Language"
                    name="language"
                    onChange={handleChange}
                    value={values.language}
                    isValid={touched.language && !errors.language}
                    isInvalid={!!touched.language && !!errors.language}
                    onBlur={handleBlur}
                    error={touched.language && errors.language ? errors.language : null}
                  />
                </Col>
              </Row>

              <br />
              <h4 style={{ color: 'blue' }}>Delivery Address</h4>
              <Row>
                <Col className="py-2">
                  <Text
                    type="text"
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!touched.email && !!errors.email}
                    onBlur={handleBlur}
                    error={touched.email && errors.email ? errors.email : null}
                  />
                </Col>
                <Col className="py-2">
                  <Text
                    type="text"
                    label="First Name"
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                    isValid={touched.firstName && !errors.firstName}
                    isInvalid={!!touched.firstName && !!errors.firstName}
                    onBlur={handleBlur}
                    error={touched.firstName && errors.firstName ? errors.firstName : null}
                  />
                </Col>
                <Col className="py-2">
                  <Text
                    type="text"
                    label="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                    isValid={touched.lastName && !errors.lastName}
                    isInvalid={!!touched.lastName && !!errors.lastName}
                    onBlur={handleBlur}
                    error={touched.lastName && errors.lastName ? errors.lastName : null}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="py-2">
                  <Text
                    type="text"
                    label="Street"
                    name="street"
                    onChange={handleChange}
                    value={values.street}
                    isValid={touched.street && !errors.street}
                    isInvalid={!!touched.street && !!errors.street}
                    onBlur={handleBlur}
                    error={touched.street && errors.street ? errors.street : null}
                  />
                </Col>
                <Col className="py-2">
                  <Text
                    type="text"
                    label="House Number"
                    name="houseNumber"
                    onChange={handleChange}
                    value={values.houseNumber}
                    isValid={touched.houseNumber && !errors.houseNumber}
                    isInvalid={!!touched.houseNumber && !!errors.houseNumber}
                    onBlur={handleBlur}
                    error={touched.houseNumber && errors.houseNumber ? errors.houseNumber : null}
                  />
                </Col>
                <Col className="py-2">
                  <Text
                    type="text"
                    label="Zip"
                    name="zip"
                    onChange={handleChange}
                    value={values.zip}
                    isValid={touched.zip && !errors.zip}
                    isInvalid={!!touched.zip && !!errors.zip}
                    onBlur={handleBlur}
                    error={touched.zip && errors.zip ? errors.zip : null}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="py-2">
                  <Text
                    type="text"
                    label="City"
                    name="city"
                    onChange={handleChange}
                    value={values.city}
                    isValid={touched.city && !errors.city}
                    isInvalid={!!touched.city && !!errors.city}
                    onBlur={handleBlur}
                    error={touched.city && errors.city ? errors.city : null}
                  />
                </Col>
                <Col className="py-2">
                  <Text
                    type="text"
                    label="Country Code"
                    name="countryCode"
                    onChange={handleChange}
                    value={values.countryCode}
                    isValid={touched.countryCode && !errors.countryCode}
                    isInvalid={!!touched.countryCode && !!errors.countryCode}
                    onBlur={handleBlur}
                    error={touched.countryCode && errors.countryCode ? errors.countryCode : null}
                  />
                </Col>
                <Col className="py-2">
                  <Text
                    type="text"
                    label="Phone Number"
                    name="phoneNumber"
                    onChange={handleChange}
                    value={values.phoneNumber}
                    isValid={touched.phoneNumber && !errors.phoneNumber}
                    isInvalid={!!touched.phoneNumber && !!errors.phoneNumber}
                    onBlur={handleBlur}
                    error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : null}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-start">
                  <Button variant="primary" onClick={prevStep}>
                    {t('sh_back_button')}
                  </Button>
                </Col>
                <Col className="text-end">
                  <Button variant="primary" type="submit" isLoading={isLoading}>
                    Next
                  </Button>
                  <Button variant="primary" isLoading={isLoading} onClick={nextStep}>
                    Skip
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

OnlineTopupPersonalData.propTypes = {};

export default OnlineTopupPersonalData;
