import React from 'react';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';

export function PriceDetailsModal({ setModal }) {
  // Context
  const { t } = useStaticContent();
  const { activeTariff } = useActivationTariff();

  return (
    <Modal
      showCloseButton
      onCloseClick={() => {
        setModal('');
      }}
      header={
        activeTariff.id === 6501
          ? t('sh_apoc_tarif_overview_10_sub1')
          : t('sh_apoc_tarif_overview_15_sub1')
      }
    >
      {activeTariff.id === 6501 && (
        <div dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_10_text1') }} />
      )}

      {activeTariff.id === 6500 && (
        <div dangerouslySetInnerHTML={{ __html: t('sh_apoc_tarif_overview_15_text1') }} />
      )}
    </Modal>
  );
}

export default PriceDetailsModal;
