import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';

import { Button, InputFeedback, Select, Text } from '@core/Inputs';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';
import { usePersonalData } from '@context/MobileOne/Activation/PersonalData';

import { appActivationFlowType, appButtonType } from '@utils/globalConstant';
import { UTAG_LINK, UTAG_VIEWS, utag } from '@utils/utag';
import './style.scss';

export function PersonalData() {
  // State
  // const [DOBType, setDOBType] = useState('date');

  // Context
  const { isLoading, onSubmit, validateSchemaPersonalData, formValue } = usePersonalData();
  const { t } = useStaticContent();
  const { prevStep, countries, activationType, activationInfo } = useActivation();

  useEffect(() => {
    setTimeout(() => {
      const { isPromoApplied } = activationInfo;
      if (window && window.utag && activationType === appActivationFlowType.NEW_ONLINE) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.PERSONAL_DATA_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.ONLINE_CUSTOMER_MOUNTING.PERSONAL_DATA_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
      if (window && window.utag && activationType === appActivationFlowType.SIM_ACTIVATION) {
        const tagInfo = isPromoApplied
          ? UTAG_VIEWS.POS_CUSTOMER_MOUNTING.PERSONAL_DATA_MOUNTING.MGM_ACTIVE
          : UTAG_VIEWS.POS_CUSTOMER_MOUNTING.PERSONAL_DATA_MOUNTING.MGM_NON_ACTIVE;
        window.utag.view(tagInfo);
      }
    }, 500);
  }, [window.utag]);

  return (
    <Formik
      initialValues={formValue}
      validationSchema={validateSchemaPersonalData}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        values: {
          firstName,
          lastName,
          street,
          houseNumber,
          zip,
          city,
          countryCode,
          birthDate,
          hotlinePassword
        },
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        setFieldTouched,
        errors
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row position-relative">
              {/* <div className="col-12 orange-gray-bubble" /> */}
              <div className="col-md-3 pb-md-5 pb-4 mt-3 pt-3 pt-sm-5">
                <div className="back-btn">
                  <a
                    className="text-white body-medium-bold text-decoration-none link-light"
                    onClick={() => {
                      if (window && window.utag) {
                        activationType === appActivationFlowType.NEW_ONLINE
                          ? window.utag.link(
                              UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.PERSONAL_DATA.BACK_ON_CLICK
                            )
                          : window.utag.link(
                              UTAG_LINK.POS_CUSTOMER_ON_SUBMIT.PERSONAL_DATA.BACK_ON_CLICK
                            );
                      }

                      prevStep();
                    }}
                  >
                    <i className="fa fa-arrow-left me-3" />
                    {t('sh_back_button')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-3 mt-sm-5">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-1 mt-2 mb-2">
                <h6 className="text-uppercase text-white mb-4">
                  {t(
                    activationType === appActivationFlowType.SIM_ACTIVATION
                      ? 'sh_apoc_step7/9'
                      : 'sh_apoc_personal_step6'
                  )}
                </h6>
                <h1 className="pink-color-text personal-head mb-0">{t('sh_apoc_personal_h1')}</h1>
                <h6 className="text-white body-large-regular font-24 mt-4 pt-3">
                  {t('sh_apoc_personal_subheader1')}
                </h6>
              </div>

              <div className="col-12 col-md-10 offset-md-1 col-lg-7 offset-lg-1">
                <div className="row mt-2 personal-row-1">
                  <div className="col-sm-6">
                    <Text
                      id="firstName"
                      type="text"
                      placeholder={t('sh_apoc_personal_first-name_input')}
                      name="firstName"
                      value={firstName}
                      onChange={handleChange}
                      isValid={touched.firstName && !errors.firstName}
                      isInvalid={touched.firstName && errors.firstName}
                      error={touched.firstName && errors.firstName ? t(errors.firstName) : false}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="col-sm-6">
                    <Text
                      id="lastName"
                      type="text"
                      placeholder={t('sh_apoc_personal_last-name_input')}
                      name="lastName"
                      value={lastName}
                      onChange={handleChange}
                      isValid={touched.lastName && !errors.lastName}
                      isInvalid={touched.lastName && errors.lastName}
                      error={touched.lastName && errors.lastName ? t(errors.lastName) : false}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-10 offset-md-1 col-lg-7 offset-lg-1">
                <div className="row my-1">
                  <div className="col-8">
                    <Text
                      id="validationStreet"
                      type="text"
                      placeholder={t('sh_apoc_personal_street_input')}
                      name="street"
                      value={street}
                      onChange={handleChange}
                      isValid={touched.street && !errors.street}
                      isInvalid={touched.street && errors.street}
                      error={touched.street && errors.street ? t(errors.street) : false}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="col-4">
                    <Text
                      id="validationHouseNumber"
                      type="text"
                      placeholder={t('sh_apoc_personal_street-nr_input')}
                      name="houseNumber"
                      value={houseNumber}
                      onChange={handleChange}
                      isValid={touched.houseNumber && !errors.houseNumber}
                      isInvalid={touched.houseNumber && errors.houseNumber}
                      error={
                        touched.houseNumber && errors.houseNumber ? t(errors.houseNumber) : false
                      }
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-10 offset-md-1 col-lg-7 offset-lg-1">
                <div className="row">
                  <div className="col-4">
                    <Text
                      id="validationZip"
                      type="number"
                      placeholder={t('sh_apoc_personal_zip_input')}
                      name="zip"
                      value={zip}
                      onChange={handleChange}
                      isValid={touched.zip && !errors.zip}
                      isInvalid={touched.zip && errors.zip}
                      error={touched.zip && errors.zip ? t(errors.zip) : false}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="col-8">
                    <Text
                      id="validationCity"
                      type="text"
                      placeholder={t('sh_apoc_personal_town_input')}
                      name="city"
                      value={city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.city && !errors.city}
                      isInvalid={touched.city && errors.city}
                      error={touched.city && errors.city ? t(errors.city) : false}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-10 offset-md-1 col-lg-7 offset-lg-1">
                <div className="row personal-row-4">
                  {activationType === appActivationFlowType.SIM_ACTIVATION && (
                    <div className="col-12 col-sm-6 mt-3 pt-1">
                      <Select
                        // defaultOption={t('sh_apoc_personal_country_input')}
                        // defaultValue={t('sh_apoc_personal_country_input')}
                        defaultOption={t('sh_apoc_personal_location_country')}
                        id="countryCode"
                        name="countryCode"
                        options={countries}
                        value={countryCode}
                        onChange={(e) =>
                          handleChange({
                            target: {
                              id: 'countryCode',
                              name: 'countryCode',
                              value: e.target.value
                            }
                          })
                        }
                        getOptionId={(option) => option.id}
                        getOptionName={(option) => option.value}
                        isInvalid={!!touched.countryCode && !!errors.countryCode}
                        onBlur={handleBlur}
                        error={
                          touched.countryCode && errors.countryCode ? t(errors.countryCode) : false
                        }
                      />
                      {touched.countryCode && errors.countryCode && (
                        <InputFeedback isInvalid feedBackMessage={t(errors.countryCode)} />
                      )}
                    </div>
                  )}
                  <div className="col-12 col-sm-6 dob-div pt-1">
                    {/* onClick={() => setFieldTouched('birthDate')} */}
                    <Text
                      id="validationDate"
                      // type={DOBType}
                      type="date"
                      placeholder={t('sh_apoc_personal_birth-date_input')}
                      name="birthDate"
                      value={birthDate}
                      onChange={handleChange}
                      isValid={touched.birthDate && !errors.birthDate}
                      isInvalid={!!touched.birthDate && !!errors.birthDate}
                      error={touched.birthDate && errors.birthDate ? t(errors.birthDate) : false}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-5 mb-sm-0">
              <div className="col-12 col-sm-7 offset-sm-1">
                <h6 className="text-white heading-small mt-4 pt-1">
                  {t('sh_apoc_personal_subheader2')}
                </h6>
                <p
                  style={{ marginTop: '12px', maxWidth: '588px' }}
                  className="body-large-regular font-16 text-white mb-3 pb-3"
                >
                  {t('sh_apoc_personal_hotline-pin_text')}
                </p>
              </div>
              <div className="row pe-0 pe-sm-0">
                <div className="col-12 col-md-10 offset-md-1 col-lg-4 offset-lg-1 pe-0 pe-sm-0">
                  <Text
                    id="validationHotPass"
                    type="password"
                    placeholder={t('sh_apoc_personal_hotline-pin_input')}
                    name="hotlinePassword"
                    value={hotlinePassword}
                    onChange={handleChange}
                    isValid={touched.hotlinePassword && !errors.hotlinePassword}
                    isInvalid={touched.hotlinePassword && errors.hotlinePassword}
                    onBlur={handleBlur}
                    error={
                      touched.hotlinePassword && errors.hotlinePassword
                        ? t(errors.hotlinePassword)
                        : false
                    }
                  />
                  <p className="text-white body-medium-regular mb-2">
                    {t('sh_apoc_personal_hotline-pin_info')}
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-4 offset-sm-1 pd-submit mb-5 mt-5">
                <Button
                  buttonType={appButtonType.PRIMARY}
                  className="bg-offWhite-color w-100"
                  type="submit"
                  isLoading={isLoading}
                >
                  {t('sh_apoc_personal_next_button')}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default PersonalData;
