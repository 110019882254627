import React from 'react';
import { Col, Container, Row } from '@core/Layout';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { CustomerUsage } from '@part/DashBoard/CustomerUsage';
import { CustomerBalance } from '@part/DashBoard/CustomerBalance';
import { ReferFriend } from '@part/DashBoard/ReferFriend';

export function DashBoard() {
  // Context
  const { t } = useStaticContent();
  return (
    <Container>
      <Row>
        <Col md={{ offset: 4, span: 8 }}>
          <h4 className="mt-3 mb-3">{t('sh_db_h1')}</h4>
          <CustomerUsage />
          <div className="pt-3">
            <CustomerBalance />
          </div>
          <div className="pt-3">
            <ReferFriend />
          </div>
        </Col>
      </Row>
      {/* <hr />
      <div className="d-flex justify-content-between align-items-center">
        <h2>{t('dashboard-dashboard')}</h2>
        {isLoading && <BSpinner size="xl" animation="border" role="status" />}
      </div>
      <hr />
      <CustomerHeader />
      <hr />
      <div className="d-flex justify-content-between align-items-center">
        <h5>{t('dashboard-counter-and-usage')}</h5>
        {isLoading && <BSpinner size="sm" animation="border" role="status" />}
      </div>
      <hr />
      <Counter />
      <hr />
      {showBookedOptions && (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('dashboard-booked-options')}</h5>
            {isLoading && <BSpinner size="sm" animation="border" role="status" />}
          </div>
          <hr />
          <BookedOption />
          <hr />
        </>
      )}
      {showBookableOptions && (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('dashboard-bookable-options')}</h5>
            {isLoading && <BSpinner size="sm" animation="border" role="status" />}
          </div>
          <hr />
          <BookableOption />
          <hr />
        </>
      )}
      {showPassOffers && (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('dashboard-pass-offer')}</h5>
            {isLoading && <BSpinner size="sm" animation="border" role="status" />}
          </div>
          <hr />
          <PassOffer />
        </>
      )} */}
    </Container>
  );
}

export default DashBoard;
