import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_VIEWS } from '@utils/utag';
import { useLayout } from '@context/Utils';
// import { appStaticPdf } from '@utils/globalConstant';

export function CancellationRights() {
  // State
  // const [withdrawlPdf, setWithdrawilPdf] = useState({});
  const {
    t,
    staticContentData
    // mediaPdf
  } = useStaticContent();
  const { setMetaTags } = useMetaTag();
  const { setLightHeader } = useLayout();

  // useEffect(() => {
  //   if (mediaPdf?.length > 0) {
  //     setWithdrawilPdf(mediaPdf.find((item) => appStaticPdf.CANCELLATION_RIGHTS === item.name));
  //   }
  // }, [mediaPdf]);
  useEffect(() => {
    setLightHeader(false);

    return () => {
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.CANCELLATION_RIGHTS_MOUNTING);
    }
  }, [window.utag]);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_legal_widerruf_meta_title'),
      description: t('sh_web_page_legal_widerruf_meta_description'),

      twitterTitle: t('sh_web_page_legal_widerruf_meta_title'),
      twitterDescription: t('sh_web_page_legal_widerruf_meta_description'),

      ogTitle: t('sh_web_page_legal_widerruf_meta_title'),
      ogDescription: t('sh_web_page_legal_widerruf_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  return (
    // <section className="position-relative bg-offWhite-color gray-shade-1 py-5 pb-0 pb-sm-5 mb-5 mb-sm-0">
    <section className="position-relative bg-offWhite-color pt-5 pb-0">
      <div className="container pt-0 pt-lg-5 py-5 mt-0 mt-lg-4">
        <div className="row py-5 pb-0 pb-sm-5">
          <div className="col-xl-7 offset-xl-1 col-md-8 offset-md-1 col-12">
            <h1
              className="primary-color-text my-5"
              dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_h1') }}
            />
            <div
              className="body-large-bold primary-color-text"
              dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_subheadline') }}
            />
            <div
              className="body-large-regular primary-color-text my-4 mt-0"
              dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_text') }}
            />
            <div
              className="body-large-regular primary-color-text mb-0"
              dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_text2') }}
            />
            {/* <strong className="body-large-bold primary-color-text">Deinem Widerrufsrecht: </strong> */}
            <div
              className="body-large-regular primary-color-text d-block m-0"
              dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_pdf_download') }}
            />
          </div>
        </div>
      </div>
      {/* <Container>
        <br />
        <br />
        <Row>
          <Col md={6} xs={12}>
            <div dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_h1') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_subheadline') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_text') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_text2') }} />
            <a
              className="btn-link"
              target="_blank"
              onClick={() => window.open(withdrawlPdf.media_url)}>
              <div
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_pdf_download') }}
              />
            </a>
            <div dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_subheadline2') }} />
            <div dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_withdrawal_text3') }} />
          </Col>
        </Row>
      </Container> */}
    </section>
  );
}

export default CancellationRights;
