import React from 'react';
import PropTypes from 'prop-types';

export function CardBody({ sx, children }) {
  return (
    <div className="card-body" {...{ style: sx }}>
      {children}
    </div>
  );
}

CardBody.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

CardBody.defaultProps = {
  sx: {}
};

export default CardBody;
