import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { useAlert } from '@context/Utils';
import {
  DEFAULT_FIELD_VALIDATION_TYPE,
  StatusCodes
} from '@dom-digital-online-media/dom-app-config-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { appAlert, formValidation } from '@utils/globalConstant';

export const VoucherContext = createContext();

export function VoucherProvider({ children }) {
  // Context
  const { isUserLoggedIn } = useAuth();
  const { onVoucherPaysafe, onVoucherTDM } = useMobileOne();
  const { showAlert } = useAlert();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [voucherCodeForm, setVoucherCodeForm] = useState({ voucherCode: '', type: 'TDM1' });

  // Validation
  const voucherValidations = Yup.object().shape({
    voucherCode: formValidation({
      required: 'please_select_paymentMethod',
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING,
      minLength: 13,
      minLengthError: 'min_length_error',
      maxLength: 16,
      maxLengthError: 'max_length_error'
    })
  });

  // Function
  const onLoad = () => {
    // TODO: Exten it with api call to fetch all voucher history.
    // console.log("Requesting");
  };

  const onSubmitTDM1 = async (code) => {
    try {
      const { data, success, status } = await onVoucherPaysafe(code);
      console.log({ data, success, status });
      if (success || status === StatusCodes.OK) {
        showAlert({ type: appAlert.SUCCESS, message: 'Voucher redeemed successfull.' });
        setVoucherCodeForm('');
      }
    } catch (error) {
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: 'Error while processing your request.' });
    }
  };

  const onSubmitTDM2 = async (code) => {
    try {
      const { data, success, status } = await onVoucherTDM(code);
      console.log({ data, success, status });
      if (success || status === StatusCodes.OK) {
        showAlert({ type: appAlert.SUCCESS, message: 'Voucher redeemed successfull.' });
        setVoucherCodeForm('');
      }
    } catch (error) {
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: 'Error while processing your request.' });
    }
  };

  const onSubmit = async (values) => {
    if (values.type === 'TDM1') {
      await onSubmitTDM1(values.voucherCode);
    }
    if (values.type === 'TDM2') {
      await onSubmitTDM2(values.voucherCode);
    }
  };

  // Hooks
  useEffect(() => {
    if (isUserLoggedIn) {
      onLoad();
    }
    return () => {
      // setHistory([]);
    };
  }, [isUserLoggedIn]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      voucherCodeForm,
      setVoucherCodeForm,

      // Validation
      voucherValidations,

      // Functions
      onLoad,
      onSubmit,
      onSubmitTDM1,
      onSubmitTDM2
    }),
    [
      // States
      isLoading,
      setIsLoading,
      voucherCodeForm,
      setVoucherCodeForm,

      // Validation
      voucherValidations,

      // Functions
      onLoad,
      onSubmit,
      onSubmitTDM1,
      onSubmitTDM2
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <VoucherContext.Provider value={contextPayload}>{children}</VoucherContext.Provider>;
}

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useVoucher = () => useContext(VoucherContext);

export default VoucherProvider;
