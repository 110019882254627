import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Col, Container, Row } from '@core/Layout';
import { Button } from '@core/Inputs';
import { appRoute, appStaticPdf } from '@utils/globalConstant';

export function EvnNotAllowed() {
  // State
  const [evnPdf, setEvnPdf] = useState({});

  // Context
  const navigate = useNavigate();
  const { t, mediaPdf } = useStaticContent();

  useEffect(() => {
    if (mediaPdf?.length > 0) {
      setEvnPdf(mediaPdf.find((item) => appStaticPdf.ORDER_ITEMIZED_BILL === item.name));
    }
  }, [mediaPdf]);

  return (
    <Container className="px-5">
      {/* Page Title */}
      <Row>
        <Col xs={2}>
          <Button
            className="p-0 mt-4"
            variant="link"
            onClick={() => navigate(appRoute.TARIFF_OPTION)}
          >
            <i className="bi bi-chevron-left" style={{ fontSize: '2rem' }} />
          </Button>
        </Col>
        <Col xs={8}>
          <center>
            <br />
            <h3>{t('sh_tab_two_evn_headline')}</h3>
          </center>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12}>
          <p>{t('sh_tab_two_evn_subheadline2')}</p>
        </Col>
        <Col xs={12} className="d-grid gap-2 mt-4">
          <Button variant="dark" onClick={() => window.open(evnPdf.media_url)}>
            {t('sh_tab_two_evn_pdf_button2')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default EvnNotAllowed;
