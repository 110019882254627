/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { Button, Link, Switch } from '@core/Inputs';
import { appActivationFlowType, appButtonType } from '@utils/globalConstant';
import LegalModal from './LegalModal';
import { UTAG_LINK, utag } from '@utils/utag';
import { useActivation } from '@context/MobileOne';
import { Modal, Spinner } from '@core/Utils';

import './style.scss';

export function Terms({
  activationInfo,
  handleChange,
  handleBlur,
  setActivationInfo,
  errors,
  touched,
  marketingMultibrand
}) {
  // Constants

  // States
  const [openModal, setOpenModal] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [openDoc, setOpenDoc] = useState(false);

  // Contexts
  const { t, staticContentData } = useStaticContent();
  const { activationType } = useActivation();
  const { getVVIDocuments, vviDocuments, isVviDocLoading } = useActivationTariff();

  // Hooks
  useEffect(() => {
    if (staticContentData) {
      const pdModal = document.querySelector('#pdModal');
      if (pdModal) {
        pdModal.addEventListener('click', () => {
          setOpenModal(true);
          setModalValue('product_service');
        });
      }
      const contractDataModal = document.querySelector('#contractDataModal');
      if (contractDataModal) {
        contractDataModal.addEventListener('click', () => {
          setOpenModal(true);
          setModalValue('contract_data');
        });
      }
      const useageDataModal = document.querySelector('#useageDataModal');
      if (useageDataModal) {
        useageDataModal.addEventListener('click', () => {
          setOpenModal(true);
          setModalValue('useage_data');
        });
      }
      const terminalDataModal = document.querySelector('#terminalDataModal');
      if (terminalDataModal) {
        terminalDataModal.addEventListener('click', () => {
          setOpenModal(true);
          setModalValue('terminal_data');
        });
      }
      const withdrawalModal = document.querySelector('#withdrawalModal');
      if (withdrawalModal) {
        withdrawalModal.addEventListener('click', () => {
          setOpenModal(true);
          setModalValue('withdrawal_modal');
        });
      }
      const openDocModal = document.querySelector('#openDocModal');
      if (openDocModal) {
        openDocModal.addEventListener('click', () => {
          setOpenDoc(true);
        });
      }
    }
  }, [staticContentData]);

  useEffect(() => {
    getVVIDocuments();
  }, []);

  // NOTE: downloadFile() didn't work in browsers on android and in-app facebook and instagram browsers on android
  // function downloadFile(url) {
  //   fetch(url)
  //     .then((response) => {
  //       // Extracting filename from the exposed content disposition header
  //       // const contentDispositionHeader = response.headers.get('content-disposition');
  //       // let filename = 'file.pdf';
  //       // if (contentDispositionHeader) {
  //       //   filename = contentDispositionHeader.split('filename=')[1].split(';')[0]; // split string at 'filename=' and get the second part and then split the result at ;
  //       // }

  //       // Extracting the name from the url
  //       const filename = url.split('/').pop();
  //       return response.blob().then((blob) => ({ blob, filename }));
  //     })
  //     .then(({ blob, filename }) => {
  //       const blobURL = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = blobURL;
  //       link.setAttribute('download', filename);
  //       link.click();
  //       setTimeout(() => {
  //         window.URL.revokeObjectURL(blobURL);
  //       }, 100);
  //     })
  //     .catch((error) => {
  //       console.error('Error downloading file:', error);
  //     });
  // }

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1 mt-2 mt-sm-3">
          <hr className="border-light-purple border-1 mb-5" style={{ opacity: 0.5 }} />
          <h6 className="heading-small pink-color-text mb-3">
            {t('sh_apoc_summary_telekom_subheader')}
          </h6>
          <div className="d-flex justify-content-between">
            <div
              // style={{ fontSize: '20px' }}
              className="body-large-regular text-white terms-1"
              dangerouslySetInnerHTML={{
                __html: t('sh_apoc_summary_telekom_text1')
              }}
            />
            <Switch
              // label={t('sh_apoc_summary_telekom_text1')}
              type="secondary"
              name="marketingMultibrand"
              id="marketingMultibrand"
              isDefaultChecked={activationInfo.marketingMultibrand}
              onChange={() => {
                if (window && window.utag) {
                  activationType === appActivationFlowType.NEW_ONLINE
                    ? window.utag.link(
                        !marketingMultibrand
                          ? UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.ENABLE_INFOSERVICE_ON_CLICK
                          : UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.DISABLE_INFOSERVICE_ON_CLICK
                      )
                    : window.utag.link(
                        !marketingMultibrand
                          ? UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.ENABLE_INFOSERVICE_ON_CLICK
                          : UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.DISABLE_INFOSERVICE_ON_CLICK
                      );
                }

                handleChange({
                  target: {
                    id: 'marketingMultibrand',
                    name: 'marketingMultibrand',
                    value: !activationInfo.marketingMultibrand
                  }
                });
                setActivationInfo({
                  ...activationInfo,
                  marketingMultibrand: !activationInfo.marketingMultibrand
                });
              }}
            />
          </div>

          <hr className="border-light-purple border-1 mt-4 mb-5" />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-9 offset-md-1 col-lg-7 offset-lg-1 col-xl-6 offset-xl-1 mt-0 mt-sm-3">
          <h6 className="pink-color-text heading-small mb-3">
            <span dangerouslySetInnerHTML={{ __html: t('sh_apoc_summary_legal-notice_h') }} />
          </h6>
          <Switch
            label={`${t('sh_apoc_summary_legal-notice_text1')}`}
            id="termsAndConditions"
            type="secondary"
            name="termsAndConditions"
            isDefaultChecked={activationInfo.termsAndConditions}
            onChange={() => {
              if (window && window.utag) {
                activationType === appActivationFlowType.NEW_ONLINE
                  ? window.utag.link(
                      !activationInfo.termsAndConditions
                        ? UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.ACCEPT_PRIVACY_ON_CLICK
                        : UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.ACCEPT_TERMS_ON_CLICK
                    )
                  : window.utag.link(
                      !activationInfo.termsAndConditions
                        ? UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.ACCEPT_PRIVACY_ON_CLICK
                        : UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.ACCEPT_TERMS_ON_CLICK
                    );
              }

              handleChange({
                target: {
                  id: 'termsAndConditions',
                  name: 'termsAndConditions',
                  value: !activationInfo.termsAndConditions
                }
              });
              setActivationInfo({
                ...activationInfo,
                termsAndConditions: !activationInfo.termsAndConditions
              });
            }}
            isValid={touched.termsAndConditions && !errors.termsAndConditions}
            isInvalid={!!touched.termsAndConditions && !!errors.termsAndConditions}
            error={
              touched.termsAndConditions && errors.termsAndConditions
                ? errors.termsAndConditions
                : null
            }
          />
          <div className="body-medium-regular text-white mt-3 mb-5">
            <div
              dangerouslySetInnerHTML={{ __html: t('sh_apoc_summary_legal-notice_text2') }}
              onClick={() => {
                if (window && window.utag) {
                  activationType === appActivationFlowType.NEW_ONLINE
                    ? window.utag.link(
                        UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.CONTRACT_SUMMARY_ON_CLICK
                      )
                    : window.utag.link(
                        UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW.CONTRACT_SUMMARY_ON_CLICK
                      );
                }
                setOpenDoc(true);
              }}
            />
            {/* <Button
              buttonType={appButtonType.LINK}
              type="button"
              id="openDocModal"
              className="shadow-none text-white body-medium-regular link-light btn btn-link p-0"
            >
              {t('sh_sm_switch_summary_legal_notice_text2')}
            </Button> */}
            {/* <span
              dangerouslySetInnerHTML={{ __html: t('sh_sm_switch_summary_legal_notice_text3') }}
            /> */}
          </div>

          <Switch
            label={t('sh_apoc_summary_legal-notice_text3')}
            type="secondary"
            name="acceptedCreditCheck"
            id="acceptedCreditCheck"
            isDefaultChecked={activationInfo.acceptedCreditCheck}
            onChange={() => {
              if (window && window.utag) {
                activationType === appActivationFlowType.NEW_ONLINE
                  ? window.utag.link(
                      !activationInfo.acceptedCreditCheck
                        ? UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW
                            .ACCEPT_WITHDRAWL_RIGHTS_ON_CLICK
                        : UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW
                            .NOT_ACCEPT_WITHDRAWL_RIGHTS_ON_CLICK
                    )
                  : window.utag.link(
                      !activationInfo.acceptedCreditCheck
                        ? UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW
                            .ACCEPT_WITHDRAWL_RIGHTS_ON_CLICK
                        : UTAG_LINK.ONLINE_CUSTOMER_ON_SUBMIT.OVERIEW
                            .NOT_ACCEPT_WITHDRAWL_RIGHTS_ON_CLICK
                    );
              }

              handleChange({
                target: {
                  id: 'acceptedCreditCheck',
                  name: 'acceptedCreditCheck',
                  value: !activationInfo.acceptedCreditCheck
                }
              });
              setActivationInfo({
                ...activationInfo,
                acceptedCreditCheck: !activationInfo.acceptedCreditCheck
              });
            }}
          />
          <hr className="border-light-purple border-1 mt-4 mb-5 d-none d-sm-block" />
        </div>
        {/* <div className="col" xs={9}>
          <p
            className="text-white"
            dangerouslySetInnerHTML={{ __html: t('sh_apoc_summary_legal-notice_text1') }}
          />
          <p className="text-white">
            {t('sh_sm_switch_summary_legal_notice_text1')}{' '}
            <Button
              buttonType={appButtonType.LINK}
              variant="link"
              className="text-white p-0"
              onClick={() => {
                setOpenDoc(true);
              }}>
              {t('sh_sm_switch_summary_legal_notice_text2')}
            </Button>{' '}
            {t('sh_sm_switch_summary_legal_notice_text3')}
          </p>
        </div> */}
      </div>

      {openModal && (
        <LegalModal
          modalValue={modalValue}
          setModalValue={setModalValue}
          setOpenModal={setOpenModal}
        />
      )}

      {openDoc && (
        <Modal
          showCloseButton
          closeButtonText="sh_apoc_tarif_overview_tarif-details_cancel"
          onCloseClick={() => {
            setOpenDoc(false);
          }}
          header={t('sh_sm_switch_summary_subheadline4')}
        >
          <h6
            className="body-large-regular primary-color-text mb-3"
            dangerouslySetInnerHTML={{ __html: t('sh_sm_switch_summary_legal_notice_h') }}
          />
          <a
            href={vviDocuments.vfzUrl}
            target="_blank"
            // onClick={() => downloadFile(vviDocuments.vfzUrl)}
            className="w-100 mb-2 btn vvi-btn vvi-btn-link d-flex align-items-center justify-content-center text-primary"
          >
            {isVviDocLoading ? <Spinner /> : t('sh_apoc_einwilligungen_button1')}
          </a>
          <a
            href={vviDocuments.agbUrl}
            target="_blank"
            // onClick={() => downloadFile(vviDocuments.agbUrl)}
            className="w-100 mb-2 btn vvi-btn vvi-btn-link d-flex align-items-center justify-content-center text-primary"
          >
            {isVviDocLoading ? <Spinner /> : t('sh_apoc_einwilligungen_button2')}
          </a>
          <a
            href={vviDocuments.preislisteUrl}
            target="_blank"
            // onClick={() => downloadFile(vviDocuments.preislisteUrl)}
            className="w-100 mb-2 btn vvi-btn vvi-btn-link d-flex align-items-center justify-content-center text-primary"
          >
            {isVviDocLoading ? <Spinner /> : t('sh_apoc_einwilligungen_button3')}
          </a>
          <a
            href={vviDocuments.leistungsbeschreibungUrl}
            target="_blank"
            // onClick={() => downloadFile(vviDocuments.leistungsbeschreibungUrl)}
            className="w-100 mb-2 btn vvi-btn vvi-btn-link d-flex align-items-center justify-content-center text-primary"
          >
            {isVviDocLoading ? <Spinner /> : t('sh_apoc_einwilligungen_button4')}
          </a>
        </Modal>
      )}
      {/* <Modal
        show={openDoc}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setOpenDoc(false)}>
        <ModalHeader closeButton>
          <ModalTitle>{t('sh_sm_switch_summary_legal_notice_h')}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <ListGroup>
            <ListGroupItem>
              <button className="btn btn-link" type="button" id="docA">
                VZF
              </button>
            </ListGroupItem>
            <ListGroupItem>
              <button className="btn btn-link" type="button" id="docB">
                AGB
              </button>
            </ListGroupItem>
            <ListGroupItem>
              <button className="btn btn-link" type="button" id="docC">
                PREISLISTE
              </button>
            </ListGroupItem>
            <ListGroupItem>
              <button className="btn btn-link" type="button" id="docD">
                LEISTUNGSBESCHREIBUNG
              </button>
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
      </Modal> */}
    </>
  );
}

Terms.propTypes = {
  activationInfo: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({})
};
Terms.defaultProps = {
  handleChange: () => {},
  handleBlur: () => {},
  touched: () => {},
  errors: {}
};
export default Terms;
