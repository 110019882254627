import React from 'react';
import PropTypes from 'prop-types';
import { AccountContextProvider } from './Account';
/* eslint-disable */
import { ActivationContextProvider } from './Activation';

import { CustomerContextProvider } from './Customer';
import { DocumentContextProvider } from './Document';
import { LookUpContextProvider } from './LookUp';
import { NewsContextProvider } from './News';
import { TariffOptionProvider } from './TariffOption';
import { VoucherContextProvider } from './Voucher';
import { DashBoardContextProvider } from './DashBoard';
import { RechargeContextProvider } from './recharge';

export function MobileOneProvider({ children, config }) {
  return (
    <>
      {/* Customer Context Provider */}
      <CustomerContextProvider config={config}>
        {/* Tariff & Option Context Provider */}
        <TariffOptionProvider>
          {/* Sim Activation Context Provider */}
          <ActivationContextProvider>
            {/* User Account Context Provider */}
            <AccountContextProvider config={config}>
              {/* Voucher Context Provider */}
              <VoucherContextProvider>
                {/* Lookup Context Provider */}
                <LookUpContextProvider>
                  {/* Document Context Provider */}
                  <DocumentContextProvider>
                    {/* News Context Provider */}
                    <NewsContextProvider>
                      {/* DashBoard Context Provider */}
                      <DashBoardContextProvider>
                        {/* Recharge Context Provider */}
                        <RechargeContextProvider>{children}</RechargeContextProvider>
                      </DashBoardContextProvider>
                    </NewsContextProvider>
                  </DocumentContextProvider>
                </LookUpContextProvider>
              </VoucherContextProvider>
            </AccountContextProvider>
          </ActivationContextProvider>
        </TariffOptionProvider>
      </CustomerContextProvider>
    </>
  );
}

MobileOneProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default MobileOneProvider;
/* eslint-disable */
export * from './Activation';
export * from './Account';

export * from './Customer';
export * from './Document';
export * from './LookUp';
export * from './News';
export * from './TariffOption';
export * from './Voucher';
