import React, { useEffect, useState } from 'react';
import { Image, QrCode } from '@core/Utils';
import { appImages, deviceRegex } from '@utils/globalConstant';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import './style.scss';
import { useMetaTag } from '@context/Utils/MetaTag';
import { UTAG_LINK, UTAG_VIEWS } from '@utils/utag';
import { useLayout } from '@context/Utils';

export function TopupCredit() {
  // States
  const [isIosDevice, setIsIosDevice] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);

  // Contexts
  const { setLightHeader } = useLayout();
  const { t, staticContentData } = useStaticContent();
  const { setMetaTags } = useMetaTag();

  //  Hooks
  useEffect(() => {
    setLightHeader(false);
    let deviceInfo = navigator.userAgent;
    setIsIosDevice(deviceRegex.ISIOS.test(deviceInfo));
    setIsAndroidDevice(deviceRegex.ISANDRIOD.test(deviceInfo));

    return () => {
      setLightHeader(true);
    };
  }, []);

  useEffect(() => {
    if (window && window.utag) {
      window.utag.view(UTAG_VIEWS.TOPUP_CREDIT_MOUNTING);
    }
  }, [window.utag]);

  useEffect(() => {
    setMetaTags({
      title: t('sh_web_page_info_aufladen_meta_title'),
      description: t('sh_web_page_info_aufladen_meta_description'),

      twitterTitle: t('sh_web_page_info_aufladen_meta_title'),
      twitterDescription: t('sh_web_page_info_aufladen_meta_description'),

      ogTitle: t('sh_web_page_info_aufladen_meta_title'),
      ogDescription: t('sh_web_page_info_aufladen_meta_description')
    });

    return () => {
      setMetaTags({
        title: '',
        description: '',

        twitterTitle: '',
        twitterDescription: '',

        ogTitle: '',
        ogDescription: ''
      });
    };
  }, [staticContentData]);

  return (
    // <Container>
    //   <br />
    //   <br />
    //   <Row>
    //     <Col md={6} xs={12} className="mb-5">
    //       <h1 dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_h1') }} />
    //       <p dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_text_1') }} />
    //       <p dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_text_2') }} />
    //     </Col>
    //     <Col md={6} xs={12} className="mb-5">
    //       <b>
    //         <p dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_subtext1') }} />
    //         <p dangerouslySetInnerHTML={{ __html: t('sh_web_download_app_subtext2') }} />
    //       </b>
    //       {!isAndroidDevice && !isIosDevice && (
    //         <>
    //           <QrCode
    //             size={230}
    //             value={t('sh_web_download_app_qr_text')}
    //             qrheight="auto"
    //             qrwidth="auto"
    //           />
    //           <br />
    //           <br />
    //           <Button
    //             className="btn-dark"
    //             href={t('sh_web_play_store_link')}
    //             to={t('sh_web_play_store_link')}
    //             target="_blank"
    //           >
    //             Google Play
    //           </Button>
    //           &ensp; &ensp;
    //           <Button
    //             className="btn-dark"
    //             href={t('sh_web_app_store_link')}
    //             to={t('sh_web_app_store_link')}
    //             target="_blank"
    //           >
    //             App Store
    //           </Button>
    //         </>
    //       )}
    //       {isAndroidDevice && (
    //         <Button
    //           className="btn-dark"
    //           href={t('https://www.google.com/')}
    //           to={t('https://www.google.com/')}
    //           target="_blank"
    //         >
    //           Google Play
    //         </Button>
    //       )}
    //       {isIosDevice && (
    //         <Button
    //           className="btn-dark"
    //           href={t('sh_web_app_store_link')}
    //           to={t('sh_web_app_store_link')}
    //           target="_blank"
    //         >
    //           App Store
    //         </Button>
    //       )}
    //     </Col>
    //   </Row>
    //   <br />
    //   <Row>
    //     <Col xs={6}>
    //       <h2 dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_subheadline') }}></h2>
    //       <br />
    //     </Col>
    //     <Row className="px-4">
    //       <Col md={1} xs={3} className="bg-dark py-3 text-center">
    //         <h4 className="text-white">REWE</h4>
    //       </Col>
    //       &ensp; &ensp; &ensp;
    //       <Col md={1} xs={3} className="bg-dark py-3 text-center">
    //         <h4 className="text-white">DM</h4>
    //       </Col>
    //     </Row>
    //     <Col xs={12}>
    //       <br />
    //       <p dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_text3') }}></p>
    //     </Col>
    //   </Row>
    // </Container>
    <>
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 py-5"> */}
      <section className="position-relative bg-offWhite-color pt-5">
        {/* <div className="container pt-0 pt-lg-5 py-5 mt-0 mt-lg-4"> */}
        <div className="container pt-0 pt-lg-5 mt-0 mt-lg-4">
          <div className="row py-5">
            {/* <div className="col-12 position-relative">
              <div className="orange-white-bubble"></div>{' '}
            </div> */}
            <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-8 col-12">
              <h1
                className="primary-color-text my-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_h1') }}
              />
              <div
                className="body-large-regular primary-color-text mb-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_text') }}
              />
              <div className="d-block d-md-none py-5"></div>
              <div
                className="position-absolute d-block d-lg-none z-1 bottom-0 end-0"
                style={{ marginBottom: '-50px' }}
              >
                {/* <img
                    className="mx-100"
                    src={appImages.sharemobileappaufladen_m}
                    alt="aufladen app"
                  /> */}
                <Image
                  className="mx-100"
                  refs="sh_web_lg_recharge_content-1"
                  resRefs="sh_web_md_recharge_content-1"
                  alt="aufladen app"
                  mobileStyle={{ height: '238', width: '260' }}
                  ipadStyle={{ height: '260', width: '292' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="position-absolute d-none d-lg-block z-1 bottom-0 end-0"
          style={{ marginBottom: '-50px' }}
        >
          {/* <img className="mx-100" src={appImages.sharemobileappaufladen} alt="aufladen app" /> */}
          <Image
            className="mx-100"
            refs="sh_web_lg_recharge_content-1"
            resRefs="sh_web_md_recharge_content-1"
            alt="aufladen app"
          />
        </div>
      </section>
      <section className="position-relative py-5">
        <div className="container pb-0 pb-lg-5 mb-0 mb-sm-3 mb-lg-0">
          <div className="row">
            <div className="col-xl-6 offset-xl-1 col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
              <h4
                className="primary-color-text my-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_h2') }}
              />
              <div
                className="body-large-regular primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_text_1') }}
              />
              <h5
                className="primary-color-text mb-4 cnt"
                dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_h3') }}
              />
              <div
                className="body-large-regular primary-color-text mb-4"
                dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_text_2') }}
              />
              <h5
                className="primary-color-text mb-4 cnt"
                dangerouslySetInnerHTML={{ __html: t('sh_web_mobile_app_h3') }}
              />
              <div className="d-flex flex-wrap flex-column flex-sm-row pb-0 pb-sm-5 pb-lg-0">
                {!isAndroidDevice && !isIosDevice && (
                  <>
                    <span className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0">
                      <img className="mx-100 grow" src={appImages.qrcode} alt="QR code" />{' '}
                    </span>

                    <a
                      className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      // href={t('sh_web_play_store_link')}
                      // to={t('sh_web_play_store_link')}
                      // target="_blank"
                      target="_blank"
                      href={t('sh_ap_playstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                    </a>
                    <a
                      className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                      // href={t('sh_web_app_store_link')}
                      // to={t('sh_web_app_store_link')}
                      // target="_blank"
                      target="_blank"
                      href={t('sh_ap_appstore')}
                      onClick={() => {
                        if (window && window.utag) {
                          window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                        }
                      }}
                    >
                      <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                    </a>
                  </>
                )}
                {isAndroidDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    // href={t('https://www.google.com/')}
                    // to={t('https://www.google.com/')}
                    // target="_blank"
                    target="_blank"
                    href={t('sh_ap_playstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.PLAY_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </a>
                )}
                {isIosDevice && (
                  <a
                    className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex"
                    // href={t('sh_web_app_store_link')}
                    // to={t('sh_web_app_store_link')}
                    // target="_blank"
                    target="_blank"
                    href={t('sh_ap_appstore')}
                    onClick={() => {
                      if (window && window.utag) {
                        window.utag.link(UTAG_LINK.HEADER_ON_CLICK.APP_STORE_ICON_ON_CLICK);
                      }
                    }}
                  >
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </a>
                )}
                {/* <span className="pe-0 pe-sm-3 pb-3 pb-sm-0 border-0">
                    <img className="mx-100" src={appImages.qrcode} alt="QR code" />
                  </span>
                  <button className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex">
                    <img className="mx-100" src={appImages.androidapp} alt="Android app" />
                  </button>
                  <button className="pe-0 ps-0 pe-sm-3 pb-3 pb-sm-0 bg-transparent border-0 d-flex">
                    <img className="mx-100" src={appImages.iosapp} alt="iOS app" />
                  </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="position-relative bg-offWhite-color gray-shade-1 gray-shade-3 pt-5 mb-5 mb-lg-0"> */}
      <section className="position-relative bg-offWhite-color pt-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 offset-xl-1 col-lg-5 offset-lg-2 col-md-8 offset-md-1 col-12">
              <h4
                className="primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_subheadline') }}
              />
            </div>

            {/* NOTE: NEXT OLD PART can be deleted here in code from 03.10.2023 on */}

            {/* ---------------------------------------------------------------- */}
            {/* IMPORTANT: OLD PART without DM logo, ONLY valid UNTIL 01.10.2023 */}
            {/* ---------------------------------------------------------------- */}
            {/*
            <div className="col-xl-5 col-lg-4 col-md-8 offset-md-1 col-12 my-0 my-lg-5 pb-5">
              <div className="pt-0 pt-lg-2 d-none d-lg-block">&nbsp;</div>
              <div
                className="body-large-regular primary-color-text m-0 mt-5 pt-0 pt-lg-5"
                dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_subheadline2') }}
              />
              <button
                type="button"
                className="btn btn-primary my-4 px-4"
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.AUFLADEN_ON_CLICK.REWE_MARKET_ON_CLICK);
                  }
                  window.open(t('sh_web_guthabenaufladen_rewe'), '_blank');
                }}
              >
                <img className="mx-100" src={appImages.rewelogo} alt="REWE Logo" />
                <strong className="px-4 label-medium rewe-cnt">REWE Markt</strong>
                <img className="mx-100" src={appImages.rt_arw} alt="right arrow" />
              </button>
            */}

            {/* --------------------------------------------------------------- */}
            {/* IMPORTANT: NEW PART with DM logo, ONLY valid FROM 02.10.2023 ON */}
            {/* --------------------------------------------------------------- */}
            <div className="col-xl-6 offset-xl-1 my-xl-5 col-lg-7 offset-lg-2 my-xl-2 col-md-8 offset-md-1 col-12 my-0 pb-5">
              <div
                className="body-large-regular primary-color-text m-0 mt-xl-5 mt-2 pt-0 pt-xl-5 pt-lg-3 pt-2"
                dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_subheadline2') }}
              />
              <div className="d-inline-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-center py-4 py-lg-0">
                <button
                  type="button"
                  className="btn btn-primary my-2 my-lg-4 me-md-3 px-4 d-inline-flex align-items-center justify-content-center"
                  onClick={() => {
                    if (window && window.utag) {
                      window.utag.link(UTAG_LINK.AUFLADEN_ON_CLICK.REWE_MARKET_ON_CLICK);
                    }
                    window.open(t('sh_web_guthabenaufladen_dm'), '_blank');
                  }}
                >
                  <img className="mx-100" src={appImages.dmlogo} alt="dm Logo" />
                  <strong className="px-4 label-medium rewe-cnt">
                    {t('sh_web_top_up_markt1')}
                  </strong>
                  <img className="mx-100" src={appImages.rt_arw} alt="right arrow" />
                </button>
                <button
                  type="button"
                  className="btn btn-primary my-2 my-lg-4 px-4 d-inline-flex align-items-center justify-content-center"
                  onClick={() => {
                    if (window && window.utag) {
                      window.utag.link(UTAG_LINK.AUFLADEN_ON_CLICK.REWE_MARKET_ON_CLICK);
                    }
                    window.open(t('sh_web_guthabenaufladen_rewe'), '_blank');
                  }}
                >
                  <img className="mx-100" src={appImages.rewelogo} alt="REWE Logo" />
                  <strong className="px-4 label-medium rewe-cnt">
                    {t('sh_web_top_up_markt2')}
                  </strong>
                  <img className="mx-100" src={appImages.rt_arw} alt="right arrow" />
                </button>
              </div>

              <div
                // className="body-large-regular primary-color-text pb-0 pb-sm-5 mb-0 mb-xl-5"
                className="body-large-regular primary-color-text"
                dangerouslySetInnerHTML={{ __html: t('sh_web_top_up_text3') }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TopupCredit;
