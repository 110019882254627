import React, { useEffect } from 'react';
import { Col, Container, Row } from '@core/Layout';
import { Button } from '@core/Inputs';
import { appRoute } from '@utils/globalConstant';
import { useNavigate } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivationTariff } from '@context/MobileOne/Activation/ActivationTariff';
import { utag, UTAG_VIEWS } from '@utils/utag';

export function Login() {
  // State

  // Context
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const { initiatePOSPurchas, initiateOldCustomerPurchase } = useActivationTariff();

  // Hooks
  // Default hooks
  useEffect(() => {
    utag.view(UTAG_VIEWS.LOGIN_MOUNTING);
  }, []);

  return (
    <Container>
      <center>
        <br />
        <Row>
          <Col xs={12} className="mb-4">
            <h1>{t('sh_login_share')}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <b>{t('sh_login_subheader1')}</b>
            <br />
            <Button variant="link" onClick={initiatePOSPurchas}>
              {t('sh_login_text1')}
            </Button>
            <br />
            <br />
            <b>{t('sh_login_subheader2')}</b>
            <br />
            <Button variant="link" onClick={initiateOldCustomerPurchase}>
              {t('sh_login_text2')}
            </Button>
            <br />
            <br />
            <Button variant="link" onClick={() => navigate(appRoute.CANCEL_CONTRACT)}>
              {t('sh_login_cancel_contract')}
            </Button>
            <br />
            <br />
            <h5>{t('sh_login_congstar')}</h5>
            <br />
            <Button
              variant="link"
              onClick={() => {
                navigate(appRoute.DOWNLOAD_APP);
              }}
            >
              {t('Download Share App')}
            </Button>
          </Col>
        </Row>
      </center>
    </Container>
  );
}

export default Login;
