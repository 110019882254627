import React from 'react';
import { Modal } from '@core/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function DeactivateSwitzerlandRoamingModal({ setModal }) {
  // Context
  const { t } = useStaticContent();

  return (
    <Modal
      showCloseButton
      onCloseClick={() => {
        setModal('');
      }}
      header={t('sh_web_roaming_pup3_h1')}
    >
      <h5
        className="mt-5 primary-color-text"
        dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup3_sub1') }}
      />
      <div className="py-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup3_text1') }} />
      <h5
        className="primary-color-text"
        dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup3_sub2') }}
      />
      <div className="py-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup3_text2') }} />
      <h5
        className="primary-color-text"
        dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup3_sub3') }}
      />
      <div className="py-2" dangerouslySetInnerHTML={{ __html: t('sh_web_roaming_pup3_text3') }} />
    </Modal>
  );
}

export default DeactivateSwitzerlandRoamingModal;
