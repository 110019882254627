import React, { useEffect, useState } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { appImages } from '@utils/globalConstant';
import './index.scss';

export function StickyLayer() {
  // States
  const [hover, setHover] = useState(false);
  const [showStickyLayer, setShowStickyLayer] = useState(false);

  // Contexts
  const { t } = useStaticContent();

  // Functions
  const handleScroll = () => {
    if (window.scrollY > 650) {
      setShowStickyLayer(true);
    } else {
      setShowStickyLayer(false);
    }
  };

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <div
      onMouseEnter={() => setHover(!hover)}
      onMouseLeave={() => setHover(!hover)}
      className={`mt-5 action-box-parent z-2 d-flex align-items-center bg-pink-color ${
        hover ? 'show ' : ''
      } ${showStickyLayer ? 'animate__animated animate__fadeIn animate__faster' : 'd-none'}`}
    >
      {hover ? <img src={appImages.leftangle} /> : <img src={appImages.rightangle} />}
      <div className="action-image ps-1 py-2 my-1">
        <img className="action-icon" src={appImages.action} />
        <p
          className="body-medium-bold primary-color-text text-center m-0"
          dangerouslySetInnerHTML={{ __html: t('sh_Footnote2_Tarife_star_pink_new_1') }}
        />
      </div>
      <div className="d-flex align-items-center justify-content-start ps-4 ms-1">
        <div
          className="mb-0 label-medium primary-color-text font-14"
          dangerouslySetInnerHTML={{ __html: t('sh_Footnote2_Tarife_star_pink_new_2') }}
        />
      </div>
    </div>
  );
}

export default StickyLayer;
