import React from 'react';
import PropTypes from 'prop-types';

export function CardImgOverlay({ sx, children, ...props }) {
  return (
    <>
      <img src={props.src} className="card-img" alt={props.alt} />
      <div className="card-img-overlay">{children}</div>
    </>
  );
}

CardImgOverlay.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

CardImgOverlay.defaultProps = {
  sx: {}
};

export default CardImgOverlay;
